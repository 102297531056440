import { getCountryOptionsWithoutUSACA } from './countriesByAbbre'

const CAN_USA_OPTIONS = [
  {
    label: 'Canada',
    value: 'CAN'
  },
  {
    label: 'United States',
    value: 'USA'
  }
]

export const passportCountryOptions = [
  {
    label: 'Issuing Country',
    value: ''
  },
  ...CAN_USA_OPTIONS,
  ...getCountryOptionsWithoutUSACA()
]

export const citizenshipOptions = [
  {
    label: 'Citizenship',
    value: ''
  },
  ...CAN_USA_OPTIONS,
  ...getCountryOptionsWithoutUSACA()
]

export const typeOfIdOptions = [
  {
    label: 'ID Type',
    value: ''
  },
  {
    label: 'Passport',
    value: 'P'
  },
  {
    label: 'Passport ID',
    value: 'I'
  },
  {
    label: 'EDL-Enhanced drivers license',
    value: 'D'
  },
  {
    label: 'US permanent residential card',
    value: 'U'
  }
]
