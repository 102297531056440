import { get, sortBy, toPairs } from 'lodash'
import yn from 'yn'
import moment from 'moment'
import {
  selectDepartureTotalGuests,
  selectDuplicatePassengerQuantities,
  selectPassengerSelections,
  selectReturnTotalGuests,
  selectDeparturePets,
  selectReturnPets
} from '../redux/passengerSelections/selectors'
import selectVehicleFormType from '../redux/selectors/selectVehicleFormType'
import {
  selectActiveCrossings,
  selectActiveDepartureCrossing,
  selectActiveReturnCrossing,
  selectAvailableKennels
} from '../redux/activeCrossings/selectors'
import { selectActiveAccommodations } from '../redux/activeAccommodations/selectors'
import { appRoutes, stepIcons, ModifyFlowType } from './constants'
import { HYDRA_MEMBER, FORCE_LOWERCASE_EMAIL } from './constants'
import { selectIsReturnTrip } from '../redux/ferryRouteSelections/selectors'
import { selectLanguage } from '../redux/session/selectors'
import { DEFAULT_LANG } from '../redux/user/constants'
import {
  accommodationDisaplayNamebyType,
  accommodationTypByCode
} from './accommodationTypes'

export const formatEmailParam = (email = '') => {
  return FORCE_LOWERCASE_EMAIL ? email.toLowerCase() : email
}

// TODO: make this recursive
export const getHydraMemberDataType = (success, idType) => {
  let result = false

  if (success.data && success.data[HYDRA_MEMBER]) {
    const member = success.data[HYDRA_MEMBER]

    if (Array.isArray(member) && member[0]) {
      const firstItem = member[0]

      if (!Array.isArray(firstItem)) {
        if (firstItem.id && firstItem.id === idType) {
          result = firstItem
        }
      } else if (firstItem[0]) {
        const nestedFirstItem = firstItem[0]

        if (!Array.isArray(nestedFirstItem)) {
          if (nestedFirstItem.id && nestedFirstItem.id === idType) {
            result = nestedFirstItem
          }
        }
      }
    }
  }

  return result
}

export const buildStepsFromKeys = (
  stepKeys,
  labels,
  modifyFlowType,
  vehicleEditable,
  state,
  userSelections
) => {
  let result = {}
  const routeKeys = Object.keys(appRoutes)
  let currentStepNumber = 0

  const effectiveStepKeys =
    modifyFlowType === ModifyFlowType.DEPARTURE_LOCKED
      ? stepKeys.filter(
          x => x !== 'departure' && x !== (vehicleEditable ? '' : 'vehicle')
        )
      : stepKeys

  effectiveStepKeys.forEach(stepName => {
    if (routeKeys.includes(stepName) && appRoutes[stepName]) {
      if (
        !stepMethods?.[stepName]?.shouldRender ||
        stepMethods?.[stepName]?.shouldRender(state, userSelections)
      ) {
        const stepDetails = {
          ...appRoutes[stepName],
          icon: stepIcons[stepName],
          stepNumber: currentStepNumber
        }

        const methods = stepMethods?.[stepName] || {}

        if (stepDetails.labelKey) {
          stepDetails.defaultLabel = labels[stepDetails.labelKey] || ''
        }

        if (stepDetails.pluralLabelKey) {
          stepDetails.pluralLabel = labels[stepDetails.pluralLabelKey] || ''
        }
        currentStepNumber += 1

        if (!stepDetails.disabled) {
          result[stepName] = { ...stepDetails, ...methods }
        }
      }
    }
  })

  return result
}

export const normalizeLabelWithCount = ({
  labelKey = '',
  pluralLabelKey = '',
  language = DEFAULT_LANG,
  count = 1,
  showCountWhenOne = false,
  labels = {}
}) => {
  const isPlural = count > 1
  let result = labels && labels[labelKey]

  if (!pluralLabelKey) return result

  let copiedPluralLabelKey = pluralLabelKey
  if (!showCountWhenOne || isPlural)
    result = labels && labels[copiedPluralLabelKey]

  if ((isPlural || showCountWhenOne) && result)
    result = `${count} ${
      language === 'fr'
        ? lowercaseFirstLetter(result)
        : capitalizeFirstLetter(result)
    }`

  return result
}

export const stepMethods = {
  passengerQuantities: {
    renderCompletedStepLabel: ({ state, userSelections, labels }) => {
      const departureTotal = selectDepartureTotalGuests(state)
      const returnTotal = selectReturnTotalGuests(state)
      const isDuplicateValues = selectDuplicatePassengerQuantities(state)
      const language = selectLanguage(state)
      const departurePets = selectDeparturePets(state)
      const returnPets = selectReturnPets(state)

      if (yn(process.env.REACT_APP_SHOW_PASSENGER_COUNTS_IN_STEPPER)) {
        const guests = selectPassengerSelections(state)

        const guestTotals = Object.entries({
          departure: guests.departure,
          return: guests.return,
          duplicatePassengerQuantities: guests.duplicatePassengerQuantities
        }).reduce((obj, [key, counts]) => {
          if (key === 'duplicatePassengerQuantities') {
            return obj
          }

          for (const [type, number] of Object.entries(counts)) {
            if (
              number > 0 &&
              (!obj[type] || (isDuplicateValues && number > obj[type]))
            ) {
              obj[type] = number
            }
          }
          return obj
        }, {})

        if (departurePets || returnPets) {
          guestTotals.pet = departurePets || returnPets
        }

        const guestList = Object.entries(guestTotals)
          .map(([key, count]) => {
            let pluralKey
            switch (key) {
              case 'adult':
                pluralKey = 'adults'
                break
              case 'senior':
                pluralKey = 'seniors'
                break
              case 'child':
                pluralKey = 'children'
                break
              case 'student':
                pluralKey = 'students'
                break
              case 'infant':
                pluralKey = 'infants'
                break
              case 'pet':
                pluralKey = 'pets'
                break
              default:
                pluralKey = ''
                break
            }

            return `${count} ${
              count > 1 && pluralKey
                ? labels[pluralKey]
                : key === 'pet'
                ? labels.petLabel
                : labels[key]
            }`
          })
          .join(', ')

        return guestList.length < 30
          ? guestList
          : guestList.substring(0, 27) + '...'
      }

      let count = isDuplicateValues
        ? departureTotal
        : Math.max(departureTotal, returnTotal)

      return normalizeLabelWithCount({
        labelKey: 'passenger',
        pluralLabelKey: 'passengers',
        language,
        count,
        labels
      })
    }
  },
  vehicle: {
    renderCompletedStepLabel: ({ state, userSelections, labels }) => {
      const vehicleFormType = selectVehicleFormType(state)
      return `${
        vehicleFormType === 'VEH-WLK' ? labels.noVehicle : labels.vehicles
      }`
    }
  },
  departure: {
    renderCompletedStepLabel: ({ state, userSelections, labels }) => {
      const departure = selectActiveDepartureCrossing(state)
      return `${labels.departure} ${
        departure && departure.departureTime ? departure.departureTime : ''
      }`
    }
  },
  return: {
    renderCompletedStepLabel: ({ state, userSelections, labels }) => {
      const returnTrip = selectActiveReturnCrossing(state)
      return `${labels.return} ${
        returnTrip && returnTrip.departureTime ? returnTrip.departureTime : ''
      }`
    },
    shouldRender: state => {
      return selectIsReturnTrip(state)
    }
  },
  accommodations: {
    renderCompletedStepLabel: ({ state, userSelections, labels }) => {
      const accommodations = selectActiveAccommodations(state)
      const language = selectLanguage(state)
      const kennels = selectAvailableKennels(state)
      const kennelResourceCodes = Object.keys(kennels)

      const reducedAccommodations = Object.values(accommodations).reduce(
        (obj, direction) => {
          for (const accommodation of Object.values(direction)) {
            // TODO: replace resource code
            const name = get(accommodation, 'details.resourceCode')
            if (name === 'RDS') {
              continue
            }

            if (
              kennelResourceCodes.includes(name) &&
              process.env.REACT_APP_ENABLE_ACCOMMODATIONS_SELECTOR ===
                'no_kennels'
            ) {
              continue
            }

            if (+accommodation.count) {
              if (!obj[name]) {
                obj[name] = 0
              }
              obj[name] += accommodation.count
            }
          }

          return obj
        },
        {}
      )

      const activeAccommodationStrings = Object.values(
        reducedAccommodations
      ).map(count => {
        return normalizeLabelWithCount({
          labelKey: 'selectedServicesLineItem',
          pluralLabelKey: 'selectedServicesLineItemPlural',
          language,
          count,
          labels,
          showCountWhenOne: true
        })
      })

      return activeAccommodationStrings && activeAccommodationStrings.length
        ? activeAccommodationStrings.join(' ')
        : labels.noAccommodationsSelected
    }
  },
  extras: {
    renderCompletedStepLabel: ({ state, labels }) => {
      const crossings = selectActiveCrossings(state)

      const total =
        (crossings?.departure?.reservedSeats?.length || 0) +
        (crossings?.return?.reservedSeats?.length || 0)

      if (total) {
        return `${total} ${total > 1 ? labels.seats : labels.seat}`
      }
      return labels.extras
    }
  },
  payment: {
    shouldRender: () => !yn(process.env.REACT_APP_HIDE_PAYMENT_ON_STEPPER)
  }
}

export const capitalizeFirstLetter = string => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

export const lowercaseFirstLetter = string => {
  return string ? string.charAt(0).toLowerCase() + string.slice(1) : ''
}

// TODO: combine with getLabelFromOptionValue
export const getValueFromOptionLabel = (label, options) => {
  let result = ''

  const option =
    options &&
    label &&
    options.find(option => {
      return option.label && option.label.toLowerCase() === label.toLowerCase()
    })

  if (option && option.value) result = option.value

  return result
}

export const getLabelFromOptionValue = (value, options) => {
  let result = ''

  const option =
    options &&
    options.find(option => {
      return option.value.toLowerCase() === value.toLowerCase()
    })

  if (option && option.label) result = option.label

  return result
}

export const addDynamicLabelsToOptions = ({
  options = [],
  nameValueMap = {},
  customLabelSubstring = false,
  labels = {}
}) => {
  const result = []
  const copiedOptions = JSON.parse(JSON.stringify(options))

  Object.entries(nameValueMap).forEach(([optionName, optionValue]) => {
    const previousItem = copiedOptions.find(
      ({ value }) => value === optionValue
    )
    const prevLabel = previousItem?.label
    const dynamicLabel = labels[`${optionName}AgeDesc`]
    if (previousItem && prevLabel) {
      let newItem = {
        ...previousItem,
        label: prevLabel
      }

      if (dynamicLabel && !prevLabel.includes(`(${dynamicLabel})`)) {
        newItem = {
          ...newItem,
          label: `${prevLabel} (${dynamicLabel})`
        }
      }

      result.push(newItem)
    }
  })

  return result
}

export const getFaresString = ({
  labels,
  language,
  adults,
  seniors,
  children,
  infants,
  students,
  pets
}) => {
  const fares = []

  if (adults > 0) {
    fares.push(
      normalizeLabelWithCount({
        labelKey: 'adult',
        pluralLabelKey: 'adults',
        language,
        count: adults,
        showCountWhenOne: true,
        labels
      })
    )
  }
  if (seniors > 0) {
    fares.push(
      normalizeLabelWithCount({
        labelKey: 'senior',
        pluralLabelKey: 'seniors',
        language,
        count: seniors,
        showCountWhenOne: true,
        labels
      })
    )
  }
  if (students > 0) {
    fares.push(
      normalizeLabelWithCount({
        labelKey: 'student',
        pluralLabelKey: 'students',
        language,
        count: students,
        showCountWhenOne: true,
        labels
      })
    )
  }
  if (children > 0) {
    fares.push(
      normalizeLabelWithCount({
        labelKey: 'child',
        pluralLabelKey: 'children',
        language,
        count: children,
        showCountWhenOne: true,
        labels
      })
    )
  }
  if (infants > 0) {
    fares.push(
      normalizeLabelWithCount({
        labelKey: 'infant',
        pluralLabelKey: 'infants',
        language,
        count: infants,
        showCountWhenOne: true,
        labels
      })
    )
  }
  if (pets > 0) {
    fares.push(
      normalizeLabelWithCount({
        labelKey: 'petLabel',
        pluralLabelKey: 'pets',
        language,
        count: pets,
        showCountWhenOne: true,
        labels
      })
    )
  }

  return fares.join(', ')
}

export const cleanPhoneNumber = value => value.replace(/\D+/g, '')

// Formatting (000) 000-0000
export const formatPhoneNumber = value =>
  cleanPhoneNumber(value).replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2-$3')

export const formatPostalCode = value => value.replace(/[^0-9a-zA-Z]/g, '')

export const formatAddress = value => value.replace(/[<>$%=?^{}[\]\\]/g, '')

export const getPassengerLabelKeysFromPassengerType = type => {
  switch (type) {
    case 'A':
      return {
        default: 'adult',
        plural: 'adults'
      }
    case 'S':
      return {
        default: 'senior',
        plural: 'seniors'
      }
    case 'E':
    case 'C':
      return {
        default: 'child',
        plural: 'children'
      }
    case 'B':
    case 'I':
      return {
        default: 'infant',
        plural: 'infants'
      }
    case 'ST':
      return {
        default: 'student',
        plural: 'students'
      }
    case 'PET':
      return {
        default: 'pet',
        plural: 'pets'
      }
    default:
      if (process.env.REACT_APP_VERB_ENV !== 'production') {
        console.error('Unsupported passenger type: ' + type)
      }
      return false
  }
}

export const getPassengerLabelKeysFromCode = type => {
  switch (type) {
    case 'adult':
      return {
        default: 'adult',
        plural: 'adults'
      }
    case 'senior':
      return {
        default: 'senior',
        plural: 'seniors'
      }
    case 'student':
      return {
        default: 'student',
        plural: 'students'
      }
    case 'child':
      return {
        default: 'child',
        plural: 'children'
      }
    case 'infant':
      return {
        default: 'infant',
        plural: 'infants'
      }
    case 'pet':
      return {
        default: 'pet',
        plural: 'pets'
      }
    default:
      return false
  }
}

export const getPassengerNamesList = (labels, bookedPassengers = []) => {
  const result = []

  // Note: These are invalid and we fallback to the default format based on type
  const firstNamePlaceholder = 'PASSENGER'
  const lastNamePlaceholder = 'LASTNAME'

  bookedPassengers.forEach(({ passengerType, name }, passengerIndex) => {
    const passengerNumber = passengerIndex + 1
    let passengerResult = ''
    let labelPrefix = ''

    const passengerLabels = getPassengerLabelKeysFromPassengerType(
      passengerType
    )

    if (passengerLabels?.default && labels[passengerLabels.default]) {
      labelPrefix = labels[passengerLabels.default]
    }

    // Default format: "Adult: Passenger 2"
    // note: prefix is sent back as value to be added by the component
    if (labels.passenger) {
      passengerResult = `${labels.passenger} ${passengerNumber}`
    }

    // If we have a valid name,
    // Format: Adult: FIRSTNAME LASTNAME
    // (name includes the first name and last name)
    if (
      name &&
      !name.includes(firstNamePlaceholder) &&
      !name.includes(lastNamePlaceholder)
    ) {
      passengerResult = name
    }

    if (passengerResult && labelPrefix) {
      result.push({
        namePrefix: labelPrefix,
        name: capitalizeFirstLetter(passengerResult)
      })
    }
  })

  return result.length ? result : false
}

export const asyncTryCatch = async (asyncMethod, asyncMethodProps) => {
  if (!asyncMethod || !asyncMethodProps) return false

  try {
    const result = await asyncMethod(asyncMethodProps)

    return result
  } catch (e) {
    return e
  }
}

export const renderParagraph = (label, classNames) =>
  `<p class="${classNames ? classNames : 'u-remove-margin'}">${label}</p>`
export const renderSpan = (label, classNames) =>
  `<span class="${classNames ? classNames : 'u-font-bold'}">${label}</span>`

export const calculateFormFlexOrder = activeCardIndex => {
  const cardOrder = activeCardIndex + 1
  let cardsPerRow = 1

  if (window.innerWidth >= 480) {
    cardsPerRow = 2
  }
  if (window.innerWidth >= 768) {
    cardsPerRow = 3
  }
  if (window.innerWidth >= 1024) {
    cardsPerRow = 4
  }

  return Math.ceil(cardOrder / cardsPerRow) * cardsPerRow + 1
}

export const renderCardOrder = (index, formPosition) => {
  if (index + 1 < formPosition) {
    return index + 1
  }
  return index + formPosition
}

export const isNearSeasonChange = (departureDate, highSeason) => {
  if (!departureDate || !highSeason) {
    return false
  }

  const envVariables = process.env

  const {
    REACT_APP_NUMBER_OF_SAILING_DAYS = 5,
    REACT_APP_NUMBER_OF_PRIOR_SAILING_DAYS = 0
  } = envVariables

  const priorDays = parseInt(REACT_APP_NUMBER_OF_PRIOR_SAILING_DAYS)
  const sailingDays = parseInt(REACT_APP_NUMBER_OF_SAILING_DAYS)

  const [highSeasonStart, highSeasonEnd] = highSeason.split('|')

  if (highSeasonStart && highSeasonEnd) {
    const seasonStartPieces = highSeasonStart.split('-')
    const seasonEndPieces = highSeasonEnd.split('-')

    const startMonth = parseInt(seasonStartPieces[0])
    const startDay = parseInt(seasonStartPieces[1])
    const endMonth = parseInt(seasonEndPieces[0])
    const endDay = parseInt(seasonEndPieces[1])

    const testSeasonStartMin = moment()
    testSeasonStartMin.set({
      year: departureDate.year(),
      month: startMonth - 1,
      date: startDay - sailingDays
    })

    const testSeasonStartMax = moment()
    testSeasonStartMax.set({
      year: departureDate.year(),
      month: startMonth - 1,
      date: startDay + priorDays + 1
    })

    const testSeasonEndMin = moment()
    testSeasonEndMin.set({
      year: departureDate.year(),
      month: endMonth - 1,
      date: endDay - sailingDays
    })

    const testSeasonEndMax = moment()
    testSeasonEndMax.set({
      year: departureDate.year(),
      month: endMonth - 1,
      date: endDay + priorDays + 1
    })

    if (
      departureDate.isBetween(testSeasonStartMin, testSeasonStartMax) ||
      departureDate.isBetween(testSeasonEndMin, testSeasonEndMax)
    ) {
      return true
    }
  }

  return false
}

/**
 * Booking Summary Dropdown need correct display name for each accomodations:
 * Cabin, Seat, Kennel So, supplement two properties for it
 *
 * for: https://jira.verbinteractive.com/browse/MAT203-149
 *
 * @param {object} accommodations code: detail
 * @param {string} lang
 * @param {object} labels labels config under current language
 */
export const addDisplayNameToAccommodation = (accommodations, lang, labels) => {
  if (!accommodations) {
    return
  }
  Object.entries(accommodations).forEach(([code, accommodation]) => {
    const accommodationType = accommodationTypByCode[code]
    const singularInConfig = labels[code] ?? labels[accommodationType]
    const pluralInConfig = labels[code] ?? labels[accommodationType]
    const accommodationLabelByCode = accommodationDisaplayNamebyType[lang][code]
    const safeAccommodationLabel = accommodationLabelByCode
      ? accommodationLabelByCode
      : accommodationDisaplayNamebyType[lang]['C2L'] // Two Bed Cabin as default

    accommodation.displayName =
      singularInConfig || safeAccommodationLabel.default
    accommodation.displayTitlePlural =
      pluralInConfig || safeAccommodationLabel.plural
  })
}

/**
 * reorder an object by key in ascending alphabetical order
 * @param {object} object
 * @returns array of [key, value], e.g. [[key, value], [key, value], ...]
 */
export const sortObjectByKey = object => {
  return sortBy(toPairs(object), function(o) {
    return o[0]
  })
}

export const stripIllegalChars = field => {
  const regex = /{|}/g
  return field.replace(regex, '')
}

/**
 * check address and city field and strip illegal chars before sending to backend
 * @param {object} params api calling params
 */
export const recursiveFixApiPayload = params => {
  Object.keys(params).forEach(field => {
    const isObject = typeof params[field] === 'object'
    const isArray = Array.isArray(params[field])
    if (isObject && !isArray) return recursiveFixApiPayload(params[field])
    // strip illegal chars in address and city field
    if (field === 'address' || field === 'city') {
      params[field] = stripIllegalChars(params[field])
    }
  })
}
