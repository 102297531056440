import { appRoutes } from './constants'

const {
  account,
  accommodations,
  departure: departureRoute,
  return: returnRoute, // alias required for return is a reserved key word
  ferryRoutes,
  extras,
  summary,
  passengerDetails,
  passengerQuantities,
  modifyBooking,
  forgotPassword,
  vehicle,
  bookingConfirmation
} = appRoutes

export {
  accommodations,
  departureRoute,
  returnRoute,
  ferryRoutes,
  extras,
  summary,
  passengerDetails,
  passengerQuantities,
  modifyBooking,
  account,
  forgotPassword,
  vehicle,
  bookingConfirmation
}

export default appRoutes
