import React, { Fragment } from 'react'
import { bool, func } from 'prop-types'
import { useSelector } from 'react-redux'
import StatusModal from '../StatusModal/StatusModal'
import { selectLabels } from '../../redux/configuration/selectors'
import statusModalImage from '../../assets/images/brand-status-image.png'

const SessionExpiredModal = ({ isOpen, onContinueBookingClick }) => {
  const labels = useSelector(selectLabels)

  return (
    <StatusModal
      backgroundUrl={statusModalImage}
      backgroundAltLabel={labels.expiredTitle}
      labels={labels}
      isOpen={isOpen}
    >
      <Fragment>
        <h4 className="modal-col-title center-text theme-font-header">
          {labels.expiredTitle}
        </h4>
        <p>{labels.expiredMessage}</p>
        <button onClick={onContinueBookingClick} className="btn btn-primary">
          {labels.expiredContinue}
        </button>
      </Fragment>
    </StatusModal>
  )
}

SessionExpiredModal.propTypes = {
  isOpen: bool.isRequired,
  onContinueBookingClick: func.isRequired
}

export default SessionExpiredModal
