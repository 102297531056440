import { crossingTypes } from '../crossings/constants'
import {
  maxVehicleLengthBeforeContact,
  SET_VEHICLE_DETAILS_DEFAULT_ACTION_ERROR,
  tripTypes
} from './constants'

export const getVehicleByCode = ({ availableVehicles, vehicleCode }) => {
  let result = false
  let vehicleType = false
  let vehicleGroupTypeHeight = false
  let vehicleGroupTypeLength = false

  if (availableVehicles?.vehicles) {
    const match = availableVehicles.vehicles.find(
      ({ resourceCode }) => resourceCode === vehicleCode
    )

    if (match) vehicleType = match
  }

  if (vehicleType && availableVehicles?.groups) {
    const { height, length } = availableVehicles.groups
    const { defaultHeight, defaultLength } = vehicleType

    if (height) {
      const match = height.find(
        ({ minVal, maxVal }) =>
          +defaultHeight >= +minVal && +defaultHeight <= +maxVal
      )

      if (match) {
        vehicleGroupTypeHeight = match
      }
    }

    if (length) {
      const match = length.find(
        ({ minVal, maxVal }) =>
          +defaultLength >= +minVal && +defaultLength <= +maxVal
      )

      if (match) {
        vehicleGroupTypeLength = match
      }
    }
  }

  if (!!vehicleType && !!vehicleGroupTypeHeight && !!vehicleGroupTypeLength) {
    result = {
      vehicleType,
      vehicleGroupTypeHeight,
      vehicleGroupTypeLength
    }
  }

  return result
}

export const renderVehicleError = ({
  labels = {},
  errorType = '',
  maxLength = 0,
  minLength = 0,
  phone = ''
}) => {
  let result = ''

  /*
    note: labels.vehicleMinLengthError covers both
    the min and max length errors:
    "Vehicle length must be between 22 and 65"
  */
  if (errorType === 'minLengthNotMet' && minLength && minLength > 0) {
    result = labels.vehicleMinLengthError
  }

  if (errorType === 'maxLengthExceeded' && maxLength && maxLength > 0) {
    result = labels.vehicleMinLengthError
  }

  if (errorType === 'requiresContact' && phone) {
    result = `${labels.forVehicleLongerThan} ${maxVehicleLengthBeforeContact} ${labels.pleaseCall} ${phone}.`
  }

  if (errorType === 'licensePlateNumberRequired') {
    result = labels.thisFieldIsRequired
  }

  return result
}

export const generateFloatingVehicleLengthLabel = ({
  labels,
  minVal,
  maxVal
}) => {
  let result = null

  if (minVal && maxVal && labels.between && labels.and && labels.feet) {
    result = `${labels.between} ${minVal} ${labels.and} ${maxVal} ${labels.feet}`
  }

  return result
}

export const validateDirection = ({
  vehicleType,
  vehicleLength,
  vehicleLicense
}) => {
  let result = !!(vehicleType && Object.keys(vehicleType).length > 0)

  if (vehicleType?.lengthRequired) {
    if (!vehicleLength) {
      result = false
    } else {
      const { minLength, maxLength } = vehicleType

      if (+vehicleLength > maxLength) {
        result = false
      }

      if (+vehicleLength < minLength) {
        result = false
      }
    }
  }

  if (vehicleType?.licensePlateNumberRequired && !vehicleLicense) {
    result = false
  }

  return result
}

export const invalidVehicleDataOnSet = (vehicleDetails = {}, crossingType) => {
  const {
    vehicleLength,
    vehicleLicense,
    vehicleGroupTypeHeight,
    vehicleGroupTypeLength,
    vehicleType
  } = vehicleDetails
  const defaultDepartureError = {
    crossingType: crossingTypes.DEPARTURE,
    error: SET_VEHICLE_DETAILS_DEFAULT_ACTION_ERROR
  }
  let error = false

  if (!vehicleGroupTypeHeight || !vehicleGroupTypeLength || !vehicleType) {
    error = defaultDepartureError
  }
  if (!vehicleLength && vehicleLength !== '') {
    error = defaultDepartureError
  }
  if (!vehicleLicense && vehicleLicense !== '') {
    error = defaultDepartureError
  }
  if (!crossingType || !Object.values(crossingTypes).includes(crossingType)) {
    error = defaultDepartureError
  }

  return error
}

// FIXME: get vehicle length/license string
const getVehicleDetailsByCrossingType = ({ booking, valueKey, crossingType }) =>
  crossingType &&
  valueKey &&
  booking?.[`${crossingType}VehicleDetails`]?.[valueKey]
    ? booking[`${crossingType}VehicleDetails`][valueKey]
    : ''

export const getBookingVehicleByCrossingType = ({
  booking = {},
  bookingVehicle = {},
  crossingType = ''
}) => {
  let result = false

  if (bookingVehicle) {
    const {
      vehicleGroupTypeLength,
      vehicleGroupTypeHeight,
      vehicleType
    } = bookingVehicle
    const vehicleLength = getVehicleDetailsByCrossingType({
      booking,
      valueKey: 'length',
      crossingType
    })
    const vehicleLicense = getVehicleDetailsByCrossingType({
      booking,
      valueKey: 'licenseNumber',
      crossingType
    })
    const invalidVehicle = invalidVehicleDataOnSet(
      {
        vehicleGroupTypeLength,
        vehicleGroupTypeHeight,
        vehicleType,
        vehicleLength,
        vehicleLicense
      },
      crossingType
    )

    if (!invalidVehicle) {
      result = {
        vehicleGroupTypeLength,
        vehicleGroupTypeHeight,
        vehicleType,
        vehicleLength: vehicleLength ? vehicleLength.toString() : '',
        vehicleLicense
      }
    }
  }

  return result
}

export const isValidTripType = code =>
  tripTypes && Object.values(tripTypes).some(val => val === code)
