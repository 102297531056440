import { handleActions } from 'redux-actions'
import moment from 'moment'
import {
  SET_ROUTE_FORM,
  SET_DEPARTURE_DATE,
  SET_DEPARTURE_ROUTE,
  SET_RETURN_DATE,
  SET_RETURN_ROUTE,
  SET_FORCES_BOOKING
} from './constants'
import dateFormats from '../../configuration/dateFormats'

const ferryRouteSelectionInitialState = {
  activeRouteForm: 'RT',
  departureDate: moment().format(dateFormats.default),
  departureRoute: {},
  returnDate: moment()
    .add(1, 'days')
    .format(dateFormats.default),
  returnRoute: {},
  forcesBooking: false
}

const ferryRouteSelectionReducer = handleActions(
  {
    [SET_ROUTE_FORM]: (state, { payload }) => ({
      ...state,
      activeRouteForm: payload
    }),
    [SET_DEPARTURE_DATE]: (state, { payload }) => ({
      ...state,
      departureDate:
        payload && payload.format
          ? payload.format(dateFormats.default)
          : payload
    }),
    [SET_DEPARTURE_ROUTE]: (state, { payload }) => {
      // FIXME(ebarrett): Remove this dumb logic in or after May 2022, it should
      //  just update the departureRoute param here...
      let newDepartureDate = state.departureDate
      let newReturnDate = state.returnDate

      if (
        payload?.ferryId === 'cat' &&
        state.departureRoute?.ferryId !== 'cat'
      ) {
        const mayDepartureDate = moment('2022-05-19').format(
          dateFormats.default
        )
        if (moment(mayDepartureDate).isAfter(moment(newDepartureDate))) {
          newDepartureDate = mayDepartureDate
        }
        const mayReturnDate = moment('2022-05-20').format(dateFormats.default)
        if (moment(mayReturnDate).isAfter(moment(newReturnDate))) {
          newReturnDate = mayReturnDate
        }
      } else if (
        payload?.ferryId !== 'cat' &&
        state.departureRoute?.ferryId === 'cat'
      ) {
        newDepartureDate = moment().format(dateFormats.default)
        newReturnDate = moment()
          .add(1, 'days')
          .format(dateFormats.default)
      }

      return {
        ...state,
        departureRoute: payload,
        departureDate: newDepartureDate,
        returnDate: newReturnDate
      }
    },
    [SET_RETURN_DATE]: (state, { payload }) => ({
      ...state,
      returnDate:
        payload && payload.format
          ? payload.format(dateFormats.default)
          : payload
    }),
    [SET_RETURN_ROUTE]: (state, { payload }) => ({
      ...state,
      returnRoute: payload
    }),
    [SET_FORCES_BOOKING]: (state, { payload }) => ({
      ...state,
      forcesBooking: payload
    })
  },
  { ...ferryRouteSelectionInitialState }
)

export { ferryRouteSelectionInitialState }
export default ferryRouteSelectionReducer
