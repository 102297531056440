export const SET_DEPARTURE_ADULTS = 'passengerSelections/SET_DEPARTURE_ADULTS'
export const SET_DEPARTURE_SENIORS = 'passengerSelections/SET_DEPARTURE_SENIORS'
export const SET_DEPARTURE_STUDENTS =
  'passengerSelections/SET_DEPARTURE_STUDENTS'
export const SET_DEPARTURE_CHILDREN =
  'passengerSelections/SET_DEPARTURE_CHILDREN'
export const SET_DEPARTURE_INFANTS = 'passengerSelections/SET_DEPARTURE_INFANTS'
export const SET_DEPARTURE_PETS = 'passengerSelections/SET_DEPARTURE_PETS'
export const SET_RETURN_ADULTS = 'passengerSelections/SET_RETURN_ADULTS'
export const SET_RETURN_SENIORS = 'passengerSelections/SET_RETURN_SENIORS'
export const SET_RETURN_STUDENTS = 'passengerSelections/SET_RETURN_STUDENTS'
export const SET_RETURN_CHILDREN = 'passengerSelections/SET_RETURN_CHILDREN'
export const SET_RETURN_INFANTS = 'passengerSelections/SET_RETURN_INFANTS'
export const SET_RETURN_PETS = 'passengerSelections/SET_RETURN_PETS'
export const SET_DUPLICATE_PASSENGER_QUANTITIES =
  'passengerSelections/SET_DUPLICATE_PASSENGER_QUANTITIES'
export const RESET_PASSENGER_SELECTION =
  'passengerSelections/RESET_PASSENGER_SELECTION'
export const SET_ALL_DEPARTURE_GUESTS =
  'passengerSelections/SET_ALL_DEPARTURE_GUESTS'
export const SET_ALL_RETURN_GUESTS = 'passengerSelections/SET_ALL_RETURN_GUESTS'
