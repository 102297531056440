import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Fragment
} from 'react'
import { func } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
// ************ CONSTANTS *************
import {
  appRoutes,
  ModifyFlowType,
  defaultToVehicleOptionsList,
  shouldPreFillVehicleLengthOnVehicleSelectionChange
} from '../../configuration/constants'
import {
  maxVehicleLengthBeforeContact,
  SCROLL_TO_VEHICLE_SECTION_IN,
  tripTypes,
  vehicleInputs,
  vehicleSections,
  vehicleSelectionDefaultState,
  vehicleTypes
} from '../../redux/vehicleSelections/constants'
import { crossingTypes } from '../../redux/crossings/constants'
// ************ ACTIONS *************
import {
  clearVehicleError,
  resetVehicleSelections,
  setVehicleDetails,
  setVehicleError,
  setVehicleFormType,
  updateVehicleDetails
} from '../../redux/vehicleSelections/actions'
import * as UserSelectionActions from '../../redux/userSelections/actions'
import {
  fetchCrossing
  // fetchCrossingsWithParams
} from '../../redux/crossings/actions'
// *********** COMPONENTS ***********
import BrandLoader from '../../components/BrandLoader/BrandLoader'
import PageWarningBanner from '../../components/PageWarningBanner/PageWarningBanner'
import AreYouBringingAVehicle from '../../components/AreYouBringingAVehicle/AreYouBringingAVehicle'
import VehicleSelectionForms from '../../components/VehicleSelection/VehicleSelectionForms/VehicleSelectionForms'
// *********** CONTEXT **************
import ModalConsumer from '../../context/modalContext'
// ********* SELECTORS **************
import {
  selectAvailableVehicleOptions,
  selectConfigurationFerryOptions,
  selectLabels,
  selectVehicleViewContent,
  selectBicycleResourceCode
} from '../../redux/configuration/selectors'
import { selectActiveRouteForm } from '../../redux/ferryRouteSelections/selectors'
import {
  selectDepartureVehicleErrors,
  selectReturnVehicleErrors
} from '../../redux/vehicleSelections/selectors'
import {
  selectActiveModifyBooking,
  selectModifyFlowType
} from '../../redux/modifyBooking/selectors'
import { selectCrossingData } from '../../redux/crossings/selectors'
import { selectLanguage, selectCurrency } from '../../redux/session/selectors'
import { selectPaymentFerryId } from '../../redux/paymentConfirmation/selectors'
import { selectUserSelections } from '../../redux/userSelections/selectors'

// ********** GLOBAL SELECTORS ******
import selectInitialVehicleValues from '../../redux/selectors/selectInitialVehicleValues'
import selectVehicleFormType from '../../redux/selectors/selectVehicleFormType'
import selectRouteVehicleOptions from '../../redux/selectors/selectRouteVehicleOptions'
import selectAreYouBringingAVehicleOptions from '../../redux/selectors/selectAreYouBringingAVehicleOptions'
// *********** UTILITIES ************
import { mapNewlinesAsBreaks } from '../../utilities/rendering/renderUtils'
import { validateDirection } from '../../redux/vehicleSelections/utilities'
// *********** STYLES ***************
import '../../styles/scss/components/vehicles/passenger-vehicles.scss'
import '../../styles/scss/components/vehicles/passenger-vehicles-details-form.scss'
import '../../styles/scss/components/vehicles/passenger-vehicles-group-types-toggles.scss'
import '../../styles/scss/components/vehicles/passenger-vehicles-initial-form.scss'

/**
 * Vehicle Selection Container
 */
const VehicleSelectionContainer = ({ handleRouteChange }) => {
  const dispatch = useDispatch()
  const vehicleSelectionFormRef = useRef(null)
  const activeRouteForm = useSelector(selectActiveRouteForm)
  const initialValues = useSelector(selectInitialVehicleValues)
  const crossingData = useSelector(selectCrossingData)
  const departureVehicleErrors = useSelector(selectDepartureVehicleErrors)
  const returnVehicleErrors = useSelector(selectReturnVehicleErrors)
  const ferryId = useSelector(selectPaymentFerryId)
  const labels = useSelector(selectLabels)
  const language = useSelector(selectLanguage)
  const initialFormType = useSelector(selectVehicleFormType)
  const defaultVehicleOptions = useSelector(selectRouteVehicleOptions)
  const groupedVehicleOptions = useSelector(selectAvailableVehicleOptions)
  const bicycleResourceCode = useSelector(selectBicycleResourceCode)
  const vehicleViewContent = useSelector(selectVehicleViewContent)
  const tooltips = vehicleViewContent?.value?.tooltip || null
  const tooltipIcons = vehicleViewContent?.tooltips?.icons || []
  const configData = useSelector(selectConfigurationFerryOptions)
  const activeModifyBooking = useSelector(selectActiveModifyBooking)
  const modifyFlowType = useSelector(selectModifyFlowType)
  const areYouBringingAVehicleOptions = useSelector(
    selectAreYouBringingAVehicleOptions
  )
  const currencyType = useSelector(selectCurrency)
  const userSelections = useSelector(selectUserSelections)

  const [formValues, setFormValues] = useState(initialValues)

  const [didVehiclePrefill, setDidVehiclePrefill] = useState(false)
  const [bringingAVehicleSelection, updateBringingAVehicleSelection] = useState(
    initialFormType
  )

  // Reset local and redux vehicle selection state
  const clearVehicleSelections = (newFormType = '') => {
    dispatch(setVehicleFormType(newFormType))
    dispatch(resetVehicleSelections())

    updateBringingAVehicleSelection(newFormType)

    const newFormValues = {
      departure:
        modifyFlowType === ModifyFlowType.DEPARTURE_LOCKED
          ? formValues.departure
          : { ...vehicleSelectionDefaultState },
      return: { ...vehicleSelectionDefaultState }
    }

    setFormValues(newFormValues)
  }

  const clearVehicleSelectionsMemoized = useCallback(clearVehicleSelections, [])

  // use one function to create filters
  const checkVehiclePropertyFilter = (direction, property) => {
    return (
      !!bringingAVehicleSelection &&
      formValues?.[direction]?.[property] &&
      Object.keys(formValues[direction][property]).length > 0
    )
  }
  const selectedDepartureHeightFilter = checkVehiclePropertyFilter(
    crossingTypes.DEPARTURE,
    vehicleSections.HEIGHT
  )
  const selectedDepartureLengthFilter = checkVehiclePropertyFilter(
    crossingTypes.DEPARTURE,
    vehicleSections.LENGTH
  )
  const selectedReturnHeightFilter = checkVehiclePropertyFilter(
    crossingTypes.RETURN,
    vehicleSections.HEIGHT
  )
  const selectedReturnLengthFilter = checkVehiclePropertyFilter(
    crossingTypes.RETURN,
    vehicleSections.LENGTH
  )

  const isWalkOn = bringingAVehicleSelection === vehicleTypes.VEHWLK
  const isBicycle = bringingAVehicleSelection === bicycleResourceCode

  const isUsingTwoVehicles = bringingAVehicleSelection === vehicleTypes.VEHUNQ

  const allowOneWayWalkOn =
    isUsingTwoVehicles && process.env.REACT_APP_ALLOW_ONE_WAY_WALK_ON

  const renderReturnVehicleForm =
    !defaultToVehicleOptionsList &&
    activeRouteForm !== tripTypes.OW &&
    bringingAVehicleSelection === vehicleTypes.VEHUNQ

  const vehicleOptions = defaultToVehicleOptionsList
    ? defaultVehicleOptions
    : groupedVehicleOptions

  useEffect(() => {
    // NOTE(ebarrett): Swap over eventually!
    // const { departureParams, returnParams } = fetchCrossingsParams
    dispatch(fetchCrossing())
    // dispatch(fetchCrossingsWithParams(departureParams, 'departure'))
    if (activeRouteForm === tripTypes.RT) {
      dispatch(fetchCrossing(crossingTypes.RETURN))
      // dispatch(fetchCrossingsWithParams(returnParams, 'return'))
    }
  }, [dispatch, activeRouteForm]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!didVehiclePrefill && activeModifyBooking) {
      // walk on both ways
      const isWalkOn =
        !activeModifyBooking.departureVehicleCode &&
        !activeModifyBooking.returnVehicleCode
      const isReturnTrip = activeModifyBooking?.departures?.length > 1
      const hasDifferentVehicles =
        isReturnTrip &&
        activeModifyBooking.departureVehicleCode !==
          activeModifyBooking.returnVehicleCode

      const isWalkingOnDeparture =
        isWalkOn &&
        hasDifferentVehicles &&
        !activeModifyBooking.departureVehicleCode
      const isWalkingOnReturn =
        isWalkOn &&
        hasDifferentVehicles &&
        !activeModifyBooking.returnVehicleCode

      if (isWalkOn) {
        clearVehicleSelectionsMemoized(vehicleTypes.VEHWLK)
        setDidVehiclePrefill(true)
        return
      }

      const formValuesUpdate = { ...formValues }

      if (activeModifyBooking.departureVehicleDetails) {
        const {
          resourceCode,
          length,
          licenseNumber
        } = activeModifyBooking.departureVehicleDetails

        const vehicleType = vehicleOptions?.vehicles?.length
          ? vehicleOptions.vehicles.find(
              option => option.resourceCode === resourceCode
            )
          : null

        const lengthValue =
          +length % 1 === 0 ? (+length).toFixed() : String(length)

        const vehicleFormValues = {
          vehicleType,
          vehicleLength: lengthValue,
          vehicleLicense: licenseNumber
        }

        formValuesUpdate[crossingTypes.DEPARTURE] = {
          ...formValuesUpdate[crossingTypes.DEPARTURE],
          ...vehicleFormValues
        }
      } else if (isWalkingOnDeparture) {
        formValuesUpdate[crossingTypes.DEPARTURE] = {
          ...formValuesUpdate[crossingTypes.DEPARTURE],
          vehicleType: vehicleTypes.VEHWLK
        }
      }

      if (activeModifyBooking.returnVehicleDetails) {
        const {
          resourceCode,
          length,
          licenseNumber
        } = activeModifyBooking.returnVehicleDetails
        const vehicleType = vehicleOptions?.vehicles?.length
          ? vehicleOptions.vehicles.find(
              option => option.resourceCode === resourceCode
            )
          : null
        const returnLengthValue =
          +length % 1 === 0 ? (+length).toFixed() : String(length)

        const returnVehicleFormValues = {
          vehicleType,
          vehicleLength: returnLengthValue,
          vehicleLicense: licenseNumber
        }

        formValuesUpdate[crossingTypes.RETURN] = {
          ...formValuesUpdate[crossingTypes.RETURN],
          // ...vehicleFormValues,
          ...returnVehicleFormValues
        }
      } else if (isWalkingOnReturn) {
        formValuesUpdate[crossingTypes.RETURN] = {
          ...formValuesUpdate[crossingTypes.RETURN],
          vehicleType: vehicleTypes.VEHWLK
        }
      }

      setFormValues(formValuesUpdate)
      setDidVehiclePrefill(true)
    }
  }, [
    activeModifyBooking,
    clearVehicleSelectionsMemoized,
    didVehiclePrefill,
    formValues,
    vehicleOptions
  ])

  const vehicleLengthErrorHandler = (
    direction,
    lengthFilter,
    valueFromChange
  ) => {
    const { maxVal, minVal } = lengthFilter
    let isValid = true
    let errorType = ''
    if (valueFromChange && +valueFromChange < minVal) {
      isValid = false
      errorType = 'minLengthNotMet'
    }
    if (valueFromChange && +valueFromChange > maxVal) {
      isValid = false
      errorType = 'maxLengthExceeded'
    }
    if (valueFromChange && +valueFromChange > +maxVehicleLengthBeforeContact) {
      isValid = false
      errorType = 'requiresContact'
    }

    // clear the error if its valid now
    if (
      isValid &&
      departureVehicleErrors &&
      departureVehicleErrors[vehicleInputs.LENGTH]
    ) {
      dispatch(
        clearVehicleError({
          crossingType: direction,
          errorkey: vehicleInputs.LENGTH
        })
      )
    }

    if (!isValid) {
      dispatch(
        setVehicleError({
          crossingType: direction,
          errorkey: vehicleInputs.LENGTH,
          errorValue: errorType || true
        })
      )
    }
  }

  const vehicleLicenseErrorHandler = (
    direction,
    filterSelected,
    valueFromChange
  ) => {
    const { licensePlateNumberRequired } = filterSelected
    let isValid = true
    let errorType = ''

    if (licensePlateNumberRequired && !valueFromChange) {
      isValid = false
      errorType = 'licensePlateNumberRequired'
    }

    // clear the error if its valid now
    if (
      isValid &&
      departureVehicleErrors &&
      departureVehicleErrors[vehicleInputs.LICENSE]
    ) {
      dispatch(
        clearVehicleError({
          crossingType: direction,
          errorkey: vehicleInputs.LICENSE
        })
      )
    }

    if (!isValid) {
      dispatch(
        setVehicleError({
          crossingType: direction,
          errorkey: vehicleInputs.LICENSE,
          errorValue: errorType || true
        })
      )
    }
  }

  // Handle vehicle LENGTH & LICENSE input change
  const handleChange = (direction, key, value) => {
    const currentValues = { ...formValues[direction] }
    const departureFiltersSelected = !!(
      selectedDepartureLengthFilter && selectedDepartureHeightFilter
    )
    const returnFiltersSelected = !!(
      selectedReturnLengthFilter && selectedReturnHeightFilter
    )

    const filterWithDirection = {
      [crossingTypes.DEPARTURE]: selectedDepartureLengthFilter,
      [crossingTypes.RETURN]: selectedReturnLengthFilter
    }

    const selectedWithDirection = {
      [crossingTypes.DEPARTURE]: departureFiltersSelected,
      [crossingTypes.RETURN]: returnFiltersSelected
    }

    // Length text input error handling when both filters are selected for this crossingType
    if (key === vehicleInputs.LENGTH) {
      // update vehicle length
      vehicleLengthErrorHandler(
        direction,
        filterWithDirection[direction],
        value
      )
    }

    // License text input error handling
    if (key === vehicleInputs.LICENSE) {
      // License text input error handling
      vehicleLicenseErrorHandler(
        direction,
        selectedWithDirection[direction],
        value
      )
    }

    // Clear length and license input fields and selected vehicle option type,
    // if height or length filter is changed
    if (key === vehicleSections.HEIGHT || key === vehicleSections.LENGTH) {
      currentValues[vehicleSections.TYPE] = {}
      currentValues[vehicleInputs.LENGTH] = ''
      currentValues[vehicleInputs.LICENSE] = ''
    }

    setFormValues({
      ...formValues,
      [direction]: { ...currentValues, [key]: value }
    })
  } // end of handleChange

  const updateVehicleTypeAndHandleSelection = copyOrNot => vehicle => {
    const vehicleType =
      activeRouteForm === tripTypes.OW
        ? vehicleTypes.VEHOWY
        : vehicleTypes.VEHDUP
    updateBringingAVehicleSelection(vehicleType)

    handleVehicleSelection(crossingTypes.DEPARTURE, vehicle, copyOrNot)
  }

  // TODO: account for modifications

  const handleVehicleSelection = (
    direction,
    vehicle,
    copyIntoReturn = false
  ) => {
    const vehicleInModify = {
      [crossingTypes.DEPARTURE]: activeModifyBooking?.departureVehicleDetails,
      [crossingTypes.RETURN]: activeModifyBooking?.returnVehicleDetails
    }
    const vehicleResourceCode = vehicleInModify[direction]?.resourceCode
    const isModifiedVehicle = vehicle.resourceCode === vehicleResourceCode
    const vehicleLengthInModification = isModifiedVehicle
      ? vehicleInModify[direction]?.length
      : ''

    const vehicleUpdates = {
      vehicleType: vehicle,
      vehicleLength:
        +vehicle.defaultLength < 21 &&
        shouldPreFillVehicleLengthOnVehicleSelectionChange
          ? vehicle.defaultLength.toString()
          : vehicleLengthInModification,
      vehicleLicense: ''
    }
    let updatedFormValues = {
      ...formValues,
      [direction]: {
        ...formValues[direction],
        ...vehicleUpdates
      }
    }

    if (copyIntoReturn) {
      updatedFormValues = {
        ...updatedFormValues,
        [crossingTypes.RETURN]: {
          ...updatedFormValues[crossingTypes.RETURN],
          ...vehicleUpdates
        }
      }
    }
    setFormValues(updatedFormValues)
  } // end of handleVehicleSelection

  const renderContinueDisabled = () => {
    if (isWalkOn || isBicycle) return false
    if (defaultToVehicleOptionsList) {
      if (
        formValues.departure &&
        Object.keys(formValues.departure).length > 0 &&
        formValues.departure.vehicleType &&
        Object.keys(formValues.departure.vehicleType).length > 0
      ) {
        return false
      }
    }
    if (!bringingAVehicleSelection) return true

    let isValid = validateDirection(formValues.departure)

    if (renderReturnVehicleForm && isValid) {
      isValid = validateDirection(formValues.return)
    }

    return !isValid
  }

  const isBringingVehicle =
    !!bringingAVehicleSelection && !isWalkOn && !isBicycle

  const scrollToVehicleSelectionForm = () => {
    setTimeout(() => {
      vehicleSelectionFormRef.current.scrollIntoView()
    }, SCROLL_TO_VEHICLE_SECTION_IN)
  }

  const onUpdateBringingAVehicleSelection = vehicleFormType => {
    updateBringingAVehicleSelection(vehicleFormType)
    if (
      vehicleFormType === vehicleTypes.VEHDUP ||
      vehicleFormType === vehicleTypes.VEHUNQ
    ) {
      scrollToVehicleSelectionForm()
    }
  }

  const submit = () => {
    // START new selection flow

    // TODO(ebarrett):
    //  - Verify this on modify flow
    //  - renderReturnVehicleForm logic is convoluted... Maybe clean up?

    const { departure: departureValues, return: returnValues } = formValues

    departureValues.vehicleLicense =
      departureValues.vehicleType.resourceCode === bicycleResourceCode
        ? 'NA'
        : departureValues.vehicleLicense

    returnValues.vehicleLicense =
      returnValues.vehicleType.resourceCode === bicycleResourceCode
        ? 'NA'
        : returnValues.vehicleLicense
    if (
      allowOneWayWalkOn &&
      departureValues?.vehicleType === vehicleTypes.VEHWLK
    ) {
      dispatch(
        UserSelectionActions.setDepartureVehicle({
          vehicleType: vehicleTypes.VEHWLK
        })
      )
    } else {
      dispatch(
        UserSelectionActions.setDepartureVehicle({
          resourceCode: departureValues.vehicleType.resourceCode,
          length:
            departureValues.vehicleLength ??
            departureValues.vehicleType.defaultLength,
          height:
            departureValues.vehicleHeight ??
            departureValues.vehicleType.defaultHeight,
          license:
            departureValues.vehicleType.resourceCode === bicycleResourceCode
              ? 'NA'
              : departureValues.vehicleLicense
        })
      )
    }

    if (renderReturnVehicleForm) {
      if (
        allowOneWayWalkOn &&
        returnValues?.vehicleType === vehicleTypes.VEHWLK
      ) {
        dispatch(
          UserSelectionActions.setReturnVehicle({
            vehicleType: vehicleTypes.VEHWLK
          })
        )
      } else {
        dispatch(
          UserSelectionActions.setReturnVehicle({
            resourceCode: returnValues.vehicleType.resourceCode,
            length:
              returnValues.vehicleLength ??
              returnValues.vehicleType.defaultLength,
            height:
              returnValues.vehicleHeight ??
              returnValues.vehicleType.defaultHeight,
            license:
              returnValues.vehicleType.resourceCode === bicycleResourceCode
                ? 'NA'
                : returnValues.vehicleLicense
          })
        )
      }
    } else {
      dispatch(UserSelectionActions.setIsSameVehicle(true))
    }
    // END new selection flow

    dispatch(setVehicleFormType(bringingAVehicleSelection))

    if (!isWalkOn) {
      const formattedDepartureValues = { ...formValues.departure }
      if (
        !formattedDepartureValues.vehicleLength &&
        formattedDepartureValues.vehicleType.defaultLength
      ) {
        formattedDepartureValues.vehicleLength = formattedDepartureValues.vehicleType.defaultLength.toString()
      }
      if (
        !allowOneWayWalkOn ||
        departureValues?.vehicleType !== vehicleTypes.VEHWLK
      ) {
        // set DEPARTURE vehicle
        dispatch(
          updateVehicleDetails({
            vehicleDetails: formattedDepartureValues,
            crossingType: crossingTypes.DEPARTURE
          })
        )
        // set RETURN vehicle in nfl
        if (defaultToVehicleOptionsList) {
          dispatch(
            updateVehicleDetails({
              vehicleDetails: formattedDepartureValues,
              crossingType: crossingTypes.RETURN
            })
          )
        }
      }

      if (renderReturnVehicleForm) {
        const formattedReturnValues = { ...formValues.return }
        if (
          !formattedReturnValues.vehicleLength &&
          formattedReturnValues.vehicleType.defaultLength
        ) {
          formattedReturnValues.vehicleLength = formattedReturnValues.vehicleType.defaultLength.toString()
        }
        if (
          !allowOneWayWalkOn ||
          returnValues?.vehicleType !== vehicleTypes.VEHWLK
        ) {
          dispatch(
            updateVehicleDetails({
              vehicleDetails: formattedReturnValues,
              crossingType: crossingTypes.RETURN
            })
          )
        }
      } else if (!defaultToVehicleOptionsList) {
        // Reset return vehicle
        dispatch(
          setVehicleDetails({
            crossingType: crossingTypes.RETURN,
            vehicle: { ...initialValues.return }
          })
        )
      }
    } else {
      // WALK ON, we'll clear any previously made selections.
      dispatch(
        setVehicleDetails({
          crossingType: crossingTypes.DEPARTURE,
          vehicle: { ...vehicleSelectionDefaultState }
        })
      )
      dispatch(
        setVehicleDetails({
          crossingType: crossingTypes.RETURN,
          vehicle: { ...vehicleSelectionDefaultState }
        })
      )
    }

    if (modifyFlowType === ModifyFlowType.DEPARTURE_LOCKED) {
      handleRouteChange(appRoutes.return.pathname)
    } else {
      handleRouteChange(appRoutes.departure.pathname)
    }
  } // end of submit

  const isLoading = Boolean(
    crossingData?.departure?.loading || crossingData?.return?.loading
  )

  const vehicleMessageBanner = ferryId
    ? labels[`vehicleMessageBanner${ferryId.toUpperCase()}`]
    : null

  const currencyRateReminderCAT =
    currencyType === 'USD' ? labels.currencyRateReminderCAT : null
  return (
    <div className="passenger-vehicles u-page-view-container">
      <div className="u-container">
        {' '}
        <div className="u-container">
          {isLoading ? (
            <BrandLoader />
          ) : (
            <div className="passenger-vehicles__content">
              <h2 className="theme-font-header">{labels.bringingAVehicle}</h2>
              {currencyRateReminderCAT && (
                <p className="u-text-center">{currencyRateReminderCAT}</p>
              )}
              {vehicleMessageBanner && (
                <PageWarningBanner
                  message={vehicleMessageBanner}
                  centered={process.env.REACT_APP_FLOW_CLIENT_ID === 'nfl'}
                >
                  <p className="u-remove-margin u-text-center">
                    {mapNewlinesAsBreaks(vehicleMessageBanner)}
                  </p>
                </PageWarningBanner>
              )}
              <Fragment>
                <AreYouBringingAVehicle
                  activeSelection={bringingAVehicleSelection}
                  selectionKeys={areYouBringingAVehicleOptions}
                  updateSelection={onUpdateBringingAVehicleSelection}
                  clearVehicleSelections={clearVehicleSelections}
                  crossingData={crossingData}
                  language={language}
                  vehicleOptions={vehicleOptions}
                  handleVehicleSelection={updateVehicleTypeAndHandleSelection(
                    false
                  )}
                  oneWay={activeRouteForm === tripTypes.OW}
                />
                {/* vehicle details form if passenger bring his/her own */}
                <span ref={vehicleSelectionFormRef}>
                  {isBringingVehicle && (
                    <ModalConsumer>
                      {({ modalIsOpen, openModal, closeModal }) =>
                        !vehicleOptions ? null : (
                          <VehicleSelectionForms
                            walkOnCode={vehicleTypes.VEHWLK}
                            allowOneWayWalkOn={allowOneWayWalkOn}
                            clientContactPhoneNumber={
                              configData?.clientContactPhoneNumber
                            }
                            selectedDepartureHeightFilter={
                              selectedDepartureHeightFilter
                            }
                            selectedDepartureLengthFilter={
                              selectedDepartureLengthFilter
                            }
                            selectedReturnHeightFilter={
                              selectedReturnHeightFilter
                            }
                            selectedReturnLengthFilter={
                              selectedReturnLengthFilter
                            }
                            departureVehicleLocked={
                              modifyFlowType === ModifyFlowType.DEPARTURE_LOCKED
                            }
                            activeSelection={bringingAVehicleSelection}
                            labels={labels}
                            crossingData={crossingData}
                            isLoading={isLoading}
                            handleChange={handleChange}
                            handleVehicleSelection={handleVehicleSelection}
                            formValues={formValues}
                            renderReturnVehicleForm={renderReturnVehicleForm}
                            departureVehicleErrors={departureVehicleErrors}
                            returnVehicleErrors={returnVehicleErrors}
                            modalIsOpen={modalIsOpen}
                            openModal={openModal}
                            closeModal={closeModal}
                            vehicleOptions={vehicleOptions}
                            language={language}
                            tooltips={tooltips}
                            tooltipIcons={tooltipIcons}
                          />
                        )
                      }
                    </ModalConsumer>
                  )}
                </span>
              </Fragment>

              {userSelections.promoCode && labels.promoDiscount && (
                <PageWarningBanner
                  message={labels.promoDiscount}
                  customClass="reminder"
                  centered={true}
                >
                  {labels.promoDiscount}
                </PageWarningBanner>
              )}
            </div>
          )}

          <div className="passenger-vehicles__buttons u-page-submit-container">
            {!isLoading && (
              <button
                disabled={renderContinueDisabled()}
                className="btn btn-primary large-primary-btn"
                onClick={() => submit()}
              >
                {labels.continueToSailingsBtn} →
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

VehicleSelectionContainer.propTypes = {
  handleRouteChange: func.isRequired
}

export default VehicleSelectionContainer
