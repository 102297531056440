import { createSelector } from 'reselect'
import { selectFerryRouteSelectionsState } from '../ferryRouteSelections/selectors'
import { selectLabels } from '../configuration/selectors'

export const selectPassengerInputLabels = createSelector(
  selectFerryRouteSelectionsState,
  selectLabels,
  (ferryRouteSelectionsState, labels) => {
    const result = {
      adult: {
        type: labels.adults,
        description: labels.adultAgeDesc
      },
      senior: {
        type: labels.seniors,
        description: labels.seniorAgeDesc
      },
      child: {
        type: labels.children,
        description: labels.childAgeDesc
      },
      infant: {
        type: labels.infants,
        description: labels.infantAgeDesc
      },
      student: {
        type: labels.students,
        description: labels.studentAgeDesc
      },
      pet: {
        type: labels.petLabel,
        description: labels.petPassenger
      }
    }

    return result
  }
)
