import React from 'react'
import { oneOfType, bool, func, string } from 'prop-types'
import { useSelector } from 'react-redux'
import { appRoutes } from '../../configuration/constants'
import { selectLabels, selectFields } from '../../redux/configuration/selectors'
import {
  getInitialValuesFromFields,
  filterFieldsByName,
  getSchemaFromFieldDetails
} from '../../redux/configuration/utilities'
import ModalForm from '../ModalForm'
import LoginForm from '../LoginForm/LoginForm'
import { agentFieldNames, fieldNames } from './constants'

const LoginModal = ({
  isTravelAgentLoginModal,
  isNoRegisterFormVariant,
  isOpen,
  loginModalType,
  formVariant,
  formTitle,
  handleRouteChange,
  closeModal,
  attemptingLogin,
  canUseApp,
  loginError,
  onValidSubmit,
  modalContinue,
  registerLink,
  onRegisterLinkClick
}) => {
  // TODO: create a LoginFormContainer for this
  const labels = useSelector(selectLabels)
  const fields = useSelector(selectFields)
  const loginFormDetails = filterFieldsByName(
    fields,
    isTravelAgentLoginModal ? agentFieldNames : fieldNames
  )
  const initialValues = getInitialValuesFromFields(loginFormDetails)
  const validationSchema = getSchemaFromFieldDetails(loginFormDetails)
  const formHelpLinks = {
    password: {
      link: appRoutes.forgotPassword.pathname,
      linkClass: 'form-input-link',
      linkLabel: labels.forgotPassword
    }
  }

  return (
    <>
      <ModalForm
        modalClasses={
          isNoRegisterFormVariant ? 'modal--lg modal-two-col' : 'modal--sm'
        }
        isOpen={isOpen}
        hideCloseButton={isNoRegisterFormVariant}
        closeModal={() =>
          closeModal({ type: loginModalType, variant: formVariant })
        }
        formTitle={formTitle}
      >
        {isNoRegisterFormVariant && (
          <div className="modal-two-col-header">
            <h4 className="form-header-title theme-font-header">
              {labels.loginToContinue}
            </h4>
            <p>{labels.accountRequired}</p>
          </div>
        )}
        <div className="modal-row">
          <div className="modal-col modal-col-form">
            {isNoRegisterFormVariant && (
              <h4 className="modal-col-title center-text theme-font-header">
                {labels.existingUserLogin}
              </h4>
            )}
            <LoginForm
              loginModalType={loginModalType}
              variant={formVariant}
              labels={labels}
              fields={fields}
              closeModal={() =>
                closeModal({ type: loginModalType, variant: formVariant })
              }
              disabled={attemptingLogin || !canUseApp}
              loginError={loginError}
              onValidSubmit={onValidSubmit}
              loginFormDetails={loginFormDetails}
              initialValues={initialValues}
              validationSchema={validationSchema}
              noRegisterVariant={isNoRegisterFormVariant}
              formHelpLinks={formHelpLinks}
              onRegisterLinkClick={onRegisterLinkClick}
              registerLink={registerLink}
            />
          </div>
          {isNoRegisterFormVariant && (
            <div className="modal-col">
              <div className="modal-col-content">
                <span className="modal-col-content-divider" />
                <h4 className="modal-col-title center-text theme-font-header">
                  {labels.noAccount}
                </h4>
                <p>{labels.createOnNextScreen}</p>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    modalContinue({
                      type: loginModalType,
                      variant: formVariant,
                      pathname: appRoutes.passengerDetails.pathname
                    })
                  }}
                >
                  {labels.continueBtn}
                </button>
                <span className="modal-col-content-divider" />
              </div>
            </div>
          )}
        </div>
      </ModalForm>
    </>
  )
}

LoginModal.propTypes = {
  isTravelAgentLoginModal: bool,
  isNoRegisterFormVariant: bool,
  isOpen: bool,
  loginModalType: string.isRequired,
  formVariant: string.isRequired,
  formTitle: oneOfType([bool, string]),
  handleRouteChange: func.isRequired,
  closeModal: func.isRequired,
  attemptingLogin: bool,
  canUseApp: bool,
  loginError: string,
  onValidSubmit: func.isRequired,
  modalContinue: func.isRequired,
  registerLink: string,
  onRegisterLinkClick: func
}

export default LoginModal
