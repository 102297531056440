import React from 'react'
import { func, node, shape, string } from 'prop-types'
import ConfigConsumer from '../../redux/configuration/context'
import SvgIcon from '../SvgIcon/SvgIcon'
import { formVariants } from '../LoginModal/constants'
import './LoginFromStep.scss'

const LoginFromStep = ({
  openModal,
  onLoginClick,
  labels,
  loginLabel,
  additionalInfo
}) => (
  <div className="form-profile">
    <div
      onClick={() => {
        onLoginClick && onLoginClick()
        openModal({ type: formVariants.CUSTOMER_LOGIN })
      }}
      className="form-profile-toggle theme-content-variant"
      tabIndex={0}
    >
      <ConfigConsumer>
        {({ themeVariables }) => (
          <SvgIcon
            className="profile-icon"
            type="profile"
            fill={themeVariables.themeIconColor}
          />
        )}
      </ConfigConsumer>
      <span className="theme-bold-link">{labels.login}</span>
      {` ${loginLabel}`}
    </div>
    {additionalInfo && <p className="form-profile-info">{additionalInfo}</p>}
  </div>
)

LoginFromStep.propTypes = {
  openModal: func.isRequired,
  labels: shape().isRequired,
  loginLabel: string.isRequired,
  onLoginClick: func,
  additionalInfo: node
}

export default LoginFromStep
