import { createSelector } from 'reselect'
import { vehicleTypes } from '../vehicleSelections/constants'
import selectVehicleFormType from './selectVehicleFormType'

const selectIsWalkOn = createSelector(
  selectVehicleFormType,
  vehicleFormType => {
    return vehicleFormType === vehicleTypes.VEHWLK
  }
)

export default selectIsWalkOn
