import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// ************ CONSTANTS *************
import {
  ENABLE_CHASE_CHECKOUT,
  ENABLE_MONERIS_CHECKOUT
} from '../../configuration/constants'
// ************ ACTIONS *************
import { clearTelephoneBookingData } from '../../redux/telephoneBooking/actions'
// *********** COMPONENTS ***********
import MonerisCheckoutConfirmationContainer from '../../components/MonerisCheckoutConfirmationContainer/MonerisCheckoutConfirmationContainer'
import MonerisConfirmationContainer from '../../components/MonerisConfirmationContainer/MonerisConfirmationContainer'
import PayPalConfirmation from '../../components/PayPalConfirmation/PayPalConfirmationContainer'
import ChaseCheckoutConfirmationContainer from '../../components/ChaseCheckoutConfirmationContainer/ChaseCheckoutConfirmationContainer'
// ********* SELECTORS **************
import { selectBookingConfirmationData } from '../../redux/booking/selectors'
import { selectTelephoneBookingData } from '../../redux/telephoneBooking/selectors'
import {
  selectConfirmationContent,
  selectLabels
} from '../../redux/configuration/selectors'
import {
  selectPaymentConfirmationData,
  selectPaymentFerryId,
  selectPayPalPaymentConfirmationData
} from '../../redux/paymentConfirmation/selectors'
import { selectLanguage } from '../../redux/session/selectors'
// *********** STYLES ***************
import '../../styles/scss/components/booking-confirmation/booking-confirmation.scss'

const BookingConfirmationContainer = ({ handleRouteChange }) => {
  const dispatch = useDispatch()
  const labels = useSelector(selectLabels)
  const lang = useSelector(selectLanguage)
  const additionalContent = useSelector(selectConfirmationContent)
  const bookingConfirmationData = useSelector(selectBookingConfirmationData)
  const paymentConfirmationData = useSelector(selectPaymentConfirmationData)
  const telephoneBookingData = useSelector(selectTelephoneBookingData)
  const ferryId = useSelector(selectPaymentFerryId)
  const payPalPaymentData = useSelector(selectPayPalPaymentConfirmationData)

  // Clear telephone booking on confirmation unmount
  useEffect(() => {
    try {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'analytics-confirmation-1',
        details: bookingConfirmationData ?? telephoneBookingData
      })
    } catch (err) {
      console.log('Analytics error: ' + err)
    }

    return () => {
      dispatch(clearTelephoneBookingData())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (payPalPaymentData) {
    return (
      <PayPalConfirmation
        bookingConfirmationData={bookingConfirmationData}
        paymentConfirmationData={paymentConfirmationData}
        payPalTransactionData={payPalPaymentData}
        lang={lang}
        labels={labels}
        additionalContent={additionalContent}
        handleRouteChange={handleRouteChange}
      />
    )
  }

  const renderCheckoutConfirmation = () => {
    if (ENABLE_CHASE_CHECKOUT) {
      return (
        <ChaseCheckoutConfirmationContainer
          lang={lang}
          labels={labels}
          additionalContent={additionalContent}
          handleRouteChange={handleRouteChange}
          bookingConfirmationData={
            bookingConfirmationData ?? telephoneBookingData
          }
          isTelephoneBooking={!!telephoneBookingData}
          ferryId={ferryId}
        />
      )
    } else if (ENABLE_MONERIS_CHECKOUT) {
      return (
        <MonerisCheckoutConfirmationContainer
          lang={lang}
          labels={labels}
          additionalContent={additionalContent}
          handleRouteChange={handleRouteChange}
          bookingConfirmationData={
            bookingConfirmationData ?? telephoneBookingData
          }
          isTelephoneBooking={!!telephoneBookingData}
          ferryId={ferryId}
        />
      )
    } else {
      return (
        <MonerisConfirmationContainer
          lang={lang}
          labels={labels}
          additionalContent={additionalContent}
          handleRouteChange={handleRouteChange}
          bookingConfirmationData={
            bookingConfirmationData ?? telephoneBookingData
          }
          ferryId={ferryId}
        />
      )
    }
  }

  return renderCheckoutConfirmation()
}

export default BookingConfirmationContainer
