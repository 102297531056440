import { put, delay, takeEvery } from 'redux-saga/effects'
import { resetSendEmailConfirmationState } from './actions'
import {
  CLEAR_CUSTOMER_ACCOUNT,
  SEND_EMAIL_CONFIRMATION_SUCCESS,
  SEND_EMAIL_CONFIRMATION_ERROR
} from './constants'
import { clearActiveModifyBooking } from '../modifyBooking/actions'
import { clearUserBookings } from '../userBookings/actions'

function* clearUserDataOnLogout() {
  yield put(clearActiveModifyBooking())
  yield put(clearUserBookings())
}

function* enableEmailConfirmationButtonAfterDelay() {
  yield delay(20000)
  yield put(resetSendEmailConfirmationState())
}

function* userSaga() {
  yield takeEvery(CLEAR_CUSTOMER_ACCOUNT, clearUserDataOnLogout)
  yield takeEvery(
    [SEND_EMAIL_CONFIRMATION_SUCCESS, SEND_EMAIL_CONFIRMATION_ERROR],
    enableEmailConfirmationButtonAfterDelay
  )
}

export default userSaga
