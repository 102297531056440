import React from 'react'
import { bool, func, shape } from 'prop-types'

const SamePassengersToggle = ({ isSamePassengers, handleToggle, labels }) => {
  return (
    <div className="passenger-quantities__duplicate-form">
      <h5 className="theme-font-header" id="same-passengers-header">
        {labels.passOnSameJourneyMsg}
      </h5>

      <div className="passenger-quantities__duplicate-form-toggles">
        <div
          className={`passenger-quantities__duplicate-form-toggle enable${
            isSamePassengers ? ' active' : ''
          }`}
          onClick={() => handleToggle(true)}
          tabIndex={0}
          aria-label={`${labels.passOnSameJourneyMsg} ${labels.yes}`}
        >
          {labels.yes}
        </div>
        <div
          className={`passenger-quantities__duplicate-form-toggle disable${
            !isSamePassengers ? ' active' : ''
          }`}
          onClick={() => handleToggle(false)}
          tabIndex={0}
          aria-label={`${labels.passOnSameJourneyMsg} ${labels.no}`}
        >
          {labels.no}
        </div>
      </div>
    </div>
  )
}

SamePassengersToggle.propTypes = {
  isSamePassengers: bool,
  handleToggle: func.isRequired,
  labels: shape({})
}

export default SamePassengersToggle
