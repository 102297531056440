import React, { Fragment } from 'react'
import { string, shape, func, bool } from 'prop-types'

import SvgIcon from '../SvgIcon/SvgIcon'
import appRoutes from '../../configuration/appRoutes'
import { formVariants } from '../LoginModal/constants'
import ConfigConsumer from '../../redux/configuration/context'

const HeaderProfileToggles = ({
  ferryLabels,
  handleRouteChange,
  openModal,
  agentProfile,
  isLoggedIn,
  activeUserName,
  onLogout,
  loginButtonLabel
}) => {
  const modalType = agentProfile
    ? formVariants.TRAVEL_AGENT_LOGIN
    : formVariants.CUSTOMER_LOGIN

  return (
    <div className="header__profile header__content-item">
      <span className="header-toggle header__content-item">
        {isLoggedIn ? (
          <Fragment>
            <button
              onClick={() => {
                handleRouteChange(appRoutes.account.pathname)
              }}
              className="header-toggle__button profile-login"
            >
              <ConfigConsumer>
                {({ themeVariables }) => (
                  <SvgIcon
                    className="header__profile-icon"
                    type="profile"
                    fill={themeVariables.themeHeaderIconColor}
                    allowFocus={false}
                  />
                )}
              </ConfigConsumer>
              {activeUserName}
            </button>
            <button
              onClick={onLogout}
              className="header-toggle__button profile-register"
            >
              {ferryLabels.logout}
            </button>
          </Fragment>
        ) : (
          <Fragment>
            <button
              onClick={() => {
                openModal({ type: modalType })
              }}
              className="header-toggle__button profile-login"
            >
              {!agentProfile && (
                <ConfigConsumer>
                  {({ themeVariables }) => (
                    <SvgIcon
                      className="header__profile-icon"
                      type="profile"
                      fill={themeVariables.themeHeaderIconColor}
                      allowFocus={false}
                    />
                  )}
                </ConfigConsumer>
              )}
              {loginButtonLabel}
            </button>
            {!agentProfile && (
              <button
                onClick={() => handleRouteChange(appRoutes.register.pathname)}
                className="header-toggle__button profile-register"
              >
                {ferryLabels.register}
              </button>
            )}
          </Fragment>
        )}
      </span>
    </div>
  )
}

HeaderProfileToggles.propTypes = {
  ferryLabels: shape().isRequired,
  handleRouteChange: func.isRequired,
  openModal: func.isRequired,
  agentProfile: bool,
  isLoggedIn: bool,
  activeUserName: string,
  onLogout: func.isRequired,
  loginButtonLabel: string
}

HeaderProfileToggles.defaultProps = {
  agentProfile: false,
  isLoggedIn: false,
  activeUserName: '',
  loginButtonLabel: ''
}

export default HeaderProfileToggles
