import { createSelector } from 'reselect'
import { ENABLE_ACCOMMODATIONS } from '../../configuration/constants'
import { activeAccommodationsInitialState } from './reducer'

export const selectActiveAccommodations = state =>
  ENABLE_ACCOMMODATIONS
    ? state.activeAccommodations
    : { ...activeAccommodationsInitialState }

export const selectActiveDepartureAccommodations = createSelector(
  selectActiveAccommodations,
  selectActiveAccommodations => selectActiveAccommodations.departure
)

export const selectActiveReturnAccommodations = createSelector(
  selectActiveAccommodations,
  selectActiveAccommodations => selectActiveAccommodations.return
)
