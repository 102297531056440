import React, { useState } from 'react'
import { object, bool, func, array } from 'prop-types'
import Modal from '../Modal'
import Checkbox from '../Checkbox/Checkbox'
import { renderContentValues } from '../../redux/configuration/utilities'
import { capitalizeFirstLetter } from '../../configuration/utilities'

import './AccessibilityModal.scss'

const AccessibilityModal = ({
  labels,
  isOpen,
  options,
  values,
  contentValues,
  onSave,
  onRequestClose
}) => {
  const [checkedValues, setCheckedValues] = useState(values)
  const onNewValue = (key, isChecked) => {
    const copy = { ...checkedValues }
    copy[key] = isChecked
    setCheckedValues(copy)
  }
  const inputs = options.map(({ code, description }) => {
    const isSelected = !!checkedValues[code]
    return (
      <div key={`acc_${code}`}>
        <Checkbox
          name={code}
          value={isSelected}
          label={labels[code]}
          disabled={false}
          onChange={v => onNewValue(code, v.target.checked)}
          formikCheckbox={false}
        />
      </div>
    )
  })

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="booking-details-modal u-container"
    >
      <div className="accessibility">
        <div
          className="accessibility__close"
          onClick={onRequestClose}
        >{`${capitalizeFirstLetter(labels?.close)} X`}</div>
        {contentValues?.length > 0 && renderContentValues(contentValues)}
        <div className="accessibility__option-grid">{inputs}</div>
        <button
          className="btn btn-primary"
          onClick={() => {
            onSave(checkedValues)
            onRequestClose()
          }}
        >
          <span className="accessibility__save">{labels.saveAndClose}</span>
        </button>
      </div>
    </Modal>
  )
}

AccessibilityModal.defaultProps = {
  contentValues: []
}

AccessibilityModal.propTypes = {
  labels: object.isRequired,
  isOpen: bool.isRequired,
  options: array.isRequired,
  values: object.isRequired,
  contentValues: array,
  onSave: func.isRequired,
  onRequestClose: func.isRequired
}

export default AccessibilityModal
