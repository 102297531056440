import { handleActions } from 'redux-actions'
import {
  SET_ACTIVE_BOOKING,
  FETCH_CANCELLATION_DATA,
  RECEIVE_CANCELLATION_DATA,
  CANCELLATION_DATA_ERROR,
  CLEAR_MODIFY_BOOKING
} from './constants'

const modifyBookingInitialState = {
  activeBooking: null,
  cancelBookingDetails: {}
}

const modifyBookingReducer = handleActions(
  {
    [SET_ACTIVE_BOOKING]: (state, action) => ({
      ...state,
      activeBooking: action.payload
    }),
    [FETCH_CANCELLATION_DATA]: (state, action) => ({
      ...state,
      cancelBookingDetails: {
        ...state.cancelBookingDetails,
        [action.payload.id]: {
          ...state.cancelBookingDetails[action.payload.id],
          loading: true
        }
      }
    }),
    [RECEIVE_CANCELLATION_DATA]: (state, action) => ({
      ...state,
      cancelBookingDetails: {
        ...state.cancelBookingDetails,
        [action.payload.id]: {
          ...state.cancelBookingDetails[action.payload.id],
          loading: false,
          data: action.payload.data
        }
      }
    }),
    [CANCELLATION_DATA_ERROR]: (state, action) => ({
      ...state,
      cancelBookingDetails: {
        ...state.cancelBookingDetails,
        [action.payload.id]: {
          ...state.cancelBookingDetails[action.payload.id],
          loading: false,
          error: action.payload.error
        }
      }
    }),
    [CLEAR_MODIFY_BOOKING]: state => ({
      ...state,
      activeBooking: null
    })
  },
  { ...modifyBookingInitialState }
)

export { modifyBookingInitialState }
export default modifyBookingReducer
