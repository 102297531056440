import React, { useEffect } from 'react'
import { func, shape } from 'prop-types'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import queryString from 'query-string'

import appRoutes, {
  ferryRoutes,
  passengerQuantities,
  accommodations,
  departureRoute,
  returnRoute,
  vehicle,
  extras,
  summary,
  passengerDetails,
  bookingConfirmation
} from '../../configuration/appRoutes'

import AccountContainer from './AccountContainer'
import FerryRoutesContainer from './FerryRoutesContainer'
import ForgotPasswordContainer from './ForgotPasswordContainer'
import UpdatePasswordContainer from './UpdatePasswordContainer'
import BookingConfirmationContainer from './BookingConfirmationContainer'
import BookingInformationContainer from './BookingInformationContainer'
import PaymentContainer from './PaymentContainer'
import ExtrasContainer from './ExtrasContainer'
import PassengerQuantitiesContainer from './PassengerQuantitiesContainer'
import RegisterContainer from './RegisterContainer'
import VehicleSelectionContainer from './VehicleSelectionContainer'
import SummaryContainer from './SummaryContainer'
import ModifyLoginContainer from './ModifyLoginContainer'

import DepartureRouteSelection from './DepartureRouteSelection'
import ReturnRouteSelection from './ReturnRouteSelection'
import AccommodationsContainer from './AccommodationsContainer'
import { validateRoute } from '../../redux/selectors/utils'
import { cleanRedux } from '../../redux/actions'
import TelephoneBookingContainer from './TelephoneBookingsContainer'
import BookingSuccessConfirmation from './BookingSuccessConfirmation'
import BookingFailureConfirmation from './BookingFailureConfirmation'

const Views = ({
  handleRouteChange,
  openModal,
  closeModal,
  changeModals,
  modalIsOpen,
  handleToggleLanguage
}) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { routeIsValid, lastValidRoute } = useSelector(state =>
    validateRoute(location.pathname, state)
  )
  const ContainerComponentWithVersion = {
    [ferryRoutes.pathname]: FerryRoutesContainer,
    [passengerQuantities.pathname]: PassengerQuantitiesContainer,
    [accommodations.pathname]: AccommodationsContainer,
    [departureRoute.pathname]: DepartureRouteSelection,
    [returnRoute.pathname]: ReturnRouteSelection,
    [vehicle.pathname]: VehicleSelectionContainer,
    [extras.pathname]: ExtrasContainer,
    [summary.pathname]: SummaryContainer,
    [passengerDetails.pathname]: BookingInformationContainer,
    [bookingConfirmation.pathname]: BookingConfirmationContainer
  }
  const getContainerByPath = (path, props = { handleRouteChange }) => {
    const Container = ContainerComponentWithVersion[path]
    return <Container {...props} />
  }

  useEffect(() => {
    dispatch(cleanRedux(location.pathname))
  }, [location.pathname, dispatch])

  return (
    <Switch>
      {!routeIsValid && (
        <Redirect
          to={{
            ...location,
            pathname: lastValidRoute
          }}
        />
      )}
      <Route exact path={appRoutes.register.pathname}>
        <RegisterContainer handleRouteChange={handleRouteChange} />
      </Route>
      <Route exact path={appRoutes.account.pathname}>
        <AccountContainer
          handleRouteChange={handleRouteChange}
          openModal={openModal}
          closeModal={closeModal}
          modalIsOpen={modalIsOpen}
        />
      </Route>
      <Route exact path={appRoutes.forgotPassword.pathname}>
        <ForgotPasswordContainer
          handleRouteChange={handleRouteChange}
          closeModal={closeModal}
          modalIsOpen={modalIsOpen}
        />
      </Route>
      <Route
        exact
        path={appRoutes.updatePassword.pathname}
        render={({ match }) =>
          !(match?.params?.emailToken && match?.params?.languageCode) ? null : (
            <UpdatePasswordContainer
              handleRouteChange={handleRouteChange}
              handleToggleLanguage={handleToggleLanguage}
              emailToken={match?.params?.emailToken}
              languageCode={match?.params?.languageCode}
            />
          )
        }
      />
      <Route
        exact
        path={ferryRoutes.pathname}
        render={({ match }) =>
          getContainerByPath(match.path, {
            handleRouteChange,
            openModal,
            closeModal,
            modalIsOpen
          })
        }
      />
      <Route
        exact
        path={passengerQuantities.pathname}
        render={({ match }) => getContainerByPath(match.path)}
      />
      <Route
        exact
        path={appRoutes.extras.pathname}
        render={({ match }) => getContainerByPath(match.path)}
      />
      <Route
        exact
        path={vehicle.pathname}
        render={({ match }) => getContainerByPath(match.path)}
      />
      <Route
        exact
        path={departureRoute.pathname}
        render={({ match }) => getContainerByPath(match.path)}
      />
      <Route
        exact
        path={returnRoute.pathname}
        render={({ match }) => getContainerByPath(match.path)}
      />
      <Route
        exact
        path={accommodations.pathname}
        render={({ match }) => getContainerByPath(match.path)}
      />
      <Route
        exact
        path={summary.pathname}
        render={({ match }) => {
          const props = {
            handleRouteChange,
            openModal,
            closeModal,
            modalIsOpen,
            changeModals,
            searchParams: queryString.parse(window.location.search),
            shouldAllowMonerisRetry:
              process.env.REACT_APP_SHOULD_ALLOW_MONERIS_RETRY,
            allowAccountsLinkOnMonerisFailure:
              process.env.REACT_APP_ALLOW_ACCOUNTS_LINK_ON_MONERIS_FAILURE,
            brokenBookingNumberThreshold:
              process.env.REACT_APP_BROKEN_BOOKING_NUMBER_THRESHOLD,
            clientId: process.env.REACT_APP_FLOW_CLIENT_ID
          }
          return getContainerByPath(match.path, props)
        }}
      />
      <Route
        exact
        path={appRoutes.passengerDetails.pathname}
        render={({ match }) => {
          const props = {
            handleRouteChange,
            openModal,
            closeModal,
            modalIsOpen
          }
          return getContainerByPath(match.path, props)
        }}
      />
      <Route exact path={appRoutes.payment.pathname}>
        <PaymentContainer
          handleRouteChange={handleRouteChange}
          openModal={openModal}
          modalIsOpen={modalIsOpen}
        />
      </Route>
      {/* booking confirmation page */}
      <Route
        exact
        path={bookingConfirmation.pathname}
        render={({ match }) => getContainerByPath(match.path)}
      />
      <Route exact path={appRoutes.bookingSuccess.pathname}>
        <BookingSuccessConfirmation handleRouteChange={handleRouteChange} />
      </Route>
      <Route exact path={appRoutes.telephoneBooking.pathname}>
        <TelephoneBookingContainer
          handleRouteChange={handleRouteChange}
          openModal={openModal}
          closeModal={closeModal}
          modalIsOpen={modalIsOpen}
        />
      </Route>
      <Route exact path={appRoutes.paymentError.pathname}>
        <BookingFailureConfirmation handleRouteChange={handleRouteChange} />
      </Route>
      <Route exact path={appRoutes.modifyBooking.pathname}>
        <ModifyLoginContainer handleRouteChange={handleRouteChange} />
      </Route>
      {/* lastly, no path to match */}
      <Redirect
        to={{
          ...location,
          pathname: appRoutes.ferryRoutes
        }}
      />
    </Switch>
  )
}

Views.propTypes = {
  handleRouteChange: func.isRequired,
  openModal: func.isRequired,
  closeModal: func.isRequired,
  modalIsOpen: shape().isRequired,
  handleToggleLanguage: func.isRequired
}

export default Views
