import React from 'react'
import { useSelector } from 'react-redux'
import { string, number, object, oneOfType, bool, func } from 'prop-types'

import { selectLabels } from '../../../redux/configuration/selectors'
import { formatPriceByLanguage } from '../../../redux/configuration/utilities'
import { PASSENGER_SELECT_DISPLAY_FREE } from '../../../configuration/constants'
import LoadingDots from '../../LoadingDots/LoadingDots'
import { selectLanguage } from '../../../redux/session/selectors'

const PassengerQuantitiesToggleField = ({
  ageLabel,
  maxReached,
  onNewQuantity,
  passengerFieldValue,
  passengerTypeData,
  typeLabel,
  bestRate,
  size,
  isLoading
}) => {
  const labels = useSelector(selectLabels)
  const lang = useSelector(selectLanguage)

  const showVariablePriceLabel = true

  let isRemoveDisabled = false

  if (passengerFieldValue === 0) {
    isRemoveDisabled = true
  }

  let formattedPrice =
    bestRate !== undefined ? formatPriceByLanguage(bestRate, lang) : null

  let priceDisplayString = formattedPrice
  let showFeesLabel = true

  if (+bestRate === 0 && PASSENGER_SELECT_DISPLAY_FREE) {
    priceDisplayString = labels.free.toUpperCase()
    showFeesLabel = false
  } else if (showVariablePriceLabel) {
    const priceLabel = `${formattedPrice}`
    priceDisplayString = (
      <>
        <span className={`price__prefix-label`}>{labels.startingFrom}</span>
        {priceLabel}
      </>
    )
  } else {
    priceDisplayString = `${formattedPrice}`
  }

  let containerClassName = 'passenger-quantities__toggle-field'
  switch (size) {
    case 'small':
      containerClassName += ' sm'
      break
    default:
      break
  }

  return (
    <div
      className={`${containerClassName}${
        passengerTypeData.code ? ' ' + passengerTypeData.code : ''
      }`}
    >
      <div className="passenger-quantities__toggle-field-headings">
        <h6 className="heading theme-title-variant-eight">{typeLabel}</h6>
        <p className="subheading theme-content-variant">{ageLabel}</p>
      </div>

      <div className="passenger-quantities__toggle-field-input">
        <button
          className="passenger-quantities__toggle-field-button decrease"
          onClick={() => onNewQuantity(passengerFieldValue - 1)}
          disabled={isRemoveDisabled}
          aria-label={`Reduce ${typeLabel} ${ageLabel}`}
        >
          <i className="material-icons">remove</i>
        </button>
        <div className="passenger-quantities__toggle-field-value">
          <p>{passengerFieldValue}</p>
        </div>
        <button
          className="passenger-quantities__toggle-field-button increase"
          onClick={() => onNewQuantity(passengerFieldValue + 1)}
          disabled={maxReached}
          aria-label={`Increase ${typeLabel} ${ageLabel}`}
        >
          <i className="material-icons">add</i>
        </button>
      </div>

      <div className="passenger-quantities__toggle-field-pricing">
        {isLoading ? (
          <LoadingDots />
        ) : (
          <p className="price theme-title-variant-eight">
            {priceDisplayString}
          </p>
        )}
        <p className="taxes-fees theme-content-variant">
          {showFeesLabel ? labels.taxFeeDesc : ' '}
        </p>
      </div>
    </div>
  )
}

PassengerQuantitiesToggleField.defaultProps = {
  formHeading: ''
}

PassengerQuantitiesToggleField.propTypes = {
  ageLabel: string.isRequired,
  maxReached: bool.isRequired,
  onNewQuantity: func.isRequired,
  passengerFieldValue: number.isRequired,
  passengerTypeData: object.isRequired,
  typeLabel: string.isRequired,
  bestRate: oneOfType([string, number]),
  size: string,
  isLoading: bool
}

export default PassengerQuantitiesToggleField
