import React from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'

import { MiniSpinner } from '../LoadingSpinner/LoadingSpinner'

import { selectLabels } from '../../redux/configuration/selectors'

const FindReservationForm = ({ findHandler, findErrorMessage }) => {
  const labels = useSelector(selectLabels)
  const initValues = { email: '', reservationId: '', workPhoneNumber: '' }

  const validators = values => {
    const errors = {}
    if (!values.email && !values.workPhoneNumber) {
      errors.email = 'Work Phone Number or Email Required'
    } else if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address'
    }
    if (!values.reservationId) {
      errors.reservationId = 'Booking Reference ID Required'
    }
    return errors
  }

  return (
    <Formik
      initialValues={initValues}
      validate={validators}
      onSubmit={findHandler}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form className="mini-form find-reservation" onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            placeholder="EMAIL ADDRESS"
            className="text-input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          <span className="error">
            {errors.email && touched.email && errors.email}
          </span>
          <input
            type="workPhoneNumber"
            name="workPhoneNumber"
            placeholder="HOME PHONE NUMBER"
            className="text-input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.workPhoneNumber}
          />
          <span className="error">
            {errors.workPhoneNumber &&
              touched.workPhoneNumber &&
              errors.workPhoneNumber}
          </span>
          <input
            type="text"
            name="reservationId"
            placeholder="BOOKING REFERENCE"
            className="text-input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.reservationId}
          />
          <span className="error">
            {errors.reservationId &&
              touched.reservationId &&
              errors.reservationId}
          </span>
          {findErrorMessage && (
            <span className="error">{findErrorMessage}</span>
          )}
          <button
            type="submit"
            className="btn btn-primary wider bold center-self submit-btn"
            disabled={isSubmitting}
          >
            {labels.findReservation}
            {isSubmitting && <MiniSpinner />}
          </button>
        </form>
      )}
    </Formik>
  )
}

export default FindReservationForm
