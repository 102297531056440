import React from 'react'

export const fieldNames = ['consent']

export const formLayout = [['consent']]

export const mockCheckboxWithTitleProps = {
  fieldKey: 'consent',
  title: 'Terms & Conditions',
  fieldDetails: {
    consent: {
      value: false,
      name: 'consent',
      type: 'checkbox',
      isRequired: true
    }
  },
  values: {
    consent: false
  },
  setFieldValue: () => true,
  formikCheckbox: false
}

export const mockChildrenProp = () => (
  <span>
    <strong>I agree</strong>
    {` with the `}
    <a
      href="/"
      title="Terms & Conditions"
      onClick={e => {
        e.preventDefault()
      }}
    >
      Terms & Conditions
    </a>
    {` & `}
    <a
      href="/"
      title="Cancellation Policy"
      onClick={e => {
        e.preventDefault()
      }}
    >
      CTMA's Cancellation and Modification Policy
    </a>
  </span>
)
