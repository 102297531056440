import queryString from 'query-string'
import { handleActions } from 'redux-actions'
import languages from '../../configuration/languages'
import {
  SET_SESSION_ID,
  SET_SESSION_CURRENCY,
  SET_SESSION_LANGUAGE,
  SET_LATEST_BOOKING_NUMBER,
  START_SESSION_TIMER,
  RESET_START_SESSION_TIMER
} from './constants'
import { DEFAULT_LANG } from '../user/constants'

const queryParams = queryString.parse(window.location.search)

const langParam = queryParams?.language || queryParams?.l || queryParams?.lang

const isFrench =
  window?.location?.origin === process.env.REACT_APP_BOOKING_BASE_FR &&
  process.env.REACT_APP_BOOKING_BASE_FR !== process.env.REACT_APP_BOOKING_BASE

const lang =
  langParam && Object.keys(languages).some(language => language === langParam)
    ? langParam
    : isFrench
    ? 'fr'
    : DEFAULT_LANG

const sessionDataInitialState = {
  sessionId: null,
  currency: 'CAD',
  language: lang,
  startSessionTimer: false,
  latestBookingNumber: null
}

const sessionDataReducer = handleActions(
  {
    [SET_SESSION_ID]: (state, { payload }) => {
      const copiedState = {
        ...state
      }
      if (payload) {
        copiedState.sessionId = payload
      }

      return copiedState
    },
    [START_SESSION_TIMER]: state => ({
      ...state,
      startSessionTimer: true
    }),
    [RESET_START_SESSION_TIMER]: state => ({
      ...state,
      startSessionTimer: false
    }),
    [SET_SESSION_LANGUAGE]: (state, { payload }) => {
      const stateCopy = { ...state }
      if (payload) {
        sessionStorage.setItem('language', payload)
        stateCopy.language = payload
      }

      return stateCopy
    },
    [SET_LATEST_BOOKING_NUMBER]: (state, { payload }) => {
      const stateCopy = { ...state }
      if (payload) {
        stateCopy.latestBookingNumber = payload
      }
    },
    [SET_SESSION_CURRENCY]: (state, { payload }) => ({
      ...state,
      currency: payload
    })
  },
  { ...sessionDataInitialState }
)

export { sessionDataInitialState }
export default sessionDataReducer
