import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js'
import 'airbnb-browser-shims'

import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { TrackJS } from 'trackjs'

import App from './containers/structure/App'
import configureStore from './redux/store'
import 'react-dates/initialize'

import './styles/scss/base/base.scss'
import './styles/scss/base/buttons.scss'
import './styles/scss/base/calendar.scss'
import './styles/scss/base/forms.scss'
import './styles/scss/base/modal.scss'
import './styles/scss/base/print.scss'
import './styles/scss/base/typography.scss'
import './styles/scss/base/slider.scss'
import './styles/scss/base/utilities.scss'
import 'react-dates/lib/css/_datepicker.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import BrandLoader from './components/BrandLoader/BrandLoader'

const { store, persistor } = configureStore()

if (
  process.env.REACT_APP_VERB_ENV === 'production' &&
  !!process.env.REACT_APP_TRACKJS_APPLICATION_ID
) {
  TrackJS.install({
    token: '21289748d5e9480ebe3d19f941d1a320',
    application: process.env.REACT_APP_TRACKJS_APPLICATION_ID
  })
}

const renderApp = () =>
  render(
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<BrandLoader />}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </Provider>,
    document.getElementById('ferries__create-reservation')
  )

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./containers/structure/App', renderApp)
}

renderApp()
