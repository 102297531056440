import React from 'react'
import { useSelector } from 'react-redux'
import { oneOfType, bool, string, node, func } from 'prop-types'
import { selectLabels } from '../../redux/configuration/selectors'

const ModalForm = ({
  title,
  closeLabel,
  children,
  closeModal,
  hideCloseButton
}) => {
  const labels = useSelector(selectLabels)
  closeLabel = `${labels.closeLabel} X`
  return (
    <div className="modal-form">
      <div className="modal-form-header">
        {title && (
          <h4 className="form-header-title theme-font-header">{title}</h4>
        )}
        {!hideCloseButton && (
          <button onClick={closeModal} className="form-header-close">
            {closeLabel}
          </button>
        )}
      </div>
      {children}
    </div>
  )
}

ModalForm.propTypes = {
  title: oneOfType([string, bool]),
  closeLabel: string.isRequired,
  closeModal: func.isRequired,
  children: node.isRequired,
  hideCloseButton: bool
}

export default ModalForm
