import React from 'react'

const MapSectionIndicator = ({ currentSectionIndex, sectionCount }) => {
  const sections = Array.from({ length: sectionCount }, (_, index) => index)
  return (
    <div className="map-section-indicator">
      {sections.map((_, index) => {
        const activeClass = currentSectionIndex === index ? 'active' : ''
        return <div key={index} className={`map-section-bar ${activeClass}`} />
      })}
    </div>
  )
}

export default MapSectionIndicator
