import { createSelector } from 'reselect'

const selectAccessibilityState = state => state.accessibility

export const selectAccessibilityResponses = createSelector(
  selectAccessibilityState,
  accessibility => accessibility.responses
)

export const selectAccessibilityResponseCount = createSelector(
  selectAccessibilityResponses,
  responses => {
    return Object.keys(responses).filter(x => !!responses[x]).length
  }
)
