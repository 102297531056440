import React, { useState } from 'react'
import { string, array, bool, func, oneOfType } from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { appRoutes } from '../../configuration/constants'
import { setActiveModificationBooking } from '../../redux/modifyBooking/actions'
import BookingTableRow from './BookingTableRow'
import { selectLabels } from '../../redux/configuration/selectors'
import { commercialRates } from '../../configuration/rateTypes'
import { formatPriceByLanguage } from '../../redux/configuration/utilities'
import { selectLanguage } from '../../redux/session/selectors'
import { resetSendEmailConfirmationState } from '../../redux/user/actions'
import Modal from '../Modal'
import SvgIcon from '../SvgIcon/SvgIcon'
import ConfigConsumer from '../../redux/configuration/context'
import yn from 'yn'

import './BookingsTable.scss'
import '../../styles/scss/components/user-bookings/user-bookings-table.scss'
import { initiateModify } from '../../redux/userSelections/actions'

function setIsOpenBooking(booking) {
  return booking?.departures?.some(departure => !departure.departureTime)
}

function setIsCommercialBooking(booking) {
  return booking?.departures?.some(departure => {
    return departure?.passengers?.booked.some(passenger =>
      commercialRates.some(rate => rate === passenger.ticketType)
    )
  })
}

function renderCouponNumbers(coupons) {
  return coupons.map(coupon => `${coupon.coupon}`).join(',')
}

const BookingsTable = ({
  bookings,
  handleRouteChange,
  setActiveBookingDetails,
  setCancelBookingModalIsOpen,
  setBookingModalIsOpen,
  error,
  openCustomErrorModal,
  isLoading,
  setBookingForPayment
}) => {
  const labels = useSelector(selectLabels)
  const lang = useSelector(selectLanguage)
  const [sortBy, setSortBy] = useState('bookingNumber')
  const [sortAscending, setSortAscending] = useState(false)

  const [
    incompleteBookingInfoModalIsOpen,
    setIncompleteBookingInfoModalIsOpen
  ] = useState(false)

  const [partiallyPaidModalIsOpen, setPartiallyPaidModalIsOpen] = useState(
    false
  )

  const [shouldCallModalIsOpen, setShouldCallModalIsOpen] = useState(false)

  const dispatch = useDispatch()
  if (!bookings) {
    return null
  }

  const handleModifyBookingClick = booking => {
    dispatch(setActiveModificationBooking(booking)) // TODO: deprecate
    dispatch(initiateModify(booking.bookingNumber))

    handleRouteChange(appRoutes.ferryRoutes.pathname)
  }

  const handleViewDetailsClick = booking => {
    setBookingModalIsOpen(true)
    setActiveBookingDetails(booking)
    dispatch(resetSendEmailConfirmationState())
  }

  const handleCancelClick = booking => {
    setCancelBookingModalIsOpen(true)
    setActiveBookingDetails(booking)
  }

  function handleSortChange(newSortKey) {
    if (sortBy !== newSortKey) {
      setSortBy(newSortKey)
      setSortAscending(false)
    } else {
      setSortAscending(!sortAscending)
    }
  }

  let sortedBookings = bookings

  if (sortBy) {
    sortedBookings = bookings.sort((a, b) => {
      if (sortAscending) {
        return a[sortBy] < b[sortBy] ? -1 : 1
      } else {
        return a[sortBy] < b[sortBy] ? 1 : -1
      }
    })
  }

  const renderSortHeader = (sortKey, label) => {
    return (
      <ConfigConsumer>
        {({ themeVariables }) => (
          <th className="sort-header" onClick={() => handleSortChange(sortKey)}>
            <span className="sort-icon">
              <SvgIcon type="sort-arrow" fill={themeVariables.themeIconColor} />
            </span>
            {label}
          </th>
        )}
      </ConfigConsumer>
    )
  }

  return (
    <>
      <div className="u-lgr-max">
        <div className="user-bookings-list">
          {sortedBookings && sortedBookings.length ? (
            sortedBookings.map(booking => {
              const isOpenBooking = setIsOpenBooking(booking)
              const isCommercial = setIsCommercialBooking(booking)
              const hasCoupons = Boolean(booking?.coupons?.length)
              const shouldCall = booking?.shouldCall
              const canEdit = booking?.canEditBooking
              const canViewBooking = booking?.canViewBooking
              const canCancelBooking = booking?.canCancelBooking
              const allowPaymentRetry = booking?.allowPaymentRetry

              return (
                <div
                  key={booking.bookingNumber}
                  className="user-bookings-list-item"
                >
                  {isCommercial && (
                    <div className="user-bookings-list-item-flag">
                      <span>{labels.commercialBooking}</span>
                    </div>
                  )}
                  {hasCoupons && (
                    <div className="user-bookings-list-item-flag">
                      <span>
                        {labels.couponApplied}{' '}
                        {renderCouponNumbers(booking.coupons)}
                      </span>
                    </div>
                  )}
                  <div className="user-bookings-list-item-header">
                    <p>
                      <span>{labels.number}:</span> {booking.bookingNumber}
                    </p>
                  </div>
                  <div className="user-bookings-list-item-line">
                    <p>
                      <span>{labels.createdDate}:</span> {booking.createdDate}
                    </p>
                    {isOpenBooking ? (
                      <p>
                        <span className="flagged-span">
                          {labels.openBooking}
                        </span>
                      </p>
                    ) : (
                      <p>
                        <span>{labels.departureDate}:</span>{' '}
                        {booking.departureDate}
                      </p>
                    )}
                  </div>
                  <div className="user-bookings-list-item-line">
                    <p>
                      <span>{labels.status}:</span>{' '}
                      {labels[booking?.paymentStatus]}
                    </p>

                    {booking?.showTooltipIcon ? (
                      <div className="mobile-tooltip-trigger-container">
                        <p>
                          <span>{labels.bookingStatus}:</span>
                        </p>
                        {labels[booking?.bookingStatus]}{' '}
                        <span
                          className="u-tooltip-trigger"
                          onClick={() => {
                            if (
                              booking?.paymentStatus ===
                              'bookingPaymentCodePartlyPaid'
                            ) {
                              setPartiallyPaidModalIsOpen(true)
                            } else {
                              setIncompleteBookingInfoModalIsOpen(true)
                            }
                          }}
                        >
                          i
                        </span>
                      </div>
                    ) : (
                      <p>
                        <span>{labels.bookingStatus}:</span>
                        {labels[booking?.bookingStatus]}{' '}
                      </p>
                    )}
                  </div>
                  <div className="user-bookings-list-item-line">
                    <p>
                      <span>{labels.total}:</span>{' '}
                      {formatPriceByLanguage(
                        booking?.reservationTotals?.totalWithoutPaid,
                        lang
                      )}
                    </p>
                    <div className="ctas">
                      {allowPaymentRetry &&
                        canCancelBooking &&
                        canEdit &&
                        !shouldCall && (
                          <button
                            className="btn btn-highlight"
                            onClick={() => setBookingForPayment(booking)}
                          >
                            {labels.pay}
                          </button>
                        )}
                      <button
                        className="btn"
                        onClick={() => handleViewDetailsClick(booking)}
                        disabled={!canViewBooking}
                      >
                        {labels.view}
                      </button>
                      <button
                        className="btn"
                        disabled={!canEdit || shouldCall}
                        onClick={() => {
                          if (canEdit && !shouldCall) {
                            handleModifyBookingClick(booking)
                          }
                        }}
                      >
                        {labels[booking?.editButtonTextCode]}
                      </button>
                      <button
                        className="btn"
                        disabled={!canCancelBooking || shouldCall}
                        onClick={() => {
                          if (canCancelBooking && !shouldCall) {
                            handleCancelClick(booking)
                          }
                        }}
                      >
                        {labels[booking.cancelButtonTextCode]}
                      </button>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <>
              <div className="user-bookings-empty-list">
                <p>
                  {error ? labels.fetchUserBookingsError : labels.noSailingsMsg}{' '}
                </p>
                <Link to={appRoutes.ferryRoutes.pathname}>
                  {labels.viewSailingSchedule}
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="u-lgr-up">
        <div className="user-bookings-table">
          <table>
            <thead>
              <tr>
                {renderSortHeader('bookingNumber', labels.number)}
                {renderSortHeader('creationTimeStamp', labels.createdDate)}
                {renderSortHeader('departureDate', labels.departureDate)}
                {renderSortHeader('totalPriceToPay', labels.total)}
                {renderSortHeader('paymentStatus', labels.status)}
                {renderSortHeader('bookingStatus', labels.bookingStatus)}
                <th
                  colSpan={
                    yn(
                      process.env
                        .REACT_APP_SHOULD_ALLOW_PAYMENT_FROM_ACCOUNTS_PAGE
                    )
                      ? '4'
                      : '3'
                  }
                ></th>
              </tr>
            </thead>

            <tbody>
              {sortedBookings.map(booking => {
                const hasCoupons = Boolean(booking?.coupons?.length)
                return (
                  <BookingTableRow
                    key={booking.bookingNumber}
                    booking={booking}
                    handleViewDetailsClick={handleViewDetailsClick}
                    handleModifyBookingClick={handleModifyBookingClick}
                    handleCancelClick={handleCancelClick}
                    isOpenBooking={setIsOpenBooking(booking)}
                    isCommercial={setIsCommercialBooking(booking)}
                    hasCoupons={hasCoupons}
                    couponText={
                      hasCoupons ? renderCouponNumbers(booking.coupons) : null
                    }
                    openIncompleteBookingModal={isPartiallyPaid => {
                      if (isPartiallyPaid) {
                        setPartiallyPaidModalIsOpen(true)
                      } else {
                        setIncompleteBookingInfoModalIsOpen(true)
                      }
                    }}
                    openShouldCallModal={() => setShouldCallModalIsOpen(true)}
                    openCustomErrorModal={openCustomErrorModal}
                    setBookingForPayment={setBookingForPayment}
                  />
                )
              })}
            </tbody>
          </table>
          {!isLoading && !bookings.length && error && (
            <div className="user-bookings-empty-list">
              <p>
                {error ? labels.fetchUserBookingsError : labels.noSailingsMsg}
              </p>
              <Link to={appRoutes.ferryRoutes.pathname}>
                {labels.viewSailingSchedule}
              </Link>
            </div>
          )}
        </div>
      </div>

      <Modal
        isOpen={incompleteBookingInfoModalIsOpen}
        onRequestClose={() => setIncompleteBookingInfoModalIsOpen(false)}
        className="modal--sm incomplete-booking-info-modal"
      >
        <div className={'incomplete-booking-info-modal-content'}>
          <div className={'incomplete-booking-info-modal-header'}>
            <button
              className="btn"
              onClick={() => setIncompleteBookingInfoModalIsOpen(false)}
            >
              {labels.close} X
            </button>
          </div>
          <p>{labels.incompleteBookingWarningCopy}</p>
        </div>
      </Modal>

      <Modal
        isOpen={partiallyPaidModalIsOpen}
        onRequestClose={() => setPartiallyPaidModalIsOpen(false)}
        className="modal--sm incomplete-booking-info-modal"
      >
        <div className={'incomplete-booking-info-modal-content'}>
          <div className={'incomplete-booking-info-modal-header'}>
            <button
              className="btn"
              onClick={() => setPartiallyPaidModalIsOpen(false)}
            >
              {labels.close} X
            </button>
          </div>
          <p>{labels.incompleteBookingWarningPartialPaid}</p>
        </div>
      </Modal>

      <Modal
        isOpen={shouldCallModalIsOpen}
        onRequestClose={() => setShouldCallModalIsOpen(false)}
        className="modal--sm incomplete-booking-info-modal"
      >
        <div className={'incomplete-booking-info-modal-content'}>
          <div className={'incomplete-booking-info-modal-header'}>
            <button
              className="btn"
              onClick={() => setShouldCallModalIsOpen(false)}
            >
              {labels.close} X
            </button>
          </div>
          <p>{labels.bookingCancelModifyCallUs}</p>
        </div>
      </Modal>
    </>
  )
}

BookingsTable.propTypes = {
  bookings: array.isRequired,
  handleRouteChange: func.isRequired,
  setActiveBookingDetails: func.isRequired,
  setCancelBookingModalIsOpen: func.isRequired,
  setBookingModalIsOpen: func.isRequired,
  error: oneOfType([string, bool]).isRequired,
  openCustomErrorModal: func.isRequired,
  isLoading: bool,
  setBookingForPayment: func.isRequired
}

export default BookingsTable
