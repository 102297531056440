export const START_BOOKING_REQUEST = 'booking/START_BOOKING_REQUEST'
export const SET_BOOKING_SUCCESS = 'booking/SET_BOOKING_SUCCESS'
export const SET_BOOKING_ERROR = 'booking/SET_BOOKING_ERROR'
export const RESET_SET_BOOKING = 'booking/RESET_SET_BOOKING'
export const CLEAR_BOOKING_DATA = 'booking/CLEAR_BOOKING_DATA'
export const CLEAR_BOOKING_NUMBER = 'booking/CLEAR_BOOKING_NUMBER'

export const ATTEMPT_PRELOAD_TRANSACTION = 'booking/ATTEMPT_PRELOAD_TRANSACTION'
export const PRELOAD_TRANSACTION_ERROR = 'booking/PRELOAD_TRANSACTION_ERROR'
export const PRELOAD_TRANSACTION_SUCCESS = 'booking/PRELOAD_TRANSACTION_SUCCESS'

export const ATTEMPT_GET_TRANSACTION_RECEIPT =
  'booking/ATTEMPT_GET_TRANSACTION_RECEIPT'
export const GET_TRANSACTION_RECEIPT_ERROR =
  'booking/GET_TRANSACTION_RECEIPT_ERROR'
export const GET_TRANSACTION_RECEIPT_SUCCESS =
  'booking/GET_TRANSACTION_RECEIPT_SUCCESS'

export const CLEAR_TICKET_NUMBERS = 'booking/CLEAR_TICKET_NUMBERS'
export const CLEAR_RECEIPTS = 'booking/CLEAR_RECEIPTS'

export const DEFAULT_BOOKING_ERROR_KEY = 'anErrorOccured'
