import React, { useEffect, useState } from 'react'
import { shape, bool, node, string, func, oneOfType } from 'prop-types'
import AnimateHeight from 'react-animate-height'
import plusIcon from '../../assets/icons/plus.svg'
import minusIcon from '../../assets/icons/minus.svg'

const ExpandableSection = ({
  labels,
  title,
  children,
  disabled,
  onClickDisabled,
  openOnSuccess,
  showLabel,
  hideLabel
}) => {
  const [height, setHeight] = useState(0)

  const toggle = () => {
    if (disabled && onClickDisabled) return onClickDisabled()
    // toggle height
    const newHeight = height === 0 ? 'auto' : 0
    setHeight(newHeight)
  }

  useEffect(() => {
    if (openOnSuccess) {
      setHeight('auto')
    } else {
      setHeight(0)
    }
  }, [openOnSuccess])

  const toggleLabel =
    height === 0 ? showLabel || labels.expand : hideLabel || labels.expand
  const toggleIcon = height === 0 ? plusIcon : minusIcon

  return (
    <div className="expandable-section-container">
      <div className="expandable-section">
        <div className="expandable-section-header">
          <h5 className="toggle-title theme-font-header">{title}</h5>
          <div className="expandable-section-toggle">
            <span className="toggle-text" onClick={toggle}>
              {toggleLabel}
            </span>
            <button
              className="expandable-section-icon"
              aria-expanded={height !== 0}
              aria-controls="example-panel"
              onClick={toggle}
              aria-label={toggleLabel}
            >
              <img src={toggleIcon} alt={toggleLabel} role="none" />
            </button>
          </div>
        </div>
        <AnimateHeight
          className="expandable-section"
          duration={500}
          height={height}
        >
          {children}
        </AnimateHeight>
      </div>
    </div>
  )
}

ExpandableSection.propTypes = {
  labels: shape().isRequired,
  children: node.isRequired,
  title: string.isRequired,
  disabled: bool,
  onClickDisabled: oneOfType([func, bool]),
  openOnSuccess: bool,
  showLabel: string,
  hideLabel: string
}

ExpandableSection.defaultProps = {
  disabled: false,
  onClickDisabled: false,
  openOnSuccess: false
}

export default ExpandableSection
