export const fieldNames = ['emailAddress', 'password']
export const formLayout = [['emailAddress'], ['password']]

export const agentFieldNames = ['userId', 'password']
export const agentFormLayout = [['userId'], ['password']]

export const formVariants = {
  CUSTOMER_LOGIN: 'login',
  TRAVEL_AGENT_LOGIN: 'agent-login'
}

export const modalVariants = {
  NO_REGISTER: 'no-register'
}
