import {
  FETCH_TELEPHONE_BOOKING,
  RECEIVE_TELEPHONE_BOOKING,
  TELEPHONE_BOOKING_ERROR,
  CLEAR_TELEPHONE_BOOKING,
  CHECKOUT_FORM_RESET,
  CHECKOUT_FORM_INIT
} from './contants'

import { startSessionTimer } from '../session/actions'
import { fetchPhoneBooking } from '../../api/api'
import { HYDRA_MEMBER } from '../../configuration/constants'

export const checkoutReadyAction = { type: CHECKOUT_FORM_INIT }
export const checkoutResetAction = { type: CHECKOUT_FORM_RESET }

export const fetchTelephoneBooking = (id, ferryId, token) => dispatch => {
  dispatch({ type: FETCH_TELEPHONE_BOOKING })

  dispatch(startSessionTimer()) // Refresh session on all api requests

  return fetchPhoneBooking(id, ferryId, token)
    .then(res => {
      const payload = res?.data?.[HYDRA_MEMBER]?.[0]
      if (payload) {
        return dispatch({
          type: RECEIVE_TELEPHONE_BOOKING,
          payload
        })
      }
    })
    .catch(err => {
      const errorMessage = err?.response?.data?.['hydra:description']

      return dispatch({
        type: TELEPHONE_BOOKING_ERROR,
        payload: errorMessage ? errorMessage : 'Unable to fetch booking'
      })
    })
}

export const clearTelephoneBookingData = () => dispatch => {
  return dispatch({
    type: CLEAR_TELEPHONE_BOOKING
  })
}
