import { createSelector } from 'reselect'

export const selectBookingState = state => state.bookingData

export const selectMonerisCurrencyCode = createSelector(
  selectBookingState,
  bookingState => bookingState?.data?.monerisData?.currencyCode
)

export const selectAttemptingSetBooking = createSelector(
  selectBookingState,
  bookingState => bookingState?.attemptingSetBooking
)

export const selectSetBookingSuccess = createSelector(
  selectBookingState,
  bookingState => bookingState.setBookingSuccess
)

export const selectSetBookingError = createSelector(
  selectBookingState,
  bookingState => bookingState.setBookingError
)

export const selectBookingConfirmationData = createSelector(
  selectBookingState,
  bookingState => bookingState?.data
)

export const selectPreloadingTransaction = createSelector(
  selectBookingState,
  bookingState => bookingState?.preloadingTransaction
)

export const selectPreloadTransactionError = createSelector(
  selectBookingState,
  bookingState => bookingState?.preloadTransactionError
)

export const selectPreloadTransactionSuccess = createSelector(
  selectBookingState,
  bookingState => bookingState?.preloadTransactionSuccess
)

export const selectPreloadedTicketNumbers = createSelector(
  selectBookingState,
  bookingState => bookingState?.preloadedTicketNumbers
)

// not in use!
export const selectAttemptingGetReceipt = createSelector(
  selectBookingState,
  bookingState => bookingState?.attemptingGetReceipt
)

export const selectGetReceiptSuccess = createSelector(
  selectBookingState,
  bookingState => bookingState?.getReceiptSuccess
)

export const selectGetReceiptError = createSelector(
  selectBookingState,
  bookingState => bookingState?.getReceiptError
)

export const selectReceipts = createSelector(
  selectBookingState,
  bookingState => bookingState?.receipts
)
