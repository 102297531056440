import queryString from 'query-string'
import moment from 'moment'
import yn from 'yn'
import dateFormats from '../configuration/dateFormats'
import * as UserSelectionActions from '../redux/userSelections/actions'
import {
  setDepartureDate,
  setDepartureRoute,
  setReturnDate,
  setReturnRoute,
  setRouteForm
} from './ferryRouteSelections/actions'
import {
  setDepartureAdults,
  setDepartureChildren,
  setDepartureInfants,
  setDepartureSeniors,
  setDepartureStudents,
  setDeparturePets,
  setDuplicatePassengerQuantities,
  setReturnAdults,
  setReturnChildren,
  setReturnInfants,
  setReturnSeniors,
  setReturnStudents,
  setReturnPets
} from './passengerSelections/actions'
import {
  resetVehicleSelections,
  setVehicleFormType
} from './vehicleSelections/actions'
import { vehicleTypes } from './vehicleSelections/constants'
import {
  appRoutes,
  ENABLE_ACCOMMODATIONS,
  noDefaultRoutes
} from '../configuration/constants'
import {
  resetActiveCrossings,
  resetReturnCrossing
} from './activeCrossings/actions'
import { resetActiveAccommodations } from './activeAccommodations/actions'
import { clearBookingData } from './booking/actions'
import { clearPaymentConfirmation } from './paymentConfirmation/actions'
import filterGroupRoutes from '../utilities/content/filterGroupRoutes'
import { isValidTripType } from './vehicleSelections/utilities'
import { fetchPromoInformation } from './promo/actions'

export const ingestQueryParameters = (
  queryParams = queryString.parse(window.location.search),
  configOptions
) => async dispatch => {
  const {
    // ----- GUESTS -------
    // ADULTS
    adults,
    departureAdults,
    a,
    returnAdults,
    ar,
    // CHILDREN
    children,
    departureChildren,
    c,
    returnChildren,
    cr,
    // INFANTS
    departureInfants,
    infants,
    i,
    returnInfants,
    ir,
    // SENIORS,
    seniors,
    s,
    departureSeniors,
    returnSeniors,
    sr,
    // STUDENTS
    departureStudents,
    returnStudents,
    students,
    st,
    str,
    // PETS
    pets,
    departurePets,
    p,
    returnPets,
    pr,
    // DATES
    departureDate,
    ddate,
    dd,
    returnDate,
    rdate,
    rd,
    // ROUTES
    departureRoute,
    dr,
    returnRoute,
    rr,
    ferry,
    vehicleType,
    vt,
    tripType,
    tt,
    coupon,
    pc
  } = queryParams

  const { routes: availableRoutes } = configOptions

  if (
    [
      appRoutes.bookingConfirmation.pathname,
      appRoutes.summary.pathname
    ].includes(window.location.pathname)
  ) {
    // We were redirected from Moneris hosted page, don't clear the persisted state
    return
  }

  /* DATES
   *  we'll assume that without an explicit tripType,
   *  the presence of both departure and return dates means we want a return trip.
   * */
  const departureDateParam = departureDate || ddate || dd
  const returnDateParam = returnDate || rdate || rd
  if (
    departureDateParam &&
    moment(departureDateParam, dateFormats.default).isSameOrAfter(
      moment(),
      'days'
    )
  ) {
    dispatch(setDepartureDate(moment(departureDateParam, dateFormats.default)))
    dispatch(UserSelectionActions.setDepartureSearchDate(departureDateParam))
    if (!returnDateParam && !tripType && !tt) {
      dispatch(setRouteForm('OW'))
    } else {
      if (
        moment(returnDateParam, dateFormats.default).isSameOrAfter(
          moment(departureDateParam, dateFormats.default),
          'days'
        )
      ) {
        dispatch(setReturnDate(moment(returnDateParam, dateFormats.default)))
        dispatch(UserSelectionActions.setReturnSearchDate(returnDateParam))
      }
      if (!tripType && !tt) {
        dispatch(setRouteForm('RT'))
      }
    }
  }
  if (coupon || pc) {
    await dispatch(
      fetchPromoInformation(coupon || pc, { clearIfInvalid: true })
    )
  }
  if (tripType && isValidTripType(tripType)) {
    dispatch(setRouteForm(tripType))
    dispatch(UserSelectionActions.setTripType(tripType))
  }

  /* ROUTES
   * we set the routes based on the query params, otherwise select the first available routes to give the
   * app some initial values.
   * */
  if (availableRoutes) {
    const shouldSetPaymentId =
      window.location.pathname !== appRoutes.telephoneBooking.pathname &&
      window.location.pathname !== appRoutes.bookingSuccess.pathname
    const matchingDepartureRoute = getMatchingDepartureRoute(
      availableRoutes,
      departureRoute || dr,
      ferry,
      noDefaultRoutes
    )

    if (matchingDepartureRoute) {
      dispatch(setDepartureRoute(matchingDepartureRoute, shouldSetPaymentId))
      dispatch(
        UserSelectionActions.setDepartureRoute(matchingDepartureRoute.code)
      )
    }

    const validReturnRoutes =
      yn(process.env.REACT_APP_COMPONENT_ENABLE_ROUTE_GROUPINGS) && ferry
        ? filterGroupRoutes(availableRoutes, ferry)
        : availableRoutes

    const returnRouteParam = returnRoute || rr

    const matchingReturnRoute =
      returnRouteParam && returnRouteParam !== departureRoute
        ? validReturnRoutes.find(
            route => route.code.toLowerCase() === returnRouteParam.toLowerCase()
          )
        : matchingDepartureRoute
        ? validReturnRoutes.find(
            route =>
              route.code.toLowerCase() !==
              matchingDepartureRoute.code.toLowerCase()
          )
        : noDefaultRoutes
        ? null
        : validReturnRoutes[1]

    if (matchingReturnRoute) {
      dispatch(setReturnRoute(matchingReturnRoute))
      dispatch(UserSelectionActions.setReturnRoute(matchingReturnRoute.code))
    }
  }

  /*
   * PASSENGERS
   * we'll assume adults or departureAdults, etc. are the same thing.
   * returnAdults, returnChildren etc, will only apply to return trips
   *
   * */
  const totalAllowedTickets = +process.env
    .REACT_APP_COMPONENT_MAXIMUM_PASSENGER_TICKETS

  let remainingDeparture = totalAllowedTickets
  let remainingReturn = totalAllowedTickets

  const getCountSafely = values => +values.find(x => !!x) || 0
  const setAndUpdateCount = (count, setCountAction, isDeparture) => {
    const amountToSet = Math.min(
      count,
      isDeparture ? remainingDeparture : remainingReturn
    )
    if (isDeparture) {
      remainingDeparture = Math.max(remainingDeparture - amountToSet, 0)
    } else {
      remainingReturn = Math.max(remainingReturn - amountToSet, 0)
    }
    dispatch(setCountAction(amountToSet))
  }

  const adultCount = getCountSafely([adults, departureAdults, a])
  const childCount = getCountSafely([children, departureChildren, c])
  const seniorCount = getCountSafely([seniors, departureSeniors, s])
  const infantCount = getCountSafely([infants, departureInfants, i])
  const studentCount = getCountSafely([students, departureStudents, st])
  const petCount = getCountSafely([pets, departurePets, p])

  setAndUpdateCount(adultCount, setDepartureAdults, true)
  setAndUpdateCount(childCount, setDepartureChildren, true)
  setAndUpdateCount(seniorCount, setDepartureSeniors, true)
  setAndUpdateCount(infantCount, setDepartureInfants, true)
  setAndUpdateCount(studentCount, setDepartureStudents, true)
  setAndUpdateCount(petCount, setDeparturePets, true)

  UserSelectionActions.updateDeparturePassengers({
    adult: adultCount,
    child: childCount,
    senior: seniorCount,
    infant: infantCount,
    student: studentCount,
    pet: petCount
  })

  const returnAdultsPrams = returnAdults || ar
  const returnSeniorsParam = returnSeniors || sr
  const returnChildrenParam = returnChildren || cr
  const returnInfantsParam = returnInfants || ir
  const returnStudentsParam = returnStudents || str
  const returnPetsParam = returnPets || pr
  if (
    returnAdultsPrams ||
    returnSeniorsParam ||
    returnChildrenParam ||
    returnInfantsParam ||
    returnStudentsParam ||
    returnPetsParam
  ) {
    // this means at least one of these is set and we need to set our
    // duplicate passenger flag (default is true)
    dispatch(setDuplicatePassengerQuantities(false))
    dispatch(UserSelectionActions.setIsSamePassengers(false))

    const adultCountReturn = getCountSafely([returnAdultsPrams])
    const childCountReturn = getCountSafely([returnChildrenParam])
    const seniorCountReturn = getCountSafely([returnSeniorsParam])
    const infantCountReturn = getCountSafely([returnInfantsParam])
    const studentCountReturn = getCountSafely([returnStudentsParam])
    const petCountReturn = getCountSafely([returnPetsParam])
    setAndUpdateCount(adultCountReturn, setReturnAdults, false)
    setAndUpdateCount(childCountReturn, setReturnChildren, false)
    setAndUpdateCount(seniorCountReturn, setReturnSeniors, false)
    setAndUpdateCount(infantCountReturn, setReturnInfants, false)
    setAndUpdateCount(studentCountReturn, setReturnStudents, false)
    setAndUpdateCount(petCountReturn, setReturnPets, false)

    UserSelectionActions.updateReturnPassengers({
      adult: adultCountReturn,
      child: childCountReturn,
      senior: seniorCountReturn,
      infant: infantCountReturn,
      student: studentCountReturn,
      pet: petCountReturn
    })
  } else {
    setAndUpdateCount(adultCount, setReturnAdults, false)
    setAndUpdateCount(childCount, setReturnChildren, false)
    setAndUpdateCount(seniorCount, setReturnSeniors, false)
    setAndUpdateCount(infantCount, setReturnInfants, false)
    setAndUpdateCount(studentCount, setReturnStudents, false)
    setAndUpdateCount(petCount, setReturnPets, false)

    UserSelectionActions.updateReturnPassengers({
      adult: adultCount,
      child: childCount,
      senior: seniorCount,
      infant: infantCount,
      student: studentCount,
      pet: petCount
    })
  }

  /*
   * Vehicles
   * walk-on, duplicateVehicle, uniqueVehicle
   * */
  const vehicleParam = vehicleType || vt
  if (vehicleParam && vehicleTypes.some(type => type === vehicleParam)) {
    dispatch(setVehicleFormType(vehicleParam))
  }
}

function getMatchingDepartureRoute(
  availableRoutes,
  departureRoute,
  ferry,
  noDefaultRoutes
) {
  if (departureRoute) {
    return availableRoutes.find(
      route => route.code.toLowerCase() === departureRoute.toLowerCase()
    )
  } else if (ferry) {
    return availableRoutes.find(
      route => route.ferryId.toLowerCase() === ferry.toLowerCase()
    )
  } else if (noDefaultRoutes) {
    return null
  } else {
    return availableRoutes[0]
  }
}

// used to clear selections made before returning to an earlier step.
export const cleanRedux = pathname => dispatch => {
  if (
    pathname === appRoutes.ferryRoutes.pathname ||
    pathname === appRoutes.passengerQuantities.pathname
  ) {
    dispatch(resetVehicleSelections())
    dispatch(resetActiveCrossings())
    if (ENABLE_ACCOMMODATIONS) dispatch(resetActiveAccommodations())
    dispatch(clearBookingData())
    dispatch(clearPaymentConfirmation())
  }
  if (pathname === appRoutes.vehicle.pathname) {
    dispatch(resetActiveCrossings())
    if (ENABLE_ACCOMMODATIONS) dispatch(resetActiveAccommodations())
    dispatch(clearBookingData())
    dispatch(clearPaymentConfirmation())
  }
  if (pathname === appRoutes.departure.pathname) {
    dispatch(resetReturnCrossing())
    if (ENABLE_ACCOMMODATIONS) dispatch(resetActiveAccommodations())
    dispatch(clearBookingData())
    dispatch(clearPaymentConfirmation())
  }
  if (
    pathname === appRoutes.return.pathname ||
    pathname === appRoutes.accommodations.pathname
  ) {
    dispatch(clearBookingData())
    dispatch(clearPaymentConfirmation())
  }
  if (
    pathname === appRoutes.bookingConfirmation.pathname ||
    pathname === appRoutes.bookingSuccess.pathname
  ) {
    // clearup previously modified stuff after complete booking workflow successfully,
    // to ensure next modify refilled correctly!
    dispatch(resetVehicleSelections())
    if (ENABLE_ACCOMMODATIONS) dispatch(resetActiveAccommodations())
  }
  if (pathname === appRoutes.account.pathname) {
    dispatch(clearPaymentConfirmation())
  }
}
