import { handleActions } from 'redux-actions'
import {
  ACCOUNT_CLEAR,
  ACCOUNT_GET_LOADING,
  ACCOUNT_GET_SUCCESS,
  ACCOUNT_GET_ERROR,
  ACCOUNT_UPDATE_LOADING,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_ERROR,
  ACCOUNT_UPDATE_SUCCESS_RESET,
  ACCOUNT_UPDATE_ERROR_RESET,
  AGENT_LOGIN_SESSION_KEY,
  BOOKINGS_GET_LOADING,
  BOOKINGS_GET_SUCCESS,
  BOOKINGS_GET_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_FORM_RESET
} from './constants'

const travelAgentInitialState = {
  account: {},
  bookings: [],
  bookingsGetLoading: false,
  bookingsGetError: false,
  accountGetLoading: false,
  accountGetError: false,
  accountUpdateLoading: false,
  accountUpdateSuccess: false,
  accountUpdateError: false,
  loginLoading: false,
  loginSuccess: false,
  loginError: false
}

const travelAgentReducer = handleActions(
  {
    [ACCOUNT_CLEAR]: state => {
      sessionStorage.removeItem(AGENT_LOGIN_SESSION_KEY)

      return {
        ...state,
        loginLoading: false,
        loginSuccess: false,
        loginError: false,
        account: {}
      }
    },
    [ACCOUNT_GET_LOADING]: state => ({
      ...state,
      account: {},
      accountGetLoading: true,
      accountGetError: false
    }),
    [ACCOUNT_GET_SUCCESS]: (state, { payload }) => {
      let newState = { ...state }

      // Return error state if we don't have the userName
      if (payload?.userName) {
        sessionStorage.setItem(AGENT_LOGIN_SESSION_KEY, payload.userName)
        newState = {
          ...newState,
          accountGetLoading: false,
          accountGetError: false,
          account: payload
        }
      } else {
        newState = {
          ...newState,
          accountGetLoading: false,
          accountGetError: true,
          account: {}
        }
      }

      return newState
    },
    [ACCOUNT_GET_ERROR]: state => ({
      ...state,
      accountGetLoading: false,
      accountGetError: true,
      account: {}
    }),
    [ACCOUNT_UPDATE_LOADING]: state => ({
      ...state,
      accountUpdateLoading: true,
      accountUpdateSuccess: false,
      accountUpdateError: false
    }),
    [ACCOUNT_UPDATE_SUCCESS]: (state, { payload }) => {
      let newState = { ...state }

      // Return error state if we don't have a userName
      if (payload?.userName) {
        sessionStorage.setItem(AGENT_LOGIN_SESSION_KEY, payload.userName)
        newState = {
          ...newState,
          accountUpdateLoading: false,
          accountUpdateSuccess: true,
          accountUpdateError: false,
          account: payload
        }
      } else {
        newState = {
          ...newState,
          accountUpdateLoading: false,
          accountUpdateSuccess: false,
          accountUpdateError: true,
          account: {}
        }
      }

      return newState
    },
    [ACCOUNT_UPDATE_ERROR]: state => ({
      ...state,
      accountUpdateLoading: false,
      accountUpdateSuccess: false,
      accountUpdateError: true
    }),
    [ACCOUNT_UPDATE_SUCCESS_RESET]: state => ({
      ...state,
      accountUpdateSuccess: false
    }),
    [ACCOUNT_UPDATE_ERROR_RESET]: state => ({
      ...state,
      accountUpdateError: false
    }),
    [BOOKINGS_GET_LOADING]: (state, { payload }) => ({
      ...state,
      bookings: [],
      bookingsGetLoading: true,
      bookingsGetError: false
    }),
    [BOOKINGS_GET_SUCCESS]: (state, { payload }) => ({
      ...state,
      bookings: payload || [],
      bookingsGetLoading: false
    }),
    [BOOKINGS_GET_ERROR]: state => ({
      ...state,
      bookings: [],
      bookingsGetLoading: false,
      bookingsGetError: true
    }),
    [LOGIN_LOADING]: state => ({
      ...state,
      loginLoading: true,
      loginSuccess: false,
      loginError: false,
      account: {}
    }),
    [LOGIN_SUCCESS]: (state, { payload }) => {
      let newState = { ...state }

      // Return error state if we don't have the userName
      if (payload?.userName) {
        sessionStorage.setItem(AGENT_LOGIN_SESSION_KEY, payload.userName)
        newState = {
          ...newState,
          loginLoading: false,
          loginSuccess: true,
          loginError: false,
          account: payload
        }
      } else {
        newState = {
          ...newState,
          loginLoading: false,
          loginSuccess: false,
          loginError: true,
          account: {}
        }
      }

      return newState
    },
    [LOGIN_ERROR]: state => ({
      ...state,
      loginLoading: false,
      loginSuccess: false,
      loginError: true,
      account: {}
    }),
    [LOGIN_FORM_RESET]: state => ({
      ...state,
      loginLoading: false,
      loginSuccess: false,
      loginError: false
    })
  },
  { ...travelAgentInitialState }
)

export { travelAgentInitialState }
export default travelAgentReducer
