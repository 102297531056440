import React from 'react'
import { string } from 'prop-types'
import { useSelector } from 'react-redux'
import { selectLabels } from '../../redux/configuration/selectors'

const ErrorBoundary = ({
  containerClasses,
  errorMessage,
  errorTitle,
  responseError
}) => {
  const labels = useSelector(selectLabels)

  return (
    <div className={containerClasses ? containerClasses : 'u-error-boundary'}>
      {errorTitle && <h4>{errorTitle}</h4>}
      <p className="u-remove-margin">
        {errorMessage ? errorMessage : labels.genericErrorMessage}
      </p>
      {responseError && (
        <p className="u-element-hidden">
          {`${labels.errorHeading} ${responseError}`}
        </p>
      )}
    </div>
  )
}

ErrorBoundary.propTypes = {
  containerClasses: string,
  errorMessage: string,
  errorTitle: string,
  responseError: string
}

export default ErrorBoundary
