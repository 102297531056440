import React from 'react'
import moment from 'moment'
import dateFormats from '../../configuration/dateFormats'
import { capitalizeFirstLetter } from '../../configuration/utilities'
import moonIcon from '../../assets/icons/moon.svg'

export const getDateValue = (crossingDetails, labels, language) => {
  moment.locale(language)

  const { departureTime, departureDate } = crossingDetails

  const departureTimeNumber = +moment(departureTime, 'HH:mm').format('HHmm')

  const requiresDateClarification =
    !!departureTime && departureTimeNumber > 0 && departureTimeNumber < 400

  const date = moment(departureDate)

  let departingOnFormatted = date.format(
    language === 'fr'
      ? dateFormats.summaryTableLongDisplayFrench
      : dateFormats.summaryTableLongDisplay
  )

  if (language === 'fr')
    departingOnFormatted = capitalizeFirstLetter(departingOnFormatted)

  const departureTimeString = !!departureTime
    ? `${labels.at} ${departureTime}`
    : labels.departureWithNoTimeSetMsg

  return (
    <span className="summary-table__row-value__date">
      <span className="summary-table__row-value__row">
        {departureTimeString !== labels.departureWithNoTimeSetMsg &&
          `${departingOnFormatted} `}
        {departureTimeString}
      </span>
      {requiresDateClarification && (
        <span className="summary-table__row-value__row u-flex">
          <img src={moonIcon} alt="" role="none" /> {labels.nightOf}{' '}
          {date
            .clone()
            .subtract(1, 'days')
            .format('dddd')}{' '}
          {labels.to} {date.format('dddd')}
        </span>
      )}
    </span>
  )
}

export function getTotalFromPriceLines(lines) {
  return lines.reduce((num, line) => {
    num += +line.price
    return num
  }, 0)
}
