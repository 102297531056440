import { createAction } from 'redux-actions'
import {
  login,
  apiRegister,
  modifyCustomer,
  sendResetPasswordEmail,
  sendEmailConfirmation as sendEmailConfirmationRequest,
  resetPassword,
  fetchCoPassengers,
  postCoPassengers,
  findReservation,
  createToken
} from '../../api/api'
import {
  getHydraMemberDataType,
  formatEmailParam
} from '../../configuration/utilities'
import appRoutes from '../../configuration/appRoutes'
import { accountClear as clearTravelAgentAccount } from '../travelAgent/actions'
import { startSessionTimer } from '../session/actions'
import { resetSetBooking } from '../booking/actions'
import { selectCustomerAccount } from './selectors'
import {
  ATTEMPTING_REGISTER,
  RESET_ATTEMPTING_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  RESET_REGISTER_ERROR,
  RESET_REGISTER_FORM,
  ATTEMPTING_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  RESET_LOGIN_FORM,
  CLEAR_CUSTOMER_ACCOUNT,
  ATTEMPTING_UPDATE,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  RESET_UPDATE_ERROR,
  RESET_UPDATE_SUCCESS,
  ATTEMPTING_PASSWORD_RESET_EMAIL,
  PASSWORD_RESET_EMAIL_SUCCESS,
  PASSWORD_RESET_EMAIL_ERROR,
  RESET_PASSWORD_RESET_EMAIL_SUCCESS,
  ATTEMPTING_PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  RESET_PASSWORD_RESET_ERROR,
  ATTEMPTING_SEND_EMAIL_CONFIRMATION,
  SEND_EMAIL_CONFIRMATION_SUCCESS,
  SEND_EMAIL_CONFIRMATION_ERROR,
  RESET_SEND_EMAIL_CONFIRMATION,
  COPASSENGERS_RECEIVED,
  FIND_USER_RESERVATION,
  FIND_RESERVATION_SUCCESS,
  FIND_RESERVATION_ERROR,
  FIND_RESERVATION_RESET
} from './constants'
import { ENABLE_COPASSENGERS } from '../../configuration/constants'

export const attemptingRegister = createAction(ATTEMPTING_REGISTER)
export const resetAttemptingRegister = createAction(RESET_ATTEMPTING_REGISTER)
export const registerUserSuccess = createAction(REGISTER_SUCCESS)
export const registerUserError = createAction(REGISTER_ERROR)
export const resetRegisterError = createAction(RESET_REGISTER_ERROR)
export const resetRegisterForm = createAction(RESET_REGISTER_FORM)

export const attemptingLogin = createAction(ATTEMPTING_LOGIN)
export const loginUserSuccess = createAction(LOGIN_SUCCESS)
export const loginUserError = createAction(LOGIN_ERROR)
export const resetLoginForm = createAction(RESET_LOGIN_FORM)

export const coPassengersReceived = createAction(COPASSENGERS_RECEIVED)

export const clearCustomerAccount = () => async dispatch => {
  await createToken()
  dispatch({ type: CLEAR_CUSTOMER_ACCOUNT })
}

export const attemptingUpdate = createAction(ATTEMPTING_UPDATE)
export const updateSuccess = createAction(UPDATE_SUCCESS)
export const updateError = createAction(UPDATE_ERROR)
export const resetUpdateError = createAction(RESET_UPDATE_ERROR)
export const resetUpdateSuccess = createAction(RESET_UPDATE_SUCCESS)

export const attemptingSendEmailConfirmation = createAction(
  ATTEMPTING_SEND_EMAIL_CONFIRMATION
)
export const sendEmailConfirmationSuccess = createAction(
  SEND_EMAIL_CONFIRMATION_SUCCESS
)
export const sendEmailConfirmationError = createAction(
  SEND_EMAIL_CONFIRMATION_ERROR
)
export const resetSendEmailConfirmationState = createAction(
  RESET_SEND_EMAIL_CONFIRMATION
)

export const attemptingPasswordResetEmail = createAction(
  ATTEMPTING_PASSWORD_RESET_EMAIL
)
export const passwordResetEmailSuccess = createAction(
  PASSWORD_RESET_EMAIL_SUCCESS
)
export const passwordResetEmailError = createAction(PASSWORD_RESET_EMAIL_ERROR)

export const resetPasswordResetEmailSuccess = createAction(
  RESET_PASSWORD_RESET_EMAIL_SUCCESS
)

export const attemptingPasswordReset = createAction(ATTEMPTING_PASSWORD_RESET)
export const passwordResetSuccess = createAction(PASSWORD_RESET_SUCCESS)
export const passwordResetError = createAction(PASSWORD_RESET_ERROR)
export const resetPasswordResetError = createAction(RESET_PASSWORD_RESET_ERROR)

export const passwordResetEmail = ({
  emailAddress,
  language
}) => async dispatch => {
  dispatch(attemptingPasswordResetEmail())
  if (!emailAddress || !language || !process.env.REACT_APP_URL) {
    return dispatch(passwordResetEmailError())
  }
  dispatch(startSessionTimer()) // Refresh session on all api requests
  const result = await sendResetPasswordEmail({
    emailAddress,
    language,
    url: `${window.location.origin}${appRoutes.updatePassword.pathnameWithoutParams}/${language}`
  })
  // we actually don't care if this works or not.
  // For security reasons we were asked to always
  // act as if this was successful
  dispatch(passwordResetEmailSuccess())
  return result
}

export const sendEmailConfirmation = ({
  bookingNumber,
  emailAddress
}) => async dispatch => {
  dispatch(attemptingSendEmailConfirmation())

  if (!bookingNumber) {
    return dispatch(sendEmailConfirmationError())
  }

  dispatch(startSessionTimer()) // Refresh session on all api requests
  return sendEmailConfirmationRequest({
    bookingNumber,
    emailAddress
  })
    .then(() => dispatch(sendEmailConfirmationSuccess()))
    .catch(() => dispatch(sendEmailConfirmationError()))
}

export const passwordReset = ({ password, emailToken }) => async dispatch => {
  dispatch(attemptingPasswordReset())

  if (!password || !emailToken) {
    return dispatch(passwordResetError())
  }

  dispatch(startSessionTimer()) // Refresh session on all api requests

  return resetPassword({
    password,
    emailToken
  })
    .then(() => dispatch(passwordResetSuccess()))
    .catch(errorResponse => {
      let errorKey = 'passwordUpdateError'

      if (
        errorResponse?.response?.status &&
        errorResponse.response.status === 400
      ) {
        const errorMessage =
          errorResponse?.response?.data?.['hydra:description']

        if (errorMessage.includes('The password is too short')) {
          errorKey = 'passwordTooShort'
        }
        if (errorMessage.includes('The password is too long')) {
          errorKey = 'passwordTooLong'
        }
        if (errorMessage.includes('The password does not match criteria')) {
          errorKey = 'passwordFailedCriteria'
        }
      }

      return dispatch(passwordResetError(errorKey))
    })
}

const apiLogin = (payload, dispatch) => {
  dispatch(startSessionTimer()) // Refresh session on all api requests

  return login(payload)
    .then(success => {
      const successData =
        payload.emailAddress &&
        getHydraMemberDataType(success, formatEmailParam(payload.emailAddress))
      const successEmail = successData?.customerAccount?.email

      if (successEmail) {
        dispatch(clearTravelAgentAccount()) // clear travel agent account
        // FIXME: clear error(Email already exists in Bookit! ...An account already exists with this email address)
        // on passengers details page after successful login.
        // This error caused by trying to create an account but actually existed already in system.
        // So, with the error hint, user should login first, then the error should disppear, allowing user continue to pay
        // see details: https://jira.verbinteractive.com/browse/IPS000-642
        dispatch(resetSetBooking())
        return dispatch(loginUserSuccess(successData.customerAccount))
      } else {
        dispatch(loginUserError())
        return Promise.reject('Unable to login')
      }
    })
    .catch(() => {
      dispatch(loginUserError())
      return Promise.reject('Unable to login')
    })
}

export const updateUser = ({
  customerNumber,
  ...restValues
}) => async dispatch => {
  dispatch(attemptingUpdate())

  if (!customerNumber) {
    return dispatch(updateError())
  }

  dispatch(startSessionTimer()) // Refresh session on all api requests

  return modifyCustomer({
    customerNumber,
    ...restValues
  })
    .then(success => {
      const successData = getHydraMemberDataType(success, customerNumber)
      const successEmail = successData?.customerAccount?.email

      if (successEmail) {
        return dispatch(updateSuccess(successData.customerAccount))
      } else {
        return dispatch(updateError())
      }
    })
    .catch(() => {
      return dispatch(updateError())
    })
}

export const loginUser = (
  formValues = { emailAddress: '', password: '' },
  sessionId
) => async dispatch => {
  const { emailAddress, password } = formValues
  dispatch(attemptingLogin())

  if (!emailAddress || !password) {
    return dispatch(loginUserError())
  }

  const loginResponse = apiLogin(
    {
      emailAddress,
      password
    },
    dispatch
  )

  if (ENABLE_COPASSENGERS) {
    loginResponse.then(successData => {
      if (successData.payload.customerNumber) {
        fetchCoPassengers(successData.payload.customerNumber, sessionId).then(
          copassSuccess => {
            dispatch(coPassengersReceived(copassSuccess?.data?.coPassengers))
          }
        )
      }
    })
  }

  return loginResponse
}

export const registerUser = (formValues = {}) => async dispatch => {
  dispatch(attemptingRegister())
  const values = { ...formValues }

  dispatch(startSessionTimer()) // Refresh session on all api requests

  const registerRequest = await apiRegister(values)

  if (registerRequest.errorKey || !registerRequest.success) {
    return dispatch(
      registerUserError({
        errorKey: registerRequest.errorKey
      })
    )
  }

  return dispatch(registerUserSuccess(registerRequest.success))
}

export const setUserCoPassengers = (
  coPassengers,
  sessionId,
  countryCode
) => async (dispatch, getState) => {
  const state = getState()
  const customer = selectCustomerAccount(state)
  const customerNumber = customer?.customerNumber

  // TODO: remove this once we can send copassenger gender properly
  const formattedCoPassengers = coPassengers.map(copassenger => ({
    ...copassenger,
    gender: 'M'
  }))

  dispatch(startSessionTimer())
  const updatedCoPassengers = await postCoPassengers(
    formattedCoPassengers,
    customerNumber,
    countryCode
  )
  dispatch(coPassengersReceived(updatedCoPassengers))
}

const startFindReservation = createAction(FIND_USER_RESERVATION)
const findReservationSuccess = createAction(FIND_RESERVATION_SUCCESS)
const findReservationError = createAction(FIND_RESERVATION_ERROR)
export const findReservationReset = createAction(FIND_RESERVATION_RESET)
export const findUserReservation = (
  { email, reservationId, workPhoneNumber },
  sessionId
) => async dispatch => {
  dispatch(startFindReservation())
  dispatch(startSessionTimer())
  try {
    const reservationResponse = await findReservation({
      email,
      reservationId,
      sessionId,
      workPhoneNumber
    })
    const reservationDetails = getHydraMemberDataType(
      reservationResponse,
      reservationId
    )
    return dispatch(
      findReservationSuccess({
        email,
        reservationId,
        workPhoneNumber,
        ...(reservationDetails?.bookingReservation || {})
      })
    )
  } catch (error) {
    const errorMessage = error.response?.data?.['hydra:description']
    return dispatch(findReservationError(errorMessage))
  } finally {
    // maybe need something todo
    // console.log('### query reservation done!')
  }
}
