import { createSelector } from 'reselect'
import { selectUserSelections } from '../userSelections/selectors'
import { selectCrossingsUnmodified } from '../crossings/selectors'

export const selectActiveCrossingDataRefactored = createSelector(
  selectUserSelections,
  selectCrossingsUnmodified,
  (userSelections, crossings) => {
    const { departureCrossing, returnCrossing } = userSelections
    const { date, time } = departureCrossing

    if (!crossings?.departure) {
      return { departure: null, return: null }
    }
    const selectedDepartureCrossing =
      crossings.departure.find(
        crossing =>
          crossing.departureDate.startsWith(date) &&
          crossing.departureTime === time
      ) ?? null

    let selectedReturnCrossing = null
    // crossings.return might be undefined
    if (returnCrossing && crossings.return) {
      const { date, time } = returnCrossing
      if (!date || !time) {
        selectedReturnCrossing = null
      } else {
        selectedReturnCrossing = crossings.return.find(
          crossing =>
            crossing.departureDate.startsWith(date) &&
            crossing.departureTime === time
        )
      }
    }

    return {
      departure: selectedDepartureCrossing,
      return: selectedReturnCrossing
    }
  }
)
