export default {
  default: 'YYYY-MM-DD',
  shortDateDisplay: 'ddd, MMM, D',
  shortDateDisplayAlt: 'ddd, MMM D',
  shortDateDisplayFrench: 'ddd [le] D MMM',
  fullYearMonthShortDisplay: 'YYYY MMMM D',
  timeOnly: 'HH:mm',
  longDisplay: 'dddd, MMMM D, YYYY',
  longDisplayFrench: 'dddd [le] D MMMM YYYY',
  summaryTableLongDisplay: 'dddd, MMMM DD, YYYY',
  summaryTableLongDisplayFrench: 'dddd [le] DD MMMM  YYYY',
  mobileLongDisplay: 'ddd, MMM D, YYYY',
  mobileLongDisplayFrench: 'ddd, MMM [le] D, YYYY',
  customDateSelectLong: 'MMMM D, YYYY',
  customDateSelectLongFrench: 'D MMMM YYYY'
}
