import React, { Fragment } from 'react'
import { func, string, bool } from 'prop-types'
import './RowGroup.scss'

const RowGroup = ({
  render,
  title,
  formTitleClasses,
  formRowClasses,
  errorClasses,
  sectionClasses,
  renderHeader,
  convertInputsToCaps
}) => {
  return (
    <Fragment>
      {renderHeader && renderHeader()}
      {title && <h4 className={formTitleClasses}>{title}</h4>}
      <div
        className={`row-group ${sectionClasses ? sectionClasses : ''}${
          convertInputsToCaps ? 'all-caps' : ''
        }`}
      >
        {render({ formRowClasses, errorClasses })}
      </div>
    </Fragment>
  )
}

RowGroup.propTypes = {
  render: func.isRequired,
  title: string,
  formTitleClasses: string,
  formRowClasses: string,
  errorClasses: string,
  sectionClasses: string,
  renderHeader: func,
  convertInputsToCaps: bool
}

RowGroup.defaultProps = {
  formTitleClasses: 'row-group-title theme-font-header',
  formRowClasses: 'row',
  errorClasses: 'row-group-error',
  sectionClasses: '',
  convertInputsToCaps: false
}

export default RowGroup
