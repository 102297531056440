import React, { Fragment } from 'react'
import { string, array, bool } from 'prop-types'

import '../../styles/scss/components/booking-confirmation/booking-confirmation-table.scss'

const BookingConfirmationTable = ({
  headerLabel,
  headerValue,
  tableData,
  centerHeader
}) => {
  const cells = tableData.map((row, key) => {
    return (
      <Fragment key={`booking-confirmation-${key}`}>
        <div className="booking-confirmation-table__table-row__label">
          {row.label}
        </div>
        <div className="booking-confirmation-table__table-row__value">
          {row.value}
        </div>
      </Fragment>
    )
  })

  return (
    <div className="booking-confirmation-table">
      <div
        className={`booking-confirmation-table__header ${
          centerHeader ? 'center' : ''
        }`}
      >
        <span className="booking-confirmation-table__header-label">
          {headerLabel}
        </span>
        <span className="booking-confirmation-table__header-value">
          {headerValue}
        </span>
      </div>
      <div className="booking-confirmation-table__table">{cells}</div>
    </div>
  )
}
BookingConfirmationTable.defaultProps = {
  headerValue: ''
}
BookingConfirmationTable.propTypes = {
  headerLabel: string.isRequired,
  headerValue: string,
  tableData: array.isRequired,
  centerHeader: bool
}

export default BookingConfirmationTable
