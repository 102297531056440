import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { omit } from 'lodash'

import {
  ENABLE_MONERIS_CHECKOUT,
  ENABLE_PAYPAL,
  PAYPAL_CURRENCY_CODE,
  PAYPAL_CLIENT_ID,
  VERB_ENV
} from '../../configuration/constants'
import appRoutes from '../../configuration/appRoutes'
// Note: session timer must be imported on the top of other UI component!
import SessionTimer from '../../components/SessionTimer/SessionTimer'

import Header from '../../components/Header'
import Layout from './Layout'
import GlobalModals from './GlobalModals'

import SessionExpiredModal from '../../components/SessionExpiredModal/SessionExpiredModal'
import SessionWarningModal from '../../components/SessionWarningModal/SessionWarningModal'

import { CHECKOUT_LIBRARY_URL } from '../../components/MonerisCheckout/constants'
import { updateConfigByLanguage } from '../../redux/configuration/actions'
import { ConfigProvider } from '../../redux/configuration/context'
import { selectFetchingLabels } from '../../redux/configuration/selectors'
import { selectLanguage } from '../../redux/session/selectors'
import ModalConsumer, { ModalProvider } from '../../context/modalContext'
import { UserProvider } from '../../context/userContext'
import { selectQueryParams } from '../../redux/selectors/selectQueryParams'
import { currentPathIsAny } from '../../utilities/browser/comparePaths'
import { checkToken, fetchCoPassengers } from '../../api/api'
import {
  clearCustomerAccount,
  coPassengersReceived,
  loginUserSuccess
} from '../../redux/user/actions'

if (VERB_ENV !== 'test') require('moment/min/locales.min')

const App = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const activeLanguage = useSelector(selectLanguage)
  const fetchingLabels = useSelector(selectFetchingLabels)
  const queryParams = useSelector(selectQueryParams)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    initializeToken()
  }, []) // eslint-disable-line

  const initializeToken = async () => {
    // check localStorage for JWT. checkToken will return a user if it can.
    const params = queryString.parse(window.location.search) || {}

    const token = params?.tok || localStorage.getItem('MAI_TOKEN')
    if (token) {
      checkToken({
        token
      })
        .then(async res => {
          if (res?.user?.customerAccount) {
            dispatch(loginUserSuccess(res.user.customerAccount))

            const coPassengerRes = await fetchCoPassengers(
              res.user.customerAccount.customerNumber
            )
            if (coPassengerRes?.data?.coPassengers) {
              dispatch(coPassengersReceived(coPassengerRes.data.coPassengers))
            }
          } else {
            await dispatch(clearCustomerAccount()) // clear customer account and make a fresh token
          }
        })
        .catch(async () => {
          await dispatch(clearCustomerAccount()) // clear customer account and make a fresh token
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      await dispatch(clearCustomerAccount()) // clear customer account and make a fresh token
      setIsLoading(false)
    }
  }

  const handleRouteChange = (target, data = null) => {
    history.push({
      pathname: target,
      search: `?${queryParams}`,
      state: data
    })
    window.scrollTo(0, 0)
  }

  const handleToggleLanguage = async languageCode => {
    if (languageCode && languageCode !== activeLanguage && !fetchingLabels) {
      const params = omit(queryString.parse(window.location.search) || {}, [
        'tok'
      ])
      params.l = languageCode

      const search = new URLSearchParams()

      for (const [key, val] of Object.entries(params)) {
        search.append(key, val)
      }

      const token = localStorage.getItem('MAI_TOKEN')

      if (token) {
        search.append('tok', encodeURI(token))
      }

      const target =
        languageCode === 'fr'
          ? process.env.REACT_APP_BOOKING_BASE_FR
          : process.env.REACT_APP_BOOKING_BASE

      if (
        target === window.location.origin ||
        window.location.origin === 'http://localhost:3000'
      ) {
        moment.locale(languageCode)
        await dispatch(updateConfigByLanguage({ languageCode }))
        if (window.location.origin === 'http://localhost:3000') {
          return
        }
      }
      window.location = `${target}${
        window.location.pathname
      }?${search.toString()}`
    }
  }

  const showExtendedHeader =
    !currentPathIsAny([
      appRoutes.register.pathname,
      appRoutes.account.pathname,
      appRoutes.telephoneBooking.pathname,
      appRoutes.bookingSuccess.pathname,
      appRoutes.forgotPassword.pathname
    ]) &&
    !location.pathname.includes(appRoutes.updatePassword.pathnameWithoutParams)

  // https://developer.paypal.com/docs/api/reference/locale-codes/
  const payPalLocale = activeLanguage === 'fr' ? 'fr_CA' : 'en_US'

  return (
    <Fragment>
      {ENABLE_MONERIS_CHECKOUT && (
        <Helmet>
          <script async src={CHECKOUT_LIBRARY_URL} />
        </Helmet>
      )}
      {ENABLE_PAYPAL && (
        <Helmet>
          <script
            defer
            src={`https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&currency=${PAYPAL_CURRENCY_CODE}&locale=${payPalLocale}`}
          />
        </Helmet>
      )}
      <Helmet>
        {activeLanguage === 'fr' ? (
          <title>
            Marine Atlantique | Traversiers vers la Nouvelle-Écosse et
            Terre-Neuve
          </title>
        ) : (
          <title>Marine Atlantic | Ferries to Nova Scotia & Newfoundland</title>
        )}
      </Helmet>
      <Helmet>
        {activeLanguage === 'fr' ? (
          <meta
            name="description"
            content="Ouvrez une séance ou enregistrez un nouveau compte auprès de Marine Atlantique pour mettre à jour les renseignements sur votre profil et gérer vos réservations de traversiers vers Terre-Neuve et la Nouvelle-Écosse"
          />
        ) : (
          <meta
            name="description"
            content="Login to Marine Atlantic to access an existing reservation, or choose your route and departure date to start your ferry booking."
          />
        )}
      </Helmet>
      <ConfigProvider isLoading={isLoading}>
        <UserProvider>
          <ModalProvider>
            <ModalConsumer>
              {({
                modalIsOpen,
                openModal,
                closeModal,
                modalContinue,
                changeModals
              }) => (
                <div
                  className="ferries-create-reservation"
                  data-testid="ferries-create-reservation"
                >
                  <Header
                    handleRouteChange={handleRouteChange}
                    openModal={openModal}
                    showExtendedHeader={showExtendedHeader}
                    handleToggleLanguage={handleToggleLanguage}
                  />
                  <Layout
                    pathname={location.pathname}
                    handleRouteChange={handleRouteChange}
                    handleToggleLanguage={handleToggleLanguage}
                    openModal={openModal}
                    closeModal={closeModal}
                    changeModals={changeModals}
                    modalIsOpen={modalIsOpen}
                    showExtendedHeader={showExtendedHeader}
                    isBootstrapping={isLoading}
                  />
                  {/* session timer must be inside the app component directly! */}
                  <SessionTimer
                    handleRouteChange={handleRouteChange}
                    render={({
                      sessionTimeoutCounter,
                      showWarningModal,
                      showExpiredModal,
                      continueBookingFromWarningModal,
                      continueBookingFromExpiredModal
                    }) => (
                      <Fragment>
                        <SessionWarningModal
                          isOpen={showWarningModal}
                          countdown={sessionTimeoutCounter}
                          onContinueBookingClick={
                            continueBookingFromWarningModal
                          }
                        />
                        <SessionExpiredModal
                          isOpen={showExpiredModal}
                          onContinueBookingClick={
                            continueBookingFromExpiredModal
                          }
                        />
                      </Fragment>
                    )}
                  />
                  <GlobalModals
                    handleRouteChange={handleRouteChange}
                    modalIsOpen={modalIsOpen}
                    openModal={openModal}
                    closeModal={closeModal}
                    modalContinue={modalContinue}
                  />
                </div>
              )}
            </ModalConsumer>
          </ModalProvider>
        </UserProvider>
      </ConfigProvider>
    </Fragment>
  )
}

export default App
