import { selectPromoCodeState } from '../promo/selectors'
import { selectActiveModifyBooking } from '../modifyBooking/selectors'
import { createSelector } from 'reselect'

export const selectCurrentBookingPromoState = createSelector(
  selectActiveModifyBooking,
  selectPromoCodeState,
  (activeModifyBooking, promoState) => {
    if (!activeModifyBooking) {
      return promoState
    } else {
      const { coupons } = activeModifyBooking

      if (coupons?.length) {
        return {
          ...promoState,
          code: coupons[0].coupon,
          valid: true,
          disabled: true
        }
      }

      return promoState
    }
  }
)
