export const actionTypes = {
  SET_TRIP_TYPE: 'userSelections/SET_TRIP_TYPE',
  SET_DEPARTURE_ROUTE: 'userSelections/SET_DEPARTURE_ROUTE',
  SET_RETURN_ROUTE: 'userSelections/SET_RETURN_ROUTE',
  SET_DEPARTURE_SEARCH_DATE: 'userSelections/SET_DEPARTURE_SEARCH_DATE',
  SET_RETURN_SEARCH_DATE: 'userSelections/SET_RETURN_SEARCH_DATE',
  SET_PROMO_CODE: 'userSelections/SET_PROMO_CODE',
  SET_IS_SAME_PASSENGERS: 'userSelections/SET_IS_SAME_PASSENGERS',
  UPDATE_DEPARTURE_PASSENGERS: 'userSelections/UPDATE_DEPARTURE_PASSENGERS',
  UPDATE_RETURN_PASSENGERS: 'userSelections/UPDATE_RETURN_PASSENGERS',
  SET_VEHICLE_FORM_TYPE: 'userSelections/SET_VEHICLE_FORM_TYPE',
  SET_IS_SAME_VEHICLE: 'userSelections/SET_IS_SAME_VEHICLE',
  SET_DEPARTURE_VEHICLE: 'userSelections/SET_DEPARTURE_VEHICLE',
  SET_RETURN_VEHICLE: 'userSelections/SET_RETURN_VEHICLE',
  SET_DEPARTURE_CROSSING: 'userSelections/SET_DEPARTURE_CROSSING',
  SET_RETURN_CROSSING: 'userSelections/SET_RETURN_CROSSING',
  UPDATE_DEPARTURE_ACCOMMODATIONS:
    'userSelections/UPDATE_DEPARTURE_ACCOMMODATIONS',
  UPDATE_RETURN_ACCOMMODATIONS: 'userSelections/UPDATE_RETURN_ACCOMMODATIONS',
  SET_ACCESSIBILITY_RESPONSES: 'userSelections/SET_ACCESSIBILITY_RESPONSES',
  UPDATE_PASSENGER_DETAILS: 'userSelections/UPDATE_PASSENGER_DETAILS',
  SET_BOOKING_DETAILS: 'userSelections/SET_BOOKING_DETAILS',
  SET_IS_FORCES_MEMBER: 'userSelections/SET_IS_FORCES_MEMBER',
  RESET_BOOKING: 'userSelections/RESET_BOOKING',
  SET_MODIFY_BOOKING_NUMBER: 'userSelections/SET_MODIFY_BOOKING_NUMBER',
  SET_BOOKING_ACCOUNT: 'userSelections/SET_BOOKING_ACCOUNT',
  SET_BILLING_INFO: 'userSelections/SET_BILLING_INFO',
  SET_BOOKING_CONSENT: 'userSelections/UPDATE_BOOKING_CONSENT'
}

export const setTripType = tripType => dispatch => {
  return dispatch({
    type: actionTypes.SET_TRIP_TYPE,
    payload: tripType
  })
}
export const setDepartureRoute = departureRoute => dispatch => {
  return dispatch({
    type: actionTypes.SET_DEPARTURE_ROUTE,
    payload: departureRoute // just a route code
  })
}
export const setReturnRoute = returnRoute => dispatch => {
  return dispatch({
    type: actionTypes.SET_RETURN_ROUTE,
    payload: returnRoute
  })
}
export const setDepartureSearchDate = date => dispatch => {
  return dispatch({
    type: actionTypes.SET_DEPARTURE_SEARCH_DATE,
    payload: date
  })
}
export const setReturnSearchDate = date => dispatch => {
  return dispatch({
    type: actionTypes.SET_RETURN_SEARCH_DATE,
    payload: date
  })
}
export const setPromoCode = promoCode => dispatch => {
  return dispatch({
    type: actionTypes.SET_PROMO_CODE,
    payload: promoCode
  })
}
export const setIsSamePassengers = isSamePassengers => dispatch => {
  return dispatch({
    type: actionTypes.SET_IS_SAME_PASSENGERS,
    payload: isSamePassengers
  })
}
export const updateDeparturePassengers = codeQuantities => dispatch => {
  return dispatch({
    type: actionTypes.UPDATE_DEPARTURE_PASSENGERS,
    payload: codeQuantities
  })
}
export const updateReturnPassengers = codeQuantities => dispatch => {
  return dispatch({
    type: actionTypes.UPDATE_RETURN_PASSENGERS,
    payload: codeQuantities
  })
}
export const setVehicleFormType = formType => dispatch => {
  return dispatch({
    type: actionTypes.SET_VEHICLE_FORM_TYPE,
    payload: formType
  })
}
export const setIsSameVehicle = isSameVehicle => dispatch => {
  return dispatch({
    type: actionTypes.SET_IS_SAME_VEHICLE,
    payload: isSameVehicle
  })
}
export const setDepartureVehicle = departureVehicle => dispatch => {
  return dispatch({
    type: actionTypes.SET_DEPARTURE_VEHICLE,
    payload: departureVehicle
  })
}
export const setReturnVehicle = returnVehicle => dispatch => {
  return dispatch({
    type: actionTypes.SET_RETURN_VEHICLE,
    payload: returnVehicle
  })
}
export const setDepartureCrossing = departureCrossing => dispatch => {
  return dispatch({
    type: actionTypes.SET_DEPARTURE_CROSSING,
    payload: departureCrossing
  })
}
export const setReturnCrossing = returnCrossing => dispatch => {
  return dispatch({
    type: actionTypes.SET_RETURN_CROSSING,
    payload: returnCrossing
  })
}
export const updateDepartureAccommodations = departureAccommodations => dispatch => {
  return dispatch({
    type: actionTypes.UPDATE_DEPARTURE_ACCOMMODATIONS,
    payload: departureAccommodations
  })
}
export const updateReturnAccommodations = returnAccommodations => dispatch => {
  return dispatch({
    type: actionTypes.UPDATE_RETURN_ACCOMMODATIONS,
    payload: returnAccommodations
  })
}
export const setAccessibilityResponses = accessibilityResponses => dispatch => {
  return dispatch({
    type: actionTypes.SET_ACCESSIBILITY_RESPONSES,
    payload: accessibilityResponses
  })
}
export const updatePassengerDetails = passengerDetails => dispatch => {
  return dispatch({
    type: actionTypes.UPDATE_PASSENGER_DETAILS,
    payload: passengerDetails
  })
}
// todo: deprecate
export const setBookingDetails = bookingDetails => dispatch => {
  return dispatch({
    type: actionTypes.SET_BOOKING_DETAILS,
    payload: bookingDetails
  })
}
export const setIsForcesMember = isForcesMember => dispatch => {
  return dispatch({
    type: actionTypes.SET_IS_FORCES_MEMBER,
    payload: isForcesMember
  })
}
export const setModifyBookingNumber = bookingNumber => dispatch => {
  return dispatch({
    type: actionTypes.SET_MODIFY_BOOKING_NUMBER,
    payload: bookingNumber
  })
}
export const resetBooking = () => dispatch => {
  return dispatch({ type: actionTypes.RESET_BOOKING, payload: null })
}

export const setBookingAccount = payload => dispatch => {
  return dispatch({ type: actionTypes.SET_BOOKING_ACCOUNT, payload })
}

export const setBillingInfo = payload => dispatch => {
  return dispatch({ type: actionTypes.SET_BILLING_INFO, payload })
}

export const setBookingConsent = payload => dispatch => {
  return dispatch({ type: actionTypes.SET_BOOKING_CONSENT, payload })
}

export const initiateModify = modifyBookingNumber => dispatch => {
  dispatch(resetBooking())
  dispatch(setModifyBookingNumber(modifyBookingNumber))
}
