import moment from 'moment'
import queryString from 'query-string'
import { createSelector } from 'reselect'

import dateFormats from '../../configuration/dateFormats'
import cleanObject from '../../utilities/data/cleanObject'

import { selectQueryParamsRelatedState } from './selectQueryParamsRelatedState'
import { selectCurrentBookingPromoState } from './selectCurrentBookingPromoState'

// using this to make sure I don't add duplicate codes
// const queryParamCodes = {
//  dd:  'departureDate',
//  rd: 'returnDate',
//  tt: 'tripType' ,
//  dr: 'departureRoute',
//  rr: 'returnRoute',
//  a: 'adults',
//  s: 'seniors',
//  c: 'children',
//  i: 'infants',
//  st: 'students' ,
//  p: 'pets',
//  ar: 'returnAdults', // (adultsReturn)
//  sr: 'returnSeniors',
//  str: 'returnStudents',
//   cr: 'returnChildren',
//   ir: 'returnInfants',
//   pr: 'returnPets',
// vt: 'vehicleType'
//
// }

export const selectQueryParams = createSelector(
  selectQueryParamsRelatedState,
  selectCurrentBookingPromoState,
  (
    {
      departureDate,
      departureRoute,
      returnDate,
      returnRoute,
      activeRouteForm,
      departureAdults,
      departureSeniors,
      departureStudents,
      departureChildren,
      departureInfants,
      departurePets,
      returnAdults,
      returnSeniors,
      returnStudents,
      returnChildren,
      returnInfants,
      returnPets,
      duplicatePassengerQuantities,
      language
    },
    promoState
  ) => {
    const result = new URLSearchParams()
    const windowParams = queryString.parse(window.location.search)
    //  TELEPHONE BOOKINGS
    if (windowParams?.data && typeof windowParams?.data === 'string') {
      //  THIS IS TELEPHONE BOOKINGS
      result.append('data', windowParams.data)
      return result.toString()
    }
    let params = {
      dd: departureDate
        ? moment(departureDate).format(dateFormats.default)
        : '',
      rd:
        activeRouteForm !== 'OW' && returnDate
          ? moment(returnDate).format(dateFormats.default)
          : '',
      tt: activeRouteForm,
      dr: departureRoute ? departureRoute.code : '',
      rr: activeRouteForm !== 'OW' && returnRoute ? returnRoute.code : '',
      a: departureAdults,
      s: departureSeniors,
      c: departureChildren,
      i: departureInfants,
      st: departureStudents,
      p: departurePets,
      l: language
      // vehicleType: selectVehicleFormType(state)
    }
    if (promoState?.valid && promoState?.code) {
      params.pc = promoState.code
    }
    if (!duplicatePassengerQuantities) {
      params = {
        ...params,
        ar: returnAdults,
        sr: returnSeniors,
        str: returnStudents,
        cr: returnChildren,
        ir: returnInfants,
        pr: returnPets
      }
    }

    const cleanParams = cleanObject(params)

    for (const [key, val] of Object.entries(cleanParams)) {
      result.append(key, val)
    }

    return result.toString()
  }
)
