import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import yn from 'yn'

import appRoutes from '../../configuration/appRoutes'
import {
  fetchTelephoneBooking,
  checkoutResetAction
} from '../../redux/telephoneBooking/actions'
import { enableCanUseApp } from '../../redux/configuration/actions'
import { setPaymentFerryId } from '../../redux/paymentConfirmation/actions'
import {
  preloadChaseTransaction,
  clearReceipts
} from '../../redux/booking/actions'

import { selectLabels } from '../../redux/configuration/selectors'
import { selectLanguage } from '../../redux/session/selectors'
import { selectPaymentFerryId } from '../../redux/paymentConfirmation/selectors'
import { selectTelephoneBookingState } from '../../redux/telephoneBooking/selectors'

import BrandLoader from '../../components/BrandLoader/BrandLoader'
import ChaseCheckoutContainer from '../../components/ChaseCheckoutContainer/ChaseCheckoutContainer'
import PaymentErrorModal from '../../components/PaymentErrorModal'
import TransactionNotCompleted from '../../components/TransactionNotCompleted/TransactionNotCompleted'

import { preparePreloadChaseData } from '../../components/ChaseCheckoutContainer/utilities'
import { getJWTToken, isJWTTokenSet } from '../../api/api'
const TelephoneBookingContainer = ({
  handleRouteChange,
  openModal,
  closeModal,
  modalIsOpen
}) => {
  const params = queryString.parse(window.location.search)
  const telephoneBookingId = params?.data

  const dispatch = useDispatch()
  const telephoneBookingState = useSelector(selectTelephoneBookingState)
  const lang = useSelector(selectLanguage)
  const labels = useSelector(selectLabels)
  const paymentFerryId = useSelector(selectPaymentFerryId)
  const [fetchingBookingData, setFetchingBookingData] = useState(false)
  const [hasFetchedBookingData, setHasFetchedBookingData] = useState(false)
  const [monerisCheckoutWidth, setMonerisCheckoutWidth] = useState(null)
  const [token, setToken] = useState(null)
  const paymentContainerRef = useRef(null)

  useEffect(() => {
    // we need to override any app disabling on this view
    dispatch(enableCanUseApp())

    if (!isJWTTokenSet()) {
      const tokenCheck = setInterval(() => {
        if (isJWTTokenSet()) {
          setToken(getJWTToken())
          clearInterval(tokenCheck)
        }
      }, 2000)
    } else {
      setToken(getJWTToken())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    const containerWidth = paymentContainerRef?.current?.getBoundingClientRect()
      ?.width

    if (containerWidth && !monerisCheckoutWidth) {
      setMonerisCheckoutWidth(containerWidth >= 672 ? 672 : containerWidth - 2)
    }
  }, [monerisCheckoutWidth])

  useEffect(() => {
    if (
      telephoneBookingId &&
      !telephoneBookingState?.loading &&
      !telephoneBookingState.data &&
      !fetchingBookingData &&
      token
    ) {
      setFetchingBookingData(true)
      // if we use multiple ferryIds we don't pass anything to the api and it will derive it from the booking
      const ferryId = yn(process.env.REACT_APP_REQUIRES_MULTIPLE_FERRY_IDS)
        ? null
        : process.env.REACT_APP_FLOW_CLIENT_ID

      dispatch(fetchTelephoneBooking(telephoneBookingId, ferryId, token)).then(
        res => {
          setHasFetchedBookingData(true)
          dispatch(setPaymentFerryId(res?.payload?.ferryId))
        }
      )
    }
  }, [
    telephoneBookingId,
    fetchingBookingData,
    setFetchingBookingData,
    setHasFetchedBookingData,
    dispatch,
    telephoneBookingState,
    params,
    token
  ])

  const continueCheckoutHandler = () => {
    closeModal({ type: 'payment-error' })
    dispatch(clearReceipts()) // remove receipt error
    dispatch(checkoutResetAction)
    const bookingConfirmationData = telephoneBookingState.data
    const pageData = preparePreloadChaseData({
      ferryId: paymentFerryId,
      bookingConfirmationData,
      lang,
      labels,
      isTelephoneBooking: true
    })
    if (!pageData) return console.warn('>>> preparePreloadChaseData failed!')
    // fetching ticket
    dispatch(
      preloadChaseTransaction(
        { ...pageData.preloadParams },
        bookingConfirmationData.bookingNumber
      )
    )
  }

  // TODO: redirect to home page...
  const cancelCheckoutHandler = () => {
    closeModal({ type: 'payment-error' })
    dispatch(clearReceipts())
    handleRouteChange(appRoutes.account.pathname)
  }
  // TODO: ...???
  const closeCheckoutHandler = () => {
    closeModal({ type: 'payment-error' })
    dispatch(clearReceipts())
  }

  const renderCheckout = () => {
    return (
      <ChaseCheckoutContainer
        bookingConfirmationData={telephoneBookingState.data}
        ferryId={paymentFerryId}
        labels={labels}
        lang={lang}
        handleRouteChange={handleRouteChange}
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        cancelTransactionRouteTarget={'payment-error'}
      />
    )
  }

  // the moneris form is hidden so we'll just show the loader unless we have an error
  return (
    <div id="telephone-booking-ctnr">
      {token &&
      Boolean(hasFetchedBookingData && telephoneBookingState?.error) ? (
        <p className="u-text-center" style={{ margin: '32px 0' }}>
          {telephoneBookingState?.error || 'Please log in to continue.'}
        </p>
      ) : (
        <BrandLoader />
      )}

      {telephoneBookingState?.data && renderCheckout()}

      {/* handle payment error actions */}
      <PaymentErrorModal
        isOpen={Object.keys(modalIsOpen).includes('payment-error')}
        continueLabel={labels.retryPayment}
        cancelLabel={labels.cancelBooking}
        labels={{
          transactionCancelled: 'Transaction cancelled',
          genericPaymentErrorMessage: 'Payment refused!'
        }}
        onCloseButtonClick={closeCheckoutHandler}
        onCancel={cancelCheckoutHandler}
        onContinue={continueCheckoutHandler}
      >
        <TransactionNotCompleted language={lang} />
      </PaymentErrorModal>
    </div>
  )
}

export default TelephoneBookingContainer
