import React, { Fragment, useEffect, useState } from 'react'
import { func } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import appRoutes from '../../configuration/appRoutes'
import { DISABLE_WAITING_ROOM } from '../../configuration/constants'
import {
  fetchConfiguration,
  fetchActiveSessions,
  refreshSession,
  enableCanUseApp
} from '../../redux/configuration/actions'
import { resetStartSessionTimer } from '../../redux/session/actions'
import {
  selectSessionId,
  selectStartSessionTimer
} from '../../redux/session/selectors'
import { clearCustomerAccount } from '../../redux/user/actions'
import { selectBookingState } from '../../redux/booking/selectors'
import { selectFetchingActiveSessions } from '../../redux/configuration/selectors'
import { logout } from '../../api/api'

const msToHoursAndMinutes = duration => {
  let seconds = Math.floor((duration / 1000) % 60)
  let minutes = Math.floor((duration / (1000 * 60)) % 60)

  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return minutes + ':' + seconds
}

const SessionTimer = ({ render, handleRouteChange }) => {
  const dispatch = useDispatch()
  const startSessionTimer = useSelector(selectStartSessionTimer)
  const [sessionTimeoutCounter, setSessionTimeoutCounter] = useState(0)
  const [
    initialActiveSessionsFetched,
    setInitialActiveSessionsFetched
  ] = useState(false)
  const [timerLoading, setTimerLoading] = useState(true)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [showExpiredModal, setShowExpiredModal] = useState(false)
  const fetchingActiveSessions = useSelector(selectFetchingActiveSessions)
  const location = useLocation()
  const sessionId = useSelector(selectSessionId)
  const triggerWarningAt =
    process.env.REACT_APP_SESSION_TIMEOUT -
    process.env.REACT_APP_SESSION_TIME_UNTIL_WARNING
  const bookingData = useSelector(selectBookingState)
  useEffect(() => {
    /*
      We need to not trigger the modal when returning from moneris.
      The path isn't available in time so we need to check if they have a booking in state

      If pathname is telephoneBooking.pathname
      dont fetch active sessions
      to avoid triggering the waiting room modal
      telephoneBooking should not be disabled when the session limit is hit
    */
    if (
      sessionId &&
      !initialActiveSessionsFetched &&
      !fetchingActiveSessions &&
      !location.pathname.includes(appRoutes.telephoneBooking.pathname) &&
      !location.pathname.includes(appRoutes.bookingSuccess.pathname) &&
      !bookingData.data &&
      !DISABLE_WAITING_ROOM
    ) {
      dispatch(fetchActiveSessions({ sessionId, pathname: location.pathname }))
      setInitialActiveSessionsFetched(true)
    }
  }, [
    dispatch,
    sessionId,
    location.pathname,
    timerLoading,
    initialActiveSessionsFetched,
    bookingData.data,
    fetchingActiveSessions
  ])

  useEffect(() => {
    if (startSessionTimer) {
      dispatch(resetStartSessionTimer())
      setSessionTimeoutCounter(process.env.REACT_APP_SESSION_TIMEOUT)
      setTimerLoading(false)
    }
  }, [startSessionTimer, dispatch])

  useEffect(() => {
    // Remove a second every second
    const timer =
      sessionTimeoutCounter > 0 &&
      setInterval(() => {
        const newTimeRemaining = sessionTimeoutCounter - 1000

        if (newTimeRemaining === triggerWarningAt) {
          setShowWarningModal(true)
        }

        if (newTimeRemaining === 0) {
          setShowWarningModal(false)
          setShowExpiredModal(true)
        }

        return setSessionTimeoutCounter(newTimeRemaining)
      }, 1000)
    return () => clearInterval(timer)
  }, [sessionTimeoutCounter, triggerWarningAt, dispatch])

  const continueBookingFromWarningModal = () => {
    setShowWarningModal(false)
    dispatch(refreshSession())
  }

  const continueBookingFromExpiredModal = async () => {
    await logout()
    setShowExpiredModal(false)
    handleRouteChange(appRoutes.ferryRoutes.pathname)

    if (!DISABLE_WAITING_ROOM) {
      dispatch(fetchActiveSessions())
    } else {
      dispatch(enableCanUseApp())
    }

    dispatch(fetchConfiguration())
    dispatch(clearCustomerAccount())
  }

  return (
    <Fragment>
      {render({
        sessionTimeoutCounter: timerLoading
          ? msToHoursAndMinutes(process.env.REACT_APP_SESSION_TIMEOUT)
          : msToHoursAndMinutes(sessionTimeoutCounter),
        showWarningModal,
        showExpiredModal,
        continueBookingFromWarningModal,
        continueBookingFromExpiredModal
      })}
    </Fragment>
  )
}

SessionTimer.propTypes = {
  render: func.isRequired,
  handleRouteChange: func.isRequired
}

export default SessionTimer
