import passengerTypeCodes from '../../configuration/passengerTypeCodes'

const formatExitingBookingToRequestBody = (
  booking,
  ferryRoutes = process.env.REACT_APP_FLOW_CLIENT_ID,
  language = process.env.REACT_APP_DEFAULT_LANG_CODE
) => {
  const {
    acceptsContact,
    address,
    amountOwing,
    customerNumber,
    departureGuestCounts,
    departures,
    returnGuestCounts,
    samePassengersAllTrips,
    productCode
  } = booking

  const billingInfo = {
    address: address?.address,
    city: address?.city,
    countryCode: address?.countryCode,
    county: address?.county,
    email: address?.email,
    mobilePhoneNumber: address?.mobilePhoneNumber,
    name: address?.name,
    postCode: address?.postCode
  }

  const formattedDepartures = []

  if (departures?.length) {
    formattedDepartures.push(
      generateDepartureData({
        departure: departures[0],
        guestCount: departureGuestCounts
      })
    )
  }

  if (departures[1]) {
    formattedDepartures.push(
      generateDepartureData({
        departure: departures[1],
        guestCount: returnGuestCounts ? returnGuestCounts : departureGuestCounts
      })
    )
  }

  const departureCode = departures?.[0]?.routeCode

  const routeDetails =
    ferryRoutes && ferryRoutes.find(route => route.code === departureCode)
  const ferryId = routeDetails?.ferryId

  return {
    amountOwing,
    acceptsContact,
    billingInfo,
    commitChanges: 'true',
    consent: 'true',
    currency: 'CAD', // this may only work for CTMA
    customerNumber,
    departures: formattedDepartures,
    ferryId,
    language,
    productCode,
    samePassengersOnReturn: samePassengersAllTrips
  }
}

function generateDepartureData({ departure, guestCount }) {
  if (!departure) {
    return {}
  }

  // todo: we'll need to know what accommodations look like.
  //  currently not available in ctma.
  // PASSENGER CODES eg: adults: 2|A
  const formattedPassengers = guestCount
    ? Object.entries(guestCount).reduce((obj, [guestTypeKey, guestCount]) => {
        if (+guestCount > 0) {
          const code = Object.values(passengerTypeCodes).find(
            typeCode => typeCode.code === guestTypeKey
          )
          if (code) {
            obj[code.apiCode] = `${guestCount}|${code.type}`
          }
        }
        return obj
      }, {})
    : {}

  const { departureDate, departureTime, routeCode, accommodations } = departure

  // PASSENGERS
  const passengerResourceCode =
    departure?.passengerPriceLines?.[0]?.resourceCode
  const passengerTicketType = departure?.passengers?.booked?.[0]?.ticketType
  const passengers = departure?.passengers?.booked?.length
    ? departure?.passengers?.booked.map(passenger => {
        const { firstName, lastName, gender, passengerType } = passenger
        return {
          firstName,
          lastName,
          gender,
          passengerType
        }
      })
    : [
        {
          firstName: 'Passenger 0',
          lastName: 'LASTNAME 0',
          gender: 'M',
          passengerType: 'A'
        }
      ]

  // VEHICLE
  // currently you can only book one vehicle in each direction but we get an array.
  const vehicleData = departure?.vehicles?.booked?.[0]
  const vehicle = vehicleData
    ? {
        height: vehicleData?.height || '',
        length: vehicleData?.length || '',
        resourceCode: vehicleData?.resourceCode || ''
      }
    : ''
  const vehicleTicketType =
    departure?.vehicles?.booked?.[0]?.ticketType || passengerTicketType

  // ACCOMMODATIONS
  // FIXME: weird! number/place/waitList could not be set!
  // or complain: invalid number or place!
  const { amount, booked } = accommodations
  const originalAccommodations = booked.map(accommodation => ({
    amount,
    resourceCode: accommodation.resourceCode,
    number: '',
    place: '',
    waitList: ''
  }))

  const result = {
    ...formattedPassengers,
    departureDate: `${departureDate}T00:00:00`,
    departureTime,
    passengerResourceCode,
    passengerTicketType,
    passengers,
    supplierCode: routeCode,
    vehicleTicketType,
    vehicle: null, // vehicle placeholder
    // add accommodation data ...
    accommodations: originalAccommodations,
    // TODO: maybe need change, currently no way to check
    accommodationTicketType: 'REG'
  }

  if (vehicle) {
    result.vehicle = vehicle
  }

  return result
}

export default formatExitingBookingToRequestBody
