import React from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'

import { MiniSpinner } from '../LoadingSpinner/LoadingSpinner'

import { selectLabels } from '../../redux/configuration/selectors'
import { forgotPassword } from '../../configuration/appRoutes'

const NormalLoginForm = ({ loginHandler, loginError }) => {
  const labels = useSelector(selectLabels)
  const initValues = { emailAddress: '', password: '' }

  const validators = values => {
    const errors = {}
    if (!values.emailAddress) {
      errors.emailAddress = 'Email Required'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.emailAddress)
    ) {
      errors.emailAddress = 'Invalid email address'
    }
    if (!values.password) {
      errors.password = 'Password Required'
    }
    return errors
  }

  return (
    <Formik
      initialValues={initValues}
      validate={validators}
      onSubmit={loginHandler}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form className="mini-form" onSubmit={handleSubmit}>
          <input
            type="email"
            name="emailAddress"
            placeholder="EMAIL ADDRESS"
            className="text-input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.emailAddress}
          />
          <span className="error">
            {errors.emailAddress && touched.emailAddress && errors.emailAddress}
          </span>
          <input
            type="password"
            name="password"
            placeholder="PASSWORD"
            className="text-input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
          <span className="error">
            {errors.password && touched.password && errors.password}
          </span>
          {loginError && <span className="error">{loginError}</span>}
          <div className="forgot-password">
            <a className="form-input-link" href={forgotPassword.pathname}>
              {labels.forgotPassword}
            </a>
          </div>
          <button
            type="submit"
            className="btn btn-primary wider bold center-self submit-btn"
            disabled={isSubmitting}
          >
            {labels.login}
            {isSubmitting && <MiniSpinner />}
          </button>
        </form>
      )}
    </Formik>
  )
}

export default NormalLoginForm
