export default {
  en: {
    value: 'en',
    monerisValue: 'en-ca'
  },
  fr: {
    value: 'fr',
    monerisValue: 'fr-ca'
  }
}
