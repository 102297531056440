import { createSelector } from 'reselect'
import { selectBicycleResourceCode } from '../configuration/selectors'
import {
  selectActiveModifyBooking,
  selectModifyFlowType
} from '../modifyBooking/selectors'
import { selectActiveRouteForm } from '../ferryRouteSelections/selectors'

import { getVehicleFilterOptions } from '../vehicleSelections/constants'
import {
  ENABLE_BICYCLE_OPTION,
  ModifyFlowType
} from '../../configuration/constants'

const selectAreYouBringingAVehicleOptions = createSelector(
  selectActiveRouteForm,
  selectActiveModifyBooking,
  selectModifyFlowType,
  selectBicycleResourceCode,
  (
    activeRouteForm,
    activeModifyBooking,
    modifyFlowType,
    bicycleResourceCode
  ) => {
    const allVehicleFilterOptions = getVehicleFilterOptions()

    // Reduce all -> set allowed by trip type
    const vehicleOptionsForTripType = Object.entries(
      allVehicleFilterOptions
    ).reduce((acc, cur) => {
      const [curKey, curValue] = cur
      if (curValue.tripTypes.includes(activeRouteForm)) {
        acc[curKey] = curValue
      }
      return acc
    }, {})

    // This is sufficient if we're not modifying a checked-in departure round-trip
    if (
      !activeModifyBooking ||
      modifyFlowType !== ModifyFlowType.DEPARTURE_LOCKED
    ) {
      return Object.keys(vehicleOptionsForTripType)
    }

    // Otherwise, let's look at what was booked before and only allow ones that wouldn't
    //  impact the departure's choice
    const {
      departureVehicleCode,
      sameVehicleBothDirection
    } = activeModifyBooking

    if (sameVehicleBothDirection && !departureVehicleCode) {
      // walk-on only
      return ['VEH-WLK']
    } else if (
      ENABLE_BICYCLE_OPTION &&
      departureVehicleCode === bicycleResourceCode
    ) {
      // bicycle option only
      return ['VELO']
    } else {
      // some sort of vehicle....
      return activeRouteForm === 'RT' ? ['VEH-DUP', 'VEH-UNQ'] : ['VEH-UNQ']
    }
  }
)

export default selectAreYouBringingAVehicleOptions
