import {
  selectActiveRouteForm,
  selectDepartureDate,
  selectDepartureRoute,
  selectReturnDate,
  selectReturnRoute
} from '../ferryRouteSelections/selectors'
import { createSelector } from 'reselect'

export const selectQueryParamDatesAndRoutes = createSelector(
  selectDepartureDate,
  selectReturnDate,
  selectDepartureRoute,
  selectReturnRoute,
  selectActiveRouteForm,
  (
    departureDate,
    returnDate,
    departureRoute,
    returnRoute,
    activeRouteForm
  ) => ({
    departureDate,
    departureRoute,
    returnDate,
    returnRoute,
    activeRouteForm
  })
)
