import React from 'react'
import { string, array, bool, shape } from 'prop-types'
import ConfigConsumer from '../../redux/configuration/context'
import SvgIcon from '../SvgIcon/SvgIcon'

import './booking-confirmation-list.scss'

const BookingConfirmationList = ({
  headerLabel,
  summary,
  listData,
  centerHeader,
  labels,
  id
}) => {
  const items = listData.map((row, key) => {
    return (
      <>
        {key !== 0 && <hr />}
        <div
          className={`booking-confirmation-${key} booking-confirmation-list__row`}
        >
          <h4 className="booking-confirmation-list__row__subheader">
            {row.subheader}
          </h4>
          <div className="booking-confirmation-list__row__value">
            {row.value}
          </div>
          <div className="booking-confirmation-list__row__date">{row.date}</div>
          <div className="booking-confirmation-list__row__vehicles">
            {row.vehicles &&
              row.vehicles.map((vehicle, key) => (
                <div
                  className="booking-confirmation-list__row__vehicle"
                  key={`booking-${id}-vehicle-${key}`}
                >
                  <img
                    src={vehicle.path}
                    alt={vehicle.description}
                    caption={vehicle.caption}
                  />{' '}
                  {vehicle.caption}
                </div>
              ))}
          </div>
          {row.passengers && (
            <div className="booking-confirmation-list__row__passengers">
              {row.passengers}
            </div>
          )}
          <span className="booking-confirmation-list__row__price">
            <span className="booking-confirmation-list__row__price__label">
              {labels.total || 'Total'}:
            </span>{' '}
            {row.price}
          </span>
        </div>
      </>
    )
  })

  return (
    <div className="booking-confirmation-list">
      {headerLabel && (
        <h3
          className={`booking-confirmation-list__header ${
            centerHeader ? 'center' : ''
          }`}
        >
          {headerLabel}
        </h3>
      )}
      {summary && (
        <div className={'booking-confirmation-list__summary'}>
          <span className="booking-confirmation-list__summary__icon">
            {summary.icon && (
              <ConfigConsumer>
                {({ themeVariables }) => (
                  <td className="summary-table__row-icon">
                    <SvgIcon
                      type={summary.icon}
                      className="summary-icon"
                      fill={themeVariables.themeIconColor}
                    />
                  </td>
                )}
              </ConfigConsumer>
            )}
          </span>
          <div className="booking-confirmation-list__summary__details">
            <div className="booking-confirmation-list__summary__details__label">
              {summary.label}
            </div>
            <div className="booking-confirmation-list__summary__details__date">
              {summary.date}
            </div>
            {summary.price && (
              <span className="booking-confirmation-list__summary__details__price">
                <span className="booking-confirmation-list__summary__details__price__label">
                  {labels.total}:
                </span>{' '}
                {summary.price}
              </span>
            )}
          </div>
        </div>
      )}
      {items?.length > 0 && (
        <>
          <hr />
          <div className="booking-confirmation-list__list">{items}</div>
        </>
      )}
    </div>
  )
}
BookingConfirmationList.defaultProps = {
  headerValue: '',
  id: ''
}
BookingConfirmationList.propTypes = {
  headerLabel: string.isRequired,
  summary: shape({}).isRequired,
  listData: array.isRequired,
  centerHeader: bool,
  labels: shape({}).isRequired,
  id: string
}

export default BookingConfirmationList
