import React from 'react'
import PropTypes, { shape, func, string, bool } from 'prop-types'
import { useSelector } from 'react-redux'
import { selectLabels } from '../../redux/configuration/selectors'
import dateFormats from '../../configuration/dateFormats'
import { selectLanguage } from '../../redux/session/selectors'
import CrossingCardContainer from '../crossings/CrossingCardContainer'
import BrandLoader from '../BrandLoader/BrandLoader'
import CrossingSlider from '../crossings/CrossingSlider/CrossingSlider'
import isRouteCurrentSelection from '../../utilities/crossings/isRouteCurrentSelection'
import shouldDisableCrossing from '../../utilities/crossings/shouldDisableCrossing'

import noCrossingIcon from '../../assets/icons/no-crossings-icon.svg'
import shouldDisplayNoPassengerMessage from '../../utilities/crossings/shouldDisplayNoPassengerMessage'

const HighlightedCrossingRouteSelection = ({
  crossings,
  departureDate,
  selectCrossing,
  currentSelection,
  continueButtonText,
  continueIsDisabled,
  continueClickHandler,
  newRoutesClickHandler,
  isLoading,
  activeVehicleResourceCode,
  activeVehicle,
  titleLabel,
  totalPassengers,
  cabinResourceCodes
}) => {
  const labels = useSelector(selectLabels)
  const language = useSelector(selectLanguage)

  const dayKey = departureDate
    ? departureDate.format(dateFormats.default)
    : null

  const dayOfCrossings = dayKey && crossings?.[dayKey]

  const displayDate =
    departureDate &&
    departureDate.format(
      language === 'fr'
        ? dateFormats.longDisplayFrench
        : dateFormats.longDisplay
    )

  const renderTitle = titleLabel => {
    if (titleLabel === labels.return) {
      return `${labels.returnSailingsOn} ${displayDate}`
    }
    return `${labels.departureSailingsOn} ${displayDate}`
  }

  return (
    <div className="crossing-selections u-container u-page-view-container">
      <div className="crossings">
        {isLoading ? (
          <BrandLoader />
        ) : (
          <>
            <h1 className="page-title theme-font-header">
              {renderTitle(titleLabel)}
            </h1>

            <div className="day-of-crossings">
              {dayOfCrossings?.length > 0 ? (
                <>
                  {dayOfCrossings.map(crossing => {
                    const { departureDate, departureTime } = crossing
                    return (
                      <div key={departureDate + '-' + departureTime}>
                        <CrossingCardContainer
                          crossing={crossing}
                          selectCrossing={selectCrossing}
                          isCurrentlySelectedRoute={isRouteCurrentSelection(
                            currentSelection,
                            crossing
                          )}
                          isDisabled={shouldDisableCrossing(
                            crossing,
                            activeVehicleResourceCode,
                            activeVehicle,
                            totalPassengers
                          )}
                          shouldDisplayNoPassengerMessage={shouldDisplayNoPassengerMessage(
                            { crossing, totalPassengers }
                          )}
                          activeVehicleResourceCode={activeVehicleResourceCode}
                          activeVehicle={activeVehicle}
                          cabinResourceCodes={cabinResourceCodes}
                        />
                      </div>
                    )
                  })}
                </>
              ) : (
                <div className="no-crossing-on-selected-dates">
                  <img src={noCrossingIcon} alt="" role="none" />
                  <p className="u-bold">
                    {labels.noCrossingsOn} {displayDate}
                  </p>
                </div>
              )}
            </div>
            {crossings && Object.keys(crossings).length > 0 && (
              <div>
                <h3 className="more-crossings-title">{labels.otherSailings}</h3>

                <div className="crossings-container">
                  <CrossingSlider
                    crossings={crossings}
                    departureDate={departureDate}
                    selectCrossing={selectCrossing}
                    currentSelection={currentSelection}
                    activeVehicleResourceCode={activeVehicleResourceCode}
                    activeVehicle={activeVehicle}
                    labels={labels}
                    totalPassengers={totalPassengers}
                    cabinResourceCodes={cabinResourceCodes}
                  />
                </div>
                {/* This label is per client, don't add to local defaults */}
                {labels.selectCrossingVesselMessage && (
                  <p className="u-text-center u-font-italic">
                    {labels.selectCrossingVesselMessage}
                  </p>
                )}
              </div>
            )}

            {crossings && !Object.keys(crossings).length ? (
              <div className="u-text-center">
                <button
                  className="btn btn-primary large-primary-btn"
                  onClick={() => {
                    if (newRoutesClickHandler) newRoutesClickHandler()
                  }}
                >
                  Select Different Dates
                </button>
              </div>
            ) : (
              <div className="crossing-submit-container u-page-submit-container">
                <button
                  className="btn btn-primary large-primary-btn"
                  disabled={!continueIsDisabled || isLoading ? '' : 'disabled'}
                  onClick={continueClickHandler}
                >
                  {continueButtonText} →
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

HighlightedCrossingRouteSelection.propTypes = {
  crossings: shape({}),
  departureDate: shape({}),
  selectCrossing: func,
  currentSelection: shape({}),
  continueButtonText: string,
  continueIsDisabled: bool,
  continueClickHandler: func,
  newRoutesClickHandler: func,
  isLoading: bool,
  activeVehicleResourceCode: string,
  activeVehicle: shape({}),
  cabinResourceCodes: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default HighlightedCrossingRouteSelection
