import React, { Fragment, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { bool, func } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import StatusModal from '../StatusModal/StatusModal'
import appRoutes from '../../configuration/appRoutes'
import { fetchActiveSessions } from '../../redux/configuration/actions'
import { isCurrentPath } from '../../utilities/browser/comparePaths'
import {
  selectLabels,
  selectFetchingActiveSessions,
  selectCanUseApp,
  selectActiveSessions,
  selectActiveSessionsError
} from '../../redux/configuration/selectors'
import { selectLanguage, selectSessionId } from '../../redux/session/selectors'
import statusModalImage from '../../assets/images/brand-status-image.png'
import BrandLoader from '../BrandLoader/BrandLoader'

const WaitingRoomModalContainer = ({
  onContinueClick,
  isOpen,
  openModal,
  closeModal
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const labels = useSelector(selectLabels)
  const canUseApp = useSelector(selectCanUseApp)
  const fetchingActiveSessions = useSelector(selectFetchingActiveSessions)
  const sessionId = useSelector(selectSessionId)
  const language = useSelector(selectLanguage)
  const activeSessions = useSelector(selectActiveSessions)
  const activeSessionError = useSelector(selectActiveSessionsError)

  useEffect(() => {
    if (
      !isOpen &&
      !canUseApp &&
      !location.pathname.includes(
        appRoutes.updatePassword.pathnameWithoutParams
      ) &&
      !isCurrentPath(appRoutes.telephoneBooking.pathname)
    ) {
      openModal({ type: 'waiting-room' })
    }
  }, [canUseApp, openModal, isOpen, location.pathname])

  useEffect(() => {
    if (
      canUseApp &&
      isOpen &&
      !isCurrentPath(appRoutes.telephoneBooking.pathname)
    )
      onContinueClick()
  }, [
    closeModal,
    canUseApp,
    isOpen,
    language,
    onContinueClick,
    location.pathname
  ])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!fetchingActiveSessions && !canUseApp && sessionId) {
        dispatch(fetchActiveSessions({ sessionId }))
      }
    }, process.env.REACT_APP_CHECK_SESSION_LIMIT_INTERVAL)
    return () => clearInterval(interval)
  }, [dispatch, fetchingActiveSessions, openModal, canUseApp, sessionId])

  // we want to only show the messaging for the waiting room if we have
  // fetched active sessions and either gotten a response or an error.
  //  ps sorry about the !== false check but sessions could potentially be 0.
  //  and the initial state in redux is false
  const hasReceivedActiveSessions = Boolean(
    activeSessions !== false || activeSessionError
  )

  // because some clients fire this right away we don't want to show an empty modal
  // we'll show the loader instead.
  const hasLabels = Boolean(Object.keys(labels).length)
  return (
    <StatusModal
      backgroundUrl={statusModalImage}
      backgroundAltLabel={labels.busierThanNormal}
      labels={labels}
      onContinueClick={onContinueClick}
      isOpen={isOpen}
    >
      {hasLabels && hasReceivedActiveSessions ? (
        <Fragment>
          <h4 className="modal-col-title with-subtitle theme-font-header">
            {labels.busierThanNormal}
          </h4>
          <p>{labels.thanksForPatience}</p>
          <h6 className="modal-col-subtitle theme-font-header">
            {labels.haveReady}
          </h6>
          <ul className="modal-text-list">
            {labels.departureAndReturnDates && (
              <li>{labels.departureAndReturnDates}</li>
            )}
            {labels.vehicleLengthInfo && <li>{labels.vehicleLengthInfo}</li>}
            {labels.passengerInfo && <li>{labels.passengerInfo}</li>}
            {labels.billingInfo && <li>{labels.billingInfo}</li>}
          </ul>
          <p className="modal-subtext">{labels.sessionWarning}</p>
        </Fragment>
      ) : (
        <BrandLoader />
      )}
    </StatusModal>
  )
}

WaitingRoomModalContainer.propTypes = {
  onContinueClick: func.isRequired,
  isOpen: bool.isRequired,
  openModal: func.isRequired,
  closeModal: func.isRequired
}

export default WaitingRoomModalContainer
