import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { object, bool, func, string, array, element } from 'prop-types'
import { useSelector } from 'react-redux'
import { getAvailableSeating } from '../../api/api'
import { selectLabels } from '../../redux/configuration/selectors'
import { formatPriceByLanguage } from '../../redux/configuration/utilities'
import { selectLanguage, selectSessionId } from '../../redux/session/selectors'
import LoadingDots from '../LoadingDots/LoadingDots'

const ReservedSeatCard = ({
  onDepartureClick,
  onReturnClick,
  showReturnButton,
  showDepartureButton,
  resourceCode,
  startingPrice,
  departureCrossing,
  returnCrossing
}) => {
  const [
    hasAvailableSeatingDeparture,
    setHasAvailableSeatingDeparture
  ] = useState({
    loading: false,
    error: false,
    success: false
  })
  const [hasAvailableSeatingReturn, setHasAvailableSeatingReturn] = useState({
    loading: false,
    error: false,
    success: false
  })

  const labels = useSelector(selectLabels)
  const lang = useSelector(selectLanguage)
  const sessionId = useSelector(selectSessionId)
  const seatData = useSelector(
    state =>
      state?.configurationData?.content?.[lang]?.accommodationView
        ?.accommodations?.[resourceCode]
  )
  const title = seatData?.title
  const imageUrl = seatData?.images?.[0]?.path
  const textContent = seatData?.textBlocks?.[0]

  useEffect(() => {
    if (
      !hasAvailableSeatingDeparture?.loading &&
      !hasAvailableSeatingDeparture?.error &&
      !hasAvailableSeatingDeparture?.success
    ) {
      setHasAvailableSeatingDeparture({
        loading: true,
        error: false,
        success: false
      })

      getAvailableSeating({
        lang,
        sessionId,
        supplierCode: departureCrossing.supplier,
        resourceCode: 'RDS',
        startDate: moment(departureCrossing.departureDate).format('YYYY-MM-DD'),
        startTime: departureCrossing.departureTime,
        productCode: departureCrossing.productCode,
        passengerType: departureCrossing.ticketType
      })
        .then(res => {
          if (res?.data) {
            setHasAvailableSeatingDeparture({
              loading: false,
              success: res.data,
              error: false
            })
          } else {
            setHasAvailableSeatingDeparture({
              loading: false,
              success: false,
              error: true
            })
          }
        })
        .catch(() => {
          setHasAvailableSeatingDeparture({
            loading: false,
            error: true,
            success: false
          })
        })
    }

    if (
      returnCrossing &&
      !hasAvailableSeatingReturn?.loading &&
      !hasAvailableSeatingReturn?.success &&
      !hasAvailableSeatingReturn?.error
    ) {
      setHasAvailableSeatingReturn({
        loading: true,
        error: false,
        success: false
      })
      getAvailableSeating({
        lang,
        sessionId,
        supplierCode: returnCrossing.supplier,
        resourceCode: 'RDS',
        startDate: moment(returnCrossing.departureDate).format('YYYY-MM-DD'),
        startTime: returnCrossing.departureTime,
        productCode: returnCrossing.productCode,
        passengerType: returnCrossing.ticketType
      })
        .then(res => {
          if (res?.data) {
            setHasAvailableSeatingReturn({
              success: res.data,
              error: false,
              loading: false
            })
          } else {
            setHasAvailableSeatingReturn({
              success: false,
              error: true,
              loading: false
            })
          }
        })
        .catch(() => {
          setHasAvailableSeatingReturn({
            success: false,
            error: true,
            loading: false
          })
        })
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAvailableSeatingDeparture, hasAvailableSeatingReturn])

  const seatPlannerUnavailableMsg =
    lang === 'en'
      ? 'The seat picker is currently unavailable while we work to restore the connection.'
      : 'Le sélecteur de sièges est actuellement indisponible pendant que nous travaillons à rétablir la connexion'

  return (
    <div className="extras__card">
      <img src={imageUrl} alt="" role="none" />
      <div className="extras__card-content with-image">
        <div>
          <h4>{title}</h4>
          <p>{textContent}</p>
        </div>
        <div className="extras__card-bottom">
          <div className="extras__card-start">
            <div className="extras__card-start-label">
              {labels.startingFrom}
            </div>
            <div className="extras__card-start-value">
              {formatPriceByLanguage(startingPrice, lang)}
            </div>
            <div className="extras__card-start-label">{labels.perSeat}</div>
          </div>
          <div className="extras__card-actions">
            {showDepartureButton &&
              (hasAvailableSeatingDeparture?.success ||
                hasAvailableSeatingDeparture?.loading) && (
                <button
                  onClick={onDepartureClick}
                  disabled={hasAvailableSeatingDeparture?.loading}
                >
                  {hasAvailableSeatingDeparture?.loading ? (
                    <LoadingDots />
                  ) : (
                    labels.departureSeats
                  )}
                </button>
              )}
            {showDepartureButton && hasAvailableSeatingDeparture?.error && (
              <p className="u-error-color">
                {labels.seatPlannerUnavailable || seatPlannerUnavailableMsg}
              </p>
            )}
            {showReturnButton &&
              (hasAvailableSeatingReturn?.success ||
                hasAvailableSeatingReturn?.loading) && (
                <button
                  onClick={onReturnClick}
                  disabled={hasAvailableSeatingReturn?.loading}
                >
                  {hasAvailableSeatingReturn?.loading ? (
                    <LoadingDots />
                  ) : (
                    labels.returnSeats
                  )}
                </button>
              )}
            {showReturnButton &&
              !showDepartureButton &&
              hasAvailableSeatingReturn.error && (
                <p className="u-error-color">
                  {labels.seatPlannerUnavailable || seatPlannerUnavailableMsg}
                </p>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

ReservedSeatCard.propTypes = {
  labels: object.isRequired,
  content: array,
  imageUrl: string.isRequired,
  buttonDisabled: bool,
  buttonText: string.isRequired,
  onButtonClick: func.isRequired,
  startingFrom: string,
  warningHeader: element,
  priceUnitLabel: string
}

export default ReservedSeatCard
