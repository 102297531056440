import { createSelector } from 'reselect'
import { selectConfigurationData } from '../configuration/selectors'
import { selectActiveModifyBooking } from '../modifyBooking/selectors'

const selectFerryRoutesModifyPrefillData = createSelector(
  selectConfigurationData,
  selectActiveModifyBooking,
  (configuration, activeModifyBooking) => {
    if (!activeModifyBooking) {
      return null
    }

    const tripType = activeModifyBooking.isReturnTrip ? 'RT' : 'OW'

    let departureRoute,
      returnRoute,
      departureDate = activeModifyBooking?.departureRoute?.departureDate,
      returnDate = activeModifyBooking?.returnRoute?.departureDate

    if (configuration?.ferry?.options?.routes) {
      for (const route of configuration.ferry.options.routes) {
        if (
          activeModifyBooking?.departureRoute &&
          route.code === activeModifyBooking.departureRoute?.routeCode
        ) {
          departureRoute = route
        } else if (
          activeModifyBooking?.returnRoute &&
          route.code === activeModifyBooking.returnRoute?.routeCode
        ) {
          returnRoute = route
        }
      }
    }

    const ferryId = departureRoute?.ferryId

    return {
      tripType,
      departureDate,
      departureRoute,
      returnDate, // maybe null
      returnRoute, // maybe null
      ferryId // for setActiveRouteGrouping
    }
  }
)

export default selectFerryRoutesModifyPrefillData
