import FerryRoutesRoundTripForm from '../components/ferryRoutes/FerryRoutesRoundTripForm'
import FerryRoutesOneWayForm from '../components/ferryRoutes/FerryRoutesOneWayForm'

const ferryRoutesForms = [
  {
    type: 'RT',
    component: FerryRoutesRoundTripForm,
    labelKey: 'roundTrip'
  },
  {
    type: 'OW',
    component: FerryRoutesOneWayForm,
    labelKey: 'oneWay'
  }
]
export default ferryRoutesForms
