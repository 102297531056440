import React from 'react'
import { func } from 'prop-types'
import { useSelector } from 'react-redux'
import { selectLanguage } from '../../redux/session/selectors'
import {
  selectLabels,
  selectCustomerLanguages,
  selectFetchingLabels
} from '../../redux/configuration/selectors'
import LanguageToggleDefault from './layouts/LanguageToggleDefault'
import { languageLabelMap } from './constants'
import { isCurrentPath } from '../../utilities/browser/comparePaths'
import {
  appRoutes,
  ENABLE_MONERIS_CHECKOUT
} from '../../configuration/constants'

const LanguageToggle = ({ handleToggleLanguage }) => {
  const activeLanguage = useSelector(selectLanguage)
  const labels = useSelector(selectLabels)
  const customerLanguages = useSelector(selectCustomerLanguages)
  const fetchingLabels = useSelector(selectFetchingLabels)
  const buttonProps = {}
  customerLanguages.forEach(langKey => {
    const label = languageLabelMap[langKey] && labels[languageLabelMap[langKey]]

    if (label) {
      buttonProps[langKey] = {
        key: 'language-' + langKey,
        className: `header-toggle__button ${
          String(langKey) === String(activeLanguage) ? ' active-language' : ''
        }`,
        onClick: () => handleToggleLanguage(langKey),
        label,
        disabled: fetchingLabels
      }
    }
  })

  /*
   * Moneris checkout does not handle language toggles well, the language in the iFrame will not update
   * and if we are switching languages for the first time on the payment page it will kill the form altogether
   * */
  const isDisabled =
    isCurrentPath(appRoutes.payment.pathname) && ENABLE_MONERIS_CHECKOUT

  return customerLanguages.length < 2 ? null : (
    <div className="header-toggle header__content-item">
      <LanguageToggleDefault buttonProps={buttonProps} disabled={isDisabled} />
    </div>
  )
}

LanguageToggle.propTypes = {
  handleToggleLanguage: func.isRequired
}

export default LanguageToggle
