import React from 'react'
import { string, bool, func } from 'prop-types'

import './TitleWithTrigger.scss'

const TitleWithTrigger = ({
  title,
  showTooltip,
  isTitleClickable,
  onClick,
  centered,
  containerClasses,
  titleClasses
}) => {
  return (
    <div
      className={`title-with-trigger ${containerClasses} ${
        isTitleClickable ? 'clickable' : ''
      } ${centered ? 'centered' : ''}`}
    >
      {title && (
        <h4
          className={`title theme-font-header u-remove-margin ${titleClasses}`}
          onClick={() => {
            if (isTitleClickable && onClick) {
              onClick()
            }
          }}
        >
          {title}
        </h4>
      )}

      {showTooltip && (
        <div
          onClick={() => {
            if (isTitleClickable && onClick) {
              onClick()
            }
          }}
          className="trigger-container"
        >
          <span className="trigger theme-title-variant">?</span>
        </div>
      )}
    </div>
  )
}

TitleWithTrigger.defaultProps = {
  centered: false,
  containerClasses: '',
  isTitleClickable: false,
  showTooltip: false,
  titleClasses: ''
}

TitleWithTrigger.propTypes = {
  centered: bool,
  containerClasses: string,
  isTitleClickable: bool,
  onClick: func,
  showTooltip: bool,
  title: string,
  titleClasses: string
}

export default TitleWithTrigger
