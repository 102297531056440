import React, { useState } from 'react'
import { func, bool, string } from 'prop-types'
import { Field } from 'formik'
import SvgIcon from '../SvgIcon/SvgIcon'

import './PreviewPasswordInput.scss'

const PreviewPasswordInput = fieldProps => {
  const [passwordVisible, setPasswordVisible] = useState(false)

  const icon = passwordVisible ? 'eye-closed' : 'eye-open'
  const viewBox = passwordVisible ? '0 0 14 12' : '0 0 14 7'

  const style = {
    ...(fieldProps.style || {}),
    textTransform: 'unset'
  }

  // NOTE: Unset text-transform so password preview shows correct casing
  return (
    <div>
      <Field
        {...fieldProps}
        style={style}
        type={passwordVisible ? 'text' : 'password'}
        autoComplete="off"
      />
      <div
        className="password-eye-toggle"
        onClick={() => setPasswordVisible(!passwordVisible)}
      >
        <SvgIcon type={icon} width="20" height="20" viewBox={viewBox} />
      </div>
    </div>
  )
}

PreviewPasswordInput.propTypes = {
  type: string,
  name: string.isRequired,
  value: string.isRequired,
  placeholder: string.isRequired,
  disabled: bool.isRequired,
  onChange: func,
  errorClasses: string,
  inputHelpLink: string,
  inputHelpLinkClass: string,
  inputHelpLinkLabel: string,
  inputHelpText: string,
  convertToCaps: bool,
  classNames: string
}

export default PreviewPasswordInput
