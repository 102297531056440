import {
  CANCELLATION_DATA_ERROR,
  FETCH_CANCELLATION_DATA,
  RECEIVE_CANCELLATION_DATA,
  SET_ACTIVE_BOOKING,
  CLEAR_MODIFY_BOOKING
} from './constants'
import { startSessionTimer } from '../session/actions'
import { selectSessionId, selectLanguage } from '../session/selectors'
import { cancelBooking, asyncModifyBooking } from '../../api/api'
import { createAction } from 'redux-actions'
import { selectPaymentFerryId } from '../paymentConfirmation/selectors'
import { getCustomerBookings } from '../userBookings/actions'
import { selectConfigurationData } from '../configuration/selectors'
import formatExistingBookingToRequestBody from '../../utilities/data/formatExistingBookingToRequestBody'

export const setActiveModificationBooking = booking => dispatch => {
  dispatch({
    type: SET_ACTIVE_BOOKING,
    payload: booking
  })
}

const fetchingCancellationData = id => {
  return { type: FETCH_CANCELLATION_DATA, payload: { id } }
}

const cancellationDataReceived = (id, data) => {
  return { type: RECEIVE_CANCELLATION_DATA, payload: { id, data } }
}

const cancellationError = (id, error) => {
  return { type: CANCELLATION_DATA_ERROR, payload: { id, error } }
}

export const cancelBookingByModify = (booking, commitChanges = false) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const language = selectLanguage(state)
  const configuration = selectConfigurationData(state)
  const sessionId = selectSessionId(state)
  const ferryRoutes = configuration.ferry.options.routes
  const clientId = configuration.ferry.options.clientId
  const modificationId = booking.bookingNumber
  const modifyParams = formatExistingBookingToRequestBody(
    booking,
    ferryRoutes,
    language
  )
  modifyParams.departures.pop() // Removing the return

  dispatch(startSessionTimer())
  dispatch(fetchingCancellationData(booking.bookingNumber))

  try {
    const modifyResult = await asyncModifyBooking({
      params: { ...modifyParams, commitChanges: commitChanges.toString() },
      clientId,
      modificationId,
      sessionId
    })
    if (commitChanges && !modifyResult?.success) {
      const error = new Error()
      error.data = modifyResult?.data?.data
      throw error
    }
    const { reservationTotals } = modifyResult
    const cancellationData = {
      ...reservationTotals,
      bookingCancelled: commitChanges
    }
    dispatch(cancellationDataReceived(booking.bookingNumber, cancellationData))
    if (commitChanges) {
      dispatch(getCustomerBookings())
    }
  } catch (err) {
    const error = err?.data?.['hydra:description'] || 'Something went wrong'
    dispatch(cancellationError(booking.bookingNumber, error))
  }
}

export const fetchOrCancelBooking = (
  id,
  commitChanges = false,
  bookingFerryId
) => (dispatch, getState) => {
  const state = getState()
  let ferryId = selectPaymentFerryId(state)
  if (!ferryId && bookingFerryId) {
    ferryId = bookingFerryId
  }
  const params = {
    ferryId,
    agentNumber: '',
    agentPaymentMethod: '',
    agentPaymentType: '',
    commitChanges: commitChanges.toString()
  }

  dispatch(fetchingCancellationData(id))
  dispatch(startSessionTimer()) // Refresh session on all api requests

  // todo: fix error messages
  return cancelBooking({ id, params })
    .then(res => {
      const data = res?.data?.['hydra:member']?.[0]
      if (data) {
        dispatch(cancellationDataReceived(id, data))
        if (commitChanges) {
          dispatch(getCustomerBookings())
        }
      } else {
        dispatch(cancellationError(id, 'error'))
      }
    })
    .catch(err => {
      const error =
        err?.response?.data?.['hydra:description'] || 'Something went wrong'
      dispatch(cancellationError(id, error))
    })
}

export const clearActiveModifyBooking = createAction(CLEAR_MODIFY_BOOKING)
