import React, { Fragment } from 'react'
import { arrayOf, shape, oneOfType, bool, func } from 'prop-types'
import Modal from '../Modal'
import { useSelector } from 'react-redux'
import { selectLabels } from '../../redux/configuration/selectors'
import { renderContentValues } from '../../redux/configuration/utilities'
import './ServiceMessageModal.scss'

const ServiceMessageModal = ({ closeModal, isOpen, message }) => {
  const labels = useSelector(selectLabels)

  return !isOpen ? null : (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal--med service-message-modal"
    >
      <Fragment>
        {message && (
          <div className="service-message-modal-content">
            {renderContentValues(message, 'service-message')}
          </div>
        )}
        <div className="service-message-modal-buttons">
          <button
            className="btn btn-primary"
            onClick={() => closeModal({ type: 'ferry-service-message' })}
          >
            {labels.continueBtn}
          </button>
        </div>
      </Fragment>
    </Modal>
  )
}

ServiceMessageModal.propTypes = {
  closeModal: func.isRequired,
  isOpen: bool,
  message: oneOfType([bool, arrayOf(shape())]).isRequired
}

ServiceMessageModal.defaultProps = {
  isOpen: false
}

export default ServiceMessageModal
