import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { selectLabels } from '../../redux/configuration/selectors'
import { formatPriceByLanguage } from '../../redux/configuration/utilities'
import { selectBookingState } from '../../redux/booking/selectors'
import BookingConfirmationTable from '../../components/BookingConfirmation/BookingConfirmationTable'
import BookingConfirmationList from '../../components/BookingConfirmationList/BookingConfirmationList'
import dateFormats from '../../configuration/dateFormats'
import queryString from 'query-string'

import '../../styles/scss/components/booking-confirmation/booking-confirmation.scss'
import { selectLanguage } from '../../redux/session/selectors'
import { getChaseTransactionReceipt } from '../../redux/booking/actions'
import { findReservation } from '../../api/api'
import { selectVehicleViewContent } from '../../redux/configuration/selectors'
import { selectAccommodationsContent } from '../../redux/configuration/selectors'
import { normalizeLabelWithCount } from '../../configuration/utilities'
import { selectLatestBookingNumber } from '../../redux/session/selectors'

const BookingFailureConfirmation = ({ handleRouteChange }) => {
  const labels = useSelector(selectLabels)
  const lang = useSelector(selectLanguage)
  const bookingData = useSelector(selectBookingState)
  const vehicleContent = useSelector(selectVehicleViewContent)
  const accommodationsContent = useSelector(selectAccommodationsContent)
  const latestBooking = useSelector(selectLatestBookingNumber)

  const [loadingReceipts, setLoadingReceipt] = useState(false)
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [bookingDetails, setBookingDetails] = useState(null)
  const [summary, setSummary] = useState(null)
  const [departureAccommodations, setDepartureAccommodations] = useState([])
  const [returnAccommodations, setReturnAccommodations] = useState([])

  const dispatch = useDispatch()
  const data = bookingData?.data
  const params = queryString.parse(window.location.search)
  const uid = params?.uid
  const receipt =
    uid && bookingData?.receipts?.[uid] ? bookingData?.receipts?.[uid] : null

  let bookingNumber = '-'
  if (receipt?.orderId.indexOf('-') > -1) {
    bookingNumber = receipt?.orderId.split('-')[1]
  } else if (data?.bookingNumber) {
    bookingNumber = data?.bookingNumber
  } else if (data?.orderId.indexOf('-') > -1) {
    bookingNumber = data?.orderId.split('-')[1]
  } else if (latestBooking) {
    bookingNumber = latestBooking
  }

  const formatForLang =
    lang === 'fr'
      ? dateFormats.summaryTableLongDisplayFrench
      : dateFormats.summaryTableLongDisplay

  const tableData = [
    {
      label: labels.bookingNumber,
      value: bookingNumber
    },
    {
      label: labels.transactionStatus,
      value: labels[data?.paymentStatus] || labels['paymentStatusUnpaid']
    },
    {
      label: labels.bookingStatus,
      value: labels[data?.bookingStatus] || '-'
    }
  ]

  if (data?.refundApplied) {
    tableData.push({
      label: labels.totalRefund,
      value: formatPriceByLanguage(data?.refundAmount, lang)
    })
  }

  useEffect(() => {
    if (uid && !receipt && !loadingReceipts) {
      setLoadingReceipt(true)
      dispatch(
        getChaseTransactionReceipt({
          uid: uid
        })
      )
    } else if (receipt && bookingData && !bookingDetails && !loadingDetails) {
      setLoadingDetails(true)
      async function getBookingDetails({
        workPhoneNumber,
        email,
        reservationId
      }) {
        const reservationData = await findReservation({
          workPhoneNumber,
          email,
          reservationId
        })

        const bookingData =
          reservationData?.data?.['hydra:member']?.[0]?.bookingReservation

        if (!bookingData) {
          return
        }

        const summaryDepartureDate = moment(
          new Date(`${bookingData.departureDate}T00:00:00`)
        ).format(formatForLang)
        const summaryArrivalDate = bookingData?.arrivalDate
          ? moment(new Date(`${bookingData.arrivalDate}T00:00:00`)).format(
              formatForLang
            )
          : null
        const ferrySummary = {
          icon: 'routes',
          label: `${labels[bookingData.departures[0].routeCode]} | ${
            bookingData?.departures?.length > 1 ? labels.return : labels.oneWay
          }`,
          date: `${summaryDepartureDate}${
            summaryArrivalDate ? ` - ${summaryArrivalDate}` : ''
          }`,
          grandTotal: formatPriceByLanguage(
            Number(bookingData?.reservationTotals?.totalPriceToPay),
            lang
          )
        }

        const departureAccommodationsData = []
        const returnAccommodationsData = []

        const ferryData =
          bookingData?.departures?.map((departure, key) => {
            const subheader =
              key === 0
                ? labels.departure || 'label:departure'
                : labels.return || 'label:return'
            const departureDate = moment(
              new Date(`${departure.departureDate}T00:00:00`)
            ).format(formatForLang)
            const arrivalDate = moment(
              new Date(`${departure.arrivalDate}T00:00:00`)
            ).format(formatForLang)
            const departureTimes = `${labels.departure} ${departure.departureTime} → ${labels.arrival} ${departure.arrivalTime}`
            const vehicles =
              departure.vehicles?.booked?.length > 0
                ? departure.vehicles?.booked?.map(vehicle => {
                    const vehicleIconData = vehicleContent?.vehicleGrouping?.[0]?.icons?.find(
                      vehicleType =>
                        vehicleType.resourceCode === vehicle.resourceCode
                    )
                    return vehicleIconData?.default || null
                  })
                : []
            const passengersLookup = {}

            const passengerTotal = departure.passengerPriceLines.reduce(
              (total, passenger) => (total += +passenger.price),
              0
            )
            const passengerFees = departure.passengerAddonPriceLines.reduce(
              (total, addon) => (total += +addon.price),
              0
            )
            const vehicleTotal = departure.vehiclePriceLines.reduce(
              (total, vehicle) => (total += +vehicle.price),
              0
            )
            const vehicleFees = departure.vehicleAddonPriceLines.reduce(
              (total, addon) => (total += +addon.price),
              0
            )
            const ammendmentFees = departure.amendmentFeePriceLines.reduce(
              (total, fee) => (total += +fee.price),
              0
            )
            const addons = departure.addOnPriceLines.reduce(
              (total, addon) => (total += +addon.price),
              0
            )

            const departureMinusAccommsTotal =
              passengerTotal +
              passengerFees +
              vehicleTotal +
              vehicleFees +
              ammendmentFees +
              addons

            departure.passengers.booked.forEach(passenger => {
              const curPassengerCount =
                passengersLookup?.[passenger.passengerType]
              if (curPassengerCount) {
                passengersLookup[passenger.passengerType] += 1
              } else {
                passengersLookup[passenger.passengerType] = 1
              }
            })
            const guestList = Object.entries(passengersLookup)
              .map(([key, count]) => {
                let pluralKey
                switch (key) {
                  case 'A':
                    pluralKey = 'adults'
                    break
                  case 'S':
                    pluralKey = 'seniors'
                    break
                  case 'C':
                    pluralKey = 'children'
                    break
                  case 'ST':
                    pluralKey = 'students'
                    break
                  case 'I':
                    pluralKey = 'infants'
                    break
                  case 'PET':
                    pluralKey = 'pets'
                    break
                  default:
                    pluralKey = ''
                    break
                }

                const guestLabel = normalizeLabelWithCount({
                  labelKey: `passengerType${key}`,
                  pluralLabelKey: pluralKey,
                  lang,
                  count,
                  labels,
                  showCountWhenOne: true
                })

                return `${guestLabel}`
              })
              .join(', ')

            departure.accommodationPriceLines.forEach(accomm => {
              const cabinLabel = normalizeLabelWithCount({
                labelKey: 'selectedServicesLineItem',
                pluralLabelKey: 'selectedServicesLineItemPlural',
                lang,
                number: Number(accomm.number),
                labels,
                showCountWhenOne: true
              })
              const accommData = {
                id: accomm.resourceCode,
                icon: 'accommodations',
                label: `${
                  accommodationsContent?.accommodations?.[accomm.resourceCode]
                    .caption
                } | ${cabinLabel}`,
                date: `${departureDate} - ${arrivalDate}`,
                price: formatPriceByLanguage(
                  +accomm.price + +accomm.tax + +accomm.tax2,
                  lang
                )
              }
              if (key === 0) {
                departureAccommodationsData.push(accommData)
              } else {
                returnAccommodationsData.push(accommData)
              }
            })

            return {
              subheader: subheader,
              value: departureDate,
              date: departureTimes,
              vehicles: vehicles,
              passengers: guestList,
              price: formatPriceByLanguage(+departureMinusAccommsTotal, lang)
            }
          }) || []

        setDepartureAccommodations(departureAccommodationsData)
        setReturnAccommodations(returnAccommodationsData)
        setSummary(ferrySummary)
        setBookingDetails(ferryData)
        setLoadingDetails(false)
      }
      getBookingDetails({
        email: receipt.merchantData1 || '',
        reservationId: bookingNumber,
        workPhoneNumber: receipt.merchantData3 || ''
      })
      setLoadingReceipt(false)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, loadingReceipts, bookingData])

  return (
    <div className="booking-confirmation">
      <div className="u-container">
        <div className="booking-confirmation-content">
          <div className="booking-confirmation-container">
            <div style={{ width: '100%', maxWidth: '625px', margin: '0 auto' }}>
              <h2 className="theme-font-header booking-success-header">
                {labels.bookingConfirmtion}
              </h2>
              <BookingConfirmationTable
                headerLabel={labels.transactionCancelled}
                tableData={tableData}
                centerHeader={true}
              />
            </div>
            <br />
            <hr />
            <br />
            <h3 className="booking-success-summary-header">
              {labels.bookingSummary || 'label:bookingSummary'}
            </h3>
            <div className="booking-success-breakdown">
              <BookingConfirmationList
                listData={bookingDetails || []}
                summary={summary}
                centerHeader
                headerLabel={labels.ferryTravel || 'label:ferryTravel'}
                labels={labels}
                id="summary"
              />
              {departureAccommodations.length > 0 &&
                departureAccommodations.map((accommodation, idx) => (
                  <BookingConfirmationList
                    listData={[]}
                    summary={accommodation}
                    centerHeader
                    headerLabel={
                      idx === 0
                        ? labels.accommodations || 'label:accommodations'
                        : null
                    }
                    labels={labels}
                    id={`departure-accomm-${idx}`}
                  />
                ))}
              {returnAccommodations.length > 0 &&
                returnAccommodations.map((accommodation, idx) => (
                  <BookingConfirmationList
                    listData={[]}
                    summary={accommodation}
                    headerLabel={null}
                    labels={labels}
                    id={`return-accomm-${idx}`}
                  />
                ))}
              {summary?.grandTotal && (
                <div className="booking-success-grand-total">
                  <span className="booking-success-grand-total__label">
                    {labels.grandTotal || 'label:grandTotal'}:
                  </span>
                  <span className="booking-success-grand-total__value">
                    {summary.grandTotal}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default BookingFailureConfirmation
