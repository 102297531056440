import { crossingTypes } from '../crossings/constants'
import { selectDepartureAndReturnVehicles } from '../vehicleSelections/selectors'
import { selectAvailableVehicleOptions } from '../configuration/selectors'
import { selectActiveModifyBooking } from '../modifyBooking/selectors'
import {
  getBookingVehicleByCrossingType,
  getVehicleByCode
} from '../vehicleSelections/utilities'
import { selectUserSelectedVehicles } from '../userSelections/selectors'
import { vehicleTypes } from '../vehicleSelections/constants'

const selectInitialVehicleValues = state => {
  const selectedVehicles = selectDepartureAndReturnVehicles(state)

  const userSelectedVehicles = selectUserSelectedVehicles(state)
  let result = { ...selectedVehicles }

  if (
    process.env.REACT_APP_ALLOW_ONE_WAY_WALK_ON &&
    userSelectedVehicles?.departure?.vehicleType === vehicleTypes.VEHWLK
  ) {
    result.departure = {
      ...result.departure,
      vehicleType: vehicleTypes.VEHWLK
    }
  }

  if (
    process.env.REACT_APP_ALLOW_ONE_WAY_WALK_ON &&
    userSelectedVehicles?.return?.vehicleType === vehicleTypes.VEHWLK
  ) {
    result.return = {
      ...userSelectedVehicles.return,
      vehicleType: vehicleTypes.VEHWLK
    }
  }
  const availableVehicles = selectAvailableVehicleOptions(state)
  const activeModifyBooking = selectActiveModifyBooking(state)

  const replaceWithBookingVehicles =
    activeModifyBooking &&
    (activeModifyBooking?.departureVehicleCode ||
      activeModifyBooking?.returnVehicleCode) &&
    availableVehicles

  if (replaceWithBookingVehicles) {
    const departureVehicle = getBookingVehicleByCrossingType({
      booking: activeModifyBooking,
      bookingVehicle: getVehicleByCode({
        availableVehicles,
        vehicleCode: activeModifyBooking?.departureVehicleCode
      }),
      crossingType: crossingTypes.DEPARTURE
    })
    const returnVehicle = getBookingVehicleByCrossingType({
      booking: activeModifyBooking,
      bookingVehicle: getVehicleByCode({
        availableVehicles,
        vehicleCode: activeModifyBooking?.returnVehicleCode
      }),
      crossingType: crossingTypes.RETURN
    })
    if (departureVehicle) result.departure = { ...departureVehicle }
    if (returnVehicle) result.return = { ...returnVehicle }
  }

  return result
}

export default selectInitialVehicleValues
