import React, { Fragment } from 'react'
import { oneOfType, arrayOf, bool, func, shape, node } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { selectLabels } from '../../redux/configuration/selectors'
import { selectLanguage } from '../../redux/session/selectors'
import {
  setDepartureRoute,
  setReturnRoute,
  setForcesBooking
} from '../../redux/ferryRouteSelections/actions'
import {
  selectDepartureDate,
  selectDepartureRoute,
  selectForcesBooking
} from '../../redux/ferryRouteSelections/selectors'
import {
  clearPromoValidation,
  fetchPromoInformation
} from '../../redux/promo/actions'
import * as UserSelectionActions from '../../redux/userSelections/actions'
import { selectCurrentBookingPromoState } from '../../redux/selectors/selectCurrentBookingPromoState'
import FerryRoutesFormSelect from './elements/FerryRoutesFormSelect'
import FerryRoutesFormSingleDatePicker from './elements/dates/FerryRoutesFormSingleDatePicker'
import ValidatedPromoInput from '../ValidatedPromoInput/ValidatedPromoInput'
import WarningBanner from '../WarningBanner/WarningBanner'
import Checkbox from '../Checkbox/Checkbox'

const FerryRoutesOneWayForm = ({ availabilityErrors, ferryRoutes, submit }) => {
  const dispatch = useDispatch()
  const activeDepartureRoute = useSelector(selectDepartureRoute)
  const departureDate = useSelector(selectDepartureDate)
  const labels = useSelector(selectLabels)
  const promoInputState = useSelector(selectCurrentBookingPromoState)
  const forcesBooking = useSelector(selectForcesBooking)
  const language = useSelector(selectLanguage)
  let isSubmitEnabled = false

  if (
    activeDepartureRoute &&
    activeDepartureRoute.code &&
    departureDate &&
    (promoInputState.valid === true ||
      promoInputState.valid === null ||
      promoInputState.code === '')
  ) {
    isSubmitEnabled = true
  }
  if (promoInputState.valid && forcesBooking) {
    isSubmitEnabled = false
  }

  const handleDepartureRoute = routeData => {
    dispatch(setDepartureRoute(routeData)) // TODO: Deprecate
    dispatch(setReturnRoute({})) // TODO: Deprecate

    dispatch(UserSelectionActions.setDepartureRoute(routeData.code))
    dispatch(UserSelectionActions.setReturnRoute(null))
  }

  return (
    <div className="ferry-routes__form-one-way">
      {!isEmpty(ferryRoutes) && (
        <div className="ferry-routes__form-routes">
          <FerryRoutesFormSelect
            activeRoute={activeDepartureRoute}
            ferryRoutes={ferryRoutes}
            fieldLabel={labels.departureRoute}
            toggleLabel={labels.change}
            updateRouteEvent={handleDepartureRoute}
          />
        </div>
      )}

      <FerryRoutesFormSingleDatePicker
        ferryLabels={labels}
        onChange={() => dispatch(clearPromoValidation())}
      />

      <div className="ferry-routes__validated-promo-container">
        <ValidatedPromoInput
          labels={labels}
          handleBlur={v => dispatch(UserSelectionActions.setPromoCode(v))}
          submitValidation={code => {
            dispatch(fetchPromoInformation(code))
          }}
          clearValidation={() => dispatch(clearPromoValidation())}
          {...promoInputState}
        />
      </div>

      <div className="ferry-routes__forces-input-container">
        <Checkbox
          name={'CF_BOOKING'}
          value={forcesBooking}
          label={labels.canadianForcesMember}
          disabled={false}
          onChange={v => {
            dispatch(setForcesBooking(v.target.checked))
            dispatch(UserSelectionActions.setIsForcesMember(v.target.checked))
          }}
          formikCheckbox={false}
        />
      </div>
      {forcesBooking && labels.canadianForcesMemberElig1 && (
        <p className="cfid-disclaimer">
          {`${labels.canadianForcesMemberElig1} `}
          <a
            href={
              language === 'fr'
                ? process.env.REACT_APP_CFID_LINK_FR
                : process.env.REACT_APP_CFID_LINK_EN
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {labels.clickHere || ''}
          </a>
          {` ${labels.canadianForcesMemberElig2}`}
        </p>
      )}

      {availabilityErrors?.length ? (
        <Fragment>
          {availabilityErrors.map((error, i) => (
            <WarningBanner key={error + i}>{error}</WarningBanner>
          ))}
        </Fragment>
      ) : null}

      {promoInputState.valid && forcesBooking && (
        <WarningBanner>{labels.discountCannotBeCombined}</WarningBanner>
      )}
      <button
        className="ferry-routes__form-submit btn btn-primary large-primary-btn"
        onClick={submit}
        disabled={!isSubmitEnabled}
      >
        {labels.continueToPassengerInfoBtn} →
      </button>
    </div>
  )
}

FerryRoutesOneWayForm.propTypes = {
  availabilityErrors: oneOfType([bool, arrayOf(node)]),
  openModal: func.isRequired,
  closeModal: func.isRequired,
  modalIsOpen: shape().isRequired,
  ferryRoutes: arrayOf(shape()).isRequired,
  isPairRoutes: bool,
  submit: func.isRequired
}

export default FerryRoutesOneWayForm
