import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { useSelector } from 'react-redux'
import ConfigConsumer from '../../../../redux/configuration/context'
import SvgIcon from '../../../SvgIcon/SvgIcon'
import { selectLanguage } from '../../../../redux/session/selectors'
import dateFormats from '../../../../configuration/dateFormats'

const FerryRoutesFormDateSelect = ({
  activeDateValue,
  containerClassNames,
  dateField,
  fieldLabel,
  toggleDateEvent
}) => {
  const language = useSelector(selectLanguage)
  return (
    <div
      className={`u-form__custom-select${
        containerClassNames ? ' ' + containerClassNames : ''
      }${dateField ? ' ' + dateField : ''}`}
      onClick={() => toggleDateEvent(dateField)}
    >
      {fieldLabel && (
        <p className="u-form__custom-select-label theme-title-variant-seven">
          {fieldLabel}
        </p>
      )}
      <div className="u-form__custom-select-field">
        <div className="select-value">
          <ConfigConsumer>
            {({ themeVariables }) => (
              <SvgIcon
                className="select-value-icon"
                type="calendar"
                fill={themeVariables.themeIconColor}
              />
            )}
          </ConfigConsumer>
          {moment(activeDateValue)
            .locale(language)
            .format(
              language === 'fr'
                ? dateFormats.customDateSelectLongFrench
                : dateFormats.customDateSelectLong
            )}
        </div>
        <p className="select-toggle" tabIndex={-1} />
      </div>
    </div>
  )
}

FerryRoutesFormDateSelect.propTypes = {
  activeDateValue: PropTypes.object.isRequired,
  containerClassNames: PropTypes.string,
  dateField: PropTypes.string.isRequired,
  ferryLabels: PropTypes.shape(),
  fieldLabel: PropTypes.string,
  toggleDateEvent: PropTypes.func.isRequired
}

export default FerryRoutesFormDateSelect
