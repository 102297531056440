import React, { Fragment, useState } from 'react'
import { shape, bool, string, func, arrayOf } from 'prop-types'
import { renderTextInput } from '../TextInput/TextInput'
import { RenderSelect } from '../Select/Select'

import './CoPassengerConfig.scss'

function validateCoPassenger(coPassenger) {
  return (
    coPassenger.passengerType &&
    coPassenger.firstName &&
    coPassenger.lastName &&
    !isNaN(new Date(coPassenger.dateOfBirth).getTime()) &&
    coPassenger.title
  )
}

const CoPassengerRow = ({
  labels,
  fields,
  formRowClasses,
  index,
  coPassenger,
  onChange,
  onDelete,
  renderErrors,
  rowNumber
}) => {
  const handleUpdate = (key, value) =>
    onChange({ ...coPassenger, [key]: value })
  const validPassengerTypes = ['A', 'S', 'C', 'I'] // NOTE(ebarrett): This is MAI specific
  const passengerTypeSelect = RenderSelect({
    name: 'passengerType',
    value: coPassenger.passengerType,
    setValue: handleUpdate,
    options: fields.passengerType.options.filter(opt =>
      validPassengerTypes.includes(opt.value)
    ),
    label: labels.passengerType,
    forceFloatingLabel: true
  })

  const titleSelect = RenderSelect({
    name: 'title',
    value: coPassenger.title,
    setValue: handleUpdate,
    options: fields.title.options,
    label: labels.title,
    forceFloatingLabel: true
  })

  const makeInputFor = (key, formatValue = v => v) => {
    return renderTextInput({
      name: key,
      value: formatValue(coPassenger[key]),
      type: 'text',
      placeholder: labels[key],
      setFieldValue: handleUpdate,
      forceFloatingLabel: true
    })
  }

  const isValid = validateCoPassenger(coPassenger)

  return (
    <Fragment>
      <span className="row">
        <div className="copassenger-config-row upper">
          <div className="copassenger-config-label index">
            {rowNumber + '.'}
          </div>
          <div className="copassenger-config-input passenger-type">
            {passengerTypeSelect}
          </div>
          <div className="copassenger-config-input">
            {makeInputFor('firstName')}
          </div>
          <div className="copassenger-config-input">
            {makeInputFor('lastName')}
          </div>
          <div className="copassenger-config-label delete" onClick={onDelete}>
            X
          </div>
        </div>
      </span>
      <span className="row">
        <div className="copassenger-config-row lower">
          <div className="copassenger-config-input birth-date">
            {makeInputFor('dateOfBirth')}
          </div>
          <div className="copassenger-config-input title">{titleSelect}</div>
        </div>
      </span>
      {!isValid && renderErrors && (
        <span className={formRowClasses + ' copassenger-config-error'}>
          {labels.coPassengerError}
        </span>
      )}
    </Fragment>
  )
}

CoPassengerRow.propTypes = {
  formRowClasses: string.isRequired,
  coPassenger: shape({}).isRequired,
  onChange: func.isRequired,
  onDelete: func.isRequired,
  renderErrors: bool
}

const CoPassengerConfig = ({
  labels,
  fields,
  formRowClasses,
  initialValues,
  onChange,
  renderErrors,
  countryCode
}) => {
  const [coPassengers, setCoPassengers] = useState(
    initialValues.map(x => {
      return { ...x, initialValue: true }
    })
  )

  const addCoPassenger = evt => {
    const newCoPassenger = {
      passengerType: 'A',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      gender: '',
      coPassengerId: '',
      countryCode: countryCode,
      newPassenger: true,
      delete: false,
      title: '',
      isInitial: false
    }
    setCoPassengers([...coPassengers, newCoPassenger])
    onChange([...coPassengers, newCoPassenger], false)
    evt.preventDefault() // Don't show the validation labels on outer form
  }

  const removeCoPassenger = index => {
    const copy = [...coPassengers]
    if (coPassengers[index].newPassenger) {
      copy.splice(index, 1)
      setCoPassengers(copy)
      onChange(copy, validateCoPassengers(copy))
    } else {
      copy[index].delete = true
      setCoPassengers(copy)
      onChange(copy, validateCoPassengers(copy))
    }
  }

  const updateCoPassenger = (coPassenger, index) => {
    const copy = [...coPassengers]
    copy[index] = coPassenger
    setCoPassengers(copy)
    onChange(copy, validateCoPassengers(copy))
  }

  const validateCoPassengers = data => {
    return data.every(coPassenger => validateCoPassenger(coPassenger))
  }

  let numRows = 0

  return (
    <div className="copassenger-config">
      {coPassengers.map((cp, index) => {
        if (!cp.delete) {
          numRows++
          return (
            <Fragment key={`cp${index}`}>
              <CoPassengerRow
                formRowClasses={formRowClasses}
                labels={labels}
                fields={fields}
                coPassenger={cp}
                index={index}
                renderErrors={renderErrors}
                onChange={cp => updateCoPassenger(cp, index)}
                onDelete={cp => removeCoPassenger(index)}
                rowNumber={numRows}
              />
              {index < coPassengers.length - 1 && (
                <div className="copassenger-config-hr" />
              )}
            </Fragment>
          )
        }
        return null
      })}
      <div className="copassenger-config-btn-container">
        <button className="btn btn-primary" onClick={addCoPassenger}>
          {labels.addCoPassenger}
        </button>
      </div>
    </div>
  )
}

CoPassengerConfig.propTypes = {
  labels: shape().isRequired,
  fields: shape().isRequired,
  initialValues: arrayOf(shape()).isRequired,
  onChange: func.isRequired, // function(newValue, isValid)
  formRowClasses: string.isRequired,
  renderErrors: bool
}

export default CoPassengerConfig

/*

  NOTE(ebarrett): SingleDatePicker doesn't have support for jumping to a specific year,
    so while I was able to get the date picker up with the snippets below, picking
    a dateOfBirth would be extremely tedious having to scroll backwards one month at a time

  ...

  import { SingleDatePicker } from 'react-dates'
  import FerryRoutesFormDateSelect from '../ferryRoutes/elements/dates/FerryRoutesFormDateSelect'

  ...

  const dobPickerId = `copassenger-dob-${index}`
  const toggleCustomDate = dateField => {
    document.getElementById(dobPickerId).focus()
    setFocus(true)
  }
  const dateOfBirthInput = <div style={{minWidth:'230px'}} onClick={toggleCustomDate}>
    <div style={{display:focus ? 'inherit' : 'none', height: 0, zIndex:100}}>
      <SingleDatePicker
        block={false}
        date={moment(coPassenger.dateOfBirth)}
        daySize={36}
        displayFormat="MMM D, YYYY"
        focused={focus}
        horizontalMargin={30}
        id={dobPickerId}
        isOutsideRange={date =>
          date.isAfter(moment())
        }
        navNext={<i className="material-icons">east</i>}
        navPrev={<i className="material-icons">west</i>}
        noBorder={true}
        numberOfMonths={1}
        onDateChange={e => console.log("asdf")}
        onFocusChange={() => setFocus(!focus)}
        firstDayOfWeek={0}
      />
    </div>
    {makeInputFor('dateOfBirth', v => v.split('T')[0])}
  </div>
  
*/
