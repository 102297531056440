import React from 'react'
import { bool, shape } from 'prop-types'

const LanguageToggleDefault = ({ buttonProps, disabled }) =>
  Object.keys(buttonProps).map(langKey => {
    const { label, ...restButtonProps } = buttonProps[langKey]

    return (
      <button {...restButtonProps} disabled={disabled}>
        {label}
      </button>
    )
  })

LanguageToggleDefault.defaultProps = {
  disabled: false
}

LanguageToggleDefault.propTypes = {
  buttonProps: shape({}).isRequired,
  disabled: bool.isRequired
}

export default LanguageToggleDefault
