import queryString from 'query-string'
import { get } from 'lodash'
import { createAction } from 'redux-actions'
import { merge } from 'lodash'
import {
  getConfiguration,
  getWebsiteStatus,
  getActiveSessions,
  getCustomerAccount
} from '../../api/api'
import {
  ENABLE_ACCOMMODATIONS,
  ENABLE_EXTRAS,
  ENABLE_FORCES_INPUT
} from '../../configuration/constants'
import labelsConfig from '../../configuration/labels'
import { asyncTryCatch } from '../../configuration/utilities'
import { languageLabelMap } from '../../components/language/constants'
import { setCurrency, startSessionTimer, setLanguage } from '../session/actions'
import { DEFAULT_LANG } from '../user/constants'
import {
  FETCH_CONFIG_BEGIN,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_ERROR,
  DEFAULT_LABEL_TYPE,
  SET_LABELS,
  SET_FIELDS,
  FETCHING_WEBSITE_STATUS,
  FETCH_WEBSITE_STATUS_SUCCESS,
  FETCH_WEBSITE_STATUS_ERROR,
  FETCHING_ACTIVE_SESSIONS,
  FETCH_ACTIVE_SESSIONS_SUCCESS,
  FETCH_ACTIVE_SESSIONS_ERROR,
  fields,
  SET_CONTENT,
  REFRESH_SESSION_SUCCESS,
  ENABLE_CAN_USE_APP,
  DISABLE_CAN_USE_APP,
  UPDATING_CONFIG_BY_LANGUAGE,
  UPDATE_CONFIG_BY_LANGUAGE_SUCCESS,
  UPDATE_CONFIG_BY_LANGUAGE_ERROR
} from './constants'
import { mockCanadianForcesModalContent } from './mockData' // TODO: replace with actual content
import { checkCanUseApp, getFormFieldsByName } from './utilities'
import { ingestQueryParameters } from '../actions'

import { selectLanguage, selectSessionId } from '../session/selectors'
import yn from 'yn'

const fetchingConfig = createAction(FETCH_CONFIG_BEGIN)
const fetchConfigSuccess = createAction(FETCH_CONFIG_SUCCESS)
const fetchConfigError = createAction(FETCH_CONFIG_ERROR)

const setLabels = createAction(SET_LABELS)
const setFields = createAction(SET_FIELDS)

const setContent = createAction(SET_CONTENT)

const fetchingWebsiteStatus = createAction(FETCHING_WEBSITE_STATUS)
const fetchWebsiteStatusSuccess = createAction(FETCH_WEBSITE_STATUS_SUCCESS)
const fetchWebsiteStatusError = createAction(FETCH_WEBSITE_STATUS_ERROR)

const fetchingActiveSessions = createAction(FETCHING_ACTIVE_SESSIONS)
const fetchActiveSessionsSuccess = createAction(FETCH_ACTIVE_SESSIONS_SUCCESS)
const fetchActiveSessionsError = createAction(FETCH_ACTIVE_SESSIONS_ERROR)

const updatingConfigByLanguage = createAction(UPDATING_CONFIG_BY_LANGUAGE)
const updateConfigByLanguageSuccess = createAction(
  UPDATE_CONFIG_BY_LANGUAGE_SUCCESS
)
const updateConfigByLanguageError = createAction(
  UPDATE_CONFIG_BY_LANGUAGE_ERROR
)
const refreshSessionSuccess = createAction(REFRESH_SESSION_SUCCESS)

const mergeLabels = (
  type = DEFAULT_LABEL_TYPE,
  data = {},
  languageCode = DEFAULT_LANG
) => {
  let result = {}
  if (data && data[type] && data[type].labels?.[0]) {
    result = merge({}, labelsConfig[languageCode] || {}, data[type].labels[0])
  }

  return result
}

const getLangIdFromQueryParams = (queryParams = {}) => {
  let result = DEFAULT_LANG

  const langQuery = queryParams.language || queryParams.lang || queryParams.l

  if (langQuery && Object.keys(languageLabelMap).includes(langQuery)) {
    result = langQuery
  }

  return result
}

export const fetchWebsiteStatus = ({ availabilityLink }) => async dispatch => {
  dispatch(fetchingWebsiteStatus())

  dispatch(startSessionTimer()) // Refresh session on all api requests
  return getWebsiteStatus({ availabilityLink })
    .then(response => {
      if (response) {
        return dispatch(fetchWebsiteStatusSuccess(response))
      } else {
        return dispatch(fetchWebsiteStatusError())
      }
    })
    .catch(() => dispatch(fetchWebsiteStatusError()))
}

export const enableCanUseApp = createAction(ENABLE_CAN_USE_APP)
export const disableCanUseApp = createAction(DISABLE_CAN_USE_APP)

const activeSessionFetchDelay = yn(
  process.env.REACT_APP_DELAY_INITIAL_CALL_TO_ACTIVE_SESSIONS
)
  ? 5000
  : 0

export const fetchActiveSessions = ({ pathname = false }) => async dispatch => {
  setTimeout(() => {
    dispatch(startSessionTimer()) // Refresh session on all api requests
    dispatch(fetchingActiveSessions())

    return getActiveSessions()
      .then(response => {
        if (response?.data?.['hydra:member']?.[0]?.sessionData?.sessionCount) {
          const sessionCount =
            response.data['hydra:member'][0].sessionData.sessionCount
          if (checkCanUseApp(sessionCount)) {
            dispatch(enableCanUseApp())
            dispatch(refreshSession())
          } else {
            dispatch(disableCanUseApp())
          }

          return dispatch(
            fetchActiveSessionsSuccess({ sessionCount, pathname })
          )
        } else {
          return dispatch(fetchActiveSessionsError(pathname))
        }
      })
      .catch(() => dispatch(fetchActiveSessionsError(pathname)))
  }, activeSessionFetchDelay)
}

const getPrimaryLangId = (state = {}, queryParams = {}) => {
  const stateLangId = selectLanguage(state)
  const result = stateLangId
    ? stateLangId
    : getLangIdFromQueryParams(queryParams)

  return result
}

const getFetchConfigurationQueryParams = (
  state = {},
  queryParams = {},
  newLanguage = false
) => {
  const result = new URLSearchParams()
  const primaryLangId = newLanguage || getPrimaryLangId(state, queryParams)

  result.append('primaryLangId', primaryLangId)

  return result
}

export const refreshSession = () => async (dispatch, getState) => {
  const state = getState()

  dispatch(startSessionTimer()) // Refresh session on all api requests

  const fetchConfigAndForgetIt = await asyncTryCatch(getCustomerAccount, {
    emailAddress: '1234',
    clientId: process.env.REACT_APP_FLOW_CLIENT_ID,
    sessionId: selectSessionId(state)
  })

  return dispatch(refreshSessionSuccess(!!fetchConfigAndForgetIt))
}

const mapConfigContent = ({ content, ferryOptions }) => {
  const result = {
    // FROM CONTENT
    canadianForcesModalContent: [], // TODO: replace with actual content
    routesData: [],
    routesGroupings: [],
    hero: [],
    vehicleGroupingContent: [],
    vehicleGroupingsContent: [],
    accommodationView: {},
    formattedAccommodations: [],
    vehicleViewContent: {},
    passengerInformationContent: {},
    confirmationViewContent: {},
    headerContent: {},
    serviceMessageContent: {},
    countries: [],
    // FROM FERRY OPTIONS
    configRoutes: [],
    mergedRoutes: [],
    vehicleResourceCodesExpanded: [],
    formattedVehicleResourceCodes: [],
    vehicleGroupings: [],
    formattedVehicleGroupings: [],
    accessibilityModalOptions: [],
    accessibilityModalContent: [],
    accessibilityCardContent: [],
    kennelCardContent: []
  }

  if (content) {
    // TODO: replace with actual content
    if (ENABLE_FORCES_INPUT) {
      result.canadianForcesModalContent = mockCanadianForcesModalContent
    }

    result.countries = content.countries ? content.countries : []

    result.routesData = get(content, 'routes.routeMaps', [])
    result.serviceMessageContent = get(
      content,
      'routes.serviceMessageContent',
      {}
    )
    result.routesGroupings = get(content, 'routes.logos', [])
    result.hero = get(content, 'hero', [])
    result.vehicleGroupingContent = get(
      content,
      'vehicleViewContent.vehicleGrouping',
      []
    )
    result.vehicleGroupingsContent = get(
      content,
      'vehicleViewContent.iconGrouping',
      []
    )
    result.accommodationView = get(content, 'accommodationView', {})
    const { accommodations = [] } = result.accommodationView
    result.formattedAccommodations = accommodations.reduce(
      (obj, accommodation) => {
        obj[accommodation.resourceCode] = accommodation

        return obj
      },
      {}
    )
    result.vehicleViewContent = get(content, 'vehicleViewContent', {})
    result.passengerInformationContent = get(
      content,
      'passengerInformationContent',
      {}
    )
    result.confirmationViewContent = get(content, 'confirmationViewContent', {})
    result.headerContent = get(content, 'header', {})

    if (ENABLE_EXTRAS) {
      const additionalInformationContent = get(
        content,
        'additionalInformationContent',
        {}
      )
      if (additionalInformationContent.value) {
        const accessibilityOptions = additionalInformationContent.value.find(
          x => x.name === 'accessibilityOptions'
        )
        result.accessibilityModalContent = accessibilityOptions?.value || []
      }

      const extrasInformationContent = get(
        content,
        'extrasInformationContent',
        {}
      )
      if (extrasInformationContent.value) {
        const [
          accessibilityCardContent,
          kennelCardContent
        ] = extrasInformationContent.value
        result.accessibilityCardContent = accessibilityCardContent
        result.kennelCardContent = kennelCardContent
      }
    }
  }

  if (ferryOptions) {
    result.configRoutes = get(ferryOptions, 'routes', [])
    result.mergedRoutes = result.configRoutes.map(route => {
      const matchingData = result.routesData.find(
        routeData =>
          routeData.route?.toLowerCase() === route.code?.toLowerCase()
      )
      return {
        ...route,
        routeMapImage:
          matchingData && matchingData.routeMapImage
            ? matchingData.routeMapImage
            : {}
      }
    })
    result.vehicleResourceCodesExpanded = get(
      ferryOptions,
      'vehicleResourceCodesExpanded',
      []
    )
    result.formattedVehicleResourceCodes = result.vehicleResourceCodesExpanded.map(
      vehicle => {
        let matchingIconSets
        let matchingPricing
        for (const vehicleContentGroup of result.vehicleGroupingContent) {
          const { icons } = vehicleContentGroup

          if (icons && icons.length) {
            const matchingVehicle = icons.find(
              icon =>
                icon.resourceCode?.toLowerCase() ===
                vehicle.resourceCode?.toLowerCase()
            )

            if (matchingVehicle) {
              matchingIconSets = {
                active: matchingVehicle.active,
                default: matchingVehicle.default
              }
              matchingPricing = {
                lowSeasonPrice: matchingVehicle.lowSeasonPrice,
                highSeasonPrice: matchingVehicle.highSeasonPrice
              }
              if (matchingVehicle.startingPrices) {
                matchingPricing.startingPrices = matchingVehicle.startingPrices
              }
              break
            }
          }
        }

        return {
          ...vehicle,
          icons: matchingIconSets,
          pricing: matchingPricing
        }
      }
    )
    result.vehicleGroupings = get(ferryOptions, 'vehicleGroupings', [])
    result.formattedVehicleGroupings = result.vehicleGroupings.map(
      configGroup => {
        const matchingContent = result.vehicleGroupingsContent.find(
          contentGroup =>
            contentGroup[configGroup.type] &&
            +contentGroup[configGroup.type].min === +configGroup.minVal &&
            +contentGroup[configGroup.type].max === +configGroup.maxVal
        )

        return {
          ...configGroup,
          icons:
            matchingContent && matchingContent.icons
              ? matchingContent.icons
              : {},
          title: matchingContent ? matchingContent.title : null
        }
      }
    )
    if (ENABLE_EXTRAS) {
      result.accessibilityModalOptions = (
        ferryOptions.addOnResourceCodes || []
      ).filter(code => code.type === 'accessibility')
    }
  }

  return result
}

// Note: This is a background version of fetchConfiguration with no loading
export const updateConfigByLanguage = ({ languageCode }) => async (
  dispatch,
  getState
) => {
  dispatch(updatingConfigByLanguage())
  const state = getState()
  const params = queryString.parse(window.location.search) || {}
  params.language = languageCode
  const fetchQuery = getFetchConfigurationQueryParams(
    state,
    params,
    languageCode
  )

  const fetchConfigForLabelsResponse = await asyncTryCatch(
    getConfiguration,
    fetchQuery.toString()
  )
  const res = fetchConfigForLabelsResponse[0]
  const content = get(
    fetchConfigForLabelsResponse[1],
    'data.hydra:member[0].data'
  )

  if (res?.data?.['hydra:member']?.[0]) {
    let groupedConfigData = {}

    const configuration = res.data['hydra:member'].map(config => {
      return config
    })

    // Key config by ID type
    for (let configKeys in configuration) {
      groupedConfigData[configuration[configKeys].id] =
        configuration[configKeys]
    }

    const ferryOptions = get(groupedConfigData, 'ferry.options')
    await dispatch(ingestQueryParameters(params, ferryOptions))
    let mappedContent = false

    if (content) mappedContent = mapConfigContent({ content, ferryOptions })

    const labels = mergeLabels(
      DEFAULT_LABEL_TYPE,
      groupedConfigData,
      languageCode
    )

    if (mappedContent && labels) {
      const {
        canadianForcesModalContent, // TODO: replace with actual content
        accommodationView,
        formattedAccommodations,
        headerContent,
        hero,
        formattedVehicleResourceCodes,
        formattedVehicleGroupings,
        vehicleViewContent,
        passengerInformationContent,
        confirmationViewContent,
        serviceMessageContent,
        mergedRoutes,
        routesGroupings,
        accessibilityModalOptions,
        accessibilityModalContent,
        accessibilityCardContent,
        kennelCardContent,
        countries
      } = mappedContent
      const defaultFields = getFormFieldsByName(Object.keys(fields), labels)

      if (defaultFields) {
        dispatch(setLanguage(languageCode))
        if (labels && Object.keys(labels).length && languageCode) {
          dispatch(setLabels({ labels, language: languageCode }))
        }
        dispatch(setFields({ fields: defaultFields, language: languageCode }))
        dispatch(
          setContent({
            language: languageCode,
            accommodationView: {
              ...accommodationView,
              accommodations: formattedAccommodations
            },
            headerContent,
            hero,
            vehicleResourceCodesExpanded: formattedVehicleResourceCodes,
            vehicleGroupings: formattedVehicleGroupings,
            vehicleViewContent,
            passengerInformationContent,
            confirmationViewContent,
            routes: mergedRoutes,
            routesGroupings,
            serviceMessageContent,
            accessibilityModalOptions,
            accessibilityModalContent,
            accessibilityCardContent,
            kennelCardContent,
            canadianForcesModalContent, // TODO: replace with actual content
            countries
          })
        )
        dispatch(
          fetchConfigSuccess({
            ...groupedConfigData,
            [DEFAULT_LABEL_TYPE]: {
              ...groupedConfigData[DEFAULT_LABEL_TYPE],
              options: {
                ...groupedConfigData[DEFAULT_LABEL_TYPE].options,
                accommodationView: {
                  ...accommodationView,
                  accommodations: formattedAccommodations
                },
                headerContent,
                routes: mergedRoutes,
                vehicleResourceCodesExpanded: formattedVehicleResourceCodes,
                vehicleGroupings: formattedVehicleGroupings,
                vehicleViewContent
              },
              labels: null
            }
          })
        )

        return dispatch(updateConfigByLanguageSuccess())
      }
    }
  }

  return dispatch(updateConfigByLanguageError())
}

export const fetchConfiguration = () => async (dispatch, getState) => {
  const queryParams = queryString.parse(window.location.search)
  const state = getState()

  const primaryLangId = getPrimaryLangId(state, queryParams)
  const fetchQuery = getFetchConfigurationQueryParams(state, queryParams)

  if (queryParams.currency) dispatch(setCurrency(queryParams.currency))

  dispatch(startSessionTimer()) // Refresh session on all api requests
  dispatch(fetchingConfig())
  return getConfiguration(fetchQuery.toString())
    .then(async responses => {
      // axios all was a late addition, so to keep of config response the same we'll use the following
      const res = responses[0]
      const content = get(responses[1], 'data.hydra:member[0].data')

      if (res?.data?.['hydra:member']?.[0]) {
        let groupedConfigData = {}
        let serviceMessageContent = {}

        // Narrow results to hydra member see OTA spec for details
        const configuration = res.data['hydra:member'].map(config => {
          return config
        })

        // Key config by ID type
        for (let configKeys in configuration) {
          groupedConfigData[configuration[configKeys].id] =
            configuration[configKeys]
        }

        const ferryOptions = get(groupedConfigData, 'ferry.options')
        await dispatch(ingestQueryParameters(queryParams, ferryOptions))

        // ROUTE CONTENT MEDIATION
        const configRoutes = get(ferryOptions, 'routes', [])
        const routesData = get(content, 'routes.routeMaps', [])
        serviceMessageContent = get(content, 'routes.serviceMessageContent', {})
        const routesGroupings = get(content, 'routes.logos', [])
        const hero = get(content, 'hero', [])
        const mergedRoutes = configRoutes.map(route => {
          const matchingData = routesData.find(
            routeData =>
              routeData.route?.toLowerCase() === route.code?.toLowerCase()
          )
          return {
            ...route,
            routeMapImage:
              matchingData && matchingData.routeMapImage
                ? matchingData.routeMapImage
                : {}
          }
        })

        // TODO: replace with actual content
        const canadianForcesModalContent = mockCanadianForcesModalContent

        // VEHICLE CONTENT MEDIATION
        const vehicleGroupingContent = get(
          content,
          'vehicleViewContent.vehicleGrouping',
          []
        )
        const vehicleResourceCodesExpanded = get(
          ferryOptions,
          'vehicleResourceCodesExpanded',
          []
        )

        const formattedVehicleResourceCodes = vehicleResourceCodesExpanded.map(
          vehicle => {
            let matchingIconSets
            let matchingPricing
            for (const vehicleContentGroup of vehicleGroupingContent) {
              const { icons } = vehicleContentGroup

              if (icons && icons.length) {
                const matchingVehicle = icons.find(
                  icon =>
                    icon.resourceCode?.toLowerCase() ===
                    vehicle.resourceCode?.toLowerCase()
                )

                if (matchingVehicle) {
                  matchingIconSets = {
                    active: matchingVehicle.active,
                    default: matchingVehicle.default
                  }
                  matchingPricing = {
                    lowSeasonPrice: matchingVehicle.lowSeasonPrice,
                    highSeasonPrice: matchingVehicle.highSeasonPrice
                  }
                  if (matchingVehicle.startingPrices) {
                    matchingPricing.startingPrices =
                      matchingVehicle.startingPrices
                  }
                  break
                }
              }
            }

            return {
              ...vehicle,
              icons: matchingIconSets,
              pricing: matchingPricing
            }
          }
        )

        const vehicleGroupings = get(ferryOptions, 'vehicleGroupings', [])
        const vehicleGroupingsContent = get(
          content,
          'vehicleViewContent.iconGrouping',
          []
        )

        const formattedVehicleGroupings = vehicleGroupings.map(configGroup => {
          const matchingContent = vehicleGroupingsContent.find(
            contentGroup =>
              contentGroup[configGroup.type] &&
              +contentGroup[configGroup.type].min === +configGroup.minVal &&
              +contentGroup[configGroup.type].max === +configGroup.maxVal
          )
          return {
            ...configGroup,
            icons:
              matchingContent && matchingContent.icons
                ? matchingContent.icons
                : {},
            title: matchingContent ? matchingContent.title : null
          }
        })

        let accommodationView = {}

        // ACCOMMODATION CONTENT
        if (ENABLE_ACCOMMODATIONS) {
          const accommodationViewContent = get(content, 'accommodationView', {})
          const { accommodations = [] } = accommodationViewContent
          const formattedAccommodations = accommodations.reduce(
            (obj, accommodation) => {
              obj[accommodation.resourceCode] = accommodation

              return obj
            },
            {}
          )

          accommodationView = {
            ...accommodationViewContent,
            accommodations: formattedAccommodations
          }
        }

        const vehicleViewContent = get(content, 'vehicleViewContent', {})
        const passengerInformationContent = get(
          content,
          'passengerInformationContent',
          {}
        )
        const confirmationViewContent = get(
          content,
          'confirmationViewContent',
          {}
        )

        const labels = mergeLabels(
          DEFAULT_LABEL_TYPE,
          groupedConfigData,
          primaryLangId
        )
        if (labels && Object.keys(labels).length && primaryLangId) {
          dispatch(setLabels({ labels, language: primaryLangId }))
          const defaultFields = getFormFieldsByName(Object.keys(fields), labels)
          if (defaultFields) {
            dispatch(
              setFields({ fields: defaultFields, language: primaryLangId })
            )
          }
        }

        // EXTRAS STEP CONTENT / OPTIONS
        let accessibilityModalContent = []
        let accessibilityModalOptions = []
        let accessibilityCardContent = []
        let kennelCardContent = []
        if (ENABLE_EXTRAS) {
          const additionalInformationContent = get(
            content,
            'additionalInformationContent',
            {}
          )
          if (additionalInformationContent.value) {
            const accessibilityOptions = additionalInformationContent.value.find(
              x => x.name === 'accessibilityOptions'
            )
            accessibilityModalContent = accessibilityOptions?.value || []
          }
          accessibilityModalOptions = (
            ferryOptions.addOnResourceCodes || []
          ).filter(code => code.type === 'accessibility')

          const extrasInformationContent = get(
            content,
            'extrasInformationContent',
            {}
          )
          if (extrasInformationContent.value) {
            const [
              accessibilityCard,
              kennelCard
            ] = extrasInformationContent.value
            accessibilityCardContent = accessibilityCard || []
            kennelCardContent = kennelCard || []
          }
        }

        dispatch(
          setContent({
            language: primaryLangId,
            accommodationView,
            headerContent: get(content, 'header', {}),
            hero: hero,
            vehicleResourceCodesExpanded: formattedVehicleResourceCodes,
            vehicleGroupings: formattedVehicleGroupings,
            vehicleViewContent,
            passengerInformationContent,
            confirmationViewContent,
            serviceMessageContent,
            accessibilityModalContent,
            accessibilityModalOptions,
            accessibilityCardContent,
            kennelCardContent,
            routes: mergedRoutes,
            routesGroupings: routesGroupings,
            canadianForcesModalContent // TODO: replace with actual content
          })
        )

        return dispatch(
          fetchConfigSuccess({
            ...groupedConfigData,
            [DEFAULT_LABEL_TYPE]: {
              ...groupedConfigData[DEFAULT_LABEL_TYPE],
              options: {
                ...groupedConfigData[DEFAULT_LABEL_TYPE].options,
                accommodationView,
                headerContent: get(content, 'header', {}),
                routes: mergedRoutes,
                vehicleResourceCodesExpanded: formattedVehicleResourceCodes,
                vehicleGroupings: formattedVehicleGroupings,
                vehicleViewContent
              },
              labels: null
            }
          })
        )
      } else {
        return dispatch(
          fetchConfigError(
            'There was an error retrieving configuration - please wait a moment, then try again.'
          )
        )
      }
    })
    .catch(err => {
      return dispatch(fetchConfigError(err))
    })
}
