import React from 'react'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import { isEmpty } from 'lodash'
import PassengerQuantitiesForm from '../passenger/quantities/PassengerQuantitiesForm'
import SamePassengersToggle from '../passenger/SamePassengersToggle'
import { setDuplicatePassengerQuantities } from '../../redux/passengerSelections/actions'
import * as UserSelectionActions from '../../redux/userSelections/actions'
import { useDispatch } from 'react-redux'

const PassengerQuantities = ({
  activeRouteForm,
  departureRoute,
  returnRoute,
  isDuplicatedPassengers,
  handleDuplicateDepartureGuests,
  returnPassengersSet,
  labels,
  departurePricesLoading,
  returnPricesLoading,
  departureBestPrices,
  returnBestRates,
  departurePassengerTypes,
  returnPassengerTypes,
  handleNextStep,
  hasRequiredAdults,
  returnPassengersOnly,
  continueButtonLabel,
  isForcesMember
}) => {
  const dispatch = useDispatch()

  const renderSamePassengersToggle =
    !returnPassengersOnly &&
    activeRouteForm === 'RT' &&
    departureRoute &&
    returnRoute
  const renderDeparturePassengersForm =
    !returnPassengersOnly && !isEmpty(departurePassengerTypes)
  const renderReturnPassengersForm =
    returnPassengersOnly ||
    (activeRouteForm === 'RT' &&
      departureRoute &&
      returnRoute &&
      !isDuplicatedPassengers &&
      returnPassengerTypes)

  const handleSamePassengersToggle = value => {
    if (value) {
      if (!isDuplicatedPassengers) {
        dispatch(setDuplicatePassengerQuantities(true)) // TODO: Deprecate
        dispatch(UserSelectionActions.setIsSamePassengers(true))
      }
    } else {
      if (isDuplicatedPassengers) {
        dispatch(setDuplicatePassengerQuantities(false)) // TODO: Deprecate
        dispatch(UserSelectionActions.setIsSamePassengers(false))
        if (!returnPassengersSet) {
          handleDuplicateDepartureGuests()
        }
      }
    }
  }

  return (
    <div className="passenger-quantities u-page-view-container">
      <div className="u-lg-max">
        {renderSamePassengersToggle && (
          <SamePassengersToggle
            isSamePassengers={isDuplicatedPassengers}
            handleToggle={handleSamePassengersToggle}
            labels={labels}
          />
        )}
      </div>
      <div className="u-container">
        {renderDeparturePassengersForm && (
          <PassengerQuantitiesForm
            ferryLabels={labels}
            formHeading={labels.passengers}
            formType="departure"
            passengerTypeData={departurePassengerTypes}
            isLoading={departurePricesLoading}
            bestRates={departureBestPrices}
            isForcesMember={isForcesMember}
          />
        )}
        {renderSamePassengersToggle && (
          <div className="u-lg-up">
            <SamePassengersToggle
              isSamePassengers={isDuplicatedPassengers}
              handleToggle={handleSamePassengersToggle}
              labels={labels}
            />
          </div>
        )}
        {renderReturnPassengersForm && (
          <PassengerQuantitiesForm
            ferryLabels={labels}
            formHeading={labels.returnRoutePassengers}
            formType="return"
            passengerTypeData={returnPassengerTypes}
            isLoading={returnPricesLoading}
            bestRates={returnBestRates}
            isForcesMember={isForcesMember}
          />
        )}
        <div className="passenger-quantities__form-submit u-page-submit-container">
          <button
            className="btn btn-primary large-primary-btn"
            disabled={!hasRequiredAdults ? 'disabled' : ''}
            onClick={() => handleNextStep()}
          >
            {continueButtonLabel} →
          </button>
        </div>
      </div>
    </div>
  )
}

PassengerQuantities.propTypes = {
  activeRouteForm: string.isRequired,
  departureRoute: shape().isRequired,
  returnRoute: shape().isRequired,
  isDuplicatedPassengers: bool.isRequired,
  handleDuplicateDepartureGuests: func.isRequired,
  returnPassengersSet: bool.isRequired,
  labels: shape().isRequired,
  departurePricesLoading: bool,
  returnPricesLoading: bool,
  departureBestPrices: shape(),
  returnBestRates: shape(),
  departurePassengerTypes: arrayOf(shape()).isRequired,
  returnPassengerTypes: arrayOf(shape()).isRequired,
  handleNextStep: func.isRequired,
  hasRequiredAdults: bool.isRequired,
  returnPassengersOnly: bool.isRequired,
  continueButtonLabel: string.isRequired,
  isForcesMember: bool.isRequired
}

PassengerQuantities.defaultProps = {
  departurePricesLoading: false,
  returnPricesLoading: false
}

export default PassengerQuantities
