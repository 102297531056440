import React from 'react'
import { oneOfType, func, shape, string, bool } from 'prop-types'
import yn from 'yn'
import { useDispatch } from 'react-redux'

import LanguageToggle from '../../language/LanguageToggle'
import { HeaderProfileToggles } from '..'
import ConfigConsumer from '../../../redux/configuration/context'
import SvgIcon from '../../SvgIcon/SvgIcon'
import appRoutes from '../../../configuration/appRoutes'
import { clearActiveModifyBooking } from '../../../redux/modifyBooking/actions'
import './HeaderDefault.scss'
import { setModifyBookingNumber } from '../../../redux/userSelections/actions'
import { logout } from '../../../api/api'

const HeaderDefault = ({
  labels,
  brandLogo,
  brandCaption,
  brandMarketingSite,
  handleRouteChange,
  openModal,
  clientContactPhoneNumber,
  showExtendedHeader,
  contactLinkData,
  handleToggleLanguage,
  travelAgentIsLoggedIn,
  customerIsLoggedIn,
  customerAccount,
  travelAgentAccount,
  clearCustomerAccount,
  clearTravelAgentAccount,
  activeModifyBooking
}) => {
  const dispatch = useDispatch()

  function renderContactLink() {
    if (contactLinkData?.url) {
      return (
        <div className="header__content-item">
          <a
            href={contactLinkData.url}
            target="__blank"
            rel="noreferrer noopener"
            className="header-icon-button"
            aria-label={`${labels.contactUs}`}
          >
            <ConfigConsumer>
              {({ themeVariables }) => (
                <SvgIcon
                  className="header__contact-icon"
                  type="contact"
                  fill={themeVariables.themeHeaderIconColor}
                  alt={`${labels.contactUs} Icon`}
                  allowFocus={false}
                />
              )}
            </ConfigConsumer>
            {labels.contactUs}
          </a>
        </div>
      )
    }

    if (clientContactPhoneNumber) {
      return (
        <div className="header__content-item">
          <a
            href={'tel:' + clientContactPhoneNumber}
            className="header-icon-button"
          >
            <ConfigConsumer>
              {({ themeVariables }) => (
                <SvgIcon
                  className="header__contact-icon"
                  type="contact"
                  fill={themeVariables.themeHeaderIconColor}
                  allowFocus={false}
                />
              )}
            </ConfigConsumer>

            {labels.contactUs}
          </a>
        </div>
      )
    }

    return null
  }

  return (
    <div className="header__main">
      <div className="u-container">
        <div className="header__main-content">
          <div className="header__brand">
            {brandLogo && (
              <button
                className="btn header__brand-btn"
                onClick={() => {
                  if (brandMarketingSite) {
                    window.open(brandMarketingSite)
                  } else {
                    handleRouteChange(appRoutes.ferryRoutes.pathname)
                  }
                }}
                aria-label={brandCaption}
              >
                <img
                  src={brandLogo}
                  className="header-brand__logo"
                  alt={brandCaption}
                  role="none"
                />
              </button>
            )}
          </div>

          <div className="header__content">
            {!showExtendedHeader && (
              <button
                className="header-booking-btn btn header__content-item"
                title={labels.booking}
                onClick={e => {
                  e.preventDefault()
                  handleRouteChange(appRoutes.ferryRoutes.pathname)
                  dispatch(clearActiveModifyBooking())
                  dispatch(setModifyBookingNumber(null))
                }}
              >
                {labels.booking}
              </button>
            )}

            {yn(process.env.REACT_APP_COMPONENT_LANGUAGE_TOGGLE) && (
              <LanguageToggle handleToggleLanguage={handleToggleLanguage} />
            )}

            {renderContactLink()}

            {yn(process.env.REACT_APP_ENABLE_TRAVEL_AGENT_USERS) &&
              !customerIsLoggedIn && (
                <HeaderProfileToggles
                  ferryLabels={labels}
                  handleRouteChange={handleRouteChange}
                  openModal={openModal}
                  agentProfile={true}
                  isLoggedIn={travelAgentIsLoggedIn}
                  activeUserName={
                    travelAgentIsLoggedIn ? travelAgentAccount.userName : ''
                  }
                  onLogout={() => {
                    dispatch(clearTravelAgentAccount())

                    // TODO: Build modify flow for travel agents
                    // if (activeModifyBooking) {
                    //   dispatch(clearActiveModifyBooking())
                    //   handleRouteChange(appRoutes.ferryRoutes.pathname)
                    // }
                  }}
                  loginButtonLabel={labels.travelAgentLogin}
                />
              )}
            {yn(process.env.REACT_APP_COMPONENT_PROFILE_SERVICES) &&
              yn(process.env.REACT_APP_COMPONENT_HEADER_PROFILE_SERVICES) &&
              !(
                yn(process.env.REACT_APP_ENABLE_TRAVEL_AGENT_USERS) &&
                travelAgentIsLoggedIn
              ) && (
                <HeaderProfileToggles
                  ferryLabels={labels}
                  handleRouteChange={handleRouteChange}
                  openModal={openModal}
                  isLoggedIn={customerIsLoggedIn}
                  activeUserName={
                    customerIsLoggedIn ? customerAccount.firstName : ''
                  }
                  onLogout={async () => {
                    await logout()
                    dispatch(clearCustomerAccount())

                    if (activeModifyBooking) {
                      dispatch(clearActiveModifyBooking())
                      handleRouteChange(appRoutes.ferryRoutes.pathname)
                    }

                    handleRouteChange(appRoutes.ferryRoutes.pathname)
                  }}
                  loginButtonLabel={labels.login}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

HeaderDefault.propTypes = {
  labels: shape().isRequired,
  brandLogo: string,
  brandCaption: string,
  brandMarketingSite: string,
  handleRouteChange: func.isRequired,
  openModal: func.isRequired,
  clientContactPhoneNumber: string,
  showExtendedHeader: bool,
  contactLinkData: shape({ url: string }),
  handleToggleLanguage: func.isRequired,
  travelAgentIsLoggedIn: bool.isRequired,
  customerIsLoggedIn: bool.isRequired,
  customerAccount: oneOfType([bool, shape()]),
  travelAgentAccount: oneOfType([bool, shape()]),
  clearCustomerAccount: func.isRequired,
  clearTravelAgentAccount: func.isRequired,
  activeModifyBooking: shape()
}

export default HeaderDefault
