import { isEqual } from 'lodash'
import { handleActions } from 'redux-actions'
import { actionTypes } from './actions'

export const refactorValidationInitialState = {
  pendingCurrent: {}, // format = {KEY: {PARAMS}}
  pendingUpdated: {}, // same as above
  results: {} // array of {KEY: { passes: NUM_PASSES, errors: [{current: PARAMS, updated: PARAMS}]}}
}

const refactorValidationReducer = handleActions(
  {
    [actionTypes.REGISTER_CURRENT_PARAMS]: (state, action) => {
      const newState = { ...state }
      newState.pendingCurrent[action.payload.key] = action.payload.params
      return GetComparedRefactorResults(newState, action.payload.key)
    },
    [actionTypes.REGISTER_UPDATED_PARAMS]: (state, action) => {
      const newState = { ...state }
      newState.pendingUpdated[action.payload.key] = action.payload.params
      return GetComparedRefactorResults(newState, action.payload.key)
    }
  },
  { ...refactorValidationInitialState }
)

function GetComparedRefactorResults(newState, key) {
  const currentParams = newState.pendingCurrent[key]
  const updatedParams = newState.pendingUpdated[key]

  // if one of these keys doesn't exist, don't resolve results
  if (!currentParams || !updatedParams) {
    return newState
  }

  // this result is a pass, update the tally or init the variable
  if (isEqual(currentParams, updatedParams)) {
    if (newState.results[key]) {
      newState.results[key].passes += 1
    } else {
      newState.results[key] = { passes: 1, errors: [] }
    }
    // remove from pending state
    delete newState.pendingCurrent[key]
    delete newState.pendingUpdated[key]
    return newState
  }

  // this result is a failure, log the params at the time of failure (init if needed)
  if (newState.results[key]) {
    newState.results[key].errors.push({
      current: currentParams,
      updated: updatedParams
    })
  } else {
    newState.results[key] = {
      passes: 0,
      errors: [{ current: currentParams, updated: updatedParams }]
    }
  }
  // remove from pending state
  delete newState.pendingCurrent[key]
  delete newState.pendingUpdated[key]
  return newState
}

export default refactorValidationReducer
