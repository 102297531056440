import React from 'react'
import { bool } from 'prop-types'
import { useSelector } from 'react-redux'
import { selectLabels } from '../../../redux/configuration/selectors'
import { ENABLE_ACCOMMODATIONS } from '../../../configuration/constants'
import ConfigConsume from '../../../redux/configuration/context'

// STYLES
import './ResourceAvailability.scss'
import SvgIcon from '../../SvgIcon/SvgIcon'

const ResourceAvailability = ({
  vehiclesAvailable,
  accommodationsAvailable,
  isFlex
}) => {
  const labels = useSelector(selectLabels)
  return (
    <ConfigConsume>
      {({ themeVariables }) => (
        <div
          className={`crossing-card-resource-availability ${
            isFlex ? 'flex' : ''
          }`}
        >
          <div className={`resources`}>
            {vehiclesAvailable ? (
              <div className="detail accommodations">
                <SvgIcon
                  type="vehicle"
                  fill={themeVariables.themeIconColor}
                  className="crossing-card-resource-icon"
                  width="26"
                  viewBox="0 0 18 16"
                  height="24"
                />
                <p>{labels.availableVehicleSpace}</p>
              </div>
            ) : (
              <div className="detail accommodations">
                <SvgIcon
                  type="no-vehicle"
                  fill={themeVariables.themeDisabledIconColor}
                  className="crossing-card-resource-icon"
                  width="26"
                  viewBox="0 0 535 541"
                  height="23"
                />
                <p>{labels.noAvailableVehicleSpace}</p>
              </div>
            )}
            {accommodationsAvailable && (
              <div className="detail accommodations">
                <SvgIcon
                  type="accommodations"
                  fill={themeVariables.themeIconColor}
                  className="crossing-card-resource-icon"
                  width="26"
                  viewBox="0 0 19 19"
                  height="26"
                />
                <p>{labels.availableAccommodations}</p>
              </div>
            )}
            {ENABLE_ACCOMMODATIONS && !accommodationsAvailable && (
              <div className="detail accommodations">
                <SvgIcon
                  type="no-accommodations"
                  fill={themeVariables.themeDisabledIconColor}
                  className="crossing-card-resource-icon"
                  width="26"
                  viewBox="0 0 535 541"
                  height="26"
                />
                <p>{labels.noAvailableAccommodations}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </ConfigConsume>
  )
}

ResourceAvailability.propTypes = {
  vehiclesAvailable: bool,
  accommodationsAvailable: bool,
  isFlex: bool
}

export default ResourceAvailability
