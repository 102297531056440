import React, { Fragment, useEffect } from 'react'
import { bool, func } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import StatusModal from '../StatusModal/StatusModal'
import { fetchWebsiteStatus } from '../../redux/configuration/actions'
import {
  selectLabels,
  selectFetchingWebsiteStatus,
  selectWebsiteStatusError,
  selectWebsiteStatus,
  selectConfigurationFerryOptions
} from '../../redux/configuration/selectors'
import statusModalImage from '../../assets/images/brand-status-image.png'

const WebsiteStatusModalContainer = ({ isOpen, openModal }) => {
  const dispatch = useDispatch()
  const labels = useSelector(selectLabels)
  const fetchingWebsiteStatus = useSelector(selectFetchingWebsiteStatus)
  const fetchWebsiteStatusError = useSelector(selectWebsiteStatusError)
  const websiteStatus = useSelector(selectWebsiteStatus)
  const options = useSelector(selectConfigurationFerryOptions)
  const check = options?.checkAvailabilityLink
  const availabilityLink = check && options?.websiteAvailabilityLink

  useEffect(() => {
    if (
      availabilityLink &&
      !websiteStatus &&
      !fetchingWebsiteStatus &&
      !fetchWebsiteStatusError
    ) {
      dispatch(fetchWebsiteStatus({ availabilityLink }))
    }
    if (
      !isOpen &&
      !fetchingWebsiteStatus &&
      !fetchWebsiteStatusError &&
      websiteStatus?.data?.serviceAvailable === false
    ) {
      openModal({ type: 'website-status' })
    }
  }, [
    availabilityLink,
    isOpen,
    dispatch,
    websiteStatus,
    fetchingWebsiteStatus,
    fetchWebsiteStatusError,
    openModal
  ])

  return (
    <StatusModal
      backgroundUrl={statusModalImage}
      backgroundAltLabel={labels.bookingNotAvailable}
      labels={labels}
      isOpen={isOpen}
    >
      <Fragment>
        <h4 className="modal-col-title center-text theme-font-header">
          {labels.bookingNotAvailable}
        </h4>
        <p>{labels.checkSoon}</p>
      </Fragment>
    </StatusModal>
  )
}

WebsiteStatusModalContainer.propTypes = {
  isOpen: bool.isRequired,
  openModal: func.isRequired
}

export default WebsiteStatusModalContainer
