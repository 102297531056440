const isProd = process.env.REACT_APP_CHASE_CHECKOUT_MODE === 'production'

// callbacks
export const PAGE_LOADED = 'page_loaded'
export const CANCEL_TRANSACTION = 'cancel_transaction'
export const ERROR_EVENT = 'error_event'
export const PAYMENT_COMPLETE = 'payment_complete'

// callback response codes
export const CALLBACK_SUCCESS_CODE = '001'

export const CHECKOUT_MODE = isProd ? 'prod' : 'qa'
export const CHECKOUT_LIBRARY_URL = isProd
  ? 'https://gateway.moneris.com/chkt/js/chkt_v1.00.js'
  : 'https://gatewayt.moneris.com/chkt/js/chkt_v1.00.js'

// payment methods: CASH | INVOICE
export const PAYMENT_METHOD_CASH = 'C'
export const PAYMENT_METHOD_INVOICE = 'I'
