import {
  selectPassengerTypeMap,
  selectActiveRouteForm
} from '../ferryRouteSelections/selectors'
import {
  selectDuplicatePassengerQuantities,
  selectPassengerSelections
} from '../passengerSelections/selectors'
import { createSelector } from 'reselect'
import { selectFields } from '../configuration/selectors'

/**
 * compose passenger field details in each direction like this:
 *
 * {
 *   departures: [
 *     passengers: [
 *       {passengerType: 'A'},
 *       {passengerType: 'E'},
 *       {passengerType: 'S'},
 *       ....
 *     ]
 *   ],
 *   returns: [
 *     passengers: [{},{},...]
 *   ]
 * }
 *
 * USED IN CONTAINERS:
 * - SummaryContainer
 * - BookingInformationContainer
 */
export const selectPassengersForBooking = createSelector(
  selectPassengerSelections,
  selectDuplicatePassengerQuantities,
  selectFields,
  selectPassengerTypeMap,
  selectActiveRouteForm,
  (
    passengerSelections,
    duplicateQuantities,
    fields,
    passengerTypes,
    activeRouteForm
  ) => {
    const isOneWay = activeRouteForm === 'OW'
    const result = {
      departures: [{ passengers: [] }],
      returns: [{ passengers: [] }]
    }

    Object.keys(passengerSelections).forEach(selectionType => {
      if (selectionType === 'departure' || selectionType === 'return') {
        Object.keys(passengerSelections[selectionType]).forEach(
          passengerType => {
            const directionPassengerTypes = passengerTypes[selectionType]
            const passengerTypeValue = directionPassengerTypes[passengerType]

            if (passengerTypeValue) {
              const typeAmount =
                passengerSelections[selectionType][passengerType] || 0

              let count = 0
              while (count < typeAmount) {
                result[`${selectionType}s`][0].passengers.push({
                  passengerType: passengerTypeValue
                })
                count++
              }
            }
          }
        )
      }
    })
    if (duplicateQuantities || isOneWay) delete result.returns

    return result
  }
)

// TODO: Another selectPassengersForBooking with user selections
// similar to selectPassengersForBooking
export const selectPassengersForBookingRefactored = createSelector()
