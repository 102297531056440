import React from 'react'
import { bool, func, shape, string, number, oneOfType } from 'prop-types'
import Modal from '../Modal'
import { useSelector } from 'react-redux'
import { selectLanguage } from '../../redux/session/selectors'
import renderPaymentConfirmationModalRefundCopy from '../../utilities/content/renderPaymentConfirmationModalCopy'

const SubmitPaymentModal = ({
  onSubmit,
  onCloseButtonClick,
  isOpen,
  labels,
  disabled,
  amountOwing,
  activeModifyBooking
}) => {
  const lang = useSelector(selectLanguage)
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseButtonClick}
      className="modal--med submit-payment-modal"
    >
      <div className="submit-payment-modal-content">
        {+amountOwing > 0 ? (
          <>
            <h4>{labels.confirmPaymentModalTitle}</h4>
            <p>{labels.confirmPaymentModalCopy}</p>
            {activeModifyBooking && (
              <p>{labels.confirmPaymentModalAttention}</p>
            )}
          </>
        ) : (
          <>
            <h4>{labels.confirmChangesMadeToBooking}</h4>
            {+amountOwing !== 0 && (
              <p>
                {renderPaymentConfirmationModalRefundCopy(
                  lang,
                  (+amountOwing * -1).toFixed(2)
                )}
              </p>
            )}
          </>
        )}

        <div className="submit-payment-modal-ctas">
          <button
            className="btn btn-primary"
            onClick={onSubmit}
            disabled={disabled}
          >
            {+amountOwing > 0
              ? labels.confirmPaymentContinueButtonText
              : labels.yes}
          </button>
          <button
            className="btn -btn-secondary"
            onClick={onCloseButtonClick}
            disabled={disabled}
          >
            {+amountOwing > 0
              ? labels.confirmPaymentCancelButtonText
              : labels.no}
          </button>
        </div>
      </div>
    </Modal>
  )
}

SubmitPaymentModal.propTypes = {
  onSubmit: func.isRequired,
  onCloseButtonClick: func.isRequired,
  isOpen: bool,
  labels: shape().isRequired,
  disabled: bool,
  amountOwing: oneOfType([string, number]),
  activeModifyBooking: oneOfType([shape(), bool])
}

SubmitPaymentModal.defaultProps = {
  isOpen: false,
  disabled: false
}

export default SubmitPaymentModal
