import React from 'react'
import { oneOfType, string, number, func, bool } from 'prop-types'
import { useSelector } from 'react-redux'
import { isCurrentPath } from '../../utilities/browser/comparePaths'

import LoadingDots from '../LoadingDots/LoadingDots'
import { appRoutes } from '../../configuration/constants'
import { selectLabels } from '../../redux/configuration/selectors'
import { formatPriceByLanguage } from '../../redux/configuration/utilities'
import { selectLanguage } from '../../redux/session/selectors'

import { ReactComponent as DiscountIcon } from '../../assets/icons/SealPercent.svg'

const HeaderBreakdownToggle = React.forwardRef((props, ref) => {
  const {
    tripTotal,
    setIsOpen,
    isOpen,
    // disabled,
    loading,
    totalAfterDiscount,
    promoApplied
  } = props
  const labels = useSelector(selectLabels)
  const lang = useSelector(selectLanguage)

  // temp disabling cart open
  // const isDisabled =
  //   disabled ||
  //   isCurrentPath(appRoutes.ferryRoutes.pathname) ||
  //   isCurrentPath(appRoutes.summary.pathname)

  return (
    <button
      className={`btn header-details__toggle ${isOpen ? 'active' : ''} ${
        process.env.REACT_APP_FLOW_CLIENT_ID
      }`}
      onClick={() => setIsOpen(!isOpen)}
      disabled
      ref={ref}
      aria-label={labels.viewBreakdown || 'View Price Breakdown'}
    >
      {loading && !isCurrentPath(appRoutes.ferryRoutes.pathname) ? (
        <LoadingDots color={'white'} />
      ) : (
        <>
          {promoApplied && (
            <div className="promo-applied-banner">
              <DiscountIcon />
              <p>{labels.promoApplied}</p>
            </div>
          )}
          <h6 className="details-total">
            {tripTotal && !isCurrentPath(appRoutes.ferryRoutes.pathname)
              ? formatPriceByLanguage(tripTotal, lang)
              : labels.defaultPrice}
          </h6>
          {promoApplied && totalAfterDiscount ? (
            <p className="details-discounted-price">
              {formatPriceByLanguage(totalAfterDiscount, lang)}
            </p>
          ) : null}
        </>
      )}

      {/*<p className="details-label">{labels.details}</p>*/}
    </button>
  )
})

HeaderBreakdownToggle.propTypes = {
  tripTotal: oneOfType([string, number]),
  setIsOpen: func.isRequired,
  isOpen: bool,
  disabled: bool,
  loading: bool
}

export default HeaderBreakdownToggle
