import {
  selectDepartureAdults,
  selectDepartureSeniors,
  selectDepartureStudents,
  selectDepartureChildren,
  selectDepartureInfants,
  selectDeparturePets
} from '../passengerSelections/selectors'
import { createSelector } from 'reselect'

export const selectQueryParamDeparturePassengers = createSelector(
  selectDepartureAdults,
  selectDepartureSeniors,
  selectDepartureStudents,
  selectDepartureChildren,
  selectDepartureInfants,
  selectDeparturePets,
  (
    departureAdults,
    departureSeniors,
    departureStudents,
    departureChildren,
    departureInfants,
    departurePets
  ) => ({
    departureAdults,
    departureSeniors,
    departureStudents,
    departureChildren,
    departureInfants,
    departurePets
  })
)
