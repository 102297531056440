import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { func, shape, string, bool } from 'prop-types'
import yn from 'yn'
import {
  ferryRoutes,
  summary,
  passengerDetails,
  modifyBooking
} from '../../configuration/appRoutes'
import { HEADER_DETAILS_ON_ROUTE_SELECTION } from '../../configuration/constants'
import BrandLoader from '../../components/BrandLoader/BrandLoader'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import { HeaderDetails } from '../../components/Header'
import ModifyBookingBanner from '../../components/ModifyBookingBanner/ModifyBookingBanner'

import Stepper from '../../components/Stepper/Stepper'
import Views from '../views/Views'
import {
  selectLabels,
  selectConfigurationState,
  selectConfigurationError
} from '../../redux/configuration/selectors'
import { selectActiveModifyBooking } from '../../redux/modifyBooking/selectors'
import { selectAttemptingGetCustomer } from '../../redux/user/selectors'
import modalIds from '../../context/configuration'
import { useActiveBookingModification } from '../../redux/header/hooks'

/**
 * Global Layout component wrapping:
 *
 * - HeaderDetails
 * - ModifyBookingBanner
 * - Stepper
 * - Views
 *
 */
const Layout = ({
  pathname,
  handleRouteChange,
  handleToggleLanguage,
  openModal,
  closeModal,
  changeModals,
  modalIsOpen,
  showExtendedHeader,
  isBootstrapping
}) => {
  const routesWithoutDetailsHeader = HEADER_DETAILS_ON_ROUTE_SELECTION
    ? []
    : [ferryRoutes.pathname, modifyBooking.pathname]

  const routesWithoutBreakdownHeader = [
    summary.pathname,
    passengerDetails.pathname
  ]
  const hideStepperRoutes = [ferryRoutes.pathname, modifyBooking.pathname]

  const shouldHideStepper = hideStepperRoutes.includes(pathname)
  const shouldHideDetailsHeader = routesWithoutDetailsHeader.some(
    route => route === pathname || route + '/' === pathname
  )
  const shouldHidePriceBreakdown = routesWithoutBreakdownHeader.some(
    route => route === pathname || route + '/' === pathname
  )

  const labels = useSelector(selectLabels)
  const activeModifyBooking = useSelector(selectActiveModifyBooking)

  const configuration = useSelector(selectConfigurationState)
  const configError = useSelector(selectConfigurationError)
  const attemptingGetCustomer = useSelector(selectAttemptingGetCustomer)
  const isLoading =
    Boolean(configuration.loading || (!configuration.data && !configError)) ||
    attemptingGetCustomer

  //FIXME: save active accommodations to store to fix the issue:
  // https://jira.verbinteractive.com/browse/CTM200-576
  useActiveBookingModification(activeModifyBooking)

  useEffect(() => {
    // add GTM for MAI
    ;(function(w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', 'GTM-WLJGLH5')
  }, [])

  return (
    <Fragment>
      {showExtendedHeader && (
        <Fragment>
          {yn(process.env.REACT_APP_COMPONENT_HEADER_DETAILS) &&
            !shouldHideDetailsHeader && (
              <HeaderDetails
                handleRouteChange={handleRouteChange}
                openModal={openModal}
                closeModal={closeModal}
                modalIsOpen={modalIsOpen}
                hidePriceBreakdown={shouldHidePriceBreakdown}
              />
            )}
          {pathname === ferryRoutes.pathname && shouldHideDetailsHeader && (
            <Fragment>
              <ModifyBookingBanner
                bookingNumberLabel={`${
                  labels.modifyingBooking
                } #${activeModifyBooking?.bookingNumber || ''}`}
                myAccountLabel={labels.myAccountBtn || ''}
                onMyAccountClick={() =>
                  openModal({ type: modalIds.continueToAccounts })
                }
                isDisplayed={!!activeModifyBooking}
              />
            </Fragment>
          )}

          {/* make it simpler and readable! */}
          {!shouldHideStepper && (
            <Stepper
              handleRouteChange={handleRouteChange}
              isDisabled={isLoading || !!configError}
              postStepperPaths={[summary.pathname, passengerDetails.pathname]}
            />
          )}
        </Fragment>
      )}
      <div className="main">
        {isLoading || isBootstrapping ? (
          <BrandLoader />
        ) : configError ? (
          <ErrorBoundary errorMessage={configError} />
        ) : (
          <Views
            handleRouteChange={handleRouteChange}
            handleToggleLanguage={handleToggleLanguage}
            openModal={openModal}
            changeModals={changeModals}
            closeModal={closeModal}
            modalIsOpen={modalIsOpen}
          />
        )}
      </div>
    </Fragment>
  )
}

Layout.propTypes = {
  pathname: string.isRequired,
  handleRouteChange: func.isRequired,
  handleToggleLanguage: func.isRequired,
  openModal: func.isRequired,
  closeModal: func.isRequired,
  changeModals: func.isRequired,
  modalIsOpen: shape().isRequired,
  showExtendedHeader: bool
}

export default Layout
