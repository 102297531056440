import { handleActions } from 'redux-actions'
import {
  FETCH_PAYMENT_CONFIRMATION,
  PAYMENT_CONFIRMATION_ERROR,
  PAYMENT_CONFIRMATION_SUCCESS,
  CLEAR_PAYMENT_CONFIRMATION,
  SET_PAYMENT_FERRY_ID,
  SET_PAYPAL_PAYMENT_CONFIRMATION
} from './constants'

const paymentConfirmationInitialState = {
  loading: false,
  data: null,
  error: null,
  paymentFerryId: ''
}

/*
 * BE VERY CAREFUL NOT TO REMOVE paymentFerryId WHEN ADDING HANDLERS.
 * */

const paymentConfirmationReducer = handleActions(
  {
    [SET_PAYMENT_FERRY_ID]: (state, { payload }) => ({
      ...state,
      paymentFerryId: payload
    }),
    [FETCH_PAYMENT_CONFIRMATION]: state => {
      return {
        ...state,
        loading: true
      }
    },
    [PAYMENT_CONFIRMATION_SUCCESS]: (state, action) => {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      }
    },
    [PAYMENT_CONFIRMATION_ERROR]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    },
    [CLEAR_PAYMENT_CONFIRMATION]: state => ({
      ...paymentConfirmationInitialState,
      paymentFerryId: state.paymentFerryId
    }),
    [SET_PAYPAL_PAYMENT_CONFIRMATION]: (state, action) => ({
      ...state,
      payPalPaymentData: action.payload
    })
  },
  { ...paymentConfirmationInitialState }
)

export { paymentConfirmationInitialState }
export default paymentConfirmationReducer
