import React from 'react'
import { string } from 'prop-types'
import './LoadingDots.scss'

const LoadingDots = ({ color }) => {
  return (
    <div className={`lds-ellipsis ${color ? color : ''}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

LoadingDots.propTypes = {
  color: string
}

export default LoadingDots
