import React from 'react'
import { bool, node, shape, string, func } from 'prop-types'
import RowGroup from '../RowGroup/RowGroup'
import { renderCheckbox } from '../Checkbox/Checkbox'
import './CheckboxWithTitle.scss'

const CheckboxWithTitle = ({
  fieldKey,
  title,
  fieldDetails,
  children,
  values,
  disabled,
  setFieldValue,
  formikCheckbox,
  onBlur,
  onChange
}) => {
  return !fieldDetails ||
    (Object.keys(fieldDetails).length === 0 && !values) ? null : (
    <div className="row checkbox-with-title">
      <div className="checkbox-with-title-container">
        <RowGroup
          title={title}
          render={({ formRowClasses, errorClasses }) => (
            <span className={formRowClasses}>
              {fieldDetails[fieldKey] &&
                renderCheckbox({
                  name: fieldDetails[fieldKey].name,
                  value: values[fieldDetails[fieldKey].name],
                  // note: the children node is the label for the checkbox
                  label: children,
                  disabled,
                  errorClasses,
                  formikCheckbox,
                  onBlur,
                  onChange
                })}
            </span>
          )}
        />
      </div>
    </div>
  )
}

CheckboxWithTitle.propTypes = {
  /**
   * The fieldKey in fieldDetails and values
   */
  fieldKey: string.isRequired,
  /**
   * The title above the checkbox
   */
  title: string.isRequired,
  /**
   * The details for this field
   */
  fieldDetails: shape().isRequired,
  /**
   * The label node to the right of the checkbox
   */
  children: node.isRequired,
  /**
   * The form values object
   */
  values: shape().isRequired,
  /**
   * setFieldValue for formik
   */
  setFieldValue: func,
  /**
   * Checkbox disabled state
   */
  disabled: bool,
  /**
   * Set formikCheckbox to false to render a standard checkbox instead of a formik Field
   */
  formikCheckbox: bool,
  onBlur: func,
  onChange: func
}

CheckboxWithTitle.defaultProps = {
  disabled: false,
  formikCheckbox: true
}

export default CheckboxWithTitle
