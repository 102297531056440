export const allCountriesMAI = [
  {
    country: 'ANDORRA',
    abbreviation: 'AD',
    labels: {
      fr: 'ANDORRE'
    }
  },
  {
    country: 'UNITED ARAB EM.',
    abbreviation: 'AE',
    labels: {
      fr: 'ÉMIRATS ARABES UNIS'
    }
  },
  {
    country: 'AFGANISTAN',
    abbreviation: 'AF',
    labels: {
      fr: 'AFGHANISTAN'
    }
  },
  {
    country: 'ANTIGUA AND BAR',
    abbreviation: 'AG',
    labels: {
      fr: 'ANTIGUA ET BARBUDA'
    }
  },
  {
    country: 'ALBANIA',
    abbreviation: 'AL',
    labels: {
      fr: 'ALBANIE'
    }
  },
  {
    country: 'ARMENIA',
    abbreviation: 'AM',
    labels: {
      fr: 'ARMÉNIE'
    }
  },
  {
    country: 'ANGOLA',
    abbreviation: 'AO',
    labels: {
      fr: 'ANGOLA'
    }
  },
  {
    country: 'ARGENTINA',
    abbreviation: 'AR',
    labels: {
      fr: 'ARGENTINE'
    }
  },
  {
    country: 'AUSTRIA',
    abbreviation: 'AT',
    labels: {
      fr: 'AUTRICHE'
    }
  },
  {
    country: 'AUSTRALIA',
    abbreviation: 'AU',
    labels: {
      fr: 'AUSTRALIE'
    }
  },
  {
    country: 'AZERBAIJAN',
    abbreviation: 'AZ',
    labels: {
      fr: 'AXERBAÏDJANAIS'
    }
  },
  {
    country: 'BOSNIA-HERZEGOV',
    abbreviation: 'BA',
    labels: {
      fr: 'BOSNIAQUE'
    }
  },
  {
    country: 'BARBADOS',
    abbreviation: 'BB',
    labels: {
      fr: 'BARBADE'
    }
  },
  {
    country: 'BANGLADESH',
    abbreviation: 'BD',
    labels: {
      fr: 'BANGLADESH'
    }
  },
  {
    country: 'BELGIUM',
    abbreviation: 'BE',
    labels: {
      fr: 'BELGIQUE'
    }
  },
  {
    country: 'BURKINA FASO',
    abbreviation: 'BF',
    labels: {
      fr: 'BURKINA FASO'
    }
  },
  {
    country: 'BULGARIA',
    abbreviation: 'BG',
    labels: {
      fr: 'BULGARIE'
    }
  },
  {
    country: 'BAHRAIN',
    abbreviation: 'BH',
    labels: {
      fr: 'BAHREÏN'
    }
  },
  {
    country: 'BURUNDI',
    abbreviation: 'BI',
    labels: {
      fr: 'BURUNDI'
    }
  },
  {
    country: 'BENIN',
    abbreviation: 'BJ',
    labels: {
      fr: 'BÉNIN'
    }
  },
  {
    country: 'BRUNEI DARUSSAL',
    abbreviation: 'BN',
    labels: {
      fr: 'BRUNÉI DARUSSALAM'
    }
  },
  {
    country: 'BOLIVIA',
    abbreviation: 'BO',
    labels: {
      fr: 'BOLIVIE'
    }
  },
  {
    country: 'BRAZIL',
    abbreviation: 'BR',
    labels: {
      fr: 'BRÉZIL'
    }
  },
  {
    country: 'BAHAMAS',
    abbreviation: 'BS',
    labels: {
      fr: 'BAHAMAS'
    }
  },
  {
    country: 'BHUTAN',
    abbreviation: 'BT',
    labels: {
      fr: 'BHOUTAN'
    }
  },
  {
    country: 'BOTSWANA',
    abbreviation: 'BW',
    labels: {
      fr: 'BOTSWANA'
    }
  },
  {
    country: 'BELARUS',
    abbreviation: 'BY',
    labels: {
      fr: 'BÉLARUS'
    }
  },
  {
    country: 'BELIZE',
    abbreviation: 'BZ',
    labels: {
      fr: 'BELIZE'
    }
  },
  {
    country: 'CANADA',
    abbreviation: 'CA',
    labels: {
      fr: 'CANADA'
    }
  },
  {
    country: 'CONGO DEM.REP',
    abbreviation: 'CD',
    labels: {
      fr: 'RÉP. DÉM. DU CONGO'
    }
  },
  {
    country: 'CENTRAL AFR.REP',
    abbreviation: 'CF',
    labels: {
      fr: 'RÉP. CENTRAFRICAINE'
    }
  },
  {
    country: 'CONGO',
    abbreviation: 'CG',
    labels: {
      fr: 'CONGO'
    }
  },
  {
    country: 'SWITZERLAND',
    abbreviation: 'CH',
    labels: {
      fr: 'SWISSE'
    }
  },
  {
    country: 'COTE DIVOIRE',
    abbreviation: 'CI',
    labels: {
      fr: "CÔTE D'IVOIRE"
    }
  },
  {
    country: 'CHILE',
    abbreviation: 'CL',
    labels: {
      fr: 'CHILI'
    }
  },
  {
    country: 'CAMEROON',
    abbreviation: 'CM',
    labels: {
      fr: 'CAMEROUN'
    }
  },
  {
    country: 'CHINA',
    abbreviation: 'CN',
    labels: {
      fr: 'CHINE'
    }
  },
  {
    country: 'COLOMBIA',
    abbreviation: 'CO',
    labels: {
      fr: 'COLOMBIE'
    }
  },
  {
    country: 'COSTA RICA',
    abbreviation: 'CR',
    labels: {
      fr: 'COSTA RICA'
    }
  },
  {
    country: 'SERBIA AND MON.',
    abbreviation: 'CS',
    labels: {
      fr: 'SERBIE ET MON.'
    }
  },
  {
    country: 'CUBA',
    abbreviation: 'CU',
    labels: {
      fr: 'CUBA'
    }
  },
  {
    country: 'CAPE VERDE',
    abbreviation: 'CV',
    labels: {
      fr: 'CAP-VERT'
    }
  },
  {
    country: 'CYPRUS',
    abbreviation: 'CY',
    labels: {
      fr: 'CHYPRE'
    }
  },
  {
    country: 'CZECH REPUBLIC',
    abbreviation: 'CZ',
    labels: {
      fr: 'RÉPUBLIQUE TCHÈQUE'
    }
  },
  {
    country: 'GERMANY',
    abbreviation: 'DE',
    labels: {
      fr: 'ALLEMAGNE'
    }
  },
  {
    country: 'DJIBOUTI',
    abbreviation: 'DJ',
    labels: {
      fr: 'DJIBOUTI'
    }
  },
  {
    country: 'DENMARK',
    abbreviation: 'DK',
    labels: {
      fr: 'DANEMARK'
    }
  },
  {
    country: 'DOMINICA',
    abbreviation: 'DM',
    labels: {
      fr: 'DOMINIQUE'
    }
  },
  {
    country: 'DOMINICAN REP.',
    abbreviation: 'DO',
    labels: {
      fr: 'RÉP. DOMINICAINE'
    }
  },
  {
    country: 'ALGERIA',
    abbreviation: 'DZ',
    labels: {
      fr: 'ALGÉRIE'
    }
  },
  {
    country: 'ECUADOR',
    abbreviation: 'EC',
    labels: {
      fr: 'ÉQUATEUR'
    }
  },
  {
    country: 'ESTONIA',
    abbreviation: 'EE',
    labels: {
      fr: 'ESTONIE'
    }
  },
  {
    country: 'EGYPT',
    abbreviation: 'EG',
    labels: {
      fr: 'ÉGYPTE'
    }
  },
  {
    country: 'ERITREA',
    abbreviation: 'ER',
    labels: {
      fr: 'ÉRYTHRÉE'
    }
  },
  {
    country: 'SPAIN',
    abbreviation: 'ES',
    labels: {
      fr: 'ESPAGNE'
    }
  },
  {
    country: 'ETHIOPIA',
    abbreviation: 'ET',
    labels: {
      fr: 'ÉTHIOPIE'
    }
  },
  {
    country: 'EURO',
    abbreviation: 'EU',
    labels: {
      fr: 'EURO'
    }
  },
  {
    country: 'FINLAND',
    abbreviation: 'FI',
    labels: {
      fr: 'FINLANDE'
    }
  },
  {
    country: 'FIJI',
    abbreviation: 'FJ',
    labels: {
      fr: 'FIDJI'
    }
  },
  {
    country: 'MICRONESIA',
    abbreviation: 'FM',
    labels: {
      fr: 'MICRONÉSIE'
    }
  },
  {
    country: 'FRANCE',
    abbreviation: 'FR',
    labels: {
      fr: 'FRANCE'
    }
  },
  {
    country: 'GABON',
    abbreviation: 'GA',
    labels: {
      fr: 'GABON'
    }
  },
  {
    country: 'GREAT BRITAIN',
    abbreviation: 'GB',
    labels: {
      fr: 'GRANDE-BRETAGNE'
    }
  },
  {
    country: 'GRENADA',
    abbreviation: 'GD',
    labels: {
      fr: 'GRENADE'
    }
  },
  {
    country: 'GEORGIA',
    abbreviation: 'GE',
    labels: {
      fr: 'GÉORGIE'
    }
  },
  {
    country: 'GHANA',
    abbreviation: 'GH',
    labels: {
      fr: 'GHANA'
    }
  },
  {
    country: 'GAMBIA',
    abbreviation: 'GM',
    labels: {
      fr: 'GAMBIE'
    }
  },
  {
    country: 'GUINEA',
    abbreviation: 'GN',
    labels: {
      fr: 'GUINÉE'
    }
  },
  {
    country: 'EQUATOR. GUINEA',
    abbreviation: 'GQ',
    labels: {
      fr: 'GUINÉE ÉQUATORIALE'
    }
  },
  {
    country: 'GREECE',
    abbreviation: 'GR',
    labels: {
      fr: 'GRÈCE'
    }
  },
  {
    country: 'GUATEMALA',
    abbreviation: 'GT',
    labels: {
      fr: 'GUATÉMALA'
    }
  },
  {
    country: 'GUINEA-BISSAU',
    abbreviation: 'GW',
    labels: {
      fr: 'GUINÉE-BISSAU'
    }
  },
  {
    country: 'GUYANA',
    abbreviation: 'GY',
    labels: {
      fr: 'GUYANA'
    }
  },
  {
    country: 'HONG KONG',
    abbreviation: 'HK',
    labels: {
      fr: 'HONG KONG'
    }
  },
  {
    country: 'HEARD ISL.',
    abbreviation: 'HM',
    labels: {
      fr: 'ÎLE HEARD'
    }
  },
  {
    country: 'HONDURAS',
    abbreviation: 'HN',
    labels: {
      fr: 'HONDURAS'
    }
  },
  {
    country: 'CROATIA',
    abbreviation: 'HR',
    labels: {
      fr: 'CROATIE'
    }
  },
  {
    country: 'HAITI',
    abbreviation: 'HT',
    labels: {
      fr: 'HAÏTI'
    }
  },
  {
    country: 'HUNGARY',
    abbreviation: 'HU',
    labels: {
      fr: 'HONGIRE'
    }
  },
  {
    country: 'INDONESIA',
    abbreviation: 'ID',
    labels: {
      fr: 'INDONÉSIE'
    }
  },
  {
    country: 'IRELAND',
    abbreviation: 'IE',
    labels: {
      fr: 'IRLANDE'
    }
  },
  {
    country: 'ISRAEL',
    abbreviation: 'IL',
    labels: {
      fr: 'ISRAËL'
    }
  },
  {
    country: 'INDIA',
    abbreviation: 'IN',
    labels: {
      fr: 'INDE'
    }
  },
  {
    country: 'IRAQ',
    abbreviation: 'IQ',
    labels: {
      fr: 'IRAQ'
    }
  },
  {
    country: 'IRAN',
    abbreviation: 'IR',
    labels: {
      fr: 'IRAN'
    }
  },
  {
    country: 'ICELAND',
    abbreviation: 'IS',
    labels: {
      fr: 'ISLANDE'
    }
  },
  {
    country: 'ITALY',
    abbreviation: 'IT',
    labels: {
      fr: 'ITALIE'
    }
  },
  {
    country: 'JAMAICA',
    abbreviation: 'JM',
    labels: {
      fr: 'JAMAÏQUE'
    }
  },
  {
    country: 'JORDAN',
    abbreviation: 'JO',
    labels: {
      fr: 'JORDANIE'
    }
  },
  {
    country: 'JAPAN',
    abbreviation: 'JP',
    labels: {
      fr: 'JAPON'
    }
  },
  {
    country: 'KENYA',
    abbreviation: 'KE',
    labels: {
      fr: 'KENYA'
    }
  },
  {
    country: 'KYRGYZSTAN',
    abbreviation: 'KG',
    labels: {
      fr: 'KYRGHIZISTAN'
    }
  },
  {
    country: 'CAMBODIA',
    abbreviation: 'KH',
    labels: {
      fr: 'CAMBODGE'
    }
  },
  {
    country: 'KIRIBATI',
    abbreviation: 'KI',
    labels: {
      fr: 'KIRIBATI'
    }
  },
  {
    country: 'COMOROS',
    abbreviation: 'KM',
    labels: {
      fr: 'COMORES'
    }
  },
  {
    country: 'SAINT KITTS NEV',
    abbreviation: 'KN',
    labels: {
      fr: 'SAINT-KITTS-ET-NEVIS'
    }
  },
  {
    country: 'KOREA (NORTH)',
    abbreviation: 'KP',
    labels: {
      fr: 'CORÉE (NORD)'
    }
  },
  {
    country: 'KOREA (SOUTH)',
    abbreviation: 'KR',
    labels: {
      fr: 'CORÉE (SUD)'
    }
  },
  {
    country: 'KUWAIT',
    abbreviation: 'KW',
    labels: {
      fr: 'KUWAIT'
    }
  },
  {
    country: 'KAZAKSTAN',
    abbreviation: 'KZ',
    labels: {
      fr: 'KAZAKHSTAN'
    }
  },
  {
    country: 'LAO',
    abbreviation: 'LA',
    labels: {
      fr: 'LAO'
    }
  },
  {
    country: 'LEBANON',
    abbreviation: 'LB',
    labels: {
      fr: 'LIBAN'
    }
  },
  {
    country: 'SAINT LUCIA',
    abbreviation: 'LC',
    labels: {
      fr: 'SAINTE-LUCIE'
    }
  },
  {
    country: 'LIECHTENSTEIN',
    abbreviation: 'LI',
    labels: {
      fr: 'LIECHTENSTEIN'
    }
  },
  {
    country: 'SRI LANKA',
    abbreviation: 'LK',
    labels: {
      fr: 'SRI LANKA'
    }
  },
  {
    country: 'LIBERIA',
    abbreviation: 'LR',
    labels: {
      fr: 'LIBÉRIA'
    }
  },
  {
    country: 'LESOTHO',
    abbreviation: 'LS',
    labels: {
      fr: 'LESOTHO'
    }
  },
  {
    country: 'LITHUANIA',
    abbreviation: 'LT',
    labels: {
      fr: 'LITUANIE'
    }
  },
  {
    country: 'LUXEMBOURG',
    abbreviation: 'LU',
    labels: {
      fr: 'LUXEMBOURG'
    }
  },
  {
    country: 'LATVIA',
    abbreviation: 'LV',
    labels: {
      fr: 'LETTONIE'
    }
  },
  {
    country: 'LIBYAN ARAB JAM',
    abbreviation: 'LY',
    labels: {
      fr: 'JAM ARABE LIBYENNE'
    }
  },
  {
    country: 'MOROCCO',
    abbreviation: 'MA',
    labels: {
      fr: 'MAROC'
    }
  },
  {
    country: 'MONACO',
    abbreviation: 'MC',
    labels: {
      fr: 'MONACO'
    }
  },
  {
    country: 'MOLDOVA',
    abbreviation: 'MD',
    labels: {
      fr: 'MOLDOVA'
    }
  },
  {
    country: 'MADAGASCAR',
    abbreviation: 'MG',
    labels: {
      fr: 'MADAGASCAR'
    }
  },
  {
    country: 'MARSHALL ISL.',
    abbreviation: 'MH',
    labels: {
      fr: 'ÎLES MARSHALL'
    }
  },
  {
    country: 'MACEDONIA',
    abbreviation: 'MK',
    labels: {
      fr: 'MACÉDOINE'
    }
  },
  {
    country: 'MALI',
    abbreviation: 'ML',
    labels: {
      fr: 'MALI'
    }
  },
  {
    country: 'MYANMAR',
    abbreviation: 'MM',
    labels: {
      fr: 'MYANMAR'
    }
  },
  {
    country: 'MONGOLIA',
    abbreviation: 'MN',
    labels: {
      fr: 'MONGOLIE'
    }
  },
  {
    country: 'MACAO',
    abbreviation: 'MO',
    labels: {
      fr: 'MACAO'
    }
  },
  {
    country: 'N. MARIANA ISL',
    abbreviation: 'MP',
    labels: {
      fr: 'MARIANNES DU NORD'
    }
  },
  {
    country: 'MAURITANIA',
    abbreviation: 'MR',
    labels: {
      fr: 'MAURITANIE'
    }
  },
  {
    country: 'MALTA',
    abbreviation: 'MT',
    labels: {
      fr: 'MALTE'
    }
  },
  {
    country: 'MAURITIUS',
    abbreviation: 'MU',
    labels: {
      fr: 'MAURICE'
    }
  },
  {
    country: 'MALDIVES',
    abbreviation: 'MV',
    labels: {
      fr: 'MALDIVES'
    }
  },
  {
    country: 'MALAWI',
    abbreviation: 'MW',
    labels: {
      fr: 'MALAWI'
    }
  },
  {
    country: 'MEXICO',
    abbreviation: 'MX',
    labels: {
      fr: 'MÉXICO'
    }
  },
  {
    country: 'MALAYSIA',
    abbreviation: 'MY',
    labels: {
      fr: 'MALAISIE'
    }
  },
  {
    country: 'MOZAMBIQUE',
    abbreviation: 'MZ',
    labels: {
      fr: 'MOZAMBIQUE'
    }
  },
  {
    country: 'NAMIBIA',
    abbreviation: 'NA',
    labels: {
      fr: 'NAMIBIE'
    }
  },
  {
    country: 'NIGER',
    abbreviation: 'NE',
    labels: {
      fr: 'NIGER'
    }
  },
  {
    country: 'NIGERIA',
    abbreviation: 'NG',
    labels: {
      fr: 'NIGÉRIA'
    }
  },
  {
    country: 'NICARAQUA',
    abbreviation: 'NI',
    labels: {
      fr: 'NICARAGUA'
    }
  },
  {
    country: 'NETHERLANDS',
    abbreviation: 'NL',
    labels: {
      fr: 'PAYS-BAS'
    }
  },
  {
    country: 'NORWAY',
    abbreviation: 'NO',
    labels: {
      fr: 'NORVÈGE'
    }
  },
  {
    country: 'NEPAL',
    abbreviation: 'NP',
    labels: {
      fr: 'NÉPAL'
    }
  },
  {
    country: 'NAURU',
    abbreviation: 'NR',
    labels: {
      fr: 'NAURU'
    }
  },
  {
    country: 'NEW ZEALAND',
    abbreviation: 'NZ',
    labels: {
      fr: 'NOUVELLE-ZÉLANDE'
    }
  },
  {
    country: 'OMAN',
    abbreviation: 'OM',
    labels: {
      fr: 'OMAN'
    }
  },
  {
    country: 'PANAMA',
    abbreviation: 'PA',
    labels: {
      fr: 'PANAMA'
    }
  },
  {
    country: 'PERU',
    abbreviation: 'PE',
    labels: {
      fr: 'PÉROU'
    }
  },
  {
    country: 'PAPUA NEW G.',
    abbreviation: 'PG',
    labels: {
      fr: 'PAPOUASIE-NOUVELLE-GUINÉE'
    }
  },
  {
    country: 'PHILIPPINES',
    abbreviation: 'PH',
    labels: {
      fr: 'PHILIPPINES'
    }
  },
  {
    country: 'PAKISTAN',
    abbreviation: 'PK',
    labels: {
      fr: 'PAKISTAN'
    }
  },
  {
    country: 'POLAND',
    abbreviation: 'PL',
    labels: {
      fr: 'POLOGNE'
    }
  },
  {
    country: 'PALESTINIAN TER',
    abbreviation: 'PS',
    labels: {
      fr: 'TER PALESTINIENS'
    }
  },
  {
    country: 'PORTUGAL',
    abbreviation: 'PT',
    labels: {
      fr: 'PORTUGAL'
    }
  },
  {
    country: 'PALAU',
    abbreviation: 'PW',
    labels: {
      fr: 'PALAOS'
    }
  },
  {
    country: 'PARAGUAY',
    abbreviation: 'PY',
    labels: {
      fr: 'PARAGUAY'
    }
  },
  {
    country: 'QATAR',
    abbreviation: 'QA',
    labels: {
      fr: 'QATAR'
    }
  },
  {
    country: 'ROMANIA',
    abbreviation: 'RO',
    labels: {
      fr: 'ROUMANIE'
    }
  },
  {
    country: 'RUSSIAN FED.',
    abbreviation: 'RU',
    labels: {
      fr: 'FÉD. DE RUSSIE'
    }
  },
  {
    country: 'RWANDA',
    abbreviation: 'RW',
    labels: {
      fr: 'RWANDA'
    }
  },
  {
    country: 'SAUDI ARABIA',
    abbreviation: 'SA',
    labels: {
      fr: 'ARABIE SOUADITE'
    }
  },
  {
    country: 'SOLOMON ISL.',
    abbreviation: 'SB',
    labels: {
      fr: 'ÎLES SALOMON'
    }
  },
  {
    country: 'SEYCHELLES',
    abbreviation: 'SC',
    labels: {
      fr: 'SEYCHELLES'
    }
  },
  {
    country: 'SUDAN',
    abbreviation: 'SD',
    labels: {
      fr: 'SOUDAN'
    }
  },
  {
    country: 'SWEDEN',
    abbreviation: 'SE',
    labels: {
      fr: 'SUÈDE'
    }
  },
  {
    country: 'SINGAPORE',
    abbreviation: 'SG',
    labels: {
      fr: 'SINGAPOUR'
    }
  },
  {
    country: 'SLOVENIA',
    abbreviation: 'SI',
    labels: {
      fr: 'SLOVÉNIE'
    }
  },
  {
    country: 'SLOVAKIA',
    abbreviation: 'SK',
    labels: {
      fr: 'SLOVAQUIE'
    }
  },
  {
    country: 'SIERRA LEONE',
    abbreviation: 'SL',
    labels: {
      fr: 'SIERRA LEONE'
    }
  },
  {
    country: 'SAN MARINO',
    abbreviation: 'SM',
    labels: {
      fr: 'SAINT-MARIN'
    }
  },
  {
    country: 'SENEGAL',
    abbreviation: 'SN',
    labels: {
      fr: 'SÉNÉGAL'
    }
  },
  {
    country: 'SOMALIA',
    abbreviation: 'SO',
    labels: {
      fr: 'SOMALI'
    }
  },
  {
    country: 'STATELESS PERSO',
    abbreviation: 'SP',
    labels: {
      fr: 'APATRIDE'
    }
  },
  {
    country: 'SURINAME',
    abbreviation: 'SR',
    labels: {
      fr: 'SURINAME'
    }
  },
  {
    country: 'SAO TOME PRINCI',
    abbreviation: 'ST',
    labels: {
      fr: 'SAO TOMÉ-ET-PRINCIPE'
    }
  },
  {
    country: 'EL SALVADOR',
    abbreviation: 'SV',
    labels: {
      fr: 'EL SALVADOR'
    }
  },
  {
    country: 'SYRIAN ARAB REP',
    abbreviation: 'SY',
    labels: {
      fr: 'RÉPUBLIQUE ARABE SYRIENNE'
    }
  },
  {
    country: 'SWAZILAND',
    abbreviation: 'SZ',
    labels: {
      fr: 'SWAZILAND'
    }
  },
  {
    country: 'CHAD',
    abbreviation: 'TD',
    labels: {
      fr: 'TCHAD'
    }
  },
  {
    country: 'TOGO',
    abbreviation: 'TG',
    labels: {
      fr: 'TOGO'
    }
  },
  {
    country: 'THAILAND',
    abbreviation: 'TH',
    labels: {
      fr: 'THAÏLANDE'
    }
  },
  {
    country: 'TAJIKISTAN',
    abbreviation: 'TJ',
    labels: {
      fr: 'TADJIKISTAN'
    }
  },
  {
    country: 'TURKMENISTAN',
    abbreviation: 'TM',
    labels: {
      fr: 'TURKMÉNISTAN'
    }
  },
  {
    country: 'TUNISIA',
    abbreviation: 'TN',
    labels: {
      fr: 'TUNISIE'
    }
  },
  {
    country: 'TONGA',
    abbreviation: 'TO',
    labels: {
      fr: 'TONGA'
    }
  },
  {
    country: 'TURKEY',
    abbreviation: 'TR',
    labels: {
      fr: 'TÜRKIYE'
    }
  },
  {
    country: 'TRINIDAD TOBACO',
    abbreviation: 'TT',
    labels: {
      fr: 'TRINITÉ-ET-TOBAGO'
    }
  },
  {
    country: 'TUVALU',
    abbreviation: 'TV',
    labels: {
      fr: 'TUVALU'
    }
  },
  {
    country: 'TAIWAN, PR.OF C',
    abbreviation: 'TW',
    labels: {
      fr: 'TAÏWAN, RÉPUBLIQUE POPULAIRE DE CHINE'
    }
  },
  {
    country: 'TANZANIA',
    abbreviation: 'TZ',
    labels: {
      fr: 'TANZANIE'
    }
  },
  {
    country: 'UKRAINE',
    abbreviation: 'UA',
    labels: {
      fr: 'UKRAINE'
    }
  },
  {
    country: 'UGANDA',
    abbreviation: 'UG',
    labels: {
      fr: 'OUGANDA'
    }
  },
  {
    country: 'UNKNOWN',
    abbreviation: 'UN',
    labels: {
      fr: 'INCONNU'
    }
  },
  {
    country: 'UNITED STATES',
    abbreviation: 'US',
    labels: {
      fr: 'ÉTATS-UNIS'
    }
  },
  {
    country: 'URUGUAY',
    abbreviation: 'UY',
    labels: {
      fr: 'URUGUAY'
    }
  },
  {
    country: 'UZBEKIZTAN',
    abbreviation: 'UZ',
    labels: {
      fr: 'OUZBÉKISTAN'
    }
  },
  {
    country: 'HOLY SEE',
    abbreviation: 'VA',
    labels: {
      fr: 'SAINT-SIÈGE'
    }
  },
  {
    country: 'SAINT VINCENT G',
    abbreviation: 'VC',
    labels: {
      fr: 'SAINT-VINCENTAIS-ET- GRENADINE'
    }
  },
  {
    country: 'VENEZUELA',
    abbreviation: 'VE',
    labels: {
      fr: 'VÉNÉZUÉLA'
    }
  },
  {
    country: 'VIETNAM',
    abbreviation: 'VN',
    labels: {
      fr: 'VIETNAM'
    }
  },
  {
    country: 'VANUATU',
    abbreviation: 'VU',
    labels: {
      fr: 'VANUATU'
    }
  },
  {
    country: 'SAMOA',
    abbreviation: 'WS',
    labels: {
      fr: 'SAMOA'
    }
  },
  {
    country: 'YEMEN',
    abbreviation: 'YE',
    labels: {
      fr: 'YÉMEN'
    }
  },
  {
    country: 'SOUTH AFRICA',
    abbreviation: 'ZA',
    labels: {
      fr: 'AFRIQUE DU SUD'
    }
  },
  {
    country: 'ZAMBIA',
    abbreviation: 'ZM',
    labels: {
      fr: 'ZAMBIE'
    }
  },
  {
    country: 'ZIMBABWE',
    abbreviation: 'ZW',
    labels: {
      fr: 'ZIMBABWE'
    }
  }
]
