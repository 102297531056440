import moment from 'moment'
import dateFormats from '../../configuration/dateFormats'
export function renderRouteLabel(route, labels) {
  let label = route.description

  if (labels[`${route.code}Caption`]) {
    label = labels[`${route.code}Caption`]
  } else if (labels[route.code]) {
    label = labels[route.code]
  }
  return label
}

export function handleCustomDateSelectionErrors({ date, labels }) {
  if (
    date &&
    process.env.REACT_APP_CUSTOM_DATE_ERROR_MIN_DATE &&
    date.isBefore(
      moment(
        process.env.REACT_APP_CUSTOM_DATE_ERROR_MIN_DATE,
        dateFormats.default
      )
    )
  ) {
    return labels.customMinDateError
  }
  return null
}
