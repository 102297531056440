import { object } from 'yup'
import moment from 'moment'
import {
  defaultToVehicleOptionsList,
  ENABLE_ACCOMMODATIONS,
  ENABLE_COPASSENGERS
} from '../../configuration/constants'
import languages from '../../configuration/languages'
import { cleanPhoneNumber } from '../../configuration/utilities'
import { PHONE_FIELDS } from '../../redux/configuration/constants'
import { tripDetailKeys } from './constants'

export const generateTripDetails = ({
  departures = [],
  monerisData = {},
  amountOwing = '',
  customerNumber = '',
  bookingNumber = '',
  orderId = '',
  lang = '',
  address = {},
  reservationTotals = {},
  labels = {},
  mapToEcommerceEvent = false,
  bookingSource = 'Online'
}) => {
  const result = {
    ps_store_id: '',
    hpp_key: '',
    charge_total: '',
    cust_id: '',
    order_id: '',
    lang: 'en-ca',
    gst: '0.00',
    note: '',
    email: '',
    bill_name: '',
    bill_address_one: '',
    bill_city: '',
    bill_state_or_province: '',
    bill_postal_code: '',
    bill_country: '',
    booking_source: ''
  }

  if (monerisData?.psStoreId) result.ps_store_id = monerisData.psStoreId
  if (monerisData?.hppKey) result.hpp_key = monerisData.hppKey
  if (amountOwing) result.charge_total = amountOwing
  if (customerNumber || bookingNumber) {
    result.cust_id = customerNumber || bookingNumber
  }
  if (orderId) result.order_id = orderId
  if (languages?.[lang]?.monerisValue) {
    result.lang = languages[lang].monerisValue
  }
  if (reservationTotals?.tax1) result.gst = reservationTotals.tax1
  if (labels?.booking) result.note = labels.booking
  if (address?.email) result.email = address.email
  if (address?.name) result.bill_name = address.name
  if (address?.address) result.bill_address_one = address.address
  if (address?.city) result.bill_city = address.city
  if (address?.county) result.bill_state_or_province = address.county
  if (address?.postCode) result.bill_postal_code = address.postCode
  if (address?.countryCode) result.bill_country = address.countryCode
  if (bookingSource) result.booking_source = bookingSource

  // TAXES
  if (
    reservationTotals?.taxesAndLevies &&
    Object.keys(reservationTotals.taxesAndLevies).length
  ) {
    result.taxes = {}

    Object.entries(reservationTotals.taxesAndLevies).forEach(
      ([key, amount]) => {
        if (key) {
          result.taxes[key] = {
            [tripDetailKeys.ID]: key,
            [mapToEcommerceEvent
              ? tripDetailKeys.NAME
              : tripDetailKeys.DESCRIPTION]: labels[key] ? labels[key] : key,
            [tripDetailKeys.QUANTITY]: mapToEcommerceEvent ? 1 : '1',
            [tripDetailKeys.PRICE]: amount,
            [tripDetailKeys.SUBTOTAL]: amount
          }
        }
      }
    )
  }

  if (departures.length > 0) {
    result.products = []

    departures.forEach(trip => {
      const tripDescription = `${trip.routeCode} ${trip.departureDate} ${trip.departureTime}`

      if (tripDescription) {
        // PASSENGERS
        if (trip.passengerPriceLines && trip.passengerPriceLines.length) {
          trip.passengerPriceLines.forEach((passengerItem, passengerIndex) => {
            result.products.push({
              [tripDetailKeys.ID]: passengerItem.resourceCode,
              [mapToEcommerceEvent
                ? tripDetailKeys.NAME
                : tripDetailKeys.DESCRIPTION]: `${tripDescription} ${labels.passenger}`,
              [tripDetailKeys.QUANTITY]: mapToEcommerceEvent
                ? parseInt(passengerItem.amount)
                : passengerItem.amount,
              [tripDetailKeys.PRICE]: passengerItem.price,
              [tripDetailKeys.SUBTOTAL]: passengerItem.price
            })
          })
        }

        // VEHICLES
        if (trip.vehiclePriceLines && trip.vehiclePriceLines.length) {
          trip.vehiclePriceLines.forEach((item, vehicleIndex) => {
            result.products.push({
              [tripDetailKeys.ID]: item.resourceCode,
              [mapToEcommerceEvent
                ? tripDetailKeys.NAME
                : tripDetailKeys.DESCRIPTION]: `${tripDescription} ${
                labels[item.resourceCode]
              }`,
              [tripDetailKeys.QUANTITY]: mapToEcommerceEvent
                ? parseInt(item.amount)
                : item.amount,
              [tripDetailKeys.PRICE]: item.price,
              [tripDetailKeys.SUBTOTAL]: item.price
            })
          })
        }

        // ACCOMMODATIONS
        if (
          ENABLE_ACCOMMODATIONS &&
          trip.accommodationPriceLines &&
          trip.accommodationPriceLines.length
        ) {
          trip.accommodationPriceLines.forEach((item, accommodationIndex) => {
            result.products.push({
              [tripDetailKeys.ID]: item.resourceCode,
              [mapToEcommerceEvent
                ? tripDetailKeys.NAME
                : tripDetailKeys.DESCRIPTION]: `${tripDescription} ${
                labels[item.resourceCode]
              }`,
              [tripDetailKeys.QUANTITY]: mapToEcommerceEvent
                ? parseInt(item.amount)
                : item.amount,
              [tripDetailKeys.PRICE]: item.price,
              [tripDetailKeys.SUBTOTAL]: item.price
            })
          })
        }
      }
    })
  }
  return result
}

export const mapFormValues = ({
  initialValues = {},
  populateWith = {},
  valueMapping = {},
  valueFormatting = {}
}) => {
  /*
    Ensure we send back all the initial form field keys,
    even if they aren't populated
  */
  let result = { ...initialValues }

  Object.keys(initialValues).forEach(fieldName => {
    /*
      Default populateWith value assignment to initial form values
    */
    if (Object.keys(populateWith).includes(fieldName)) {
      result[fieldName] = populateWith[fieldName]
    }

    /*
      Mapping:
      If an alternate field name is defined in valueMapping
      When its tied to the fieldName,
      And the populateWith object also contains that alternate field name,
      And we have an initial value for this field name in the result,
      Then map fieldName to the alternate value.
    */
    let mapToKey = null
    if (valueMapping?.[fieldName] && valueMapping[fieldName] !== fieldName) {
      mapToKey = valueMapping[fieldName]
    }

    if (
      mapToKey &&
      Object.keys(populateWith).includes(mapToKey) &&
      Object.keys(result).includes(fieldName)
    ) {
      result[fieldName] = populateWith[mapToKey]
    }

    /*
      Formatting:
      Optional formatting for the resulting value
    */
    let formattingMethod = null
    if (
      valueFormatting?.[fieldName] &&
      typeof valueFormatting[fieldName] === 'function'
    ) {
      formattingMethod = valueFormatting[fieldName]
    }

    if (formattingMethod && Object.keys(result).includes(fieldName)) {
      result[fieldName] = formattingMethod(result[fieldName])
    }
  })

  return result
}

export const getInitialValuesFromBookingPassengers = (
  passengers = false,
  initialValuesPassenger = {},
  activeModifyBooking = null,
  coPassengers = null,
  userPassengerType = null
) => {
  const result = {}
  const oldPassengersById = getOldPassengersById(activeModifyBooking)

  if (!passengers) {
    return {}
  }

  const unmappedCoPassengers = {
    departures: [...(coPassengers || [])],
    returns: [...(coPassengers || [])]
  }
  const skippedCoPassengerForUserPassengerType = {
    departures: false,
    returns: false
  }

  const tryMapCoPassenger = (direction, passengerType) => {
    if (
      passengerType === userPassengerType &&
      !skippedCoPassengerForUserPassengerType[direction]
    ) {
      skippedCoPassengerForUserPassengerType[direction] = true
      return {}
    }
    const remaining = unmappedCoPassengers[direction]
    const matchIndex = remaining.findIndex(
      x => x.passengerType === passengerType
    )
    if (matchIndex > -1) {
      const [match] = remaining.splice(matchIndex, 1) // Removes element from remaining
      const [dateOfBirth] = match.dateOfBirth.split('T')
      return {
        ...match,
        dateOfBirth
      }
    }
    return {}
  }

  Object.keys(passengers).forEach(direction => {
    let passengerId
    if (passengers[direction]) {
      passengers[direction].forEach(directionInfo => {
        if (directionInfo?.passengers) {
          const amountsPerType = {}
          directionInfo.passengers.forEach(passengerInfo => {
            if (passengerInfo?.passengerType) {
              const passengerType = passengerInfo.passengerType

              if (!amountsPerType[passengerType]) {
                amountsPerType[passengerType] = 1
              } else {
                amountsPerType[passengerType]++
              }

              passengerId = `${direction}-${passengerType}-${amountsPerType[passengerType]}`

              let prefillPassengerData = {}
              if (activeModifyBooking) {
                prefillPassengerData = mapModifyPassengerToInitialValues(
                  oldPassengersById[passengerId]
                )
              } else if (ENABLE_COPASSENGERS) {
                prefillPassengerData = tryMapCoPassenger(
                  direction,
                  passengerType
                )
              }

              result[passengerId] = {
                ...initialValuesPassenger,
                type: direction,
                passengerType,
                ...prefillPassengerData
              }
            }
          })
        }
      })
    }
  })

  return result
}

/**
 * TODO:
 * passengers need `countryCode` field because of issue CTM200-554
 * https://jira.verbinteractive.com/browse/CTM200-554
 *
 * Use citizenship field as `countryCode` for CAT route:
 * https://jira.verbinteractive.com/browse/BAY202-137
 */
export const addCountryCodeForPassengersFromBookingInfo = ({
  passengerDetails,
  bookingInformation
}) => {
  const countryCode = bookingInformation.countryCode
  Object.entries(passengerDetails).forEach(([_, passenger]) => {
    // first, trying to get citizenship
    if (passenger.citizenship) {
      return (passenger.countryCode = passenger.citizenship)
    }
    // then, fallback to contact info
    passenger.countryCode = countryCode
  })
}

function mapModifyPassengerToInitialValues(oldPassenger) {
  if (!oldPassenger) {
    return {}
  }

  return {
    ...oldPassenger,
    accessibility: !!oldPassenger.note,
    accessibilityTextField: oldPassenger.note || '',
    citizenship: oldPassenger.countryCode || '',
    passportConfirm: oldPassenger.passport || '',
    passportIssueCountry: oldPassenger.placeOfBirth || '',
    passportExpiryDate: oldPassenger.passportExpiryDate
      ? moment(oldPassenger.passportExpiryDate).format('YYYY-MM-DD')
      : '',
    title: oldPassenger.title || ''
  }
}

function getOldPassengersById(activeModifyBooking) {
  const oldPassengersById = {}
  if (activeModifyBooking) {
    const { departureRoute, returnRoute } = activeModifyBooking
    const oldPassengerCounts = {
      departures: {},
      returns: {}
    }

    if (departureRoute) {
      const oldDepartPassengers = departureRoute.passengers.booked

      oldDepartPassengers.forEach(passenger => {
        const { passengerType } = passenger

        if (!oldPassengerCounts.departures[passengerType]) {
          oldPassengerCounts.departures[passengerType] = 1
        } else {
          oldPassengerCounts.departures[passengerType]++
        }
        const oldId = `departures-${passengerType}-${oldPassengerCounts.departures[passengerType]}`
        oldPassengersById[oldId] = passenger
      })
    }

    if (returnRoute) {
      const oldReturnPassengers = returnRoute.passengers.booked

      oldReturnPassengers.forEach(passenger => {
        const { passengerType } = passenger

        if (!oldPassengerCounts.returns[passengerType]) {
          oldPassengerCounts.returns[passengerType] = 1
        } else {
          oldPassengerCounts.returns[passengerType]++
        }
        const oldId = `returns-${passengerType}-${oldPassengerCounts.returns[passengerType]}`
        oldPassengersById[oldId] = passenger
      })
    }
  }
  return oldPassengersById
}

export const getPassengerSchemaFromInitialValues = (
  initialValues = false,
  passengerDetailsFormSchema = false
) => {
  let result = object().shape({})

  if (initialValues && passengerDetailsFormSchema) {
    Object.keys(initialValues).forEach(passengerId => {
      const passengerIdSchema = object().shape({
        [passengerId]: passengerDetailsFormSchema
      })
      if (passengerIdSchema) {
        result = result.concat(passengerIdSchema)
      }
    })
  }

  return result
}

const prepareTermsData = ({ formData, result }) => {
  let preparedResult = { ...result }

  preparedResult.consent = formData?.consent ? 'true' : 'false'
  return preparedResult
}

export const getAcceptsContactValue = ({ formData, customerAccount }) => {
  let acceptsContact =
    customerAccount?.acceptsContact &&
    customerAccount.acceptsContact !== 'false'
      ? 'Y'
      : 'N'

  if (
    !customerAccount &&
    formData?.accountInformation?.acceptsContact &&
    formData.accountInformation.acceptsContact !== 'false'
  ) {
    acceptsContact = 'Y'
  }

  if (
    formData?.bookingInformation?.acceptsContact &&
    formData.bookingInformation.acceptsContact !== 'false'
  ) {
    acceptsContact = 'Y'
  }

  return acceptsContact
}

export const preparePassengerDetails = ({
  formData,
  result,
  duplicatePassengerQuantities
}) => {
  let preparedResult = { ...result }

  if (formData?.passengerDetails) {
    preparedResult.departures = [{ passengers: [] }]
    preparedResult.returns = [{ passengers: [] }]
    const passengerIds = Object.keys(formData.passengerDetails)

    if (passengerIds) {
      passengerIds.forEach(passengerId => {
        if (formData.passengerDetails[passengerId]) {
          const {
            accessibility,
            type,
            accessibilityTextField,
            passportIssueCountry,
            ...remainingPassengerInfo
          } = formData.passengerDetails[passengerId]
          const newPassenger = { ...remainingPassengerInfo }

          newPassenger.note = accessibility ? accessibilityTextField : ' '

          if (passportIssueCountry)
            newPassenger.placeOfBirth = passportIssueCountry

          if (type === 'departures') {
            preparedResult.departures[0].passengers.push(newPassenger)
          }
          if (type === 'returns') {
            preparedResult.returns[0].passengers.push(newPassenger)
          }
        }
      })
    }
  }

  if (duplicatePassengerQuantities) {
    preparedResult.returns = [...preparedResult.departures]
  }

  return preparedResult
}

/*
  Note: Merges duplicate fields from booking and account information
  to create billing information,
  "accountSameAsBooking" determines which fields are used
*/
const getBillingFromAccountAndBooking = ({ formData }) => {
  let result = false

  if (formData?.bookingInformation) {
    result = { ...formData.bookingInformation }
  }

  /*
    Note:
    defaultToVehicleOptionsList renders a VehicleOptionsList
    for the vehicle selection step (no vehicle width/height filtering).

    Inline account creation is disabled
    when rendering a VehicleOptionsList (nfl-bay layout)
  */
  if (formData?.accountInformation && !defaultToVehicleOptionsList) {
    const {
      accountSameAsBooking,
      ...remainingAccountInfo
    } = formData.accountInformation

    if (accountSameAsBooking) {
      result = {
        ...remainingAccountInfo,
        ...result
      }
    } else {
      result = {
        ...result,
        ...remainingAccountInfo
      }
    }
  }

  return result
}

const mapAccountAndBookingToBilling = ({
  // booking fields below
  firstName,
  lastName,
  address,
  city,
  countryCode,
  county,
  postalCode,
  emailAddress,
  title = false,
  // account fields below
  language = false,
  passengerType = false,
  gender = false,
  ...restBookingFields
}) => {
  const result = {
    name: firstName + ' ' + lastName,
    address,
    city,
    countryCode,
    county,
    postCode: postalCode,
    email: emailAddress
  }

  PHONE_FIELDS.client.forEach(phoneField => {
    result[phoneField] = cleanPhoneNumber(restBookingFields[phoneField])
  })

  if (language) result.language = language
  if (passengerType) result.passengerType = passengerType
  result.gender = gender && gender !== 'X' ? gender : 'M' // TODO: Remove as we can't send the X/neutral term as of yet
  if (title) result.title = title

  return result
}

export const prepareFormBookingData = (
  formData = false,
  customerAccount = false,
  duplicatePassengerQuantities = false
) => {
  let formDataCopy = false
  let licenseNumber = false

  /*
    Note: removing licensePlateNumber key from formData
    and store it as licenseNumber
  */
  if (formData) {
    const {
      bookingInformation: { licensePlateNumber, ...restBookingInformation },
      ...restFormData
    } = formData
    formDataCopy = {
      bookingInformation: { ...restBookingInformation },
      ...restFormData
    }
    if (licensePlateNumber) licenseNumber = licensePlateNumber
  }

  const acceptsContact = getAcceptsContactValue({
    formData: formDataCopy,
    customerAccount
  })
  let result = {}
  let registerAccountData

  /*
    getBillingFromAccountAndBooking uses "accountSameAsBooking"
    to determine the order in which to merge account and booking information.
  */
  const accountAndBookingFormData = getBillingFromAccountAndBooking({
    formData: formDataCopy
  })
  const billingInfo = mapAccountAndBookingToBilling(accountAndBookingFormData)

  /*
    Prepare register account data
  */
  if (
    formDataCopy?.accountInformation &&
    accountAndBookingFormData &&
    !customerAccount &&
    !defaultToVehicleOptionsList
  ) {
    const {
      firstName,
      lastName,
      address,
      city,
      countryCode,
      county,
      postalCode,
      emailAddress,
      language,
      passengerType,
      gender,
      password,
      title,
      ...restAccountAndBookingFormData
    } = accountAndBookingFormData

    registerAccountData = {
      firstName,
      lastName,
      address,
      city,
      countryCode,
      county,
      postalCode,
      emailAddress,
      language,
      passengerType,
      gender,
      password,
      title
    }

    PHONE_FIELDS.client.forEach(phoneField => {
      registerAccountData[phoneField] = cleanPhoneNumber(
        restAccountAndBookingFormData[phoneField]
      )
    })

    // note: defaultProductCode is also required to register an account
    if (registerAccountData && formDataCopy?.defaultProductCode) {
      registerAccountData.defaultProductCode = formDataCopy.defaultProductCode
    }
  }

  if (registerAccountData) result.registerAccountData = registerAccountData

  result.acceptsContact = acceptsContact
  result.billingInformation = billingInfo
  result = prepareTermsData({ formData: formDataCopy, result })
  result = preparePassengerDetails({
    formData: formDataCopy,
    result,
    duplicatePassengerQuantities
  })

  if (licenseNumber) result = { licenseNumber, ...result }

  return result
}
