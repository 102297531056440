import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { func, shape } from 'prop-types'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
// ************ CONSTANTS *************
import {
  appRoutes,
  ENABLE_MONERIS_CHECKOUT,
  ENABLE_CHASE_CHECKOUT,
  ENABLE_PAYPAL
} from '../../configuration/constants'
// *********** COMPONENTS ***********
import MonerisCheckoutContainer from '../../components/MonerisCheckout/MonerisCheckoutContainer'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import MonerisRedirectForm from '../../components/BookingInformation/MonerisRedirectForm'
import MonerisPayPageLinks from '../../components/MonerisPayPageLinks/MonerisPayPageLinks'
import PayPalContainer from '../../components/PayPal/PayPalContainer'

// ********* SELECTORS **************
import {
  selectLanguage,
  selectSessionId,
  selectCurrency
} from '../../redux/session/selectors'
import { selectLabels } from '../../redux/configuration/selectors'
import { selectBookingConfirmationData } from '../../redux/booking/selectors'
import { selectPaymentFerryId } from '../../redux/paymentConfirmation/selectors'
import { selectActiveModifyBooking } from '../../redux/modifyBooking/selectors'
// ********** GLOBAL SELECTORS ******
import { selectPaypalCreateOrderParam } from '../../redux/selectors/selectPaypalCreateOrderParam'

// *********** UTILITIES ************
import { generateTripDetails } from '../../components/BookingInformation/utilities'
// *********** STYLES ***************
import '../../styles/scss/components/payment/payment.scss'
import visaLogo from '../../assets/icons/creditCards/visa.svg'
import mcLogo from '../../assets/icons/creditCards/mastercard.svg'
import payPalIcon from '../../assets/icons/creditCards/payPal.svg'
import ChaseCheckoutContainer from '../../components/ChaseCheckoutContainer/ChaseCheckoutContainer'

/*
* TESTING NOTE:
*  When testing moneris checkout they have some strange test cases setup. I lost some time figuring that one out 🤦‍♂️
* MONERIS
* The cent value of the transaction is what determines the response code in the testing environment.
* For example, a transaction made for an amount of $5.00 OR $1.00 will be approved.
* The response code returned for an amount other than those listed here is not predictable.
   VISA Cards
    Transactions values between $11.00 to $ 50.99, $60.01 to $119.99 and $130.01 to $150.00 will always be approved.
    Transactions values between $51.00 to $60.00 will always be declined with ISO/RESP code 41/478
    Transactions values between $120.00 to $130.00 will always be declined with ISO/RESP Code 54/482
* */

/*
 * See PayPalButton.js for PayPal specific notes
 * */

const PaymentContainer = ({ handleRouteChange, openModal, modalIsOpen }) => {
  const [paymentTypeSelection, setPaymentTypeSelection] = useState('creditCard')
  const [redirectToMonerisPage, setRedirectToMonerisPage] = useState(false)
  const [paymentError, setPaymentError] = useState(null)
  const [loading, setLoading] = useState(false)
  const bookingConfirmationData = useSelector(selectBookingConfirmationData)
  const ferryId = useSelector(selectPaymentFerryId)
  const labels = useSelector(selectLabels)
  const lang = useSelector(selectLanguage)
  const sessionId = useSelector(selectSessionId)
  const currencyType = useSelector(selectCurrency)
  const paypalCreateParam = useSelector(selectPaypalCreateOrderParam)

  const activeModifyBooking = useSelector(selectActiveModifyBooking)

  const history = useHistory()
  const location = useLocation()

  const cancelTransactionRouteTarget =
    location?.state?.cancelTransactionRouteTarget || appRoutes.summary.pathname

  const hasValidBookingNumber =
    bookingConfirmationData?.bookingNumber &&
    bookingConfirmationData.bookingNumber !== '-1'

  const paymentContainerRef = useRef(null)
  const [monerisCheckoutWidth, setMonerisCheckoutWidth] = useState(null)
  const currencyRateReminderCAT =
    currencyType === 'USD' ? labels.currencyRateReminderCAT : null

  /*
   * From the checkout docs
   * https://developer.moneris.com/sitecore/media%20library/Hidden/MCO/Client-Side%20Checkout%20Page
   * (Optional): If you are not using the "Full screen" window sizing option, you will need to define the size of your window by creating another <div> around this one, for example:
   *
   * Sorry, we need to dynamically update the width provided to the container around moneris checkout's iFrame.
   * The resize listener is in MonerisCheckoutContainer so that it is only run when needed.
   * */

  useLayoutEffect(() => {
    const containerWidth = paymentContainerRef?.current?.getBoundingClientRect()
      ?.width

    if (containerWidth && !monerisCheckoutWidth) {
      setMonerisCheckoutWidth(containerWidth >= 672 ? 672 : containerWidth - 2)
    }
  }, [monerisCheckoutWidth])

  useEffect(() => {
    if (!hasValidBookingNumber) {
      history.goBack()
    } else {
      // we want to intercept the back button here and send the user back to the summary with the incomplete
      // booking warning. Same as if they hit cancel in either moneris setup or the button below the paypal buttons

      const backListener = history.listen((location, action) => {
        if (action === 'POP') {
          openModal({ type: 'cancel-transaction' })
          handleRouteChange(cancelTransactionRouteTarget)
        }
      })

      return () => backListener()
    }
  }, [
    history,
    hasValidBookingNumber,
    handleRouteChange,
    openModal,
    cancelTransactionRouteTarget
  ])

  const tripDetails = generateTripDetails({
    ...bookingConfirmationData,
    lang,
    labels
  })
  const customerId = tripDetails?.cust_id
  const customerEmail = tripDetails?.email

  const paymentOptions = {
    creditCard: {
      value: 'creditCard',
      enabled: true
    },
    payPal: {
      value: 'payPal',
      enabled: ENABLE_PAYPAL
    }
  }

  const numberOFEnabledPaymentOptions = Object.values(paymentOptions).reduce(
    (num, option) => {
      return option.enabled ? num + 1 : num
    },
    0
  )

  function onCancelTransaction() {
    openModal({ type: 'cancel-transaction' })
    handleRouteChange(cancelTransactionRouteTarget)
  }

  function renderBookingReferenceInfo() {
    return (
      <div className="payment__customer-details-container">
        <div className="payment__customer-details-title">
          {labels.customerNumber}
        </div>
        <div className="payment__customer-details-value">{customerId}</div>
      </div>
    )
  }

  function renderPaymentOptions() {
    if (ENABLE_CHASE_CHECKOUT) {
      return (
        <ChaseCheckoutContainer
          bookingConfirmationData={bookingConfirmationData}
          ferryId={ferryId}
          labels={labels}
          lang={lang}
          sessionId={sessionId}
          handleRouteChange={handleRouteChange}
          openModal={openModal}
          modalIsOpen={modalIsOpen}
          cancelTransactionRouteTarget={cancelTransactionRouteTarget}
        />
      )
    } else if (
      ENABLE_MONERIS_CHECKOUT &&
      paymentTypeSelection === 'creditCard'
    ) {
      return (
        <MonerisCheckoutContainer
          bookingConfirmationData={bookingConfirmationData}
          ferryId={ferryId}
          labels={labels}
          lang={lang}
          sessionId={sessionId}
          handleRouteChange={handleRouteChange}
          openModal={openModal}
          modalIsOpen={modalIsOpen}
          cancelTransactionRouteTarget={cancelTransactionRouteTarget}
          monerisCheckoutWidth={monerisCheckoutWidth}
          paymentContainerRef={paymentContainerRef}
        />
      )
    } else if (paymentTypeSelection === 'payPal') {
      return (
        <PayPalContainer
          setPaymentError={setPaymentError}
          loading={loading}
          setLoading={setLoading}
          paypalCreateParam={paypalCreateParam}
          bookingConfirmationData={bookingConfirmationData}
          ferryId={ferryId}
          handleRouteChange={handleRouteChange}
          labels={labels}
          lang={lang}
          onCancelTransaction={onCancelTransaction}
        />
      )
    }

    // this is set by the button in the next return section
    if (redirectToMonerisPage) {
      return (
        <>
          <LoadingSpinner />
          <MonerisRedirectForm
            labels={labels}
            lang={lang}
            bookingData={bookingConfirmationData}
            currencyType={currencyType}
          />
        </>
      )
    }

    return (
      <MonerisPayPageLinks
        labels={labels}
        activeModifyBooking={activeModifyBooking}
        onCancelClick={onCancelTransaction}
        onConfirmClick={() => setRedirectToMonerisPage(true)}
      />
    )
  }

  return !hasValidBookingNumber ? null : (
    <div
      className={`payment u-page-view-container ${process.env.REACT_APP_FLOW_CLIENT_ID}`}
    >
      <h2 className="theme-font-header payment__header">{labels.payment}</h2>

      <div className="u-container">
        <div className="payment__content">
          <div className="payment__content-inner" ref={paymentContainerRef}>
            <h2 className="theme-font-header">{labels.customerDetails}</h2>
            <div className="payment__customer-details">
              {renderBookingReferenceInfo()}
              <div className="payment__customer-details-container">
                <div className="payment__customer-details-title">
                  {labels.emailAddress}
                </div>
                <div className="payment__customer-details-value">
                  {customerEmail}
                </div>
              </div>
            </div>

            {paymentError ? (
              <p className="u-page-view-container u-padding-top u-text-center u-error-color">
                {labels.genericApiErrorMessage}
              </p>
            ) : (
              <>
                <h2 className="theme-font-header">
                  {labels.paymentInformation}
                </h2>
                {currencyRateReminderCAT && (
                  <p className="u-text-center">{currencyRateReminderCAT}</p>
                )}

                <div className="payment-options">
                  {numberOFEnabledPaymentOptions > 1 && (
                    <div className="payment-form-section border-bottom">
                      <p className="payment-options-title">
                        {labels.paymentMethod}
                      </p>

                      <div className="credit-cards">
                        <button
                          className={`btn btn-radio ${
                            paymentTypeSelection === 'creditCard'
                              ? 'active'
                              : ''
                          }`}
                          onClick={() =>
                            setPaymentTypeSelection(
                              paymentOptions.creditCard.value
                            )
                          }
                        >
                          {labels.creditCard}
                        </button>
                        <div className="credit-card-logos">
                          <img src={visaLogo} alt="visa" role="none" />
                          <img src={mcLogo} alt="Mastercard" role="none" />
                        </div>
                      </div>
                      {ENABLE_PAYPAL && paymentOptions.payPal.enabled && (
                        <button
                          className={`btn btn-radio paypal-btn ${
                            paymentTypeSelection === 'payPal' ? 'active' : ''
                          }`}
                          onClick={() =>
                            setPaymentTypeSelection(paymentOptions.payPal.value)
                          }
                          aria-label="PayPal"
                        >
                          <div className="logo-container">
                            <img src={payPalIcon} alt="PayPal" role="none" />
                          </div>
                        </button>
                      )}
                    </div>
                  )}

                  {renderPaymentOptions()}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

PaymentContainer.propTypes = {
  handleRouteChange: func.isRequired,
  openModal: func.isRequired,
  modalIsOpen: shape().isRequired
}

export default PaymentContainer
