import { handleActions } from 'redux-actions'
import {
  START_BOOKING_REQUEST,
  SET_BOOKING_ERROR,
  SET_BOOKING_SUCCESS,
  RESET_SET_BOOKING,
  CLEAR_BOOKING_DATA,
  CLEAR_BOOKING_NUMBER,
  ATTEMPT_PRELOAD_TRANSACTION,
  PRELOAD_TRANSACTION_ERROR,
  PRELOAD_TRANSACTION_SUCCESS,
  ATTEMPT_GET_TRANSACTION_RECEIPT,
  GET_TRANSACTION_RECEIPT_ERROR,
  GET_TRANSACTION_RECEIPT_SUCCESS,
  CLEAR_TICKET_NUMBERS,
  CLEAR_RECEIPTS
} from './constants'

const bookingDataInitialState = {
  attemptingSetBooking: false,
  setBookingSuccess: false,
  setBookingError: false,
  preloadingTransaction: false,
  preloadTransactionSuccess: false,
  preloadTransactionError: false,
  preloadedTicketNumbers: {},
  attemptingGetReceipt: false,
  getReceiptSuccess: false,
  getReceiptError: '',
  receipts: {}
}

const bookingDataReducer = handleActions(
  {
    [START_BOOKING_REQUEST]: state => ({
      ...state,
      attemptingSetBooking: true,
      setBookingSuccess: false,
      setBookingError: false
    }),
    [SET_BOOKING_ERROR]: (state, { payload }) => ({
      ...state,
      attemptingSetBooking: false,
      setBookingSuccess: false,
      setBookingError: payload || 'genericApiErrorMessage'
    }),
    [SET_BOOKING_SUCCESS]: (state, action) => {
      const copiedState = {
        ...state,
        attemptingSetBooking: false,
        setBookingSuccess: true,
        setBookingError: false
      }
      let newData = { ...action.payload }

      if (copiedState.data) {
        newData = {
          ...state.data,
          ...action.payload
        }
      }

      return {
        ...copiedState,
        data: newData
      }
    },
    [RESET_SET_BOOKING]: state => ({
      ...state,
      attemptingSetBooking: false,
      setBookingSuccess: false,
      setBookingError: false
    }),
    [CLEAR_BOOKING_DATA]: () => ({
      ...bookingDataInitialState
    }),
    [CLEAR_BOOKING_NUMBER]: state => {
      const copy = { ...state }
      if (copy.data) {
        delete copy.data.bookingNumber
      }
      return copy
    },
    [ATTEMPT_PRELOAD_TRANSACTION]: state => ({
      ...state,
      preloadingTransaction: true,
      preloadTransactionSuccess: false,
      preloadTransactionError: false,
      preloadedTicketNumbers: {}
    }),
    [PRELOAD_TRANSACTION_ERROR]: state => ({
      ...state,
      preloadingTransaction: false,
      preloadTransactionError: true
    }),
    [PRELOAD_TRANSACTION_SUCCESS]: (state, { payload }) => {
      let newState = { ...state }
      /*
        Wipe out old ticket numbers,
        Key ticket by ID to ensure we always select the proper ticket
      */
      if (payload?.id || payload?.uid) {
        newState = {
          ...newState,
          preloadingTransaction: false,
          preloadTransactionSuccess: true,
          preloadedTicketNumbers: {
            uid: payload.uid,
            ticket: payload.ticket,
            orderNo: payload.orderNumber
          }
        }
      } else {
        // Treat as an error if we dont have an ID and a ticket
        newState = {
          ...newState,
          preloadingTransaction: false,
          preloadTransactionError: true
        }
      }

      return newState
    },
    [CLEAR_TICKET_NUMBERS]: state => ({
      ...state,
      preloadingTransaction: false,
      preloadTransactionSuccess: false,
      preloadTransactionError: false,
      preloadedTicketNumbers: {}
    }),
    [CLEAR_RECEIPTS]: state => ({
      ...state,
      attemptingGetReceipt: false,
      getReceiptSuccess: false,
      getReceiptError: '',
      receipts: {}
    }),
    [ATTEMPT_GET_TRANSACTION_RECEIPT]: state => ({
      ...state,
      attemptingGetReceipt: true,
      getReceiptSuccess: false,
      getReceiptError: '',
      receipts: {}
    }),
    [GET_TRANSACTION_RECEIPT_ERROR]: (state, { payload }) => ({
      ...state,
      attemptingGetReceipt: false,
      getReceiptSuccess: false,
      getReceiptError: payload || 'paymentConfirmationError',
      receipts: {}
    }),
    [GET_TRANSACTION_RECEIPT_SUCCESS]: (state, { payload }) => {
      let newState = { ...state }
      /*
        Wipe out old ticket receipts,
        Key ticket by ID to ensure we always select the proper receipt
      */
      if (payload?.id && payload?.receipt) {
        newState = {
          ...newState,
          attemptingGetReceipt: false,
          getReceiptSuccess: true,
          receipts: {
            [payload.id]: payload.receipt
          }
        }
      } else {
        // Treat as an error if we dont have an ID and a receipt
        newState = {
          ...newState,
          attemptingGetReceipt: false,
          getReceiptError: 'paymentConfirmationError'
        }
      }

      return newState
    }
  },
  { ...bookingDataInitialState }
)

export { bookingDataInitialState }
export default bookingDataReducer
