import React from 'react'
import { bool, string, oneOfType, func, array } from 'prop-types'

const InputField = ({
  error,
  errorArray,
  inputClassNames,
  label,
  name,
  onChange,
  parentClassNames,
  placeholder,
  type,
  value,
  useFloatingLabel,
  floatingLabel
}) => {
  return (
    <div
      className={`u-form__field u-form__field-input${
        parentClassNames ? ' ' + parentClassNames : ''
      }`}
    >
      <label className="u-form__field-label">
        <span className="u-form__field-custom-label">{label}</span>
        <input
          className={`u-form__field-input${
            inputClassNames ? ' ' + inputClassNames : ''
          } ${value ? 'has-value' : ''}`}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          value={value}
        />
        {useFloatingLabel && (floatingLabel || placeholder) && (
          <span
            className={`u-form__field-floating-label ${value ? 'active' : ''} ${
              label ? '' : 'no-label'
            }`}
          >
            {floatingLabel || placeholder}
          </span>
        )}
      </label>
      {error && <span className="u-form__field-error">{error}</span>}
      {errorArray &&
        errorArray.map(err => (
          <span className="u-form__field-error" key={err}>
            {err}
          </span>
        ))}
    </div>
  )
}

InputField.defaultProps = {
  error: null,
  name: '',
  placeholder: '',
  type: 'text'
}

InputField.propTypes = {
  error: oneOfType([bool, string]),
  errorArray: array,
  inputClassNames: string,
  label: string,
  name: string,
  onChange: func.isRequired,
  parentClassNames: string,
  placeholder: string,
  type: string,
  value: string.isRequired,
  useFloatingLabel: bool,
  floatingLabel: string
}

export default InputField
