import yn from 'yn'
import { filter, isEmpty } from 'lodash'

export default function filterGroupRoutes(routes, activeRouteGroup) {
  const groupRoutes = filter(routes, function(route) {
    return route.ferryId === activeRouteGroup
  })

  return groupRoutes
}

export const getFerryRoutesAndPair = (configuration, activeRouteGrouping) => {
  let ferryRoutes = []
  let isPairRoutes = false

  if (configuration?.ferry) {
    if (
      configuration.ferry.options &&
      !isEmpty(configuration.ferry.options.routes)
    ) {
      if (
        yn(process.env.REACT_APP_COMPONENT_ENABLE_ROUTE_GROUPINGS) &&
        activeRouteGrouping
      ) {
        ferryRoutes = filterGroupRoutes(
          configuration.ferry.options.routes,
          activeRouteGrouping
        )
      } else {
        ferryRoutes = configuration.ferry.options.routes
      }
    }

    if (!isEmpty(ferryRoutes) && ferryRoutes.length === 2) {
      isPairRoutes = true
    }
  }

  return {
    ferryRoutes,
    isPairRoutes
  }
}
