export const actionTypes = {
  REGISTER_CURRENT_PARAMS: 'refactorValidation/REGISTER_CURRENT_PARAMS',
  REGISTER_UPDATED_PARAMS: 'refactorValidation/REGISTER_UPDATED_PARAMS'
}

export const registerCurrentParams = (key, params) => dispatch => {
  return dispatch({
    type: actionTypes.REGISTER_CURRENT_PARAMS,
    payload: { key, params }
  })
}

export const registerUpdatedParams = (key, params) => dispatch => {
  return dispatch({
    type: actionTypes.REGISTER_UPDATED_PARAMS,
    payload: { key, params }
  })
}
