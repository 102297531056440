import { selectDuplicatePassengerQuantities } from '../passengerSelections/selectors'
import { createSelector } from 'reselect'
import { selectLanguage } from '../session/selectors'
import { selectQueryParamDatesAndRoutes } from './selectQueryParamDatesAndRoutes'
import { selectQueryParamDeparturePassengers } from './selectQueryParamDeparturePassengers'
import { selectQueryParamReturnPassengers } from './selectQueryParamReturnPassengers'

export const selectQueryParamsRelatedState = createSelector(
  selectQueryParamDatesAndRoutes,
  selectQueryParamDeparturePassengers,
  selectQueryParamReturnPassengers,
  selectDuplicatePassengerQuantities,
  selectLanguage,
  (
    { departureDate, departureRoute, returnDate, returnRoute, activeRouteForm },
    {
      departureAdults,
      departureSeniors,
      departureStudents,
      departureChildren,
      departureInfants,
      departurePets
    },
    {
      returnAdults,
      returnSeniors,
      returnStudents,
      returnChildren,
      returnInfants,
      returnPets
    },
    duplicatePassengerQuantities,
    language
  ) => ({
    departureDate,
    departureRoute,
    returnDate,
    returnRoute,
    activeRouteForm,
    departureAdults,
    departureSeniors,
    departureStudents,
    departureChildren,
    departureInfants,
    departurePets,
    returnAdults,
    returnSeniors,
    returnStudents,
    returnChildren,
    returnInfants,
    returnPets,
    duplicatePassengerQuantities,
    language
  })
)
