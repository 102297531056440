import { handleActions } from 'redux-actions'
import appRoutes from '../../configuration/appRoutes'
import { DISABLE_WAITING_ROOM } from '../../configuration/constants'
import {
  FETCH_CONFIG_BEGIN,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_ERROR,
  SET_LABELS,
  SET_FIELDS,
  FETCHING_WEBSITE_STATUS,
  FETCH_WEBSITE_STATUS_SUCCESS,
  FETCH_WEBSITE_STATUS_ERROR,
  FETCHING_ACTIVE_SESSIONS,
  FETCH_ACTIVE_SESSIONS_SUCCESS,
  FETCH_ACTIVE_SESSIONS_ERROR,
  SET_CONTENT,
  ENABLE_CAN_USE_APP,
  DISABLE_CAN_USE_APP,
  UPDATING_CONFIG_BY_LANGUAGE,
  UPDATE_CONFIG_BY_LANGUAGE_SUCCESS,
  UPDATE_CONFIG_BY_LANGUAGE_ERROR
} from './constants'
import { checkCanUseApp } from './utilities'
import yn from 'yn'

const configurationInitialState = {
  loading: false,
  error: null,
  data: null,
  fields: {},
  labels: {},
  fetchingLabels: false,
  fetchLabelsError: false,
  content: {},
  fetchingWebsiteStatus: false,
  websiteStatus: false,
  websiteStatusError: false,
  fetchingActiveSessions: false,
  activeSessions: false,
  activeSessionsError: false,
  canUseApp: DISABLE_WAITING_ROOM
    ? true
    : !yn(process.env.REACT_APP_TRIGGER_WAITING_ROOM_ON_LOAD)
}

const configurationReducer = handleActions(
  {
    [UPDATING_CONFIG_BY_LANGUAGE]: state => {
      return {
        ...state,
        fetchingLabels: true,
        fetchLabelsError: false
      }
    },
    [UPDATE_CONFIG_BY_LANGUAGE_SUCCESS]: state => ({
      ...state,
      fetchingLabels: false
    }),
    [UPDATE_CONFIG_BY_LANGUAGE_ERROR]: state => ({
      ...state,
      fetchingLabels: false,
      fetchLabelsError: true
    }),
    [SET_LABELS]: (state, { payload }) => {
      const newState = {
        ...state
      }
      if (payload.labels && payload.language) {
        const sessionTopLabels = window.sessionStorage.getItem('labels')
        let parsedLabels
        if (sessionTopLabels) {
          parsedLabels = JSON.parse(sessionTopLabels) || {}
        }

        const newLabels = {
          ...parsedLabels,
          [payload.language]: payload.labels
        }
        newState.labels = newLabels
        window.sessionStorage.setItem('labels', JSON.stringify(newLabels))
      }
      return newState
    },
    [SET_FIELDS]: (state, { payload }) => {
      const newState = {
        ...state
      }
      if (payload.fields && payload.language) {
        const sessionTopFields = window.sessionStorage.getItem('fields')
        let parsedFields
        if (sessionTopFields) {
          parsedFields = JSON.parse(sessionTopFields) || {}
        }

        const newFields = {
          ...parsedFields,
          [payload.language]: payload.fields
        }
        newState.fields = newFields
        window.sessionStorage.setItem('fields', JSON.stringify(newFields))
      }
      return newState
    },
    [FETCH_CONFIG_BEGIN]: (state, action) => ({
      ...state,
      loading: true,
      error: null
    }),
    [FETCH_CONFIG_SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload
    }),
    [FETCH_CONFIG_ERROR]: (state, { payload }) => ({
      ...state,
      loading: false,
      error:
        typeof payload === 'string'
          ? payload
          : payload.message || 'An unknown error occured'
    }),
    [FETCHING_WEBSITE_STATUS]: state => ({
      ...state,
      fetchingWebsiteStatus: true,
      websiteStatus: false,
      websiteStatusError: false
    }),
    [FETCH_WEBSITE_STATUS_SUCCESS]: (state, { payload }) => ({
      ...state,
      fetchingWebsiteStatus: false,
      websiteStatusError: false,
      websiteStatus: payload
    }),
    [FETCH_WEBSITE_STATUS_ERROR]: state => ({
      ...state,
      fetchingWebsiteStatus: false,
      websiteStatus: false,
      websiteStatusError: true
    }),
    [FETCHING_ACTIVE_SESSIONS]: state => ({
      ...state,
      fetchingActiveSessions: true,
      activeSessionsError: false
    }),
    [FETCH_ACTIVE_SESSIONS_SUCCESS]: (state, { payload }) => {
      // TODO: This could be refactored to force enable a set of pathnames
      let currentPathname = payload.pathname
      const isUpdatePassword =
        currentPathname &&
        currentPathname.includes(appRoutes.updatePassword.pathnameWithoutParams)

      return {
        ...state,
        fetchingActiveSessions: false,
        activeSessions: +payload.sessionCount,
        activeSessionsError: false,
        canUseApp: !isUpdatePassword
          ? checkCanUseApp(payload.sessionCount)
          : true
      }
    },
    [ENABLE_CAN_USE_APP]: state => ({
      ...state,
      canUseApp: true
    }),
    [DISABLE_CAN_USE_APP]: state => ({
      ...state,
      canUseApp: false
    }),
    [FETCH_ACTIVE_SESSIONS_ERROR]: (state, { payload }) => {
      // TODO: This could be refactored to force enable a set of pathnames
      let currentPathname = payload
      const isUpdatePassword =
        currentPathname &&
        currentPathname.includes(appRoutes.updatePassword.pathnameWithoutParams)

      return {
        ...state,
        fetchingActiveSessions: false,
        activeSessionsError: true,
        canUseApp: isUpdatePassword ? true : false
      }
    },
    [SET_CONTENT]: (state, action) => ({
      ...state,
      content: {
        ...state.content,
        [action.payload.language]: action.payload
      }
    })
  },
  { ...configurationInitialState }
)

export { configurationInitialState }
export default configurationReducer
