import React from 'react'
import { arrayOf, oneOfType, bool, string, shape, func } from 'prop-types'
import BrandLoader from '../BrandLoader/BrandLoader'
import BookingReminders from '../BookingReminders/BookingReminders'
import BookingConfirmationTable from '../BookingConfirmation/BookingConfirmationTable'
import LinkWithTitle from '../LinkWithTitle/LinkWithTitle'

const MonerisConfirmation = ({
  labels,
  isLoading,
  error,
  tableData,
  additionalContent,
  onPrimaryClick,
  onMyAccountBtnClick,
  isLoggedIn
}) => {
  return (
    <div className="booking-confirmation">
      <div className="u-container">
        <div className="booking-confirmation-content">
          <div className="padded-container">
            <h2 className="theme-font-header">{labels.bookingConfirmtion}</h2>
            {isLoading ? (
              <BrandLoader />
            ) : (
              <>
                {error ? (
                  <p className="u-text-center" style={{ color: 'red' }}>
                    {error}
                  </p>
                ) : (
                  <>
                    <BookingReminders labels={labels} />
                    <BookingConfirmationTable
                      headerLabel={labels.transactionApproved}
                      tableData={tableData}
                      centerHeader
                    />
                  </>
                )}
              </>
            )}
          </div>
          <div className="padded-container">
            {additionalContent?.value?.linkWithHeading &&
            Object.keys(additionalContent.value.linkWithHeading).length > 0
              ? Object.keys(additionalContent.value.linkWithHeading).map(
                  (linkWithHeadingName, linkWithHeadingKey) => {
                    const linkWithHeadingDetails =
                      additionalContent.value.linkWithHeading[
                        linkWithHeadingName
                      ].value
                    let result = null

                    if (linkWithHeadingDetails.length > 0) {
                      const key = `${linkWithHeadingName}-linkWithHeading-${linkWithHeadingKey}`
                      const title = linkWithHeadingDetails[0].value
                      const link = linkWithHeadingDetails[1].link
                      const linkLabel = linkWithHeadingDetails[1].value

                      if (key && title && link && linkLabel) {
                        result = (
                          <LinkWithTitle
                            key={key}
                            title={title}
                            linkLabel={linkLabel}
                            link={link}
                          />
                        )
                      }
                    }

                    return result
                  }
                )
              : null}
          </div>
        </div>

        {!isLoading && !error && (
          <>
            <div>
              <div className="booking-confirmation__submits">
                <button
                  className="btn btn-primary large-primary-btn"
                  onClick={onPrimaryClick}
                >
                  {labels.newBookingBtn || labels.continueBtn}
                </button>
                {isLoggedIn && (
                  <button
                    className="btn btn-primary large-primary-btn"
                    onClick={onMyAccountBtnClick}
                  >
                    {labels.myAccountBtn}
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

MonerisConfirmation.propTypes = {
  labels: shape().isRequired,
  isLoading: bool.isRequired,
  error: oneOfType([bool, string]),
  tableData: arrayOf(shape()).isRequired,
  additionalContent: shape().isRequired,
  onPrimaryClick: func.isRequired,
  onMyAccountBtnClick: func.isRequired,
  isLoggedIn: bool.isRequired
}

export default MonerisConfirmation
