import { createAction } from 'redux-actions'
import {
  SET_ACTIVE_DEPARTURE_CROSSING,
  SET_ACTIVE_RETURN_CROSSING,
  RESET_ACTIVE_CROSSINGS,
  RESET_RETURN_CROSSINGS
} from './constants'

export const setDepartureCrossing = createAction(SET_ACTIVE_DEPARTURE_CROSSING)
export const setReturnCrossing = createAction(SET_ACTIVE_RETURN_CROSSING)
export const resetActiveCrossings = createAction(RESET_ACTIVE_CROSSINGS)
export const resetReturnCrossing = createAction(RESET_RETURN_CROSSINGS)
