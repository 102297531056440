import React from 'react'
import { func, shape, string, bool } from 'prop-types'
import yn from 'yn'
import { ENABLE_ACCOMMODATIONS } from '../../configuration/constants'
import SvgIcon from '../SvgIcon/SvgIcon'
import ConfigConsumer from '../../redux/configuration/context'
import { formatPriceByLanguage } from '../../redux/configuration/utilities'
import '../../styles/scss/components/summary/mobile-summary-styles.scss'

export const MobileSummaryBreakdownItem = ({
  label,
  value,
  cost,
  iconType,
  changeRoute,
  handleChange,
  labels,
  editable,
  currencyLabel
}) => (
  <div className="mobile-summary-trip-breakdown-item">
    <div className="mobile-summary-trip-breakdown-item-header">
      {iconType && (
        <ConfigConsumer>
          {({ themeVariables }) => (
            <SvgIcon
              type={iconType}
              className="mobile-summary-icon"
              fill={themeVariables.themeIconColor}
            />
          )}
        </ConfigConsumer>
      )}
      {label && <p>{label}</p>}
    </div>
    <div className="mobile-summary-trip-breakdown-item-details">
      <p>{value}</p>
    </div>
    <div className="mobile-summary-trip-breakdown-item-footer">
      {cost ? (
        <div>
          {cost} {currencyLabel}
        </div>
      ) : (
        <div></div>
      )}
      <div>
        {changeRoute && editable && (
          <button
            className="btn"
            aria-label={`${labels.change} ${label}`}
            onClick={() => handleChange(changeRoute)}
          >
            {labels.change}
          </button>
        )}
      </div>
    </div>
  </div>
)

const MobileSummaryBreakdown = ({
  breakdown,
  route,
  headerLabel,
  handleRouteChange,
  totalLabel,
  labels,
  language,
  editable,
  currencyLabel
}) => {
  if (!breakdown) {
    return null
  }

  const {
    dateSection,
    passengersSection,
    vehicleSection,
    accommodationsSection,
    discountSection,
    taxesAndFeesSections,
    tripTotal,
    alreadyPaid,
    balanceDue
  } = breakdown

  return (
    <div className="mobile-summary-trip">
      <div className="mobile-summary-trip-header">
        <p>{headerLabel}</p>
        <p>{labels[route?.routeCode]}</p>
      </div>
      <div className="mobile-summary-trip-breakdown">
        <MobileSummaryBreakdownItem
          {...dateSection}
          labels={labels}
          handleChange={handleRouteChange}
          editable={editable}
          currencyLabel={currencyLabel}
        />
        <MobileSummaryBreakdownItem
          {...passengersSection}
          labels={labels}
          handleChange={handleRouteChange}
          editable={editable}
          currencyLabel={currencyLabel}
        />
        <MobileSummaryBreakdownItem
          {...vehicleSection}
          labels={labels}
          handleChange={handleRouteChange}
          editable={editable}
          currencyLabel={currencyLabel}
        />
        {ENABLE_ACCOMMODATIONS && (
          <MobileSummaryBreakdownItem
            {...accommodationsSection}
            labels={labels}
            handleChange={handleRouteChange}
            editable={editable}
            currencyLabel={currencyLabel}
          />
        )}
        {discountSection && (
          <MobileSummaryBreakdownItem
            {...discountSection}
            cost={`-${discountSection.cost}`}
            editable={editable}
            currencyLabel={currencyLabel}
            iconType={'discount'}
          />
        )}
        {taxesAndFeesSections.length ? (
          <div className="tax-lines">
            {taxesAndFeesSections.map((sectionInfo, index) => {
              return (
                <MobileSummaryBreakdownItem
                  {...sectionInfo}
                  label={index === 0 ? labels.taxesAndFees : null}
                  labels={labels}
                  key={`tf_${index}`}
                  editable={editable}
                  currencyLabel={currencyLabel}
                />
              )
            })}
          </div>
        ) : null}

        {yn(
          process.env
            .REACT_APP_SHOULD_HIDE_PREVIOUS_PAYMENT_LINES_ON_SUMMARY_TRIP_BREAKDOWN
        ) ? (
          <div className="mobile-summary-trip-total">
            <p>{totalLabel}</p>
            <p>
              {formatPriceByLanguage(tripTotal, language)} {currencyLabel}
            </p>
          </div>
        ) : (
          <>
            {alreadyPaid ? (
              <div className="mobile-summary-modification-totals">
                <div className="mobile-summary-modification-totals-item">
                  <p>{labels.tripTotal}</p>
                  <p>
                    {formatPriceByLanguage(tripTotal, language)} {currencyLabel}
                  </p>
                </div>
                <div className="mobile-summary-modification-totals-item">
                  <p>{labels.paidAmount}</p>
                  <p>
                    {formatPriceByLanguage(alreadyPaid, language)}{' '}
                    {currencyLabel}
                  </p>
                </div>
                <div className="mobile-summary-modification-totals-item balance-due">
                  <p>{labels.balanceDue}</p>
                  <p>
                    {formatPriceByLanguage(balanceDue, language)}{' '}
                    {currencyLabel}
                  </p>
                </div>
              </div>
            ) : (
              <div className="mobile-summary-trip-total">
                <p>{totalLabel}</p>
                <p>
                  {formatPriceByLanguage(tripTotal, language)} {currencyLabel}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

MobileSummaryBreakdown.propTypes = {
  breakdown: shape().isRequired,
  labels: shape().isRequired,
  language: string.isRequired,
  route: shape().isRequired,
  headerLabel: string.isRequired,
  handleRouteChange: func.isRequired,
  totalLabel: string.isRequired,
  editable: bool,
  currencyLabel: string
}

MobileSummaryBreakdown.defaultProps = {
  editable: true,
  currencyLabel: ''
}

export default MobileSummaryBreakdown
