const isRouteCurrentSelection = (currentSelection, crossing) => {
  return Boolean(
    currentSelection &&
      crossing &&
      crossing.departureDate === currentSelection.departureDate &&
      crossing.departureTime === currentSelection.departureTime &&
      crossing.routeCode === currentSelection.routeCode &&
      crossing.shipCode === currentSelection.shipCode
  )
}

export default isRouteCurrentSelection
