import React, { Fragment } from 'react'
import { arrayOf, oneOfType, bool, func, shape, node } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { selectLanguage } from '../../redux/session/selectors'
import { selectLabels } from '../../redux/configuration/selectors'
import {
  setDepartureRoute,
  setReturnRoute,
  setForcesBooking
} from '../../redux/ferryRouteSelections/actions'
import {
  selectDepartureDate,
  selectDepartureRoute,
  selectReturnDate,
  selectReturnRoute,
  selectForcesBooking
} from '../../redux/ferryRouteSelections/selectors'
import {
  clearPromoValidation,
  fetchPromoInformation
} from '../../redux/promo/actions'
import * as UserSelectionActions from '../../redux/userSelections/actions'
import { selectCurrentBookingPromoState } from '../../redux/selectors/selectCurrentBookingPromoState'
import FerryRoutesFormSelect from './elements/FerryRoutesFormSelect'
import FerryRoutesFormDateRangePicker from './elements/dates/FerryRoutesFormDateRangePicker'
import ValidatedPromoInput from '../ValidatedPromoInput/ValidatedPromoInput'
import WarningBanner from '../WarningBanner/WarningBanner'
import Checkbox from '../Checkbox/Checkbox'

const FerryRoutesRoundTripForm = ({
  availabilityErrors,
  ferryRoutes,
  isPairRoutes,
  submit,
  enableRouteSelection
}) => {
  const dispatch = useDispatch()
  const activeDepartureRoute = useSelector(selectDepartureRoute)
  const activeReturnRoute = useSelector(selectReturnRoute)
  const departureDate = useSelector(selectDepartureDate)
  const returnDate = useSelector(selectReturnDate)
  const labels = useSelector(selectLabels)
  const forcesBooking = useSelector(selectForcesBooking)
  const promoInputState = useSelector(selectCurrentBookingPromoState)
  const language = useSelector(selectLanguage)

  let isSubmitEnabled = false

  if (
    activeDepartureRoute &&
    activeDepartureRoute.code &&
    activeReturnRoute &&
    activeReturnRoute.code &&
    departureDate &&
    returnDate &&
    (promoInputState.valid === true ||
      promoInputState.valid === null ||
      promoInputState.code === '')
  ) {
    isSubmitEnabled = true
  }
  if (promoInputState.valid && forcesBooking) {
    isSubmitEnabled = false
  }

  const handleDepartureRoute = routeData => {
    if (isPairRoutes) {
      if (String(routeData.code) !== String(activeDepartureRoute.code)) {
        if (activeDepartureRoute && activeDepartureRoute.code) {
          dispatch(setReturnRoute(activeDepartureRoute))
          dispatch(
            UserSelectionActions.setReturnRoute(activeDepartureRoute.code)
          )
        } else {
          const firstAvailableRoute = ferryRoutes.find(
            route => route.code !== routeData.code
          )
          if (firstAvailableRoute) {
            dispatch(setReturnRoute(firstAvailableRoute))
            dispatch(
              UserSelectionActions.setReturnRoute(firstAvailableRoute.code)
            )
          }
        }
      }
    }
    dispatch(setDepartureRoute(routeData))

    dispatch(setReturnRoute(null))
    dispatch(UserSelectionActions.setReturnRoute(null))

    dispatch(UserSelectionActions.setDepartureRoute(routeData.code))
    dispatch(clearPromoValidation())
  }

  const handleReturnRoute = routeData => {
    if (isPairRoutes) {
      if (String(routeData.code) !== String(activeReturnRoute.code)) {
        if (activeReturnRoute && activeReturnRoute.code) {
          dispatch(setDepartureRoute(activeReturnRoute))
          dispatch(
            UserSelectionActions.setDepartureRoute(activeReturnRoute.code)
          )
        } else {
          const firstAvailableRoute = ferryRoutes.find(
            route => route.code !== routeData.code
          )
          if (firstAvailableRoute) {
            dispatch(setDepartureRoute(firstAvailableRoute))
            dispatch(
              UserSelectionActions.setDepartureRoute(firstAvailableRoute.code)
            )
          }
        }
      }
    }
    dispatch(setReturnRoute(routeData))
    dispatch(UserSelectionActions.setReturnRoute(routeData.code))
    dispatch(clearPromoValidation())
  }

  let returnRoutes = ferryRoutes

  if (activeDepartureRoute?.crossings) {
    const validReturnRoutes = []
    activeDepartureRoute.crossings.forEach(c => {
      if (c.supplierCode !== activeDepartureRoute.code) {
        validReturnRoutes.push(c.supplierCode)
      }
    })
    returnRoutes = returnRoutes.filter(
      r => validReturnRoutes.indexOf(r.code) > -1
    )
  } else {
    returnRoutes = []
  }

  return (
    <div className="ferry-routes__form-round-trip">
      {!isEmpty(ferryRoutes) && (
        <div className="ferry-routes__form-routes">
          <FerryRoutesFormSelect
            activeRoute={activeDepartureRoute}
            ferryRoutes={ferryRoutes}
            fieldLabel={labels.departureRoute}
            toggleLabel={labels.change}
            updateRouteEvent={handleDepartureRoute}
            disableToggle={!enableRouteSelection}
          />
          <FerryRoutesFormSelect
            activeRoute={activeReturnRoute}
            ferryRoutes={returnRoutes}
            fieldLabel={labels.returnRoute}
            toggleLabel={labels.change}
            updateRouteEvent={handleReturnRoute}
            disableToggle={!enableRouteSelection || returnRoutes.length === 0}
          />
        </div>
      )}

      <FerryRoutesFormDateRangePicker
        ferryLabels={labels}
        returnDateOnly={!enableRouteSelection}
        onChange={() => dispatch(clearPromoValidation())}
      />

      <div className="ferry-routes__validated-promo-container">
        <ValidatedPromoInput
          labels={labels}
          handleBlur={v => dispatch(UserSelectionActions.setPromoCode(v))}
          submitValidation={code => {
            dispatch(fetchPromoInformation(code))
          }}
          clearValidation={() => dispatch(clearPromoValidation())}
          {...promoInputState}
        />
      </div>

      <div className="ferry-routes__forces-input-container">
        <Checkbox
          name={'CF_BOOKING'}
          value={forcesBooking}
          label={labels.canadianForcesMember}
          disabled={false}
          onChange={v => {
            dispatch(setForcesBooking(v.target.checked))
            dispatch(UserSelectionActions.setIsForcesMember(v.target.checked))
          }}
          formikCheckbox={false}
        />
      </div>
      {forcesBooking && labels.canadianForcesMemberElig1 && (
        <p className="cfid-disclaimer">
          {`${labels.canadianForcesMemberElig1} `}
          <a
            href={
              language === 'fr'
                ? process.env.REACT_APP_CFID_LINK_FR
                : process.env.REACT_APP_CFID_LINK_EN
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {labels.clickHere || ''}
          </a>
          {` ${labels.canadianForcesMemberElig2}`}
        </p>
      )}

      {availabilityErrors?.length ? (
        <Fragment>
          {availabilityErrors.map((error, i) => (
            <WarningBanner key={error + i}>{error}</WarningBanner>
          ))}
        </Fragment>
      ) : null}
      {promoInputState.valid && forcesBooking && (
        <WarningBanner>{labels.discountCannotBeCombined}</WarningBanner>
      )}
      <button
        className="ferry-routes__form-submit btn btn-primary large-primary-btn"
        onClick={submit}
        disabled={!isSubmitEnabled}
      >
        {labels.continueToPassengerInfoBtn} →
      </button>
    </div>
  )
}

FerryRoutesRoundTripForm.propTypes = {
  availabilityErrors: oneOfType([bool, arrayOf(node)]),
  openModal: func.isRequired,
  closeModal: func.isRequired,
  modalIsOpen: shape().isRequired,
  ferryRoutes: arrayOf(shape()).isRequired,
  isPairRoutes: bool,
  submit: func.isRequired,
  enableRouteSelection: bool.isRequired
}

export default FerryRoutesRoundTripForm
