import moment from 'moment'
import { isEqual } from 'lodash'
import { ENABLE_ACCOMMODATIONS } from '../../configuration/constants'
import passengerTypeCodes from '../../configuration/passengerTypeCodes'

export const tranformBookingObject = booking => {
  let departureRoute,
    returnRoute,
    isReturnTrip = booking.departures?.length > 1,
    departureGuestCounts,
    returnGuestCounts

  // just in case the api returns these out of order
  for (const departure of booking.departures) {
    if (
      !departureRoute ||
      (departureRoute && // for the linter
        moment(departureRoute.departureDate).isAfter(
          moment(departure.departureDate),
          'days'
        ))
    ) {
      departureRoute = departure
    } else {
      returnRoute = departure
    }
  }

  // PASSENGER COUNTS
  if (departureRoute?.passengers?.booked) {
    departureGuestCounts = renderTripGuests(departureRoute.passengers.booked)
  }

  if (returnRoute?.passengers?.booked) {
    returnGuestCounts = renderTripGuests(returnRoute.passengers.booked)
  }

  // VEHICLES
  // this is under the assumption that you can only have one vehicle per crossing.
  // That is all the frontend currently supports

  const departureVehicleCode =
    departureRoute?.vehiclePriceLines?.[0]?.resourceCode
  const returnVehicleCode = returnRoute?.vehiclePriceLines?.[0]?.resourceCode

  const departureVehicleDetails = departureRoute?.vehicles?.booked?.[0]
  const returnVehicleDetails = returnRoute?.vehicles?.booked?.[0]

  return {
    ...booking,
    isReturnTrip,
    departureRoute,
    returnRoute,
    departureGuestCounts,
    returnGuestCounts,
    samePassengersAllTrips: Boolean(
      !isReturnTrip || isEqual(departureGuestCounts, returnGuestCounts)
    ),
    departureVehicleCode,
    returnVehicleCode,
    sameVehicleBothDirection:
      !isReturnTrip || departureVehicleCode === returnVehicleCode,
    departureVehicleDetails,
    returnVehicleDetails,
    departureAccommodations: formatAccommodationSelections(
      departureRoute?.accommodations?.booked
    ),
    returnAccommodations: formatAccommodationSelections(
      returnRoute?.accommodations?.booked
    ),
    departureSeats: formatSelectedSeats(departureRoute?.accommodations?.booked),
    returnSeats: formatSelectedSeats(returnRoute?.accommodations?.booked)
  }
}

export const transformBookingsArray = bookings => {
  return bookings && Object.keys(bookings).length
    ? Object.values(bookings).map(tranformBookingObject)
    : []
}

const renderTripGuests = trip => {
  return trip.reduce((obj, passenger) => {
    const code =
      passengerTypeCodes?.[passenger.passengerType]?.code ||
      passenger.passengerType
    if (!obj[code]) {
      obj[code] = 1
    } else {
      obj[code] += 1
    }

    return obj
  }, {})
}

const formatSelectedSeats = accommodations => {
  if (!accommodations?.length) {
    return null
  }

  return accommodations.filter(
    accommodation => accommodation.resourceCode === 'RDS'
  )
}

const formatAccommodationSelections = accommodations => {
  return accommodations?.length && ENABLE_ACCOMMODATIONS
    ? accommodations.reduce((obj, accommodation) => {
        if (!obj[accommodation.resourceCode]) {
          obj[accommodation.resourceCode] = {
            count: 1,
            details: accommodation
          }
        } else {
          obj[accommodation.resourceCode] = {
            ...obj[accommodation.resourceCode],
            count: (obj[accommodation.resourceCode].count += 1)
          }
        }

        return obj
      }, {})
    : null
}
