import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { ENABLE_ACCOMMODATIONS } from '../../configuration/constants'
import appRoutes from '../../configuration/appRoutes'
import { isCurrentPath } from '../../utilities/browser/comparePaths'

import {
  setActiveDepartureAccommodations,
  setActiveReturnAccommodations
} from '../../redux/activeAccommodations/actions'
import {
  updateDepartureAccommodations,
  updateReturnAccommodations
} from '../../redux/userSelections/actions'
import {
  selectActiveDepartureAccommodations,
  selectActiveReturnAccommodations
} from '../activeAccommodations/selectors'
import { crossingTypes } from '../crossings/constants'
import {
  setVehicleFormType,
  setVehicleDetails
} from '../vehicleSelections/actions'
import { selectInitVehicleFormType } from '../vehicleSelections/selectors'
import * as UserSelectionActions from '../userSelections/actions'
import { vehicleTypes } from '../vehicleSelections/constants'

/**
 * Custom hook for booking modification restore in header details dropdown.
 * The reason saving active accommodations comes from booking record here is,
 * we need to display expense of the booking record being modified in couple of pages:
 * passengers/vehicles/route page. But, implemmentation in each view is not wise,
 * cause its a common logic which could be reusable.
 * Also NOTE:
 * deps in this hook should be ignored,
 * thats means we need to run this hook in each page to recalculate breakdown data
 *
 * @param {*} activeModifyBooking
 * @param {*} dispatch
 */
export const useActiveBookingModification = activeModifyBooking => {
  const dispatch = useDispatch()
  const vehicleFormType = useSelector(selectInitVehicleFormType)
  const activeDepartureAccommodations = useSelector(
    selectActiveDepartureAccommodations
  )
  const activeReturnAccommodations = useSelector(
    selectActiveReturnAccommodations
  )

  const setActiveVehiclesToSelection = () => {
    // FIXME: if already set(in vehicle page),
    // no need to restore original vehicle in modify workflow
    if (vehicleFormType) return
    // if vehicle in departure selected
    const isReturnTrip = activeModifyBooking?.departures?.length > 1
    const hasDifferentVehicles =
      isReturnTrip &&
      activeModifyBooking.departureVehicleCode !==
        activeModifyBooking.returnVehicleCode

    const isWalkingOnDeparture =
      hasDifferentVehicles && !activeModifyBooking.departureVehicleCode
    const isWalkingOnReturn =
      hasDifferentVehicles && !activeModifyBooking.returnVehicleCode

    if (activeModifyBooking.departureVehicleDetails) {
      const vehicleType =
        activeModifyBooking.departureVehicleDetails.resourceCode
      const resourceCode =
        activeModifyBooking.departureVehicleDetails.resourceCode
      const vehicleLength = activeModifyBooking.departureVehicleDetails.length
      const vehicleHeight = activeModifyBooking.departureVehicleDetails.height
      const vehicleLicense =
        activeModifyBooking.departureVehicleDetails.licenseNumber
      const actionPayload = {
        crossingType: crossingTypes.DEPARTURE,
        vehicle: {
          vehicleType: {
            resourceCode,
            defaultLength: parseInt(vehicleLength),
            defaultHeight: parseInt(vehicleHeight)
          },
          vehicleLength, // necessary to query price
          vehicleLicense,
          vehicleGroupTypeHeight: {},
          vehicleGroupTypeLength: {}
        }
      }
      dispatch(setVehicleFormType(vehicleType))
      dispatch(setVehicleDetails(actionPayload))
    } else if (isWalkingOnDeparture) {
      //  we use the user vehicle selections to determine one way walk ons
      dispatch(
        UserSelectionActions.setDepartureVehicle({
          vehicleType: vehicleTypes.VEHWLK
        })
      )
    }

    // if vehicle in return selected
    if (activeModifyBooking.returnVehicleDetails) {
      const resourceCode = activeModifyBooking.returnVehicleDetails.resourceCode
      const vehicleLength = activeModifyBooking.returnVehicleDetails.length
      const vehicleHeight = activeModifyBooking.returnVehicleDetails.height
      const vehicleLicense =
        activeModifyBooking.returnVehicleDetails.licenseNumber
      const actionPayload = {
        crossingType: crossingTypes.RETURN,
        vehicle: {
          vehicleType: {
            resourceCode,
            defaultLength: parseInt(vehicleLength),
            defaultHeight: parseInt(vehicleHeight)
          },
          vehicleLength, // necessary to query price
          vehicleLicense,
          vehicleGroupTypeHeight: {},
          vehicleGroupTypeLength: {}
        }
      }
      dispatch(setVehicleDetails(actionPayload))
    } else if (isWalkingOnReturn) {
      //  we use the user vehicle selections to determine one way walk ons
      dispatch(
        UserSelectionActions.setReturnVehicle({
          vehicleType: vehicleTypes.VEHWLK
        })
      )
    }
  }

  const formatAccommodations = accommodations => {
    if (!accommodations || accommodations === {}) {
      return null
    }
    const formattedAccommodations = {}
    Object.keys(accommodations).forEach(accommodationKey => {
      formattedAccommodations[accommodationKey] =
        accommodations[accommodationKey].count
    })

    return formattedAccommodations
  }

  const setActiveAccommodations = () => {
    if (!ENABLE_ACCOMMODATIONS) return // no need
    // in accommodations page no need to reset, cause already set
    if (isCurrentPath(appRoutes.accommodations.pathname)) return
    if (isCurrentPath(appRoutes.passengerDetails.pathname)) return
    if (isCurrentPath(appRoutes.summary.pathname)) return
    if (isCurrentPath(appRoutes.extras.pathname)) return

    if (activeModifyBooking.departureAccommodations) {
      // having accommodations no need to reset
      if (!isEmpty(activeDepartureAccommodations)) return
      // restore original accommodations
      dispatch(
        updateDepartureAccommodations(
          formatAccommodations(activeModifyBooking.departureAccommodations)
        )
      )
      // TODO: deprecate
      dispatch(
        setActiveDepartureAccommodations(
          activeModifyBooking.departureAccommodations
        )
      )
    }

    if (activeModifyBooking.returnAccommodations) {
      // having accommodations no need to reset
      if (!isEmpty(activeReturnAccommodations)) return
      // restore original accommodations
      dispatch(
        updateReturnAccommodations(
          formatAccommodations(activeModifyBooking?.returnAccommodations)
        )
      )
      // TODO: deprecate
      dispatch(
        setActiveReturnAccommodations(activeModifyBooking.returnAccommodations)
      )
    }
  }

  useEffect(() => {
    if (!activeModifyBooking) return // no booking in modification
    // Due to the `cleanRedux` on route change,
    // lazy saving accommodations to avoid being overridden by `cleanRedux`
    // lifecycle sequence like this:
    // 1. component hook render -->
    // 2. route change clean -->
    // 3. run callback in setTimeout!
    setTimeout(() => {
      setActiveAccommodations()
      setActiveVehiclesToSelection()
    })
  })
}
