import { createSelector } from 'reselect'
import moment from 'moment'
import dateFormats from '../../configuration/dateFormats'

export const selectFerryRouteSelectionsState = state =>
  state.ferryRouteSelections

export const selectDepartureDate = createSelector(
  selectFerryRouteSelectionsState,
  ferryRouteSelectionsState => {
    return ferryRouteSelectionsState.departureDate
      ? moment(ferryRouteSelectionsState.departureDate, dateFormats.default)
      : null
  }
)
export const selectDepartureRoute = createSelector(
  selectFerryRouteSelectionsState,
  ferryRouteSelectionsState => ferryRouteSelectionsState.departureRoute
)

export const selectReturnDate = createSelector(
  selectFerryRouteSelectionsState,
  ferryRouteSelectionsState => {
    return ferryRouteSelectionsState.returnDate
      ? moment(ferryRouteSelectionsState.returnDate, dateFormats.default)
      : null
  }
)
export const selectReturnRoute = createSelector(
  selectFerryRouteSelectionsState,
  ferryRouteSelectionsState => ferryRouteSelectionsState.returnRoute
)

export const selectActiveRouteForm = createSelector(
  selectFerryRouteSelectionsState,
  ferryRouteSelectionsState => ferryRouteSelectionsState.activeRouteForm
)

export const selectIsReturnTrip = createSelector(
  selectFerryRouteSelectionsState,
  ferryRouteSelectionsState =>
    ferryRouteSelectionsState.activeRouteForm === 'RT'
)

export const selectForcesBooking = createSelector(
  selectFerryRouteSelectionsState,
  ferryRouteSelectionsState => ferryRouteSelectionsState.forcesBooking
)

/**
 * used in selectPassengersForBooking.js only
 */
export const selectPassengerTypeMap = createSelector(
  selectDepartureRoute,
  selectReturnRoute,
  selectIsReturnTrip,
  (departureRoute, returnRoute, isReturn) => {
    let result = false

    if (departureRoute) {
      result = {}
      result.departure = {}
      departureRoute.passengerTypes.forEach(({ code, type }) => {
        result.departure[code] = type
      })
    }

    if (isReturn && returnRoute) {
      result.return = {}
      returnRoute.passengerTypes.forEach(({ code, type }) => {
        result.return[code] = type
      })
    }

    if (!result.return) {
      result.return = { ...result.departure }
    }

    return result
  }
)

// only required in CAT route of NFL-Bay ferry
export const selectPassengerPassportIsRequired = createSelector(
  selectDepartureRoute,
  departureRoute => {
    return departureRoute.code === 'YABA' || departureRoute.code === 'BAYA'
  }
)
