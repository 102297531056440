import React, { Fragment, useEffect, useState } from 'react'
import { func, string } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { enableCanUseApp } from '../../redux/configuration/actions'
import { Formik, Form } from 'formik'
import {
  selectLabels,
  selectFields,
  selectCanUseApp
} from '../../redux/configuration/selectors'
import RowGroup from '../../components/RowGroup/RowGroup'
import Notification from '../../components/Notification'
import { renderTextInput } from '../../components/TextInput/TextInput'
import {
  getInitialValuesFromFields,
  filterFieldsByName,
  getSchemaFromFieldDetails
} from '../../redux/configuration/utilities'
import {
  passwordReset,
  resetPasswordResetError
} from '../../redux/user/actions'
import {
  selectAttemptingPasswordReset,
  selectPasswordResetSuccess,
  selectPasswordResetError
} from '../../redux/user/selectors'
import { selectLanguage, selectSessionId } from '../../redux/session/selectors'
import { fieldNames } from './updatePasswordConstants'

import '../../styles/scss/components/forms/page-form-defaults.scss'

const UpdatePasswordContainer = ({
  handleRouteChange,
  emailToken,
  languageCode,
  handleToggleLanguage
}) => {
  const dispatch = useDispatch()
  const labels = useSelector(selectLabels)
  const fields = useSelector(selectFields)
  const clientId = process.env.REACT_APP_FLOW_CLIENT_ID
  const sessionId = useSelector(selectSessionId)
  const canUseApp = useSelector(selectCanUseApp)
  const [passwordMismatchError, setPasswordMismatchError] = useState(false)
  const [initialLanguageCheck, setInitialLanguageCheck] = useState(false)
  const fieldDetails = filterFieldsByName(fields, fieldNames)
  const initialValues = getInitialValuesFromFields(fieldDetails)
  const validationSchema = getSchemaFromFieldDetails(fieldDetails)
  const attemptingPasswordReset = useSelector(selectAttemptingPasswordReset)
  const passwordResetSuccess = useSelector(selectPasswordResetSuccess)
  const passwordResetError = useSelector(selectPasswordResetError)
  const activeLanguage = useSelector(selectLanguage)
  const disabled = attemptingPasswordReset || !canUseApp

  const handleSubmit = ({ password, confirmPassword }) => {
    let submitError = false
    setPasswordMismatchError(false)

    if (password !== confirmPassword) {
      setPasswordMismatchError(true)
      submitError = true
    }

    if (!submitError) {
      dispatch(passwordReset({ password, clientId, sessionId, emailToken }))
    }
  }

  useEffect(() => {
    if (
      languageCode &&
      activeLanguage !== languageCode &&
      !initialLanguageCheck
    ) {
      setInitialLanguageCheck(true)
      handleToggleLanguage(languageCode)
    }
  }, [activeLanguage, languageCode, handleToggleLanguage, initialLanguageCheck])

  useEffect(() => {
    dispatch(enableCanUseApp())
  }, [dispatch])

  return (
    <div className="page-container">
      <span className="page-container-top-bg" />
      {!fieldDetails ? null : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, values, errors }) => {
            const hasErrors = Boolean(errors && Object.keys(errors).length)
            return !values ? null : (
              <Form>
                <div className="row-group-container row-group-container-with-top-bg">
                  <div className="row-group-container-bg row-group-container-with-top-bg">
                    {!passwordResetSuccess && (
                      <div className="row alternate-row-header">
                        <h2 className="theme-font-header">
                          {labels.updatePassword}
                        </h2>
                        <p>{labels.updatePasswordHelpText}</p>
                      </div>
                    )}
                    <RowGroup
                      render={({ formRowClasses, errorClasses }) => (
                        <Fragment>
                          {passwordResetSuccess ? (
                            <div className="row alternate-row-header alternate-header-padding">
                              <h2 className="theme-font-header">
                                {labels.thankYou}
                              </h2>
                              <p>{labels.passwordUpdateSuccess}</p>
                            </div>
                          ) : (
                            <Fragment>
                              <span className={formRowClasses}>
                                {fieldDetails.password &&
                                  renderTextInput({
                                    name: fieldDetails.password.name,
                                    value: values[fieldDetails.password.name],
                                    type: 'password',
                                    placeholder: fieldDetails.password.label,
                                    errorClasses,
                                    setFieldValue,
                                    disabled,
                                    inputHelpText: labels.passwordHelper
                                  })}
                              </span>
                              <span className={formRowClasses}>
                                {fieldDetails.confirmPassword &&
                                  renderTextInput({
                                    name: fieldDetails.confirmPassword.name,
                                    value:
                                      values[fieldDetails.confirmPassword.name],
                                    type: 'password',
                                    placeholder:
                                      fieldDetails.confirmPassword.label,
                                    errorClasses,
                                    setFieldValue,
                                    disabled
                                  })}
                              </span>
                              {passwordResetError && (
                                <Notification
                                  type="error"
                                  message={
                                    labels[passwordResetError] ||
                                    labels.passwordUpdateError
                                  }
                                  onDismiss={() => {
                                    dispatch(resetPasswordResetError())
                                  }}
                                />
                              )}
                              {passwordMismatchError && (
                                <Notification
                                  type="error"
                                  message={labels.passwordMismatch}
                                  onDismiss={() => {
                                    setPasswordMismatchError(false)
                                  }}
                                />
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    />
                  </div>
                  <div className="fullpage-submit-row">
                    {!passwordResetSuccess && (
                      <button
                        type="submit"
                        className="btn btn-primary large-primary-btn"
                        disabled={disabled}
                      >
                        {labels.update}
                      </button>
                    )}
                    {hasErrors && labels.genericDataEntryError !== '' && (
                      <div className="generic-error">
                        {labels.genericDataEntryError}
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

UpdatePasswordContainer.propTypes = {
  handleRouteChange: func.isRequired,
  emailToken: string.isRequired,
  languageCode: string.isRequired,
  handleToggleLanguage: func.isRequired
}

export default UpdatePasswordContainer
