import React from 'react'
import { string, bool, func, shape } from 'prop-types'
import moment from 'moment'
import yn from 'yn'
import dateFormats from '../../configuration/dateFormats'
import { useSelector } from 'react-redux'
import { selectLabels } from '../../redux/configuration/selectors'
import { formatPriceByLanguage } from '../../redux/configuration/utilities'
import { selectLanguage } from '../../redux/session/selectors'
const BookingTableRow = ({
  booking,
  handleViewDetailsClick,
  handleModifyBookingClick,
  handleCancelClick,
  isOpenBooking,
  isCommercial,
  couponText,
  openIncompleteBookingModal,
  openShouldCallModal,
  setBookingForPayment
}) => {
  const labels = useSelector(selectLabels)
  const lang = useSelector(selectLanguage)
  const {
    bookingNumber,
    createdDate,
    departureDate,
    bookingStatus,
    reservationTotals,
    paymentStatus,
    editButtonTextCode,
    canEditBooking,
    canCancelBooking,
    canViewBooking,
    cancelButtonTextCode,
    coupons,
    shouldCall,
    allowPaymentRetry
  } = booking

  const total = formatPriceByLanguage(reservationTotals?.totalWithoutPaid, lang)

  const CouponsRow = ({ couponText }) => (
    <tr className="special-details-row">
      <td>
        <div>
          <p className="u-remove-margin">
            {labels.couponApplied} {couponText}
          </p>
        </div>
      </td>
    </tr>
  )

  const CommercialBookingRow = () => (
    <tr className="special-details-row">
      <td>
        <div>
          <p className="u-remove-margin">{labels.commercialBooking}</p>
        </div>
      </td>
    </tr>
  )

  const DepartureDateCell = ({ isOpenBooking, departureDate }) => (
    <td className={isOpenBooking ? 'flagged-data' : ''}>
      {isOpenBooking ? (
        <div>
          <span className="u-remove-margin">{labels.openBooking}</span>
        </div>
      ) : (
        departureDate && moment(departureDate).format(dateFormats.default)
      )}
    </td>
  )

  const BookingStatusCell = ({
    showTooltipIcon,
    bookingStatusLabel,
    clickHandler
  }) => (
    <td>
      {showTooltipIcon ? (
        <div className="table-tooltip-container" onClick={clickHandler}>
          <span>{bookingStatusLabel}</span>
          <span className="u-tooltip-trigger">i</span>
        </div>
      ) : (
        bookingStatusLabel
      )}
    </td>
  )

  const bookingStatusClickHandler = () => {
    openIncompleteBookingModal(paymentStatus === 'bookingPaymentCodePartlyPaid')
  }

  const AllowPaymentFromAccount = ({ shouldHaveButton, clickHandler }) => {
    const allowOrNot = yn(
      process.env.REACT_APP_SHOULD_ALLOW_PAYMENT_FROM_ACCOUNTS_PAGE
    )
    if (!allowOrNot) return null

    return (
      <>
        {shouldHaveButton ? (
          <td className="clickable">
            <button className="btn btn-highlight" onClick={clickHandler}>
              {labels.pay}
            </button>
          </td>
        ) : (
          <td />
        )}
      </>
    )
  }

  const ViewBookingCell = ({ canViewBooking, clickHandler }) => (
    <td
      className={`clickable ${canViewBooking ? '' : 'disabled'}`}
      onClick={() => {
        if (canViewBooking) clickHandler()
      }}
    >
      {labels.view}
    </td>
  )

  const ModifyBookingCell = ({
    canEditBooking,
    shouldCall,
    editLabel,
    clickHandler
  }) => (
    <td
      className={`clickable ${canEditBooking ? '' : 'disabled'}`}
      onClick={() => {
        if (canEditBooking && !shouldCall) {
          clickHandler()
        }
      }}
    >
      {editLabel}
    </td>
  )

  const ShouldCallCell = ({ shouldCall, clickHandler }) => {
    if (!shouldCall) return null
    return (
      <td onClick={clickHandler}>
        <span className="u-tooltip-trigger">i</span>
      </td>
    )
  }

  return (
    <>
      {!!coupons?.length && <CouponsRow couponText={couponText} />}
      {isCommercial && <CommercialBookingRow />}
      <tr>
        {/* column: id */}
        <td>{bookingNumber}</td>
        {/* column: date */}
        <td>
          {createdDate && moment(createdDate).format(dateFormats.default)}
        </td>
        {/* column: departure date */}
        <DepartureDateCell
          isOpenBooking={isOpenBooking}
          departureDate={departureDate}
        />
        {/* column: total */}
        <td>{total && total}</td>
        {/* column: payment status */}
        <td>{labels[paymentStatus] || paymentStatus}</td>
        {/* column: booking status */}
        <BookingStatusCell
          showTooltipIcon={booking.showTooltipIcon}
          bookingStatusLabel={labels[bookingStatus]}
          clickHandler={bookingStatusClickHandler}
        />
        {/* column: allow payment from account */}
        <AllowPaymentFromAccount
          shouldHaveButton={
            allowPaymentRetry &&
            canEditBooking &&
            canCancelBooking &&
            !shouldCall
          }
          clickHandler={() => setBookingForPayment(booking)}
        />
        {/* column: view booking */}
        <ViewBookingCell
          canViewBooking={canViewBooking}
          clickHandler={() => handleViewDetailsClick(booking)}
        />
        {/* column: modify booking */}
        <ModifyBookingCell
          canEditBooking={canEditBooking}
          shouldCall={shouldCall}
          editLabel={labels[editButtonTextCode]}
          clickHandler={() => handleModifyBookingClick(booking)}
        />
        {/* column: cancel booking */}
        <ModifyBookingCell
          canEditBooking={canCancelBooking}
          editLabel={labels[cancelButtonTextCode]}
          clickHandler={() => handleCancelClick(booking)}
        />
        {/* column: should call */}
        <ShouldCallCell
          shouldCall={shouldCall}
          clickHandler={openShouldCallModal}
        />
      </tr>
    </>
  )
}

BookingTableRow.propTypes = {
  booking: shape().isRequired,
  handleViewDetailsClick: func.isRequired,
  handleModifyBookingClick: func.isRequired,
  handleCancelClick: func.isRequired,
  isOpenBooking: bool,
  isCommercial: bool,
  couponText: string,
  openIncompleteBookingModal: func.isRequired,
  openShouldCallModal: func.isRequired,
  openCustomErrorModal: func.isRequired,
  setBookingForPayment: func.isRequired
}

export default BookingTableRow
