import React from 'react'
import { string } from 'prop-types'
import './LinkWithTitle.scss'

const LinkWithTitle = ({ title, linkLabel, link }) => {
  return (
    <div className="link-with-title">
      <h6>{title}</h6>
      <a
        href={link}
        title={linkLabel}
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkLabel}
      </a>
    </div>
  )
}

LinkWithTitle.propTypes = {
  /**
   * The required title string
   */
  title: string.isRequired,
  /**
   * The required linkLabel string
   */
  linkLabel: string.isRequired,
  /**
   * The required link string
   */
  link: string.isRequired
}

export default LinkWithTitle
