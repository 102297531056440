import React, { useRef } from 'react'

import yesIcon from '../../../assets/icons/seat-selected-symbol.svg'
import noIcon from '../../../assets/icons/no-icon.svg'
import './ChooseSeatPopup.css'

const ChooseSeatPopup = ({
  feature,
  renderOnLeft,
  renderAbove,
  isSelected,
  onAddSeatClick,
  onRemoveSeatClick,
  labels
}) => {
  // use ref to get current popup element
  // this would make sure to get the top popup overlay
  // especially in case of picker co-exists with planner
  // other solution is to pass a selector to ChooseSeatPopup
  const popupElementRef = useRef(null)

  if (!feature) return null // nothing to render

  const seatNumber = feature.get('seatNumber') || '????'
  const wheelchair = feature.get('wheelchair')
  const acOutlet = feature.get('acOutlet')
  // const aisleSeat = feature.get('aisleSeat')
  // const windowSeat = feature.get('windowSeat')
  // const seatStorage = feature.get('seatStorage')
  // const legRoom = feature.get('legRoom')

  const bToYesNo = v =>
    v ? (
      <img src={yesIcon} className="yes-icon" alt="Yes" role="none" />
    ) : (
      <img src={noIcon} className="no-icon" alt="No" role="none" />
    )

  const seatNumberDisplay = seatNumber ? `${seatNumber}` : ''
  const popupLeftClass = renderOnLeft ? 'render-left' : ''
  const popupRightClass = renderAbove ? 'render-above' : ''
  const popupEndClass = `choose-seat ${popupLeftClass} ${popupRightClass}`

  const onCloseHandler = () => {
    const popupElement = popupElementRef.current
    const bubbleCloseEvent = new Event('close', { bubbles: true })
    // notify father to close me
    popupElement.dispatchEvent(bubbleCloseEvent)
  }

  function handleAddSeatClick() {
    onAddSeatClick(feature)
    onCloseHandler()
  }

  function handleRemoveSeatClick() {
    onRemoveSeatClick(feature)
    onCloseHandler()
  }

  return (
    <div className={popupEndClass} ref={popupElementRef}>
      {/* header */}
      <div className="seat-title-head">
        <h3 className="choose-seat-title">{`${labels.seat} ${seatNumberDisplay}`}</h3>
        <button
          type="button"
          className="close-button"
          onClick={onCloseHandler}
          aria-label={labels.close}
        >
          X
        </button>
      </div>
      {/* body */}
      <div className="popup-body">
        {/**
        <div className="choose-seat-info">
          {bToYesNo(aisleSeat)} <span>{labels.aisleSeat}</span>
        </div>
        <div className="choose-seat-info">
          {bToYesNo(windowSeat)} <span>{labels.windowSeat}</span>
        </div>
        **/}
        <div className="choose-seat-info">
          {bToYesNo(wheelchair)} <span>{labels.wheelchairAccessible}</span>
        </div>
        <div className="choose-seat-info">
          {bToYesNo(acOutlet)} <span>{labels.acOutlet}</span>
        </div>
      </div>
      {/* footer */}
      <div className="footer-row">
        {isSelected ? (
          <button
            tabIndex={-1}
            className="choose-seat-btn"
            onClick={handleRemoveSeatClick}
          >
            {labels.removeSeat}
          </button>
        ) : (
          <button
            tabIndex={-1}
            className="choose-seat-btn"
            onClick={handleAddSeatClick}
          >
            {labels.addSeat}
          </button>
        )}
      </div>
    </div>
  )
}

export default ChooseSeatPopup
