import {
  FETCH_PAYMENT_CONFIRMATION,
  PAYMENT_CONFIRMATION_ERROR,
  PAYMENT_CONFIRMATION_SUCCESS,
  CLEAR_PAYMENT_CONFIRMATION,
  SET_PAYMENT_FERRY_ID,
  SET_PAYPAL_PAYMENT_CONFIRMATION
} from './constants'
import { postPayment } from '../../api/api'
import { selectLabels } from '../configuration/selectors'
import { startSessionTimer } from '../session/actions'
import { selectSessionId } from '../session/selectors'
import { createAction } from 'redux-actions'
import { getCustomerBookings } from '../userBookings/actions'

export const setPaymentFerryId = createAction(SET_PAYMENT_FERRY_ID)

export const setPayPalPaymentConfirmation = createAction(
  SET_PAYPAL_PAYMENT_CONFIRMATION
)

export const fetchPaymentConfirmation = params => (dispatch, getState) => {
  dispatch({ type: FETCH_PAYMENT_CONFIRMATION })
  const state = getState()
  const labels = selectLabels(state)
  const sessionID = selectSessionId(state)
  const ticketNumber = state.bookingData?.preloadedTicketNumbers?.ticket

  dispatch(startSessionTimer()) // Refresh session on all api requests

  return postPayment({ ...params, ticketNumber }, sessionID)
    .then(res => {
      const data = res?.data?.['hydra:member']?.[0]

      if (data && data.paymentApplied) {
        // so our user's booking table updates.
        dispatch(getCustomerBookings())
        return dispatch({
          type: PAYMENT_CONFIRMATION_SUCCESS,
          payload: { ...data, ...params }
        })
      } else {
        return dispatch({
          type: PAYMENT_CONFIRMATION_ERROR,
          payload: labels.paymentDeclinedError
        })
      }
    })
    .catch(() => {
      dispatch({
        type: PAYMENT_CONFIRMATION_ERROR,
        payload: labels.paymentDeclinedError
      })
    })
}

export const clearPaymentConfirmation = createAction(CLEAR_PAYMENT_CONFIRMATION)
