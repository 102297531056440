import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { shape } from 'prop-types'
import { Formik, Form } from 'formik'

import ExpandableSection from '../ExpandableSection/ExpandableSection'
import ModalConsumer from '../../context/modalContext'
import Notification from '../Notification'
import RowGroup from '../RowGroup/RowGroup'
import { renderTextInput } from '../TextInput/TextInput'
import { formVariants } from '../LoginModal/constants'

import { getFormFieldsAndLayout } from '../../containers/views/accountUtilities'
import { resetUpdateSuccess, resetUpdateError } from '../../redux/user/actions'

import { selectLabels, selectFields } from '../../redux/configuration/selectors'
import { updateAgentAccount } from '../../redux/travelAgent/actions'
import {
  selectTravelAgentUpdateSuccess,
  selectTravelAgentUpdateError
} from '../../redux/travelAgent/selectors'
import {
  filterFieldsByName,
  renderFormFields,
  getSchemaFromFieldDetails
} from '../../redux/configuration/utilities'
import { selectSessionId } from '../../redux/session/selectors'

const TravelAgentForm = ({ agent }) => {
  const { agentFieldNames, agentFormLayout } = getFormFieldsAndLayout()
  const dispatch = useDispatch()
  const fields = useSelector(selectFields)
  const labels = useSelector(selectLabels)
  const sessionId = useSelector(selectSessionId)
  const fieldDetails = filterFieldsByName(fields, agentFieldNames)
  const updateSuccess = useSelector(selectTravelAgentUpdateSuccess)
  const updateError = useSelector(selectTravelAgentUpdateError)
  const [nameLengthError, setNameLengthError] = useState(null)
  const [passwordMismatchError, setPasswordMismatchError] = useState(false)
  const [minPhoneNumberError, setMinPhoneNumberError] = useState(false)

  const initialValues = {
    name: agent.name || '',
    initials: agent.initials || '',
    departmentCode: agent.departmentCode || '',
    workPhoneNumber: agent.workPhoneNumber || '',
    homePhoneNumber: agent.homePhoneNumber || '',
    mobilePhoneNumber: agent.mobilePhoneNumber || '',
    email: agent.email || '',
    password: '',
    confirmPassword: ''
  }
  const validationSchema = getSchemaFromFieldDetails(fieldDetails)
  const disabled = !agent

  const handleSubmit = formValues => {
    setPasswordMismatchError(false)
    setMinPhoneNumberError(false)
    let error = false
    const {
      password,
      confirmPassword,
      workPhoneNumber,
      homePhoneNumber,
      mobilePhoneNumber
    } = formValues
    const phoneValid = n => n && n.length >= 10

    if (password !== confirmPassword) {
      setPasswordMismatchError(true)
      error = true
    }

    if (
      !phoneValid(workPhoneNumber) ||
      !phoneValid(homePhoneNumber) ||
      !phoneValid(mobilePhoneNumber)
    ) {
      setMinPhoneNumberError(true)
      error = true
    }

    if (!error) {
      return dispatch(
        updateAgentAccount({
          sessionId,
          userName: agent.userName,
          ...formValues
        })
      )
    }
  }

  return (
    <div className="padded-container">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <ModalConsumer>
            {({ openModal }) => (
              <ExpandableSection
                labels={labels}
                title={labels.accountInformation}
                disabled={!agent}
                onClickDisabled={() =>
                  openModal({ type: formVariants.CUSTOMER_LOGIN })
                }
                showLabel={labels.showSection}
                hideLabel={labels.hideSection}
              >
                <Form>
                  <RowGroup
                    convertInputsToCaps
                    render={({ formRowClasses, errorClasses }) => (
                      <div className="row-group-container-bg row-group-container-no-title">
                        {renderFormFields({
                          formLayout: agentFormLayout,
                          fieldDetails,
                          values,
                          setFieldValue,
                          disabled,
                          formRowClasses,
                          errorClasses
                        })}
                        <h4 className="row-group-subtitle theme-font-header">
                          {labels.password}
                        </h4>
                        <span className={formRowClasses}>
                          {renderTextInput({
                            name: fieldDetails.password.name,
                            value: values[fieldDetails.password.name],
                            type: 'password',
                            placeholder: fieldDetails.password.label,
                            errorClasses,
                            setFieldValue,
                            disabled,
                            classNames: 'password',
                            inputHelpText: labels.passwordHelper
                          })}
                          {renderTextInput({
                            name: fieldDetails.confirmPassword.name,
                            value: values[fieldDetails.confirmPassword.name],
                            type: 'password',
                            placeholder: fieldDetails.confirmPassword.label,
                            errorClasses,
                            setFieldValue,
                            disabled,
                            classNames: 'password'
                          })}
                        </span>
                        {passwordMismatchError && (
                          <Notification
                            type="error"
                            message={labels.passwordMismatch}
                            onDismiss={() => {
                              setPasswordMismatchError(false)
                            }}
                          />
                        )}
                        {updateError && (
                          <Notification
                            type="error"
                            message={labels.accountUpdateError}
                            onDismiss={() => {
                              dispatch(resetUpdateError())
                            }}
                          />
                        )}
                        {minPhoneNumberError && (
                          <Notification
                            type="error"
                            message={labels.minPhoneNumberError}
                            onDismiss={() => {
                              setMinPhoneNumberError(false)
                            }}
                          />
                        )}
                        {updateSuccess && (
                          <Notification
                            type="success"
                            message={labels.accountUpdateSuccess}
                            onDismiss={() => {
                              dispatch(resetUpdateSuccess())
                            }}
                          />
                        )}
                        {nameLengthError && (
                          <Notification
                            type="error"
                            message={labels.combinedNameMaxLengthErrorMessage}
                            onDismiss={() => {
                              setNameLengthError(false)
                            }}
                          />
                        )}
                        <div className="fullpage-submit-row">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={disabled}
                          >
                            {labels.updateBtn}
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </Form>
              </ExpandableSection>
            )}
          </ModalConsumer>
        )}
      </Formik>
    </div>
  )
}

TravelAgentForm.propTypes = {
  agent: shape().isRequired
}

export default TravelAgentForm
