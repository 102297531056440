import React from 'react'
import { string } from 'prop-types'
import { useSelector } from 'react-redux'

import brandLoader from '../../assets/images/brand-loader.gif'
import { selectLabels } from '../../redux/configuration/selectors'
import './BrandLoader.scss'

const BrandLoader = props => {
  const { containerClasses, loaderSrc } = props
  const labels = useSelector(selectLabels)

  return (
    <div
      className={containerClasses ? containerClasses : 'u-brand-loader'}
      data-testid={'brand-loader'}
    >
      <img
        src={loaderSrc ? loaderSrc : brandLoader}
        alt={labels.brand}
        role="none"
      />
    </div>
  )
}

BrandLoader.propTypes = {
  containerClasses: string,
  loaderSrc: string
}

export default BrandLoader
