import React, { useState, useRef } from 'react'
import { func } from 'prop-types'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
// require explained here: https://momentjs.com/docs/#/use-it/browserify/
import moment from 'moment'

import { HeaderBreakdown, HeaderBreakdownToggle } from '..'
import ClickAwayListener from '../../ClickAwayListener/ClickAwayListener'
import ModifyBookingBanner from '../../ModifyBookingBanner/ModifyBookingBanner'
import dateFormats from '../../../configuration/dateFormats'
import { selectLabels } from '../../../redux/configuration/selectors'
import {
  selectDepartureDate,
  selectDepartureRoute,
  selectReturnDate,
  selectReturnRoute,
  selectActiveRouteForm
} from '../../../redux/ferryRouteSelections/selectors'
import { selectActiveCrossings } from '../../../redux/activeCrossings/selectors'
import { selectPricingBreakdown } from '../../../redux/selectors/selectPricingBreakdown'
import { selectActiveModifyBooking } from '../../../redux/modifyBooking/selectors'
import { selectBookingState } from '../../../redux/booking/selectors'
import { selectLanguage } from '../../../redux/session/selectors'
import './HeaderDetails.scss'
import modalIds from '../../../context/configuration'

const HeaderDetails = ({ openModal, hidePriceBreakdown }) => {
  const activeDepartureRoute = useSelector(selectDepartureRoute)
  const language = useSelector(selectLanguage)
  const activeReturnRoute = useSelector(selectReturnRoute)
  const departureDate = useSelector(selectDepartureDate)
  const returnDate = useSelector(selectReturnDate)
  const activeRouteForm = useSelector(selectActiveRouteForm)
  const labels = useSelector(selectLabels)
  const pricingBreakdown = useSelector(selectPricingBreakdown)
  const bookingInformation = useSelector(selectBookingState)
  const activeCrossingData = useSelector(selectActiveCrossings)
  const activeModifyBooking = useSelector(selectActiveModifyBooking)
  const tripTotal = pricingBreakdown?.paid
    ? pricingBreakdown.totalAfterPaid
    : pricingBreakdown.total
  const totalAfterDiscount = pricingBreakdown?.totalBeforeDiscount
  const discount = pricingBreakdown?.discount
  const activeCrossingDepartureDate = get(
    activeCrossingData,
    'departure.departureDate'
  )

  const activeCrossingReturnDate = get(
    activeCrossingData,
    'return.departureDate'
  )

  let departureDateDisplay = activeCrossingDepartureDate || departureDate
  let returnDateDisplay = activeCrossingReturnDate || returnDate

  const [priceBreakdownIsOpen, setPriceBreakdownIsOpen] = useState(false)

  const triggerRef = useRef(null)

  const localDepartureDate = moment(departureDateDisplay)
  const localReturnDate = moment(returnDateDisplay)
  localDepartureDate.locale(language)
  localReturnDate.locale(language)

  return (
    <div className={`header-details ${process.env.REACT_APP_FLOW_CLIENT_ID}`}>
      <div className="header-details__main">
        <div className="u-container header-container">
          <div className="header-details__col route-departure">
            <h6 className="route-title theme-title-variant">
              {labels.departure}
            </h6>
            <p>
              {labels[`${activeDepartureRoute?.code}Caption`] ||
                activeDepartureRoute?.description}
              <span className="route-separator"> | </span>
              {departureDateDisplay && (
                <span className="route-date-container">
                  <span className="route-date">
                    {' '}
                    {language === 'fr'
                      ? localDepartureDate.format(dateFormats.longDisplayFrench)
                      : localDepartureDate.format(dateFormats.longDisplay)}
                  </span>
                  <span className="route-date-mobile">
                    {' '}
                    {language === 'fr'
                      ? localDepartureDate.format(dateFormats.longDisplayFrench)
                      : localDepartureDate.format(
                          dateFormats.mobileLongDisplay
                        )}
                  </span>
                </span>
              )}
            </p>
          </div>

          {activeRouteForm === 'RT' && (
            <div className="header-details__col route-return">
              <h6 className="route-title theme-title-variant">
                {labels.return}
              </h6>
              <p>
                {labels[`${activeReturnRoute?.code}Caption`] ||
                  activeReturnRoute?.description}
                <span className="route-separator"> | </span>
                {returnDateDisplay && (
                  <span className="route-date-container">
                    <span className="route-date">
                      {' '}
                      {language === 'fr'
                        ? localReturnDate.format(dateFormats.longDisplayFrench)
                        : localReturnDate.format(dateFormats.longDisplay)}
                    </span>
                    <span className="route-date-mobile">
                      {' '}
                      {language === 'fr'
                        ? localReturnDate.format(dateFormats.longDisplayFrench)
                        : localReturnDate.format(dateFormats.mobileLongDisplay)}
                    </span>
                  </span>
                )}
              </p>
            </div>
          )}
          <HeaderBreakdownToggle
            isOpen={priceBreakdownIsOpen}
            setIsOpen={setPriceBreakdownIsOpen}
            tripTotal={bookingInformation?.data?.amountOwing || tripTotal}
            disabled={!pricingBreakdown.total || +pricingBreakdown.total === 0}
            ref={triggerRef}
            loading={pricingBreakdown && pricingBreakdown.loading}
            totalAfterDiscount={totalAfterDiscount}
            promoApplied={!!discount}
          />
          {priceBreakdownIsOpen && (
            <div className="details-breakdown">
              <ClickAwayListener
                toggleEvent={() => setPriceBreakdownIsOpen(false)}
                triggerRef={triggerRef}
              >
                <HeaderBreakdown
                  pricingBreakdown={pricingBreakdown}
                  overrideTotal={bookingInformation?.data?.amountOwing ?? null}
                />
              </ClickAwayListener>
            </div>
          )}
        </div>
      </div>
      <ModifyBookingBanner
        bookingNumberLabel={`${
          labels.modifyingBooking
        } #${activeModifyBooking?.bookingNumber || ''}`}
        myAccountLabel={labels.myAccountBtn || ''}
        onMyAccountClick={() =>
          openModal({ type: modalIds.continueToAccounts })
        }
        isDisplayed={!!activeModifyBooking}
      />
    </div>
  )
}

HeaderDetails.propTypes = {
  openModal: func.isRequired
}

export default HeaderDetails
