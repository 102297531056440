import React, { useState, useEffect } from 'react'

import infoIcon from '../../assets/icons/info-icon.svg'
import SeatPlanLegend from './SeatPlanLegend'

const SeatPlanLegendLayer = ({ closeLegend, labels }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (closeLegend) {
      setOpen(false)
    }
  }, [closeLegend])

  return (
    <div className="legend-overlay-on-map hidden-md-up full-size click-through">
      <button
        type="button"
        className="clickable icon-button legend-button-opener"
        onClick={() => setOpen(!open)}
        aria-label={labels.legend}
      >
        {!open && (
          <div className="legend-button-content">
            <img
              src={infoIcon}
              className="legend-button-icon"
              alt="info"
              role="none"
            />{' '}
            {labels.legend}
          </div>
        )}
        {open && <SeatPlanLegend labels={labels} />}
      </button>
    </div>
  )
}

export default SeatPlanLegendLayer
