import axios from 'axios'
import { get } from 'lodash'
import queryString from 'query-string'
import { HYDRA_MEMBER } from '../configuration/constants'
import {
  getHydraMemberDataType,
  recursiveFixApiPayload
} from '../configuration/utilities'
import { defaultHeaders, requiredEmptyUserStrings } from './constants'
import { FORCE_LOWERCASE_EMAIL } from '../configuration/constants'

// TOP LEVEL ENDPOINT TYPES
const CONFIG = '/api/config'
const CONTENT = '/api/content'
const FERRY = '/api/ferry'
const AUTH = '/api/auth'
const PAYMENT = '/api/payment'

const axiosInstance = axios.create({
  params: {
    clientId: process.env.REACT_APP_FLOW_CLIENT_ID
  }
})
const newEndpoint = string => `${process.env.REACT_APP_ENDPOINT_DOLLI}${string}`
const newChaseEndpoint = string =>
  `${process.env.REACT_APP_CHASE_HOST ||
    'https://chase.hostedpaymentservice.net'}${string}`

const endpoints = {
  availableSeating: newEndpoint(`${FERRY}/seats`),
  activeSession: newEndpoint(`${FERRY}/active-new-session`),
  agentUser: newEndpoint(`${FERRY}/agent-user`),
  booking: newEndpoint(`${FERRY}/booking`),
  configuration: newEndpoint(`${CONFIG}?`),
  content: newEndpoint(`${CONTENT}/ferry-sailing?`),
  crossing: newEndpoint(`${FERRY}/crossing`),
  coupon: newEndpoint(`${FERRY}/coupon`),
  customer: newEndpoint(`${FERRY}/customer`),
  customers: newEndpoint(`${FERRY}/customers`),
  passwordReset: newEndpoint(`${FERRY}/customer/password-reset`),
  payment: newEndpoint(`${FERRY}/booking/payment`),
  preloadTransaction: newEndpoint(`${FERRY}/booking/checkout/preload`),
  transactionReceipt: newEndpoint(`${FERRY}/booking/checkout/receipt`),
  telephoneBooking: newEndpoint(`${FERRY}/phone-booking`),
  findReservation: newEndpoint(`${FERRY}/bookings/reservation`),
  createToken: newEndpoint(`${AUTH}`),
  generateChaseUid: newEndpoint(`${PAYMENT}/generate`),
  generateChaseUidSource: newChaseEndpoint(`/api/init`),
  chaseReceipt: newEndpoint(`${PAYMENT}/query`)
}

export const getConfiguration = params =>
  Promise.all([
    axiosInstance.get(endpoints.configuration, {
      params: queryString.parse(params || '')
    }),
    axiosInstance.get(endpoints.content, {
      params: queryString.parse(params || '')
    })
  ])

export const createToken = () => {
  localStorage.removeItem('MAI_TOKEN')
  if (axiosInstance.defaults.headers.common['Authorization']) {
    delete axiosInstance.defaults.headers.common['Authorization']
  }
  return axiosInstance
    .post(
      endpoints.createToken,
      {},
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    .then(res => {
      const token = res?.data?.['hydra:member']?.[0]?.[0]?.token

      if (token) {
        axiosInstance.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${token}`
        localStorage.setItem('MAI_TOKEN', token)
      }
    })
}

//  POST /api/ferry/customers/cached?clientId=mai
export const checkToken = async ({ token }) => {
  try {
    const userRes = await axiosInstance.post(
      `${endpoints.customers}/cached`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {}
      }
    )
    const user = userRes?.data?.['hydra:member']?.[0]

    if (user) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
      localStorage.setItem('MAI_TOKEN', token)
    }

    return user
      ? {
          user
        }
      : null
  } catch (err) {
    // do nothing, it's fine
  }
}

export const isJWTTokenSet = () => {
  return !!axiosInstance.defaults.headers.common['Authorization']
}

export const getJWTToken = () => {
  return isJWTTokenSet ? localStorage.getItem('MAI_TOKEN') : null
}

export const crossing = params => {
  return axiosInstance.get(endpoints.crossing, { params })
}

export const getCustomerAccount = ({ emailAddress }) => {
  return axiosInstance.post(
    `${endpoints.customers}/search`,
    JSON.stringify({ identifier: emailAddress }),
    { headers: { ...defaultHeaders } }
  )
}

export const sendEmailConfirmation = ({ bookingNumber, emailAddress }) =>
  axiosInstance.put(
    `${endpoints.booking}/${bookingNumber}/send-email-confirmation`,
    JSON.stringify({
      sendConfirmationEmailParameters: {
        emailAddress: formatEmailParam(emailAddress)
      }
    }),
    { headers: { ...defaultHeaders } }
  )

export const getWebsiteStatus = ({ availabilityLink }) => {
  return axiosInstance.get(availabilityLink, { headers: { ...defaultHeaders } })
}

export const getActiveSessions = () => {
  return axiosInstance.get(`${endpoints.activeSession}`, {
    headers: { ...defaultHeaders }
  })
}

export const fetchCustomerBookings = ({ id, start, end }) => {
  const params = new URLSearchParams()

  if (start) {
    params.append('fromDate', start)
  }
  if (end) {
    params.append('toDate', end)
  }

  return axiosInstance.get(
    `${endpoints.customer}/${encodeURIComponent(
      id
    )}/bookings?${params.toString()}`,
    { headers: { ...defaultHeaders } }
  )
}

export const generateChaseUid = ({ ...params }) => {
  const urlParams = new URLSearchParams()
  urlParams.append('hotelProvider', 1)
  return axiosInstance.post(
    `${endpoints.generateChaseUid}?${urlParams.toString()}`,
    JSON.stringify({ createParameters: { ...params } }),
    {
      headers: { ...defaultHeaders }
    }
  )
}

export const generateChaseUidFromSource = params => {
  const urlParams = new URLSearchParams()
  urlParams.append('hotelProvider', 1)
  return axiosInstance.post(
    `${endpoints.generateChaseUidSource}?${urlParams.toString()}`,
    JSON.stringify(params),
    {
      headers: { ...defaultHeaders }
    }
  )
}

export const apiGenerateChaseUid = ({ ...params }) =>
  generateChaseUid({ ...params })
    .then(response => {
      const data = response?.data?.['hydra:member']?.[0]
      let result = { error: 'true', success: {} }
      if (data?.id) {
        return (result = { error: '', success: { uid: data.id, id: data.id } })
      } else {
        if (
          typeof data?.errors === 'object' &&
          Object.keys(data.errors).length > 0
        ) {
          if (data.errors?.order_no) {
            result.error = 'Duplicate Order'
          }

          if (data.errors?.txn_total) {
            result.error = 'Invalid params'
          }
        }
      }
      return result
    })
    .catch(err => {
      return { error: 'true', success: {} }
    })

export const getCouponInformation = ({ code, departureDate, returnDate }) => {
  const params = new URLSearchParams()
  params.append('departureDate', departureDate)
  if (returnDate) {
    params.append('returnDate', returnDate)
  }

  return axiosInstance.get(
    `${endpoints.coupon}/${encodeURIComponent(code)}?${params.toString()}`,
    {
      headers: { ...defaultHeaders }
    }
  )
}

export const travelAgentGetAccount = ({ userId }) =>
  axiosInstance.get(`${endpoints.agentUser}/${userId}`, {
    headers: { ...defaultHeaders }
  })

export const travelAgentModifyAccount = ({ userName, ...restValues }) =>
  axiosInstance.put(
    `${endpoints.agentUser}/${userName}/modify`,
    JSON.stringify({
      modifyParameters: [{ ...restValues }]
    }),
    { headers: { ...defaultHeaders } }
  )

export const travelAgentLogin = ({ userId, password }) => {
  const postBody = JSON.stringify({
    loginParameters: {
      id: userId,
      password: password
    }
  })

  return axiosInstance.post(`${endpoints.agentUser}/login`, postBody, {
    headers: { ...defaultHeaders }
  })
}

export const travelAgentGetBookings = ({ agentNumber, start, end }) => {
  const params = new URLSearchParams()

  if (start) {
    params.append('fromDate', start)
  }
  if (end) {
    params.append('toDate', end)
  }

  return axiosInstance.get(
    `${endpoints.agentUser}/${agentNumber}/bookings?${params.toString()}`,
    { headers: { ...defaultHeaders } }
  )
}

/**
 * login with POST method
 * for https://jira.verbinteractive.com/browse/CTM200-559
 *
 * @param {object}  object { emailAddress, password }
 * @returns Promise
 *
 * sample:
 * curl -X POST "https://http://dev-ferries.dolli.cloud/api/ferry/customer/login?clientId=ctma" \
 *  -H  "accept: application/ld+json" -H  "Content-Type: application/ld+json" \
 *  -d "{  \"loginParameters\": {    \"id\": \"username@domain.com\",    \"password\": \"%123456Test\"  }}"
 */
export const login = ({ emailAddress, password }) => {
  const postBody = JSON.stringify({
    loginParameters: {
      id: formatEmailParam(emailAddress),
      password: password
    }
  })
  return axiosInstance.post(`${endpoints.customer}/login`, postBody, {
    headers: { ...defaultHeaders }
  })
}

export const logout = () => createToken()

export const chaseTransactionReceipt = ({ uid }) =>
  axiosInstance.get(`${endpoints.chaseReceipt}/${uid}?hotelProvider=1`, {
    headers: { ...defaultHeaders }
  })

export const apiChaseTransactionReceipt = ({ uid }) =>
  chaseTransactionReceipt({ uid })
    .then(response => {
      const data = response?.data
      let result = { error: 'true', success: {} }

      if (data?.status === 'Success' && data?.id) {
        result = { error: '', success: { receipt: data, id: data.id } }
      }
      return result
    })
    .catch(() => ({ error: true, success: {} }))

export const bookingTransactionReceipt = (params = {}) =>
  axiosInstance.post(
    `${endpoints.transactionReceipt}`,
    JSON.stringify({ createParameters: { ...params } }),
    { headers: { ...defaultHeaders } }
  )

export const apiBookingTransactionReceipt = ({ ...params }) =>
  bookingTransactionReceipt({ ...params })
    .then(response => {
      const data = response?.data?.['hydra:member']?.[0]
      let result = { error: 'true', success: {} }

      if (data?.success === 'true' && data?.receipt && data?.id) {
        result = { error: '', success: { receipt: data.receipt, id: data.id } }
      }

      return result
    })
    .catch(() => ({ error: 'true', success: {} }))

export const preloadBookingTransaction = (params = {}) =>
  axiosInstance.post(
    `${endpoints.preloadTransaction}`,
    JSON.stringify({
      createParameters: {
        ...params
      }
    }),
    { headers: { ...defaultHeaders } }
  )

export const apiPreloadBookingTransaction = ({ ...params }) =>
  preloadBookingTransaction({ ...params })
    .then(response => {
      const data = response?.data?.['hydra:member']?.[0]
      let result = { error: 'true', success: {} }

      if (data?.success === 'true' && data?.ticket && data?.id) {
        result = { error: '', success: { ticket: data.ticket, id: data.id } }
      } else {
        if (
          typeof data?.errors === 'object' &&
          Object.keys(data.errors).length > 0
        ) {
          if (data.errors?.order_no) {
            result.error = 'Duplicate Order'
          }

          if (data.errors?.txn_total) {
            result.error = 'Invalid params'
          }
        }
      }

      return result
    })
    .catch(() => ({ error: 'true', success: {} }))

export const book = ({ params = {} }) =>
  axiosInstance.post(
    `${endpoints.booking}`,
    JSON.stringify({ createParameters: [{ ...params }] }),
    { headers: { ...defaultHeaders } }
  )

export const modifyBooking = ({ params = {}, modificationId }) =>
  axiosInstance.put(
    `${endpoints.booking}/${modificationId}/modify`,
    JSON.stringify({ modifyParameters: [{ ...params }] }),
    { headers: { ...defaultHeaders } }
  )

export const asyncModifyBooking = async (modifyDetails = {}) =>
  new Promise(resolve => {
    modifyBooking({ ...modifyDetails })
      .then(res => {
        const data = get(res, ['data', HYDRA_MEMBER, '0'])
        resolve({ ...data, success: true } || { success: false, data })
      })
      .catch(error => {
        resolve({ success: false, data: error.response })
      })
  })

export const cancelBooking = ({ id, params }) =>
  axiosInstance.put(
    `${endpoints.booking}/${id}/cancel`,
    JSON.stringify({ modifyParameters: [{ ...params }] }),
    { headers: { ...defaultHeaders } }
  )

export const register = (formValues = {}) => {
  return axiosInstance.post(
    `${endpoints.customer}`,
    JSON.stringify({ createParameters: [{ ...formValues }] }),
    { headers: { ...defaultHeaders } }
  )
}

export const apiRegister = ({ ...restValues }) => {
  const formattedEmailAddress = formatEmailParam(restValues.emailAddress)
  // check city and address
  recursiveFixApiPayload(restValues)

  return register({
    ...requiredEmptyUserStrings,
    ...restValues,
    emailAddress: formattedEmailAddress
  })
    .then(success => {
      if (restValues.emailAddress) {
        const emailAddressExpected = restValues.emailAddress
        const successData = getHydraMemberDataType(
          success,
          emailAddressExpected
        )

        if (successData && successData.customerAccount) {
          return { success: successData.customerAccount, error: false }
        }
      }

      return { error: true, success: false }
    })
    .catch(async errorResponse => {
      const customerExists = await checkCustomerAccountExists(
        formattedEmailAddress
      )

      if (customerExists) {
        return { errorKey: 'emailAddressAlreadyExists', success: false }
      }

      let errorKey = 'defaultRegisterUserError'
      if (
        errorResponse?.response?.status &&
        errorResponse.response.status.toString().startsWith('4')
      ) {
        const errorMessage =
          errorResponse?.response?.data?.['hydra:description']

        if (errorMessage.includes('E-mails already exists in Bookit!')) {
          errorKey = 'emailAddressAlreadyExists'
        }

        if (errorMessage.includes('The password is too short')) {
          errorKey = 'passwordTooShort'
        }
        if (errorMessage.includes('The password is too long')) {
          errorKey = 'passwordTooLong'
        }
        if (
          errorMessage.includes('The password does not match criteria') ||
          errorMessage.includes('Password is invalid!')
        ) {
          errorKey = 'passwordFailedCriteria'
        }
      }

      return { errorKey, success: false }
    })
}

export const modifyCustomer = ({ customerNumber, ...restValues }) => {
  return axiosInstance.put(
    `${endpoints.customer}/${customerNumber}/modify`,
    JSON.stringify({
      modifyParameters: [
        {
          ...requiredEmptyUserStrings,
          ...restValues,
          emailAddress: formatEmailParam(restValues.emailAddress)
        }
      ]
    }),
    { headers: { ...defaultHeaders } }
  )
}

export const sendResetPasswordEmail = ({ emailAddress, language, url }) =>
  axiosInstance.post(
    `${endpoints.passwordReset}`,
    JSON.stringify({
      createPasswordResetParameters: {
        email: formatEmailParam(emailAddress),
        url,
        locale: language
      }
    }),
    { headers: { ...defaultHeaders } }
  )

export const resetPassword = ({ password, emailToken }) =>
  axiosInstance.put(
    `${endpoints.passwordReset}/token/${emailToken}`,
    JSON.stringify({
      modifyPasswordResetParameters: {
        password
      }
    }),
    { headers: { ...defaultHeaders } }
  )

export const postPayment = params =>
  axiosInstance.post(
    `${endpoints.payment}`,
    JSON.stringify({ createParameters: [params] }),
    {
      headers: { ...defaultHeaders }
    }
  )

export const fetchPhoneBooking = (id, ferryId, token) => {
  const params = new URLSearchParams()
  if (ferryId) {
    params.append('ferryId', ferryId)
  }

  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    localStorage.setItem('MAI_TOKEN', token)
  }

  return axiosInstance.get(
    `${endpoints.telephoneBooking}/${id}?${params.toString()}`
  )
}

export const fetchCoPassengers = customerNumber => {
  return axiosInstance.get(
    `${endpoints.customers}/${customerNumber}/co-passengers`,
    { headers: { ...defaultHeaders } }
  )
}

export const postCoPassengers = async (coPassengers, customerNumber) => {
  const response = await axiosInstance.put(
    `${endpoints.customers}/${customerNumber}/co-passengers`,
    JSON.stringify({ coPassengers }),
    {
      headers: { ...defaultHeaders }
    }
  )

  const data = response?.data?.['hydra:member']?.[0]
  return data.coPassengers
}

// bookingNumbers is "Comma delimited list of booking numbers to retrieve"
export const fetchBookingsById = ({ ids }) => {
  return axiosInstance.get(`${endpoints.booking}?bookingNumbers=${ids}`)
}

const formatServerError = (caughtError, defaultError = {}) => {
  const result = { error: defaultError }

  if (caughtError?.response) {
    const { data, status } = caughtError.response

    if (data && status) {
      result.error.code = status

      if (data['hydra:title']) result.error.title = data['hydra:title']
      if (data['hydra:description']) {
        result.error.description = data['hydra:description']
      }
    }
  }

  return result
}

export const asyncPostBooking = ({ params }) => {
  params.billingInfo = {
    ...params.billingInfo,
    email: formatEmailParam(params.billingInfo.email)
  }
  // check city and address
  recursiveFixApiPayload(params)

  return new Promise(resolve => {
    let result = { success: false, successData: {}, error: {} }

    book({ params })
      .then(res => {
        const data = get(res, ['data', HYDRA_MEMBER, '0'])

        if (data?.bookingNumber) {
          const { bookingNumber, address, ...restSuccessData } = data
          result.success = true
          // dont store phony data or bookingNumber
          result.successData =
            bookingNumber === '-1' ? { ...restSuccessData } : { ...data }
        }
      })
      .catch(caughtError => {
        result = { ...result, ...formatServerError(caughtError) }
      })
      .then(() => {
        // Note: always executed
        resolve(result)
      })
  })
}

export const asyncPostModifyBooking = ({
  params,
  modificationId,
  commitChanges
}) => {
  params.billingInfo = {
    ...params.billingInfo,
    email: formatEmailParam(params.billingInfo.email)
  }

  // check city and address
  recursiveFixApiPayload(params)

  return new Promise(resolve => {
    let result = { success: false, successData: {}, error: {} }

    modifyBooking({
      params,
      modificationId
    })
      .then(res => {
        const data = get(res, ['data', HYDRA_MEMBER, '0'])
        if (data) {
          result.success = true
          result.successData = {
            ...data,
            isModification: true,
            modificationId,
            commitChanges
          }
        }
      })
      .catch(caughtError => {
        result = { ...result, ...formatServerError(caughtError) }
      })
      .then(() => {
        // Note: always executed
        resolve(result)
      })
  })
}

export const findReservation = ({
  email = '',
  reservationId,
  workPhoneNumber = ''
}) => {
  const params = {
    reservationId,
    workPhoneNumber,
    email
  }

  return axiosInstance.post(
    `${endpoints.findReservation}`,

    JSON.stringify(params),
    {
      headers: { ...defaultHeaders }
    }
  )
}

export const getAvailableSeating = ({
  supplierCode,
  resourceCode,
  startDate,
  startTime,
  productCode,
  passengerType,
  primaryLangId
}) => {
  const queryParams = queryString.stringify({
    supplierCode,
    resourceCode,
    startDate,
    startTime,
    productCode,
    passengerType,
    primaryLangId
  })
  return axiosInstance.get(`${endpoints.availableSeating}?${queryParams}`, {
    headers: { ...defaultHeaders }
  })
}

// Local functions
async function checkCustomerAccountExists(emailAddress) {
  const formattedEmail = formatEmailParam(emailAddress)
  const searchResponse = await getCustomerAccount({
    emailAddress: formattedEmail
  })
  return searchResponse.data['hydra:member'][0].id === 200
}

function formatEmailParam(email = '') {
  return FORCE_LOWERCASE_EMAIL ? email.toLowerCase() : email
}

export default endpoints
