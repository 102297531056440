import React, { useEffect, useState } from 'react'
import { shape, func } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'
import yn from 'yn'
import moment from 'moment'
import queryString from 'query-string'
// ************ CONSTANTS *************
import {
  defaultRouteImage,
  defaultRouteImageFrench,
  ModifyFlowType
} from '../../configuration/constants'
import appRoutes from '../../configuration/appRoutes'
// ************ ACTIONS *************
import {
  setDepartureDate,
  setDepartureRoute,
  setReturnDate,
  setReturnRoute,
  setRouteForm
} from '../../redux/ferryRouteSelections/actions'
import {
  fetchCrossing,
  fetchCrossingsWithParams
} from '../../redux/crossings/actions'
import * as UserSelectionActions from '../../redux/userSelections/actions'
// *********** COMPONENTS ***********
import ferryRoutesForms from '../../configuration/ferryRoutesForms'
import FerryRoutesFormsContainer from '../../components/ferryRoutes/FerryRoutesFormsContainer'
// ********* SELECTORS **************
import {
  selectConfigurationData,
  selectLabels,
  selectRoutesContent
} from '../../redux/configuration/selectors'
import {
  selectActiveRouteForm,
  selectDepartureDate,
  selectDepartureRoute,
  selectReturnDate,
  selectReturnRoute
} from '../../redux/ferryRouteSelections/selectors'
import { selectModifyFlowType } from '../../redux/modifyBooking/selectors'
import { selectTravelAgentAccount } from '../../redux/travelAgent/selectors'
import { selectCustomerAccount } from '../../redux/user/selectors'
import selectFerryRoutesModifyPrefillData from '../../redux/selectors/selectFerryRoutesModifyPrefillData'
import { selectInitialFetchCrossingsParams } from '../../redux/refactor/selectFetchCrossingsParams'
import { selectLanguage } from '../../redux/session/selectors'
import { selectUserSelections } from '../../redux/userSelections/selectors'
// *********** UTILITIES ************
import { isValidTripType } from '../../redux/vehicleSelections/utilities'
import filterGroupRoutes from '../../utilities/content/filterGroupRoutes'
// *********** STYLES ***************
import '../../styles/scss/components/ferryRoutes/ferry-routes.scss'
import '../../styles/scss/components/ferryRoutes/ferry-routes-form-defaults.scss'

const FerryRoutesContainer = ({
  handleRouteChange,
  openModal,
  closeModal,
  modalIsOpen
}) => {
  const dispatch = useDispatch()
  const queryParams = queryString.parse(window.location.search)
  const VIEW_SCHEDULE_LINK = process.env.REACT_APP_VIEW_CROSSING_SCHEDULE_LINK

  const departureRoute = useSelector(selectDepartureRoute)
  const returnRoute = useSelector(selectReturnRoute)
  const configuration = useSelector(selectConfigurationData)
  const activeRouteForm = useSelector(selectActiveRouteForm)
  const travelAgentAccount = useSelector(selectTravelAgentAccount)
  const customerAccount = useSelector(selectCustomerAccount)
  const routeContent = useSelector(selectRoutesContent)
  const labels = useSelector(selectLabels)
  const modifyFlowType = useSelector(selectModifyFlowType)
  const modifyPrefillData = useSelector(selectFerryRoutesModifyPrefillData)
  const fetchCrossingsParams = useSelector(selectInitialFetchCrossingsParams)
  const language = useSelector(selectLanguage)
  const userSelections = useSelector(selectUserSelections)

  const [availabilityErrors, setAvailabilityErrors] = useState(null)
  const [crossingsLoading, setCrossingsLoading] = useState(false)
  const [activeRouteGrouping, setActiveRouteGrouping] = useState(null)
  const tripTypeParam = get(queryParams, 'tt')

  const departureDate = useSelector(selectDepartureDate)
  const returnDate = useSelector(selectReturnDate)
  useEffect(() => {
    try {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'analytics-initial-1',
        details: userSelections
      })
    } catch (err) {
      console.log('Analytics error: ' + err)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      !isEmpty(tripTypeParam) &&
      isValidTripType(tripTypeParam.toUpperCase())
    ) {
      dispatch(setRouteForm(tripTypeParam.toUpperCase()))
      dispatch(UserSelectionActions.setTripType(tripTypeParam.toUpperCase()))
    }
  }, [tripTypeParam, dispatch])

  useEffect(() => setAvailabilityErrors(null), [departureDate])
  useEffect(() => setAvailabilityErrors(null), [returnDate])

  // pre-filling the form if we have an active modifying booking in state
  useEffect(() => {
    if (modifyPrefillData) {
      dispatch(setRouteForm(modifyPrefillData.tripType))
      dispatch(UserSelectionActions.setTripType(modifyPrefillData.tripType))

      if (modifyPrefillData.departureRoute) {
        dispatch(setDepartureRoute(modifyPrefillData.departureRoute))
        dispatch(
          UserSelectionActions.setDepartureRoute(
            modifyPrefillData.departureRoute.code
          )
        )
        setActiveRouteGrouping(modifyPrefillData.ferryId)
      }

      if (modifyPrefillData.returnRoute) {
        dispatch(setReturnRoute(modifyPrefillData.returnRoute))
        dispatch(
          UserSelectionActions.setReturnRoute(
            modifyPrefillData.returnRoute.code
          )
        )
      } else {
        dispatch(setReturnRoute({}))
        dispatch(UserSelectionActions.setReturnRoute(null))
      }

      if (modifyPrefillData.departureDate) {
        const departureMoment = moment(modifyPrefillData.departureDate)
        dispatch(setDepartureDate(departureMoment))
        dispatch(
          UserSelectionActions.setDepartureSearchDate(
            departureMoment.format('YYYY-MM-DD')
          )
        )
      }

      if (modifyPrefillData.returnDate) {
        const returnMoment = moment(modifyPrefillData.returnDate)
        dispatch(setReturnDate(moment(modifyPrefillData.returnDate)))
        dispatch(
          UserSelectionActions.setReturnSearchDate(
            returnMoment.format('YYYY-MM-DD')
          )
        )
      } else {
        dispatch(setReturnDate(null))
        dispatch(UserSelectionActions.setReturnSearchDate(null))
      }
    }
  }, [dispatch, modifyPrefillData])

  const getDepartureErrorNode = () => {
    let departureErrorNode = <span>{labels.noDepartureCrossingsAvailable}</span>

    // MAI Specific
    if (VIEW_SCHEDULE_LINK) {
      departureErrorNode = (
        <span>
          {`${labels.noDepartureCrossingsAvailable || ''} `}
          <a
            href={VIEW_SCHEDULE_LINK}
            title={labels.viewSailingSchedule || ''}
            target="_blank"
            rel="noopener noreferrer"
          >
            {labels.viewSailingSchedule || ''}
          </a>
          {` ${labels.sailingListTimes || ''}`}
        </span>
      )
    }

    return departureErrorNode
  }

  const getReturnErrorNode = () => {
    let returnErrorNode = <span>{labels.noReturnCrossingsAvailable}</span>
    // MAI Specific
    if (VIEW_SCHEDULE_LINK) {
      returnErrorNode = (
        <span>
          {`${labels.noCrossingsSurroundingReturn || ''} `}
          <a
            href={VIEW_SCHEDULE_LINK}
            title={labels.viewOurSchedule || ''}
            target="_blank"
            rel="noopener noreferrer"
          >
            {labels.viewOurSchedule || ''}
          </a>
          {` ${labels.forACompleteListOfSailings || ''}`}
        </span>
      )
    }

    return returnErrorNode
  }

  const getErrorNodesByClient = (crossingErrors = {}) => {
    const { departureError, returnError } = crossingErrors
    let result = []
    let departureErrorNode = departureError ? getDepartureErrorNode() : null
    let returnErrorNode = returnError ? getReturnErrorNode() : null
    if (departureErrorNode) result.push(departureErrorNode)
    if (returnErrorNode) result.push(returnErrorNode)

    // In MAI theme, if there are two errors, use a single combined error
    if (result.length === 2) {
      result = []
      result.push(
        <span>
          {`${labels.noCrossingsAvailable || ''} `}
          <a
            href={VIEW_SCHEDULE_LINK}
            title={labels.viewSailingSchedule || ''}
            target="_blank"
            rel="noopener noreferrer"
          >
            {labels.viewSailingSchedule || ''}
          </a>
          {` ${labels.sailingListTimes || ''}`}
        </span>
      )
    }

    return result
  }

  const determineErrorsOnSubmit = ({
    departureCrossings = {},
    returnCrossings = {},
    isReturnTrip
  }) =>
    getErrorNodesByClient({
      departureError: !Object.keys(departureCrossings).length,
      returnError: isReturnTrip && !Object.keys(returnCrossings).length
    })

  const getCrossingData = async (direction = '') => {
    const options = {
      fetchAllPassengerTypes: true,
      validationKey:
        direction === 'departure'
          ? 'ferryRoutesDepartureCrossing'
          : 'ferryRoutesReturnCrossing'
    }
    const responseData = await dispatch(fetchCrossing(direction, options))

    // NOTE(ebarrett): Will eventually replace fetchCrossing call above
    const { departureParams, returnParams } = fetchCrossingsParams
    const params = direction === 'departure' ? departureParams : returnParams
    dispatch(fetchCrossingsWithParams(params, direction, options))

    return responseData.isSuccess
      ? get(responseData, 'payload.crossings', {})
      : {}
  }

  const getReturnCrossingResponse = async () => {
    let result
    if (activeRouteForm === 'RT') result = await getCrossingData('return')
    return result
  }

  const submit = async () => {
    setCrossingsLoading(true)
    setAvailabilityErrors(null)

    const departureResponse = await getCrossingData('departure')
    const returnResponse = await getReturnCrossingResponse()
    const errors = determineErrorsOnSubmit({
      departureCrossings: departureResponse,
      returnCrossings: returnResponse,
      isReturnTrip: activeRouteForm === 'RT'
    })

    if (errors.length > 0) {
      setCrossingsLoading(false)
      setAvailabilityErrors(errors)
    } else {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'analytics-routes-1',
        details: userSelections
      })

      handleRouteChange(appRoutes.passengerQuantities.pathname, {
        isFromRouteSelection: true
      })
    }
  }

  let ferryRoutes = []
  let isPairRoutes = false

  if (configuration?.ferry) {
    if (
      configuration.ferry.options &&
      !isEmpty(configuration.ferry.options.routes)
    ) {
      if (
        yn(process.env.REACT_APP_COMPONENT_ENABLE_ROUTE_GROUPINGS) &&
        activeRouteGrouping
      ) {
        ferryRoutes = filterGroupRoutes(
          configuration.ferry.options.routes,
          activeRouteGrouping
        )
      } else {
        ferryRoutes = configuration.ferry.options.routes
      }
    }

    if (!isEmpty(ferryRoutes) && ferryRoutes.length === 2) {
      isPairRoutes = true
    }
  }

  let renderFormToggles = []
  let routeFormElement = null

  const activeRouteContent =
    departureRoute && departureRoute.code && routeContent?.length
      ? routeContent.find(route => route.code === departureRoute.code)
      : null

  for (const formKey in ferryRoutesForms) {
    const currentForm = ferryRoutesForms[formKey]

    if (currentForm.labelKey && currentForm.type && currentForm.component) {
      if (modifyFlowType !== ModifyFlowType.DEPARTURE_LOCKED) {
        renderFormToggles.push(
          <div
            key={'form-toggle-' + currentForm.type}
            className={`theme-title-variant-six ferry-routes__form-type-toggle${
              currentForm.type === activeRouteForm ? ' active' : ''
            }`}
            onClick={() => {
              dispatch(setRouteForm(currentForm.type))
              dispatch(setDepartureRoute(departureRoute))
              dispatch(setReturnRoute(returnRoute))
              dispatch(UserSelectionActions.setTripType(currentForm.type))
              dispatch(
                UserSelectionActions.setDepartureRoute(departureRoute.code)
              )
              dispatch(UserSelectionActions.setReturnRoute(returnRoute?.code))
            }}
            tabIndex={0}
          >
            <p className="theme-title-variant-six">
              {labels[currentForm.labelKey]}
            </p>
          </div>
        )
      }

      if (currentForm.type === activeRouteForm) {
        routeFormElement = (
          <currentForm.component
            openModal={openModal}
            closeModal={closeModal}
            modalIsOpen={modalIsOpen}
            ferryRoutes={ferryRoutes}
            isPairRoutes={isPairRoutes}
            submit={submit}
            availabilityErrors={availabilityErrors}
            enableRouteSelection={
              modifyFlowType !== ModifyFlowType.DEPARTURE_LOCKED
            }
          />
        )
      }
    }
  }
  // FIXME: beautiful image desired if no default route selected
  let routeMap = language === 'fr' ? defaultRouteImageFrench : defaultRouteImage

  if (
    configuration?.ferry?.options?.routes.length &&
    departureRoute &&
    departureRoute.code
  ) {
    routeMap = activeRouteContent
      ? get(activeRouteContent, 'routeMapImage.path', defaultRouteImage)
      : defaultRouteImage
  }

  const formsContainerCommonProps = {
    crossingsLoading,
    customerAccount,
    travelAgentAccount,
    labels,
    openModal,
    renderFormToggles,
    routeFormElement,
    routeMap,
    handleRouteChange,
    modifyFlowType
  }
  return (
    <div className="ferry-routes">
      <FerryRoutesFormsContainer {...formsContainerCommonProps} />
    </div>
  )
}

FerryRoutesContainer.propTypes = {
  handleRouteChange: func.isRequired,
  openModal: func.isRequired,
  closeModal: func.isRequired,
  modalIsOpen: shape().isRequired
}

export default FerryRoutesContainer
