import { createSelector } from 'reselect'
import { selectActiveModifyBooking } from '../modifyBooking/selectors'
import { selectActiveRouteForm } from '../ferryRouteSelections/selectors'
import {
  tripTypes,
  vehicleSections,
  vehicleTypes
} from '../vehicleSelections/constants'
import { vehicleSelectionsState } from '../vehicleSelections/selectors'

const selectVehicleFormType = createSelector(
  vehicleSelectionsState,
  selectActiveModifyBooking,
  selectActiveRouteForm,
  (vehicleSelections, activeModifyBooking, activeRouteForm) => {
    // no booking in modification
    let result = vehicleSelections[vehicleSections.FORM_TYPE]
    if (!activeModifyBooking) return result
    // process booking modification vehicle selection restore
    const departureVehicleCode = activeModifyBooking?.departureVehicleCode
    const returnVehicleCode = activeModifyBooking?.returnVehicleCode
    if (departureVehicleCode || returnVehicleCode) {
      // first check for VEH-DUP/VEH-UNQ/VEH-OWY
      if (
        activeModifyBooking.isReturnTrip &&
        activeRouteForm !== tripTypes.OW
      ) {
        result = activeModifyBooking.sameVehicleBothDirection
          ? vehicleTypes.VEHDUP
          : vehicleTypes.VEHUNQ
      } else {
        result = vehicleTypes.VEHOWY
      }
      // check again for VEH-WLK/VELO
      const vehicleTypeCode = departureVehicleCode || returnVehicleCode
      const noVechileOptions = [vehicleTypes.VEHWLK, vehicleTypes.VELO]
      if (noVechileOptions.includes(vehicleTypeCode)) {
        result = vehicleTypeCode
      }
    }
    return result
  }
)

export default selectVehicleFormType
