import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import FindReservationForm from '../../components/ModifyLoginForms/FindReservationForm'
import NormalLoginForm from '../../components/ModifyLoginForms/NormalLoginForm'
import { selectLabels } from '../../redux/configuration/selectors'
import { selectSessionId } from '../../redux/session/selectors'
import {
  selectLoginSuccess,
  selectLoginError,
  selectFindReservationSuccess,
  selectFindReservationError,
  selectFindReservationDetails
} from '../../redux/user/selectors'
import {
  loginUser,
  findUserReservation,
  findReservationReset
} from '../../redux/user/actions'
import { account, ferryRoutes } from '../../configuration/appRoutes'
import { setActiveModificationBooking } from '../../redux/modifyBooking/actions'
import * as UserSelectionActions from '../../redux/userSelections/actions'
import { tranformBookingObject } from '../../utilities/bookings/transformBookingsArray'

import '../../styles/scss/components/booking-modify/booking-modify.scss'

const ModifyLoginContainer = ({ handleRouteChange }) => {
  const dispatch = useDispatch()
  const submitCallbackRef = useRef()
  const labels = useSelector(selectLabels)
  const sessionId = useSelector(selectSessionId)
  const customerLoginSuccess = useSelector(selectLoginSuccess)
  const customerLoginError = useSelector(selectLoginError)
  const findReservationSuccess = useSelector(selectFindReservationSuccess)
  const findReservationError = useSelector(selectFindReservationError)
  const reservationDetails = useSelector(selectFindReservationDetails)

  // processing header resize
  useEffect(() => {
    const cssSelector = '.header .header__main .u-container'
    const headerUContainer = document.querySelector(cssSelector)
    headerUContainer.classList.add('py-12')
  }, [])

  // reset find reservation state
  useEffect(() => {
    dispatch(findReservationReset())
  }, [dispatch])

  // processing login success
  useEffect(() => {
    if (!customerLoginSuccess) return
    // hide loading
    const setSubmitting = submitCallbackRef.current
    if (typeof setSubmitting === 'function') setSubmitting(false)

    // login success, and redirect...
    handleRouteChange(account.pathname)
  }, [customerLoginSuccess, handleRouteChange])

  // processing fetch data error
  useEffect(() => {
    if (!customerLoginError && !findReservationError) return
    // hide loading
    const setSubmitting = submitCallbackRef.current
    if (typeof setSubmitting === 'function') setSubmitting(false)
  }, [customerLoginError, findReservationError])

  // processing find reservation success
  useEffect(() => {
    if (!findReservationSuccess) return

    // hide loading
    const setSubmitting = submitCallbackRef.current
    if (typeof setSubmitting === 'function') setSubmitting(false)

    if (!reservationDetails) return
    // convert reservation data to modifyBooking,
    // and redirect to ferries page
    const bookingDetails = tranformBookingObject(reservationDetails)
    // TODO: deprecate
    dispatch(setActiveModificationBooking(bookingDetails))
    dispatch(
      UserSelectionActions.setModifyBookingNumber(bookingDetails.bookingNumber)
    )
    handleRouteChange(ferryRoutes.pathname)
  }, [findReservationSuccess, reservationDetails, dispatch, handleRouteChange])

  const loginNow = (values, { setSubmitting }) => {
    dispatch(loginUser({ ...values }, sessionId))
    // cache callback
    submitCallbackRef.current = setSubmitting
  }

  const findReservationNow = (values, { setSubmitting }) => {
    dispatch(findUserReservation({ ...values }, sessionId))
    // cache callback
    submitCallbackRef.current = setSubmitting
  }

  return (
    <div className="modify-login page-container u-page-view-container">
      <div className="dark-blue-header" />
      <div className="relative-form-container">
        <h1 className="page-title bold">{labels.modifyExistingReservation}</h1>
        <div className="form-row">
          <div className="half-form left">
            <h2 className="form-title text-center bold">{labels.guestUsers}</h2>
            {/* find reservation form */}
            <FindReservationForm
              findHandler={findReservationNow}
              findErrorMessage={findReservationError}
            />
          </div>
          <div className="half-form">
            <h2 className="form-title text-center bold">
              {labels.iHaveAccount}
            </h2>
            {/* login form */}
            <NormalLoginForm
              loginHandler={loginNow}
              loginError={customerLoginError ? labels.loginUserError : ''}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModifyLoginContainer
