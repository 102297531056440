import { PHONE_FIELDS } from '../../redux/configuration/constants'

export const getFormFieldsAndLayout = () => {
  const whenDifferentAccount = { when: { accountSameAsBooking: false } }
  const allFieldNames = [
    'accountSameAsBooking',
    'title',
    'firstName',
    'lastName',
    'address',
    'city',
    'countryCode',
    'county',
    'postalCode',
    'workPhoneNumber',
    'mobilePhoneNumber',
    'emailAddress',
    'emailAddressConfirm',
    'language',
    'passengerType',
    'gender',
    'acceptsContact',
    'password',
    'confirmPassword'
  ]

  const mainLayout = [
    ['language', 'passengerType', 'gender'],
    ['acceptsContact']
  ]

  // optional if !accountSameAsBooking
  const contactRows = PHONE_FIELDS.useSplitLayout
    ? [
        ['mobilePhoneNumber', 'workPhoneNumber'],
        ['emailAddress', 'emailAddressConfirm']
      ]
    : [
        [
          'mobilePhoneNumber',
          'workPhoneNumber',
          'emailAddress',
          'emailAddressConfirm'
        ]
      ]
  const optionalLayout = [
    ['title', 'firstName', 'lastName'],
    ['address', 'city'],
    ['countryCode', 'county', 'postalCode'],
    ...contactRows
  ]

  const conditionallyIncludedRequiredFields = {
    title: whenDifferentAccount,
    firstName: whenDifferentAccount,
    lastName: whenDifferentAccount,
    address: whenDifferentAccount,
    city: whenDifferentAccount,
    countryCode: whenDifferentAccount,
    county: whenDifferentAccount,
    postalCode: whenDifferentAccount,
    mobilePhoneNumber: whenDifferentAccount,
    workPhoneNumber: whenDifferentAccount,
    emailAddress: whenDifferentAccount,
    emailAddressConfirm: whenDifferentAccount
  }

  const keysToRemove = PHONE_FIELDS.all.reduce((acc, phoneField) => {
    if (!PHONE_FIELDS.client.includes(phoneField)) {
      acc[phoneField] = true
    }
    return acc
  }, {})

  switch (process.env.REACT_APP_FLOW_CLIENT_ID) {
    default:
      keysToRemove.title = true
      break
  }

  const filterKeys = arr => arr.filter(x => !keysToRemove[x])

  return {
    fieldNames: filterKeys(allFieldNames),
    mainLayout: mainLayout.map(row => filterKeys(row)),
    optionalLayout: optionalLayout.map(row => filterKeys(row)),
    conditionallyIncludedRequiredFields: Object.keys(
      conditionallyIncludedRequiredFields
    ).reduce((acc, fieldName) => {
      if (!keysToRemove[fieldName]) {
        acc[fieldName] = conditionallyIncludedRequiredFields[fieldName]
      }
      return acc
    }, {})
  }
}
