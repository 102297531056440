import { getLabelFromOptionValue } from '../../configuration/utilities'

/*
  NOTE(ebarrett):
    Passenger fields have a lot variability depending on client and route,
    and unfortunately the layout needs to be explicitly defined (Formik doesn't
    have hints about laying fields out intelligently).

    We were encountering some bugs using the `keysToRemove` approach for pulling
    items out of the layout. I've tried to capture the rules in a more
    straight-forward manner here below. Please update the rules if additional fields
    are added 

    1. MAI does not show accessibility fields
    2. Only CAT collects passport fields
    3. Only BFL collects dateOfBirth [without passport]

    So this gives us the following layouts...
      - Default layout (fallback)
      - MAI layout
      - CAT layout
      - BFL layout
*/

function getMAILayout() {
  return {
    fieldNames: [
      'type', // Used to build labels for form
      'passengerType', // Used to build labels for form
      'title',
      'firstName',
      'lastName',
      'gender'
    ],
    mainLayout: [['title', 'firstName', 'lastName', 'gender']],
    optionalLayout: [],
    conditionallyIncludedRequiredFields: {},
    conditionalTests: {}
  }
}

export const getFieldsAndLayout = ({ paymentFerryId }) => {
  return getMAILayout()
}

export const getPassengerLabel = ({
  fieldDetails,
  passenger,
  duplicateQuantities,
  labels
}) => {
  const passengerType = getLabelFromOptionValue(
    passenger?.passengerType,
    fieldDetails?.passengerType?.options
  )
  let tripTypePrefix

  if (passenger?.type) {
    if (passenger.type === 'departures' && labels.departureRoute) {
      tripTypePrefix = labels.departureRoute
    }
    if (passenger.type === 'returns' && labels.returnRoute) {
      tripTypePrefix = labels.returnRoute
    }
  }

  return tripTypePrefix && passengerType
    ? `${tripTypePrefix} - ${passengerType}`
    : ''
}
