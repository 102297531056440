import React from 'react'
import './LoadingSpinner.scss'

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export const MiniSpinner = () => (
  <div className="loading-spinner mini">
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
)

export default LoadingSpinner
