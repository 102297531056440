import { createAction } from 'redux-actions'
import {
  SET_ACTIVE_ACCOMMODATION,
  ADD_ACTIVE_ACCOMMODATIONS,
  RESET_ACTIVE_ACCOMMODATION,
  SET_ACTIVE_RETURN_ACCOMMODATIONS,
  SET_ACTIVE_DEPARTURE_ACCOMMODATIONS
} from './constants'

export const setActiveAccommodation = createAction(SET_ACTIVE_ACCOMMODATION)
export const addActiveAccommodations = createAction(ADD_ACTIVE_ACCOMMODATIONS)
export const resetActiveAccommodations = createAction(
  RESET_ACTIVE_ACCOMMODATION
)

export const setActiveDepartureAccommodations = createAction(
  SET_ACTIVE_DEPARTURE_ACCOMMODATIONS
)
export const setActiveReturnAccommodations = createAction(
  SET_ACTIVE_RETURN_ACCOMMODATIONS
)
