import React from 'react'
import moment from 'moment'
import { formatPriceByLanguage } from '../../redux/configuration/utilities'
import BookingConfirmationTable from '../BookingConfirmation/BookingConfirmationTable'
import BookingReminders from '../BookingReminders/BookingReminders'
import LinkWithTitle from '../LinkWithTitle/LinkWithTitle'
import { clearActiveModifyBooking } from '../../redux/modifyBooking/actions'
import appRoutes from '../../configuration/appRoutes'
import { useDispatch } from 'react-redux'

const PayPalConfirmation = ({
  bookingConfirmationData,
  paymentConfirmationData,
  payPalTransactionData,
  lang,
  labels,
  additionalContent,
  handleRouteChange
}) => {
  const dispatch = useDispatch()

  const tableData = [
    {
      label: labels.bookingNumber,
      value: bookingConfirmationData?.bookingNumber
    },
    {
      label: labels.referenceNumber,
      value: bookingConfirmationData?.orderId
    },
    {
      label: labels.transactionNumber,
      value: paymentConfirmationData?.transactionNumber
    },
    {
      label: labels.transactionDate,
      value: payPalTransactionData?.create_time
        ? moment(payPalTransactionData.create_time).format(
            'YYYY-MM-DD HH:MM:SS'
          )
        : ''
    },
    {
      label: labels.transactionAmount,
      value: formatPriceByLanguage(paymentConfirmationData?.amount, lang)
    },
    {
      label: labels.transactionStatus,
      value: paymentConfirmationData?.paymentApplied
        ? labels.paymentStatusPaid
        : labels.paymentStatusUnpaid
    },
    {
      label: labels.authorizationCode,
      value: paymentConfirmationData?.orderId
    },
    {
      label: labels.cardType,
      value: 'PayPal'
    }
  ]

  const onPrimaryClick = () => {
    dispatch(clearActiveModifyBooking())
    handleRouteChange(appRoutes.ferryRoutes.pathname)
  }

  const onMyAccountBtnClick = () => {
    handleRouteChange(appRoutes.account.pathname)
    dispatch(clearActiveModifyBooking())
  }

  return (
    <div className="booking-confirmation">
      <div className="u-container">
        <div className="booking-confirmation-content">
          <div className="padded-container">
            <h2 className="theme-font-header">{labels.bookingConfirmtion}</h2>
            <BookingReminders labels={labels} />
            <BookingConfirmationTable
              headerLabel={labels.transactionApproved}
              tableData={tableData}
              centerHeader
            />
          </div>
          <div className="padded-container">
            {additionalContent?.value?.linkWithHeading &&
            Object.keys(additionalContent.value.linkWithHeading).length > 0
              ? Object.keys(additionalContent.value.linkWithHeading).map(
                  (linkWithHeadingName, linkWithHeadingKey) => {
                    const linkWithHeadingDetails =
                      additionalContent.value.linkWithHeading[
                        linkWithHeadingName
                      ].value
                    let result = null

                    if (linkWithHeadingDetails.length > 0) {
                      const key = `${linkWithHeadingName}-linkWithHeading-${linkWithHeadingKey}`
                      const title = linkWithHeadingDetails[0].value
                      const link = linkWithHeadingDetails[1].link
                      const linkLabel = linkWithHeadingDetails[1].value

                      if (key && title && link && linkLabel) {
                        result = (
                          <LinkWithTitle
                            key={key}
                            title={title}
                            linkLabel={linkLabel}
                            link={link}
                          />
                        )
                      }
                    }

                    return result
                  }
                )
              : null}
          </div>
        </div>
        <div>
          <div className="booking-confirmation__submits">
            <button
              className="btn btn-primary large-primary-btn"
              onClick={onPrimaryClick}
            >
              {labels.newBookingBtn || labels.continueBtn}
            </button>
            <button
              className="btn btn-primary large-primary-btn"
              onClick={onMyAccountBtnClick}
            >
              {labels.myAccountBtn}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PayPalConfirmation
