import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { string, shape, bool, array } from 'prop-types'
import { isEmpty } from 'lodash'
import * as UserSelectionActions from '../../../redux/userSelections/actions'
import {
  setDepartureAdults,
  setDepartureSeniors,
  setDepartureChildren,
  setDepartureInfants,
  setDepartureStudents,
  setDeparturePets,
  setReturnAdults,
  setReturnSeniors,
  setReturnChildren,
  setReturnInfants,
  setReturnStudents,
  setReturnPets
} from '../../../redux/passengerSelections/actions'
import {
  selectDepartureAdults,
  selectDepartureSeniors,
  selectDepartureChildren,
  selectDepartureInfants,
  selectDepartureStudents,
  selectDeparturePets,
  selectReturnAdults,
  selectReturnSeniors,
  selectReturnChildren,
  selectReturnInfants,
  selectReturnStudents,
  selectReturnPets
} from '../../../redux/passengerSelections/selectors'
import { selectUserSelections } from '../../../redux/userSelections/selectors'
import { selectPassengerInputLabels } from '../../../redux/selectors/selectPassengerInputLabels'
import { selectCurrency } from '../../../redux/session/selectors'

import PassengerQuantitiesToggleField from './PassengerQuantitiesToggleField'
import PageWarningBanner from '../../PageWarningBanner/PageWarningBanner'

const PassengerQuantitiesForm = ({
  ferryLabels,
  formHeading,
  formType,
  passengerTypeData,
  isLoading,
  bestRates,
  isForcesMember
}) => {
  const dispatch = useDispatch()
  const maxCombinedTickets =
    process.env.REACT_APP_COMPONENT_MAXIMUM_PASSENGER_TICKETS
  const passengerDescription =
    formType === 'departure'
      ? ferryLabels.passengerAgeDeterminationMsg
      : ferryLabels.returnPassengerAgeDeterminationMsg

  // const passengerConstraints = useSelector(selectPassengerQuantitiesConstraints)
  // TODO: deprecate the logic below with passengerConstraints above
  const departureAdults = useSelector(selectDepartureAdults) || 0
  const departureSeniors = useSelector(selectDepartureSeniors) || 0
  const departureChildren = useSelector(selectDepartureChildren) || 0
  const departureInfants = useSelector(selectDepartureInfants) || 0
  const departureStudents = useSelector(selectDepartureStudents) || 0
  const departurePets = useSelector(selectDeparturePets) || 0
  const returnAdults = useSelector(selectReturnAdults) || 0
  const returnSeniors = useSelector(selectReturnSeniors) || 0
  const returnChildren = useSelector(selectReturnChildren) || 0
  const returnInfants = useSelector(selectReturnInfants) || 0
  const returnStudents = useSelector(selectReturnStudents) || 0
  const returnPets = useSelector(selectReturnPets) || 0
  const passengerLabels = useSelector(selectPassengerInputLabels)
  const currencyType = useSelector(selectCurrency)
  const currencyRateReminderCAT =
    currencyType === 'USD' ? ferryLabels.currencyRateReminderCAT : null
  const userSelections = useSelector(selectUserSelections)

  const departureGuestsTotal =
    departureAdults +
    departureSeniors +
    departureChildren +
    departureInfants +
    departureStudents +
    departurePets
  const returnGuestsTotal =
    returnAdults +
    returnSeniors +
    returnChildren +
    returnInfants +
    returnStudents +
    returnPets

  const maxReached =
    formType === 'departure'
      ? maxCombinedTickets && departureGuestsTotal >= maxCombinedTickets
      : maxCombinedTickets && returnGuestsTotal >= maxCombinedTickets

  const togglePropsArray = []

  for (const passengerKey in passengerTypeData) {
    const currentPassengerType = passengerTypeData[passengerKey]

    if (
      currentPassengerType.code &&
      currentPassengerType.description &&
      currentPassengerType.display &&
      currentPassengerType.type &&
      currentPassengerType.enabled
    ) {
      let passengerFieldEvent = null
      let passengerFieldValue = null
      const passengerTypeLabel = passengerLabels[currentPassengerType.code].type

      const passengerAgeLabel =
        passengerLabels[currentPassengerType.code].description
      const isDeparture = formType === 'departure'
      const bestRate = bestRates && bestRates[currentPassengerType.type]

      switch (currentPassengerType.code) {
        case 'adult':
          passengerFieldEvent = isDeparture
            ? setDepartureAdults
            : setReturnAdults
          passengerFieldValue = isDeparture ? departureAdults : returnAdults
          break
        case 'senior':
          passengerFieldEvent = isDeparture
            ? setDepartureSeniors
            : setReturnSeniors
          passengerFieldValue = isDeparture ? departureSeniors : returnSeniors
          break
        case 'child':
          passengerFieldEvent = isDeparture
            ? setDepartureChildren
            : setReturnChildren
          passengerFieldValue = isDeparture ? departureChildren : returnChildren
          break
        case 'infant':
          passengerFieldEvent = isDeparture
            ? setDepartureInfants
            : setReturnInfants
          passengerFieldValue = isDeparture ? departureInfants : returnInfants
          break
        case 'student':
          passengerFieldEvent = isDeparture
            ? setDepartureStudents
            : setReturnStudents
          passengerFieldValue = isDeparture ? departureStudents : returnStudents
          break
        default:
          if (process.env.REACT_APP_VERB_ENV !== 'production') {
            console.warn(
              'Unsupported passenger type code: ' + currentPassengerType.code
            )
          }
          continue
      }

      const userSelectionAction = isDeparture
        ? UserSelectionActions.updateDeparturePassengers
        : UserSelectionActions.updateReturnPassengers
      const passengerTypeCode = currentPassengerType.code
      const onNewQuantity = quantity => {
        dispatch(passengerFieldEvent(quantity))
        dispatch(userSelectionAction({ [passengerTypeCode]: quantity }))
      }

      if (passengerTypeLabel && passengerAgeLabel) {
        togglePropsArray.push({
          key: 'passenger-quantity-' + formType + '-' + passengerTypeLabel,
          ageLabel: passengerAgeLabel,
          maxReached,
          onNewQuantity: onNewQuantity,
          passengerFieldValue: passengerFieldValue || 0,
          passengerTypeData: currentPassengerType,
          typeLabel: passengerTypeLabel,
          bestRate: bestRate,
          isLoading: isLoading
        })
      }
    }
  }

  togglePropsArray.push({
    key: 'passenger-quantity-' + formType + '-PET',
    ageLabel: '',
    maxReached,
    onNewQuantity: quantity => {
      dispatch(
        formType === 'departure'
          ? setDeparturePets(quantity)
          : setReturnPets(quantity)
      ) // TODO: Deprecate
    },
    passengerFieldValue:
      (formType === 'departure' ? departurePets : returnPets) || 0,
    passengerTypeData: 'PET',
    typeLabel: ferryLabels.petLabel,
    bestRate: 0,
    isLoading: isLoading
  })

  const toggleSize = togglePropsArray.length >= 5 ? 'small' : ''
  const containerClass =
    togglePropsArray.length >= 5
      ? 'passenger-quantities__form-container wide'
      : 'passenger-quantities__form-container'

  return !isEmpty(togglePropsArray) ? (
    <div
      className={`passenger-quantities__form${formType ? ' ' + formType : ''}`}
    >
      <div className={containerClass}>
        <div className="passenger-quantities__form-header">
          {formHeading && <h2 className="theme-font-header">{formHeading}</h2>}
          <p className="u-remove-margin u-text-center subheading">
            {passengerDescription}
          </p>
          {currencyRateReminderCAT && (
            <p className="u-remove-margin u-text-center subheading">
              {currencyRateReminderCAT}
            </p>
          )}
        </div>
        <div className="passenger-quantities__form-toggles">
          {togglePropsArray.map(toggleProps => (
            <PassengerQuantitiesToggleField
              {...toggleProps}
              size={toggleSize}
            />
          ))}
        </div>
        {isForcesMember && ferryLabels?.['canadianForcesMemberPricing'] && (
          <p className="u-remove-margin u-text-center subheading cf-note">
            {ferryLabels?.['canadianForcesMemberPricing']}
          </p>
        )}
        {maxReached && ferryLabels.maxPassengerCountReachedMessage && (
          <PageWarningBanner
            message={ferryLabels.maxPassengerCountReachedMessage}
          >
            {ferryLabels.maxPassengerCountReachedMessage}
          </PageWarningBanner>
        )}
        {userSelections.promoCode && ferryLabels.promoDiscount && (
          <PageWarningBanner
            message={ferryLabels.promoDiscount}
            customClass="reminder"
            centered={true}
          >
            {ferryLabels.promoDiscount}
          </PageWarningBanner>
        )}
      </div>
    </div>
  ) : null
}

PassengerQuantitiesForm.defaultProps = {
  formHeading: ''
}

PassengerQuantitiesForm.propTypes = {
  ferryLabels: shape(),
  formHeading: string,
  formType: string.isRequired,
  passengerTypeData: array.isRequired,
  isLoading: bool,
  bestRates: shape(),
  isForcesMember: bool.isRequired
}

export default PassengerQuantitiesForm
