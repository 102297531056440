import { createAction } from 'redux-actions'
import {
  travelAgentGetAccount,
  travelAgentModifyAccount,
  travelAgentLogin,
  travelAgentGetBookings
} from '../../api/api'
import { startSessionTimer } from '../session/actions'
import { clearCustomerAccount } from '../user/actions'
import { getHydraMemberDataType } from '../../configuration/utilities'
import {
  ACCOUNT_CLEAR,
  ACCOUNT_GET_LOADING,
  ACCOUNT_GET_SUCCESS,
  ACCOUNT_GET_ERROR,
  ACCOUNT_UPDATE_LOADING,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_ERROR,
  ACCOUNT_UPDATE_SUCCESS_RESET,
  ACCOUNT_UPDATE_ERROR_RESET,
  BOOKINGS_GET_LOADING,
  BOOKINGS_GET_SUCCESS,
  BOOKINGS_GET_ERROR,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_FORM_RESET
} from './constants'

export const accountClear = createAction(ACCOUNT_CLEAR)
export const accountGetLoading = createAction(ACCOUNT_GET_LOADING)
export const accountGetSuccess = createAction(ACCOUNT_GET_SUCCESS)
export const accountGetError = createAction(ACCOUNT_GET_ERROR)

export const getAccount = userId => async dispatch => {
  dispatch(accountGetLoading(userId))

  if (!userId) return dispatch(accountGetError())
  dispatch(startSessionTimer()) // Refresh session on all api requests

  return travelAgentGetAccount({ userId })
    .then(success => {
      /*
        Note:
        success.data.id is also the userName value in the agentUser object,
        Treat responses with an ID and agentUser object as success
      */
      if (success?.data?.agentUser && success?.data?.id) {
        dispatch(clearCustomerAccount()) // clear customer account
        dispatch(bookingsGetSuccess(success?.data?.bookings || []))

        return dispatch(
          accountGetSuccess({ ...success.data.agentUser, id: success.data.id })
        )
      } else {
        return dispatch(accountGetError())
      }
    })
    .catch(() => {
      return dispatch(accountGetError())
    })
}

export const accountUpdateLoading = createAction(ACCOUNT_UPDATE_LOADING)
export const accountUpdateSuccess = createAction(ACCOUNT_UPDATE_SUCCESS)
export const accountUpdateError = createAction(ACCOUNT_UPDATE_ERROR)
export const accountUpdateSuccessReset = createAction(
  ACCOUNT_UPDATE_SUCCESS_RESET
)
export const accountUpdateErrorReset = createAction(ACCOUNT_UPDATE_ERROR_RESET)

export const updateAgentAccount = ({
  sessionId,
  userName,
  ...restValues
}) => async dispatch => {
  dispatch(accountUpdateLoading())
  if (!userName) return dispatch(accountUpdateError())
  dispatch(startSessionTimer()) // Refresh session on all api requests

  return travelAgentModifyAccount({
    sessionId,
    userName,
    ...restValues
  })
    .then(success => {
      const agent = getHydraMemberDataType(success, userName)
      return dispatch(accountUpdateSuccess(agent.agentUser))
    })
    .catch(() => {
      return dispatch(accountUpdateError())
    })
}

export const bookingsGetLoading = createAction(BOOKINGS_GET_LOADING)
export const bookingsGetSuccess = createAction(BOOKINGS_GET_SUCCESS)
export const bookingsGetError = createAction(BOOKINGS_GET_ERROR)

export const loginLoading = createAction(LOGIN_LOADING)
export const loginSuccess = createAction(LOGIN_SUCCESS)
export const loginError = createAction(LOGIN_ERROR)
export const loginFormReset = createAction(LOGIN_FORM_RESET)

const apiTravelAgentLogin = (payload, dispatch) => {
  dispatch(startSessionTimer()) // Refresh session on all api requests

  return travelAgentLogin(payload)
    .then(success => {
      try {
        const { agentUser, id, bookings } = success.data['hydra:member'][0]
        if (!agentUser || !id) {
          throw new Error('No user')
        }
        dispatch(clearCustomerAccount()) // clear customer account
        dispatch(bookingsGetSuccess(bookings || []))
        return dispatch(loginSuccess({ ...agentUser, id }))
      } catch (err) {
        return dispatch(loginError())
      }
    })
    .catch(() => {
      return dispatch(loginError())
    })
}

export const login = (
  formValues = { userId: '', password: '' }
) => async dispatch => {
  const { userId, password } = formValues
  dispatch(loginLoading())

  if (!userId || !password) {
    return dispatch(loginError())
  }

  return apiTravelAgentLogin(
    {
      userId,
      password
    },
    dispatch
  )
}

export const getAgentBookings = (start, end) => (dispatch, getState) => {
  dispatch(bookingsGetLoading())
  const state = getState()
  let agentNumber

  if (state?.travelAgentData?.account?.agentNumber) {
    agentNumber = state.travelAgentData.account.agentNumber
  }

  if (!agentNumber) {
    return dispatch(bookingsGetError())
  }

  return travelAgentGetBookings({
    agentNumber,
    start,
    end
  })
    .then(success => {
      if (success?.data) {
        return dispatch(bookingsGetSuccess(success.data.bookings || []))
      } else {
        return dispatch(bookingsGetError())
      }
    })
    .catch(() => {
      return dispatch(bookingsGetError())
    })
}
