import React from 'react'

const NavigationButton = ({ icon, alt, clickHandler }) => (
  <button
    type="button"
    className="icon-button"
    onClick={clickHandler}
    aria-label={alt}
  >
    <img src={icon} alt={alt} className="map-scroll-icon" />
  </button>
)

export default NavigationButton
