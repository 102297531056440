import { handleActions } from 'redux-actions'
import {
  CLEAR_USER_BOOKINGS,
  FETCH_USER_BOOKINGS,
  USER_BOOKING_ERROR,
  USER_BOOKING_SUCCESS,
  SHOW_NON_REFUNDED
} from './constants'

const userBookingsInitialState = {
  loading: false,
  data: null,
  error: false,
  success: false,
  nonRefundedModalVisible: false
}

const userBookingsReducer = handleActions(
  {
    [FETCH_USER_BOOKINGS]: state => ({
      ...state,
      loading: true,
      error: false,
      success: false
    }),
    [USER_BOOKING_SUCCESS]: (state, { payload }) => ({
      loading: false,
      data: {
        ...payload
      },
      success: true
    }),
    [USER_BOOKING_ERROR]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false
    }),
    [CLEAR_USER_BOOKINGS]: () => ({ ...userBookingsInitialState }),
    [SHOW_NON_REFUNDED]: (state, { payload }) => ({
      ...state,
      nonRefundedModalVisible: !!payload
    })
  },
  { ...userBookingsInitialState }
)

export { userBookingsInitialState }
export default userBookingsReducer
