import React, { Fragment, useState } from 'react'
import { string, bool, shape, func } from 'prop-types'
import RowGroup from '../RowGroup/RowGroup'
import Notification from '../Notification'
import { defaultToVehicleOptionsList } from '../../configuration/constants'
import { renderFormFields } from '../../redux/configuration/utilities'
import { getFieldsAndLayout } from './utilities'
import TitleWithTrigger from '../TitleWithTrigger/TitleWithTrigger'
import Modal from '../Modal'

const BookingInformationForm = ({
  labels,
  fieldDetails,
  values,
  disabled,
  setFieldValue,
  parentKey,
  emailMismatchError,
  setEmailMismatchError,
  hasVehicle,
  signedIn,
  onBlur
}) => {
  const [modalIsVisible, setModalIsVisible] = useState(false)

  const { layout } = getFieldsAndLayout(
    signedIn,
    defaultToVehicleOptionsList,
    hasVehicle
  )

  return !fieldDetails || !values ? null : (
    <>
      <RowGroup
        convertInputsToCaps
        // title={labels.bookingInformation}
        renderHeader={() => (
          <TitleWithTrigger
            onClick={() => setModalIsVisible(true)}
            title={labels.bookingInformation}
            isTitleClickable={Boolean(labels.bookingInformationToolTip)}
            showTooltip={Boolean(labels.bookingInformationToolTip)}
            centered
            containerClasses="booking-information-trigger-container"
          />
        )}
        render={({ formRowClasses, errorClasses }) => (
          <Fragment>
            {renderFormFields({
              formLayout: layout,
              fieldDetails,
              values,
              setFieldValue,
              disabled,
              formRowClasses,
              errorClasses,
              parentKey,
              onBlur
            })}
            {emailMismatchError && (
              <Notification
                type="error"
                message={labels.confirmEmailError}
                onDismiss={() => {
                  setEmailMismatchError(false)
                }}
              />
            )}
          </Fragment>
        )}
      />
      {modalIsVisible && (
        <Modal
          isOpen={modalIsVisible}
          onRequestClose={() => setModalIsVisible(false)}
          className={'modal--med booking-contact-modal'}
        >
          <p>{labels.bookingInformationToolTip}</p>
        </Modal>
      )}
    </>
  )
}

BookingInformationForm.propTypes = {
  labels: shape().isRequired,
  disabled: bool,
  fieldDetails: shape().isRequired,
  values: shape().isRequired,
  setFieldValue: func.isRequired,
  parentKey: string,
  emailMismatchError: bool,
  setEmailMismatchError: func.isRequired,
  hasVehicle: bool,
  signedIn: bool
}

BookingInformationForm.defaultProps = {
  disabled: false,
  parentKey: 'bookingInformation'
}

export default BookingInformationForm
