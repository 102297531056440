import { get } from 'lodash'
import moment from 'moment'
import { ENABLE_ACCOMMODATIONS } from '../../configuration/constants'
import dateFormats from '../../configuration/dateFormats'
import { crossing } from '../../api/api'
import cleanObject from '../../utilities/data/cleanObject'
import {
  CROSSING_ERROR,
  FETCH_CROSSING,
  RECEIVE_CROSSINGS,
  RECEIVE_CROSSINGS_UNMODIFIED
} from './constants'
import {
  selectActiveDepartureAccommodations,
  selectActiveReturnAccommodations
} from '../activeAccommodations/selectors'
import {
  selectAvailableVehicleOptions,
  selectConfigurationData,
  selectIsHighSession,
  selectLabels
} from '../configuration/selectors'
import {
  selectActiveDepartureCrossing,
  selectActiveReturnCrossing
} from '../activeCrossings/selectors'
import {
  setDepartureCrossing,
  setReturnCrossing
} from '../activeCrossings/actions'
import { selectCurrentBookingPromoState } from '../selectors/selectCurrentBookingPromoState'
import { vehicleSelectionsState } from '../vehicleSelections/selectors'
import selectIsWalkOn from '../selectors/selectIsWalkOn'
import { selectPassengerSelections } from '../passengerSelections/selectors'
import {
  selectFerryRouteSelectionsState
  // selectForcesBooking
} from '../ferryRouteSelections/selectors'
import { startSessionTimer } from '../session/actions'
import { selectSessionState } from '../session/selectors'
import {
  registerUpdatedParams,
  registerCurrentParams
} from '../refactorValidation/actions'
import { selectUserSelectedVehicles } from '../userSelections/selectors'
import { vehicleTypes } from '../vehicleSelections/constants'

/*
  NOTE(ebarrett):
    Function that will eventually replace fetchCrossing,
     see selectFetchCrossingsParams selector
  TODO(ebarrett):
    - Convert to async/await
    - Remove 'else' block after refactor
*/
export const fetchCrossingsWithParams = (
  params,
  direction = 'departure'
) => dispatch => {
  const key =
    direction === 'departure'
      ? 'ferryRoutesDepartureCrossing'
      : 'ferryRoutesReturnCrossing'
  dispatch(registerUpdatedParams(key, params))
}

/*
  fetchCrossing is used at multiple locations throughout the flow
  to get the correct updated crossings data in accordance with
  additional selections made by the user
*/
export const fetchCrossing = (direction = 'departure', options = {}) => (
  dispatch,
  getState
) => {
  dispatch({ type: FETCH_CROSSING, payload: direction })

  // selectors
  const state = getState()
  const ferryRouteSelections = selectFerryRouteSelectionsState(state)
  const passengerSelections = selectPassengerSelections(state)
  const vehicleSelections = vehicleSelectionsState(state)

  const userVehicleSelections = selectUserSelectedVehicles(state)

  const isWalkOn =
    selectIsWalkOn(state) ||
    userVehicleSelections?.[direction]?.vehicleType === vehicleTypes.VEHWLK

  const sessionData = selectSessionState(state)
  const configurationData = selectConfigurationData(state)
  // const forcesBooking = selectForcesBooking(state)
  const promoState = selectCurrentBookingPromoState(state)
  const labels = selectLabels(state)
  const activeDepartureCrossing = selectActiveDepartureCrossing(state)

  // environment variables
  const envVariables = process.env
  const {
    REACT_APP_NUMBER_OF_SAILING_DAYS = 5,
    REACT_APP_NUMBER_OF_PRIOR_SAILING_DAYS = 0
  } = envVariables

  // helper constants
  const clientId = get(configurationData, 'ferry.options.clientId')
  const isDeparture = direction === 'departure'
  const { currency, sessionId } = sessionData
  const { vehicleFormType } = vehicleSelections
  const {
    departureDate,
    returnDate,
    departureRoute,
    returnRoute
  } = ferryRouteSelections
  const activeRoute = isDeparture ? departureRoute : returnRoute
  const passengerTypes = activeRoute?.passengerTypes || []
  const walkOnPassengerTypes = activeRoute?.walkOnPassengerTypes || []
  const tripPassengerTypes = isWalkOn ? walkOnPassengerTypes : passengerTypes

  // active route codes
  const productCode = get(activeRoute, 'activeCrossingData.productCode', '')
  const supplierCode = get(activeRoute, 'activeCrossingData.supplierCode', '')
  const ticketType = get(activeRoute, 'activeTicketTypeData.ticketType')

  // passenger-related resource codes
  const passengerResourceCode = getPassengerResourceCode(
    configurationData,
    isWalkOn
  )

  // Note: We need these even if !ENABLE_ACCOMMODATIONS
  const accommodationResourceCodes = get(
    configurationData,
    'ferry.options.accommodationResourceCodes'
  )
  const configVehicleResourceCodes = get(
    configurationData,
    'ferry.options.vehicleResourceCodes'
  )

  // option to fetch all accommodations for pricing
  let accommodationResourcesCodes = []

  if (ENABLE_ACCOMMODATIONS) {
    const activeAccommodations = isDeparture
      ? selectActiveDepartureAccommodations(state)
      : selectActiveReturnAccommodations(state)

    // we either need to fetch *all* codes, or just the current, active codes
    if (options.fetchAllAccommodationResources) {
      const allAvailableAccommodationResourceCodes =
        configurationData?.ferry?.options?.accommodationResourceCodes || []
      for (const accommodationResourceCode of allAvailableAccommodationResourceCodes) {
        // format is count|code, so for these we just statically set each at 1
        accommodationResourcesCodes.push(`1|${accommodationResourceCode}`)
      }
    } else {
      Object.entries(activeAccommodations).forEach(([code, data]) => {
        if (+data.count) {
          accommodationResourcesCodes.push(`${data.count}|${code}`)
        }
      })
    }
  }

  const accommodationResources = ENABLE_ACCOMMODATIONS
    ? accommodationResourcesCodes.join(',')
    : ''

  // date manipulation, determine what the date range needs to be
  let date = isDeparture ? moment(departureDate) : moment(returnDate)
  let daysToSubtract = REACT_APP_NUMBER_OF_PRIOR_SAILING_DAYS
  if (
    !isDeparture &&
    activeDepartureCrossing &&
    activeDepartureCrossing.departureDate
  ) {
    if (
      moment(activeDepartureCrossing.departureDate).isSameOrAfter(date, 'day')
    ) {
      date = activeDepartureCrossing.departureDate
      daysToSubtract = 0
    } else if (
      date.diff(activeDepartureCrossing.departureDate, 'days') < daysToSubtract
    ) {
      daysToSubtract =
        date.diff(activeDepartureCrossing.departureDate, 'days') >= 0
          ? date.diff(activeDepartureCrossing.departureDate, 'days')
          : 0
    }
  }

  if (moment(date).isSameOrBefore(moment(), 'days')) {
    daysToSubtract = 0
  }

  // determining guest counts
  let guestCount = isDeparture
    ? passengerSelections.departure
    : passengerSelections.return

  // get the broken-out guest count object
  if (options.fetchAllPassengerTypes) {
    guestCount = {
      adult: 1,
      child: 1,
      senior: 1,
      infant: 1,
      student: 1
    }
  }

  const guests = generateGuestCounts(tripPassengerTypes, guestCount)

  // gather up all the resource codes by type
  const resourceCodes = configVehicleResourceCodes
    ? [...configVehicleResourceCodes]
    : []
  if (passengerResourceCode) {
    resourceCodes.push(passengerResourceCode)
  }
  if (
    accommodationResourceCodes &&
    Object.keys(accommodationResourceCodes).length > 0
  ) {
    resourceCodes.push(...accommodationResourceCodes)
  }

  // a collection of all resource codes
  const departureResources = resourceCodes.join('|')

  // vehicle data
  const vehicleDirectionKey =
    isDeparture || vehicleFormType === 'VEH-DUP' || vehicleFormType === 'VELO'
      ? 'departure'
      : 'return'

  const vehicleResourceCode = get(vehicleSelections, [
    vehicleDirectionKey,
    'vehicleType',
    'resourceCode'
  ])
  const vehicleLength = get(vehicleSelections, [
    vehicleDirectionKey,
    'vehicleLength'
  ])

  // build the request params now that all the data is acquired
  const params = {
    clientId,
    departureDate: moment(date)
      .subtract(+daysToSubtract, 'days')
      .format(dateFormats.default), // the ui shows dates prior and after the user selected dates
    returnDate: moment(date)
      .add(5, 'days')
      .format(dateFormats.default),
    accommodationResources,
    ticketType,
    productCode,
    yields: activeRoute.yields,
    departureResources,
    departureRoute: activeRoute.code,
    currency,
    sessionId,
    days: +REACT_APP_NUMBER_OF_SAILING_DAYS + +daysToSubtract,
    adults: guests.adult,
    children: guests.child,
    infants: guests.infant,
    seniors: guests.senior,
    students: guests.student,
    passengerResourceCode,
    vehicleResourceCode,
    vehicleLength: vehicleLength ? vehicleLength.toString() : '',
    supplier: supplierCode
  }

  if (promoState.valid) {
    params.coupon = promoState.code

    const {
      couponBookingProductCode,
      couponBookingTicketType
    } = configurationData.ferry.options

    if (couponBookingProductCode) {
      params.productCode = couponBookingProductCode
    }
    if (couponBookingTicketType) {
      params.ticketType = couponBookingTicketType
    }
  }

  // add proper product code if we have Canadian Forces promos enabled and filled out
  /**
   * Commenting out for MAT203-361
  if (process.env.REACT_APP_ENABLE_FORCES_INPUT && forcesBooking) {
    const {
      CFIDPassengerTicketType,
      CFIDBookingProductCode
    } = configurationData.ferry.options

    params.productCode = CFIDBookingProductCode
    params.ticketType = CFIDPassengerTicketType
  }
  **/

  if (options.validationKey) {
    dispatch(registerCurrentParams(options.validationKey, params))
  }

  dispatch(startSessionTimer()) // Refresh session on all api requests

  // actually make the call with our params
  return crossing(cleanObject(params))
    .then(res => {
      return dispatch(receiveCrossings(res, direction, params, options))
    })
    .catch(() => {
      const message = labels.fetchCrossingsError
      return dispatch(fetchCrossingsError(direction, message))
    })
}

const fetchCrossingsError = (direction, error) => dispatch => {
  dispatch({ type: CROSSING_ERROR, payload: { direction, error } })
  return {
    isSuccess: false,
    error
  }
}

function getPassengerResourceCode(configurationData, isWalkOn) {
  const configPassengerResourceCode = get(
    configurationData,
    'ferry.options.passengerResourceCode'
  )
  const walkOnPassengerResourceCode = get(
    configurationData,
    'ferry.options.walkOnPassengerResourceCode'
  )
  return isWalkOn ? walkOnPassengerResourceCode : configPassengerResourceCode
}

export const receiveCrossings = (
  res,
  direction = 'departure',
  fetchParams,
  options = {}
) => (dispatch, getState) => {
  const state = getState()
  const labels = selectLabels(state)
  const configurationData = selectConfigurationData(state)
  const isWalkOn = selectIsWalkOn(state)
  const passengerResourceCode = getPassengerResourceCode(
    configurationData,
    isWalkOn
  )
  const configVehicleResourceCodes = get(
    configurationData,
    'ferry.options.vehicleResourceCodes'
  )
  const accommodationResourceCodes = get(
    configurationData,
    'ferry.options.accommodationResourceCodes'
  )
  const isDeparture = direction === 'departure'

  const crossings = get(res, 'data.hydra:member[0].departures')
  if (crossings) {
    dispatch(receiveCrossingsUnmodified(direction, crossings))
    const formattedCrossingData = reduceCrossings(
      crossings,
      passengerResourceCode,
      configVehicleResourceCodes,
      accommodationResourceCodes,
      fetchParams.departureDate,
      fetchParams.days,
      state
    )

    const activeCrossing = isDeparture
      ? selectActiveDepartureCrossing(state)
      : selectActiveReturnCrossing(state)

    // updateActiveCrossing when we need to update the pricing we display through the app.
    // at the time of writing this comment, this only occurs on the summary page.
    if (activeCrossing && options.updateActiveCrossing) {
      const dayKey = moment(activeCrossing.departureDate).format(
        dateFormats.default
      )

      const isMatchingCrossing = crossing =>
        crossing.departureTime === activeCrossing.departureTime &&
        crossing.shipCode === activeCrossing.shipCode

      if (
        formattedCrossingData &&
        formattedCrossingData.crossings &&
        formattedCrossingData.crossings[dayKey]
      ) {
        const matchingCrossing = formattedCrossingData.crossings[dayKey].find(
          isMatchingCrossing
        )

        if (matchingCrossing) {
          if (isDeparture) {
            dispatch(setDepartureCrossing(matchingCrossing))
          } else {
            dispatch(setReturnCrossing(matchingCrossing))
          }
        }
      }
    }

    const isMatchingCrossingWithRouteCode = crossing =>
      crossing.routeCode === options.activeModifyBooking?.routeCode &&
      crossing.departureTime === options.activeModifyBooking?.departureTime &&
      crossing.shipCode === options.activeModifyBooking?.shipCode

    // if the user is modifying a current booking, we'll set it as the current active booking
    if (
      options.setActiveCrossingFromModification &&
      options.activeModifyBooking
    ) {
      const activeDayKey = options.activeModifyBooking?.departureDate
      if (formattedCrossingData?.crossings?.[activeDayKey]?.length) {
        const matchingCrossing = formattedCrossingData.crossings[
          activeDayKey
        ].find(isMatchingCrossingWithRouteCode)
        if (matchingCrossing) {
          if (isDeparture) {
            dispatch(setDepartureCrossing(matchingCrossing))
          } else {
            dispatch(setReturnCrossing(matchingCrossing))
          }
        }
      }
    }

    const payload = {
      direction,
      ...formattedCrossingData
    }

    dispatch({
      type: RECEIVE_CROSSINGS,
      payload
    })

    return {
      isSuccess: true,
      payload
    }
  } else {
    dispatch(fetchCrossingsError(direction, labels.fetchCrossingsError))
  }
}

function receiveCrossingsUnmodified(direction, crossings) {
  return dispatch => {
    dispatch({
      type: RECEIVE_CROSSINGS_UNMODIFIED,
      payload: { direction, crossings }
    })
  }
}

function generateGuestCounts(passengerTypes, guestCounts) {
  if (!guestCounts || !Object.keys(guestCounts).length) {
    return {}
  }
  return Object.entries(guestCounts).reduce(
    (obj, [guestTypeKey, guestTypeCount]) => {
      const matchingPassengerType = passengerTypes.find(
        type => type.code === guestTypeKey
      )
      if (+guestTypeCount) {
        obj[guestTypeKey] = `${guestTypeCount}|${
          matchingPassengerType && matchingPassengerType.type
            ? matchingPassengerType.type
            : ''
        }`
      }

      return obj
    },
    {}
  )
}

function reduceCrossings(
  crossings,
  passengerResourceCode,
  vehicleResourceCode,
  accommodationResourceCodes,
  departureDate,
  numberOfDays,
  state
) {
  const vehicleContent = selectAvailableVehicleOptions(state)
  const vehicleContentData = vehicleContent?.vehicles
  return crossings.reduce(
    (obj, crossing) => {
      // we need at least an empty arrays for evey day we tried to fetch.
      let i = 0
      while (i < +numberOfDays) {
        const objKey = moment(departureDate, dateFormats.default)
          .add(i, 'days')
          .format(dateFormats.default)

        if (!obj.crossings[objKey]) {
          obj.crossings[objKey] = []
        }

        i += 1
      }

      const dayKey = moment(crossing.departureDate).format(dateFormats.default)
      // we aren't going to display any crossings that have departed.
      if (moment(crossing.departureDate).isSame(moment(), 'days')) {
        if (
          moment(crossing.departureTime, dateFormats.timeOnly).isBefore(
            moment(),
            'minutes'
          )
        ) {
          return obj
        }
      }

      // this is now a dummy check to make sure we don't miss any.
      if (!obj.crossings[dayKey]) {
        obj.crossings[dayKey] = []
      }

      const isHighSeason = selectIsHighSession(state, dayKey)

      const formattedResources = {
        passenger: [],
        vehicles: [],
        accommodations: []
      }
      for (const resource of crossing.resources) {
        let formattedResource = resource

        if (resource.resourceCode === passengerResourceCode) {
          formattedResources.passenger.push(resource)
        } else if (
          vehicleResourceCode.some(code => code === resource.resourceCode)
        ) {
          // get the matching content so we can use our high and low season pricing
          if (!resource.price || +resource.price === 0) {
            const additionalVehicleData = vehicleContentData.find(
              v => v.resourceCode === resource.resourceCode
            )

            if (additionalVehicleData?.pricing) {
              formattedResource = {
                ...resource,
                price: isHighSeason
                  ? additionalVehicleData?.pricing?.highSeasonPrice
                  : additionalVehicleData?.pricing?.lowSeasonPrice
              }
              if (
                additionalVehicleData.pricing?.startingPrices?.[
                  crossing.routeCode
                ]
              ) {
                formattedResource.price =
                  additionalVehicleData.pricing?.startingPrices?.[
                    crossing.routeCode
                  ]
              }
            }
          }
          formattedResources.vehicles.push(formattedResource)
        }

        // BEST RATES
        if (
          +formattedResource.freeCapacity > 0 &&
          (!obj.resourceBestRates[formattedResource.resourceCode] ||
            formattedResource.price <
              obj.resourceBestRates[formattedResource.resourceCode].price)
        ) {
          obj.resourceBestRates[
            formattedResource.resourceCode
          ] = formattedResource
        }
        // Available cabins
        if (
          accommodationResourceCodes.some(
            code => code === resource.resourceCode
          )
        ) {
          const accommodationsPricing =
            crossing?.bookingPrice?.bookingPrice?.resources?.accommodations ||
            []
          const resourcePricing = accommodationsPricing.find(
            x => x.resourceCode === resource.resourceCode
          )

          if (resourcePricing) {
            const resourceWithBookingPrice = {
              ...resource,
              price: resourcePricing.price
            }
            formattedResources.accommodations.push(resourceWithBookingPrice)
          } else {
            formattedResources.accommodations.push(resource)
          }
        }
      }

      const passengerPricing = get(
        crossing,
        'bookingPrice.bookingPrice.resources.passengers'
      )

      if (passengerPricing) {
        for (const passenger of passengerPricing) {
          // MAT203-364 - Using netPrice instead of discounted prices from now on
          if (
            !obj.passengerBestRates[passenger.type] ||
            obj.passengerBestRates[passenger.type] >
              (+passenger?.netPrice || +passenger.price)
          ) {
            obj.passengerBestRates[passenger.type] =
              +passenger?.netPrice || +passenger.price
          }
        }
      }

      obj.crossings[dayKey].push({ ...crossing, formattedResources })
      return obj
    },
    {
      crossings: {},
      resourceBestRates: {},
      passengerBestRates: {}
    }
  )
}
