const canbinENUnitLabel = {
  default: 'Cabin',
  plural: 'Cabins'
}
const canbinFRUnitLabel = {
  default: 'Cabine',
  plural: 'Cabines'
}

const kennelENUnitLabel = {
  default: 'Kennel',
  plural: 'Kennels'
}
const kennelFRUnitLabel = {
  default: 'Chenil',
  plural: 'Chenils'
}

const seatENUnitLabel = {
  default: 'Seat',
  plural: 'Seats'
}

const seatFRUnitLabel = {
  default: 'siège',
  plural: 'des places'
}

export const accommodationTypByCode = {
  C4B: 'cabin',
  C2L: 'cabin',
  RDS: 'seat',
  KSM: 'kennel',
  KMD: 'kennel',
  KLG: 'kennel',
  KXL: 'kennel',
  'CAB-REG': 'CAB-REG' // Two Bed Cabin
}

export const accommodationDisaplayNamebyType = {
  en: {
    'CAB-REG': canbinENUnitLabel, // Two Bed Cabin
    C4B: canbinENUnitLabel,
    C2L: canbinENUnitLabel,
    RDS: seatENUnitLabel,
    KSM: kennelENUnitLabel,
    KMD: kennelENUnitLabel,
    KLG: kennelENUnitLabel,
    KXL: kennelENUnitLabel
  },
  fr: {
    'CAB-REG': canbinFRUnitLabel, // Two Bed Cabin
    C4B: canbinFRUnitLabel,
    C2L: canbinFRUnitLabel,
    RDS: seatFRUnitLabel,
    KSM: kennelFRUnitLabel,
    KMD: kennelFRUnitLabel,
    KLG: kennelFRUnitLabel,
    KXL: kennelFRUnitLabel
  }
}
