import React, { Fragment } from 'react'
import yn from 'yn'
import { oneOfType, bool, shape, func, string } from 'prop-types'
import { renderCheckbox } from '../Checkbox/Checkbox'
import { renderTextInput } from '../TextInput/TextInput'
import { renderFormFields } from '../../redux/configuration/utilities'
import Notification from '../Notification'
import RowGroup from '../RowGroup/RowGroup'
import { getFormFieldsAndLayout } from './utilities'

const AccountInformationForm = ({
  values,
  disabled,
  setFieldValue,
  fieldDetails,
  labels,
  passwordMismatchError,
  setPasswordMismatchError,
  emailMismatchError,
  setEmailMismatchError,
  parentKey,
  convertInputsToCaps,
  nameLengthError,
  setNameLengthError,
  onBlur
}) => {
  const { mainLayout, optionalLayout } = getFormFieldsAndLayout()

  return !fieldDetails ||
    Object.keys(fieldDetails).length === 0 ||
    !values ? null : (
    <RowGroup
      convertInputsToCaps={convertInputsToCaps}
      title={labels.accountInformation}
      render={({ formRowClasses, errorClasses }) => (
        <Fragment>
          <span className={formRowClasses}>
            {fieldDetails.accountSameAsBooking &&
              renderCheckbox({
                name: parentKey
                  ? `${parentKey}.${fieldDetails.accountSameAsBooking.name}`
                  : `${fieldDetails.accountSameAsBooking.name}`,
                value: values[fieldDetails.accountSameAsBooking.name],
                label: fieldDetails.accountSameAsBooking.label,
                disabled,
                errorClasses,
                onBlur
              })}
          </span>
          {!yn(values[fieldDetails.accountSameAsBooking.name]) &&
            renderFormFields({
              formLayout: optionalLayout,
              fieldDetails,
              values,
              setFieldValue,
              disabled,
              formRowClasses,
              errorClasses,
              parentKey,
              onBlur
            })}
          {!yn(values[fieldDetails.accountSameAsBooking.name]) &&
            emailMismatchError && (
              <Notification
                type="error"
                message={labels.confirmEmailError}
                onDismiss={() => {
                  setEmailMismatchError(false)
                }}
              />
            )}
          {renderFormFields({
            formLayout: mainLayout,
            fieldDetails,
            values,
            setFieldValue,
            disabled,
            formRowClasses,
            errorClasses,
            parentKey,
            onBlur
          })}
          <h4 className="row-group-subtitle theme-font-header">
            {labels.password}
          </h4>
          {passwordMismatchError && (
            <Notification
              type="error"
              message={labels.passwordMismatch}
              onDismiss={() => {
                setPasswordMismatchError(false)
              }}
            />
          )}
          {nameLengthError && (
            <Notification
              type="error"
              message={labels.combinedNameMaxLengthErrorMessage}
              onDismiss={() => {
                setNameLengthError(false)
              }}
            />
          )}
          <span className={formRowClasses}>
            {fieldDetails.password &&
              renderTextInput({
                name: parentKey
                  ? `${parentKey}.${fieldDetails.password.name}`
                  : `${fieldDetails.password.name}`,
                value: values[fieldDetails.password.name],
                type: 'password',
                placeholder: fieldDetails.password.label,
                errorClasses,
                setFieldValue,
                disabled,
                classNames: 'password',
                inputHelpText: labels.passwordHelper,
                onBlur,
                parentKey
              })}
            {fieldDetails.confirmPassword &&
              renderTextInput({
                name: parentKey
                  ? `${parentKey}.${fieldDetails.confirmPassword.name}`
                  : `${fieldDetails.confirmPassword.name}`,
                value: values[fieldDetails.confirmPassword.name],
                type: 'password',
                placeholder: fieldDetails.confirmPassword.label,
                errorClasses,
                setFieldValue,
                disabled,
                classNames: 'password',
                onBlur,
                parentKey
              })}
          </span>
        </Fragment>
      )}
    />
  )
}

AccountInformationForm.propTypes = {
  values: shape().isRequired,
  disabled: bool.isRequired,
  setFieldValue: func.isRequired,
  fieldDetails: shape().isRequired,
  labels: shape().isRequired,
  passwordMismatchError: oneOfType([string, bool]),
  setPasswordMismatchError: func.isRequired,
  emailMismatchError: oneOfType([string, bool]),
  setEmailMismatchError: func.isRequired,
  parentKey: string,
  convertInputsToCaps: bool,
  nameLengthError: oneOfType([string, bool]),
  setNameLengthError: func.isRequired
}

AccountInformationForm.defaultProps = {
  disabled: false,
  parentKey: 'accountInformation',
  convertInputsToCaps: false
}

export default AccountInformationForm
