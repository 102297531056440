import moment from 'moment'
import { handleActions } from 'redux-actions'
import { actionTypes } from './actions'
import dateFormats from '../../configuration/dateFormats'

const CAT_ROUTES = ['BAYA', 'YABA']
const TODAY = moment().format(dateFormats.default)
const TOMORROW = moment()
  .add(1, 'days')
  .format(dateFormats.default)

export const userSelectionsInitialState = {
  // Landing / search selections
  tripType: 'RT', // String, 'OW' or 'RT'
  departureRoute: null, // Code (eg. 'DGSJ' for Digby -> Saint John)
  departureSearchDate: TODAY, // DateString (eg. '2022-04-20')
  returnRoute: null, // Code, null if OW
  returnSearchDate: TOMORROW, // DateString, null if OW
  promoCode: '', // String

  // Passenger quantities
  isSamePassengers: true, // Boolean
  departurePassengers: {
    // CODE -> Quantity pairs
  },
  returnPassengers: {
    // null if tripType === 'OW'
    // CODE -> Quantity pairs
  },

  // Vehicle selection
  vehicleFormType: '',
  isSameVehicle: true, // Boolean
  departureVehicle: {
    // optional
    code: null, // String
    height: null, // Number, optional
    length: null, // Number, optional,
    license: null // String, optional
  },
  returnVehicle: {
    // optional, null if isSameVehicle
    code: null, // String
    height: null, // Number, optional
    length: null, // Number, optional
    license: null // String, optional
  },

  // Crossing selections
  departureCrossing: {
    date: null, // DateString (eg. '2022-05-22T00:00:00')
    time: null // TimeString (eg. '15:00')
  },
  returnCrossing: {
    // null if tripType === 'OW'
    date: null,
    time: null
  },

  // Accommodations
  departureAccommodations: {
    // CODE -> Quantity pairs
  },
  returnAccommodations: {
    // CODE -> Quantity pairs
  },

  // Extras
  accessibilityResponses: {
    // CODE -> Boolean pairs
  },

  passengerDetails: {
    // KEY -> Formik Data pairs
  },
  bookingDetails: {
    // Formik Data
  },
  // Account Information as entered before it has been created
  accountInformation: {},
  // Customer billing information
  billingInfo: {},
  bookingConsent: false,
  isForcesMember: false, // CFID handling
  modifyBookingNumber: null, // booking number of the booking we are modifying,
  bookingAccount: null // new account information for booking,
}

const userSelectionsReducer = handleActions(
  {
    [actionTypes.SET_TRIP_TYPE]: (state, action) => ({
      ...state,
      tripType: action.payload
    }),
    [actionTypes.SET_DEPARTURE_ROUTE]: (state, action) => {
      // FIXME(ebarrett): This is temp logic for CAT, remove in or after May 2022,
      //  it should just be a simple pass-through
      const wasCat = CAT_ROUTES.includes(state.departureRoute)
      const isCat = CAT_ROUTES.includes(action.payload)
      const changingToCat = !wasCat && isCat
      const newState = {
        ...state,
        departureRoute: action.payload
      }
      if (changingToCat) {
        newState.departureSearchDate = '2022-05-19'
        newState.returnSearchDate = '2022-05-20'
      }
      return newState
    },
    [actionTypes.SET_RETURN_ROUTE]: (state, action) => ({
      ...state,
      returnRoute: action.payload
    }),
    [actionTypes.SET_DEPARTURE_SEARCH_DATE]: (state, action) => ({
      ...state,
      departureSearchDate: action.payload
    }),
    [actionTypes.SET_RETURN_SEARCH_DATE]: (state, action) => ({
      ...state,
      returnSearchDate: action.payload
    }),
    [actionTypes.SET_PROMO_CODE]: (state, action) => ({
      ...state,
      promoCode: action.payload
    }),
    [actionTypes.SET_IS_SAME_PASSENGERS]: (state, action) => ({
      ...state,
      isSamePassengers: action.payload
    }),
    [actionTypes.UPDATE_DEPARTURE_PASSENGERS]: (state, action) => {
      const departurePassengers = {
        ...state.departurePassengers,
        ...action.payload
      }
      return {
        ...state,
        departurePassengers
      }
    },
    [actionTypes.UPDATE_RETURN_PASSENGERS]: (state, action) => {
      const returnPassengers = { ...state.returnPassengers, ...action.payload }
      return {
        ...state,
        returnPassengers
      }
    },
    [actionTypes.SET_VEHICLE_FORM_TYPE]: (state, action) => ({
      ...state,
      vehicleFormType: action.payload
    }),
    [actionTypes.SET_IS_SAME_VEHICLE]: (state, action) => ({
      ...state,
      isSameVehicle: action.payload
    }),
    [actionTypes.SET_DEPARTURE_VEHICLE]: (state, action) => ({
      ...state,
      departureVehicle: action.payload
    }),
    [actionTypes.SET_RETURN_VEHICLE]: (state, action) => ({
      ...state,
      returnVehicle: action.payload
    }),
    [actionTypes.SET_DEPARTURE_CROSSING]: (state, action) => ({
      ...state,
      departureCrossing: action.payload
    }),
    [actionTypes.SET_RETURN_CROSSING]: (state, action) => ({
      ...state,
      returnCrossing: action.payload
    }),
    [actionTypes.UPDATE_DEPARTURE_ACCOMMODATIONS]: (state, action) => {
      const departureAccommodations = {
        ...state.departureAccommodations,
        ...action.payload
      }
      return {
        ...state,
        departureAccommodations
      }
    },
    [actionTypes.UPDATE_RETURN_ACCOMMODATIONS]: (state, action) => {
      const returnAccommodations = {
        ...state.returnAccommodations,
        ...action.payload
      }
      return {
        ...state,
        returnAccommodations
      }
    },
    [actionTypes.SET_ACCESSIBILITY_RESPONSES]: (state, action) => ({
      ...state,
      accessibilityResponses: action.payload
    }),
    [actionTypes.UPDATE_PASSENGER_DETAILS]: (state, action) => ({
      ...state,
      passengerDetails: action.payload
    }),
    [actionTypes.SET_BOOKING_DETAILS]: (state, action) => ({
      ...state,
      bookingDetails: action.payload
    }),
    [actionTypes.SET_IS_FORCES_MEMBER]: (state, action) => ({
      ...state,
      isForcesMember: action.payload
    }),
    [actionTypes.RESET_BOOKING]: state => {
      // get all the default values except the ones we want to keep
      const {
        tripType,
        departureRoute,
        departureSearchDate,
        returnRoute,
        returnSearchDate,
        promoCode,
        ...rest
      } = userSelectionsInitialState

      // use the state of the ones we want, override the rest
      return { ...state, ...rest }
    },
    [actionTypes.SET_MODIFY_BOOKING_NUMBER]: (state, action) => ({
      ...state,
      modifyBookingNumber: action.payload
    }),
    [actionTypes.SET_BOOKING_ACCOUNT]: (state, action) => ({
      ...state,
      bookingAccount: action.payload
    }),
    [actionTypes.SET_BILLING_INFO]: (state, action) => ({
      ...state,
      billingInfo: action.payload
    }),
    [actionTypes.SET_BOOKING_CONSENT]: (state, action) => ({
      ...state,
      bookingConsent: action.payload
    })
  },
  { ...userSelectionsInitialState }
)

export default userSelectionsReducer
