import { createAction } from 'redux-actions'
import { getCouponInformation } from '../../api/api'
import { startSessionTimer } from '../session/actions'
import { selectSessionId } from '../session/selectors'
import {
  selectDepartureDate,
  selectReturnDate,
  selectIsReturnTrip
} from '../ferryRouteSelections/selectors'
import {
  PROMO_FETCH_START,
  PROMO_FETCH_SUCCESS,
  PROMO_FETCH_ERROR,
  SET_PROMO_CODE,
  CLEAR_VALIDATION,
  RESET_PROMO_STATE
} from './constants'

export const setPromoCode = createAction(SET_PROMO_CODE)
export const clearPromoValidation = createAction(CLEAR_VALIDATION)

export const fetchPromoInformation = (code, options) => (
  dispatch,
  getState
) => {
  dispatch({ type: PROMO_FETCH_START })
  const state = getState()
  const sessionId = selectSessionId(state)
  const departureDate = selectDepartureDate(state)
  const returnDate = selectReturnDate(state)
  const isReturn = selectIsReturnTrip(state)

  dispatch(startSessionTimer())
  dispatch(setPromoCode(code))

  const params = {
    code,
    sessionId,
    departureDate: departureDate.format('YYYY-MM-DD')
  }

  if (isReturn && returnDate) {
    params.returnDate = returnDate.format('YYYY-MM-DD')
  }

  return getCouponInformation(params)
    .then(res => {
      let coupons = res?.data?.coupons || []
      coupons = coupons.filter(x => x.couponValid)

      if (coupons.length) {
        dispatch({
          type: PROMO_FETCH_SUCCESS,
          payload: { coupons }
        })
      } else {
        dispatch({
          type: !!options.clearIfInvalid
            ? RESET_PROMO_STATE
            : PROMO_FETCH_ERROR,
          payload: {}
        })
      }
    })
    .catch(() => {
      dispatch({
        type: !!options?.clearIfInvalid ? RESET_PROMO_STATE : PROMO_FETCH_ERROR,
        payload: {}
      })
    })
}
