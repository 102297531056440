import React, { Fragment } from 'react'
import { boolean, object, string, ref } from 'yup'
import { renderCheckbox } from '../../components/Checkbox/Checkbox'
import { renderTextArea } from '../../components/TextArea/TextArea'
import { renderTextInput } from '../../components/TextInput/TextInput'

import { DISABLE_WAITING_ROOM } from '../../configuration/constants'
import {
  dropdownLabelMap,
  supportedFieldTypes,
  EMPTY_SELECTION_MAP_KEY,
  emailFields,
  fields
} from './constants'
import { RenderSelect } from '../../components/Select/Select'

const {
  TEXT,
  PASSWORD,
  CHECKBOX,
  TEL,
  TEXTAREA,
  SELECT,
  SELECT_TEXT_FALLBACK
} = supportedFieldTypes
const supportedSchemaTypes = {
  BOOLEAN: 'boolean',
  STRING: 'string'
}

export const isEmptyStringType = fieldType =>
  fieldType === TEXT ||
  fieldType === PASSWORD ||
  fieldType === TEL ||
  fieldType === TEXTAREA ||
  fieldType === SELECT ||
  fieldType === SELECT_TEXT_FALLBACK

export const getInitialValuesFromFields = (formFields = {}) => {
  const result = {}
  Object.keys(formFields).forEach(fieldName => {
    const fieldDetails = formFields[fieldName]
    const { type, value } = fieldDetails

    if (
      value ||
      (value === '' && isEmptyStringType(type)) ||
      (value === false && type === CHECKBOX) ||
      (value === '' && type === SELECT_TEXT_FALLBACK)
    ) {
      result[fieldName] = fieldDetails.value
    }
  })

  return result
}

const getDefinedFieldByName = fieldName =>
  fields[fieldName] ? { ...fields[fieldName] } : false

export const filterFieldsByName = (fields = {}, fieldNameArray = []) => {
  const fieldsCopy = JSON.parse(JSON.stringify(fields))

  return Object.keys(fieldsCopy)
    .filter(key => fieldNameArray.includes(key))
    .reduce((obj, key) => {
      obj[key] = fieldsCopy[key]
      return obj
    }, {})
}

export const getFormFieldsByName = (fieldNameArray = [], labels = {}) => {
  const result = {}
  const isRequiredLabel = labels.isRequired || ''

  fieldNameArray.forEach(fieldName => {
    let details = getDefinedFieldByName(fieldName)
    let label = fieldName

    if (labels?.[fieldName]) {
      label = labels[fieldName]
    }

    if (details) {
      result[fieldName] = {
        ...details,
        label,
        requiredLabel: `${label} ${isRequiredLabel}`
      }

      if (details.type === TEXTAREA && result[fieldName].requiredLabel) {
        result[fieldName].requiredLabel = label
      }

      if (details.type === CHECKBOX && result[fieldName].requiredLabel) {
        result[fieldName].requiredLabel = labels.checkBoxRequired
      }

      if (details.minLength) {
        result[fieldName].minLengthErrorMessage = labels.minLengthErrorMessage
      }
      if (details.maxLength) {
        result[fieldName].maxLengthErrorMessage = labels.maxLengthErrorMessage
      }

      // Its a dropdown with options and a name that has mapped labels
      if (
        result[fieldName].type &&
        result[fieldName].type === SELECT &&
        result[fieldName].options &&
        result[fieldName].options.length > 0 &&
        Object.keys(dropdownLabelMap).includes(fieldName)
      ) {
        // Go through the map for this dropdown and add option labels
        Object.keys(dropdownLabelMap[fieldName]).forEach(mapKey => {
          // Check if option value maps to a label key
          const optionIndexToMapLabel = result[fieldName].options.findIndex(
            option => {
              return option.value === mapKey
            }
          )
          // Check if theres an empty selection value
          const emptyOptionIndex = result[fieldName].options.findIndex(
            option => {
              return option.value === ''
            }
          )
          let optionLabel = ''

          // Add empty selection label if defined
          if (
            mapKey === EMPTY_SELECTION_MAP_KEY &&
            labels[dropdownLabelMap[fieldName][mapKey]] &&
            emptyOptionIndex !== -1
          ) {
            result[fieldName].options[emptyOptionIndex] = {
              ...details.options[emptyOptionIndex],
              label: labels[dropdownLabelMap[fieldName][mapKey]]
            }
          }

          // If open maps to a label and it exists in the config labels
          if (optionIndexToMapLabel !== -1) {
            const optionLabelKey = dropdownLabelMap[fieldName][mapKey]

            if (optionLabelKey && labels[optionLabelKey]) {
              optionLabel = labels[optionLabelKey]
            }
          }

          // add the label to the field options
          if (optionLabel) {
            result[fieldName].options[optionIndexToMapLabel] = {
              ...details.options[optionIndexToMapLabel],
              label: optionLabel
            }
          }
        })
      }
    }
  })

  return result
}

const buildBooleanSchema = ({
  fieldName,
  label = '',
  requiredLabel = false,
  isEmail = false,
  regex = false,
  conditionallyRequired = false
}) => {
  let result = boolean()
  if (requiredLabel) {
    result = result.concat(result.oneOf([true], requiredLabel))
  }

  return result
}

const buildStringSchema = ({
  fieldName,
  label = '',
  requiredLabel = false,
  isEmail = false,
  regex = false,
  conditionallyRequired = false,
  minLength,
  maxLength,
  maxLengthErrorMessage,
  minLengthErrorMessage,
  conditionalTest = null,
  equalTo,
  equalToDisplayValue
}) => {
  if (!fieldName) return null

  let result = string()

  const conditionalRequirement =
    conditionallyRequired?.when &&
    Object.keys(conditionallyRequired.when).length > 0

  if (requiredLabel && !conditionalRequirement) {
    result = result.concat(result.required(requiredLabel))
  }

  if (requiredLabel && conditionalTest) {
    result = result.concat(
      result.test(`${fieldName}-test`, requiredLabel, conditionalTest)
    )
  }

  if (conditionalRequirement && requiredLabel) {
    result = result.concat(
      result.when(Object.keys(conditionallyRequired.when)[0], {
        is:
          conditionallyRequired.when[
            Object.keys(conditionallyRequired.when)[0]
          ],
        then: result
          .concat(result.required(requiredLabel))
          .concat(
            minLength ? result.min(minLength, minLengthErrorMessage) : undefined
          )
          .concat(
            maxLength ? result.max(maxLength, maxLengthErrorMessage) : undefined
          )
      })
    )

    if (minLength) {
      result.when(Object.keys(conditionallyRequired.when)[0], {
        is:
          conditionallyRequired.when[
            Object.keys(conditionallyRequired.when)[0]
          ],
        then: result.concat(result.min(minLength, minLengthErrorMessage))
      })
    }
    if (maxLength) {
      result.when(Object.keys(conditionallyRequired.when)[0], {
        is:
          conditionallyRequired.when[
            Object.keys(conditionallyRequired.when)[0]
          ],
        then: result.concat(result.max(maxLength, maxLengthErrorMessage))
      })
    }
  }

  if (!conditionalRequirement) {
    if (minLength) {
      result = result.concat(result.min(minLength, minLengthErrorMessage))
    }
    if (maxLength) {
      result = result.concat(result.max(maxLength, maxLengthErrorMessage))
    }
  }

  if (isEmail) {
    const invalidEmailLabel = requiredLabel || ''
    result = result.concat(result.email(invalidEmailLabel))
  }

  // FIXME(ebarrett): This is NOT localized. We should be providing a localization
  //  format param (likely a function) for CTMA form validation
  if (equalTo) {
    const matchSchema = result.oneOf(
      [ref(equalTo), null],
      `Does not match ${equalToDisplayValue ? equalToDisplayValue : equalTo}`
    )
    result = result.concat(matchSchema)
  }

  return result
}

const buildFieldSchema = ({
  fieldName,
  schemaType = supportedSchemaTypes.STRING,
  label = '',
  requiredLabel = false,
  isEmail = false,
  regex = false,
  conditionallyRequired = false,
  minLength,
  maxLength,
  minLengthErrorMessage,
  maxLengthErrorMessage,
  conditionalTest = null,
  equalTo,
  equalToDisplayValue
}) => {
  let result
  if (!fieldName) return result
  result = object().shape({})
  let fieldSchema

  if (schemaType === supportedSchemaTypes.STRING) {
    fieldSchema = buildStringSchema({
      fieldName,
      label,
      requiredLabel,
      isEmail,
      regex,
      conditionallyRequired,
      minLength,
      maxLength,
      minLengthErrorMessage,
      maxLengthErrorMessage,
      conditionalTest,
      equalTo,
      equalToDisplayValue
    })
  }

  if (schemaType === supportedSchemaTypes.BOOLEAN) {
    fieldSchema = buildBooleanSchema({
      fieldName,
      label,
      requiredLabel,
      isEmail,
      regex,
      conditionallyRequired
    })
  }

  if (fieldSchema) {
    result = result.concat(
      object().shape({
        [fieldName]: fieldSchema
      })
    )
  }

  return result
}

export const getSchemaFromFieldDetails = (
  fieldDetails,
  conditionallyIncludedRequiredFields = false,
  conditionalTests = null
) => {
  let result

  if (fieldDetails) {
    result = object().shape({})

    Object.keys(fieldDetails).forEach(fieldName => {
      const details = fieldDetails[fieldName]
      let fieldSchema = false
      let isEmail = false
      let schemaType = false
      const minLength = details?.minLength
      const maxLength = details?.maxLength
      const equalTo = details?.equalTo
      const equalToDisplayValue = details?.equalToDisplayValue

      // determine input type from field type
      if (details?.type) {
        if (isEmptyStringType(details.type)) {
          schemaType = supportedSchemaTypes.STRING
        }
        if (details.type === CHECKBOX) {
          schemaType = supportedSchemaTypes.BOOLEAN
        }
      }

      // for additional schema needed to validated emails
      if (emailFields.includes(fieldName)) isEmail = true

      if (schemaType) {
        let requiredLabel
        if (details?.isRequired) {
          requiredLabel = details?.requiredLabel || ''
        }

        fieldSchema = buildFieldSchema({
          fieldName,
          schemaType,
          label: details.label || '',
          regex: details.regex || false,
          requiredLabel,
          isEmail,
          conditionallyRequired:
            conditionallyIncludedRequiredFields?.[fieldName],
          minLength,
          maxLength,
          maxLengthErrorMessage: details?.maxLengthErrorMessage,
          minLengthErrorMessage: details?.minLengthErrorMessage,
          conditionalTest: conditionalTests?.[fieldName],
          equalTo,
          equalToDisplayValue
        })

        if (fieldSchema) result = result.concat(fieldSchema)
      }
    })
  }

  return result
}

export const renderFormFields = ({
  formLayout,
  fieldDetails,
  values,
  setFieldValue,
  disabled,
  formRowClasses,
  errorClasses,
  parentKey = false,
  formHelpLinks = false,
  onBlur
}) => {
  return formLayout.map((formRowNames, rowKey) => (
    <Fragment key={rowKey}>
      <span className={formRowClasses} key={rowKey}>
        {formRowNames.map(inputName => {
          const fieldInfo = fieldDetails[inputName]
          const fieldInfoType = fieldInfo?.type
          let fieldName = parentKey
            ? `${parentKey}.${inputName}`
            : `${inputName}`
          let result = null
          if (
            fieldInfoType === TEXT ||
            fieldInfoType === PASSWORD ||
            fieldInfoType === TEL
          ) {
            let textInputProps = {
              name: fieldName,
              type: fieldInfoType,
              value: values[fieldInfo.name],
              placeholder: fieldInfo.label,
              errorClasses,
              disabled,
              setFieldValue,
              convertToCaps: fieldInfo.convertToCaps || false,
              classNames: fieldInfo.classNames,
              maxLength: fieldInfo?.maxLength,
              onBlur
            }

            if (
              formHelpLinks?.[inputName] &&
              formHelpLinks[inputName].link &&
              formHelpLinks[inputName].linkClass &&
              formHelpLinks[inputName].linkLabel
            ) {
              textInputProps = {
                ...textInputProps,
                inputHelpLink: formHelpLinks[inputName].link,
                inputHelpLinkClass: formHelpLinks[inputName].linkClass,
                inputHelpLinkLabel: formHelpLinks[inputName].linkLabel
              }
            }
            result = renderTextInput(textInputProps)
          }

          if (fieldInfoType === SELECT_TEXT_FALLBACK) {
            const { fallbackOnKey, fallbackWhenNot } = fieldInfo
            const compareValue = values[fallbackOnKey]
            if (compareValue && !fallbackWhenNot.includes(compareValue)) {
              result = renderTextInput({
                name: fieldName,
                type: 'text',
                value: values[fieldInfo.name],
                placeholder: fieldInfo.label,
                errorClasses,
                disabled,
                setFieldValue,
                convertToCaps: fieldInfo.convertToCaps || false,
                classNames: fieldInfo.classNames,
                maxLength: fieldInfo?.maxLength
              })
            } else {
              result = RenderSelect({
                name: fieldName,
                value: values[fieldInfo.name],
                setValue: setFieldValue,
                errorClasses,
                options: fieldInfo.options,
                disabled,
                label: fieldInfo.label,
                showLabel: fieldInfo.showLabel,
                verticalLabel: fieldInfo.verticalLabel,
                conditionalValueKey: fieldInfo.conditionalValueKey,
                conditionalValue: values[fieldInfo.conditionalValueKey],
                shouldClear: fieldInfo.shouldClear
              })
            }
          }

          if (fieldInfoType === SELECT) {
            result = RenderSelect({
              name: fieldName,
              value: values[fieldInfo.name],
              setValue: setFieldValue,
              errorClasses,
              options: fieldInfo.options,
              disabled,
              label: fieldInfo.label,
              showLabel: fieldInfo.showLabel,
              verticalLabel: fieldInfo.verticalLabel,
              conditionalValueKey: fieldInfo.conditionalValueKey,
              conditionalValue: values[fieldInfo.conditionalValueKey],
              shouldClear: fieldInfo.shouldClear,
              onBlur
            })
          }

          if (fieldInfoType === CHECKBOX) {
            result = renderCheckbox({
              name: fieldName,
              value: values[fieldInfo.name],
              label: fieldInfo.label,
              errorClasses,
              disabled,
              onBlur
            })
          }

          if (fieldInfoType === TEXTAREA) {
            result = renderTextArea({
              name: fieldName,
              value: values[fieldInfo.name],
              placeholder: fieldInfo.label,
              setValue: setFieldValue,
              errorClasses,
              disabled,
              maxLength: fieldInfo?.maxLength,
              onBlur
            })
          }

          return <Fragment key={`${rowKey}-${inputName}`}>{result}</Fragment>
        })}
      </span>
    </Fragment>
  ))
}

export const checkCanUseApp = activeSessions =>
  DISABLE_WAITING_ROOM
    ? true
    : +activeSessions < process.env.REACT_APP_SESSION_LIMIT

export const formatPriceByLanguage = (price, languageKey) => {
  let result = ''
  if (price || price === 0) result = '' + price // convert to string
  if (result) result = (+result).toFixed(2) // convert to number
  if (result === '') result = (+result).toFixed(2)

  // language formatting
  result = languageKey === 'fr' ? `${result} $` : `$${result}`
  if (result.includes('.') && languageKey === 'fr') {
    result = result.replace('.', ',')
  }

  return result
}

const supportedContentComponentTypes = [
  'tooltip',
  'warningBanner',
  'linkWithHeading',
  'checkboxWithHeading'
]

export const getContentComponents = (rawContent = {}) => {
  let result = {}

  // If content has a top level "value" key, check this to render supported content components
  if (rawContent?.value) {
    const contentValue = rawContent.value

    supportedContentComponentTypes.forEach(supportedType => {
      result[supportedType] = {}
      // If the type is supported and it has a name and value
      const supportedContentComponents = contentValue.filter(
        ({ type, name, value }) =>
          name && value && type && type === supportedType
      )

      if (supportedContentComponents) {
        supportedContentComponents.forEach(({ name, type, value }) => {
          result[supportedType][name] = { value }
        })
      }
    })
  }

  return result
}

const determineHeadingAttributes = attributes => {
  let isHeadingTag = false
  let headingTag = ''

  if (attributes.includes('h1')) {
    isHeadingTag = true
    headingTag = 'h1'
  }

  if (!isHeadingTag && attributes.includes('h2')) {
    isHeadingTag = true
    headingTag = 'h2'
  }

  if (!isHeadingTag && attributes.includes('h3')) {
    isHeadingTag = true
    headingTag = 'h3'
  }

  // compatibiliy: Some of the content h1 tags are defined as headings
  if (
    (!isHeadingTag && attributes.includes('heading')) ||
    attributes.includes('h4')
  ) {
    isHeadingTag = true
    headingTag = 'h4'
  }

  if (!isHeadingTag && attributes.includes('h5')) {
    isHeadingTag = true
    headingTag = 'h5'
  }

  if (!isHeadingTag && attributes.includes('h6')) {
    isHeadingTag = true
    headingTag = 'h6'
  }

  return {
    headingTag,
    isHeadingTag
  }
}

const getHeadingTag = ({
  key,
  headingTag,
  resultingContent,
  classNames,
  id
}) => {
  let result = null

  switch (headingTag) {
    case 'h1':
      result = (
        <h1 key={key} className={classNames} id={id || null}>
          {resultingContent}
        </h1>
      )
      break
    case 'h2':
      result = (
        <h2 key={key} className={classNames} id={id || null}>
          {resultingContent}
        </h2>
      )
      break
    case 'h3':
      result = (
        <h3 key={key} className={classNames} id={id || null}>
          {resultingContent}
        </h3>
      )
      break
    case 'h4':
      result = (
        <h4 key={key} className={classNames} id={id || null}>
          {resultingContent}
        </h4>
      )
      break
    case 'h5':
      result = (
        <h5 key={key} className={classNames} id={id || null}>
          {resultingContent}
        </h5>
      )
      break
    case 'h6':
      result = (
        <h6 key={key} className={classNames} id={id || null}>
          {resultingContent}
        </h6>
      )
      break
    default:
      result = null
  }

  return result
}

export const renderContentValues = (
  contentValues = [],
  contentName,
  renderingListItem = false
) => {
  return contentValues.map(
    ({ attributes = [], interpolation = {}, value, id, link }, valueKey) => {
      const { headingTag, isHeadingTag } = determineHeadingAttributes(
        attributes
      )

      const isBold = attributes.includes('bold')
      const isItalic = attributes.includes('italic')
      const isList = attributes.includes('list')
      const isLink = attributes.includes('link')
      const isExampleList = attributes.includes('exampleList')
      const key = `${contentName}-${valueKey}`

      let classNames = isItalic ? 'u-font-italic' : ''
      let contentValue = typeof value === 'string' ? value : null
      let ComponentRepresentation = null
      let result = null

      // back through for list items
      if (!contentValue && (isList || isExampleList)) {
        contentValue = renderContentValues(value, key, true)
      }

      // if we have a valid content string value, replace with jsx if needed
      if (contentValue) {
        const attemptReplacement = Object.keys(interpolation).length > 0

        if (attemptReplacement) {
          ComponentRepresentation = renderMergedReplacements(
            contentValue,
            interpolation
          )
        }

        // Resulting content could be a string or a component
        const resultingContent = !!ComponentRepresentation
          ? ComponentRepresentation
          : contentValue

        if (isHeadingTag) {
          result = getHeadingTag({
            key,
            headingTag,
            resultingContent,
            classNames,
            id
          })
        } else if (isList || isExampleList) {
          result = (
            <ul key={key} className={classNames}>
              {resultingContent}
            </ul>
          )
        } else if (renderingListItem) {
          result = (
            <li key={key} className={classNames}>
              {resultingContent}
            </li>
          )
        } else if (isLink && link) {
          result = (
            <p
              key={key}
              className={classNames}
              id={id || null}
              dangerouslySetInnerHTML={{
                __html: resultingContent.replace(
                  '<a>',
                  `<a href="${link}" target="_blank">`
                )
              }}
            />
          )
        } else {
          result = (
            <p key={key} className={classNames} id={id || null}>
              {isBold ? <strong>{resultingContent}</strong> : resultingContent}
            </p>
          )
        }
      }

      return result
    }
  )
}

function renderMergedReplacements(contentValue, interpolations) {
  let remainingText = contentValue

  const splitAt = (str, index) => [str.slice(0, index), str.slice(index)]
  const tagOpen = '{{'
  const tagClose = '}}'
  const tagLength = 2

  let keyIndex = 0
  const result = []

  while (remainingText.indexOf(tagOpen) < remainingText.indexOf(tagClose)) {
    const keyStart = remainingText.indexOf(tagOpen) + tagLength
    const keyEnd = remainingText.indexOf(tagClose)
    const key = remainingText.substring(keyStart, keyEnd)
    const interpolationElement = renderInterpolationElement(
      interpolations[key],
      keyIndex
    )

    remainingText = remainingText.replace(`{{${key}}}`, '')
    const [before, after] = splitAt(remainingText, keyStart - tagLength)

    result.push(before)
    result.push(interpolationElement)

    remainingText = after
    keyIndex++
  }

  result.push(remainingText)

  return <>{result}</>
}

function renderInterpolationElement(interpolation, keyIndex) {
  return (
    <a
      key={`ie_${keyIndex}`}
      href={interpolation.href}
      target="_blank"
      rel="noopener noreferrer"
      title={interpolation.value}
    >
      {interpolation.value}
    </a>
  )
}
