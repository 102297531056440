import { createSelector } from 'reselect'
import { crossingTypes } from '../crossings/constants'
import { vehicleTypes, vehicleInputs } from './constants'

export const vehicleSelectionsState = state => state.vehicleSelections

export const selectInitVehicleFormType = createSelector(
  vehicleSelectionsState,
  vehicleSelections => vehicleSelections.vehicleFormType
)

export const selectDepartureVehicle = createSelector(
  vehicleSelectionsState,
  vehicleSelections => vehicleSelections[crossingTypes.DEPARTURE]
)
export const selectDepartureVehicleLength = createSelector(
  selectDepartureVehicle,
  departureVehicle => departureVehicle[vehicleInputs.LENGTH]
)
export const selectDepartureVehicleErrors = createSelector(
  vehicleSelectionsState,
  vehicleSelections => vehicleSelections[`${crossingTypes.DEPARTURE}Errors`]
)
export const selectReturnVehicle = createSelector(
  vehicleSelectionsState,
  ({ vehicleFormType, departure, ...restVehicleSelectionState }) =>
    vehicleFormType === vehicleTypes.VEHDUP ||
    vehicleFormType === vehicleTypes.VELO
      ? departure
      : restVehicleSelectionState.return
)
export const selectReturnVehicleLength = createSelector(
  selectReturnVehicle,
  returnVehicle => returnVehicle[vehicleInputs.LENGTH]
)
export const selectReturnVehicleErrors = createSelector(
  vehicleSelectionsState,
  vehicleSelections => vehicleSelections[`${crossingTypes.RETURN}Errors`]
)
export const selectContainsVehicleErrors = createSelector(
  selectDepartureVehicleErrors,
  selectReturnVehicleErrors,
  (departureVehicleErrors, returnVehicleErrors) =>
    (departureVehicleErrors &&
      Object.values(departureVehicleErrors).includes(true)) ||
    (returnVehicleErrors && Object.values(returnVehicleErrors).includes(true))
)
export const selectDepartureAndReturnVehicles = createSelector(
  selectDepartureVehicle,
  selectReturnVehicle,
  (departureVehicle, returnVehicle) => ({
    departure: departureVehicle,
    return: returnVehicle
  })
)
