import { createAction } from 'redux-actions'
import {
  SET_SESSION_CURRENCY,
  SET_SESSION_ID,
  SET_SESSION_LANGUAGE,
  START_SESSION_TIMER,
  RESET_START_SESSION_TIMER,
  SET_LATEST_BOOKING_NUMBER
} from './constants'

export const setSessionId = createAction(SET_SESSION_ID)
export const setLanguage = createAction(SET_SESSION_LANGUAGE)
export const setCurrency = createAction(SET_SESSION_CURRENCY)
export const startSessionTimer = createAction(START_SESSION_TIMER)
export const resetStartSessionTimer = createAction(RESET_START_SESSION_TIMER)
export const setLatestBooking = createAction(SET_LATEST_BOOKING_NUMBER)
