import { handleActions } from 'redux-actions'
import {
  SET_ACTIVE_DEPARTURE_CROSSING,
  SET_ACTIVE_RETURN_CROSSING,
  RESET_ACTIVE_CROSSINGS,
  RESET_RETURN_CROSSINGS
} from './constants'

const activeCrossingInitialState = {
  departure: null,
  return: null
}

const activeCrossingReducer = handleActions(
  {
    [SET_ACTIVE_DEPARTURE_CROSSING]: (state, action) => ({
      ...state,
      departure: action.payload
    }),
    [SET_ACTIVE_RETURN_CROSSING]: (state, action) => ({
      ...state,
      return: action.payload
    }),
    [RESET_RETURN_CROSSINGS]: state => ({
      ...state,
      return: null
    }),
    [RESET_ACTIVE_CROSSINGS]: () => ({ ...activeCrossingInitialState })
  },

  { ...activeCrossingInitialState }
)

export { activeCrossingInitialState }
export default activeCrossingReducer
