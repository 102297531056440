import React from 'react'
import { func, shape, bool, string } from 'prop-types'
import ModalForm from '../ModalForm'

const CancelTransactionModal = ({
  isOpen,
  onCloseButtonClick,
  onContinue,
  onCancel,
  labels,
  continueLabel,
  cancelLabel,
  suppressOnRequestClose,
  hideCloseButton
}) => {
  const continueButtonText = continueLabel
    ? continueLabel
    : labels.continueModifyingBooking
  const cancelButtonText = cancelLabel ? cancelLabel : labels.cancelBooking

  return (
    <ModalForm
      modalClasses="modal--sm"
      isOpen={isOpen}
      closeModal={!suppressOnRequestClose ? onCloseButtonClick : () => null}
      hideCloseButton={true}
    >
      <div className="modal-row">
        <div className="modal-col modal-question modal-content-padded">
          <h4>{labels.transactionCancelled}</h4>
          <p>{labels.transactionCancelledMessage}</p>
          <div className="modal-question-buttons">
            <button className="btn btn-primary" onClick={onContinue}>
              {continueButtonText}
            </button>
            <button className="btn" onClick={onCancel}>
              {cancelButtonText}
            </button>
          </div>
        </div>
      </div>
    </ModalForm>
  )
}

CancelTransactionModal.propTypes = {
  onCloseButtonClick: func.isRequired,
  onContinue: func.isRequired,
  onCancel: func.isRequired,
  labels: shape().isRequired,
  isOpen: bool,
  continueLabel: string,
  cancelLabel: string,
  suppressOnRequestClose: bool,
  hideCloseButton: bool
}

CancelTransactionModal.defaultProps = {
  isOpen: false,
  suppressOnRequestClose: false,
  hideCloseButton: false
}

export default CancelTransactionModal
