export const defaultHeaders = {
  Accept: 'application/ld+json',
  'Content-Type': 'application/ld+json'
}

export const requiredEmptyUserStrings = {
  title: '',
  dateOfBirth: '',
  contactName: '',
  annotations: []
}
