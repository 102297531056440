import React from 'react'
import { oneOfType, bool, func, node, string } from 'prop-types'
import Modal from '../Modal'
import { ModalForm } from '../ModalForm'

const ModalFormContainer = ({
  children,
  isOpen,
  closeModal,
  formTitle,
  closeLabel,
  modalClasses,
  overlayClasses,
  hideCloseButton
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    contentLabel={formTitle}
    className={modalClasses}
    overlayClassName={overlayClasses}
  >
    <ModalForm
      title={formTitle}
      closeLabel={closeLabel}
      closeModal={closeModal}
      hideCloseButton={hideCloseButton}
    >
      {children}
    </ModalForm>
  </Modal>
)

ModalFormContainer.propTypes = {
  children: node.isRequired,
  isOpen: bool.isRequired,
  closeModal: func.isRequired,
  formTitle: oneOfType([string, bool]),
  closeLabel: string,
  modalClasses: string,
  overlayClasses: string,
  hideCloseButton: bool
}

ModalFormContainer.defaultProps = {
  closeLabel: 'Close X',
  modalClasses: 'modal--sm',
  overlayClasses: ''
}

export default ModalFormContainer
