import React, { Fragment } from 'react'
import { arrayOf, shape, oneOfType, bool, func } from 'prop-types'
import Modal from '../Modal'
import { useSelector } from 'react-redux'
import cfCard from '../../assets/images/cfCard.png'
import vCard from '../../assets/images/vCard.png'
import { selectLabels } from '../../redux/configuration/selectors'
import { renderContentValues } from '../../redux/configuration/utilities'
import './CanadianForcesCodeModal.scss'

const CanadianForcesCodeModal = ({ closeModal, isOpen, content }) => {
  const labels = useSelector(selectLabels)
  let firstValueSet = null
  let secondValueSet = null

  if (content) {
    firstValueSet = content.slice(0, 9)
    secondValueSet = content.slice(9 + 1)
  }

  return !isOpen ? null : (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal--med base-modal"
    >
      <Fragment>
        {firstValueSet && secondValueSet ? (
          <div className="base-modal-content">
            {renderContentValues(firstValueSet, 'canadian-forces')}
            <div className="base-modal-row">
              <div className="base-modal-col">
                <h3>{labels.cfCard}</h3>
                <img src={cfCard} alt={labels.cfCard} role="none" />
              </div>
              <div className="base-modal-col">
                <h3>{labels.vCard}</h3>
                <img src={vCard} alt={labels.vCard} role="none" />
              </div>
            </div>
            {renderContentValues(secondValueSet, 'canadian-forces-additional')}
          </div>
        ) : null}
        <div className="service-message-modal-buttons">
          <button
            className="btn btn-primary"
            onClick={() => closeModal({ type: 'canadian-forces' })}
          >
            {labels.close}
          </button>
        </div>
      </Fragment>
    </Modal>
  )
}

CanadianForcesCodeModal.propTypes = {
  closeModal: func.isRequired,
  isOpen: bool,
  content: oneOfType([bool, arrayOf(shape())]).isRequired
}

CanadianForcesCodeModal.defaultProps = {
  isOpen: false
}

export default CanadianForcesCodeModal
