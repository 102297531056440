import { all } from 'redux-saga/effects'
import modalSaga from './modal/sagas'
import sessionSaga from './session/sagas'
// import travelAgentSaga from './travelAgent/sagas'
import userSaga from './user/sagas'

export default function* rootSaga() {
  yield all([
    modalSaga(),
    sessionSaga(),
    // travelAgentSaga(),
    userSaga()
  ])
}
