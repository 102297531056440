import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { func } from 'prop-types'
import {
  selectLabels,
  selectAccessibilityResources,
  selectAccessibilityModalContent,
  // selectAccessibilityCardContent,
  selectAccommodationsContent
} from '../../redux/configuration/selectors'
import {
  selectAccessibilityResponses
  // selectAccessibilityResponseCount
} from '../../redux/accessibility/selectors'
import { selectPassengerSelections } from '../../redux/passengerSelections/selectors'
import { selectKennelCardDetails } from '../../redux/selectors/selectKennelCardDetails'
import { setAccessibilityResponses } from '../../redux/accessibility/actions'
import * as UserSelectionActions from '../../redux/userSelections/actions'
import { addActiveAccommodations } from '../../redux/activeAccommodations/actions'
import selectKennelQuantitiesCardState from '../../redux/selectors/selectKennelQuantitiesCardState'
import appRoutes from '../../configuration/appRoutes'

import ExtrasCard from '../../components/ExtrasCard/ExtrasCard'
import KennelQuantitiesCard from '../../components/KennelQuantitiesCard/KennelQuantitiesCard'
import AccessibilityModal from '../../components/AccessibilityModal/AccessibilityModal'

import '../../styles/scss/components/extras/extras.scss'
import Modal from '../../components/Modal'
import SeatPickerContainer from '../../components/SeatPicker/SeatPickerContainer'
import ReservedSeatCard from '../../components/ReservedSeatCard/ReservedSeatCard'
import { selectActiveCrossings } from '../../redux/activeCrossings/selectors'
import {
  setDepartureCrossing,
  setReturnCrossing
} from '../../redux/activeCrossings/actions'
import { selectActiveModifyBooking } from '../../redux/modifyBooking/selectors'

const ExtrasContainer = ({ handleRouteChange }) => {
  const labels = useSelector(selectLabels)
  const dispatch = useDispatch()
  const accessibilityModalOptions = useSelector(selectAccessibilityResources)
  const accessibilityModalContent = useSelector(selectAccessibilityModalContent)
  // const accessibilityCardContent = useSelector(selectAccessibilityCardContent)
  const accessibilityResponses = useSelector(selectAccessibilityResponses)
  /** const accessibilityResponseCount = useSelector(
    selectAccessibilityResponseCount
  ) **/
  const kennelCardDetails = useSelector(selectKennelCardDetails)
  const kennelSelectionState = useSelector(selectKennelQuantitiesCardState)
  const accommodationsContent = useSelector(selectAccommodationsContent)
  const activeCrossings = useSelector(selectActiveCrossings)
  const activeModifyBooking = useSelector(selectActiveModifyBooking)
  const passengersData = useSelector(selectPassengerSelections)

  const departureCrossing = activeCrossings?.departure
  const returnCrossing = activeCrossings?.return
  const seatResourceCode = 'RDS'
  const [accessibilityModalVisible, setAccessibilityModalVisible] = useState(
    false
  )

  const lowestSeatPrice = [departureCrossing, returnCrossing].reduce(
    (acc, cur) => {
      const price = cur?.resources?.find(
        item => item.resourceCode === seatResourceCode
      )?.price
      return price < acc ? price : acc
    },
    Infinity
  )

  const [departureSeatPickerOpen, setDepartureSeatPickerOpen] = useState(false)
  const [returnSeatPickerOpen, setReturnSeatPickerOpen] = useState(false)
  const [kennelCardOpen, setKennelCardOpen] = useState(false)

  const getNumberOfPassengers = passengersObj => {
    return (
      Number(passengersObj.adult) +
      Number(passengersObj.senior) +
      Number(passengersObj.child) +
      Number(passengersObj.student)
    )
  }

  const departurePassengers = departureCrossing
    ? getNumberOfPassengers(passengersData.departure)
    : 0
  const returnPassengers = returnCrossing
    ? getNumberOfPassengers(passengersData.return)
    : 0

  /** TODO: re-enable
  const accessibilityCardDetails = {
    buttonText: `${labels.selectAccessibilityOptions} (${accessibilityResponseCount})`,
    imageUrl: accessibilityCardContent?.imageUrl,
    content: accessibilityCardContent?.value
  }
  **/

  const handleAccessibilitySave = values => {
    dispatch(setAccessibilityResponses(values)) // TODO: Deprecate
    dispatch(UserSelectionActions.setAccessibilityResponses(values))
  }

  function handleDepartureSeatPickerSubmit(seats) {
    dispatch(
      setDepartureCrossing({
        ...departureCrossing,
        reservedSeats: seats
      })
    )
    setDepartureSeatPickerOpen(false)
  }

  function handleReturnSeatPickerSubmit(seats) {
    dispatch(
      setReturnCrossing({
        ...returnCrossing,
        reservedSeats: seats
      })
    )
    setReturnSeatPickerOpen(false)
  }

  function handleDepatureSeatClick() {
    setDepartureSeatPickerOpen(true)
  }

  function handleReturnSeatClick() {
    setReturnSeatPickerOpen(true)
  }

  let showReservedSeatsDeparture = false
  if (
    departureCrossing?.resources?.find(d => d.resourceCode === seatResourceCode)
  ) {
    showReservedSeatsDeparture = true
  }

  let showReservedSeatsReturn = false
  if (
    returnCrossing?.resources?.find(r => r.resourceCode === seatResourceCode)
  ) {
    showReservedSeatsReturn = true
  }

  return (
    <div className="extras u-container u-page-view-container">
      <div className="extras__content">
        <h2 className="theme-font-header" id={`${seatResourceCode}-header`}>
          {labels.extras}
        </h2>
        <div className="extras__card-container">
          {/* left card TODO: re-enable */}
          {/*           <ExtrasCard
            {...accessibilityCardDetails}
            labels={labels}
            onButtonClick={() =>
              setAccessibilityModalVisible(!accessibilityModalVisible)
            }
          /> */}
          {/* right card */}
          {kennelCardDetails && kennelCardOpen ? (
            <KennelQuantitiesCard
              {...kennelCardDetails}
              labels={labels}
              selections={kennelSelectionState}
              accommodationsContent={accommodationsContent}
              onCloseClick={() => setKennelCardOpen(false)}
              onConfirmClick={selectedQuantities => {
                dispatch(addActiveAccommodations(selectedQuantities))
                setKennelCardOpen(false)
              }}
            />
          ) : (
            kennelCardDetails && (
              <ExtrasCard
                {...kennelCardDetails}
                labels={labels}
                onButtonClick={() => setKennelCardOpen(true)}
                resourceCode={seatResourceCode}
              />
            )
          )}
          {lowestSeatPrice !== Infinity && (
            <ReservedSeatCard
              onDepartureClick={handleDepatureSeatClick}
              onReturnClick={handleReturnSeatClick}
              showDepartureButton={showReservedSeatsDeparture}
              showReturnButton={!!returnCrossing && showReservedSeatsReturn}
              resourceCode={seatResourceCode}
              startingPrice={lowestSeatPrice}
              departureSeats={departureCrossing.reservedSeats}
              returnSeats={returnCrossing?.reservedSeats}
              departureCrossing={departureCrossing}
              returnCrossing={returnCrossing}
            />
          )}
        </div>
      </div>
      <AccessibilityModal
        labels={labels}
        isOpen={accessibilityModalVisible}
        options={accessibilityModalOptions}
        values={accessibilityResponses}
        contentValues={accessibilityModalContent}
        onSave={handleAccessibilitySave}
        onRequestClose={() => setAccessibilityModalVisible(false)}
      />
      <Modal
        className="modal--xl"
        isOpen={departureSeatPickerOpen}
        onRequestClose={() => setDepartureSeatPickerOpen(false)}
      >
        <SeatPickerContainer
          supplierCode={departureCrossing.supplier}
          routeCode={departureCrossing.routeCode}
          shipCode={departureCrossing.shipCode}
          resourceCode={seatResourceCode}
          startDate={departureCrossing.departureDate}
          startTime={departureCrossing.departureTime}
          productCode={departureCrossing.productCode}
          passengerType={departureCrossing.ticketType}
          defaultSelectedSeats={departureCrossing.reservedSeats}
          onCloseClick={() => setDepartureSeatPickerOpen(false)}
          onSubmit={handleDepartureSeatPickerSubmit}
          seatsFromModification={activeModifyBooking?.departureSeats}
          activeModifyBooking={activeModifyBooking?.departures?.[0]}
          numberPassengers={departurePassengers}
        />
      </Modal>
      {returnCrossing && (
        <Modal
          className="modal--xl"
          isOpen={returnSeatPickerOpen}
          onRequestClose={() => setReturnSeatPickerOpen(false)}
        >
          <SeatPickerContainer
            supplierCode={returnCrossing?.supplier}
            routeCode={returnCrossing?.routeCode}
            shipCode={returnCrossing?.shipCode}
            resourceCode={seatResourceCode}
            startDate={returnCrossing?.departureDate}
            startTime={returnCrossing?.departureTime}
            productCode={returnCrossing?.productCode}
            passengerType={returnCrossing?.ticketType}
            defaultSelectedSeats={returnCrossing?.reservedSeats}
            onCloseClick={() => setReturnSeatPickerOpen(false)}
            onSubmit={handleReturnSeatPickerSubmit}
            seatsFromModification={activeModifyBooking?.returnSeats}
            activeModifyBooking={activeModifyBooking?.departures?.[1]}
            numberPassengers={returnPassengers}
          />
        </Modal>
      )}

      <div className="u-page-submit-container">
        <button
          className="btn btn-primary large-primary-btn"
          onClick={() => handleRouteChange(appRoutes.summary.pathname)}
        >
          {labels.continueToSummaryBtn} →
        </button>
      </div>
    </div>
  )
}

ExtrasContainer.propTypes = {
  handleRouteChange: func.isRequired
}

export default ExtrasContainer
