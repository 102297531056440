import { handleActions } from 'redux-actions'
import { crossingTypes } from '../crossings/constants'
import {
  vehicleSelectionDefaultState,
  vehicleSelectionDefaultErrorState,
  vehicleSections
} from './constants'

import {
  SET_VEHICLE_FORM_TYPE,
  SET_VEHICLE_DETAILS,
  RESET_VEHICLE_SELECTIONS,
  SET_VEHICLE_ERROR,
  CLEAR_VEHICLE_ERROR,
  CLEAR_VEHICLE_ERRORS,
  vehicleErrorStateKeysObject
} from './constants'

const vehicleSelectionsInitialState = {
  [vehicleSections.FORM_TYPE]: '',
  [crossingTypes.DEPARTURE]: { ...vehicleSelectionDefaultState },
  [crossingTypes.RETURN]: { ...vehicleSelectionDefaultState },
  [vehicleErrorStateKeysObject.DEPARTURE]: {
    ...vehicleSelectionDefaultErrorState
  },
  [vehicleErrorStateKeysObject.RETURN]: { ...vehicleSelectionDefaultErrorState }
}

const vehicleSelectionsReducer = handleActions(
  {
    [SET_VEHICLE_DETAILS]: (state, { payload }) => {
      const { crossingType, vehicle } = payload
      return {
        ...state,
        [crossingType]: {
          ...vehicle
        }
      }
    },
    [SET_VEHICLE_ERROR]: (state, { payload }) => {
      const copiedState = { ...state }

      if (payload) {
        const { crossingType, errorkey, errorValue } = payload
        if (
          crossingType &&
          Object.values(vehicleErrorStateKeysObject).includes(
            `${crossingType}Errors`
          ) &&
          errorkey &&
          Object.keys(vehicleSelectionDefaultErrorState).includes(errorkey)
        ) {
          copiedState[`${crossingType}Errors`] = {
            ...copiedState[`${crossingType}Errors`],
            [errorkey]: errorValue || true
          }
        }
      }

      return copiedState
    },
    [CLEAR_VEHICLE_ERROR]: (state, { payload }) => {
      const copiedState = { ...state }

      if (payload) {
        const { crossingType, errorkey } = payload
        if (
          crossingType &&
          Object.values(vehicleErrorStateKeysObject).includes(
            `${crossingType}Errors`
          ) &&
          errorkey &&
          Object.keys(vehicleSelectionDefaultErrorState).includes(errorkey)
        ) {
          copiedState[`${crossingType}Errors`] = {
            ...copiedState[`${crossingType}Errors`],
            [errorkey]: false
          }
        }
      }

      return copiedState
    },
    [CLEAR_VEHICLE_ERRORS]: state => ({
      ...state,
      [vehicleErrorStateKeysObject.DEPARTURE]: {
        ...vehicleSelectionDefaultErrorState
      },
      [vehicleErrorStateKeysObject.RETURN]: {
        ...vehicleSelectionDefaultErrorState
      }
    }),
    [SET_VEHICLE_FORM_TYPE]: (state, { payload }) => ({
      ...state,
      [vehicleSections.FORM_TYPE]: payload || ''
    }),
    [RESET_VEHICLE_SELECTIONS]: () => ({ ...vehicleSelectionsInitialState })
  },
  { ...vehicleSelectionsInitialState }
)

export { vehicleSelectionsInitialState }
export default vehicleSelectionsReducer
