import { createAction } from 'redux-actions'
import {
  SET_VEHICLE_FORM_TYPE,
  SET_VEHICLE_DETAILS,
  RESET_VEHICLE_SELECTIONS,
  SET_VEHICLE_DETAILS_SUCCESS,
  SET_VEHICLE_DETAILS_ERROR,
  SET_VEHICLE_ERROR,
  CLEAR_VEHICLE_ERROR,
  CLEAR_VEHICLE_ERRORS
} from './constants'
import { invalidVehicleDataOnSet } from './utilities'

export const setVehicleFormType = createAction(SET_VEHICLE_FORM_TYPE)
export const setVehicleDetails = createAction(SET_VEHICLE_DETAILS)
export const resetVehicleSelections = createAction(RESET_VEHICLE_SELECTIONS)
export const setVehicleDetailsSuccess = createAction(
  SET_VEHICLE_DETAILS_SUCCESS
)
export const setVehicleDetailsError = createAction(SET_VEHICLE_DETAILS_ERROR)
export const setVehicleError = createAction(SET_VEHICLE_ERROR)
export const clearVehicleError = createAction(CLEAR_VEHICLE_ERROR)
export const clearVehicleErrors = createAction(CLEAR_VEHICLE_ERRORS)

export const updateVehicleDetails = ({
  vehicleDetails = {},
  crossingType = ''
}) => dispatch => {
  const isInvalidError = invalidVehicleDataOnSet(vehicleDetails, crossingType)
  if (isInvalidError) return dispatch(setVehicleDetailsError(isInvalidError))

  const {
    vehicleType,
    vehicleLength,
    vehicleLicense,
    vehicleGroupTypeHeight,
    vehicleGroupTypeLength
  } = vehicleDetails

  dispatch(
    setVehicleDetails({
      crossingType,
      vehicle: {
        vehicleType,
        vehicleLength,
        vehicleLicense,
        vehicleGroupTypeHeight,
        vehicleGroupTypeLength
      }
    })
  )

  return dispatch(setVehicleDetailsSuccess({ crossingType }))
}
