import { createSelector } from 'reselect'

export const selectCrossingState = state => state.crossingsData

export const selectCrossingData = createSelector(
  selectCrossingState,
  crossingState => crossingState
)

export const selectDepartureCrossing = createSelector(
  selectCrossingState,
  crossingState => crossingState.departure
)

export const selectReturnCrossing = createSelector(
  selectCrossingState,
  crossingState => crossingState.return
)

export const selectCrossingsUnmodified = createSelector(
  selectCrossingState,
  crossingState => crossingState.responses
)

export const selectIsDepartureCrossingsLoading = createSelector(
  selectCrossingState,
  crossingState => !!crossingState?.departure?.loading
)

export const selectIsReturnCrossingsLoading = createSelector(
  selectCrossingState,
  crossingState => !!crossingState?.return?.loading
)
