import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { string, bool, shape, func } from 'prop-types'
import yn from 'yn'
import { selectModifyFlowType } from '../../redux/modifyBooking/selectors'
import { renderFormFields } from '../../redux/configuration/utilities'
import { getFieldsAndLayout } from './utilities'
import { ModifyFlowType } from '../../configuration/constants'
import './PassengerForm.scss'

const PassengerForm = ({
  fieldDetails,
  disabled,
  values,
  rowLabels,
  setFieldValue,
  formRowClasses,
  errorClasses,
  parentKey,
  paymentFerryId,
  onBlur
}) => {
  const modifyFlowType = useSelector(selectModifyFlowType)
  const { mainLayout, optionalLayout } = getFieldsAndLayout({
    paymentFerryId
  })

  let previousPassengerType = null
  return !fieldDetails || !values ? null : (
    <Fragment>
      {Object.keys(values).map(passengerId => {
        const passengerValue = values[passengerId]
        const renderDivider =
          passengerValue.type === 'returns' &&
          previousPassengerType === 'departures' &&
          modifyFlowType !== ModifyFlowType.DEPARTURE_LOCKED
        previousPassengerType = passengerValue.type

        if (
          modifyFlowType === ModifyFlowType.DEPARTURE_LOCKED &&
          passengerValue.type === 'departures'
        ) {
          return null
        }

        return (
          <Fragment key={passengerId}>
            {renderDivider && (
              <div className="rule-container">
                <hr />
              </div>
            )}
            {rowLabels?.[passengerId] && (
              <span className={formRowClasses}>
                <span className="row-label theme-title-variant">
                  {rowLabels[passengerId]}
                </span>
              </span>
            )}
            {renderFormFields({
              formLayout: mainLayout,
              fieldDetails,
              disabled,
              values: passengerValue,
              setFieldValue,
              formRowClasses,
              errorClasses,
              parentKey: parentKey
                ? `${parentKey}.${passengerId}`
                : passengerId,
              onBlur
            })}
            {yn(values[passengerId].accessibility) &&
              renderFormFields({
                formLayout: optionalLayout,
                fieldDetails,
                disabled,
                values: passengerValue,
                setFieldValue,
                formRowClasses,
                errorClasses,
                parentKey: `${parentKey}.${passengerId}`,
                onBlur
              })}
          </Fragment>
        )
      })}
    </Fragment>
  )
}

PassengerForm.propTypes = {
  fieldDetails: shape().isRequired,
  disabled: bool,
  values: shape().isRequired,
  rowLabels: shape().isRequired,
  setFieldValue: func.isRequired,
  formRowClasses: string.isRequired,
  errorClasses: string.isRequired,
  parentKey: string,
  paymentFerryId: string.isRequired
}

PassengerForm.defaultProps = {
  disabled: false,
  parentKey: 'passengerDetails'
}

export default PassengerForm
