import { createSelector } from 'reselect'
import { transformBookingsArray } from '../../utilities/bookings/transformBookingsArray'

export const selectUserBookingState = state => state.userBookings

export const selectUserBookings = createSelector(
  selectUserBookingState,
  bookingState => {
    const bookings = bookingState?.data

    return transformBookingsArray(bookings)
  }
)

export const selectUserBookingsLoading = createSelector(
  selectUserBookingState,
  bookingState => bookingState?.loading || false
)

export const selectUserBookingsLoadingError = createSelector(
  selectUserBookingState,
  bookingState => bookingState?.error || false
)

export const selectNonRefundedModalVisible = createSelector(
  selectUserBookingState,
  bookingState => bookingState?.nonRefundedModalVisible
)
