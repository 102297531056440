import React, { useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { fetchCrossing } from '../../redux/crossings/actions'
import { selectDepartureCrossing } from '../../redux/crossings/selectors'

import {
  selectActiveRouteForm,
  selectDepartureDate,
  selectDepartureRoute
} from '../../redux/ferryRouteSelections/selectors'

import { setDepartureCrossing } from '../../redux/activeCrossings/actions'
import * as UserSelectionActions from '../../redux/userSelections/actions'
import { selectActiveDepartureCrossing } from '../../redux/activeCrossings/selectors'
import {
  selectAccommodationsContent,
  selectLabels
} from '../../redux/configuration/selectors'
import appRoutes from '../../configuration/appRoutes'
import {
  ENABLE_ACCOMMODATIONS,
  ENABLE_EXTRAS
} from '../../configuration/constants'
import { selectActiveModifyBooking } from '../../redux/modifyBooking/selectors'
import { selectDepartureVehicle } from '../../redux/vehicleSelections/selectors'
import HighlightedCrossingRouteSelection from '../../components/RouteSelection/HighlightedCrossingRouteSelection'
import { selectUserSelections } from '../../redux/userSelections/selectors'

import '../../styles/scss/components/crossing-selections/crossing-selections.scss'
import { selectPassengerSelections } from '../../redux/passengerSelections/selectors'

const DepartureRouteSelection = ({ handleRouteChange }) => {
  // TODO: need a no routes available message

  const labels = useSelector(selectLabels)
  const dispatch = useDispatch()
  const departureRoute = useSelector(selectDepartureRoute)
  const departureDate = useSelector(selectDepartureDate)
  const crossingsData = useSelector(selectDepartureCrossing)
  const userSelections = useSelector(selectUserSelections)

  const currentActiveDeparture = useSelector(selectActiveDepartureCrossing)

  const activeRouteForm = useSelector(selectActiveRouteForm)
  const isReturnTrip = activeRouteForm === 'RT'

  const activeModifyBooking = useSelector(selectActiveModifyBooking)

  const activeVehicle = useSelector(selectDepartureVehicle)

  const selectedPassengers = useSelector(selectPassengerSelections)
  const totalPassengers = Object.values(
    selectedPassengers?.departure || {}
  ).reduce((num, passengerCount) => {
    return (num += passengerCount)
  }, 0)

  const activeVehicleResourceCode = activeVehicle?.vehicleType?.resourceCode

  const accommodationsContent = useSelector(selectAccommodationsContent)

  const cabinResourceCodes = Object.values(
    accommodationsContent?.accommodations ?? []
  )
    .filter(accommodation => !Number(accommodation?.isExtra))
    .map(accommodation => accommodation.resourceCode)

  useEffect(() => {
    dispatch(
      fetchCrossing('departure', {
        setActiveCrossingFromModification: Boolean(
          activeModifyBooking?.departureRoute
        ),
        activeModifyBooking: activeModifyBooking?.departureRoute
      })
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = !crossingsData || crossingsData.loading

  const error = crossingsData && crossingsData.error

  let continueBtnLabel, continueBtnRoute
  if (isReturnTrip) {
    continueBtnLabel = labels.continueToReturnTrip
    continueBtnRoute = appRoutes.return.pathname
  } else if (ENABLE_ACCOMMODATIONS) {
    continueBtnLabel = labels.continueToAccommodationsBtn
    continueBtnRoute = appRoutes.accommodations.pathname
  } else if (ENABLE_EXTRAS) {
    continueBtnLabel = labels.continueToExtrasBtn
    continueBtnRoute = appRoutes.extras.pathname
  } else {
    continueBtnLabel = labels.continueToSummaryBtn
    continueBtnRoute = appRoutes.summary.pathname
  }

  if (error && error.length) {
    return <p className="u-text-center">{error}</p>
  }

  const handleCrossingSelection = crossing => {
    const { departureDate, departureTime } = crossing
    dispatch(
      UserSelectionActions.setDepartureCrossing({
        date: moment(departureDate).format('YYYY-MM-DD'),
        time: departureTime
      })
    )
    dispatch(setDepartureCrossing(crossing)) // TODO: Deprecate
  }

  return (
    <HighlightedCrossingRouteSelection
      crossings={crossingsData && crossingsData.crossings}
      departureDate={departureDate}
      route={departureRoute}
      selectCrossing={handleCrossingSelection}
      currentSelection={currentActiveDeparture}
      continueButtonText={continueBtnLabel}
      continueIsDisabled={
        !currentActiveDeparture ||
        !crossingsData?.crossings ||
        Object.keys(crossingsData.crossings).length === 0
      }
      continueClickHandler={() => {
        try {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'analytics-departure-1',
            details: userSelections
          })
        } catch (err) {
          console.log('Analytics error: ' + err)
        }

        handleRouteChange(continueBtnRoute)
      }}
      newRoutesClickHandler={() => {
        handleRouteChange(appRoutes.ferryRoutes)
      }}
      isLoading={isLoading}
      error={error}
      activeVehicleResourceCode={activeVehicleResourceCode}
      activeVehicle={activeVehicle}
      titleLabel={labels.departure}
      totalPassengers={totalPassengers}
      cabinResourceCodes={cabinResourceCodes}
    />
  )
}

export default DepartureRouteSelection
