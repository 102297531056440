import React from 'react'
import { bool, shape, func, string } from 'prop-types'
import { useSelector } from 'react-redux'
import PassengerForm from './PassengerForm'
import RowGroup from '../RowGroup/RowGroup'
import { selectFields } from '../../redux/configuration/selectors'
import { selectPaymentFerryId } from '../../redux/paymentConfirmation/selectors'
import { filterFieldsByName } from '../../redux/configuration/utilities'
import { getPassengerLabel } from './utilities'
import { getFieldsAndLayout } from './utilities'

const PassengerDetailsForm = ({
  fieldDetails,
  labels,
  values,
  disabled,
  setFieldValue,
  duplicatePassengerQuantities,
  onBlur,
  parentKey
}) => {
  const rowLabels = {}
  const fields = useSelector(selectFields)
  const paymentFerryId = useSelector(selectPaymentFerryId)
  const { fieldNames } = getFieldsAndLayout({
    paymentFerryId
  })
  // note: Using defaultFieldDetails to not display ages in row labels
  const defaultFieldDetails = filterFieldsByName(fields, fieldNames)
  Object.keys(values).forEach((passengerId, arrayKey) => {
    rowLabels[passengerId] = `${getPassengerLabel({
      fieldDetails: defaultFieldDetails,
      passenger: values[passengerId],
      duplicateQuantities: duplicatePassengerQuantities,
      labels
    })} ${passengerId.substring(passengerId.lastIndexOf('-') + 1)}`
  })

  return (
    <RowGroup
      title={labels.passengerDetails}
      convertInputsToCaps
      render={({ formRowClasses, errorClasses }) => (
        <PassengerForm
          fieldDetails={fieldDetails}
          disabled={disabled}
          values={values}
          rowLabels={rowLabels}
          setFieldValue={setFieldValue}
          formRowClasses={formRowClasses}
          errorClasses={errorClasses}
          paymentFerryId={paymentFerryId}
          onBlur={onBlur}
          parentKey={parentKey}
        />
      )}
    />
  )
}

PassengerDetailsForm.propTypes = {
  fieldDetails: shape().isRequired,
  labels: shape().isRequired,
  disabled: bool.isRequired,
  values: shape().isRequired,
  setFieldValue: func.isRequired,
  duplicatePassengerQuantities: bool,
  onBlur: func,
  parentKey: string
}

export default PassengerDetailsForm
