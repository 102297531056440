import React from 'react'
import { string, number, func, bool, oneOfType } from 'prop-types'
import '../../styles/scss/base/counter.scss'

const Counter = ({
  value,
  handleDecrement,
  handleIncrement,
  disableDecrement,
  disableIncrement,
  disabled,
  size,
  increaseLabel,
  reduceLabel
}) => {
  let flexClass, valueClass, buttonClass
  switch (size) {
    case 'small':
      flexClass = 'flex-container sm'
      valueClass = 'sm'
      break
    case 'xsmall':
      flexClass = 'flex-container xs'
      valueClass = 'xs'
      break
    default:
      flexClass = 'flex-container'
      valueClass = 'value'
      break
  }
  return (
    <div className={`custom-counter${disabled ? ' disabled' : ''}`}>
      <div className={flexClass}>
        <button
          className={buttonClass}
          onClick={handleDecrement}
          disabled={disableDecrement}
          aria-label={reduceLabel || 'Reduce'}
        >
          -
        </button>
        <p className={valueClass}>{value}</p>
        <button
          className={buttonClass}
          onClick={handleIncrement}
          disabled={disableIncrement}
          aria-label={increaseLabel || 'Increase'}
        >
          +
        </button>
      </div>
    </div>
  )
}

Counter.propTypes = {
  value: oneOfType([string, number]).isRequired,
  handleDecrement: func.isRequired,
  handleIncrement: func.isRequired,
  disableDecrement: bool,
  disableIncrement: bool,
  disabled: bool,
  size: string,
  increaseLabel: string,
  reduceLabel: string
}

export default Counter
