import { createAction } from 'redux-actions'
import {
  OPEN,
  CLOSE,
  CONTINUE,
  CONTINUE_TO_PATHNAME,
  CLEAR_PATHNAME,
  LOGIN_PATHNAME
} from './constants'

export const open = createAction(OPEN)
export const close = createAction(CLOSE)
export const continueFromModal = createAction(CONTINUE)
export const continueToPathname = createAction(CONTINUE_TO_PATHNAME)
export const clearPathname = createAction(CLEAR_PATHNAME)
export const setLoginPathName = createAction(LOGIN_PATHNAME)
