import React from 'react'
import PropTypes, { func, string, object, bool } from 'prop-types'
import { useSelector } from 'react-redux'
import SvgIcon from '../SvgIcon/SvgIcon'
import ConfigConsumer from '../../redux/configuration/context'
import { selectLabels } from '../../redux/configuration/selectors'

const SummaryTableRow = ({
  handleRouteChange,
  label,
  iconType,
  value,
  cost,
  changeRoute,
  rowClassName,
  editable,
  currencyLabel
}) => {
  const ferryLabels = useSelector(selectLabels)

  return (
    <tr className={`summary-table__row summary-table__row-${rowClassName}`}>
      {iconType && (
        <ConfigConsumer>
          {({ themeVariables }) => (
            <td className="summary-table__row-icon">
              <SvgIcon
                type={iconType}
                className="summary-icon"
                fill={themeVariables.themeIconColor}
              />
            </td>
          )}
        </ConfigConsumer>
      )}
      <td
        className="summary-table__row-label theme-title-variant"
        colSpan={iconType ? '1' : '2'}
        id={`${iconType}-label`}
      >
        {label && <span>{label}</span>}
      </td>
      <td className="summary-table__row-value">
        {value && <span>{value}</span>}
      </td>
      <td className="summary-table__row-cost">
        {cost && <span>{`${cost} ${currencyLabel}`}</span>}
      </td>
      <td className="summary-table__row-edit">
        {changeRoute && editable && (
          <button
            aria-label={`${ferryLabels.change.charAt(0).toUpperCase() +
              ferryLabels.change.slice(1)} ${label}`}
            onClick={() => handleRouteChange(changeRoute)}
          >
            {ferryLabels.change}
          </button>
        )}
      </td>
    </tr>
  )
}

SummaryTableRow.defautProps = {
  editable: false,
  currencyLabel: ''
}

SummaryTableRow.propTypes = {
  handleRouteChange: func,
  label: string,
  iconType: string,
  value: PropTypes.oneOfType([string, object]),
  cost: string,
  changeRoute: string,
  rowClassName: string,
  editable: bool,
  currencyLabel: string
}

export default SummaryTableRow
