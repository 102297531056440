import React from 'react'
import { shape } from 'prop-types'
import { useSelector } from 'react-redux'

import { selectAccessibilityResponses } from '../../redux/accessibility/selectors'
import { selectAccessibilityResources } from '../../redux/configuration/selectors'

const AccessibilitySummary = ({ labels }) => {
  const resources = useSelector(selectAccessibilityResources)
  const responses = useSelector(selectAccessibilityResponses)

  const shouldRender = Object.values(responses).find(x => x === true)

  if (!shouldRender) {
    return null
  }

  return (
    <div className="summary-container summary-container__total">
      <div className="summary-container__header theme-font-header">
        <span className="summary-container__header-label">
          {labels.accessibilityInformation}
        </span>
      </div>
      <div className="u-md-up">
        <ul>
          {Object.keys(responses).map(resourceCode => {
            if (!responses[resourceCode]) {
              return null
            }
            const resource = resources.find(x => x.code === resourceCode)
            return (
              <li className="summary-table__row-value" key={resourceCode}>
                {resource.description}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

AccessibilitySummary.propTypes = {
  labels: shape().isRequired
}

export default AccessibilitySummary
