import React, { Fragment } from 'react'
import { func, shape } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { ENABLE_FORCES_INPUT } from '../../configuration/constants'
import appRoutes from '../../configuration/appRoutes'
import modalIds from '../../context/configuration'

import { clearActiveModifyBooking } from '../../redux/modifyBooking/actions'

import {
  selectLabels,
  selectForcesCodeModalContent
} from '../../redux/configuration/selectors'
import { selectActiveModifyBooking } from '../../redux/modifyBooking/selectors'

import LoginModalContainer from '../../components/LoginModalContainer/LoginModalContainer'
import ServiceMessageModal from '../../components/ServiceMessageModal/ServiceMessageModal'

import CanadianForcesCodeModal from '../../components/CanadianForcesCodeModal/CanadianForcesCodeModal'
import WaitingRoomModal from '../../components/WaitingRoomModal/WaitingRoomModal'
import WebsiteStatusModal from '../../components/WebsiteStatusModal/WebsiteStatusModal'
import LoadingModal from '../../components/LoadingModal/LoadingModal'
import ModifyBookingModal from '../../components/ModifyBookingModal/ModifyBookingModal'

const GlobalModals = ({
  handleRouteChange,
  modalIsOpen,
  openModal,
  closeModal,
  modalContinue
}) => {
  const dispatch = useDispatch()
  const labels = useSelector(selectLabels)
  const canadianForcesCodeModalContent = useSelector(
    selectForcesCodeModalContent
  )
  const activeModifyBooking = useSelector(selectActiveModifyBooking)

  return (
    <Fragment>
      {ENABLE_FORCES_INPUT && (
        <CanadianForcesCodeModal
          isOpen={Object.keys(modalIsOpen).includes('canadian-forces')}
          closeModal={closeModal}
          content={canadianForcesCodeModalContent}
        />
      )}
      <ServiceMessageModal
        isOpen={Object.keys(modalIsOpen).includes('ferry-service-message')}
        message={
          Object.keys(modalIsOpen).includes('ferry-service-message') &&
          modalIsOpen['ferry-service-message'].message
        }
        closeModal={closeModal}
      />
      <LoginModalContainer
        modalIsOpen={modalIsOpen}
        openModal={openModal}
        closeModal={closeModal}
        modalContinue={modalContinue}
        handleRouteChange={handleRouteChange}
      />
      <LoadingModal modalIsOpen={modalIsOpen} />

      <WaitingRoomModal
        onContinueClick={() => {
          closeModal({ type: 'waiting-room' })
          handleRouteChange(appRoutes.ferryRoutes.pathname)
        }}
        isOpen={Object.keys(modalIsOpen).includes('waiting-room')}
        openModal={openModal}
        closeModal={closeModal}
      />
      <WebsiteStatusModal
        isOpen={Object.keys(modalIsOpen).includes('website-status')}
        openModal={openModal}
      />
      {activeModifyBooking && (
        <ModifyBookingModal
          isDisplayed={!!modalIsOpen[modalIds.continueToAccounts]}
          onCloseModal={() => closeModal({ type: modalIds.continueToAccounts })}
          modalText={labels.lostModificationChangesText || ''}
          closeLabel={labels.close ? `${labels.close} X` : ''}
          onContinueToAccount={() => {
            dispatch(clearActiveModifyBooking())
            handleRouteChange(appRoutes.account.pathname)
            closeModal({ type: modalIds.continueToAccounts })
          }}
          continueToAccountLabel={labels.cancelBookingContinueToAccount || ''}
          onContinueModifiying={() => {
            closeModal({ type: modalIds.continueToAccounts })
          }}
          continueModifiyingLabel={`${
            labels.continueModifyingBooking
          } #${activeModifyBooking?.bookingNumber || ''}`}
        />
      )}
    </Fragment>
  )
}

GlobalModals.propTypes = {
  handleRouteChange: func.isRequired,
  modalIsOpen: shape().isRequired,
  openModal: func.isRequired,
  closeModal: func.isRequired,
  modalContinue: func.isRequired
}

export default GlobalModals
