import React, { useEffect, useState, createContext } from 'react'
import { node } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { updateConfigByLanguage, fetchConfiguration } from './actions'
import themeVariables from '../../styles/scss/configuration/_variables.scss'
import { selectConfigurationState } from '../../redux/configuration/selectors'
import { selectLanguage } from '../../redux/session/selectors'
import { selectQueryParams } from '../selectors/selectQueryParams'
import { appRoutes } from '../../configuration/constants'
const { Provider, Consumer } = createContext()

const ConfigProvider = ({ children, isLoading }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const configuration = useSelector(selectConfigurationState)
  const languageCode = useSelector(selectLanguage)
  const queryParams = useSelector(selectQueryParams)
  /*
    note:
    This local state ensures we always check on config (fetch or update)
    regardless of redux-persisted state
  */
  const [initialConfigsFetched, setInitialConfigsFetched] = useState(false)
  const [configContextValues] = useState({
    themeVariables,
    currentTheme: process.env.REACT_APP_FLOW_CLIENT_ID
  })

  useEffect(() => {
    /*
      If we have configuration data,
      silently update config with no success/error or other side effects
    */
    if (isLoading) {
      return
    }
    if (!initialConfigsFetched) {
      setInitialConfigsFetched(true)
      if (!configuration.data) {
        dispatch(fetchConfiguration()).then(() => {
          history.push({
            pathname: window.location.pathname,
            search:
              window.location.pathname.indexOf(
                appRoutes.telephoneBooking.pathname
              ) > -1 ||
              window.location.pathname.indexOf(
                appRoutes.bookingSuccess.pathname
              ) > -1
                ? window.location.search
                : `?${queryParams}`
          })
        })
      } else {
        dispatch(updateConfigByLanguage({ languageCode }))
      }
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    initialConfigsFetched,
    configuration.data,
    languageCode,
    isLoading
  ])

  return <Provider value={configContextValues}>{children}</Provider>
}

ConfigProvider.propTypes = {
  children: node.isRequired
}

export { ConfigProvider }
export default Consumer
