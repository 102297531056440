import themeVariables from '../styles/scss/configuration/_variables.scss'
import yn from 'yn'

export const HYDRA_MEMBER = 'hydra:member'

// START environment variables
export const FLOW_CLIENT_ID = process.env.REACT_APP_FLOW_CLIENT_ID
export const ENABLE_ACCOMMODATIONS =
  process.env.REACT_APP_ENABLE_ACCOMMODATIONS === 'true'
export const ENABLE_EXTRAS = process.env.REACT_APP_ENABLE_EXTRAS_STEP === 'true'
export const ENABLE_LOGIN_GATE =
  process.env.REACT_APP_ENABLE_LOGIN_GATE === 'true'
export const VEHICLE_HEIGHT_FILTERING =
  process.env.REACT_APP_ENABLE_VEHICLE_HEIGHT_FILTERING === 'true'
export const VEHICLE_LENGTH_FILTERING =
  process.env.REACT_APP_ENABLE_VEHICLE_LENGTH_FILTERING === 'true'
export const DISABLE_WAITING_ROOM = !!(
  process.env.REACT_APP_DISABLE_WAITING_ROOM &&
  process.env.REACT_APP_DISABLE_WAITING_ROOM === 'true'
)
export const ENABLE_FORCES_INPUT = !!(
  process.env.REACT_APP_ENABLE_FORCES_INPUT &&
  process.env.REACT_APP_ENABLE_FORCES_INPUT === 'true'
)
export const VERB_ENV = process.env.REACT_APP_VERB_ENV
export const ENABLE_MONERIS_CHECKOUT = !!(
  process.env.REACT_APP_ENABLE_MONERIS_CHECKOUT &&
  process.env.REACT_APP_ENABLE_MONERIS_CHECKOUT === 'true'
)
export const ENABLE_CHASE_CHECKOUT = !!(
  process.env.REACT_APP_ENABLE_CHASE_CHECKOUT &&
  process.env.REACT_APP_ENABLE_CHASE_CHECKOUT === 'true'
)
export const USE_CHASE_DOLLI = !!(
  process.env.REACT_APP_USE_CHASE_DOLLI &&
  process.env.REACT_APP_USE_CHASE_DOLLI === 'true'
)
export const HEADER_DETAILS = yn(process.env.REACT_APP_COMPONENT_HEADER_DETAILS) // TODO: Better name?
export const HEADER_DETAILS_ON_ROUTE_SELECTION = yn(
  process.env.REACT_APP_COMPONENT_HEADER_DETAILS_ON_ROUTE_SELECTION
)
export const ENABLE_PAYPAL = !!(
  process.env.REACT_APP_ENABLE_PAYPAL &&
  process.env.REACT_APP_ENABLE_PAYPAL === 'true' &&
  process.env.REACT_APP_PAYPAL_CLIENT_ID !== undefined
)
export const PAYPAL_CURRENCY_CODE =
  process.env.REACT_APP_PAYPAL_CURRENCY_CODE || 'CAD'
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID

export const ENABLE_VALIDATED_PROMO =
  process.env.REACT_APP_COMPONENT_PROMO_INPUT === 'ValidatedPromoInput'
export const ENABLE_COPASSENGERS =
  process.env.REACT_APP_ENABLE_COPASSENGERS === 'true'

export const ENABLE_BICYCLE_OPTION =
  process.env.REACT_APP_FILTER_BICYCLE_RESOURCE === 'true'
export const ENABLE_PROMO_DEFAULT_OPEN =
  process.env.REACT_APP_COMPONENT_VALIDATED_PROMO_DEFAULT_OPEN === 'true'
export const FORCE_LOWERCASE_EMAIL =
  process.env.REACT_APP_FORCE_LOWERCASE_EMAIL_ON_SUBMIT === 'true'
export const PASSENGER_SELECT_DISPLAY_FREE =
  process.env.REACT_APP_PASSENGER_SELECT_DISPLAY_FREE

// NOTE(ebarrett): Temp flag for working on refactor without disturbing
//  existing functionality
export const USE_REFACTOR = process.env.REACT_APP_USE_REFACTOR === 'true'

export const EXCLUDE_KENNELS_FROM_ACCOMMODATIONS =
  process.env.REACT_APP_ENABLE_ACCOMMODATIONS_SELECTOR === 'no_kennels'

export const ENABLE_CROSSING_SELECTION_COMPONENT =
  process.env.REACT_APP_CROSSING_SELECTION_COMPONENT ===
  'highlight_selected_dates'

export const ENABLE_WAITLIST_ACCOMMODATIONS =
  process.env.REACT_APP_ENABLE_WAITLIST_ACCOMMODATIONS === 'true'

/*
  If no vehicle filtering is needed,
  default to the list display for all vehicle options.
  No prices or icons, just titles in a list similar to AreYouBringingAVehicle.

  This is used in a few places throughout the booking flow
*/
export const defaultToVehicleOptionsList =
  process.env.REACT_APP_COMPONENT_VEHICLE_SELECT === 'VehicleOptionList'

export const allowAnonymousBookings =
  process.env.REACT_APP_ALLOW_ANONYMOUS_BOOKING === 'true'

export const noDefaultRoutes =
  process.env.REACT_APP_COMPONENT_NO_DEFAULT_ROUTE === 'true'

export const PRIOR_SAILING_HOURS =
  process.env.REACT_APP_PRIOR_SAILING_HOURS !== undefined &&
  +process.env.REACT_APP_PRIOR_SAILING_HOURS
    ? +process.env.REACT_APP_PRIOR_SAILING_HOURS
    : 0

export const SHOW_CUTOFF_MESSAGE =
  process.env.REACT_APP_SHOW_CUTOFF_MESSAGE === 'true'

// END environment variables

export const appRoutes = {
  account: {
    labelKey: 'account',
    pathname: `/compte-account`
  },
  ferryRoutes: {
    labelKey: 'editRoutes',
    pathname: '/'
  },
  passengerQuantities: {
    labelKey: 'passenger',
    pluralLabelKey: 'passengers',
    pathname: `/passagers-passengers`
  },
  vehicle: {
    labelKey: 'vehicles',
    pathname: `/vehicule-vehicle`
  },
  departure: {
    labelKey: 'departure',
    pathname: `/depart-departure`
  },
  register: {
    labelKey: 'register',
    pathname: `/enregistrer-register`
  },
  return: {
    labelKey: 'return',
    pathname: `/retour-return`
  },
  accommodations: {
    labelKey: 'servicesTimeLineLabel',
    pluralLabelKey: 'servicesTimeLineLabelPlural',
    pathname: `/hebergements-accommodations`,
    disabled: !ENABLE_ACCOMMODATIONS
  },
  summary: {
    labelKey: 'summary',
    pathname: `/sommaire-summary`
  },
  extras: {
    labelKey: 'extras',
    pathname: `/supplements-extras`,
    disabled: !ENABLE_EXTRAS
  },
  passengerDetails: {
    labelKey: 'accountInformation',
    pathname: `/renseignements-information`
  },
  payment: {
    labelKey: 'payment',
    pathname: `/paiement-payment`
  },
  paymentError: {
    labelKey: 'paymentError',
    pathname: `/erreur-error`
  },
  bookingConfirmation: {
    labelKey: 'bookingConfirmtion',
    pathname: `/confirmation`
  },
  bookingSuccess: {
    labelKey: 'bookingSuccess',
    pathname: `/succes-success`
  },
  forgotPassword: {
    labelKey: 'forgotPassword',
    pathname: `/mot-de-passe-oublier-forgot-password`
  },
  updatePassword: {
    labelKey: 'updatePassword',
    pathname: `/modifier-mot-de-passe-update-password/:languageCode/:emailToken`,
    pathnameWithoutParams: `/modifier-mot-de-passe-update-password`
  },
  telephoneBooking: {
    labelKey: 'telephoneBooking',
    pathname: `/book/telephone-booking`
  },
  modifyBooking: {
    labelKey: 'modifyBooking',
    pathname: `/modifier-modify`
  }
}

export const stepKeys = [
  'ferryRoutes',
  'passengerQuantities',
  'vehicle',
  'departure',
  'return',
  'accommodations',
  'extras',
  'payment'
]

const iconFills = {
  default: themeVariables.themeDisabledIconColor,
  active: themeVariables.themeIconColor
}

export const stepIcons = {
  ferryRoutes: {
    type: 'routes',
    ...iconFills
  },
  passengerQuantities: {
    type: 'profile',
    ...iconFills
  },
  vehicle: {
    type: 'vehicle',
    ...iconFills
  },
  departure: {
    type: 'time',
    ...iconFills
  },
  return: {
    type: 'time',
    ...iconFills
  },
  accommodations: {
    type: 'accommodations',
    ...iconFills
  },
  extras: {
    type: 'extras',
    ...iconFills
  },
  payment: {
    type: 'fares',
    ...iconFills
  }
}

export const ModifyFlowType = {
  ONE_WAY: 'ModifyOneWay',
  ANY: 'ModifyAny',
  DEPARTURE_LOCKED: 'DepartureLocked'
}

export const sessionStoragePaymentDataKey = `${process.env.REACT_APP_FLOW_CLIENT_ID}PaymentData`

export const accommodationResources = {
  C4B: 'C4B', // Four-Bed Cabin
  C2L: 'C2L', // Two-Bed Cabin
  RDS: 'RDS', // Reserved seat
  KSM: 'KSM',
  KMD: 'KMD',
  KLG: 'KLG',
  KXL: 'KXL'
}

export const defaultRouteImage =
  process.env.REACT_APP_DEFAULT_ROUTE_IMAGE ||
  'https://via.placeholder.com/800x600'

export const defaultRouteImageFrench =
  process.env.REACT_APP_DEFAULT_ROUTE_IMAGE_FRENCH || defaultRouteImage

export const IS_NFL_CLIENT = false

export const shouldPreFillVehicleLengthOnVehicleSelectionChange = false

export const CANCELLED_WITHOUT_REFUND_MSG =
  'Process could not be completed. Please call 1-800-341-7981 for support.'

export const CANCELLED_AUTH_ERROR_MSG =
  'Authentication failed due to invalid authentication credential'

export const TripType = {
  oneWay: 'OW',
  roundTrip: 'RT'
}
