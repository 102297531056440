import React from 'react'
import { bool, string, func } from 'prop-types'
import Modal from '../Modal'

const ModifyBookingModal = ({
  isDisplayed,
  onCloseModal,
  modalText,
  closeLabel,
  onContinueToAccount,
  onContinueModifiying,
  continueToAccountLabel,
  continueModifiyingLabel
}) => (
  <Modal
    isOpen={isDisplayed}
    onRequestClose={onCloseModal}
    className="continue-to-account-modal modal--med"
  >
    <div className="continue-to-account-warning">
      <div className="continue-to-account-warning-header">
        <h3>{modalText}</h3>
        <button className="btn" onClick={onCloseModal}>
          {closeLabel}
        </button>
      </div>
      <div className="continue-to-account-warning-actions">
        <button className="btn btn-primary" onClick={onContinueToAccount}>
          {continueToAccountLabel}
        </button>
        <button className="btn" onClick={onContinueModifiying}>
          {continueModifiyingLabel}
        </button>
      </div>
    </div>
  </Modal>
)

ModifyBookingModal.propTypes = {
  isDisplayed: bool,
  onCloseModal: func.isRequired,
  modalText: string.isRequired,
  closeLabel: string.isRequired,
  onContinueToAccount: func.isRequired,
  onContinueModifiying: func.isRequired,
  continueToAccountLabel: string.isRequired,
  continueModifiyingLabel: string.isRequired
}

ModifyBookingModal.defaultProps = {
  isDisplayed: false
}

export default ModifyBookingModal
