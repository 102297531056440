import { PHONE_FIELDS } from '../../redux/configuration/constants'

export function getFieldsAndLayout(
  signedIn,
  defaultToVehicleOptionsList,
  hasVehicle
) {
  const allFieldNames = [
    'title',
    'firstName',
    'lastName',
    'address',
    'city',
    'countryCode',
    'county',
    'postalCode',
    'licensePlateNumber',
    `workPhoneNumber`,
    'mobilePhoneNumber',
    'homePhoneNumber',
    'emailAddress',
    'emailAddressConfirm',
    'acceptsContact'
  ]
  const contactRows = PHONE_FIELDS.useSplitLayout
    ? [
        ['licensePlateNumber', ...PHONE_FIELDS.client],
        ['emailAddress', 'emailAddressConfirm']
      ]
    : [
        [
          'licensePlateNumber',
          [...PHONE_FIELDS.client],
          'emailAddress',
          'emailAddressConfirm'
        ]
      ]
  const allFieldLayout = [
    ['title', 'firstName', 'lastName'],
    ['address', 'city'],
    ['countryCode', 'county', 'postalCode'],
    ...contactRows,
    ['acceptsContact']
  ]
  const keysToRemove = {
    emailAddressConfirm: signedIn,
    licensePlateNumber: !defaultToVehicleOptionsList || !hasVehicle
  }
  PHONE_FIELDS.all.forEach(phoneField => {
    if (!PHONE_FIELDS.client.includes(phoneField)) {
      keysToRemove[phoneField] = true
    }
  })
  const filterKeys = arr => arr.filter(x => !keysToRemove[x])

  return {
    fields: filterKeys(allFieldNames),
    layout: allFieldLayout.map(row => filterKeys(row))
  }
}
