import React, { useState } from 'react'
import PropTypes, { string, array, func, shape, bool } from 'prop-types'
import moment from 'moment'
import dateFormats from '../../configuration/dateFormats'
import CrossingCardContainer from './CrossingCardContainer'
import noCrossingIcon from '../../assets/icons/no-crossings-icon.svg'
import { useSelector } from 'react-redux'
import { selectLabels } from '../../redux/configuration/selectors'
import Modal from '../Modal'
import { selectLanguage } from '../../redux/session/selectors'
import isRouteCurrentSelection from '../../utilities/crossings/isRouteCurrentSelection'
import shouldDisableCrossing from '../../utilities/crossings/shouldDisableCrossing'
import shouldDisplayNoPassengerMessage from '../../utilities/crossings/shouldDisplayNoPassengerMessage'

const isCurrentSlide = (crossings, currentSelection) => {
  let result = false

  if (crossings.length) {
    result = crossings.find(crossing => {
      const { departureTime, departureDate, routeCode, shipCode } = crossing

      return (
        currentSelection &&
        departureDate === currentSelection?.departureDate &&
        departureTime === currentSelection?.departureTime &&
        routeCode === currentSelection?.routeCode &&
        shipCode === currentSelection?.shipCode
      )
    })
  }

  return !!result
}

const CrossingDayColumn = ({
  day,
  crossings,
  selectCrossing,
  currentSelection,
  activeVehicleResourceCode,
  activeVehicle,
  highlightFirstCrossing,
  totalPassengers,
  cabinResourceCodes
}) => {
  const labels = useSelector(selectLabels)
  const language = useSelector(selectLanguage)

  const [departureModalMessage, setDepartureModalMessage] = useState(null)

  const title = moment(day, dateFormats.default)
  const activeSlide = isCurrentSlide(crossings, currentSelection)

  let formattedTitle = title
    .locale(language)
    .format(
      language === 'fr'
        ? dateFormats.shortDateDisplayFrench
        : dateFormats.shortDateDisplay
    )

  formattedTitle = title
    .locale(language)
    .format(
      language === 'fr'
        ? dateFormats.shortDateDisplayFrench
        : dateFormats.shortDateDisplayAlt
    )

  function buildCrossingCards(crossings) {
    let didHighlight = false
    // FIXME: departure time may repeat
    const crossingsUnique = {}
    crossings.forEach(crossing => {
      // find the best price crossing
      const crossingPrice = +crossing.bookingPrice.bookingPrice.totalPrice
      const dayTimeKey = crossing.departureDate + '-' + crossing.departureTime
      const existingCrossing = crossingsUnique[dayTimeKey]
      const lowestPriceFound = +(
        existingCrossing?.bookingPrice?.bookingPrice?.totalPrice ?? Infinity
      )

      if (crossingPrice < lowestPriceFound) {
        crossingsUnique[dayTimeKey] = crossing
      }
    })

    return Object.values(crossingsUnique).map((crossing, index) => {
      const departureMessageLabel = crossing?.departureMessage
      const isCurrentlySelectedRoute = isRouteCurrentSelection(
        currentSelection,
        crossing
      )

      const isDisabled = shouldDisableCrossing(
        crossing,
        activeVehicleResourceCode,
        activeVehicle,
        totalPassengers
      )
      const isHighlighted =
        highlightFirstCrossing && !didHighlight && !isDisabled

      if (isHighlighted) {
        didHighlight = true
      }

      const containerStyle = `crossing-card-container ${
        isHighlighted ? 'highlighted' : ''
      } ${isCurrentlySelectedRoute ? 'active' : ''}`

      return (
        <div
          key={`${crossing.departureDate}-${crossing.departureTime}`}
          className={containerStyle}
        >
          {departureMessageLabel && (
            <div
              className={`day-crossing-travel-warning-container ${
                isCurrentlySelectedRoute ? 'active' : ''
              }`}
              onClick={() => setDepartureModalMessage(departureMessageLabel)}
            >
              <p>{labels.travelAdvisory}</p>{' '}
              <i className="material-icons">information</i>
            </div>
          )}
          <CrossingCardContainer
            crossing={crossing}
            selectCrossing={selectCrossing}
            isCurrentlySelectedRoute={isCurrentlySelectedRoute}
            isDisabled={isDisabled}
            activeVehicleResourceCode={activeVehicleResourceCode}
            activeVehicle={activeVehicle}
            shouldDisplayNoPassengerMessage={shouldDisplayNoPassengerMessage({
              crossing,
              totalPassengers
            })}
            cabinResourceCodes={cabinResourceCodes}
          />
        </div>
      )
    })
  }

  return (
    <div className={`day `}>
      <p
        className={
          activeSlide
            ? 'day-title theme-title-variant-three'
            : 'day-title theme-font-header'
        }
      >
        {formattedTitle}
      </p>
      {crossings.length ? (
        buildCrossingCards(crossings)
      ) : (
        <div className="empty crossing-card-container">
          <img src={noCrossingIcon} alt={labels.noCrossings} role="none" />
          <p>{labels.noCrossings}</p>
        </div>
      )}

      <Modal
        isOpen={Boolean(departureModalMessage)}
        onRequestClose={() => setDepartureModalMessage(null)}
        className="modal--sm crossing-departure-message-modal"
      >
        <div>
          <p>{departureModalMessage}</p>
        </div>
      </Modal>
    </div>
  )
}

CrossingDayColumn.propTypes = {
  day: string.isRequired,
  crossings: array.isRequired,
  selectCrossing: func.isRequired,
  currentSelection: shape(),
  activeVehicleResourceCode: string,
  activeVehicle: shape(),
  highlightFirstCrossing: bool.isRequired,
  cabinResourceCodes: PropTypes.arrayOf(string).isRequired
}

export default CrossingDayColumn
