const isMatchingAccommodation = (accommodationA, accommodationB) => {
  return accommodationA.resourceCode === accommodationB.resourceCode
}

const isMatchingCrossing = (crossingA, crossingB) => {
  return (
    crossingA.departureTime === crossingB.departureTime &&
    crossingA.routeCode === crossingB.routeCode &&
    crossingA.shipCode === crossingB.shipCode
  )
}

const resourceSatisfiesConstraint = (resource, constraint) => {
  if (!resource) {
    return false
  } else if (!constraint) {
    return true
  } else {
    const { type, key, values } = constraint
    if (!Array.isArray(values)) {
      if (process.env.REACT_APP_VERB_ENV !== 'production') {
        console.error('Expected constraint values to be an array')
      }
      return true
    }
    switch (type) {
      case 'include':
        return values.includes(resource[key])
      case 'exclude':
        return !values.includes(resource[key])
      default:
        if (process.env.REACT_APP_VERB_ENV !== 'production') {
          console.warn('Unsupported accommodations constraint type ' + type)
        }
        return true
    }
  }
}

const getAccommodationWithCapacity = (
  accommodation,
  crossing,
  availabilityData,
  activeModifyBooking
) => {
  let freeCapacityOffset = 0

  if (activeModifyBooking) {
    const matchingCrossing = activeModifyBooking.departures.find(x =>
      isMatchingCrossing(crossing, x)
    )
    if (matchingCrossing) {
      const bookedMatchingAccommodations = matchingCrossing.accommodations.booked.filter(
        x => isMatchingAccommodation(accommodation, x)
      )

      freeCapacityOffset += bookedMatchingAccommodations.length
    }
  }

  // freeCapacity might be negative in odd testing situations, but client should be able to
  //   adjust their already booked cabin count in the range [0, freeCapacityOffset]
  const freeCapacity =
    Math.max(+availabilityData.freeCapacity, 0) + freeCapacityOffset

  return {
    ...accommodation,
    ...availabilityData,
    freeCapacity
  }
}

export const getFilteredAccommodations = (
  activeCrossings,
  activeModifyBooking,
  constraints
) => {
  return Object.entries(activeCrossings).reduce(
    (obj, [direction, crossing]) => {
      if (!crossing) return obj

      const matchingAccommodations =
        crossing?.bookingPrice?.bookingPrice?.resources?.accommodations

      const matchingAccommodationAvailability = crossing?.resources?.filter(
        crossing => crossing.resourceType === 'accommodation'
      )

      if (
        matchingAccommodations?.length &&
        matchingAccommodationAvailability?.length
      ) {
        for (const matchingAccommodation of matchingAccommodations) {
          // check availability
          const availabilityData = matchingAccommodationAvailability.find(
            accommodation =>
              isMatchingAccommodation(accommodation, matchingAccommodation)
          )

          if (!resourceSatisfiesConstraint(availabilityData, constraints)) {
            continue
          }

          const accommodationWithCapacity = getAccommodationWithCapacity(
            matchingAccommodation,
            crossing,
            availabilityData,
            activeModifyBooking
          )

          accommodationWithCapacity.price = matchingAccommodation.price

          obj[matchingAccommodation.resourceCode] = {
            ...(obj[matchingAccommodation.resourceCode] || {}),
            [direction]: accommodationWithCapacity,
            details: matchingAccommodation
          }
        }
      }

      return obj
    },
    {}
  )
}
