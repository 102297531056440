/* eslint-disable no-multi-str */
export default {
  en: {
    // NEW ONES NOT IN CONFIG BELOW:
    showSection: 'Show',
    hideSection: 'Hide',
    customerNumber: 'Customer Number',
    postalCode: 'Postal Code',
    booking: 'Booking',
    bookToday: 'Book Today',
    loginToContinue: 'Login to Continue',
    accountRequired: 'An account is required to create and edit a booking',
    existingUserLogin: 'Existing User Login',
    noAccount: "Don't have an account?",
    createOnNextScreen:
      'No problem. You’ll be asked to create one on the next screen',
    isInvalid: 'is invalid.',
    forgotPasswordHelpText:
      'Please enter your email below and we will send you a link to reset your password.',
    updatePassword: 'Update Password',
    updatePasswordHelpText: 'Enter and confirm your password to reset it.',
    send: 'Send',
    update: 'Update',
    thankYou: 'Thank you',
    passwordResetSuccess:
      'An email with instructions was just sent to your inbox.',
    passwordResetError: 'Failed to send password reset email',
    passwordUpdateSuccess: 'Your new password has been set successfully.',
    passwordUpdateError: 'Failed to update password',
    noDepartureCrossingsAvailable:
      'No availability surrounding your departure date',
    noReturnCrossingsAvailable: 'No availability surrounding your return date',
    paymentConfirmationError: 'Unable to finalize payment',
    paymentStatusPaid: 'Paid',
    paymentStatusUnpaid: 'Unpaid',
    busierThanNormal: "Sorry! We're busier than normal!",
    yourTurn: "It's your turn! Click below to complete your reservation!",
    continueToBookingBtn: 'Continue to Booking',
    inQueue:
      "You're in the queue and will automatically be able to book once a slot becomes available.",
    bookingNotAvailable: 'Sorry! Booking Is Not Available',
    checkSoon: 'Please check again soon.',
    vehicleHeightExplanation:
      'Including any objects on the roof of your vehicle',
    vehicleLengthExplanation:
      'Including any trailers and objects exceeding normal vehicle length',
    totalRefund: 'Total Refund',
    cancellationFee: 'Cancellation Fee',
    paidAmount: 'Amount Paid',
    bookingCancelledSuccess: 'Booking Successfully Canceled',
    cancelBookingBtnText: 'Yes, Cancel my booking',
    dismissCancelBookingBtnText: 'No, Keep my booking',
    arrival: 'Arrival',
    ship: 'Ship',
    TPS: 'TPS Tax',
    TVQ: 'TVQ Tax',
    taxesAndFees: 'Taxes and Fees',
    needAccommodations: 'Do You Need Accommodations?',
    placesToStay: 'Find places to stay on Iles de la Madeleine',
    selectDates: 'Please Select Dates',
    modifyingBooking: 'Modifying Booking',
    continueModifyingBooking: 'Continue modifying booking',
    cancelBookingContinueToAccount: 'I understand, continue to account',
    backToUserBookings: 'Back to My Bookings',
    lostModificationChangesText: 'You are about to lose any unsaved changes',
    services: 'Services',
    vehicleSelection: 'Vehicle selection',
    genericApiErrorMessage: 'Sorry, something went wrong',
    vehicleLengthBeAdvised:
      'Please be advised you cannot reserve a commercial vehicle more than 21 feet',
    travelAdvisory: 'Travel Advisory',
    to: 'to',
    openBooking: 'Open Booking',
    commercialBooking: 'Commercial Booking',
    couponApplied: 'Coupon Applied',
    payment: 'Payment',
    maxPassengerCountReachedMessage:
      'If you are booking more than 10 passengers please contact us.',
    minPhoneNumberError: 'Please enter a valid 10 digit phone number',
    amendmentFee: 'Amendment Fee',
    warningTitle: 'Are you there?',
    warningContent: 'Your active booking session will expire in',
    warningContinue: 'Continue Booking',
    expiredTitle: 'Your Session Has Expired',
    expiredContinue: 'New Booking',
    selectedServicesLineItem: 'Cabin',
    selectedServicesLineItemPlural: 'Cabins',
    servicesTimeLineLabel: 'Cabin',
    servicesTimeLineLabelPlural: 'Cabins',
    balanceDue: 'Balance Due',
    genericPaymentErrorMessage: 'There was an error processing payment',
    fetchUserBookingsError: 'There was an error fetching your bookings',
    departingOn: 'Departure on',
    incompleteBookingWarningCopy:
      'You have until the next day to complete the booking or it will be cancelled by a user at the call center',
    confirmEmailError: 'Email address does not match.',
    emailAddressConfirm: 'Confirm Email Address', // remove after added to back end
    vehicleCannotExceed: 'Vehicle cannot exceed', // remove after added to back end
    vehicleMustbe: 'Vehicle must be', // remove after added to back end
    feet: 'feet', // remove after added to back end
    feetOrLonger: 'feet or longer', // remove after added to back end
    forVehicleLongerThan: 'For vehicles longer than', // remove after added to back end
    pleaseCall: 'please call', // remove after added to back end
    thisFieldIsRequired: 'This field is required', // remove after added to back end
    between: 'Between', // remove after added to back end
    and: 'and', // remove after added to back end
    complete: 'Complete',
    retryPayment: 'Retry the payment',
    keepMyBooking: 'Keep my booking',
    customMinDateError:
      'To book this date or any other date by March 31, 2021, you must contact our customer service at 1 888 986-3278.',
    pay: 'Pay',
    minLengthErrorMessage: 'Does not meet minimum length requirements',
    maxLengthErrorMessage: 'Exceeds maximum length limit',
    combinedNameMaxLengthErrorMessage:
      'First name and last name must have a combined length of 40 characters maximum.',
    travelAgentLogin: 'Travel Agent Login',
    loginTravelAgentError: 'Your user ID or password is incorrect.',

    clickHere: 'Click here',
    forEligibilityInfo: 'for eligibility info',
    promoCode: 'Promo Code',
    promoCodeCta: 'Enter Special Code',
    promoCodeErrorMsg: 'Please enter a valid code',
    applyPromo: 'Apply Coupon +',
    validate: 'Validate',
    canadianForces: 'Canadian Forces',
    canadianForcesCta: 'Enter CF1 Card Number',
    canadianForcesMember: 'I am a member of the Canadian Forces',
    saveAndClose: 'save and close',
    close: 'Close',
    addKennels: 'Add Kennels',
    continueToExtrasBtn: 'Continue to Extras',
    extras: 'Extras',
    customerDetails: 'Customer Details',
    paymentInformation: 'Payment Information',
    size: 'Size',
    selectSize: 'Select a Size',
    cfCard: 'CF1FC – CF card',
    vCard: 'CF1FC – V card',
    licensePlateInstruction:
      "Enter 'Unknown' if you are unsure of the plate number",
    title: 'Title',
    titleMR: 'Mr.',
    titleMRS: 'Mrs.',
    titleMS: 'Ms.',
    titleMX: 'MX',
    departureTrip: 'Departure Trip',
    returnTrip: 'Return Trip',
    soldOut: 'Sold Out',
    soldOutDeparture: 'Sold Out for Departure',
    soldOutReturn: 'Sold Out for Return',
    departuresOn: 'Departures on',
    arrivalTime: 'Arrival Time',
    otherDepartures: 'Other Departures Near Your Date',
    noCrossingsOn: 'No available crossings on',
    selectAccessibilityOptions: 'Select Accessibility Options',
    // BAY202-75
    student: 'Student',
    students: 'Students',
    studentAgeDesc: ' ',
    // END BAY202-75
    free: 'Free',
    // CTM200-483 PayPal
    order: 'Order',
    paymentMethod: 'Payment Method',
    payPalCopy: `To finish your purchase, click on the “PayPal” or "Debit or Credit Card" button and log on to PayPal using your email and password`,
    // END CTM200-483 PayPal
    // MAT203-72
    updateCoPassengers: 'Update Co-Passengers',
    addCoPassenger: 'Add Co-Passenger',
    coPassengerError: '* Please complete the co-passenger fields',
    // END MAT203-72
    // MAT203-60
    noCrossingsSurroundingDeparture:
      'No crossings surrounding your departure date.',
    noCrossingsSurroundingReturn: 'No crossings surrounding your return date.',
    viewOurSchedule: 'View our schedule',
    forACompleteListOfSailings: 'for a complete list of sailing times.',
    noCrossingsSurroundingBothDates:
      'No crossings surrounding your return & departure dates.',
    // END MAT203-60
    // CTM200-483
    creditCard: 'Credit Card',
    // END CTM200-483
    // BAY202-86
    anErrorOccured: 'An error occurred',
    // END BAY202-86
    invoicePaymentErrorMsg: 'Sorry, payment not allowed for invoice booking.',
    pageDataInvalidErroMsg: 'Page data invalid, unable to initialize payment.',

    // CTM200-585
    departureVehicleLocked:
      'Departure vehicle may not be updated after check-in',

    passport: 'ID Number',
    passportConfirm: 'Confirm ID Number',
    typeOfId: 'Identification Type',
    passportIssueCountry: 'Issuing Country',
    passportExpiryDate: 'Date Expired (yyyy-mm-dd)',
    citizenship: 'Citizenship',

    // BAY202-153
    modifyExistingReservation: 'Modify an Existing Reservation',
    guestUsers: 'Guest Users',
    iHaveAccount: 'I Have an Account',
    findReservation: 'Find Reservation',
    login: 'Login',

    // CTM200-649
    bookingStatusWaitlisted: 'Waitlist',

    // BAY202-172
    rentalReminder:
      'If booking with a rental car, please enter "Rental" in the license plate field.',

    departureSeats: 'Departure Seats',
    returnSeats: 'Return Seats',
    perSeat: 'per seat',
    reservedSeat: 'Reserved Seat',
    reservedSeats: 'Reserved Seats',
    chooseYourSeat: 'Choose Your Seat',
    reservedSeating: 'Reserved Seating',
    deckNine: 'Deck 9',
    legend: 'Legend',
    seat: 'Seat',
    legroom: 'Legroom',
    wheelchairAccessible: 'Wheelchair Accessible',
    addSeat: 'Add Seat',
    removeSeat: 'Remove Seat',
    acOutlet: 'AC Outlet',
    availableSeat: 'AvailableSeat',
    unavailableSeat: 'Unavailable Seat',
    acCPAPMachine: 'Seats share AC outlet',
    selected: 'Selected',
    confirmSeatSelection: 'Confirm Seat Selection'
  },
  fr: {
    showSection: 'Afficher',
    hideSection: 'Cacher',
    customerNumber: 'Customer Number',
    postalCode: 'Postal Code',
    booking: 'Booking',
    bookToday: 'Book Today',
    loginToContinue: 'Login to Continue',
    accountRequired: 'An account is required to create and edit a booking',
    existingUserLogin: 'Existing User Login',
    noAccount: "Don't have an account?",
    createOnNextScreen:
      'No problem. You’ll be asked to create one on the next screen',
    isInvalid: 'is invalid.',
    forgotPasswordHelpText:
      'Please enter your email below and we will send you a link to reset your password.',
    updatePassword: 'Update Password',
    updatePasswordHelpText: 'Enter and confirm your password to reset it.',
    send: 'Send',
    update: 'Update',
    thankYou: 'Thank you',
    passwordResetSuccess:
      'An email with instructions was just sent to your inbox.',
    passwordResetError: 'Failed to send password reset email',
    passwordUpdateSuccess: 'Your new password has been set successfully.',
    passwordUpdateError: 'Failed to update password',
    noDepartureCrossingsAvailable:
      'Aucune disponibilité autour de votre date de départ',
    noReturnCrossingsAvailable:
      'Aucune disponibilité autour de votre date de retour',
    paymentConfirmationError: 'Impossible de finaliser le paiement',
    paymentStatusPaid: 'Payé',
    paymentStatusUnpaid: 'Non payé',
    busierThanNormal: "Sorry! We're busier than normal!",
    yourTurn: "It's your turn! Click below to complete your reservation!",
    continueToBookingBtn: 'Continue to Booking',
    inQueue:
      "You're in the queue and will automatically be able to book once a slot becomes available.",
    bookingNotAvailable: 'Sorry! Booking Is Not Available',
    checkSoon: 'Please check again soon.',
    vehicleHeightExplanation:
      'Y compris tout objet sur le toit de votre véhicule',
    vehicleLengthExplanation:
      'Y compris les remorques et les objets dépassant la longueur normale du véhicule',
    totalRefund: 'Remboursement total',
    cancellationFee: "Frais d'annulation",
    paidAmount: 'Le montant payé',
    bookingCancelledSuccess: 'Réservation annulée avec succès',
    cancelBookingBtnText: 'Oui, annuler ma réservation',
    dismissCancelBookingBtnText: 'Non, je garde ma réservation',
    arrival: 'Arrivée',
    ship: 'Navire',
    TPS: 'Tax TPS',
    TVQ: 'Tax TVQ',
    taxesAndFees: 'Taxes et frais',
    needAccommodations: 'Do You Need Accommodations?',
    placesToStay: 'Find places to stay on Iles de la Madeleine',
    selectDates: 'Veuillez sélectionner des dates',
    modifyingBooking: 'Modifier la réservation',
    continueModifyingBooking: 'Continuer à modifier la réservation',
    cancelBookingContinueToAccount: 'Je comprends, continue à rendre compte',
    backToUserBookings: 'Retour à Mes réservations',
    lostModificationChangesText:
      'Vous êtes sur le point de perdre toutes les modifications non enregistrée',
    services: 'Services',
    vehicleSelection: 'Sélection de véhicule',
    genericApiErrorMessage: "Désolé, quelque chose s'est mal passé",
    vehicleLengthBeAdvised:
      'Veuillez noter que vous ne pouvez pas réserver un véhicule utilitaire de plus de 21 pieds',
    travelAdvisory: 'Recommandations aux voyageurs',
    to: 'à',
    openBooking: 'Réservation ouverte',
    commercialBooking: 'Réservation commerciale',
    couponApplied: 'Coupon appliqué',
    payment: 'Paiement',
    maxPassengerCountReachedMessage:
      'Si vous réservez plus de 10 passagers, veuillez nous contacter.',
    minPhoneNumberError:
      'Veuillez saisir un numéro de téléphone valide à 10 chiffres',
    amendmentFee: 'Frais de modification',
    warningTitle: 'Êtes-vous là?',
    warningContent: 'Votre session de réservation active expirera dans',
    warningContinue: 'Continuer la réservation',
    expiredTitle: 'Votre Session A Expiré',
    expiredContinue: 'Nouvelle réservation',
    selectedServicesLineItem: 'Cabine',
    selectedServicesLineItemPlural: 'Cabines',
    servicesTimeLineLabel: 'Cabine',
    servicesTimeLineLabelPlural: 'Cabines',
    balanceDue: 'Montant dû',
    genericPaymentErrorMessage:
      "Une erreur s'est produite lors du traitement du paiement",
    fetchUserBookingsError:
      "Une erreur s'est produite lors de la récupération de vos réservations",
    departingOn: 'Départ le',
    incompleteBookingWarningCopy:
      'You have until the next day to complete the booking or it will be cancelled by a user at the call center',
    confirmEmailError: "L'adresse email ne correspond pas.",
    emailAddressConfirm: 'Confirmez votre adresse email',
    vehicleCannotExceed: 'Le véhicule ne peut pas dépasser',
    vehicleMustbe: 'Le véhicule doit mesurer',
    feet: 'pieds',
    feetOrLonger: 'pieds ou plus',
    forVehicleLongerThan: 'Pour les véhicules de plus de',
    pleaseCall: 'pieds, veuillez appeler le',
    thisFieldIsRequired: 'This field is required',
    between: 'Entre',
    and: 'et',
    customMinDateError:
      'Pour réserver cette date ou toute autre date d’ici le 31 mars 2021, vous devez communiquer avec notre service à la clientèle au 1 888 986-3278.',
    complete: 'Compléter',
    retryPayment: 'Réessayer le paiement',
    keepMyBooking: 'Conserver ma réservation',
    pay: 'Payer',
    minLengthErrorMessage: 'Ne répond pas aux exigences de longueur minimale',
    maxLengthErrorMessage: 'Dépasse la limite de longueur maximale',
    combinedNameMaxLengthErrorMessage:
      'Le prénom et le nom de famille doivent avoir une longueur combinée de 40 caractères maximum.',
    travelAgentLogin: 'Connexion Agent De Voyage',
    loginTravelAgentError: 'Votre ID ou mot de passe est incorrect.',
    clickHere: 'Cliquez ici',
    forEligibilityInfo: 'au sujet des exigences d’admissibilité',
    promoCode: 'Code Spécial',
    promoCodeCta: 'Entrez un code spécial',
    promoCodeErrorMsg: 'Veuillez entrer un code valide',
    applyPromo: 'Appliquer le code promotionnel +',
    validate: 'Valider',
    canadianForces: 'Forces canadiennes',
    canadianForcesCta: 'Entrez le numéro de carte CF1',
    canadianForcesMember: 'Je suis un membre des Forces canadiennes',
    saveAndClose: 'sauvegarde et fermer',
    close: 'Fermer',
    addKennels: 'Ajouter espace pour niche',
    continueToExtrasBtn: 'continuer vers extras',
    extras: 'Extras',
    customerDetails: 'Détails du client',
    paymentInformation: 'Détails de paiement',
    size: 'Taille',
    selectSize: 'Sélectionner une taille',
    cfCard: 'CF1FC – CF card',
    vCard: 'CF1FC – V card',
    licensePlateInstruction:
      "Entrez « Inconnu » si vous n'êtes pas certain du numéro de la plaque d'immatriculation",
    title: 'Titre',
    titleMR: 'M.',
    titleMRS: 'Mme.',
    titleMS: 'Mme.',
    departureTrip: 'Departure Trip',
    returnTrip: 'Return Trip',
    soldOut: 'Complet',
    soldOutDeparture: 'Sold Out for Departure',
    soldOutReturn: 'Sold Out for Return',
    departuresOn: 'Départs le',
    arrivalTime: "Heure d'arrivée",
    otherDepartures: 'Autres départs à proximité de votre date',
    noCrossingsOn: 'Aucune traversée disponible le',
    selectAccessibilityOptions: 'Select Accessibility Options',
    // BAY202-75
    student: 'Étudiant',
    students: 'Étudiants',
    studentAgeDesc: ' ',
    // END BAY202-75
    free: 'Gratuit',
    // CTM200-483 PayPal
    order: 'Ordre',
    paymentMethod: 'Mode de Paiement',
    payPalCopy: `Pour terminer votre achat, cliquez sur le bouton "PayPal" ou "Carte de crédit ou de débit" et connectez-vous à PayPal en utilisant votre adresse e-mail et votre mot de passe`,
    // END CTM200-483 PayPal
    // MAT203-72
    updateCoPassengers: 'Update Co-Passengers',
    addCoPassenger: 'Add Co-Passenger',
    coPassengerError: '* Please complete the co-passenger fields',
    // END MAT203-72
    // MAT203-60
    noCrossingsSurroundingDeparture:
      'Aucune traversée autour de votre date de départ.',
    noCrossingsSurroundingReturn:
      'Aucun traversée autour de votre date de retour.',
    viewOurSchedule: 'Consultez notre horaire',
    forACompleteListOfSailings:
      'pour une liste complète des horaires de navigation.',
    noCrossingsSurroundingBothDates:
      'Aucune traversée autour de vos dates de retour et de départ.',
    // END MAT203-60
    // CTM200-483
    creditCard: 'Carte de crédit',
    // END CTM200-483
    // BAY202-86
    anErrorOccured: "Une erreur s'est produite",
    // END BAY202-86
    invoicePaymentErrorMsg: `Désolé, le paiement n'est pas autorisé pour la réservation de facture.`,
    pageDataInvalidErroMsg: `Données de page invalides, impossible d'initialiser le paiement.`,
    // CTM200-585
    departureVehicleLocked:
      "La sélection du véhicule ne peut pas être modifiée après l'enregistrement",

    passport: 'Passeport',
    passportConfirm: 'Confirmer le Passeport',

    departureSeats: `Ajouter un siège pour l'aller`,
    returnSeats: 'Ajouter un siège pour le retour ',
    perSeat: 'par siège',
    reservedSeat: 'Siège réservé',
    reservedSeats: 'Sièges réservés',
    chooseYourSeat: 'Choisissez votre siège',
    reservedSeating: 'Siège réservé',
    deckNine: 'Pont 9',
    legend: 'Légende',
    seat: 'Siège',
    legroom: 'Dégagement pour les jambes',
    wheelchairAccessible: 'Accessible aux fauteuils roulants',
    addSeat: 'Ajouter un siège',
    removeSeat: 'Retirer le siège',
    acOutlet: 'prise CA',
    availableSeat: 'Siège disponible',
    unavailableSeat: 'Siège non disponible',
    acCPAPMachine:
      "Sièges à prise CA partagée pour l'appareil de ventilation en PPC",
    selected: 'Sélectionné',
    confirmSeatSelection: 'Confirmer la sélection du siège',

    // CTM200-649
    bookingStatusWaitlisted: 'File d’attente',

    // BAY202-172
    rentalReminder:
      'Si vous réservez avec une voiture de location, veuillez entrer "Location" dans le champ de la plaque d\'immatriculation.'
  }
}
