import { get } from 'lodash'
import { createAction } from 'redux-actions'

import {
  apiPreloadBookingTransaction,
  apiBookingTransactionReceipt,
  apiRegister,
  asyncPostBooking,
  asyncPostModifyBooking,
  apiGenerateChaseUid,
  apiChaseTransactionReceipt
} from '../../api/api'

// *** CONSTANTS ***
import { PHONE_FIELDS } from '../configuration/constants'
import {
  START_BOOKING_REQUEST,
  SET_BOOKING_ERROR,
  SET_BOOKING_SUCCESS,
  RESET_SET_BOOKING,
  CLEAR_BOOKING_DATA,
  ATTEMPT_PRELOAD_TRANSACTION,
  PRELOAD_TRANSACTION_ERROR,
  PRELOAD_TRANSACTION_SUCCESS,
  ATTEMPT_GET_TRANSACTION_RECEIPT,
  GET_TRANSACTION_RECEIPT_ERROR,
  GET_TRANSACTION_RECEIPT_SUCCESS,
  CLEAR_TICKET_NUMBERS,
  CLEAR_RECEIPTS,
  DEFAULT_BOOKING_ERROR_KEY
} from './constants'
import {
  ENABLE_ACCOMMODATIONS,
  CANCELLED_WITHOUT_REFUND_MSG,
  defaultToVehicleOptionsList
} from '../../configuration/constants'
// *** UTILITIES ***
import { formatCrossingData, getBillingFromCustomerAccount } from './utilities'
// *** ACTIONS ***
import { registerUserSuccess } from '../user/actions'
import { startSessionTimer } from '../session/actions'
// *** SELECTORS ***
import { selectConfigurationData } from '../configuration/selectors'
import { selectSessionState } from '../session/selectors'
import {
  selectDepartureVehicle,
  selectReturnVehicle
} from '../vehicleSelections/selectors'
import { selectForcesBooking } from '../ferryRouteSelections/selectors'
import {
  selectActiveDepartureCrossing,
  selectActiveReturnCrossing
} from '../activeCrossings/selectors'
import { selectCurrentBookingPromoState } from '../selectors/selectCurrentBookingPromoState'
import {
  selectActiveDepartureAccommodations,
  selectActiveReturnAccommodations
} from '../activeAccommodations/selectors'
import {
  selectCustomerAccount,
  selectFindReservationDetails
} from '../user/selectors'
import { selectPaymentFerryId } from '../paymentConfirmation/selectors'
import {
  selectDuplicatePassengerQuantities,
  selectDeparturePets,
  selectReturnPets
} from '../passengerSelections/selectors'

export const startBookingRequest = createAction(START_BOOKING_REQUEST)
export const setBookingSuccess = createAction(SET_BOOKING_SUCCESS)
export const setBookingError = createAction(SET_BOOKING_ERROR)
export const resetSetBooking = createAction(RESET_SET_BOOKING)
export const clearBookingData = createAction(CLEAR_BOOKING_DATA)
export const clearTicketNumbers = createAction(CLEAR_TICKET_NUMBERS)
export const clearReceipts = createAction(CLEAR_RECEIPTS)

export const attemptingPreloadTransaction = createAction(
  ATTEMPT_PRELOAD_TRANSACTION
)
export const preloadTransactionError = createAction(PRELOAD_TRANSACTION_ERROR)
export const preloadTransactionSuccess = createAction(
  PRELOAD_TRANSACTION_SUCCESS
)

export const preloadTransaction = (
  { params = {} },
  bookingNumber
) => async dispatch => {
  const values = { ...params }
  dispatch(attemptingPreloadTransaction())

  dispatch(startSessionTimer()) // Refresh session on all api requests

  // generate orderNumber, this needs to unique every time we fetch
  // the api "ticket" for moneris aka preload api call
  // it needs to be saved for when we have a successful payment to pass to Dolli.
  // we currently pass it to the payment call through location.state
  // Using Math.floor(Date.now() / 1000) to match how Dolli does this and keep it consistent across payment methods.
  const orderNumber = `${Math.floor(Date.now() / 1000)}-${bookingNumber || ''}`
  // save this orderNumber for payment call on success.
  const preloadRequest = await apiPreloadBookingTransaction({
    ...values,
    orderNo: orderNumber
  })

  if (
    !!preloadRequest.error ||
    !preloadRequest.success?.ticket ||
    !preloadRequest.success?.id
  ) {
    return dispatch(preloadTransactionError())
  }

  // Note: success is shape({ ticket, id })
  return dispatch(
    preloadTransactionSuccess({ ...preloadRequest.success, orderNumber })
  )
}

export const attemptingGetTransactionReceipt = createAction(
  ATTEMPT_GET_TRANSACTION_RECEIPT
)
export const getTransactionReceiptError = createAction(
  GET_TRANSACTION_RECEIPT_ERROR
)
export const getTransactionReceiptSuccess = createAction(
  GET_TRANSACTION_RECEIPT_SUCCESS
)

export const getTransactionReceipt = ({
  params = {},
  isTelephoneBooking
}) => async dispatch => {
  const values = {
    ...params,
    isPhoneBooking: (!!isTelephoneBooking).toString()
  }
  dispatch(attemptingGetTransactionReceipt())

  dispatch(startSessionTimer()) // Refresh session on all api requests
  const receiptRequest = await apiBookingTransactionReceipt(values)

  if (
    !receiptRequest ||
    !!receiptRequest.error ||
    !receiptRequest.success?.receipt ||
    receiptRequest.success?.receipt?.success === 'false' ||
    !receiptRequest.success?.id
  ) {
    console.error('!!! got transaction receipt error!')
    return dispatch(
      getTransactionReceiptError(
        receiptRequest.success?.receipt?.success === 'false'
          ? 'paymentDeclinedError'
          : 'paymentConfirmationError'
      )
    )
  }

  // Note: success is shape({ receipt, id })
  return dispatch(getTransactionReceiptSuccess(receiptRequest.success))
}

/**
 * POST BOOKING API WORKFLOW:
 *
 * - assembly booking api requried parameters
 * - send request
 * - save result
 * - handle error
 */
export const postBooking = ({
  bookingInformation = false,
  commitChanges = false, // ONLY SET TO TRUE ONCE READY TO MAKE THE BOOKING
  activeModifyBooking = false,
  modificationId = false,
  /*
    Note:
    We can't generate passengers on summary postBooking when modifying,
    we need to have proper user information for pricing
  */
  modifyPassengers = false,
  /*
    Note:
    additionalParams adds travel agent specific request params
  */
  additionalParams = {}
}) => async (dispatch, getState) => {
  // initialize booking request
  let modifyBookingId = modificationId
  dispatch(startBookingRequest())

  // selectors
  const state = getState()
  const configurationData = selectConfigurationData(state)
  const ferryId = selectPaymentFerryId(state)
  const forcesBooking = selectForcesBooking(state)
  const promoState = selectCurrentBookingPromoState(state)
  const sessionData = selectSessionState(state)
  const departureCrossing = selectActiveDepartureCrossing(state)
  const returnCrossing = selectActiveReturnCrossing(state)
  const departurePets = selectDeparturePets(state)
  const returnPets = selectReturnPets(state)

  let ticketType = departureCrossing.ticketType

  const duplicatePassengerQuantities = selectDuplicatePassengerQuantities(state)
  const customerData = selectCustomerAccount(state)
  const reservationDetails = selectFindReservationDetails(state)

  // derived constants from selectors
  const configOptions = get(configurationData, 'ferry.options')
  const { currency, language } = sessionData
  const departures = []
  const toDos = []
  const samePassengersOnReturn = Boolean(
    duplicatePassengerQuantities || !Boolean(returnCrossing)
  )

  // vehicle data
  let departureVehicle = selectDepartureVehicle(state)
  let returnVehicle = selectReturnVehicle(state)

  if (
    defaultToVehicleOptionsList &&
    bookingInformation.licenseNumber &&
    departureVehicle.vehicleType &&
    returnVehicle.vehicleType
  ) {
    departureVehicle = {
      ...departureVehicle,
      vehicleLicense: bookingInformation.licenseNumber
    }

    returnVehicle = {
      ...returnVehicle,
      vehicleLicense: bookingInformation.licenseNumber
    }
  }

  // accomodations data
  let departureAccommodations = false
  let returnAccommodations = false

  if (ENABLE_ACCOMMODATIONS) {
    departureAccommodations = selectActiveDepartureAccommodations(state)
    returnAccommodations = selectActiveReturnAccommodations(state)
  }

  // miscellaneous parameters
  let acceptsContact = bookingInformation?.acceptsContact || 'N'
  let billingInformation
  let consent = 'false'
  let params

  if (bookingInformation) consent = bookingInformation.consent

  const departureAddOns = []
  const returnAddOns = []
  if (process.env.REACT_APP_ENABLE_EXTRAS_STEP) {
    if (departureCrossing?.reservedSeats) {
      const seats = departureCrossing?.reservedSeats || []
      departureAddOns.push({
        resourceCode: 'RDS',
        amount: seats.length,
        number: seats.map(seat => seat.number).join('|'),
        place: seats.map(seat => seat.place).join('|'),
        waitList: 'false'
      })
    }

    if (returnCrossing?.reservedSeats) {
      const seats = returnCrossing?.reservedSeats || []
      returnAddOns.push({
        resourceCode: 'RDS',
        amount: seats.length,
        number: seats.map(seat => seat.number).join('|'),
        place: seats.map(seat => seat.place).join('|'),
        waitList: 'false'
      })
    }
  }

  const departurePetsAddOns = [
    {
      resourceCode: 'PET',
      amount: departurePets
    }
  ]

  const returnPetsAddOns = [
    {
      resourceCode: 'PET',
      amount: returnPets
    }
  ]

  let passengerTicketType = ticketType
  let vehicleTicketType = ticketType
  let accommodationTicketType = ticketType
  let productCode = configOptions.passengerBookingProductCode

  // change the passenger ticket types if this is a Canadian Forces-promo ticket
  if (process.env.REACT_APP_ENABLE_FORCES_INPUT && forcesBooking) {
    const {
      CFIDPassengerTicketType,
      CFIDBookingProductCode,
      CFIDVehicleTicketCode
    } = configurationData.ferry.options
    productCode = CFIDBookingProductCode
    passengerTicketType = CFIDPassengerTicketType
    vehicleTicketType = CFIDVehicleTicketCode
    toDos.push({
      type: 'I',
      message: 'Verify ID'
    })
  }

  // collate our crossing data as departure and return

  departures.push(
    formatCrossingData({
      crossing: departureCrossing,
      vehicleDetails: departureVehicle,
      accommodationDetails: departureAccommodations,
      passengerTicketType,
      vehicleTicketType,
      accommodationTicketType,
      passengers: bookingInformation?.departures?.[0]?.passengers || [],
      isModify: !!modifyBookingId,
      addOns: departureAddOns,
      pets: departurePetsAddOns
    })
  )

  // departure information is formatted where
  // departure[0] = departure, departure[1] = return
  if (returnCrossing) {
    departures.push(
      formatCrossingData({
        crossing: returnCrossing,
        vehicleDetails: returnVehicle,
        accommodationDetails: returnAccommodations,
        passengerTicketType,
        vehicleTicketType,
        accommodationTicketType,
        passengers: bookingInformation?.returns?.[0]?.passengers || [],
        isModify: !!modifyBookingId,
        addOns: returnAddOns,
        pets: returnPetsAddOns
      })
    )
  }

  // query params
  params = {
    ferryId,
    commitChanges: commitChanges.toString(), // for API.
    customerNumber: customerData?.customerNumber,
    currency,
    language,
    departures,
    consent,
    acceptsContact,
    productCode,
    samePassengersOnReturn: samePassengersOnReturn.toString(),
    toDos
  }

  // for `find reservation` to modify booking
  if (reservationDetails) {
    params = {
      ...params,
      modifyByResId: true,
      modifyByResIdParams: { email: reservationDetails.email }
    }
  }

  if (promoState.valid) {
    params.coupon = promoState.code
    params.productCode =
      promoState.coupons?.[0]?.productCode || params.productCode
  }

  /*
    Note:
    additionalParams adds travel agent specific request params
  */
  if (additionalParams && Object.keys(additionalParams).length) {
    params = {
      ...params,
      ...additionalParams
    }
  }

  // add the billing information if reasonable
  if (
    !bookingInformation.registerAccountData &&
    bookingInformation.billingInformation
  ) {
    billingInformation = bookingInformation.billingInformation

    if (defaultToVehicleOptionsList) {
      billingInformation = { contact: '', title: '', ...billingInformation }
    }
  }

  if (bookingInformation.registerAccountData) {
    dispatch(startSessionTimer()) // Refresh session on all api requests
    // trying to register new account...
    const registerRequest = await apiRegister({
      ...bookingInformation.registerAccountData
    })

    if (registerRequest.errorKey || !registerRequest.success) {
      return dispatch(setBookingError(registerRequest.errorKey))
    } else {
      billingInformation = getBillingFromCustomerAccount(
        registerRequest.success
      )

      // Tie new customer number to booking params
      if (registerRequest.success.customerNumber) {
        params.customerNumber = registerRequest.success.customerNumber
      }

      dispatch(registerUserSuccess(registerRequest.success))
    }
  }

  if (!billingInformation && !commitChanges) {
    // we need to send empty strings for the API not to fail.
    // with some testing these appear to be the minimum required fields. .
    billingInformation = {
      title: 'MR',
      name: 'Passenger',
      address: '123 fake',
      city: 'Halifax',
      county: 'NS',
      countryCode: 'CAN',
      postCode: '1a1a1a',
      email: 'test-user@example.com',
      contact: ''
    }

    // if we actually modified the address, fill that out
    if (activeModifyBooking?.address) {
      const {
        title,
        name,
        address,
        city,
        county,
        countryCode,
        postCode,
        email,
        contact
      } = activeModifyBooking?.address

      billingInformation = {
        title,
        name,
        address,
        city,
        county,
        countryCode,
        postCode,
        email,
        contact
      }
    }

    PHONE_FIELDS.all.forEach(phoneField => {
      billingInformation[phoneField] = '5555555555' // note: must be 10 length
    })
  }

  // data doesn't add up
  if (!billingInformation) {
    return dispatch(setBookingError(DEFAULT_BOOKING_ERROR_KEY))
  }

  params.billingInfo = billingInformation

  let requestResult = null
  dispatch(startSessionTimer()) // Refresh session on all api requests

  if (modifyBookingId) {
    // Replace empty passenger arrays with previous booking passengers
    if (modifyPassengers) {
      if (
        params?.departures?.[0]?.passengers &&
        modifyPassengers?.departures?.[0]?.passengers
      ) {
        params.departures[0].passengers =
          modifyPassengers.departures[0].passengers
      }
      if (
        params?.departures?.[1]?.passengers &&
        modifyPassengers?.returns?.[0]?.passengers
      ) {
        params.departures[1].passengers = modifyPassengers.returns[0].passengers
      }
    }

    // do the posting for an in-progress booking
    requestResult = await asyncPostModifyBooking({
      params,
      modificationId: modifyBookingId,
      commitChanges
    })
  } else {
    // actually post the booking
    requestResult = await asyncPostBooking({ params })
  }

  if (requestResult.success) {
    return dispatch(setBookingSuccess({ ...requestResult.successData }))
  } else {
    if (
      requestResult?.error?.description ===
      'Not enough capacity, try changing date or resources'
    ) {
      return dispatch(setBookingError('noCapacity'))
    } else if (
      requestResult?.error?.description === CANCELLED_WITHOUT_REFUND_MSG
    ) {
      return dispatch(setBookingError('__content:forcedCancellation'))
    } else {
      return dispatch(setBookingError(DEFAULT_BOOKING_ERROR_KEY))
    }
  }
}

export const preloadChaseTransaction = (
  params = {},
  bookingNumber
) => async dispatch => {
  const values = { ...params }
  dispatch(attemptingPreloadTransaction())

  dispatch(startSessionTimer()) // Refresh session on all api requests

  // generate orderNumber, this needs to unique every time we fetch
  // the api uid for chase aka preload api call
  // it needs to be saved for when we have a successful payment to pass to Dolli.
  // we currently pass it to the payment call through location.state
  // Using Math.floor(Date.now() / 1000) to match how Dolli does this and keep it consistent across payment methods.
  const orderNumber = `${Math.floor(Date.now() / 1000)}-${bookingNumber || ''}`
  // save this orderNumber for payment call on success.
  const preloadRequest = await apiGenerateChaseUid({
    ...values,
    orderId: orderNumber
  })

  if (!!preloadRequest?.error || !preloadRequest?.success?.uid) {
    return dispatch(preloadTransactionError())
  }
  // Note: success is shape({ ticket, id })
  return dispatch(
    preloadTransactionSuccess({ ...preloadRequest.success, orderNumber })
  )
}

export const getChaseTransactionReceipt = ({
  uid,
  params = {},
  isTelephoneBooking
}) => async dispatch => {
  const values = {
    uid,
    params,
    isPhoneBooking: (!!isTelephoneBooking).toString()
  }
  dispatch(attemptingGetTransactionReceipt())

  dispatch(startSessionTimer()) // Refresh session on all api requests
  const receiptRequest = await apiChaseTransactionReceipt(values)
  if (
    !receiptRequest ||
    !!receiptRequest.error ||
    !receiptRequest.success?.receipt ||
    receiptRequest.success?.receipt?.status_code === '00' ||
    !receiptRequest.success?.id
  ) {
    console.error('!!! got transaction receipt error!')
    return dispatch(
      getTransactionReceiptError(
        receiptRequest.success?.receipt?.status_code
          ? 'paymentDeclinedError'
          : 'paymentConfirmationError'
      )
    )
  }

  // Note: success is shape({ receipt, id })
  return dispatch(getTransactionReceiptSuccess(receiptRequest.success))
}
