import React, { useState } from 'react'
import { object, func, number, bool } from 'prop-types'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import cabinPlaceholder from '../../assets/images/placeholders/cabin.jpg'
import ExtrasCard from '../ExtrasCard/ExtrasCard'
import Counter from '../buttons/Counter'
import SvgIcon from '../SvgIcon/SvgIcon'
import { selectLabels } from '../../redux/configuration/selectors'
import { formatPriceByLanguage } from '../../redux/configuration/utilities'
import { selectLanguage } from '../../redux/session/selectors'
import { renderContentValues } from '../../redux/configuration/utilities'
import { capitalizeFirstLetter } from '../../configuration/utilities'
import {
  accommodationResources,
  ENABLE_WAITLIST_ACCOMMODATIONS
} from '../../configuration/constants'

const OpenableAccommodationCard = ({
  accommodation,
  handleCountChange,
  curDepartureCount,
  curReturnCount,
  isOneWay,
  content,
  departureRoute,
  returnRoute,
  isDepartureWaitList,
  isReturnWaitList
}) => {
  const labels = useSelector(selectLabels)
  const lang = useSelector(selectLanguage)
  const title = content?.title || ''
  const description = content?.textBlocks || []
  const disclaimer = content?.disclaimer || ''
  const images = content?.images?.length
    ? content.images
    : [{ path: cabinPlaceholder }]
  const displayImage = images[0]
  const resourceCode = get(accommodation, ['details', 'resourceCode'])
  const departureMax = Math.max(
    Number(get(accommodation, ['departure', 'freeCapacity'])),
    0
  )
  const returnMax = Math.max(
    Number(get(accommodation, ['return', 'freeCapacity'])),
    0
  )

  const availableForDeparture = !!accommodation.departure
  const availableForReturn = !isOneWay && !!accommodation.return

  // only show wait list if the accommodation is available but has reached maximum capacity
  const showWaitList =
    ENABLE_WAITLIST_ACCOMMODATIONS && (isDepartureWaitList || isReturnWaitList)

  // get the waitlist limits if we use those
  let waitListDepartureMax = 0
  if (departureRoute.waitListLimit) {
    const waitListLimit = departureRoute.waitListLimit.find(
      w => w.resourceCode === resourceCode
    )
    if (waitListLimit?.limit) {
      waitListDepartureMax = Math.max(
        waitListLimit.limit - Number(accommodation?.departure?.waitListed),
        0
      )
    }
  }

  let waitListReturnMax = 0
  if (returnRoute?.waitListLimit && accommodation.return) {
    const waitListLimit = returnRoute.waitListLimit.find(
      w => w.resourceCode === resourceCode
    )
    if (waitListLimit?.limit && accommodation.return) {
      waitListReturnMax = Math.max(
        waitListLimit.limit - Number(accommodation?.return?.waitListed),
        0
      )
    }
  }

  const [isOpen, setIsOpen] = useState(false)
  const [departureCount, setDepartureCount] = useState(curDepartureCount || 0)
  const [returnCount, setReturnCount] = useState(curReturnCount || 0)
  const [departureWaitListCount, setDepartureWaitListCount] = useState(
    isDepartureWaitList ? curDepartureCount || 0 : 0
  )
  const [returnWaitListCount, setReturnWaitListCount] = useState(
    isReturnWaitList ? curReturnCount || 0 : 0
  )

  const pricing = Object.entries(accommodation).reduce((obj, [key, values]) => {
    if (key !== 'details') {
      obj[key] = +values.price
    }
    return obj
  }, {})
  const startingFrom = formatPriceByLanguage(
    Math.min(...Object.values(pricing)),
    lang
  )

  // for reserved seat, the price label should be per seat
  const isReservedSeat = resourceCode === accommodationResources.RDS
  const priceUnitLabel = isReservedSeat ? labels.perSeat : labels.perCabin
  const openButtonText = isReservedSeat
    ? labels.addReservedSeatBtn
    : labels.addCabinsBtn
  const confirmButtonText = isReservedSeat
    ? labels.addReservedSeatBtn
    : labels.addCabinsBtn
  const closeButtonText = `${capitalizeFirstLetter(labels.close)} X`

  const priceElement = (
    <div className="extras__card-start">
      <div className="extras__card-start-label">{labels.startingFrom}</div>
      <div className="extras__card-start-value">{startingFrom}</div>
      <div className="extras__card-start-label">{priceUnitLabel}</div>
    </div>
  )

  const cardContent = [
    {
      attributes: 'h4',
      value: title,
      id: `${resourceCode}-header`
    },
    ...description.map(line => ({ value: line })),
    {
      attributes: 'italic',
      value: disclaimer,
      id: `${resourceCode}-disclaimer`
    }
  ]

  const incrementDeparture = () =>
    setDepartureCount(Math.min(departureCount + 1, departureMax || Infinity))
  const decrementDeparture = () =>
    setDepartureCount(Math.max(departureCount - 1, 0))
  const incrementReturn = () =>
    setReturnCount(Math.min(returnCount + 1, returnMax || Infinity))
  const decrementReturn = () => setReturnCount(Math.max(returnCount - 1, 0))

  const incrementDepartureWaitList = () => {
    setDepartureWaitListCount(
      Math.min(
        departureWaitListCount + 1,
        Math.max(waitListDepartureMax, 0) || Infinity
      )
    )
  }
  const decrementDepartureWaitList = () =>
    setDepartureWaitListCount(Math.max(departureWaitListCount - 1, 0))
  const incrementReturnWaitList = () =>
    setReturnWaitListCount(
      Math.min(
        returnWaitListCount + 1,
        Math.max(waitListReturnMax, 0) || Infinity
      )
    )
  const decrementReturnWaitList = () =>
    setReturnWaitListCount(Math.max(returnWaitListCount - 1, 0))

  let soldOutHeaderText
  if (
    (!availableForDeparture || departureMax === 0) &&
    (!availableForReturn || returnMax === 0)
  ) {
    soldOutHeaderText = labels.soldOut
  } else if (!isOneWay && (!availableForReturn || returnMax === 0)) {
    soldOutHeaderText = labels.soldOutReturn
  } else if (!availableForDeparture || departureMax === 0) {
    soldOutHeaderText = labels.soldOutDeparture
  }

  let soldOutHeader = !soldOutHeaderText ? null : (
    <div className="extras__card-warning">
      <span>
        <span className="extras__card-warning-icon">
          <SvgIcon type="warning" fill="white" />
        </span>
        {soldOutHeaderText}
      </span>
      {isOpen ? (
        <span
          className="extras__card-warning-close"
          onClick={() => setIsOpen(false)}
        >
          {closeButtonText}
        </span>
      ) : null}
    </div>
  )

  const quantitySelectGrid = (
    <div className={`extras__card-cabin-grid-${isOneWay ? 'ow' : 'rt'}`}>
      <div className="extras__card-grid-label">{labels.departureTrip}</div>
      {!isOneWay && (
        <div className="extras__card-grid-label">{labels.returnTrip}</div>
      )}
      <div className="extras__card-counter-container">
        <Counter
          value={departureCount}
          handleIncrement={incrementDeparture}
          handleDecrement={decrementDeparture}
          disableIncrement={
            !availableForDeparture ||
            isDepartureWaitList ||
            departureCount >= departureMax
          }
          disableDecrement={
            !availableForDeparture || isDepartureWaitList || departureCount <= 0
          }
          disabled={
            !availableForDeparture || isDepartureWaitList || departureMax <= 0
          }
          size="xsmall"
        />
      </div>
      {!isOneWay && (
        <Counter
          value={returnCount}
          handleIncrement={incrementReturn}
          handleDecrement={decrementReturn}
          disableIncrement={
            !availableForReturn || isReturnWaitList || returnCount >= returnMax
          }
          disableDecrement={
            !availableForReturn || isReturnWaitList || returnCount <= 0
          }
          disabled={!availableForReturn || isReturnWaitList || returnMax <= 0}
          size="xsmall"
        />
      )}
    </div>
  )

  const quantityWaitListSelectGrid = showWaitList ? (
    <>
      <div className={`extras__card-cabin-grid-${isOneWay ? 'ow' : 'rt'}`}>
        <div className="extras__card-grid-label">
          Waitlist {labels.departureTrip}
        </div>
        {!isOneWay && (
          <div className="extras__card-grid-label">
            Waitlist {labels.returnTrip}
          </div>
        )}
        <div className="extras__card-counter-container">
          <Counter
            value={departureWaitListCount}
            handleIncrement={incrementDepartureWaitList}
            handleDecrement={decrementDepartureWaitList}
            disableIncrement={
              !isDepartureWaitList ||
              departureWaitListCount >= waitListDepartureMax
            }
            disableDecrement={
              !isDepartureWaitList || departureWaitListCount === 0
            }
            disabled={
              !isDepartureWaitList ||
              departureWaitListCount >= waitListDepartureMax
            }
            size="xsmall"
          />
        </div>
        {!isOneWay && (
          <Counter
            value={returnWaitListCount}
            handleIncrement={incrementReturnWaitList}
            handleDecrement={decrementReturnWaitList}
            disableIncrement={
              !isReturnWaitList || returnWaitListCount >= waitListReturnMax
            }
            disableDecrement={!isReturnWaitList || returnWaitListCount === 0}
            disabled={
              !isReturnWaitList || returnWaitListCount >= waitListReturnMax
            }
            size="xsmall"
          />
        )}
      </div>
    </>
  ) : null

  const handleConfirmClick = () => {
    if (isDepartureWaitList) {
      handleCountChange(
        accommodation,
        'departure',
        departureWaitListCount - curDepartureCount,
        true
      )
    } else {
      handleCountChange(
        accommodation,
        'departure',
        departureCount - curDepartureCount
      )
    }

    if (!isOneWay) {
      if (isReturnWaitList) {
        handleCountChange(
          accommodation,
          'return',
          returnWaitListCount - curReturnCount,
          true
        )
      } else {
        handleCountChange(accommodation, 'return', returnCount - curReturnCount)
      }
    }
    setIsOpen(false)
  }

  if (isOpen) {
    return (
      <div className="extras__card-container-narrow">
        <div className="extras__card">
          {soldOutHeader ? (
            soldOutHeader
          ) : (
            <div
              className="extras__card-close sm"
              onClick={() => setIsOpen(false)}
            >
              {closeButtonText}
            </div>
          )}
          <div className="extras__card-content">
            <div>{renderContentValues(cardContent)}</div>
            <div className="extras__card-bottom">{priceElement}</div>
            {quantitySelectGrid}
            {quantityWaitListSelectGrid}
            <button
              className="extras__card-btn-confirm btn btn-primary"
              onClick={handleConfirmClick}
            >
              {confirmButtonText}
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="extras__card-container-narrow">
        <ExtrasCard
          labels={labels}
          content={cardContent}
          imageUrl={displayImage.path}
          buttonText={openButtonText}
          onButtonClick={() => setIsOpen(true)}
          startingFrom={startingFrom}
          warningHeader={soldOutHeader}
          priceUnitLabel={priceUnitLabel}
          resourceCode={resourceCode}
        />
      </div>
    )
  }
}

OpenableAccommodationCard.propTypes = {
  accommodation: object.isRequired,
  handleCountChange: func.isRequired,
  curDepartureCount: number.isRequired,
  curReturnCount: number.isRequired,
  isOneWay: bool.isRequired,
  content: object
}

export default OpenableAccommodationCard
