import { handleActions } from 'redux-actions'
import {
  CONTINUE_TO_PATHNAME,
  CLEAR_PATHNAME,
  LOGIN_PATHNAME
} from './constants'

const modalInitialState = {
  pathToContinueTo: false,
  loginPathName: '/'
}

const modalReducer = handleActions(
  {
    [CONTINUE_TO_PATHNAME]: (state, { payload }) => ({
      ...state,
      pathToContinueTo: payload
    }),
    [CLEAR_PATHNAME]: (state, { payload }) => ({
      ...state,
      pathToContinueTo: false
    }),
    [LOGIN_PATHNAME]: (state, { payload }) => ({
      ...state,
      loginPathName: payload
    })
  },
  { ...modalInitialState }
)

export { modalInitialState }
export default modalReducer
