import { createSelector } from 'reselect'

export const selectTelephoneBookingState = state => state.telephoneBooking

export const selectTelephoneBookingData = createSelector(
  selectTelephoneBookingState,
  telephoneBookingState => telephoneBookingState.data
)

export const selectTelephoneCheckoutInit = createSelector(
  selectTelephoneBookingState,
  telephoneBookingState => telephoneBookingState.checkoutFormInitialized
)
