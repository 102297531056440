import { createSelector } from 'reselect'
import { transformBookingsArray } from '../../utilities/bookings/transformBookingsArray'
import yn from 'yn'

const travelAgentsEnabled = yn(process.env.REACT_APP_ENABLE_TRAVEL_AGENT_USERS)
const selectTravelAgentState = state => state.travelAgentData

export const selectTravelAgentUpdateLoading = createSelector(
  selectTravelAgentState,
  travelAgentState => travelAgentState.accountUpdateLoading
)
export const selectTravelAgentUpdateSuccess = createSelector(
  selectTravelAgentState,
  travelAgentState => travelAgentState.accountUpdateSuccess
)
export const selectTravelAgentUpdateError = createSelector(
  selectTravelAgentState,
  travelAgentState => travelAgentState.accountUpdateError
)

export const selectTravelAgentAccount = createSelector(
  selectTravelAgentState,
  travelAgentState =>
    !!(
      travelAgentsEnabled &&
      travelAgentState.account &&
      Object.keys(travelAgentState.account).length
    )
      ? travelAgentState.account
      : false
)

export const selectTravelAgentBookingParams = createSelector(
  selectTravelAgentAccount,
  account => {
    let result = {}

    if (account && travelAgentsEnabled) {
      const { agentNumber, paymentMethod, paymentType } = account

      result = {
        agentNumber,
        agentPaymentMethod: paymentMethod,
        agentPaymentType: paymentType
      }
    }

    return result
  }
)

export const selectTravelAgentAccountGetLoading = createSelector(
  selectTravelAgentState,
  travelAgentState => travelAgentState.accountGetLoading
)

export const selectTravelAgentBookings = createSelector(
  selectTravelAgentState,
  travelAgentState => transformBookingsArray(travelAgentState?.bookings)
)

export const selectTravelAgentBookingsGetLoading = createSelector(
  selectTravelAgentState,
  travelAgentState => travelAgentState.bookingsGetLoading
)

export const selectTravelAgentBookingsGetError = createSelector(
  selectTravelAgentState,
  travelAgentState => travelAgentState.bookingsGetError
)

export const selectTravelAgentLoginLoading = createSelector(
  selectTravelAgentState,
  travelAgentState => travelAgentState.loginLoading
)
export const selectTravelAgentLoginSuccess = createSelector(
  selectTravelAgentState,
  travelAgentState => travelAgentState.loginSuccess
)
export const selectTravelAgentLoginError = createSelector(
  selectTravelAgentState,
  travelAgentState => travelAgentState.loginError
)
