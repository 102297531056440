import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { func, string, shape } from 'prop-types'
import { generateTripDetails } from '../BookingInformation/utilities'
import MonerisConfirmation from '../MonerisConfirmation/MonerisConfirmation'
import appRoutes from '../../configuration/appRoutes'
import { clearTicketNumbers, clearReceipts } from '../../redux/booking/actions'
import { selectReceipts } from '../../redux/booking/selectors'
import { formatPriceByLanguage } from '../../redux/configuration/utilities'
import { clearActiveModifyBooking } from '../../redux/modifyBooking/actions'
import { fetchPaymentConfirmation } from '../../redux/paymentConfirmation/actions'
import { selectPaymentConfirmationState } from '../../redux/paymentConfirmation/selectors'
import { DEFAULT_LANG } from '../../redux/user/constants'
import { selectLoginSuccess } from '../../redux/user/selectors'

const ChaseCheckoutConfirmationContainer = ({
  lang,
  labels,
  additionalContent,
  handleRouteChange,
  bookingConfirmationData,
  isTelephoneBooking,
  ferryId
}) => {
  const dispatch = useDispatch()
  const paymentConfirmationState = useSelector(selectPaymentConfirmationState)
  const receipts = useSelector(selectReceipts)
  const loginSuccess = useSelector(selectLoginSuccess)

  // Note: get receipt success reducer replaces all previous receipts with 1 new receipt
  const receipt = receipts && Object.values(receipts)?.[0]
  const [error, setError] = useState(null)
  const [
    eCommercePurchaseEventTriggered,
    setECommercePurchaseEventTriggered
  ] = useState(false)

  const location = useLocation()
  // we pass this on a successful moneris checkout payment.
  // it is used by dolli to link payments to bookings
  const orderNo = location?.state?.monerisCheckoutData?.orderNo
  const isLoading = false // TODO: Might need this
  const clientId = process.env.REACT_APP_FLOW_CLIENT_ID
  const tripDetails = generateTripDetails({
    ...bookingConfirmationData,
    lang,
    labels,
    mapToEcommerceEvent: true
  })

  // eCommercePurchaseEvent
  useEffect(() => {
    if (
      tripDetails &&
      bookingConfirmationData?.bookingNumber &&
      !eCommercePurchaseEventTriggered
    ) {
      setECommercePurchaseEventTriggered(true)
    }
  }, [
    clientId,
    bookingConfirmationData,
    tripDetails,
    labels,
    lang,
    eCommercePurchaseEventTriggered
  ])

  useEffect(() => {
    if (!receipt) {
      setError(labels.paymentDeclinedError)
    } else {
      const reqBody = {
        bookingNumber: bookingConfirmationData?.bookingNumber,
        isPhonePayment: (!!isTelephoneBooking).toString(),
        currencyType: bookingConfirmationData?.bookingCurrency,
        orderId: orderNo,
        cardType: receipt?.cardType,
        amount: receipt?.amount,
        approvalCode: receipt?.approvalCode,
        transactionNumber: receipt?.transactionNumber,
        ferryId,
        paymentProvider: 'Moneris'
      }

      if (
        !paymentConfirmationState.loading &&
        !paymentConfirmationState.data &&
        !error
      ) {
        dispatch(fetchPaymentConfirmation(reqBody))
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: restore ecommerce event

  const tableData = [
    {
      label: labels.bookingNumber,
      value: bookingConfirmationData?.bookingNumber
    },
    {
      label: labels.referenceNumber,
      value: receipt?.referenceNumber
    },
    {
      label: labels.transactionNumber,
      value: receipt?.transactionNumber
    },
    {
      label: labels.transactionDate,
      value: receipt?.transactionDateTime
    },
    {
      label: labels.transactionAmount,
      value: formatPriceByLanguage(receipt?.amount, lang || DEFAULT_LANG)
    },
    {
      label: labels.transactionStatus,
      value: labels.paymentStatusPaid
    },
    {
      label: labels.authorizationCode,
      value: receipt?.approvalCode
    },
    {
      label: labels.cardType,
      value: labels[`cardType${receipt?.cardType}`] || receipt?.cardType
    }
  ]

  return (
    <MonerisConfirmation
      labels={labels}
      isLoading={isLoading}
      error={error}
      tableData={tableData}
      additionalContent={additionalContent}
      isLoggedIn={loginSuccess}
      onPrimaryClick={() => {
        dispatch(clearActiveModifyBooking())
        dispatch(clearTicketNumbers())
        dispatch(clearReceipts())
        handleRouteChange(appRoutes.ferryRoutes.pathname)
      }}
      onMyAccountBtnClick={() => {
        dispatch(clearActiveModifyBooking())
        dispatch(clearTicketNumbers())
        dispatch(clearReceipts())
        handleRouteChange(appRoutes.account.pathname)
      }}
    />
  )
}

ChaseCheckoutConfirmationContainer.propTypes = {
  lang: string.isRequired,
  labels: shape().isRequired,
  additionalContent: shape().isRequired,
  handleRouteChange: func.isRequired,
  bookingConfirmationData: shape().isRequired,
  ferryId: string.isRequired
}

export default ChaseCheckoutConfirmationContainer
