import React, { Fragment } from 'react'
import { arrayOf, oneOfType, string, shape, bool, func } from 'prop-types'
import { crossingTypes } from '../../../redux/crossings/constants'
import { mergeBestRates } from '../../../redux/crossings/utilities'
import BrandLoader from '../../BrandLoader/BrandLoader'
import VehicleForm from '../VehicleForm/VehicleForm.js'

const VehicleSelectionForms = ({
  clientContactPhoneNumber,
  selectedDepartureHeightFilter,
  selectedDepartureLengthFilter,
  selectedReturnHeightFilter,
  selectedReturnLengthFilter,
  departureVehicleLocked,
  activeSelection,
  vehicleOptions,
  language,
  tooltips,
  tooltipIcons,
  labels,
  crossingData,
  isLoading,
  handleChange,
  handleVehicleSelection,
  formValues,
  renderReturnVehicleForm,
  departureVehicleErrors,
  returnVehicleErrors,
  modalIsOpen,
  openModal,
  closeModal,
  walkOnCode,
  allowOneWayWalkOn
}) => {
  let departureVehicleSection
  if (departureVehicleLocked) {
    departureVehicleSection = null
  } else {
    departureVehicleSection = (
      <VehicleForm
        clientContactPhoneNumber={clientContactPhoneNumber}
        selectedHeightFilter={selectedDepartureHeightFilter}
        selectedLengthFilter={selectedDepartureLengthFilter}
        activeSelection={activeSelection}
        vehicleOptions={vehicleOptions}
        language={language}
        tooltips={tooltips}
        tooltipIcons={tooltipIcons}
        labels={labels}
        crossingData={crossingData}
        isLoading={isLoading}
        modalIsOpen={modalIsOpen}
        openModal={openModal}
        closeModal={closeModal}
        handleChange={(groupType, val) => {
          handleChange(crossingTypes.DEPARTURE, groupType, val)
        }}
        handleVehicleSelection={vehicle => {
          handleVehicleSelection(crossingTypes.DEPARTURE, vehicle)
        }}
        formValues={formValues[crossingTypes.DEPARTURE]}
        formErrors={departureVehicleErrors}
        title={renderReturnVehicleForm ? labels.arrivingVehicle : null}
        bestRates={
          renderReturnVehicleForm
            ? crossingData?.departure?.resourceBestRates
            : mergeBestRates(crossingData)
        }
        walkOnCode={walkOnCode}
        allowOneWayWalkOn={
          allowOneWayWalkOn &&
          formValues[crossingTypes.RETURN]?.vehicleType !== walkOnCode
        }
      />
    )
  }

  return isLoading ? (
    <BrandLoader />
  ) : (
    <div className="passenger-vehicles__selections">
      {departureVehicleSection}
      {renderReturnVehicleForm && (
        <Fragment>
          <div className="rule-container">
            {!departureVehicleLocked && <hr />}
          </div>
          <div style={{ paddingTop: '32px' }}>
            <VehicleForm
              clientContactPhoneNumber={clientContactPhoneNumber}
              selectedHeightFilter={selectedReturnHeightFilter}
              selectedLengthFilter={selectedReturnLengthFilter}
              activeSelection={activeSelection}
              vehicleOptions={vehicleOptions}
              language={language}
              tooltips={tooltips}
              tooltipIcons={tooltipIcons}
              labels={labels}
              crossingData={crossingData}
              isLoading={isLoading}
              modalIsOpen={modalIsOpen}
              openModal={openModal}
              closeModal={closeModal}
              handleChange={(groupType, val) => {
                handleChange(crossingTypes.RETURN, groupType, val)
              }}
              handleVehicleSelection={vehicle => {
                handleVehicleSelection(crossingTypes.RETURN, vehicle)
              }}
              formValues={formValues[crossingTypes.RETURN]}
              formErrors={returnVehicleErrors}
              title={labels.departureVehicle}
              bestRates={crossingData?.return?.resourceBestRates}
              walkOnCode={walkOnCode}
              allowOneWayWalkOn={
                allowOneWayWalkOn &&
                formValues[crossingTypes.DEPARTURE]?.vehicleType !== walkOnCode
              }
            />
          </div>
        </Fragment>
      )}
    </div>
  )
}

VehicleSelectionForms.propTypes = {
  clientContactPhoneNumber: string,
  selectedDepartureHeightFilter: oneOfType([bool, shape()]).isRequired,
  selectedDepartureLengthFilter: oneOfType([bool, shape()]).isRequired,
  selectedReturnHeightFilter: oneOfType([bool, shape()]).isRequired,
  selectedReturnLengthFilter: oneOfType([bool, shape()]).isRequired,
  selectedHeightFilter: shape(),
  selectedLengthFilter: shape(),
  activeSelection: string.isRequired,
  vehicleOptions: shape().isRequired,
  language: string.isRequired,
  tooltips: shape().isRequired,
  tooltipIcons: arrayOf(shape()).isRequired,
  labels: shape().isRequired,
  crossingData: shape().isRequired,
  isLoading: bool.isRequired,
  handleChange: func.isRequired,
  handleVehicleSelection: func.isRequired,
  formValues: shape().isRequired,
  renderReturnVehicleForm: bool.isRequired,
  departureVehicleErrors: shape().isRequired,
  returnVehicleErrors: shape().isRequired,
  modalIsOpen: shape().isRequired,
  openModal: func.isRequired,
  closeModal: func.isRequired
}

export default VehicleSelectionForms
