import { createSelector } from 'reselect'
import moment from 'moment'
import { DEFAULT_LANG } from '../user/constants'
import { selectLanguage } from '../session/selectors'
import {
  ENABLE_ACCOMMODATIONS,
  ENABLE_FORCES_INPUT
} from '../../configuration/constants'
import dateFormats from '../../configuration/dateFormats'
import { getContentComponents } from './utilities'

export const selectConfigurationState = state => state.configurationData

export const selectFetchingWebsiteStatus = createSelector(
  selectConfigurationState,
  configState => configState.fetchingWebsiteStatus
)

export const selectFetchingLabels = createSelector(
  selectConfigurationState,
  configState => configState.fetchingLabels
)

export const selectWebsiteStatus = createSelector(
  selectConfigurationState,
  configState => configState.websiteStatus
)

export const selectWebsiteStatusError = createSelector(
  selectConfigurationState,
  configState => configState.websiteStatusError
)

export const selectFetchingActiveSessions = createSelector(
  selectConfigurationState,
  configState => configState.fetchingActiveSessions
)

export const selectActiveSessions = createSelector(
  selectConfigurationState,
  configState => configState.activeSessions
)

export const selectActiveSessionsError = createSelector(
  selectConfigurationState,
  configState => configState.activeSessionsError
)

export const selectCanUseApp = createSelector(
  selectConfigurationState,
  configState => configState.canUseApp
)

export const selectConfigurationData = createSelector(
  selectConfigurationState,
  configState => configState.data
)

export const selectConfigurationError = createSelector(
  selectConfigurationState,
  configState => configState.error
)

export const selectLabels = createSelector(
  selectConfigurationState,
  selectLanguage,
  (configState, language) => {
    const stateLabels = configState?.labels?.[language]
    const sessionTopLabels = window.sessionStorage.getItem('labels')
    let sessionLabels
    if (sessionTopLabels) {
      const parsedLabels = JSON.parse(sessionTopLabels)
      if (parsedLabels && parsedLabels[language]) {
        sessionLabels = parsedLabels[language]
      }
    }
    return sessionLabels || stateLabels || {}
  }
)

export const selectFields = createSelector(
  selectConfigurationState,
  selectLanguage,
  (configState, language) => {
    const stateFields = configState?.fields?.[language]
    const sessionTopFields = window.sessionStorage.getItem('fields')
    let sessionFields
    if (sessionTopFields) {
      const parsedFields = JSON.parse(sessionTopFields)
      if (parsedFields && parsedFields[language]) {
        sessionFields = parsedFields[language]
      }
    }

    return { ...sessionFields } || { ...stateFields } || {}
  }
)

export const selectConfigurationFerryOptions = createSelector(
  selectConfigurationData,
  data => (data && data.ferry && data.ferry.options) || {}
)

export const selectConfigurationContent = createSelector(
  selectConfigurationState,
  selectLanguage,
  (configState, language) => {
    return configState?.content?.[language] || null
  }
)

export const selectDefaultProductCode = createSelector(
  selectConfigurationFerryOptions,
  options => options.defaultProductCode || ''
)

export const selectHeaderContent = createSelector(
  selectConfigurationContent,
  content => content?.headerContent
)

export const selectAccessibilityResources = createSelector(
  selectConfigurationContent,
  content => content?.accessibilityModalOptions || []
)

export const selectAccessibilityModalContent = createSelector(
  selectConfigurationContent,
  content => content?.accessibilityModalContent
)

export const selectAccessibilityCardContent = createSelector(
  selectConfigurationContent,
  content => content?.accessibilityCardContent
)
export const selectKennelCardContent = createSelector(
  selectConfigurationContent,
  content => content?.kennelCardContent
)

export const selectServiceMessageContent = createSelector(
  selectConfigurationContent,
  content => {
    const topList = content?.serviceMessageContent?.value
    let result = {}

    if (topList) {
      topList.forEach(listItem => {
        const { ferryId, type, value } = listItem
        if (
          ferryId &&
          type === 'serviceMessage' &&
          value &&
          Array.isArray(value) &&
          value.length > 0
        ) {
          result[ferryId] = value
        }
      })
    }

    return Object.keys(result).length > 0 ? result : false
  }
)

export const selectRoutesContent = createSelector(
  selectConfigurationContent,
  content => content?.routes
)

export const selectAccommodationsContent = createSelector(
  selectConfigurationContent,
  content => (ENABLE_ACCOMMODATIONS ? content?.accommodationView : {})
)

export const selectPassengerInformationContent = createSelector(
  selectConfigurationContent,
  content => {
    const rawContent = content?.passengerInformationContent
    let result = {}
    let valueKey = {}

    if (rawContent) valueKey = getContentComponents(rawContent)

    result = {
      ...rawContent,
      value: valueKey
    }

    return result
  }
)

export const selectConfirmationContent = createSelector(
  selectConfigurationContent,
  content => {
    const rawContent = content?.confirmationViewContent
    let result = {}
    let valueKey = {}

    if (rawContent) valueKey = getContentComponents(rawContent)

    result = {
      ...rawContent,
      value: valueKey
    }

    return result
  }
)

export const selectCustomerOptions = createSelector(
  selectConfigurationFerryOptions,
  options => options.customer
)

export const selectCustomerLanguages = createSelector(
  selectCustomerOptions,
  customer =>
    customer && customer.languages ? customer.languages : [DEFAULT_LANG]
)

export const selectAvailableVehicleOptions = state => {
  const configOption = selectConfigurationContent(state)
  if (!configOption) return null

  const {
    vehicleResourceCodesExpanded: vehicles,
    vehicleGroupings: groups
  } = configOption

  const options = {
    groups: {
      length: [],
      height: []
    },
    vehicles
  }

  for (const group of groups) {
    if (options.groups[group.type]) {
      options.groups[group.type].push(group)
    }
  }

  return options
}

export const selectVehicleViewContent = createSelector(
  selectConfigurationContent,
  content => {
    const rawContent = content?.vehicleViewContent
    let result = {}
    let valueKey = {}

    if (rawContent) valueKey = getContentComponents(rawContent)

    result = {
      ...rawContent,
      value: valueKey
    }

    return result
  }
)

export const selectBookingStatusCodes = createSelector(
  selectConfigurationFerryOptions,
  options =>
    options.bookingStatusCodes
      ? options.bookingStatusCodes.reduce((obj, codeSet) => {
          obj[codeSet.code.toLowerCase()] = codeSet
          return obj
        }, {})
      : {}
)

export const selectPaymentBookingStatusCodes = createSelector(
  selectConfigurationFerryOptions,
  options =>
    options.bookingPaymentStatusCodes
      ? options.bookingPaymentStatusCodes.reduce((obj, codeSet) => {
          obj[codeSet.code.toLowerCase()] = codeSet
          return obj
        }, {})
      : {}
)

export const selectIsHighSession = (state, date) => {
  const options = selectConfigurationFerryOptions(state)

  const highSessions = options?.highSeason

  if (highSessions) {
    // we need to turn this into two moment dates for our range
    const range = highSessions.split('|')
    const highSessionStart = moment(range[0], 'MM-DD')
    const highSessionEnd = moment(range[1], 'MM-DD')

    return moment(date, dateFormats.default).isBetween(
      highSessionStart,
      highSessionEnd
    )
  }

  return false
}

export const selectForcesCodeModalContent = createSelector(
  selectConfigurationContent,
  content =>
    (ENABLE_FORCES_INPUT &&
      content?.canadianForcesModalContent?.value?.[0]?.value) ||
    false
)

export const selectBicycleResourceCode = createSelector(
  selectConfigurationFerryOptions,
  options => (options?.bicycleResourceCode ? options.bicycleResourceCode : null)
)
