import { createAction } from 'redux-actions'

import formatActiveRouteData from '../../utilities/content/formatActiveRouteData'
import { setPaymentFerryId } from '../paymentConfirmation/actions'
import {
  SET_ROUTE_FORM,
  SET_DEPARTURE_DATE,
  SET_DEPARTURE_ROUTE,
  SET_RETURN_DATE,
  SET_RETURN_ROUTE,
  SET_FORCES_BOOKING
} from './constants'
import { selectActiveRouteForm } from './selectors'
import { setCurrency } from '../session/actions'

export const setRouteForm = createAction(SET_ROUTE_FORM)
export const setDepartureDate = createAction(SET_DEPARTURE_DATE)

export const setDepartureRoute = (route, shouldSetPaymentId = true) => (
  dispatch,
  getState
) => {
  const activeRouteForm = selectActiveRouteForm(getState())

  if (route?.ferryId && shouldSetPaymentId) {
    dispatch(setPaymentFerryId(route.ferryId))
  }

  dispatch({
    type: SET_DEPARTURE_ROUTE,
    payload: formatActiveRouteData(route, activeRouteForm)
  })

  // FIXME: update `currency` in session from selected route...
  // cause crossing api calling need correct `currency` parameter in case of issue:
  // https://jira.verbinteractive.com/browse/BAY202-124
  dispatch(setCurrency(route.displayCurrency))
}
export const setReturnDate = createAction(SET_RETURN_DATE)
export const setReturnRoute = route => (dispatch, getState) => {
  const activeRouteForm = selectActiveRouteForm(getState())

  dispatch({
    type: SET_RETURN_ROUTE,
    payload: formatActiveRouteData(route, activeRouteForm)
  })
}
export const setForcesBooking = createAction(SET_FORCES_BOOKING)
