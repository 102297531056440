import React, { Fragment } from 'react'
import { string, node, oneOfType, bool, func } from 'prop-types'
import { Field, ErrorMessage } from 'formik'

const renderCheckbox = ({
  name,
  value,
  label,
  disabled,
  errorClasses,
  formikCheckbox = true,
  onBlur,
  onChange
}) => (
  <Checkbox
    name={name}
    value={value}
    label={label}
    disabled={disabled}
    errorClasses={errorClasses}
    formikCheckbox={formikCheckbox}
    onChange={onChange}
    onBlur={onBlur}
  />
)

const Checkbox = ({
  name,
  value,
  label,
  disabled,
  errorClasses,
  formikCheckbox,
  onChange,
  onBlur
}) => {
  const attributes = {
    id: name,
    type: 'checkbox',
    name,
    checked: value,
    disabled
  }
  if (onBlur) {
    attributes.onBlur = onBlur
  }
  if (onChange) {
    attributes.onChange = onChange
  }
  return (
    <Fragment>
      <label htmlFor={name} className="checkbox-row">
        {formikCheckbox ? <Field {...attributes} /> : <input {...attributes} />}
        <span className="checkbox-row-label">
          {label}
          {formikCheckbox && (
            <span className={errorClasses}>
              <ErrorMessage name={name} />
            </span>
          )}
        </span>
      </label>
    </Fragment>
  )
}

Checkbox.propTypes = {
  name: string.isRequired,
  value: bool.isRequired,
  label: oneOfType([string, node]).isRequired,
  disabled: bool.isRequired,
  errorClasses: string,
  formikCheckbox: bool,
  onBlur: func,
  onChange: func
}

Checkbox.defaultProps = {
  formikCheckbox: true
}

export { renderCheckbox }
export default Checkbox
