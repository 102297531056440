import React, { Fragment, useState, useEffect } from 'react'
import { func } from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
// ************ CONSTANTS *************
import {
  passengerTypeOptionMap,
  PHONE_FIELDS
} from '../../redux/configuration/constants'
import appRoutes from '../../configuration/appRoutes'
// ************ ACTIONS *************
import {
  registerUser,
  resetRegisterError,
  resetAttemptingRegister
} from '../../redux/user/actions'
// *********** COMPONENTS ***********
import RowGroup from '../../components/RowGroup/RowGroup'
import Notification from '../../components/Notification'
import { renderTextInput } from '../../components/TextInput/TextInput'
// ********* SELECTORS **************
import {
  selectLabels,
  selectFields,
  selectDefaultProductCode,
  selectCanUseApp
} from '../../redux/configuration/selectors'
import {
  selectAttemptingRegister,
  selectRegisterUserError,
  selectRegisterUserSuccess
} from '../../redux/user/selectors'
import { selectSessionId } from '../../redux/session/selectors'
// *********** UTILITIES ************
import {
  addDynamicLabelsToOptions,
  cleanPhoneNumber
} from '../../configuration/utilities'
import {
  getInitialValuesFromFields,
  filterFieldsByName,
  getSchemaFromFieldDetails,
  renderFormFields
} from '../../redux/configuration/utilities'
import { getFieldsAndLayout } from './registerFormUtilities'

// *********** STYLES ***************
import '../../styles/scss/components/forms/page-form-defaults.scss'

const RegisterContainer = ({ handleRouteChange }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const labels = useSelector(selectLabels)
  const [emailMismatchError, setEmailMismatchError] = useState(false)
  const [passwordMismatchError, setPasswordMismatchError] = useState(false)
  const [minPhoneNumberError, setMinPhoneNumberError] = useState(false)
  const [showDataEntryError, setShowDataEntryError] = useState(false)

  /*
    the first nad last name are combined for the api and have different max length
    then just first and last name individually.
    From ticket CTM200-452: each field should be max 30 characters.
    However, in the booking itself, the max length for the ""Name"" field (which combines the first name and last name) is max 40 characters.
  * */
  const [nameLengthError, setNameLengthError] = useState(null)
  const defaultProductCode = useSelector(selectDefaultProductCode)
  const attemptingRegister = useSelector(selectAttemptingRegister)
  const registerUserSuccess = useSelector(selectRegisterUserSuccess)
  const registerUserError = useSelector(selectRegisterUserError)
  const sessionId = useSelector(selectSessionId)
  const canUseApp = useSelector(selectCanUseApp)
  const fields = useSelector(selectFields)

  const { fieldNames, formLayout } = getFieldsAndLayout()
  const fieldDetails = filterFieldsByName(fields, fieldNames)
  const disabled = attemptingRegister || !canUseApp

  // note: Adding ages to passenger type labels dynamically here
  if (fieldDetails?.passengerType?.options) {
    const newOptions = addDynamicLabelsToOptions({
      options: fieldDetails.passengerType.options,
      nameValueMap: passengerTypeOptionMap,
      customLabelSubstring: 'AgeDesc',
      labels
    })

    if (newOptions.length > 0) {
      fieldDetails.passengerType.options = newOptions
    }
  }

  const initialValues = getInitialValuesFromFields(fieldDetails)
  const validationSchema = getSchemaFromFieldDetails(fieldDetails)

  useEffect(() => {
    if (registerUserSuccess) {
      dispatch(resetAttemptingRegister())
      handleRouteChange(appRoutes.account.pathname)
    }
  }, [dispatch, history, registerUserSuccess, handleRouteChange])
  const handleSubmit = ({
    password,
    confirmPassword,
    acceptsContact,
    emailAddress,
    emailAddressConfirm,
    firstName,
    lastName,
    gender,
    ...restValues
  }) => {
    let submitError = false
    setPasswordMismatchError(false)
    setEmailMismatchError(false)
    setMinPhoneNumberError(false)
    setNameLengthError(false)
    if (password !== confirmPassword) {
      setPasswordMismatchError(true)
      submitError = true
    }

    if (emailAddress !== emailAddressConfirm) {
      setEmailMismatchError(true)
      submitError = true
    }

    PHONE_FIELDS.client.forEach(phoneField => {
      if (
        restValues[phoneField].length < 10 &&
        PHONE_FIELDS.requiredStatus[phoneField] === true
      ) {
        setMinPhoneNumberError(true)
        submitError = true
      }
    })
    // using >= 39 to account for the space we need to add when sending to the api.
    if (
      firstName?.length &&
      lastName?.length &&
      firstName.length + lastName.length >= 39
    ) {
      setNameLengthError(true)
      submitError = true
    }

    if (!submitError) {
      let registerValues = {
        ...restValues,
        password,
        emailAddress,
        firstName,
        lastName,
        defaultProductCode,
        acceptsContact: !!acceptsContact ? 'Y' : 'N',
        gender: 'M'
      }
      PHONE_FIELDS.client.forEach(phoneField => {
        registerValues[phoneField] = cleanPhoneNumber(restValues[phoneField])
      })
      dispatch(registerUser(registerValues, sessionId))
    }
  }

  return (
    <div className="page-container">
      <span className="page-container-top-bg" />
      {!fieldDetails ? null : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validate={() => setShowDataEntryError(true)}
        >
          {({ setFieldValue, values, errors, handleChange }) => {
            const hasErrors = Boolean(errors && Object.keys(errors).length)
            return !values ? null : (
              <Form>
                <div className="row-group-container row-group-container-with-top-bg">
                  <div className="row-group-container-bg row-group-container-with-top-bg">
                    <div className="row-group-container-header">
                      <h1 className="theme-font-header">
                        {labels.registerTitle}
                        <span>{labels.allFieldsAreRequired}</span>
                      </h1>
                    </div>
                    <RowGroup
                      convertInputsToCaps
                      render={({ formRowClasses, errorClasses }) => (
                        <Fragment>
                          {renderFormFields({
                            formLayout,
                            fieldDetails,
                            values,
                            setFieldValue,
                            disabled,
                            formRowClasses,
                            errorClasses
                          })}
                          <h4 className="row-group-subtitle theme-font-header">
                            {labels.password}
                          </h4>
                          <span className={formRowClasses}>
                            {fieldDetails.password &&
                              renderTextInput({
                                name: fieldDetails.password.name,
                                value: values[fieldDetails.password.name],
                                type: 'password',
                                placeholder: fieldDetails.password.label,
                                errorClasses,
                                setFieldValue,
                                disabled,
                                inputHelpText: labels.passwordHelper
                              })}
                            {fieldDetails.confirmPassword &&
                              renderTextInput({
                                name: fieldDetails.confirmPassword.name,
                                value:
                                  values[fieldDetails.confirmPassword.name],
                                type: 'password',
                                placeholder: fieldDetails.confirmPassword.label,
                                errorClasses,
                                setFieldValue,
                                disabled
                              })}
                          </span>
                          {registerUserError && (
                            <Notification
                              type="error"
                              message={
                                labels[registerUserError.errorKey]
                                  ? labels[registerUserError.errorKey]
                                  : ''
                              }
                              onDismiss={() => {
                                dispatch(resetRegisterError())
                              }}
                            />
                          )}
                          {emailMismatchError && (
                            <Notification
                              type="error"
                              message={labels.confirmEmailError}
                              onDismiss={() => {
                                setEmailMismatchError(false)
                              }}
                            />
                          )}
                          {passwordMismatchError && (
                            <Notification
                              type="error"
                              message={labels.passwordMismatch}
                              onDismiss={() => {
                                setPasswordMismatchError(false)
                              }}
                            />
                          )}
                          {minPhoneNumberError && (
                            <Notification
                              type="error"
                              message={labels.minPhoneNumberError}
                              onDismiss={() => {
                                setMinPhoneNumberError(false)
                              }}
                            />
                          )}
                          {nameLengthError && (
                            <Notification
                              type="error"
                              message={labels.combinedNameMaxLengthErrorMessage}
                              onDismiss={() => {
                                setNameLengthError(false)
                              }}
                            />
                          )}
                        </Fragment>
                      )}
                    />
                  </div>
                  <div className="fullpage-submit-row">
                    <button
                      type="submit"
                      className="btn btn-primary large-primary-btn"
                      disabled={disabled}
                    >
                      {labels.register}
                    </button>
                    {hasErrors &&
                      showDataEntryError &&
                      labels.genericDataEntryError !== '' && (
                        <>
                          <br />
                          <Notification
                            type="error"
                            message={labels.genericDataEntryError}
                            onDismiss={() => {
                              setShowDataEntryError(false)
                            }}
                          />
                        </>
                      )}
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

RegisterContainer.propTypes = {
  handleRouteChange: func.isRequired
}

export default RegisterContainer
