import { createSelector } from 'reselect'
import { ModifyFlowType } from '../../configuration/constants'
import { selectLabels } from '../configuration/selectors'

export const selectActiveModifyBookingState = state => state.modifyBookingData

export const selectActiveModifyBooking = createSelector(
  selectActiveModifyBookingState,
  modifyBookingState => modifyBookingState.activeBooking
)

export const selectBookingCancellationState = (id, state) => {
  return state.modifyBookingData.cancelBookingDetails?.[id] || {}
}

export const selectCancelBookingDetails = createSelector(
  selectActiveModifyBookingState,
  modifyBookingState => modifyBookingState.cancelBookingDetails
)

export const selectModifyFlowType = createSelector(
  selectActiveModifyBooking,
  booking => {
    if (!booking) {
      return null
    }

    const departures = booking.departures

    if (departures.length === 1) {
      return ModifyFlowType.ONE_WAY
    } else if (departures[0].editStatus.canEdit) {
      return ModifyFlowType.ROUND_TRIP
    } else {
      return ModifyFlowType.DEPARTURE_LOCKED
    }
  }
)

export const selectIsVehicleEditable = createSelector(
  selectActiveModifyBooking,
  activeModifyBooking => {
    if (!activeModifyBooking || activeModifyBooking?.departures?.length === 1) {
      return true
    } else {
      const [departureTrip, returnTrip] = activeModifyBooking.departures
      const departureVehicleExists = departureTrip.vehicles.booked.length > 0
      const returnVehicleExists = returnTrip.vehicles.booked.length > 0
      const both = departureVehicleExists && returnVehicleExists
      const neither = !departureVehicleExists && !returnVehicleExists
      return both || neither
    }
  }
)

export const selectContinueButtonOnPassengersPage = createSelector(
  selectActiveModifyBooking,
  selectModifyFlowType,
  selectLabels,
  (activeModifyBooking, modifyFlowType, labels) => {
    // new booking, or one way booking, go vehicle step
    if (!modifyFlowType || modifyFlowType === ModifyFlowType.ONE_WAY) {
      return labels.continueToVehicleInformationBtn
    }

    // modify booking..., both routes have or not have vehicles, go vehicle step
    const [departureTrip, returnTrip] = activeModifyBooking.departures
    const departureVehicleExists = departureTrip.vehicles.booked.length === 0
    const returnVehicleExists = returnTrip.vehicles.booked.length === 0
    if (departureVehicleExists === returnVehicleExists) {
      return labels.continueToVehicleInformationBtn
    }

    // modify booking..., and departrue locked, go return step
    if (modifyFlowType === ModifyFlowType.DEPARTURE_LOCKED) {
      return labels.continueToReturnTrip
    }

    // most complex case:
    // only one route have vehicles, this case not supported currently,
    // skip vehicle step, go departure step!
    return labels.continueToSailingsBtn
  }
)
