import React, { useEffect, createContext } from 'react'
import { node } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { getAccount as getTravelAgentAccount } from '../redux/travelAgent/actions'
import { AGENT_LOGIN_SESSION_KEY } from '../redux/travelAgent/constants'
import {
  selectTravelAgentAccount,
  selectTravelAgentAccountGetLoading
} from '../redux/travelAgent/selectors'
import { selectSessionId } from '../redux/session/selectors'
const { Provider, Consumer } = createContext()

const UserProvider = ({ children }) => {
  const dispatch = useDispatch()
  const loggedInTravelAgentUserName = sessionStorage.getItem(
    AGENT_LOGIN_SESSION_KEY
  )
  const travelAgentAccount = useSelector(selectTravelAgentAccount)
  const travelAgentAccountGetLoading = useSelector(
    selectTravelAgentAccountGetLoading
  )

  const sessionId = useSelector(selectSessionId)

  useEffect(() => {
    if (
      loggedInTravelAgentUserName &&
      !travelAgentAccount &&
      !travelAgentAccountGetLoading
    ) {
      dispatch(getTravelAgentAccount(loggedInTravelAgentUserName, sessionId))
    }
  }, [
    dispatch,
    loggedInTravelAgentUserName,
    travelAgentAccount,
    sessionId,
    travelAgentAccountGetLoading
  ])

  return <Provider value={{ userLoading: false }}>{children}</Provider>
}

UserProvider.propTypes = {
  children: node.isRequired
}

export { UserProvider }
export default Consumer
