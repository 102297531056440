import moment from 'moment'
import {
  CLEAR_USER_BOOKINGS,
  FETCH_USER_BOOKINGS,
  USER_BOOKING_ERROR,
  USER_BOOKING_SUCCESS,
  DEFAULT_FETCH_BOOKINGS_ERROR,
  SHOW_NON_REFUNDED
} from './constants'
import { startSessionTimer } from '../session/actions'
import { selectSessionId } from '../session/selectors'
import { selectCustomerAccount } from '../user/selectors'
import { fetchCustomerBookings } from '../../api/api'
import dateFormats from '../../configuration/dateFormats'
import { createAction } from 'redux-actions'

export const fetchingUserBookings = createAction(FETCH_USER_BOOKINGS)
export const fetchUserBookingsSuccess = createAction(USER_BOOKING_SUCCESS)
export const fetchUserBookingsError = createAction(USER_BOOKING_ERROR)
export const clearUserBookings = createAction(CLEAR_USER_BOOKINGS)
export const showNonRefundedModal = createAction(SHOW_NON_REFUNDED)
export const getCustomerBookings = (start, end) => (dispatch, getState) => {
  const state = getState()

  if (state?.userBookings?.loading) {
    return
  }

  dispatch(fetchingUserBookings())

  const sessionId = selectSessionId(state)
  const customer = selectCustomerAccount(state)
  const customerNumber = customer?.customerNumber

  const reqBody = { id: customerNumber, sessionId }

  if (start) {
    reqBody.start = start
    if (!end) {
      reqBody.end = moment(start, dateFormats.default)
        .add(1, 'months')
        .format(dateFormats.default)
    }
  }

  if (end) {
    reqBody.end = end
    if (!start) {
      reqBody.start = moment(end, dateFormats.default)
        .subtract(1, 'months')
        .format(dateFormats.default)
    }
  }

  dispatch(startSessionTimer()) // Refresh session on all api requests

  if (customerNumber) {
    return fetchCustomerBookings(reqBody)
      .then(res => {
        const bookings = res?.data?.bookings

        if (bookings) {
          dispatch(fetchUserBookingsSuccess(reduceBookings(bookings)))
        } else {
          dispatch(fetchUserBookingsError(DEFAULT_FETCH_BOOKINGS_ERROR))
        }
      })
      .catch(err => {
        const errorMessage = err?.response?.data?.['hydra:description']
        dispatch(
          fetchUserBookingsError(
            errorMessage ? errorMessage : DEFAULT_FETCH_BOOKINGS_ERROR
          )
        )
      })
  }
}

function reduceBookings(bookings) {
  return bookings.reduce((obj, booking) => {
    obj[booking.bookingNumber] = booking
    return obj
  }, {})
}
