import { selectDepartureRoute } from '../ferryRouteSelections/selectors'
import { createSelector } from 'reselect'
import { selectAvailableVehicleOptions } from '../configuration/selectors'

const selectRouteVehicleOptions = createSelector(
  selectAvailableVehicleOptions,
  selectDepartureRoute,
  (vehicleOptions, departureRoute) => {
    if (!vehicleOptions) return null
    const { groups, vehicles } = vehicleOptions
    if (departureRoute?.vehicleTypes) {
      const routeTypeCodes = departureRoute?.vehicleTypes?.map(
        vt => vt.resourceCode
      )
      return {
        groups,
        vehicles: vehicles.filter(v => routeTypeCodes.includes(v.resourceCode))
      }
    } else {
      return vehicleOptions
    }
  }
)

export default selectRouteVehicleOptions
