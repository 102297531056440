import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SingleDatePicker } from 'react-dates'

import { useDispatch, useSelector } from 'react-redux'
import {
  setDepartureDate,
  setReturnDate
} from '../../../../redux/ferryRouteSelections/actions'
import * as UserSelectionActions from '../../../../redux/userSelections/actions'
import {
  selectDepartureDate,
  selectReturnDate
} from '../../../../redux/ferryRouteSelections/selectors'

import FerryRoutesFormDateSelect from './FerryRoutesFormDateSelect'

import moment from 'moment'
import { debounce } from 'lodash'
import { selectLanguage } from '../../../../redux/session/selectors'

const FerryRoutesFormSingleDatePicker = ({ ferryLabels, onChange }) => {
  const dispatch = useDispatch()
  const departureDate = useSelector(selectDepartureDate)
  const returnDate = useSelector(selectReturnDate)
  const language = useSelector(selectLanguage)
  const [focus, setFocus] = useState(false)

  const [calendarSize, setCalendarSize] = useState(
    window.innerWidth > 1024 ? 'lg' : 'sm'
  )

  function checkNumberOfMonths() {
    const size = window.innerWidth > 1024 ? 'lg' : 'sm'

    if (calendarSize !== size) {
      setCalendarSize(size)
    }
  }

  const debounced = debounce(checkNumberOfMonths, 100)

  useEffect(() => {
    window.addEventListener('resize', debounced)

    return () => {
      debounced.cancel()
      window.removeEventListener('resize', debounced)
    }
  })

  const handleOnDateChange = date => {
    dispatch(setDepartureDate(moment(date))) // TODO: Deprecate
    dispatch(
      UserSelectionActions.setDepartureSearchDate(date.format('YYYY-MM-DD'))
    )
    if (returnDate) {
      dispatch(setReturnDate(null)) // TODO: Deprecate
      dispatch(UserSelectionActions.setReturnSearchDate(null))
    }
    if (onChange) {
      onChange(date)
    }
  }

  const toggleCustomDate = dateField => {
    const targetDatePicker = 'one-way-' + dateField
    document.getElementById(targetDatePicker).focus()
  }

  return departureDate ? (
    <div className="ferry-routes__form-single-date-picker">
      <SingleDatePicker
        block={true}
        date={departureDate ? departureDate.locale(language) : departureDate}
        daySize={36}
        displayFormat="MMM D, YYYY"
        focused={focus}
        horizontalMargin={30}
        id="one-way-departure"
        isOutsideRange={date =>
          date.isBefore(moment()) || date.isAfter(moment().add(18, 'months'))
        }
        navNext={
          <i className="material-icons" tabIndex="0">
            east
          </i>
        }
        navPrev={
          <i className="material-icons" tabIndex="0">
            west
          </i>
        }
        noBorder={true}
        numberOfMonths={1}
        onDateChange={handleOnDateChange}
        onFocusChange={() => {
          if (!focus) {
            const targetHeight = window.innerHeight + 300
            window.setTimeout(() => {
              window.scrollTo(0, targetHeight)
            }, 250)
          }

          setFocus(!focus)
        }}
        firstDayOfWeek={0}
        transitionDuration={0}
      />

      <div className="ferry-routes__form-single-date-picker-selects">
        <FerryRoutesFormDateSelect
          activeDateValue={departureDate}
          dateField="departure"
          ferryLabels={ferryLabels}
          fieldLabel={ferryLabels.departureDate}
          toggleDateEvent={toggleCustomDate}
        />
      </div>
    </div>
  ) : null
}

FerryRoutesFormSingleDatePicker.defaultProps = {
  date: null
}

FerryRoutesFormSingleDatePicker.propTypes = {
  ferryLabels: PropTypes.shape(),
  date: PropTypes.func
}

export default FerryRoutesFormSingleDatePicker
