export const tripDetailKeys = {
  ACCOMMODATIONS: 'accommodations',
  PASSENGER: 'passenger',
  VEHICLE: 'vehicle',
  ITEM: 'item',
  ID: 'id',
  NAME: 'name',
  DESCRIPTION: 'description',
  QUANTITY: 'quantity',
  PRICE: 'price',
  SUBTOTAL: 'subtotal'
}

// Note mockActiveModifyPassengers updates Mar1 2021
export const mockActiveModifyPassengers = {
  departureRoute: {
    passengers: {
      amount: 1,
      booked: [
        {
          concessionNumber: '',
          consent: 'true',
          countryCode: 'CAN',
          dateOfBirth: '',
          disibility: null,
          firstName: 'TEST',
          frequentTravellerNumber: '',
          gender: 'M',
          lastName: 'USER',
          name: 'TEST USER',
          nameListId: 'P1',
          note: '',
          passengerType: 'A',
          passport: '',
          passportExpiryDate: '0001-01-01T00:00:00',
          placeOfBirth: '',
          resourceCode: 'PAX',
          specialRemarks: '',
          ticketType: 'REG',
          title: null,
          typeOfId: ''
        }
      ]
    }
  },
  returnRoute: {
    passengers: {
      amount: 1,
      booked: [
        {
          concessionNumber: '',
          consent: 'true',
          countryCode: 'CAN',
          dateOfBirth: '',
          disibility: null,
          firstName: 'TEST',
          frequentTravellerNumber: '',
          gender: 'M',
          lastName: 'USER',
          name: 'TEST USER',
          nameListId: 'P1',
          note: '',
          passengerType: 'A',
          passport: '',
          passportExpiryDate: '0001-01-01T00:00:00',
          placeOfBirth: '',
          resourceCode: 'PAX',
          specialRemarks: '',
          ticketType: 'REG',
          title: null,
          typeOfId: ''
        }
      ]
    }
  }
}

// Note expectedModifyPassengerInitialValues updated Mar1 2021
export const expectedModifyPassengerInitialValues = {
  'departures-A-1': {
    accessibility: false,
    accessibilityTextField: '',
    firstName: 'TEST',
    lastName: 'USER',
    type: 'departures',
    passengerType: 'A',
    gender: 'M',
    concessionNumber: '',
    consent: 'true',
    countryCode: 'CAN',
    dateOfBirth: '',
    disibility: null,
    frequentTravellerNumber: '',
    name: 'TEST USER',
    nameListId: 'P1',
    note: '',
    passport: '',
    passportExpiryDate: '0001-01-01T00:00:00',
    placeOfBirth: '',
    resourceCode: 'PAX',
    specialRemarks: '',
    ticketType: 'REG',
    title: null,
    typeOfId: ''
  },
  'departures-S-1': {
    accessibility: false,
    accessibilityTextField: '',
    firstName: '',
    lastName: '',
    type: 'departures',
    passengerType: 'S',
    gender: ''
  },
  'departures-E-1': {
    accessibility: false,
    accessibilityTextField: '',
    firstName: '',
    lastName: '',
    type: 'departures',
    passengerType: 'E',
    gender: ''
  },
  'departures-E-2': {
    accessibility: false,
    accessibilityTextField: '',
    firstName: '',
    lastName: '',
    type: 'departures',
    passengerType: 'E',
    gender: ''
  },
  'departures-B-1': {
    accessibility: false,
    accessibilityTextField: '',
    firstName: '',
    lastName: '',
    type: 'departures',
    passengerType: 'B',
    gender: ''
  }
}

export const expectedPreparedPassengerDetailsOnModify = {
  departures: [
    {
      passengers: [
        {
          firstName: 'TEST',
          lastName: 'USER',
          passengerType: 'A',
          gender: 'M',
          concessionNumber: '',
          consent: 'true',
          countryCode: 'CAN',
          dateOfBirth: '',
          disibility: null,
          frequentTravellerNumber: '',
          name: 'TEST USER',
          nameListId: 'P1',
          note: '',
          passport: '',
          passportExpiryDate: '0001-01-01T00:00:00',
          placeOfBirth: '',
          resourceCode: 'PAX',
          specialRemarks: '',
          ticketType: 'REG',
          title: null,
          typeOfId: ''
        },
        { firstName: '', lastName: '', passengerType: 'S', gender: '' },
        { firstName: '', lastName: '', passengerType: 'E', gender: '' },
        { firstName: '', lastName: '', passengerType: 'E', gender: '' },
        { firstName: '', lastName: '', passengerType: 'B', gender: '' }
      ]
    }
  ],
  returns: [
    {
      passengers: [
        {
          firstName: 'TEST',
          lastName: 'USER',
          passengerType: 'A',
          gender: 'M',
          concessionNumber: '',
          consent: 'true',
          countryCode: 'CAN',
          dateOfBirth: '',
          disibility: null,
          frequentTravellerNumber: '',
          name: 'TEST USER',
          nameListId: 'P1',
          note: '',
          passport: '',
          passportExpiryDate: '0001-01-01T00:00:00',
          placeOfBirth: '',
          resourceCode: 'PAX',
          specialRemarks: '',
          ticketType: 'REG',
          title: null,
          typeOfId: ''
        },
        { firstName: '', lastName: '', passengerType: 'S', gender: '' },
        { firstName: '', lastName: '', passengerType: 'E', gender: '' },
        { firstName: '', lastName: '', passengerType: 'E', gender: '' },
        { firstName: '', lastName: '', passengerType: 'B', gender: '' }
      ]
    }
  ]
}

// Note: mockSharedPassengerFields updated Feb16th 2021
export const mockSharedPassengerFields = {
  accessibility: false,
  accessibilityTextField: '',
  firstName: '',
  lastName: '',
  type: '',
  passengerType: '',
  gender: ''
}

// Note: mockPassengerFieldDetails updated Feb16th 2021
export const mockPassengerFieldDetails = {
  departures: [
    {
      passengers: [
        { passengerType: 'A' },
        { passengerType: 'S' },
        { passengerType: 'E' },
        { passengerType: 'E' },
        { passengerType: 'B' }
      ]
    }
  ]
}

// Note: mockInitialValuesPassengerDetails updated Feb16th 2021
export const mockInitialValuesPassengerDetails = {
  'departures-A-1': {
    accessibility: false,
    accessibilityTextField: '',
    firstName: '',
    lastName: '',
    type: 'departures',
    passengerType: 'A',
    gender: ''
  },
  'departures-S-1': {
    accessibility: false,
    accessibilityTextField: '',
    firstName: '',
    lastName: '',
    type: 'departures',
    passengerType: 'S',
    gender: ''
  },
  'departures-E-1': {
    accessibility: false,
    accessibilityTextField: '',
    firstName: '',
    lastName: '',
    type: 'departures',
    passengerType: 'E',
    gender: ''
  },
  'departures-E-2': {
    accessibility: false,
    accessibilityTextField: '',
    firstName: '',
    lastName: '',
    type: 'departures',
    passengerType: 'E',
    gender: ''
  },
  'departures-B-1': {
    accessibility: false,
    accessibilityTextField: '',
    firstName: '',
    lastName: '',
    type: 'departures',
    passengerType: 'B',
    gender: ''
  }
}

// Note: mockCustomerAccount updated Feb16th 2021
export const mockCustomerAccount = {
  customerNumber: '100258',
  firstName: 'HWTRWUJ',
  lastName: 'WHRTUJWU',
  firstAndLastName: 'HWTRWUJ WHRTUJWU',
  companyCode: 'CTM',
  companyName: 'C.T.M.A. Traversier Ltée',
  address: '15 STREET',
  postCode: 'E12123',
  city: 'CITY',
  countryName: 'Canada',
  countryCode: 'CAN',
  county: 'NB',
  workPhoneNumber: '',
  mobilePhoneNumber: '1232456315',
  homePhoneNumber: '',
  email: 'feb16@test.com',
  defaultProductCode: 'NORM',
  contact: '',
  title: '',
  gender: 'M',
  dateOfBirth: '',
  acceptsContact: 'false',
  language: 'en',
  paymentMethod: 'C',
  paymentType: 'C',
  groupCode: 'CLIENT',
  externalId: '',
  externalDate: null,
  requiresNewPassword: 'false',
  warnAboutPasswordExpiry: 'false',
  active: 'true',
  consent: 'true',
  constentStamp: null,
  consentUser: 'API',
  restricted: 'false',
  passengerType: 'A'
}

// Note: mockValidFormValuesWithDefaultProductCode updated Feb16th 2021
export const mockValidFormValuesWithDefaultProductCode = {
  passengerDetails: {
    'departures-A-1': {
      accessibility: false,
      accessibilityTextField: '',
      firstName: 'HWTRWUJ',
      lastName: 'WHRTUJWU',
      type: 'departures',
      passengerType: 'A',
      gender: 'M'
    },
    'departures-S-1': {
      accessibility: false,
      accessibilityTextField: '',
      firstName: 'Gjtehwh',
      lastName: 'hsrtj',
      type: 'departures',
      passengerType: 'S',
      gender: 'F'
    },
    'departures-E-1': {
      accessibility: false,
      accessibilityTextField: '',
      firstName: 'hstrj',
      lastName: 'srtyjrs',
      type: 'departures',
      passengerType: 'E',
      gender: 'M'
    },
    'departures-E-2': {
      accessibility: false,
      accessibilityTextField: '',
      firstName: 'rsjy',
      lastName: 'sryjrs',
      type: 'departures',
      passengerType: 'E',
      gender: 'F'
    },
    'departures-B-1': {
      accessibility: false,
      accessibilityTextField: '',
      firstName: 'GAETH',
      lastName: 'getj',
      type: 'departures',
      passengerType: 'B',
      gender: 'M'
    }
  },
  bookingInformation: {
    mobilePhoneNumber: '123 245-6315',
    firstName: 'HWTRWUJ',
    lastName: 'WHRTUJWU',
    address: '15 STREET',
    city: 'CITY',
    countryCode: 'CAN',
    county: 'NB',
    postalCode: 'E12123',
    emailAddress: 'feb16@test.com'
  },
  consent: true,
  defaultProductCode: 'NORM'
}

// Note: mockPreparedFormValuesUsingCustomerAccount updated Feb16th 2021
export const mockPreparedFormValuesUsingCustomerAccount = {
  acceptsContact: 'N',
  billingInformation: {
    name: 'HWTRWUJ WHRTUJWU',
    address: '15 STREET',
    city: 'CITY',
    countryCode: 'CAN',
    county: 'NB',
    postCode: 'E12123',
    mobilePhoneNumber: '1232456315',
    email: 'feb16@test.com'
  },
  consent: 'true',
  departures: [
    {
      passengers: [
        {
          firstName: 'HWTRWUJ',
          lastName: 'WHRTUJWU',
          passengerType: 'A',
          gender: 'M'
        },
        {
          firstName: 'Gjtehwh',
          lastName: 'hsrtj',
          passengerType: 'S',
          gender: 'F'
        },
        {
          firstName: 'hstrj',
          lastName: 'srtyjrs',
          passengerType: 'E',
          gender: 'M'
        },
        {
          firstName: 'rsjy',
          lastName: 'sryjrs',
          passengerType: 'E',
          gender: 'F'
        },
        {
          firstName: 'GAETH',
          lastName: 'getj',
          passengerType: 'B',
          gender: 'M'
        }
      ]
    }
  ],
  returns: [
    {
      passengers: [
        {
          firstName: 'HWTRWUJ',
          lastName: 'WHRTUJWU',
          passengerType: 'A',
          gender: 'M'
        },
        {
          firstName: 'Gjtehwh',
          lastName: 'hsrtj',
          passengerType: 'S',
          gender: 'F'
        },
        {
          firstName: 'hstrj',
          lastName: 'srtyjrs',
          passengerType: 'E',
          gender: 'M'
        },
        {
          firstName: 'rsjy',
          lastName: 'sryjrs',
          passengerType: 'E',
          gender: 'F'
        },
        {
          firstName: 'GAETH',
          lastName: 'getj',
          passengerType: 'B',
          gender: 'M'
        }
      ]
    }
  ]
}
