import React, { useState } from 'react'

import seatAvailableIcon from '../../assets/icons/seat-available-symbol.svg'
import seatSelectedIcon from '../../assets/icons/seat-selected-symbol.svg'
import seatUnavailableIcon from '../../assets/icons/seat-unavailable-symbol.jpg'
import seatWheelChairIcon from '../../assets/icons/seat-wheelchair-symbol.svg'
import seatOutletIcon from '../../assets/icons/seat-outlet-symbol.svg'

import viewingWindowIcon from '../../assets/icons/window-symbol.svg'
import doorIcon from '../../assets/icons/door-symbol.svg'
import televisionIcon from '../../assets/icons/television-symbol.svg'
import stairsIcon from '../../assets/icons/stairs.png'

const SeatPlanLegend = ({ labels }) => {
  const [showOutletDetails, setShowOutletDetails] = useState(false)

  const toggleOutlet = () => {
    setShowOutletDetails(!showOutletDetails)
  }

  return (
    <div className="seat-plan-legend">
      <h3 className="legend-title">{labels.legend}</h3>
      <div className="legend-item">
        <img
          src={seatAvailableIcon}
          alt="available"
          className="legend-item-icon"
          role="none"
        />
        <p>{labels.availableSeat}</p>
      </div>
      <div className="legend-item">
        <img
          src={seatSelectedIcon}
          alt="selected"
          className="legend-item-icon"
          role="none"
        />
        <p>{labels.selectedSeat}</p>
      </div>
      <div className="legend-item">
        <img
          src={seatUnavailableIcon}
          alt="unavailable"
          className="legend-item-icon"
          role="none"
        />
        <p>{labels.unavailableSeat}</p>
      </div>
      <div className="legend-item">
        <img
          src={seatWheelChairIcon}
          alt="wheelchair"
          className="legend-item-icon"
          role="none"
        />
        <p>{labels.wheelchairAccessible}</p>
      </div>
      <div className="legend-item outlet">
        <img
          src={seatOutletIcon}
          alt="available"
          className="legend-item-icon"
          role="none"
        />
        <div className="outlet-text">
          <p>
            {labels.acCPAPMachine}{' '}
            <button className="more-details-btn" onClick={toggleOutlet}>
              {showOutletDetails
                ? `- ${labels.seeLess}`
                : `+ ${labels.seeMore}`}
            </button>
          </p>
          {showOutletDetails && (
            <p className="outlet-details">{labels.acCPAPMachineDetails}</p>
          )}
        </div>
      </div>
      <hr />
      <div className="legend-item-large">
        <img
          src={viewingWindowIcon}
          alt="viewing window"
          className="legend-item-icon-large"
          role="none"
        />
        <p>{labels.viewingWindow ?? 'Viewing Window'}</p>
      </div>
      <div className="legend-item-large door">
        <img
          src={doorIcon}
          alt="entry / exit door"
          className="legend-item-icon-large"
          role="none"
        />
        <div>
          <p>{labels.entryExitDoor ?? 'Entry / Exit Door'}</p>
          <p className="legend-details">
            {labels.entryExitDoorDetails ?? 'Security card required to enter.'}
          </p>
        </div>
      </div>
      <div className="legend-item-large">
        <img
          src={televisionIcon}
          alt="television"
          className="legend-item-icon-large"
          role="none"
        />
        <p>{labels.television ?? 'Television Screen'}</p>
      </div>
      <div className="legend-item-large">
        <img
          src={stairsIcon}
          role="none"
          alt="stairs"
          className="legend-item-icon-large"
        />
        <p>{labels.stairs ?? 'Stairs'}</p>
      </div>
    </div>
  )
}

export default SeatPlanLegend
