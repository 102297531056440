/* eslint-disable no-multi-str */
import {
  generateCountyOptions,
  renderCountryOptions
} from '../../configuration/supportedCountries'
import {
  passportCountryOptions,
  citizenshipOptions,
  typeOfIdOptions
} from '../../configuration/passportConstants'

import { IS_NFL_CLIENT } from '../../configuration/constants'

export const supportedFieldTypes = {
  TEXT: 'text',
  PASSWORD: 'password',
  TEL: 'tel',
  NUMBER: 'number',
  CHECKBOX: 'checkbox',
  TEXTAREA: 'textarea',
  SELECT: 'select',
  SELECT_TEXT_FALLBACK: 'select_text_fallback'
}

/*
  NOTE(ebarrett): SELECT_TEXT_FALLBACK is being used to turn a select into a text field for CAT,
    when country is not CAN or USA. To accomplish this, we add the following keys into the field
    details...

    fallbackOnKey     - the form field to compare the value for
    fallbackWhenNot   - the values for `fallbackOnKey` that would result in the text fallback

    This isn't ideal, I'd prefer supplying a function, but field details are being stored in session
    storage for some reason.
*/

const {
  TEXT,
  CHECKBOX,
  PASSWORD,
  TEL,
  TEXTAREA,
  SELECT,
  SELECT_TEXT_FALLBACK
} = supportedFieldTypes

export const EMPTY_SELECTION_MAP_KEY = 'emptySelection'
export const emailFields = ['email', 'emailAddress']

// TODO: countryCode, county will likely be pulled from somewhere else
export const dropdownLabelMap = {
  language: {
    emptySelection: 'language', // Primary Language
    en: 'english', // English
    fr: 'french' // French
  },
  type: {
    emptySelection: 'type', // Type
    departures: 'departure', // Departure
    returns: 'return' // Return
  },
  passengerType: {
    emptySelection: 'passengerType', // Passenger Type
    A: 'passengerTypeA', // Adult
    E: 'passengerTypeC', // Child
    C: 'passengerTypeC', // Child
    B: 'passengerTypeE', // Infant
    I: 'passengerTypeE', // Infant
    S: 'passengerTypeS', // Senior
    ST: 'passengerTypeST', // Student
    PET: 'passengerTypePET' // Pet
  },
  gender: {
    emptySelection: 'gender',
    M: 'male', // Male
    F: 'female', // Female
    X: 'genderDiverse'
  },
  title: {
    emptySelection: 'title',
    MR: 'titleMR',
    MS: 'titleMS',
    MRS: 'titleMRS',
    MX: 'titleMX'
  }
}

// This is used to generated custom dropdown labels with Ages alongside types
export const passengerTypeOptionMap = {
  empty: '',
  // infant: 'B',
  // child: 'E',
  adult: 'A',
  senior: 'S'
}

const allPhoneFields = [
  'workPhoneNumber',
  'mobilePhoneNumber',
  'homePhoneNumber'
]
let clientPhoneFields
let agentPhoneFields = [...allPhoneFields]
const phoneFieldsRequiredStatus = {
  workPhoneNumber: true,
  mobilePhoneNumber: true,
  homePhoneNumber: true
}
clientPhoneFields = ['workPhoneNumber', 'mobilePhoneNumber', 'homePhoneNumber']
phoneFieldsRequiredStatus.homePhoneNumber = false

export const PHONE_FIELDS = {
  all: allPhoneFields,
  client: clientPhoneFields,
  agent: agentPhoneFields,
  requiredStatus: phoneFieldsRequiredStatus,
  useSplitLayout: clientPhoneFields.length >= 2
}

const renderPhoneMaxLength = () => {
  return process.env.REACT_APP_REQUIRES_COUNTRY_SELECT_FOR_PHONE_INPUTS ===
    'true'
    ? 20
    : 12
}

/**
 * used in actions/updateConfigByLanguage, fetchConfiguration
 */
export const fields = {
  consent: {
    value: false,
    name: 'consent',
    type: CHECKBOX,
    isRequired: true
  },
  arriveCANConsent: {
    value: false,
    name: 'arriveCANConsent',
    type: CHECKBOX,
    isRequired: true
  },
  licensePlateNumber: {
    value: '',
    name: 'licensePlateNumber',
    type: TEXT,
    isRequired: true
  },
  dateOfBirth: {
    value: '',
    name: 'dateOfBirth',
    type: TEXT,
    isRequired: true
  },
  workPhoneNumber: {
    value: '',
    name: 'workPhoneNumber',
    type: TEL,
    isRequired: PHONE_FIELDS.requiredStatus.workPhoneNumber,
    maxLength: renderPhoneMaxLength()
  },
  mobilePhoneNumber: {
    value: '',
    name: 'mobilePhoneNumber',
    type: TEL,
    isRequired: PHONE_FIELDS.requiredStatus.mobilePhoneNumber,
    maxLength: renderPhoneMaxLength()
  },
  homePhoneNumber: {
    value: '',
    name: 'homePhoneNumber',
    type: TEL,
    isRequired: PHONE_FIELDS.requiredStatus.homePhoneNumber,
    maxLength: renderPhoneMaxLength()
  },
  name: {
    value: '',
    name: 'name',
    type: TEXT,
    isRequired: true,
    maxLength: 60
  },
  initials: {
    value: '',
    name: 'initials',
    type: TEXT,
    isRequired: true,
    maxLength: 3
  },
  email: {
    value: '',
    name: 'email',
    type: TEXT,
    isRequired: true,
    classNames: 'email',
    maxLength: 60
  },
  accessibility: {
    value: false,
    name: 'accessibility',
    type: CHECKBOX
  },
  accessibilityTextField: {
    value: '',
    name: 'accessibilityTextField',
    type: TEXTAREA,
    isRequired: false,
    maxLength: IS_NFL_CLIENT ? 10 : 30
  },
  accountSameAsBooking: {
    value: true,
    name: 'accountSameAsBooking',
    type: CHECKBOX
  },
  firstName: {
    value: '',
    name: 'firstName',
    type: TEXT,
    isRequired: true,
    maxLength: 30
  },
  lastName: {
    value: '',
    name: 'lastName',
    type: TEXT,
    isRequired: true,
    maxLength: 30
  },
  language: {
    value: '',
    name: 'language',
    type: SELECT,
    options: [
      { value: '' },
      {
        value: 'en'
      },
      {
        value: 'fr'
      }
    ],
    isRequired: true,
    showLabel: true,
    verticalLabel: true
  },
  type: {
    value: '',
    name: 'type',
    type: SELECT,
    options: [
      {
        value: ''
      },
      {
        value: 'departures'
      },
      {
        value: 'returns'
      }
    ],
    showLabel: true,
    verticalLabel: true,
    isRequired: true
  },
  passengerType: {
    value: '',
    name: 'passengerType',
    type: SELECT,
    options: [
      { value: '' },
      {
        value: 'B'
      },
      {
        value: 'E'
      },
      {
        value: 'A'
      },
      {
        value: 'S'
      },
      {
        value: 'C'
      },
      {
        value: 'I'
      },
      {
        value: 'ST'
      },
      {
        value: 'PET'
      }
    ],
    showLabel: true,
    verticalLabel: true,
    isRequired: true
  },
  title: {
    value: '',
    name: 'title',
    type: SELECT,
    options: [
      {
        value: ''
      },
      {
        value: 'MR'
      },
      {
        value: 'MS'
      },
      {
        value: 'MRS'
      },
      {
        value: 'MX'
      }
    ],
    isRequired: true,
    showLabel: true
  },
  gender: {
    value: '',
    name: 'gender',
    type: SELECT,
    options: [
      {
        value: ''
      },
      {
        value: 'F'
      },
      {
        value: 'M'
      },
      {
        value: 'X'
      }
    ],
    isRequired: true,
    showLabel: true,
    verticalLabel: true
  },
  address: {
    value: '',
    name: 'address',
    type: TEXT,
    isRequired: true,
    maxLength: 40
  },
  city: {
    value: '',
    name: 'city',
    type: TEXT,
    isRequired: true,
    maxLength: 30
  },
  countryCode: {
    value: '',
    name: 'countryCode',
    type: SELECT,
    options: [
      {
        value: '',
        label: 'COUNTRY',
        labels: {
          fr: 'PAYS'
        }
      },
      ...renderCountryOptions()
    ],
    isRequired: true,
    showLabel: true,
    verticalLabel: true,
    shouldClear: 'county'
  },
  county: {
    value: '',
    name: 'county',
    type: SELECT_TEXT_FALLBACK,
    options: generateCountyOptions(),
    isRequired: true,
    showLabel: true,
    verticalLabel: true,
    conditionalValueKey: 'countryCode',
    fallbackOnKey: 'countryCode',
    fallbackWhenNot: ['USA', 'CAN', 'US', 'CA'],
    maxLength: 30
  },
  postalCode: {
    value: '',
    name: 'postalCode',
    type: TEXT,
    isRequired: true,
    convertToCaps: true,
    maxLength: 12
  },
  userId: {
    value: '',
    name: 'userId',
    type: TEXT,
    isRequired: true
  },
  emailAddress: {
    value: '',
    name: 'emailAddress',
    type: TEXT,
    isRequired: true,
    classNames: 'email',
    maxLength: 60
  },
  emailAddressConfirm: {
    value: '',
    name: 'emailAddressConfirm',
    type: TEXT,
    isRequired: true,
    classNames: 'email'
  },
  contactName: {
    value: '',
    name: 'contactName',
    type: TEXT,
    isRequired: true,
    maxLength: 60
  },
  acceptsContact: {
    value: false,
    name: 'acceptsContact',
    type: CHECKBOX
  },
  password: {
    value: '',
    name: 'password',
    type: PASSWORD,
    isRequired: true,
    classNames: 'password',
    minLength: process.env.REACT_APP_MIN_PASSWORD_LENGTH,
    maxLength: process.env.REACT_APP_MAX_PASSWORD_LENGTH
  },
  confirmPassword: {
    value: '',
    name: 'confirmPassword',
    type: PASSWORD,
    isRequired: true,
    classNames: 'password'
  },
  typeOfId: {
    value: '',
    name: 'typeOfId',
    type: SELECT,
    options: typeOfIdOptions,
    isRequired: true,
    showLabel: true,
    verticalLabel: true
  },
  passport: {
    // for CAT route passengers
    value: '',
    name: 'passport',
    type: TEXT,
    isRequired: true,
    minLength: 1,
    maxLength: 100
  },
  passportConfirm: {
    value: '',
    name: 'passportConfirm',
    type: TEXT,
    isRequired: true,
    minLength: 1,
    maxLength: 100,
    equalTo: 'passport', // used as `oneOf` value check
    equalToDisplayValue: 'ID Number'
  },
  passportIssueCountry: {
    value: '',
    name: 'passportIssueCountry',
    type: SELECT,
    options: passportCountryOptions,
    isRequired: true,
    showLabel: true,
    verticalLabel: true
  },
  passportExpiryDate: {
    value: '',
    name: 'passportExpiryDate',
    type: TEXT, // or date?
    isRequired: true
  },
  citizenship: {
    value: '',
    name: 'citizenship',
    type: SELECT,
    options: citizenshipOptions,
    isRequired: true,
    showLabel: true,
    verticalLabel: true
  }
}

export const FETCH_CONFIG_BEGIN = 'configuration/FETCH_CONFIG_BEGIN'
export const FETCH_CONFIG_SUCCESS = 'configuration/FETCH_CONFIG_SUCCESS'
export const FETCH_CONFIG_ERROR = 'configuration/FETCH_CONFIG_ERROR'
export const SET_LABELS = 'configuration/SET_LABELS'
export const SET_FIELDS = 'configuration/SET_FIELDS'
export const DEFAULT_LABEL_TYPE = 'ferry'
export const FETCHING_WEBSITE_STATUS = 'configuration/FETCHING_WEBSITE_STATUS'
export const FETCH_WEBSITE_STATUS_SUCCESS =
  'configuration/FETCH_WEBSITE_STATUS_SUCCESS'
export const FETCH_WEBSITE_STATUS_ERROR =
  'configuration/FETCH_WEBSITE_STATUS_ERROR'
export const FETCHING_ACTIVE_SESSIONS = 'configuration/FETCHING_ACTIVE_SESSIONS'
export const FETCH_ACTIVE_SESSIONS_SUCCESS =
  'configuration/FETCH_ACTIVE_SESSIONS_SUCCESS'
export const FETCH_ACTIVE_SESSIONS_ERROR =
  'configuration/FETCH_ACTIVE_SESSIONS_ERROR'
export const SET_CONTENT = 'configuration/SET_CONTENT'
export const REFRESH_SESSION_SUCCESS = 'configuration/REFRESH_SESSION_SUCCESS'
export const ENABLE_CAN_USE_APP = 'configuration/ENABLE_CAN_USE_APP'
export const DISABLE_CAN_USE_APP = 'configuration/DISABLE_CAN_USE_APP'
export const UPDATING_CONFIG_BY_LANGUAGE =
  'configuration/UPDATING_CONFIG_BY_LANGUAGE'
export const UPDATE_CONFIG_BY_LANGUAGE_SUCCESS =
  'configuration/UPDATE_CONFIG_BY_LANGUAGE_SUCCESS'
export const UPDATE_CONFIG_BY_LANGUAGE_ERROR =
  'configuration/UPDATE_CONFIG_BY_LANGUAGE_ERROR'
