import { createSelector } from 'reselect'
import { selectRoutesContent } from '../configuration/selectors'

const getUserSelectionsState = state => state.userSelections

export const selectUserSelections = createSelector(
  getUserSelectionsState,
  userSelections => userSelections
)

export const selectUserSelectedVehicles = createSelector(
  getUserSelectionsState,
  userSelections => ({
    departure: userSelections?.departureVehicle || null,
    return: userSelections?.returnVehicle || null
  })
)

export const selectModifyBookingNumber = createSelector(
  getUserSelectionsState,
  userSelections => userSelections.modifyBookingNumber
)

export const selectTripType = createSelector(
  getUserSelectionsState,
  userSelections => userSelections.tripType
)

export const selectUserSelectedDepartureCrossing = createSelector(
  getUserSelectionsState,
  userSelections => userSelections.departureCrossing
)

export const selectUserSelectedPaymentFerryId = createSelector(
  getUserSelectionsState,
  selectRoutesContent,
  (userSelections, routes) => {
    const filter = route => route.code === userSelections.departureRoute
    const { ferryId } = routes.find(filter)
    return ferryId
  }
)

export const selectPassengerQuantitiesConstraints = createSelector(
  getUserSelectionsState,
  userSelections => {
    const hasDepartureAdults =
      userSelections.departurePassengers.adult ||
      userSelections.departurePassengers.senior ||
      userSelections.departurePassengers.student
    const hasReturnAdults =
      userSelections.isSamePassengers ||
      userSelections.returnPassengers.adult ||
      userSelections.returnPassengers.senior ||
      userSelections.returnPassengers.student
    const maxTickets = process.env.REACT_APP_COMPONENT_MAXIMUM_PASSENGER_TICKETS

    const departureAdults = userSelections.departurePassengers.adult || 0
    const departureSeniors = userSelections.departurePassengers.senior || 0
    const departureChildren = userSelections.departurePassengers.child || 0
    const departureInfants = userSelections.departurePassengers.infant || 0
    const departureStudents = userSelections.departurePassengers.student || 0
    const returnAdults = userSelections.returnPassengers.adult || 0
    const returnSeniors = userSelections.returnPassengers.senior || 0
    const returnChildren = userSelections.returnPassengers.child || 0
    const returnInfants = userSelections.returnPassengers.infant || 0
    const returnStudents = userSelections.returnPassengers.student || 0

    const departureGuestsTotal =
      departureAdults +
      departureSeniors +
      departureChildren +
      departureInfants +
      departureStudents

    const returnGuestsTotal =
      returnAdults +
      returnSeniors +
      returnChildren +
      returnInfants +
      returnStudents

    return {
      hasRequiredAdults: !!(hasDepartureAdults && hasReturnAdults),
      departureMaxReached: departureGuestsTotal >= maxTickets,
      returnMaxReached: userSelections.isSamePassengers
        ? departureGuestsTotal >= maxTickets
        : returnGuestsTotal >= maxTickets
    }
  }
)

export const selectBookingAccount = createSelector(
  getUserSelectionsState,
  userSelections => userSelections.bookingAccount
)

export const selectBillingInfo = createSelector(
  getUserSelectionsState,
  userSelections => userSelections.billingInfo
)
