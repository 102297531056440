import React from 'react'
import { bool, string, func } from 'prop-types'
import { Field, ErrorMessage } from 'formik'

const renderTextArea = ({
  name,
  value,
  placeholder,
  disabled,
  errorClasses,
  setValue,
  maxLength,
  onBlur
}) => {
  return (
    <Field name={name}>
      {() => {
        const onChange = e => {
          let value = e.target.value
          if (maxLength && e.target.value.length >= maxLength) {
            value = value.substring(0, maxLength)
          }
          setValue(name, value)
        }

        return (
          <TextArea
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            errorClass={errorClasses}
            setValue={onChange}
            onBlur={onBlur}
          />
        )
      }}
    </Field>
  )
}

const TextArea = ({
  name,
  value,
  placeholder,
  disabled,
  errorClass,
  setValue,
  onBlur
}) => {
  let textareaPlaceholder = placeholder

  if (textareaPlaceholder) {
    textareaPlaceholder = textareaPlaceholder.toUpperCase()
  }

  return (
    <label htmlFor={name}>
      <textarea
        value={value}
        name={name}
        disabled={disabled}
        placeholder={textareaPlaceholder}
        className="form-textarea"
        onChange={setValue}
        onBlur={onBlur}
      />
      <span className={errorClass}>
        <ErrorMessage name={name} />
      </span>
    </label>
  )
}

TextArea.propTypes = {
  name: string.isRequired,
  value: string.isRequired,
  placeholder: string.isRequired,
  disabled: bool.isRequired,
  errorClass: string,
  setValue: func.isRequired,
  onBlur: func
}

TextArea.defaultProps = {
  errorClass: ''
}

export { renderTextArea }
export default TextArea
