import React from 'react'
import { string } from 'prop-types'

const TransactionNotCompleted = ({ language }) => {
  return (
    <>
      <h4>The transaction has not been completed</h4>
      <p>
        There was an error processing payment. The transaction has not been
        completed.
      </p>
      <p>What do you want to do?</p>
    </>
  )
}

TransactionNotCompleted.propTypes = {
  language: string.isRequired
}

export default TransactionNotCompleted
