import React from 'react'
import { useSelector } from 'react-redux'
import { func, bool } from 'prop-types'

import { HeaderDefault } from '../Header'
import {
  selectConfigurationData,
  selectHeaderContent,
  selectLabels
} from '../../redux/configuration/selectors'
import { selectActiveModifyBooking } from '../../redux/modifyBooking/selectors'
import { accountClear as clearTravelAgentAccount } from '../../redux/travelAgent/actions'
import { selectTravelAgentAccount } from '../../redux/travelAgent/selectors'
import { clearCustomerAccount } from '../../redux/user/actions'
import { selectCustomerAccount } from '../../redux/user/selectors'

const Header = ({
  handleRouteChange,
  openModal,
  showExtendedHeader,
  handleToggleLanguage
}) => {
  const labels = useSelector(selectLabels)
  const configurationData = useSelector(selectConfigurationData)
  const customerAccount = useSelector(selectCustomerAccount)
  const activeModifyBooking = useSelector(selectActiveModifyBooking)
  const customerIsLoggedIn = !!customerAccount
  const travelAgentAccount = useSelector(selectTravelAgentAccount)
  const travelAgentIsLoggedIn = !!travelAgentAccount

  let brandLogo = null
  let brandCaption = labels.brandLogo

  const headerContent = useSelector(selectHeaderContent)

  if (headerContent) {
    brandLogo = headerContent?.brandLogos?.[0].path
    brandCaption = headerContent?.brandLogos?.[0]?.caption
  }

  const brandMarketingSite = headerContent?.brandLogos?.[0]?.link
  const contactLinkData = headerContent?.contactLink

  return (
    <div className="header header--default">
      <HeaderDefault
        labels={labels}
        brandLogo={brandLogo}
        brandCaption={brandCaption}
        handleRouteChange={handleRouteChange}
        openModal={openModal}
        clientContactPhoneNumber={
          configurationData?.ferry?.options?.clientContactPhoneNumber ||
          contactLinkData?.phoneNumber
        }
        brandMarketingSite={brandMarketingSite}
        showExtendedHeader={showExtendedHeader}
        contactLinkData={contactLinkData}
        handleToggleLanguage={handleToggleLanguage}
        travelAgentIsLoggedIn={travelAgentIsLoggedIn}
        customerIsLoggedIn={customerIsLoggedIn}
        customerAccount={customerAccount}
        travelAgentAccount={travelAgentAccount}
        clearCustomerAccount={clearCustomerAccount}
        clearTravelAgentAccount={clearTravelAgentAccount}
        activeModifyBooking={activeModifyBooking}
      />
    </div>
  )
}

Header.propTypes = {
  handleRouteChange: func.isRequired,
  openModal: func.isRequired,
  showExtendedHeader: bool,
  handleToggleLanguage: func.isRequired
}

export default Header
