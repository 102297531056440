import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { oneOfType, arrayOf, bool, func, shape, string } from 'prop-types'
// api
import { asyncModifyBooking } from '../../api/api'
// components
import BrandLoader from '../BrandLoader/BrandLoader'
import BookingsTable from './BookingsTable'
import BookingDetailsModal from '../BookingDetailsModal/BookingDetailsModal'
import CancelBookingModal from './CancelBookingModal'
import DateSelection from './DateSelection'
import Modal from '../Modal'
import SubmitPaymentModal from '../SubmitPaymentModal/SubmitPaymentModal'
import TransactionNotCompleted from '../TransactionNotCompleted/TransactionNotCompleted'
// configuration
import appRoutes from '../../configuration/appRoutes'
import dateFormats from '../../configuration/dateFormats'
// context
import modalIds from '../../context/configuration'
// redux
import {
  setBookingError,
  setBookingSuccess,
  startBookingRequest,
  clearTicketNumbers,
  clearReceipts
} from '../../redux/booking/actions'
import { selectPassengerInformationContent } from '../../redux/configuration/selectors'
import {
  getCustomerBookings,
  showNonRefundedModal
} from '../../redux/userBookings/actions'
import { selectNonRefundedModalVisible } from '../../redux/userBookings/selectors'
import { setActiveModificationBooking } from '../../redux/modifyBooking/actions'
import * as UserSelectionActions from '../../redux/userSelections/actions'
import { setPaymentFerryId } from '../../redux/paymentConfirmation/actions'
import { setCurrency, startSessionTimer } from '../../redux/session/actions'
// utilities
import formatExistingBookingToRequestBody from '../../utilities/data/formatExistingBookingToRequestBody'
import { renderContentValues } from '../../redux/configuration/utilities'
// styles
import '../../styles/scss/components/user-bookings/user-bookings.scss'
// siblings

/**
 * User Booking History in Account Page
 */
const UserBookings = ({
  handleRouteChange,
  openModal,
  closeModal,
  modalIsOpen,
  bookings,
  loadingBookings,
  loadBookingsError,
  getBookingsAction,
  tableRecordsSelector,
  labels,
  attemptingSetBooking,
  ferryRoutes,
  lang,
  currencyCode
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [bookingModalIsOpen, setBookingModalIsOpen] = useState(false)
  const [cancelBookingModalIsOpen, setCancelBookingModalIsOpen] = useState(
    false
  )
  const [activeBookingDetails, setActiveBookingDetails] = useState(null)
  // at the time of writing this comment the two options here for errorKeyToShow are "call" and "error"
  const [customErrorModalTextKey, setCustomErrorModalTextKey] = useState(
    location?.state?.showIncompleteBookingModal
      ? location?.state?.errorKeyToShow
        ? location?.state?.errorKeyToShow
        : 'error'
      : null
  )
  const [initialFetchBookingsCalled, setInitialFetchBookingsCalled] = useState(
    false
  )
  const nonRefundedModalVisible = useSelector(selectNonRefundedModalVisible)
  const passengerInformationContent = useSelector(
    selectPassengerInformationContent
  )
  const filteredBookings = useSelector(tableRecordsSelector)
  const warningBanners = passengerInformationContent.value?.warningBanner

  useEffect(() => {
    if (!initialFetchBookingsCalled) {
      setInitialFetchBookingsCalled(true)
      dispatch(getBookingsAction())
    }
  }, [initialFetchBookingsCalled, dispatch, getBookingsAction])

  const submitNewDates = (startDate, endDate) => {
    dispatch(
      getBookingsAction(
        startDate.format(dateFormats.default),
        endDate.format(dateFormats.default)
      )
    )
  }

  const confirmInlinePayNow = async booking => {
    openModal({ type: modalIds.loading })
    if (booking?.bookingNumber) {
      const modificationId = booking.bookingNumber
      dispatch(startBookingRequest())
      const params = formatExistingBookingToRequestBody(
        booking,
        ferryRoutes,
        lang
      )
      dispatch(startSessionTimer()) // Refresh session on all api requests
      const modifyResult = await asyncModifyBooking({
        params,
        modificationId
      })

      if (modifyResult.success) {
        // we'll need the ferryId set for booking confirmation or summary (on moneris errors)
        dispatch(setPaymentFerryId(params?.ferryId))

        // TODO: deprecate
        dispatch(setActiveModificationBooking(modifyResult.data))
        dispatch(UserSelectionActions.setModifyBookingNumber(modificationId))
        dispatch(
          setBookingSuccess({
            ...modifyResult.data,
            isModification: true,
            modificationId,
            commitChanges: true
          })
        )
        // Going to payment will redirect to moneris or use iframe checkout
        dispatch(setCurrency(currencyCode))
        dispatch(clearTicketNumbers())
        dispatch(clearReceipts())

        closeModal({ type: modalIds.loading })
        handleRouteChange(appRoutes.payment.pathname, {
          cancelTransactionRouteTarget: appRoutes.account.pathname
        })
      } else {
        dispatch(setBookingError())
      }
    } else {
      closeModal({ type: modalIds.loading })
    }
  }

  return (
    <div className="padded-container wider-content user-bookings-container">
      <div className="user-bookings-header">
        <div className="user-bookings-header-left">
          <h5 className="theme-font-header">{labels.bookings}</h5>
        </div>
        <DateSelection submit={submitNewDates} />
      </div>
      <div className="user-bookings-body">
        {loadingBookings ? (
          <BrandLoader />
        ) : (
          <>
            {bookings && (
              <BookingsTable
                bookings={filteredBookings}
                handleRouteChange={handleRouteChange}
                labels={labels}
                setActiveBookingDetails={setActiveBookingDetails}
                setCancelBookingModalIsOpen={setCancelBookingModalIsOpen}
                activeBookingDetails={activeBookingDetails}
                setBookingModalIsOpen={setBookingModalIsOpen}
                error={loadBookingsError}
                isLoading={loadingBookings}
                openCustomErrorModal={errorKey => {
                  setCustomErrorModalTextKey(errorKey)
                  openModal({ type: modalIds.userBookingCustomErrorModal })
                }}
                setBookingForPayment={confirmInlinePayNow}
              />
            )}
          </>
        )}
      </div>
      {customErrorModalTextKey &&
        modalIsOpen[modalIds.userBookingCustomErrorModal] && (
          <Modal
            isOpen={Boolean(
              customErrorModalTextKey &&
                modalIsOpen[modalIds.userBookingCustomErrorModal]
            )}
            onRequestClose={() => {
              closeModal({ type: modalIds.userBookingCustomErrorModal })
              setCustomErrorModalTextKey(null)
            }}
            className="modal--sm"
          >
            <div>
              <div className={''} style={{ padding: '32px' }}>
                <div
                  className={''}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <button
                    className="btn"
                    onClick={() => {
                      closeModal({
                        type: modalIds.userBookingCustomErrorModal
                      })
                      setCustomErrorModalTextKey(null)
                    }}
                    style={{ alignSelf: 'flex-end' }}
                  >
                    {labels.close} X
                  </button>
                </div>

                {customErrorModalTextKey === 'paymentError' ? (
                  <TransactionNotCompleted language={lang} />
                ) : (
                  <p style={{ lineHeight: 1.6 }}>
                    {labels[customErrorModalTextKey]}
                  </p>
                )}
              </div>
            </div>
          </Modal>
        )}
      {activeBookingDetails && bookingModalIsOpen && (
        <BookingDetailsModal
          isOpen={bookingModalIsOpen}
          onRequestClose={() => {
            setBookingModalIsOpen(false)
            setActiveBookingDetails(null)
          }}
          bookingDetails={activeBookingDetails}
        />
      )}
      {cancelBookingModalIsOpen && activeBookingDetails && (
        <CancelBookingModal
          onRequestClose={closingDueToNonRefundedError => {
            setCancelBookingModalIsOpen(false)
            setActiveBookingDetails(null)

            if (closingDueToNonRefundedError) {
              dispatch(getCustomerBookings())
              dispatch(showNonRefundedModal(true))
            }
          }}
          isOpen={cancelBookingModalIsOpen}
          booking={activeBookingDetails}
        />
      )}
      {Object.keys(modalIsOpen).includes('submit-payment') ? (
        <SubmitPaymentModal
          onSubmit={confirmInlinePayNow}
          onCloseButtonClick={() => {
            closeModal({ type: 'submit-payment' })
            setActiveBookingDetails(null)
          }}
          isOpen={Object.keys(modalIsOpen).includes('submit-payment')}
          labels={labels}
          amountOwing={activeBookingDetails?.reservationTotals?.total}
          activeModifyBooking={false}
          disabled={attemptingSetBooking}
        />
      ) : null}
      {nonRefundedModalVisible && (
        <Modal
          isOpen={nonRefundedModalVisible}
          onRequestClose={() => {
            dispatch(showNonRefundedModal(false))
          }}
          className="modal--sm"
        >
          <div style={{ padding: '32px' }}>
            <div
              className={''}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <button
                className="btn"
                onClick={() => {
                  dispatch(showNonRefundedModal(false))
                }}
                style={{ alignSelf: 'flex-end' }}
              >
                {labels.close} X
              </button>
            </div>
            <div style={{ lineHeight: 1.6 }}>
              {renderContentValues(warningBanners.forcedCancellations.value)}
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

UserBookings.propTypes = {
  handleRouteChange: func.isRequired,
  openModal: func.isRequired,
  closeModal: func.isRequired,
  modalIsOpen: shape({}).isRequired,
  bookings: arrayOf(shape({})).isRequired,
  loadingBookings: bool.isRequired,
  loadBookingsError: oneOfType([string, bool]).isRequired,
  getBookingsAction: func.isRequired,
  tableRecordsSelector: func.isRequired,
  labels: shape({}).isRequired,
  agentBookingsGetError: bool.isRequired,
  attemptingSetBooking: bool.isRequired,
  ferryRoutes: arrayOf(shape({})),
  lang: string.isRequired,
  clientId: string.isRequired,
  currencyCode: string.isRequired
}

export default UserBookings
