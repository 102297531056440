import moment from 'moment'
import {
  getInitialValuesFromFields,
  filterFieldsByName
} from '../../redux/configuration/utilities'
import { PHONE_FIELDS } from '../../redux/configuration/constants'
import yn from 'yn'

export const getFormFieldsAndLayout = () => {
  const allFieldNames = [
    'firstName',
    'lastName',
    'language',
    'title',
    'dateOfBirth',
    'passengerType',
    'address',
    'city',
    'countryCode',
    'county',
    'postalCode',
    'mobilePhoneNumber',
    'workPhoneNumber',
    'homePhoneNumber',
    'emailAddress',
    'acceptsContact',
    'password',
    'confirmPassword'
  ]

  const contactRows = PHONE_FIELDS.useSplitLayout
    ? [[...PHONE_FIELDS.client], ['emailAddress']]
    : [[...PHONE_FIELDS.client, 'emailAddress']]

  const allFieldLayout = [
    ['firstName', 'lastName'],
    ['title', 'dateOfBirth'],
    ['language', 'passengerType'],
    ['address', 'city'],
    ['countryCode', 'county', 'postalCode'],
    ...contactRows,
    ['acceptsContact']
  ]

  const agentFieldNames = [
    'name',
    'initials',
    'departmentCode',
    'workPhoneNumber',
    'homePhoneNumber',
    'mobilePhoneNumber',
    'email',
    'password',
    'confirmPassword'
  ]

  const agentFormLayout = [
    ['name', 'initials', 'departmentCode'],
    ['workPhoneNumber', 'homePhoneNumber', 'mobilePhoneNumber'],
    ['email']
  ]

  const keysToRemove = PHONE_FIELDS.all.reduce((acc, phoneField) => {
    if (!PHONE_FIELDS.client.includes(phoneField)) {
      acc[phoneField] = true
    }
    return acc
  }, {})

  const filterKeys = arr => arr.filter(x => !keysToRemove[x])

  return {
    fieldNames: filterKeys(allFieldNames),
    formLayout: allFieldLayout.map(row => filterKeys(row)),
    agentFieldNames,
    agentFormLayout
  }
}

export const mapCustomerValuesToInputNames = (
  customerAccount = false,
  fields = {}
) => {
  const { fieldNames } = getFormFieldsAndLayout()
  const defaultEmptyFormDetails = filterFieldsByName(fields, fieldNames)
  const defaultEmptyFormValues = getInitialValuesFromFields(
    defaultEmptyFormDetails
  )
  let result = { ...defaultEmptyFormValues }

  if (customerAccount) {
    Object.keys(defaultEmptyFormValues).forEach(fieldName => {
      if (customerAccount[fieldName]) {
        result[fieldName] = customerAccount[fieldName]
      }
      // mapping here
      if (customerAccount.email) result.emailAddress = customerAccount.email
      if (customerAccount.postCode) result.postalCode = customerAccount.postCode
      if (customerAccount.dateOfBirth)
        result.dateOfBirth = moment(customerAccount.dateOfBirth).format(
          'YYYY-MM-DD'
        )
      if (typeof customerAccount.title !== 'undefined')
        result.title = customerAccount.title
      result.acceptsContact = yn(customerAccount.acceptsContact)
    })
  }

  return result
}
