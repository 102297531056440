import React from 'react'
import { useSelector } from 'react-redux'
import { oneOf, func, string } from 'prop-types'
import xIcon from '../../assets/icons/x.svg'
import { selectLabels } from '../../redux/configuration/selectors'

const Notification = ({ type, message, onDismiss, size, children }) => {
  const labels = useSelector(selectLabels)

  return (
    <span
      className={
        size === 'lg'
          ? `padded-container notification-row notification-row-${size}`
          : `row notification-row notification-row-${size}`
      }
    >
      <div
        className={
          size === 'lg'
            ? `row-group-container-notification theme-font-header notificationtype-${type} theme-font-header`
            : `row-group-container-notification theme-font-header notificationtype-${type} theme-font-five`
        }
      >
        {message}
        {children}
        <button className="dismiss-notification">
          <img
            src={xIcon}
            alt=""
            onClick={() => {
              onDismiss()
            }}
            aria-label={labels.close}
          />
        </button>
      </div>
    </span>
  )
}

Notification.propTypes = {
  type: oneOf(['error', 'success']),
  size: oneOf(['sm', 'lg']),
  message: string.isRequired,
  onDismiss: func.isRequired
}

Notification.defaultProps = {
  type: 'error',
  size: 'sm',
  message: string.isRequired,
  onDismiss: func.isRequired
}

export default Notification
