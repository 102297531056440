import React from 'react'
import { bool, oneOfType, func, shape, string } from 'prop-types'
import { Formik, Form } from 'formik'
import RowGroup from '../RowGroup/RowGroup'
import { renderFormFields } from '../../redux/configuration/utilities'
import {
  agentFormLayout,
  formLayout,
  formVariants
} from '../LoginModal/constants'

const LoginForm = ({
  loginModalType, // see formVariants
  closeModal,
  labels,
  fields,
  disabled,
  loginError,
  onValidSubmit,
  variant,
  loginFormDetails,
  initialValues,
  validationSchema,
  noRegisterVariant,
  formHelpLinks,
  registerLink,
  onRegisterLinkClick
}) => (
  <div
    className={
      noRegisterVariant ? 'modal-form-content' : 'modal-form-content-mt'
    }
  >
    <div className="modal-form-inputs">
      <Formik
        initialValues={initialValues}
        onSubmit={onValidSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <RowGroup
              formRowClasses="modal-form-row"
              errorClasses="modal-form-error"
              render={({ formRowClasses, errorClasses }) => (
                <div className="modal-form-body">
                  {renderFormFields({
                    formLayout:
                      loginModalType === formVariants.TRAVEL_AGENT_LOGIN
                        ? agentFormLayout
                        : formLayout,
                    fieldDetails: loginFormDetails,
                    values,
                    setFieldValue,
                    disabled,
                    formRowClasses,
                    errorClasses,
                    formHelpLinks
                  })}
                  <span className={`${formRowClasses} modal-form-submit-row`}>
                    <button
                      type="submit"
                      className="btn btn-primary u-nowrap"
                      disabled={disabled}
                    >
                      {labels.login}
                    </button>
                    {loginError && (
                      <div className="modal-form-submit-message notificationtype-error">
                        {loginError}
                      </div>
                    )}
                    {!noRegisterVariant && (
                      <a
                        href={registerLink || '/'}
                        target={registerLink ? '_blank' : '_self'}
                        onClick={e => {
                          if (onRegisterLinkClick) {
                            e.preventDefault()
                            onRegisterLinkClick()
                          }
                        }}
                        title={labels.registerNewAccount}
                        className="form-row-link theme-title-variant"
                        rel="noreferrer"
                      >
                        {labels.registerNewAccount}
                      </a>
                    )}
                  </span>
                </div>
              )}
            />
          </Form>
        )}
      </Formik>
    </div>
  </div>
)

LoginForm.propTypes = {
  loginModalType: string.isRequired,
  closeModal: func.isRequired,
  labels: shape().isRequired,
  fields: shape().isRequired,
  disabled: bool.isRequired,
  loginError: string,
  onValidSubmit: func.isRequired,
  variant: oneOfType([bool, string]),
  loginFormDetails: shape().isRequired,
  initialValues: shape().isRequired,
  validationSchema: shape().isRequired,
  noRegisterVariant: bool,
  formHelpLinks: shape(),
  registerLink: string,
  onRegisterLinkClick: func
}

export default LoginForm
