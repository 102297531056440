import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { selectLabels } from '../../redux/configuration/selectors'
import { formatPriceByLanguage } from '../../redux/configuration/utilities'
import { selectBookingState } from '../../redux/booking/selectors'
import BookingConfirmationTable from '../../components/BookingConfirmation/BookingConfirmationTable'
import BookingConfirmationList from '../../components/BookingConfirmationList/BookingConfirmationList'
import { ENABLE_CHASE_CHECKOUT, appRoutes } from '../../configuration/constants'
import dateFormats from '../../configuration/dateFormats'
import queryString from 'query-string'
import BrandLoader from '../../components/BrandLoader/BrandLoader'

import '../../styles/scss/components/booking-confirmation/booking-confirmation.scss'
import { clearActiveModifyBooking } from '../../redux/modifyBooking/actions'
import { selectLanguage } from '../../redux/session/selectors'
import { getChaseTransactionReceipt } from '../../redux/booking/actions'
import { findReservation } from '../../api/api'
import { selectVehicleViewContent } from '../../redux/configuration/selectors'
import { selectAccommodationsContent } from '../../redux/configuration/selectors'
import { normalizeLabelWithCount } from '../../configuration/utilities'
import { selectLatestBookingNumber } from '../../redux/session/selectors'

const BookingSuccessConfirmation = ({ handleRouteChange }) => {
  const labels = useSelector(selectLabels)
  const lang = useSelector(selectLanguage)
  const bookingData = useSelector(selectBookingState)
  const vehicleContent = useSelector(selectVehicleViewContent)
  const accommodationsContent = useSelector(selectAccommodationsContent)
  const latestBooking = useSelector(selectLatestBookingNumber)

  const [loadingReceipts, setLoadingReceipt] = useState(false)
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [bookingDetails, setBookingDetails] = useState(null)
  const [showSuccessTable, setShowSuccessTable] = useState(false)
  const [summary, setSummary] = useState(null)
  const [departureAccommodations, setDepartureAccommodations] = useState([])
  const [returnAccommodations, setReturnAccommodations] = useState([])

  const dispatch = useDispatch()
  const data = bookingData?.data
  const params = queryString.parse(window.location.search)
  const uid = params?.uid
  const receipt =
    uid && bookingData?.receipts?.[uid] ? bookingData?.receipts?.[uid] : null
  const success =
    params?.status !== 'error' &&
    (data?.refundApplied ||
      showSuccessTable ||
      Boolean(params?.status === 'success' || receipt?.txRefNum))

  let bookingNumber = '-'
  if (receipt?.orderId.indexOf('-') > -1) {
    bookingNumber = receipt?.orderId.split('-')[1]
  } else if (data?.bookingNumber) {
    bookingNumber = data?.bookingNumber
  } else if (data?.orderId.indexOf('-') > -1) {
    bookingNumber = data?.orderId.split('-')[1]
  } else if (latestBooking) {
    bookingNumber = latestBooking
  } else {
    bookingNumber = '-'
  }

  const formatForLang =
    lang === 'fr'
      ? dateFormats.summaryTableLongDisplayFrench
      : dateFormats.summaryTableLongDisplay

  const tableData = ENABLE_CHASE_CHECKOUT
    ? [
        {
          label: labels.bookingNumber,
          value: bookingNumber
        },
        {
          label: labels.transactionStatus,
          value: success
            ? labels['paymentStatusPaid']
            : labels['paymentStatusUnpaid']
        }
      ]
    : [
        {
          label: labels.bookingNumber,
          value: bookingData?.data?.bookingNumber
        },
        {
          label: labels.transactionStatus,
          value: labels[data?.paymentStatus]
        },
        {
          label: labels.bookingStatus,
          value: labels[data?.bookingStatus]
        }
      ]

  if (data?.refundApplied) {
    tableData.push({
      label: labels.totalRefund,
      value: formatPriceByLanguage(data?.refundAmount, lang)
    })
  }

  const fireAnalytics = analyticsData => {
    try {
      window.dataLayer = window?.dataLayer || []
      window.dataLayer.push({
        event: 'analytics-confirmation-1',
        details: { ...analyticsData }
      })
    } catch (err) {
      console.log('Analytics error: ' + err)
    }
  }
  /*
  const formatReceiptForReporting = receipt => {
    return {
      ps_store_id: '', // old moneris field
      hpp_key: '', // old moneris field
      charge_total: receipt.amount,
      cust_id: '',
      order_id: bookingNumber,
      lang: languages?.[lang]?.monerisValue || 'en-ca', // keeping as it isnt tied to moneris, specifically
      gst: '0.00', // we don't add this
      note: labels?.booking || 'Booking',
      email: receipt.merchantData1 || '',
      bill_name: receipt.billingName,
      bill_address_one: receipt.address.addressLine[0],
      bill_city: receipt.address.cityName,
      bill_state_or_province: receipt.address.stateProv,
      bill_postal_code: receipt.address.postalCode,
      bill_country: receipt.address.countryName,
      booking_source: receipt.bookingSource
    }
  }
  */

  useEffect(() => {
    if (uid && !receipt && !loadingReceipts) {
      setLoadingReceipt(true)

      dispatch(
        getChaseTransactionReceipt({
          uid: uid
        })
      )
    } else if (receipt && bookingData && !bookingDetails && !loadingDetails) {
      setLoadingDetails(true)
      async function getBookingDetails({
        workPhoneNumber,
        email,
        reservationId
      }) {
        const reservationData = await findReservation({
          workPhoneNumber,
          email,
          reservationId
        }).catch(err => {
          console.log(`Could not get reservation data`, err)
        })
        const bookingData =
          reservationData?.data?.['hydra:member']?.[0]?.bookingReservation

        if (!bookingData) {
          if (receipt) {
            fireAnalytics({
              ...receipt,
              booking_source: !email ? 'Telephone' : 'Online'
            })
          }
          setLoadingDetails(false)
          setShowSuccessTable(true)
          return
        } else {
          fireAnalytics({
            ...bookingData,
            booking_source: 'Online'
          })
        }

        const summaryDepartureDate = moment(
          new Date(`${bookingData.departureDate}T00:00:00`)
        ).format(formatForLang)
        const summaryArrivalDate = bookingData?.arrivalDate
          ? moment(new Date(`${bookingData.arrivalDate}T00:00:00`)).format(
              formatForLang
            )
          : null
        const ferrySummary = {
          icon: 'routes',
          label: `${labels[bookingData.departures[0].routeCode]} | ${
            bookingData?.departures?.length > 1 ? labels.return : labels.oneWay
          }`,
          date: `${summaryDepartureDate}${
            summaryArrivalDate ? ` - ${summaryArrivalDate}` : ''
          }`,
          grandTotal: formatPriceByLanguage(
            Number(bookingData?.reservationTotals?.totalPriceToPay),
            lang
          )
        }

        const departureAccommodationsData = []
        const returnAccommodationsData = []

        const ferryData =
          bookingData?.departures?.map((departure, key) => {
            const subheader =
              key === 0
                ? labels.departure || 'label:departure'
                : labels.return || 'label:return'
            const departureDate = moment(
              new Date(`${departure.departureDate}T00:00:00`)
            ).format(formatForLang)
            const arrivalDate = moment(
              new Date(`${departure.arrivalDate}T00:00:00`)
            ).format(formatForLang)
            const departureTimes = `${labels.departure} ${departure.departureTime} → ${labels.arrival} ${departure.arrivalTime}`
            const vehicles =
              departure.vehicles?.booked?.length > 0
                ? departure.vehicles?.booked?.map(vehicle => {
                    const vehicleIconData = vehicleContent?.vehicleGrouping?.[0]?.icons?.find(
                      vehicleType =>
                        vehicleType.resourceCode === vehicle.resourceCode
                    )
                    return vehicleIconData?.default || null
                  })
                : []
            const passengersLookup = {}

            const passengerTotal = departure.passengerPriceLines.reduce(
              (total, passenger) => (total += +passenger.price),
              0
            )
            const passengerFees = departure.passengerAddonPriceLines.reduce(
              (total, addon) => (total += +addon.price),
              0
            )
            const vehicleTotal = departure.vehiclePriceLines.reduce(
              (total, vehicle) => (total += +vehicle.price),
              0
            )
            const vehicleFees = departure.vehicleAddonPriceLines.reduce(
              (total, addon) => (total += +addon.price),
              0
            )
            const ammendmentFees = departure.amendmentFeePriceLines.reduce(
              (total, fee) => (total += +fee.price),
              0
            )
            const addons = departure.addOnPriceLines.reduce(
              (total, addon) => (total += +addon.price),
              0
            )

            const departureMinusAccommsTotal =
              passengerTotal +
              passengerFees +
              vehicleTotal +
              vehicleFees +
              ammendmentFees +
              addons

            departure.passengers.booked.forEach(passenger => {
              const curPassengerCount =
                passengersLookup?.[passenger.passengerType]
              if (curPassengerCount) {
                passengersLookup[passenger.passengerType] += 1
              } else {
                passengersLookup[passenger.passengerType] = 1
              }
            })
            const guestList = Object.entries(passengersLookup)
              .map(([key, count]) => {
                let pluralKey
                switch (key) {
                  case 'A':
                    pluralKey = 'adults'
                    break
                  case 'S':
                    pluralKey = 'seniors'
                    break
                  case 'C':
                    pluralKey = 'children'
                    break
                  case 'ST':
                    pluralKey = 'students'
                    break
                  case 'I':
                    pluralKey = 'infants'
                    break
                  case 'PET':
                    pluralKey = 'pets'
                    break
                  default:
                    pluralKey = ''
                    break
                }

                const guestLabel = normalizeLabelWithCount({
                  labelKey: `passengerType${key}`,
                  pluralLabelKey: pluralKey,
                  lang,
                  count,
                  labels,
                  showCountWhenOne: true
                })

                return `${guestLabel}`
              })
              .join(', ')

            departure.accommodationPriceLines.forEach(accomm => {
              const cabinLabel = normalizeLabelWithCount({
                labelKey: 'selectedServicesLineItem',
                pluralLabelKey: 'selectedServicesLineItemPlural',
                lang,
                number: Number(accomm.number),
                labels,
                showCountWhenOne: true
              })
              const accommData = {
                id: accomm.resourceCode,
                icon: 'accommodations',
                label: `${
                  accommodationsContent?.accommodations?.[accomm.resourceCode]
                    .caption
                } | ${cabinLabel}`,
                date: `${departureDate} - ${arrivalDate}`,
                price: formatPriceByLanguage(
                  +accomm.price + +accomm.tax + +accomm.tax2,
                  lang
                )
              }
              if (key === 0) {
                departureAccommodationsData.push(accommData)
              } else {
                returnAccommodationsData.push(accommData)
              }
            })

            return {
              subheader: subheader,
              value: departureDate,
              date: departureTimes,
              vehicles: vehicles,
              passengers: guestList,
              price: formatPriceByLanguage(+departureMinusAccommsTotal, lang)
            }
          }) || []

        setDepartureAccommodations(departureAccommodationsData)
        setReturnAccommodations(returnAccommodationsData)
        setSummary(ferrySummary)
        setBookingDetails(ferryData)
        setLoadingDetails(false)
        setShowSuccessTable(false)
      }
      if (receipt.merchantData1 || receipt.merchantData3) {
        getBookingDetails({
          email: receipt.merchantData1 || '',
          reservationId: bookingNumber,
          workPhoneNumber: receipt.merchantData3 || ''
        })
      } else {
        setShowSuccessTable(true)
        setLoadingDetails(false)
        if (receipt) {
          fireAnalytics({
            ...receipt,
            booking_source: !receipt.merchantData1 ? 'Telephone' : 'Online'
          })
        }
      }
      setLoadingReceipt(false)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, loadingReceipts, bookingData])

  const renderCTAs = () => (
    <div className="booking-confirmation__submits">
      <button
        className="btn btn-primary large-primary-btn"
        onClick={() => {
          dispatch(clearActiveModifyBooking())
          handleRouteChange(appRoutes.ferryRoutes.pathname)
        }}
      >
        {labels.newBookingBtn || labels.continueBtn}
      </button>
      <button
        className="btn btn-primary large-primary-btn"
        onClick={() => {
          dispatch(clearActiveModifyBooking())
          handleRouteChange(appRoutes.account.pathname)
        }}
      >
        {labels.myAccountBtn}
      </button>
    </div>
  )

  if (showSuccessTable || data?.refundApplied || !success) {
    return (
      <div className="booking-confirmation">
        <div className="u-container">
          <div className="booking-confirmation-content">
            <div className="booking-confirmation-container">
              <h2 className="theme-font-header booking-success-header">
                {labels.bookingConfirmtion}
              </h2>
              <BookingConfirmationTable
                headerLabel={
                  success
                    ? labels.transactionApproved
                    : labels.transactionCancelled
                }
                tableData={tableData}
                centerHeader={true}
              />
            </div>
            {renderCTAs()}
          </div>
        </div>
      </div>
    )
  }

  if (params?.status !== 'error' && !bookingDetails) {
    return (
      <div className="booking-confirmation">
        <div className="u-container">
          <div className="booking-confirmation-content">
            <div className="booking-confirmation-container">
              <h2 className="theme-font-header booking-success-header">
                {labels.bookingConfirmtion}
              </h2>
              <BrandLoader />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="booking-confirmation">
      <div className="u-container">
        <div className="booking-confirmation-content">
          <div className="booking-confirmation-container">
            <h2 className="theme-font-header booking-success-header">
              {labels.bookingConfirmtion}
            </h2>
            <h3 className="theme-font-subheader booking-success-subheader">
              {success
                ? labels.transactionApproved
                : labels.transactionCancelled}
            </h3>

            <div className="booking-success-number">
              <span className="booking-success-number__label">
                {labels.yourBookingNumber || 'label:yourBookingNumber'}:
              </span>
              <span className="booking-success-number__value">
                {bookingNumber}
              </span>
            </div>
            {labels.bookingSuccessDisclaimer ? (
              <div
                className="booking-success-disclaimer"
                dangerouslySetInnerHTML={{
                  __html: labels.bookingSuccessDisclaimer
                }}
              />
            ) : (
              <div className="booking-success-disclaimer">
                label:bookingSuccessDisclaimer (HTML Enabled)
              </div>
            )}

            <h3 className="booking-success-summary-header">
              {labels.bookingSummary || 'label:bookingSummary'}
            </h3>
            <div className="booking-success-breakdown">
              <BookingConfirmationList
                listData={bookingDetails || []}
                summary={summary}
                centerHeader
                headerLabel={labels.ferryTravel || 'label:ferryTravel'}
                labels={labels}
                id="summary"
              />
              {departureAccommodations?.length > 0 &&
                departureAccommodations.map((accommodation, idx) => (
                  <BookingConfirmationList
                    listData={[]}
                    summary={accommodation}
                    centerHeader
                    headerLabel={
                      idx === 0
                        ? labels.accommodations || 'label:accommodations'
                        : null
                    }
                    labels={labels}
                    id={`departure-accomm-${idx}`}
                  />
                ))}
              {returnAccommodations?.length > 0 &&
                returnAccommodations.map((accommodation, idx) => (
                  <BookingConfirmationList
                    listData={[]}
                    summary={accommodation}
                    headerLabel={null}
                    labels={labels}
                    id={`return-accomm-${idx}`}
                  />
                ))}
              {summary?.grandTotal && (
                <div className="booking-success-grand-total">
                  <span className="booking-success-grand-total__label">
                    {labels.grandTotal || 'label:grandTotal'}:
                  </span>
                  <span className="booking-success-grand-total__value">
                    {summary.grandTotal}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {renderCTAs()}
      </div>
    </div>
  )
}

export default BookingSuccessConfirmation
