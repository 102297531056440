import React, { useRef } from 'react'
import PropTypes, { shape, string, instanceOf, func } from 'prop-types'
import Moment from 'moment'
import CrossingDayColumn from '../CrossingDayColumn'
import ConfigConsumer from '../../../redux/configuration/context'
import Slider from 'react-slick'
import SvgIcon from '../../SvgIcon/SvgIcon'

if (process.env.REACT_APP_CROSSING_CARD_LAYOUT === 'ship_name') {
  require('./ship-name-layout-styles.scss')
} else {
  require('./default-styles.scss')
}

const PrevArrow = ({
  onClick,
  className,
  requiresPlaceholders,
  currentSlide,
  label
}) => {
  const disabled = !onClick || (requiresPlaceholders && currentSlide <= 1)

  return (
    <button
      className={`btn ${className} custom ${disabled ? 'slick-disabled' : ''}`}
      onClick={onClick}
      disabled={disabled}
      aria-label={label || 'Previous'}
    >
      <ConfigConsumer>
        {({ themeVariables }) => (
          <SvgIcon type="prev-slide" fill={themeVariables.themeIconColor} />
        )}
      </ConfigConsumer>
    </button>
  )
}

const NextArrow = ({ onClick, className, label }) => {
  return (
    <button
      className={`btn ${className} custom`}
      onClick={onClick}
      aria-label={label || 'Next'}
    >
      <ConfigConsumer>
        {({ themeVariables }) => (
          <SvgIcon type="next-slide" fill={themeVariables.themeIconColor} />
        )}
      </ConfigConsumer>
    </button>
  )
}

const CrossingSlider = ({
  crossings,
  departureDate,
  selectCrossing,
  currentSelection,
  activeVehicleResourceCode,
  activeVehicle,
  labels,
  totalPassengers,
  cabinResourceCodes
}) => {
  const requiresPlaceholders = Object.keys(crossings).length < 3
  const sliderRef = useRef(null)

  const crossingDates = Object.keys(crossings || {})
  const departureDateString = departureDate.format('YYYY-MM-DD')
  const initialSlideIndex =
    crossingDates.findIndex(date => date === departureDateString) +
    (requiresPlaceholders ? 1 : 0)

  const sliderSettings = {
    slidesToShow: 2,
    infinite: false,
    prevArrow: (
      <PrevArrow
        label={labels?.previous}
        requiresPlaceholders={requiresPlaceholders}
      />
    ),
    nextArrow: <NextArrow label={labels?.next} />,
    centerMode: true,
    focusOnSelect: true,
    speed: 250,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          centerMode: true
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          centerMode: true
        }
      }
    ],
    initialSlide: initialSlideIndex
  }

  return (
    <div className="crossing-slide-container">
      <Slider {...sliderSettings} ref={sliderRef}>
        {/* This blank slide is only needed when we have less than 3 days */}
        {requiresPlaceholders && (
          <div className="day blank">
            <div className="placeholder" />
          </div>
        )}

        {Object.entries(crossings).map(([dayKey, dayCrossings]) => {
          return (
            <CrossingDayColumn
              key={dayKey}
              day={dayKey}
              crossings={dayCrossings}
              selectCrossing={crossing => {
                selectCrossing(crossing)
              }}
              currentSelection={currentSelection}
              activeVehicleResourceCode={activeVehicleResourceCode}
              activeVehicle={activeVehicle}
              highlightFirstCrossing={
                departureDate.format('YYYY-MM-DD') === dayKey
              }
              totalPassengers={totalPassengers}
              cabinResourceCodes={cabinResourceCodes}
            />
          )
        })}
        {/* This blank slide is needed so we can center the last day if selected */}
        <div className="day blank">
          <div className="placeholder" />
        </div>
      </Slider>
    </div>
  )
}

CrossingSlider.propTypes = {
  crossings: shape().isRequired,
  departureDate: instanceOf(Moment).isRequired,
  selectCrossing: func.isRequired,
  currentSelection: shape(),
  activeVehicleResourceCode: string,
  activeVehicle: shape(),
  labels: shape({}),
  cabinResourceCodes: PropTypes.arrayOf(string).isRequired
}

export default CrossingSlider
