import React from 'react'
import { bool, node, shape, string, func, arrayOf } from 'prop-types'
import RowGroup from '../RowGroup/RowGroup'
import { renderCheckbox } from '../Checkbox/Checkbox'
import './TitledCheckboxGroup.scss'

const TitledCheckboxGroup = ({
  fieldKeys,
  title,
  fieldDetails,
  fieldLabels,
  values,
  disabled,
  setFieldValue,
  formikCheckbox
}) => {
  return !fieldDetails ||
    (Object.keys(fieldDetails).length === 0 && !values) ? null : (
    <div className="row checkbox-with-title">
      <div className="checkbox-with-title-container">
        {title && (
          <h4 className="row-group-title theme-font-header">{title}</h4>
        )}
        {fieldKeys.map((fieldKey, index) => {
          const isLastElement = index === fieldKeys.length - 1
          const formRowClasses = isLastElement
            ? 'row'
            : 'row u-remove-padding-bottom'
          return (
            <RowGroup
              key={`rg_${index}`}
              title={null}
              formRowClasses={formRowClasses}
              render={({ formRowClasses, errorClasses }) => (
                <span className={formRowClasses}>
                  {fieldDetails[fieldKey] &&
                    renderCheckbox({
                      name: fieldDetails[fieldKey].name,
                      value: values[fieldDetails[fieldKey].name],
                      label: fieldLabels[index],
                      disabled,
                      errorClasses,
                      formikCheckbox
                    })}
                </span>
              )}
            />
          )
        })}
      </div>
    </div>
  )
}

TitledCheckboxGroup.propTypes = {
  /**
   * The fieldKey in fieldDetails and values
   */
  fieldKeys: arrayOf(string).isRequired,
  /**
   * The title above the checkbox
   */
  title: string.isRequired,
  /**
   * The details for this field
   */
  fieldDetails: shape().isRequired,
  /**
   * The label node to the right of the checkbox
   */
  children: node.isRequired,
  /**
   * The form values object
   */
  values: shape().isRequired,
  /**
   * setFieldValue for formik
   */
  setFieldValue: func.isRequired,
  /**
   * Checkbox disabled state
   */
  disabled: bool,
  /**
   * Set formikCheckbox to false to render a standard checkbox instead of a formik Field
   */
  formikCheckbox: bool
}

TitledCheckboxGroup.defaultProps = {
  fieldKeys: [],
  disabled: false,
  formikCheckbox: true
}

export default TitledCheckboxGroup
