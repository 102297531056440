import React from 'react'
import { string, func } from 'prop-types'
import { Field } from 'formik'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const CustomTelephoneInput = ({ name, placeholder, onChange, value }) => {
  return (
    <Field name={name}>
      {({ form: { setFieldTouched } }) => {
        return (
          <ReactPhoneInput
            onChange={(value, country, e, formattedValue) => {
              onChange(formattedValue)
            }}
            value={value}
            inputProps={{
              name
            }}
            placeholder={placeholder}
            onFocus={() => setFieldTouched(name)}
            country="ca"
          />
        )
      }}
    </Field>
  )
}

CustomTelephoneInput.propTypes = {
  name: string,
  placeholder: string,
  onChange: func.isRequired,
  value: string.isRequired
}

export default CustomTelephoneInput
