import React from 'react'
import { bool, node, shape, string } from 'prop-types'
import Modal from '../Modal'
import yn from 'yn'

const StatusModal = ({
  backgroundUrl,
  backgroundAltLabel,
  labels,
  isOpen,
  children
}) => (
  <Modal
    isOpen={isOpen}
    className={
      yn(process.env.REACT_APP_COMPONENT_STATUS_MODAL_IMAGE)
        ? 'modal--lg modal-two-col modal-no-padding two-col-with-bg'
        : 'modal-one-col'
    }
  >
    <div className="modal-row">
      {yn(process.env.REACT_APP_COMPONENT_STATUS_MODAL_IMAGE) && (
        <div className="modal-col modal-col-with-bg modal-image">
          <img
            className="modal-col-bg"
            src={backgroundUrl}
            alt={backgroundAltLabel}
            role="none"
          />
        </div>
      )}

      <div className="modal-col modal-content">
        <div className="modal-col-content modal-col-content-wide modal-content-padded">
          {children}
        </div>
      </div>
    </div>
  </Modal>
)

StatusModal.propTypes = {
  backgroundUrl: string.isRequired,
  backgroundAltLabel: string,
  labels: shape().isRequired,
  isOpen: bool.isRequired,
  children: node.isRequired
}

export default StatusModal
