import { createSelector } from 'reselect'

export const selectSessionState = state => state.sessionData

export const selectLanguage = createSelector(
  selectSessionState,
  sessionState => sessionState.language
)

export const selectSessionId = createSelector(
  selectSessionState,
  sessionState => sessionState.sessionId
)

export const selectStartSessionTimer = createSelector(
  selectSessionState,
  sessionState => sessionState.startSessionTimer
)

export const selectCurrency = createSelector(
  selectSessionState,
  sessionState => sessionState.currency
)

export const selectLatestBookingNumber = createSelector(
  selectSessionState,
  sessionState => sessionState.latestBookingNumber
)
