import React from 'react'
import { Link } from 'react-router-dom'
import { func, bool, string } from 'prop-types'
import { Field, ErrorMessage } from 'formik'
import yn from 'yn'

import {
  formatAddress,
  formatPhoneNumber,
  formatPostalCode
} from '../../configuration/utilities'
import { PHONE_FIELDS } from '../../redux/configuration/constants'
import CustomTelephoneInput from './CustomTelephoneInput'
import PreviewPasswordInput from './PreviewPasswordInput'

const renderTextInput = ({
  name,
  value,
  type,
  placeholder,
  disabled,
  setFieldValue,
  inputHelpLink,
  inputHelpLinkClass,
  inputHelpLinkLabel,
  inputHelpText,
  errorClasses,
  convertToCaps,
  classNames,
  maxLength,
  forceFloatingLabel,
  onBlur
}) => {
  const textInputProps = {
    name,
    value,
    type,
    placeholder,
    disabled,
    inputHelpLink,
    inputHelpLinkClass,
    inputHelpLinkLabel,
    inputHelpText,
    errorClasses,
    convertToCaps,
    classNames,
    forceFloatingLabel,
    onBlur
  }

  if (placeholder) {
    textInputProps.placeholder = textInputProps.placeholder.toUpperCase()
  }

  // NOTE(ebarrett): This usage of name.includes seems a bit sketchy, would be easy to
  //  make a text input with a name that might collide with one of these checks
  //  (e.g. 'dateOfBirthYear', 'workPhoneNumberExtension') ... Maybe revisit this later
  const onChange = e => {
    let value = e.target.value

    if (maxLength && e.target.value.length >= maxLength) {
      value = value.substring(0, maxLength)
    }

    if (name.includes('dateOfBirth')) value = value.replace(/[^\d-]+/g, '')

    PHONE_FIELDS.client.forEach(phoneField => {
      if (name.includes(phoneField)) {
        value = formatPhoneNumber(value)
        value = value.substring(0, 12) // 10 + formatting
      }
    })

    if (name.includes('postalCode')) {
      value = formatPostalCode(value)
    }

    if (name.includes('address')) {
      value = formatAddress(value)
    }

    if (name.includes('city')) {
      value = formatAddress(value)
    }

    setFieldValue(name, value)
  }

  if (setFieldValue) textInputProps.onChange = onChange

  return <TextInput {...textInputProps} setFieldValue={setFieldValue} />
}

const TextInput = ({
  type,
  name,
  value,
  placeholder,
  disabled,
  onChange,
  errorClasses,
  inputHelpLink,
  inputHelpLinkClass,
  inputHelpLinkLabel,
  inputHelpText,
  classNames,
  setFieldValue,
  forceFloatingLabel,
  onBlur
}) => {
  let FieldComponent = Field
  let fieldProps = {
    value: value,
    type,
    name,
    placeholder: forceFloatingLabel ? '' : placeholder,
    disabled,
    onBlur
  }
  const isPasswordField = type === 'password'
  const usePasswordPreview =
    isPasswordField && process.env.REACT_APP_ENABLE_PASSWORD_PREVIEW === 'true'
  const isFormattedTelephoneInput =
    type === 'tel' &&
    yn(process.env.REACT_APP_REQUIRES_COUNTRY_SELECT_FOR_PHONE_INPUTS)

  if (onChange) fieldProps.onChange = onChange

  if (isPasswordField) {
    fieldProps.style = { textTransform: 'unset' }
  }

  if (isFormattedTelephoneInput) {
    FieldComponent = CustomTelephoneInput
    fieldProps.onChange = value => setFieldValue(name, value)
  } else if (usePasswordPreview) {
    FieldComponent = PreviewPasswordInput
  }

  return (
    <label
      htmlFor={name}
      className={`${
        value || isFormattedTelephoneInput || forceFloatingLabel
          ? 'floating-label-container floating-label-container-active'
          : 'floating-label-container'
      } ${classNames ? classNames : ''} ${
        isFormattedTelephoneInput ? 'custom-telephone-input' : ''
      }`}
    >
      <span
        className={
          value || isFormattedTelephoneInput || forceFloatingLabel
            ? 'floating-label floating-label-active'
            : 'floating-label'
        }
      >
        {placeholder}
      </span>

      <FieldComponent {...fieldProps} />

      <span className={errorClasses}>
        {!inputHelpLink && inputHelpText && (
          <span className="input-helptext">{`${inputHelpText} `}</span>
        )}
        <ErrorMessage name={name} />
        {inputHelpLink && (
          <Link to={inputHelpLink} className={inputHelpLinkClass}>
            {inputHelpLinkLabel}
          </Link>
        )}
      </span>
    </label>
  )
}

TextInput.propTypes = {
  type: string,
  name: string.isRequired,
  value: string.isRequired,
  placeholder: string.isRequired,
  disabled: bool.isRequired,
  onChange: func,
  errorClasses: string,
  inputHelpLink: string,
  inputHelpLinkClass: string,
  inputHelpLinkLabel: string,
  inputHelpText: string,
  convertToCaps: bool,
  classNames: string,
  onBlur: func
}

TextInput.defaultProps = {
  type: 'text',
  errorClasses: '',
  inputHelpLinkClass: '',
  inputHelpLinkLabel: 'Need additional help?',
  convertToCaps: false,
  classNames: null,
  disabled: false,
  onBlur: undefined
}

export { renderTextInput }
export default TextInput
