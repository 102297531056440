import ticketTypeCodes from '../../configuration/ticketTypeCodes'

export default function(route, routeType) {
  if (!route) return null

  const activeCrossingData =
    route.crossings && route.crossings.length
      ? route.crossings.find(crossing => crossing.supplierCode === route.code)
      : {}

  let activeTicketTypeData

  if (
    activeCrossingData &&
    activeCrossingData.ticketTypes &&
    activeCrossingData.ticketTypes.length
  ) {
    activeTicketTypeData = activeCrossingData.ticketTypes.find(ticketType => {
      return ticketTypeCodes[routeType].some(
        code => code === ticketType.tripType
      )
    })
  }

  return {
    ...route,
    activeCrossingData,
    activeTicketTypeData
  }
}
