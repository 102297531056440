import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DateRangePicker, SingleDatePicker } from 'react-dates'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import moment from 'moment'

import {
  setDepartureDate,
  setReturnDate
} from '../../../../redux/ferryRouteSelections/actions'
import {
  selectDepartureDate,
  selectReturnDate
} from '../../../../redux/ferryRouteSelections/selectors'
import * as UserSelectionActions from '../../../../redux/userSelections/actions'
import FerryRoutesFormDateSelect from './FerryRoutesFormDateSelect'
import { selectLanguage } from '../../../../redux/session/selectors'

const FerryRoutesFormDateRangePicker = ({
  ferryLabels,
  returnDateOnly,
  onChange
}) => {
  const dispatch = useDispatch()
  const departureDate = useSelector(selectDepartureDate)
  const returnDate = useSelector(selectReturnDate)
  const language = useSelector(selectLanguage)
  const [focus, setFocus] = useState(null)
  // Setting or maintaining dates through renders & form changes
  useEffect(() => {
    if (departureDate && !returnDate) {
      const nextDate = moment(departureDate).add(1, 'days')
      dispatch(setReturnDate(nextDate)) // TODO: Deprecate
      dispatch(
        UserSelectionActions.setReturnSearchDate(nextDate.format('YYYY-MM-DD'))
      )
    }
  }, [dispatch, returnDate, departureDate])

  const [calendarSize, setCalendarSize] = useState(
    window.innerWidth > 1024 ? 'lg' : 'sm'
  )

  function checkNumberOfMonths() {
    const size = window.innerWidth > 1024 ? 'lg' : 'sm'

    if (calendarSize !== size) {
      setCalendarSize(size)
    }
  }

  const debounced = debounce(checkNumberOfMonths, 100)

  useEffect(() => {
    window.addEventListener('resize', debounced)

    return () => {
      debounced.cancel()
      window.removeEventListener('resize', debounced)
    }
  })

  const handleOnDateChange = ({ startDate, endDate }) => {
    const newStartDate = moment(startDate)
    dispatch(setDepartureDate(newStartDate)) // TODO: Deprecate
    dispatch(
      UserSelectionActions.setDepartureSearchDate(
        newStartDate.format('YYYY-MM-DD')
      )
    )

    const newEndDate = moment(
      endDate ? endDate : moment(startDate).add(1, 'days')
    )
    dispatch(setReturnDate(newEndDate)) // TODO: Deprecate
    dispatch(
      UserSelectionActions.setReturnSearchDate(newEndDate.format('YYYY-MM-DD'))
    )
    if (onChange) {
      onChange(startDate, endDate)
    }
  }

  const handleReturnDateChange = date => {
    dispatch(setReturnDate(date)) // TODO: Deprecate
    dispatch(
      UserSelectionActions.setReturnSearchDate(
        moment(date).format('YYYY-MM-DD')
      )
    )
  }

  const toggleCustomDate = dateField => {
    const targetDatePicker = returnDateOnly
      ? 'one-way-return'
      : 'round-trip-' + dateField
    document.getElementById(targetDatePicker).focus()
  }

  let dateRangeMinimumNights
  if (process.env.REACT_APP_FLOW_CLIENT_ID === 'nfl') {
    dateRangeMinimumNights = 0
  } else {
    dateRangeMinimumNights = 1
  }

  return departureDate && returnDate ? (
    <div className="ferry-routes__form-date-range-picker">
      {returnDateOnly ? (
        <SingleDatePicker
          block={true}
          date={returnDate ? returnDate.locale(language) : returnDate}
          daySize={36}
          displayFormat="MMM D, YYYY"
          focused={focus}
          horizontalMargin={30}
          id="one-way-return"
          isOutsideRange={date =>
            date.isBefore(moment(departureDate)) ||
            date.isAfter(moment().add(18, 'months'))
          }
          navNext={
            <i className="material-icons" tabIndex="0">
              east
            </i>
          }
          navPrev={
            <i className="material-icons" tabIndex="0">
              west
            </i>
          }
          noBorder={true}
          numberOfMonths={1}
          onDateChange={handleReturnDateChange}
          onFocusChange={() => {
            if (!focus) {
              const targetHeight = window.innerHeight + 300
              window.setTimeout(() => {
                window.scrollTo(0, targetHeight)
              }, 250)
            }

            setFocus(!focus)
          }}
          firstDayOfWeek={0}
          transitionDuration={0}
        />
      ) : (
        <DateRangePicker
          block={true}
          customArrowIcon={<span className="u-remove-element"></span>}
          customCloseIcon={<span className="u-remove-element"></span>}
          daySize={36}
          displayFormat={language === 'fr' ? 'DD MMM YYYY' : '"MMM D, YYYY"'}
          endDate={
            returnDate ? moment(returnDate).locale(language) : returnDate
          }
          endDateId="round-trip-return"
          focusedInput={focus}
          horizontalMargin={30}
          isOutsideRange={date =>
            date.isBefore(moment(), 'day') ||
            date.isAfter(moment().add(18, 'months'))
          }
          minimumNights={dateRangeMinimumNights}
          navNext={
            <i className="material-icons" tabIndex="0">
              east
            </i>
          }
          navPrev={
            <i className="material-icons" tabIndex="0">
              west
            </i>
          }
          noBorder={true}
          numberOfMonths={calendarSize === 'lg' ? 2 : 1}
          onDatesChange={handleOnDateChange}
          onFocusChange={focus => {
            if (focus) {
              const targetHeight = window.innerHeight + 400
              window.setTimeout(() => {
                window.scrollTo(0, targetHeight)
              }, 250)
            }

            setFocus(focus)
          }}
          showClearDates={true}
          startDate={
            departureDate
              ? moment(departureDate).locale(language)
              : departureDate
          }
          startDateId="round-trip-departure"
          firstDayOfWeek={0}
          transitionDuration={0}
        />
      )}

      <div className="ferry-routes__form-date-range-picker-selects">
        {!returnDateOnly && (
          <FerryRoutesFormDateSelect
            activeDateValue={departureDate}
            containerClassNames="u-form__custom-select--half"
            dateField="departure"
            ferryLabels={ferryLabels}
            fieldLabel={ferryLabels.departureDate}
            toggleDateEvent={toggleCustomDate}
          />
        )}

        <FerryRoutesFormDateSelect
          activeDateValue={returnDate}
          containerClassNames="u-form__custom-select--half"
          dateField="return"
          ferryLabels={ferryLabels}
          fieldLabel={ferryLabels.returnDate}
          toggleDateEvent={toggleCustomDate}
        />
      </div>
    </div>
  ) : null
}

FerryRoutesFormDateRangePicker.defaultProps = {
  onChange: null
}

FerryRoutesFormDateRangePicker.propTypes = {
  ferryLabels: PropTypes.shape(),
  returnDateOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func
}

export default FerryRoutesFormDateRangePicker
