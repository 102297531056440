import React, { useState, useRef } from 'react'

// OpenLayers imports
import 'ol/ol.css'
import './SeatPicker.css'
// VERB imports
import ChooseSeatPopup from './ChooseSeatPopup/ChooseSeatPopup'
import ResponsiveSeatMap from './ResponsiveSeatMap'
import SeatPlanLegend from './SeatPlanLegend'
import SeatPlanLegendLayer from './SeatPlanLegendLayer'

import closeModalIcon from '../../assets/icons/close-modal-button.svg'

import {
  choosenStyle,
  invisibleStyle,
  unavailableStyle,
  selectionStyle
} from './olStyles'
import { useSelector } from 'react-redux'
import { selectLabels } from '../../redux/configuration/selectors'

const SeatPicker = ({
  seatData,
  backgroundImage,
  backgroundImageExtent,
  selectedSeats,
  onCloseClick,
  onSelectionComplete,
  seatsFromModification,
  numberPassengers
}) => {
  const labels = useSelector(selectLabels)
  const [selectedFeatures, setSelectedFeatures] = useState([])
  const [seatList, setSeatList] = useState(selectedSeats)

  // set up refs to cache instances
  const popupPositionRef = useRef({ left: false, above: false })

  // getter
  const getCurrentFeature = () =>
    selectedFeatures?.length ? selectedFeatures[0] : null
  const showSeatPopup = () => selectedFeatures.length > 0

  const seatsConfirmHandler = () => {
    onSelectionComplete(seatList)
  }

  const wasPreviouslySelectedSeat = feature => {
    const seatNumber = feature.get('seatNumber')
    return seatsFromModification?.some(seat => seat.number === seatNumber)
  }

  const isUnavailableSeat = feature =>
    !isChoosenSeat(feature) &&
    !wasPreviouslySelectedSeat(feature) &&
    feature.get('unavailable')

  const isChoosenSeat = feature => {
    const seatNumber = feature.get('seatNumber')
    if (seatList.includes(seatNumber)) return true
    return false
  }

  function isSelectedSeat(feature) {
    const seatNumber = feature.get('seatNumber')
    return (
      selectedFeatures.findIndex(
        check => check.get('seatNumber') === seatNumber
      ) !== -1
    )
  }

  function featureStyleChecker(feature) {
    if (isSelectedSeat(feature)) {
      return selectionStyle
    }
    if (isChoosenSeat(feature)) {
      return choosenStyle
    }
    if (isUnavailableSeat(feature)) {
      return unavailableStyle
    }
    return invisibleStyle
  }

  function featureSelectHandler(features) {
    setSelectedFeatures(features)
  }

  function handleFeatureSpacebarKeydown(feature) {
    const seatNumber = feature.get('seatNumber')
    if (seatList.includes(seatNumber)) {
      setSeatList(seatList.filter(seat => seat !== seatNumber))
    } else {
      setSeatList([...seatList, seatNumber])
    }
  }

  function isFeatureInSeatList(feature) {
    const seatNumber = feature?.get('seatNumber')
    return seatList.includes(seatNumber)
  }

  function handleAddSeatClick(feature) {
    const seatNumber = feature.get('seatNumber')
    if (!seatList.includes(seatNumber)) {
      setSeatList([...seatList, seatNumber])
    }
  }

  function handleRemoveSeatClick(feature) {
    const seatNumber = feature.get('seatNumber')
    setSeatList(seatList.filter(seat => seat !== seatNumber))
  }

  // render component
  return (
    <div className="seat-picker">
      {/* header text and close */}
      <div className="seat-picker-header">
        <h1 className="seat-picker-header-title">{labels.chooseYourSeat}</h1>
        <button
          type="button"
          className="icon-button"
          onClick={onCloseClick}
          aria-label={labels.close}
        >
          <img
            src={closeModalIcon}
            alt={labels.close}
            className="close-button-image"
            role="none"
          />
        </button>
      </div>
      {/* selected seat info on mobile screen */}
      <div className="hidden-md-up seat-selected-info">
        <p>
          {labels.reservedSeating} - {labels.deck}
        </p>
        <h3>
          {seatList.length} {labels.selected}
        </h3>
      </div>
      <div className="seat-picker-content">
        {/* left part: seat map */}
        <div className="seat-picker-ol-container relative">
          {/*** seat map ****/}
          <ResponsiveSeatMap
            enablePan={false}
            enableZoom={false}
            imageUrl={backgroundImage}
            imageExtent={backgroundImageExtent}
            featuresJson={seatData}
            overlaySelector=".seat-picker .ol-popup"
            mapClickHandler={() => setSelectedFeatures([])}
            featureSelectHandler={featureSelectHandler}
            popupRepositionHandler={position =>
              (popupPositionRef.current = position)
            }
            selectedFeature={selectedFeatures[0]}
            getFeatureStyle={featureStyleChecker}
            unavailableSeatFilter={isUnavailableSeat}
            onFeatureSpacebarKeydown={handleFeatureSpacebarKeydown}
            labels={labels}
          />
          {/* seat map overlay controlled and a part of seat map */}
          <div className="ol-popup">
            {showSeatPopup() && (
              <ChooseSeatPopup
                labels={labels}
                feature={getCurrentFeature()}
                isSelected={isFeatureInSeatList(getCurrentFeature())}
                onAddSeatClick={handleAddSeatClick}
                onRemoveSeatClick={handleRemoveSeatClick}
                renderOnLeft={popupPositionRef.current.left}
                renderAbove={popupPositionRef.current.above}
              />
            )}
          </div>
          {/* legend button on mobile */}
          <SeatPlanLegendLayer labels={labels} closeLegend={showSeatPopup()} />
        </div>
        {/* right part on desktop screen: seats data added */}
        <div className="seat-picker-data">
          <h2 className="reserved-seating-title">{labels.reservedSeating}</h2>
          <p className="number-passengers">
            {labels.numberOfPassengers ?? 'Number of passengers'}:{' '}
            <span className="number-passengers-total">{numberPassengers}</span>
          </p>
          <SeatPlanLegend labels={labels} />
          <div className="flex-fill" />
          <p className="reserved-seating-selected">
            {labels.selectedSeats ?? 'Number of seats selected'}:{' '}
            <span className="number-passengers-total">{seatList.length}</span>
          </p>
          <button className="seat-picker-btn" onClick={seatsConfirmHandler}>
            {labels.confirmSeatSelection}
          </button>
        </div>
      </div>
      {/* bottom confirm button on mobile screen */}
      <div className="hidden-md-up bottom-button-row">
        <button className="seat-picker-btn" onClick={seatsConfirmHandler}>
          {labels.confirmSeatSelection}
        </button>
      </div>
    </div>
  )
}

export default SeatPicker
