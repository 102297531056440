import React, { useState } from 'react'
import { func } from 'prop-types'
import { DateRangePicker } from 'react-dates'
import SvgIcon from '../SvgIcon/SvgIcon'
import { useSelector } from 'react-redux'
import { selectLabels } from '../../redux/configuration/selectors'
import ConfigConsumer from '../../redux/configuration/context'
import { selectLanguage } from '../../redux/session/selectors'
import dateFormats from '../../configuration/dateFormats'

const DateSelection = ({ submit }) => {
  const labels = useSelector(selectLabels)
  const language = useSelector(selectLanguage)
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null
  })
  const { startDate, endDate } = dates

  const [focusedInput, setFocusedInput] = useState(null)

  function openDates() {
    document.getElementById('user-bookings-start-date-input').focus()
  }

  return (
    <div className="user-booking-dates-container ">
      <div className="user-booking-dates-custom-input" onClick={openDates}>
        <ConfigConsumer>
          {({ themeVariables }) => (
            <>
              <SvgIcon type="calendar" fill={themeVariables.themeIconColor} />
              <p className="u-remove-margin u-text-center u-flex-grow">
                {startDate ? (
                  <>
                    {startDate
                      .locale(language)
                      .format(
                        language === 'fr'
                          ? dateFormats.customDateSelectLongFrench
                          : dateFormats.customDateSelectLong
                      )}
                    {' - '}
                    {endDate &&
                      endDate
                        .locale(language)
                        .format(
                          language === 'fr'
                            ? dateFormats.customDateSelectLongFrench
                            : dateFormats.customDateSelectLong
                        )}{' '}
                  </>
                ) : (
                  labels.selectDates
                )}
              </p>
            </>
          )}
        </ConfigConsumer>
      </div>
      <DateRangePicker
        startDate={startDate ? startDate.locale(language) : startDate} // momentPropTypes.momentObj or null,
        startDateId="user-bookings-start-date-input" // PropTypes.string.isRequired,
        endDate={endDate ? endDate.locale(language) : endDate} // momentPropTypes.momentObj or null,
        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) => {
          setDates({ startDate, endDate })
          if (startDate && endDate && focusedInput === 'endDate') {
            submit(startDate, endDate)
          }
        }} // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
        onClose={({ startDate, endDate }) => {
          if (!startDate || !endDate) {
            setDates({ startDate: null, endDate: null })
          }
        }}
        block={true}
        customArrowIcon={<span className="u-remove-element"></span>}
        customCloseIcon={<span className="u-remove-element"></span>}
        daySize={36}
        displayFormat="MMM D, YYYY"
        isOutsideRange={() => false}
        minimumNights={0}
        navNext={
          <i className="material-icons" tabIndex="0">
            east
          </i>
        }
        navPrev={
          <i className="material-icons" tabIndex="0">
            west
          </i>
        }
        noBorder={true}
        numberOfMonths={2}
        showClearDates={true}
        firstDayOfWeek={0}
      />
    </div>
  )
}

DateSelection.propTypes = {
  submit: func.isRequired
}

export default DateSelection
