export const mergeBestRates = (crossingData = {}) => {
  return Object.values(crossingData).reduce((obj, crossingType) => {
    if (crossingType?.resourceBestRates) {
      Object.values(crossingType.resourceBestRates).forEach(rate => {
        if (
          !obj[rate.resourceCode] ||
          +obj[rate.resourceCode].price > +rate.price
        ) {
          obj[rate.resourceCode] = rate
        }
      })
    }
    return obj
  }, {})
}
