export const SET_ACTIVE_ACCOMMODATION =
  'activeAccommodations/SET_ACTIVE_ACCOMMODATION'
export const RESET_ACTIVE_ACCOMMODATION =
  'activeAccommodations/RESET_ACTIVE_ACCOMMODATION'
export const SET_ACTIVE_DEPARTURE_ACCOMMODATIONS =
  'activeAccommodations/SET_ACTIVE_DEPARTURE_ACCOMMODATIONS'
export const SET_ACTIVE_RETURN_ACCOMMODATIONS =
  'activeAccommodations/SET_ACTIVE_RETURN_ACCOMMODATIONS'
export const ADD_ACTIVE_ACCOMMODATIONS =
  'activeAccommodations/ADD_ACTIVE_ACCOMMODATIONS'
