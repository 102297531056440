import yn from 'yn'
import generateCustomErrorKey from '../../utilities/bookings/generateCustomErrorKey'
import {
  appRoutes,
  ENABLE_ACCOMMODATIONS,
  ENABLE_EXTRAS
} from '../../configuration/constants'
import {
  selectActiveRouteForm,
  selectDepartureDate,
  selectDepartureRoute,
  selectReturnDate,
  selectReturnRoute
} from '../ferryRouteSelections/selectors'
import {
  selectDepartureAdults,
  selectDepartureSeniors,
  selectDepartureStudents,
  selectDuplicatePassengerQuantities,
  selectReturnAdults,
  selectReturnSeniors,
  selectReturnStudents
} from '../passengerSelections/selectors'
import {
  selectDepartureVehicle,
  selectReturnVehicle
} from '../vehicleSelections/selectors'
import selectVehicleFormType from '../selectors/selectVehicleFormType'
import {
  selectActiveDepartureCrossing,
  selectActiveReturnCrossing
} from '../activeCrossings/selectors'
import { selectActiveModifyBooking } from '../modifyBooking/selectors'
import { selectCrossingData } from '../crossings/selectors'
import { bookingStatusCodes } from '../../configuration/statusCodes'

export const validateRoute = (pathname, state) => {
  const tripTypeCode = selectActiveRouteForm(state)
  const isModify = selectActiveModifyBooking(state) !== null
  const isReturn = tripTypeCode === 'RT'

  let routeIsValid = false
  const lastValidRoute = getLastValidBookingFlowRoute(state, isReturn)

  const routesValid = validateFerryRoutes(state, isReturn)
  const crossingsValid = validateCrossingData(state, isReturn)
  const passengersValid = validatePassengerCounts(state, isReturn)
  const vehiclesValid = validateVehicles(state, isReturn)
  const departureValid = validateDepartureCrossing(state, isReturn)
  const returnValid = validateReturnCrossing(state, isReturn)

  const allTrueOrIsModify = arr => (isModify ? true : arr.every(x => !!x))

  switch (pathname) {
    case appRoutes.passengerQuantities.pathname:
      routeIsValid = allTrueOrIsModify([routesValid, crossingsValid])
      break
    case appRoutes.vehicle.pathname:
      routeIsValid = allTrueOrIsModify([
        routesValid,
        crossingsValid,
        passengersValid
      ])
      break
    case appRoutes.departure.pathname:
      routeIsValid = allTrueOrIsModify([
        routesValid,
        crossingsValid,
        passengersValid,
        vehiclesValid
      ])
      break
    case appRoutes.return.pathname:
      routeIsValid = allTrueOrIsModify([
        routesValid,
        crossingsValid,
        passengersValid,
        vehiclesValid,
        departureValid,
        isReturn
      ])
      break
    case appRoutes.accommodations.pathname:
      routeIsValid =
        ENABLE_ACCOMMODATIONS &&
        allTrueOrIsModify([
          routesValid,
          crossingsValid,
          passengersValid,
          vehiclesValid,
          departureValid,
          returnValid
        ])
      break
    case appRoutes.extras.pathname:
      routeIsValid =
        ENABLE_EXTRAS &&
        allTrueOrIsModify([
          routesValid,
          crossingsValid,
          passengersValid,
          vehiclesValid,
          departureValid,
          returnValid
        ])
      break
    case appRoutes.summary.pathname:
    case appRoutes.passengerDetails.pathname:
    case appRoutes.payment.pathname:
      routeIsValid = allTrueOrIsModify([
        routesValid,
        crossingsValid,
        passengersValid,
        vehiclesValid,
        departureValid,
        returnValid
      ])
      break
    default:
      routeIsValid = true
      break
  }

  return {
    routeIsValid,
    lastValidRoute
  }
}

function getLastValidBookingFlowRoute(state, isReturn) {
  if (!validateFerryRoutes(state, isReturn)) {
    return appRoutes.ferryRoutes.pathname
  }
  if (!validateCrossingData(state, isReturn)) {
    return appRoutes.ferryRoutes.pathname
  }
  if (!validatePassengerCounts(state, isReturn)) {
    return appRoutes.passengerQuantities.pathname
  }
  if (!validateVehicles(state, isReturn)) {
    return appRoutes.vehicle.pathname
  }
  if (!validateDepartureCrossing(state, isReturn)) {
    return appRoutes.departure.pathname
  }
  if (!validateReturnCrossing(state, isReturn)) {
    return isReturn ? appRoutes.return.pathname : appRoutes.departure.pathname
  }
  return appRoutes.summary.pathname
}

function validateFerryRoutes(state, isReturn) {
  const departureDate = selectDepartureDate(state)
  const returnDate = selectReturnDate(state)
  const departureRoute = selectDepartureRoute(state)
  const returnRoute = selectReturnRoute(state)

  const hasValidDepartureTrip = Boolean(
    departureDate && departureRoute && Object.keys(departureRoute).length
  )
  const hasValidReturnTrip = Boolean(
    returnDate && returnRoute && Object.keys(returnRoute).length
  )

  return hasValidDepartureTrip && (isReturn ? hasValidReturnTrip : true)
}

function validateCrossingData(state, isReturn) {
  const crossingData = selectCrossingData(state)
  const departureCrossingsData = crossingData?.departure?.crossings || {}
  const returnCrossingsData = crossingData?.return?.crossings || {}

  const departureValid =
    crossingData?.error ||
    crossingData?.departure?.loading ||
    Object.keys(departureCrossingsData).length > 0
  const returnValid =
    !isReturn ||
    crossingData?.error ||
    crossingData?.return?.loading ||
    Object.keys(returnCrossingsData).length > 0

  return departureValid && (isReturn ? returnValid : true)
}

function validatePassengerCounts(state, isReturn) {
  const duplicatePassengers = selectDuplicatePassengerQuantities(state)
  const departureAdults = selectDepartureAdults(state)
  const departureSeniors = selectDepartureSeniors(state)
  const departureStudents = selectDepartureStudents(state)
  const returnAdults = selectReturnAdults(state)
  const returnSeniors = selectReturnSeniors(state)
  const returnStudents = selectReturnStudents(state)

  const anyDeparture = departureAdults || departureSeniors || departureStudents
  const anyReturn = returnAdults || returnSeniors || returnStudents

  return duplicatePassengers ? anyDeparture : anyDeparture && anyReturn
}

function validateVehicles(state, isReturn) {
  const vehicleFormType = selectVehicleFormType(state)
  const isWalkOn = vehicleFormType === 'VEH-WLK'
  const requiresOnlyOneVehicle = ['VEH-OWY', 'VEH-DUP', 'VELO'].includes(
    vehicleFormType
  )

  if (isWalkOn) {
    return true
  } else {
    const departureVehicle = selectDepartureVehicle(state)
    const returnVehicle = selectReturnVehicle(state)
    const departureValid = !!departureVehicle?.vehicleType
    const returnValid = !!returnVehicle?.vehicleType

    return requiresOnlyOneVehicle
      ? departureValid
      : departureValid && returnValid
  }
}

function validateDepartureCrossing(state, isReturn) {
  const activeDepartureCrossing = selectActiveDepartureCrossing(state)
  return activeDepartureCrossing != null
}

function validateReturnCrossing(state, isReturn) {
  const activeReturnCrossing = selectActiveReturnCrossing(state)
  return isReturn ? activeReturnCrossing != null : true
}

export const convertBookingsToTableRecords = bookings => {
  return (bookings || [])
    .map(booking => {
      if (!booking) {
        return null
      }

      const paid = booking.reservationTotals?.paid
      const hasPayment = Boolean(paid && +paid > 0)

      let showTooltipIcon,
        customErrorLabelKey,
        hideBooking = !!booking.shouldHideBooking

      let bookingStatus = booking.bookingStatus

      // CTMA has some custom logic.
      if (process.env.REACT_APP_FLOW_CLIENT_ID === 'ctma') {
        customErrorLabelKey = generateCustomErrorKey(booking)

        // CTM200-649 -- show different label if waitlisted resource
        const hasWaitlistedResource = bookingHasWaitlistedResource(booking)
        if (hasWaitlistedResource) {
          bookingStatus = 'bookingStatusWaitlisted'
        }

        // from ticket CTM200-647
        const isBelowBrokenThreshold =
          +booking.bookingNumber <=
          +process.env.REACT_APP_BROKEN_BOOKING_NUMBER_THRESHOLD
        const isCancelled =
          booking.bookingStatus === bookingStatusCodes.cancelled
        const isOpenBooking = booking?.departures?.some(
          departure => !departure.departureTime
        )

        hideBooking = isBelowBrokenThreshold && isCancelled
        showTooltipIcon =
          booking.paymentStatus !== 'bookingPaymentCodeFullyPaid' &&
          !isCancelled &&
          !hasWaitlistedResource &&
          !isOpenBooking
      } else {
        // Not CTMA
        const { paymentStatus } = booking
        showTooltipIcon =
          bookingStatus === 'bookingStatusCodeK' ||
          paymentStatus === 'bookingPaymentCodePartlyPaid'
      }

      if (hideBooking) {
        return null
      }

      const total = booking.reservationTotals?.total
      const allowPaymentRetry =
        yn(process.env.REACT_APP_SHOULD_ALLOW_PAYMENT_FROM_ACCOUNTS_PAGE) &&
        total &&
        +total > 0

      return {
        ...booking,
        bookingStatus,
        totalPriceToPay: booking?.reservationTotals?.totalWithoutPaid,
        showTooltipIcon,
        paid,
        hasPayment,
        customErrorLabelKey,
        allowPaymentRetry
      }
    })
    .filter(x => !!x)
}

function bookingHasWaitlistedResource(booking) {
  let hasWaitlistedResource = false
  const { departures } = booking
  departures.forEach(departure => {
    const { accommodations, passengers, vehicles } = departure
    if (
      accommodations?.waitListAmount > 0 ||
      passengers?.waitListAmount > 0 ||
      vehicles?.waitListAmount > 0
    ) {
      hasWaitlistedResource = true
    }
  })
  return hasWaitlistedResource
}
