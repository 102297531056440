import { get } from 'lodash'
import cleanObject from '../../utilities/data/cleanObject'
import { PHONE_FIELDS } from '../configuration/constants'
import {
  filterFieldsByName,
  getInitialValuesFromFields
} from '../configuration/utilities'
import { getFieldsAndLayout as getPassengerFieldsAndLayout } from '../../components/PassengerDetailsForm/utilities'
import {
  getInitialValuesFromBookingPassengers,
  preparePassengerDetails
} from '../../components/BookingInformation/utilities'

export const buildModifyPassengers = ({
  activeModifyBooking,
  duplicatePassengerQuantities,
  fields,
  paymentFerryId,
  passengerFieldDetails,
  passengerFormData = null
}) => {
  const modificationId = activeModifyBooking?.bookingNumber
  let result = {}

  const { fieldNames: passengerFieldNames } = getPassengerFieldsAndLayout({})

  if (modificationId && activeModifyBooking?.departureRoute) {
    const sharedPassengerFieldDetails = filterFieldsByName(
      fields,
      passengerFieldNames
    )
    const initialValuesSharedPassenger = getInitialValuesFromFields(
      sharedPassengerFieldDetails
    )
    let initialValuesPassengerDetails = false

    initialValuesPassengerDetails = getInitialValuesFromBookingPassengers(
      passengerFieldDetails,
      initialValuesSharedPassenger,
      {
        departureRoute: activeModifyBooking.departureRoute,
        returnRoute: activeModifyBooking.returnRoute
          ? activeModifyBooking.returnRoute
          : null
      }
    )

    if (passengerFormData) {
      Object.keys(initialValuesPassengerDetails).forEach(passengerKey => {
        if (passengerFormData[passengerKey]) {
          initialValuesPassengerDetails[passengerKey] = {
            ...initialValuesPassengerDetails[passengerKey],
            ...passengerFormData[passengerKey]
          }
        }
      })
    }

    result = preparePassengerDetails({
      formData: {
        passengerDetails: { ...initialValuesPassengerDetails }
      },
      result,
      duplicatePassengerQuantities
    })
  }

  return result
}

export const getBillingFromCustomerAccount = ({
  firstName = '',
  lastName = '',
  address = '',
  city = '',
  countryCode = '',
  county = '',
  postCode = '',
  email = '',
  language = '',
  passengerType = '',
  gender = '',
  acceptsContact = 'N',
  title = '',
  ...restValues
}) => {
  const mappedBillingInfo = {
    name: firstName + ' ' + lastName,
    address,
    city,
    countryCode,
    county,
    postCode,
    email,
    language,
    passengerType,
    gender,
    acceptsContact,
    title
  }
  PHONE_FIELDS.client.forEach(phoneField => {
    mappedBillingInfo[phoneField] = restValues[phoneField] || ''
  })
  return mappedBillingInfo
}

export function formatCrossingData({
  vehicleDetails,
  crossing,
  accommodationDetails = false,
  passengerTicketType,
  vehicleTicketType,
  accommodationTicketType,
  passengers,
  addOns,
  pets,
  /*
    Note:
    isModify will keep passengers array empty
  */
  isModify = false
}) {
  const {
    passengerResourceCode,
    supplier: supplierCode,
    departureDate,
    departureTime,
    adults,
    children,
    infants,
    seniors,
    students
  } = crossing
  let { vehicleResourceCode } = crossing
  let vehicle
  let accommodations = []

  const { vehicleLicense, vehicleType, vehicleLength } = vehicleDetails

  if (vehicleResourceCode) {
    vehicle = cleanObject({
      resourceCode: vehicleResourceCode,
      licenseNumber: vehicleLicense,
      // "leave it blank… don’t believe we use it for anything but I put there in case a client wanted to start using it" - IPS team
      // vehicleType: vehicleType ? vehicleType.resourceCode : '',
      length: vehicleLength
        ? vehicleLength.toString()
        : vehicleType?.defaultLength
        ? vehicleType.defaultLength.toString()
        : '',
      height: vehicleType ? vehicleType.defaultHeight : ''
    })
  }

  if (accommodationDetails && Object.keys(accommodationDetails).length) {
    const formatRefactoredAccommodationDetails = accommodationDetails => {
      return accommodationDetails
    }

    accommodations = Object.values(
      formatRefactoredAccommodationDetails(accommodationDetails)
    ).reduce((arr, accommodation) => {
      if (+accommodation?.count > 0) {
        arr.push({
          resourceCode: get(accommodation, 'details.resourceCode', ''),
          amount: accommodation.count,
          number: '', //TODO: add these for other clients
          place: '',
          waitList: get(accommodation, 'waitlist', '')
        })
      }

      return arr
    }, [])
  }

  if (addOns && addOns?.length) {
    addOns.forEach(addOn => accommodations.push({ ...addOn }))
  }

  const result = {
    passengerResourceCode,
    passengerTicketType,
    accommodationTicketType,
    vehicleTicketType,
    supplierCode,
    departureDate,
    departureTime,
    adults,
    children,
    infants,
    seniors,
    students,
    vehicle,
    accommodations,
    // we may call this to get pricing before we have the proper data,
    // in that situation we need to make a fake passenger for each of these.
    // otherwise the API fails 🤷‍
    passengers: passengers.length
      ? passengers
      : generateMockPassengers({
          adults,
          children,
          infants,
          seniors,
          students,
          isModify
        }),
    addOns: pets
  }

  return cleanObject(result)
}

function generateMockPassengers({
  adults,
  children,
  infants,
  seniors,
  students,
  isModify
}) {
  const passengers = []

  /*
    Note:
    If we were to generate mock passengers in modify,
    the summary prices would increase
    as they would appear as additional passengers
  */
  if (isModify) return passengers

  if (adults) {
    passengers.push(...generateFakePassenger(adults))
  }
  if (children) {
    passengers.push(...generateFakePassenger(children))
  }
  if (seniors) {
    passengers.push(...generateFakePassenger(seniors))
  }
  if (infants) {
    passengers.push(...generateFakePassenger(infants))
  }
  if (students) {
    passengers.push(...generateFakePassenger(students))
  }

  return passengers
}

function generateFakePassenger(passengerCode) {
  const countAndType = passengerCode.split('|')
  const count = countAndType?.[0]
  const type = countAndType?.[1]
  const passengers = []
  let i = 0
  while (i < count) {
    passengers.push({
      title: '',
      firstName: `Passenger ${i}`,
      lastName: `LastName ${i}`,
      gender: 'M',
      dateOfBirth: '',
      frequentTravellerNumber: '',
      countryCode: '',
      note: '',
      passengerType: type,
      disability: '',
      passport: '',
      typeOfId: '',
      passportExpiryDate: '',
      placeOfBirth: '',
      consessionNumber: '',
      consent: ''
    })
    i++
  }

  return passengers
}

export function findCrossing(crossings, date, time) {
  if (!crossings) {
    return null
  }
  return crossings.find(
    crossing =>
      crossing.departureDate.startsWith(date) && crossing.departureTime === time
  )
}
