import React from 'react'
import { array, string, number, func, bool, oneOfType } from 'prop-types'
import { useSelector } from 'react-redux'
import { selectLanguage } from '../../redux/session/selectors'
import { Field, ErrorMessage } from 'formik'

const Select = ({ name, options, onChange, value, disabled, onBlur }) => {
  const language = useSelector(selectLanguage)

  return (
    <Field
      as="select"
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      onBlur={onBlur}
    >
      {options.map(option => {
        let displayLabel = option.label

        if (option?.labels?.[language]) {
          displayLabel = option.labels[language]
        }

        return (
          <option key={option.value} value={option.value}>
            {displayLabel}
          </option>
        )
      })}
    </Field>
  )
}

Select.propTypes = {
  name: string.isRequired,
  options: array.isRequired,
  onChange: func.isRequired,
  value: oneOfType([string, number]).isRequired,
  disabled: bool,
  onBlur: func
}

export default Select

// for formik
export const RenderSelect = ({
  name,
  value,
  setValue,
  errorClasses,
  options,
  disabled,
  label,
  conditionalValueKey,
  conditionalValue,
  shouldClear,
  forceFloatingLabel,
  onBlur
}) => {
  let selectPlaceholder = label

  if (selectPlaceholder) {
    selectPlaceholder = selectPlaceholder.toUpperCase()
  }

  function onChange(e) {
    setValue(name, e.target.value)
    if (shouldClear) {
      setValue(shouldClear, '')
    }
  }
  let filteredOptions = options

  if (conditionalValueKey) {
    filteredOptions = conditionalValue
      ? options.filter(option => option.sortByKey === conditionalValue)
      : []
  }

  if (!filteredOptions || !filteredOptions.length) {
    filteredOptions.push({
      label: forceFloatingLabel ? '' : selectPlaceholder,
      value: ''
    })
  }

  const emptyOptionIndex = filteredOptions.findIndex(
    ({ value }) => value === ''
  )
  if (emptyOptionIndex !== -1) {
    filteredOptions[emptyOptionIndex] = {
      ...filteredOptions[emptyOptionIndex],
      label: filteredOptions[emptyOptionIndex].label
        ? filteredOptions[emptyOptionIndex].label.toUpperCase()
        : ''
    }
  }

  return (
    <label
      className={`u-form__field-label floating-label-container ${
        value || forceFloatingLabel ? 'floating-label-container-active' : ''
      }`}
    >
      <div
        className={`u-relative u-full-width custom-dropdown  ${
          value || forceFloatingLabel ? '' : 'no-value'
        }`}
      >
        <span
          className={`floating-label ${
            value || forceFloatingLabel ? 'floating-label-active' : ''
          }`}
        >
          {selectPlaceholder}
        </span>
        <div className="select-wrapper">
          <Select
            name={name}
            options={filteredOptions}
            value={value}
            disabled={disabled}
            onChange={onChange}
            label={selectPlaceholder}
            errorClasses={errorClasses}
            onBlur={onBlur}
          />
        </div>
        <span className={errorClasses}>
          <ErrorMessage name={name} />
        </span>
      </div>
    </label>
  )
}
