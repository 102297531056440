import React, { useCallback, useEffect } from 'react'
import { shape, func } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import appRoutes from '../../configuration/appRoutes'
import {
  selectCanUseApp,
  selectLabels
} from '../../redux/configuration/selectors'
import { login as travelAgentLogin } from '../../redux/travelAgent/actions'
import {
  selectTravelAgentLoginLoading,
  selectTravelAgentLoginSuccess,
  selectTravelAgentLoginError
} from '../../redux/travelAgent/selectors'
import { selectSessionId } from '../../redux/session/selectors'
import { loginUser } from '../../redux/user/actions'
import {
  selectAttemptingLogin,
  selectLoginSuccess,
  selectLoginError
} from '../../redux/user/selectors'
import LoginModal from '../LoginModal/LoginModal'
import { formVariants } from '../LoginModal/constants'
import { modalVariants } from '../LoginModal/constants'

const LoginModalContainer = ({
  modalIsOpen,
  closeModal,
  openModal,
  modalContinue,
  handleRouteChange
}) => {
  const loginFormCurrentVariant = Object.keys(modalIsOpen).includes(
    formVariants.CUSTOMER_LOGIN
  )
    ? modalIsOpen.login.variant || ''
    : ''
  const agentLoginFormCurrentVariant = Object.keys(modalIsOpen).includes(
    formVariants.TRAVEL_AGENT_LOGIN
  )
    ? modalIsOpen[formVariants.TRAVEL_AGENT_LOGIN].variant || ''
    : ''
  const loginFormIsNoRegisterFormVariant =
    modalVariants.NO_REGISTER === loginFormCurrentVariant
  const agentLoginFormIsNoRegisterFormVariant =
    modalVariants.NO_REGISTER === agentLoginFormCurrentVariant

  const dispatch = useDispatch()
  const labels = useSelector(selectLabels)
  const sessionId = useSelector(selectSessionId)

  const canUseApp = useSelector(selectCanUseApp)

  const customerLoginLoading = useSelector(selectAttemptingLogin)
  const travelAgentLoginLoading = useSelector(selectTravelAgentLoginLoading)
  const customerLoginSuccess = useSelector(selectLoginSuccess)
  const travelAgentLoginSuccess = useSelector(selectTravelAgentLoginSuccess)
  const customerLoginError = useSelector(selectLoginError)
  const travelAgentLoginError = useSelector(selectTravelAgentLoginError)

  useEffect(() => {
    if (
      travelAgentLoginSuccess &&
      Object.keys(modalIsOpen).includes(formVariants.TRAVEL_AGENT_LOGIN)
    ) {
      closeModal({
        type: formVariants.TRAVEL_AGENT_LOGIN,
        variant: agentLoginFormCurrentVariant || ''
      })
    }
  }, [
    travelAgentLoginSuccess,
    closeModal,
    agentLoginFormCurrentVariant,
    modalIsOpen
  ])

  useEffect(() => {
    if (
      customerLoginSuccess &&
      Object.keys(modalIsOpen).includes(formVariants.CUSTOMER_LOGIN)
    ) {
      closeModal({
        type: formVariants.CUSTOMER_LOGIN,
        variant: loginFormCurrentVariant || ''
      })
    }
  }, [customerLoginSuccess, closeModal, loginFormCurrentVariant, modalIsOpen])

  const openModalMemoized = useCallback(openModal, [])
  useEffect(() => {
    const params = queryString.parse(window.location.search) || {}
    if (
      params.travelAgentLogin &&
      process.env.REACT_APP_ENABLE_TRAVEL_AGENT_USERS
    ) {
      openModalMemoized({ type: formVariants.TRAVEL_AGENT_LOGIN })
    }
  }, [openModalMemoized])

  return (
    <>
      <LoginModal
        isTravelAgentLoginModal={true}
        isNoRegisterFormVariant={agentLoginFormIsNoRegisterFormVariant}
        isOpen={Object.keys(modalIsOpen).includes(
          formVariants.TRAVEL_AGENT_LOGIN
        )}
        loginModalType={formVariants.TRAVEL_AGENT_LOGIN}
        formVariant={agentLoginFormCurrentVariant}
        formTitle={
          !agentLoginFormIsNoRegisterFormVariant && labels.travelAgentLogin
        }
        handleRouteChange={handleRouteChange}
        closeModal={closeModal}
        attemptingLogin={travelAgentLoginLoading}
        canUseApp={canUseApp}
        loginError={travelAgentLoginError ? labels.loginTravelAgentError : ''}
        onValidSubmit={formValues => {
          dispatch(travelAgentLogin({ ...formValues }, sessionId))
        }}
        modalContinue={modalContinue}
        registerLink={process.env.REACT_APP_TRAVEL_AGENT_REGISTER_LINK}
      />
      <LoginModal
        isTravelAgentLoginModal={false}
        isNoRegisterFormVariant={loginFormIsNoRegisterFormVariant}
        isOpen={Object.keys(modalIsOpen).includes(formVariants.CUSTOMER_LOGIN)}
        loginModalType={formVariants.CUSTOMER_LOGIN}
        formVariant={loginFormCurrentVariant}
        formTitle={!loginFormIsNoRegisterFormVariant && labels.login}
        handleRouteChange={handleRouteChange}
        closeModal={closeModal}
        attemptingLogin={customerLoginLoading}
        canUseApp={canUseApp}
        loginError={customerLoginError ? labels.loginUserError : ''}
        onValidSubmit={formValues => {
          dispatch(loginUser({ ...formValues }, sessionId))
        }}
        modalContinue={modalContinue}
        onRegisterLinkClick={() => {
          handleRouteChange(appRoutes.register.pathname)
          closeModal({
            type: formVariants.CUSTOMER_LOGIN,
            variant: loginFormCurrentVariant
          })
        }}
      />
    </>
  )
}

LoginModalContainer.propTypes = {
  modalIsOpen: shape().isRequired,
  closeModal: func.isRequired,
  openModal: func.isRequired,
  modalContinue: func.isRequired,
  handleRouteChange: func.isRequired
}

export default LoginModalContainer
