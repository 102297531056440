import { handleActions } from 'redux-actions'
import {
  SET_DEPARTURE_ADULTS,
  SET_DEPARTURE_CHILDREN,
  SET_DEPARTURE_INFANTS,
  SET_DEPARTURE_SENIORS,
  SET_DEPARTURE_STUDENTS,
  SET_DEPARTURE_PETS,
  SET_DUPLICATE_PASSENGER_QUANTITIES,
  SET_RETURN_ADULTS,
  SET_RETURN_CHILDREN,
  SET_RETURN_INFANTS,
  SET_RETURN_SENIORS,
  SET_RETURN_STUDENTS,
  SET_RETURN_PETS,
  RESET_PASSENGER_SELECTION,
  SET_ALL_DEPARTURE_GUESTS,
  SET_ALL_RETURN_GUESTS
} from './constants'

// TODO: these keys and initial state need to come from config.options.passengerTypes
const passengerSelectionsInitialState = {
  departure: {},
  return: {},
  pets: {},
  duplicatePassengerQuantities: true
}

const passengerSelectionsReducer = handleActions(
  {
    [SET_DEPARTURE_ADULTS]: (state, { payload }) => ({
      ...state,
      departure: {
        ...state.departure,
        adult: payload
      }
    }),
    [SET_DEPARTURE_SENIORS]: (state, { payload }) => ({
      ...state,
      departure: {
        ...state.departure,
        senior: payload
      }
    }),
    [SET_DEPARTURE_STUDENTS]: (state, { payload }) => ({
      ...state,
      departure: {
        ...state.departure,
        student: payload
      }
    }),
    [SET_DEPARTURE_PETS]: (state, { payload }) => ({
      ...state,
      pets: {
        ...state.pets,
        departure: payload
      }
    }),
    [SET_DEPARTURE_CHILDREN]: (state, { payload }) => ({
      ...state,
      departure: {
        ...state.departure,
        child: payload
      }
    }),
    [SET_DEPARTURE_INFANTS]: (state, { payload }) => ({
      ...state,
      departure: {
        ...state.departure,
        infant: payload
      }
    }),
    [SET_DUPLICATE_PASSENGER_QUANTITIES]: (state, { payload }) => {
      const copiedState = { ...state }
      if (payload) copiedState.return = { ...copiedState.departure }

      return {
        ...copiedState,
        duplicatePassengerQuantities: payload
      }
    },

    [SET_RETURN_ADULTS]: (state, { payload }) => ({
      ...state,
      return: {
        ...state.return,
        adult: payload
      }
    }),
    [SET_RETURN_SENIORS]: (state, { payload }) => ({
      ...state,
      return: {
        ...state.return,
        senior: payload
      }
    }),
    [SET_RETURN_STUDENTS]: (state, { payload }) => ({
      ...state,
      return: {
        ...state.return,
        student: payload
      }
    }),
    [SET_RETURN_PETS]: (state, { payload }) => ({
      ...state,
      pets: {
        ...state.pets,
        return: payload
      }
    }),
    [SET_RETURN_CHILDREN]: (state, { payload }) => ({
      ...state,
      return: {
        ...state.return,
        child: payload
      }
    }),
    [SET_RETURN_INFANTS]: (state, { payload }) => ({
      ...state,
      return: {
        ...state.return,
        infant: payload
      }
    }),
    [RESET_PASSENGER_SELECTION]: () => ({ ...passengerSelectionsInitialState }),
    [SET_ALL_DEPARTURE_GUESTS]: (state, action) => {
      return { ...state, departure: action.payload }
    },
    [SET_ALL_RETURN_GUESTS]: (state, action) => {
      return { ...state, return: action.payload }
    }
  },
  { ...passengerSelectionsInitialState }
)

export { passengerSelectionsInitialState }
export default passengerSelectionsReducer
