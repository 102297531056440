export const FETCH_PAYMENT_CONFIRMATION =
  'paymentConfirmation/FETCH_PAYMENT_CONFIRMATION'
export const PAYMENT_CONFIRMATION_SUCCESS =
  'paymentConfirmation/PAYMENT_CONFIRMATION_SUCCESS'
export const PAYMENT_CONFIRMATION_ERROR =
  'paymentConfirmation/PAYMENT_CONFIRMATION_ERROR'
export const CLEAR_PAYMENT_CONFIRMATION =
  'paymentConfirmation/CLEAR_PAYMENT_CONFIRMATION'
export const SET_PAYMENT_FERRY_ID = 'paymentConfirmation/SET_PAYMENT_FERRY_ID'
export const SET_PAYPAL_PAYMENT_CONFIRMATION =
  'paymentConfirmation/SET_PAYPAL_PAYMENT_CONFIRMATION'
