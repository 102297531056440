import { createSelector } from 'reselect'

export const selectPassengerSelections = state => state.passengerSelections

export const selectDepartureAdults = createSelector(
  selectPassengerSelections,
  passengerSelectionState => passengerSelectionState.departure.adult
)
export const selectDepartureSeniors = createSelector(
  selectPassengerSelections,
  passengerSelectionState => passengerSelectionState.departure.senior
)
export const selectDepartureStudents = createSelector(
  selectPassengerSelections,
  passengerSelectionState => passengerSelectionState.departure.student
)
export const selectDepartureChildren = createSelector(
  selectPassengerSelections,
  passengerSelectionState => passengerSelectionState.departure.child
)
export const selectDepartureInfants = createSelector(
  selectPassengerSelections,
  passengerSelectionState => passengerSelectionState.departure.infant
)
export const selectDeparturePets = createSelector(
  selectPassengerSelections,
  passengerSelectionState => passengerSelectionState.pets?.departure
)

export const selectReturnAdults = createSelector(
  selectPassengerSelections,
  passengerSelectionState => passengerSelectionState.return.adult
)
export const selectReturnSeniors = createSelector(
  selectPassengerSelections,
  passengerSelectionState => passengerSelectionState.return.senior
)
export const selectReturnStudents = createSelector(
  selectPassengerSelections,
  passengerSelectionState => passengerSelectionState.return.student
)
export const selectReturnChildren = createSelector(
  selectPassengerSelections,
  passengerSelectionState => passengerSelectionState.return.child
)
export const selectReturnInfants = createSelector(
  selectPassengerSelections,
  passengerSelectionState => passengerSelectionState.return.infant
)
export const selectReturnPets = createSelector(
  selectPassengerSelections,
  passengerSelectionState => passengerSelectionState.pets?.return
)

export const selectDuplicatePassengerQuantities = createSelector(
  selectPassengerSelections,
  passengerSelectionState =>
    passengerSelectionState.duplicatePassengerQuantities
)

export const selectDepartureTotalGuests = createSelector(
  selectPassengerSelections,
  passengerSelectionState =>
    Object.values(passengerSelectionState.departure).reduce(
      (num, guestCount) => (num += +guestCount),
      0
    )
)

export const selectReturnTotalGuests = createSelector(
  selectPassengerSelections,
  passengerSelectionState =>
    Object.values(passengerSelectionState.return).reduce(
      (num, guestCount) => (num += +guestCount),
      0
    )
)

export const hasSetReturnPassengers = createSelector(
  selectPassengerSelections,
  passengerSelectionState =>
    Boolean(Object.keys(passengerSelectionState.return).length)
)
