import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// ************ CONSTANTS *************
import {
  ENABLE_ACCOMMODATIONS,
  ENABLE_EXTRAS,
  FLOW_CLIENT_ID,
  ENABLE_WAITLIST_ACCOMMODATIONS,
  ModifyFlowType
} from '../../configuration/constants'
import appRoutes from '../../configuration/appRoutes'

// ************ ACTIONS *************
import {
  setActiveAccommodation,
  setActiveDepartureAccommodations,
  setActiveReturnAccommodations
} from '../../redux/activeAccommodations/actions'
import { fetchCrossing } from '../../redux/crossings/actions'
import {
  updateDepartureAccommodations,
  updateReturnAccommodations
} from '../../redux/userSelections/actions'

// *********** COMPONENTS ***********
import BrandLoader from '../../components/BrandLoader/BrandLoader'
import OpenableAccommodationCard from '../../components/accommodations/OpenableAccommodationCard'
import PageWarningBanner from '../../components/PageWarningBanner/PageWarningBanner'

// ********* SELECTORS **************
import { selectAvailableAccommodations } from '../../redux/activeCrossings/selectors'
import {
  selectAccommodationsContent,
  selectLabels
} from '../../redux/configuration/selectors'
import { selectActiveAccommodations } from '../../redux/activeAccommodations/selectors'
import { selectCrossingData } from '../../redux/crossings/selectors'
import { selectActiveRouteForm } from '../../redux/ferryRouteSelections/selectors'
import {
  selectActiveModifyBooking,
  selectModifyFlowType
} from '../../redux/modifyBooking/selectors'
import { selectUserSelections } from '../../redux/userSelections/selectors'
import {
  selectDepartureRoute,
  selectReturnRoute
} from '../../redux/ferryRouteSelections/selectors'
// *********** UTILITIES ************
import { sortObjectByKey } from '../../configuration/utilities'
// *********** STYLES ***************
import '../../styles/scss/components/accommodations/accommodations.scss'

const AccommodationsContainer = ({ handleRouteChange }) => {
  const dispatch = useDispatch()
  const activeRouteForm = useSelector(selectActiveRouteForm)
  const modifyFlowType = useSelector(selectModifyFlowType)
  const activeModifyBooking = useSelector(selectActiveModifyBooking)
  const userSelections = useSelector(selectUserSelections)
  const departureRoute = useSelector(selectDepartureRoute)
  const returnRoute = useSelector(selectReturnRoute)

  useEffect(() => {
    dispatch(
      fetchCrossing('departure', { fetchAllAccommodationResources: true })
    )
    if (activeRouteForm === 'RT') {
      dispatch(
        fetchCrossing('return', { fetchAllAccommodationResources: true })
      )
    }

    if (activeModifyBooking) {
      if (
        activeModifyBooking?.departureAccommodations &&
        ENABLE_ACCOMMODATIONS
      ) {
        dispatch(
          updateDepartureAccommodations(
            activeModifyBooking?.departureAccommodations
          )
        )
        // TODO: deprecate
        dispatch(
          setActiveDepartureAccommodations(
            activeModifyBooking?.departureAccommodations
          )
        )
      }

      if (activeModifyBooking?.returnAccommodations && ENABLE_ACCOMMODATIONS) {
        dispatch(
          updateReturnAccommodations(activeModifyBooking?.returnAccommodations)
        )
        // TODO: deprecate
        dispatch(
          setActiveReturnAccommodations(
            activeModifyBooking?.returnAccommodations
          )
        )
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const crossingState = useSelector(selectCrossingData)

  const isLoading =
    crossingState?.departure?.loading || crossingState?.return?.loading

  const labels = useSelector(selectLabels)
  const activeAccommodations = useSelector(selectActiveAccommodations)
  const accommodationsContent = useSelector(selectAccommodationsContent)

  const availableAccommodationsAll = useSelector(selectAvailableAccommodations)

  const availableAccommodations = Object.keys(
    availableAccommodationsAll
  ).reduce((acc, resourceCode) => {
    const accommodation = accommodationsContent?.accommodations?.[resourceCode]
    if (!Number(accommodation?.isExtra)) {
      acc[resourceCode] = { ...availableAccommodationsAll[resourceCode] }
    }
    return acc
  }, {})

  function getDirectionValue(accommodation, direction) {
    return (
      activeAccommodations?.[direction]?.[accommodation.details.resourceCode]
        ?.count || 0
    )
  }

  function getDirectionIsWaitList(accommodation, direction) {
    if (!ENABLE_WAITLIST_ACCOMMODATIONS) {
      return false
    }
    const waitListAccommodation =
      activeAccommodations?.[direction]?.[accommodation.details.resourceCode]
    if (waitListAccommodation) {
      return waitListAccommodation.waitlist
    } else {
      const maxCapacity = accommodation?.[direction]?.freeCapacity
      return maxCapacity <= 0
    }
  }

  function handleCountChange(
    accommodation,
    direction,
    addend,
    isWaitList = false
  ) {
    if (ENABLE_ACCOMMODATIONS) {
      if (direction === 'departure') {
        const departureAccommodations = {}
        departureAccommodations[accommodation.details.resourceCode] =
          getDirectionValue(accommodation, direction) + addend
        dispatch(updateDepartureAccommodations(departureAccommodations))
      } else {
        const returnAccommodations = {}
        returnAccommodations[accommodation.details.resourceCode] =
          getDirectionValue(accommodation, direction) + addend
        dispatch(updateReturnAccommodations(returnAccommodations))
      }
      // TODO: deprecate
      dispatch(
        setActiveAccommodation({
          code: accommodation.details.resourceCode,
          count: getDirectionValue(accommodation, direction) + addend,
          direction,
          accommodation: accommodation[direction],
          waitlist: isWaitList
        })
      )
    }
  }

  let continueBtnLabel, continueBtnRoute
  if (ENABLE_EXTRAS) {
    continueBtnRoute = appRoutes.extras.pathname
    continueBtnLabel = labels.continueToExtrasBtn
  } else {
    continueBtnRoute = appRoutes.summary.pathname
    continueBtnLabel = labels.continueToSummaryBtn
  }

  const cardLayoutClass = 'accommodations-list-horizontal',
    CardComponent = OpenableAccommodationCard

  return (
    <div
      className={`accommodations u-container u-page-view-container ${FLOW_CLIENT_ID}`}
    >
      <h1 className="page-title theme-font-header">{labels.services}</h1>
      {accommodationsContent?.warnings?.length ? (
        <div className="accommodations-warnings">
          {accommodationsContent.warnings.map(warning => {
            return (
              <PageWarningBanner
                message={warning.message}
                key={warning.message}
              >
                <span
                  className="accommodations-warning-message"
                  dangerouslySetInnerHTML={{ __html: warning.message }}
                />
              </PageWarningBanner>
            )
          })}
        </div>
      ) : null}
      <div className="content">
        {isLoading ? (
          <div style={{ flex: 1 }}>
            <BrandLoader />
          </div>
        ) : (
          <>
            {availableAccommodations &&
            Object.keys(availableAccommodations).length ? (
              <div className={cardLayoutClass}>
                {sortObjectByKey(availableAccommodations).map(
                  ([key, accommodation]) => {
                    return (
                      <CardComponent
                        key={key}
                        accommodation={accommodation}
                        content={
                          accommodationsContent &&
                          accommodationsContent.accommodations
                            ? accommodationsContent.accommodations[key]
                            : {}
                        }
                        handleCountChange={handleCountChange}
                        curDepartureCount={getDirectionValue(
                          accommodation,
                          'departure'
                        )}
                        curReturnCount={getDirectionValue(
                          accommodation,
                          'return'
                        )}
                        isOneWay={activeRouteForm === 'OW'}
                        editReturnOnly={
                          modifyFlowType === ModifyFlowType.DEPARTURE_LOCKED
                        }
                        departureRoute={departureRoute}
                        returnRoute={returnRoute}
                        isDepartureWaitList={getDirectionIsWaitList(
                          accommodation,
                          'departure'
                        )}
                        isReturnWaitList={
                          activeRouteForm !== 'OW' &&
                          getDirectionIsWaitList(accommodation, 'return')
                        }
                      />
                    )
                  }
                )}
              </div>
            ) : (
              <p className="u-flex-grow u-text-center">
                {labels.noAvailableAccommodations}
              </p>
            )}
          </>
        )}

        {accommodationsContent?.sidebars?.length ? (
          <div className={'additional-information'}>
            {accommodationsContent.sidebars.map(sidebar => {
              const { caption, textBlocks } = sidebar

              const image = sidebar.images?.[0]

              return (
                <div className="additional-information-card" key={caption}>
                  <div className="image-container">
                    {image && <img src={image.path} alt="" role="none" />}
                  </div>
                  <div className="body">
                    <p className="title theme-title-variant-four">{caption}</p>

                    {textBlocks && textBlocks.length
                      ? textBlocks.map(block => (
                          <p className="copy" key={block}>
                            {block}
                          </p>
                        ))
                      : null}
                  </div>
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
      <div className="footer">
        {accommodationsContent?.messagesBottom?.length > 0 && (
          <div>
            {accommodationsContent.messagesBottom.map(message => {
              return (
                <div
                  className={`copy ${message.type}`}
                  key={message.message}
                  dangerouslySetInnerHTML={{ __html: message.message }}
                />
              )
            })}
          </div>
        )}
        {labels.accommodationsDisclaimer && !isLoading && (
          <p className="u-remove-margin u-text-center subheading">
            {labels.accommodationsDisclaimer}
          </p>
        )}
      </div>
      <div className="u-page-submit-container">
        <button
          className="btn btn-primary large-primary-btn"
          onClick={() => {
            // MAI tracking
            try {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'analytics-accommodations-1',
                details: userSelections
              })
            } catch (err) {
              console.log('Analytics error: ' + err)
            }

            handleRouteChange(continueBtnRoute)
          }}
        >
          {continueBtnLabel} →
        </button>
      </div>
    </div>
  )
}

export default AccommodationsContainer
