import { createSelector } from 'reselect'

export const selectPaymentConfirmationState = state => state.paymentConfirmation

export const selectPaymentConfirmationData = createSelector(
  selectPaymentConfirmationState,
  paymentConfirmationState => paymentConfirmationState.data
)

export const selectPaymentFerryId = createSelector(
  selectPaymentConfirmationState,
  paymentConfirmationState => paymentConfirmationState.paymentFerryId
)

export const selectPayPalPaymentConfirmationData = createSelector(
  selectPaymentConfirmationState,
  paymentConfirmationState => paymentConfirmationState.payPalPaymentData
)
