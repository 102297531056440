import React, { Fragment, useState, useEffect } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
// ************ CONSTANTS *************
import { ENABLE_COPASSENGERS } from '../../configuration/constants'
import {
  passengerTypeOptionMap,
  PHONE_FIELDS
} from '../../redux/configuration/constants'
import supportedCurrencies from '../../configuration/supportedCurrencies'
// ************ ACTIONS *************
import { resetSetBooking } from '../../redux/booking/actions'
import { getAgentBookings } from '../../redux/travelAgent/actions'
import {
  updateUser,
  resetUpdateSuccess,
  resetUpdateError,
  resetRegisterForm,
  setUserCoPassengers
} from '../../redux/user/actions'
import { getCustomerBookings } from '../../redux/userBookings/actions'
import { findReservationReset } from '../../redux/user/actions'
// *********** COMPONENTS ***********
import ExpandableSection from '../../components/ExpandableSection/ExpandableSection'
import Notification from '../../components/Notification'
import RowGroup from '../../components/RowGroup/RowGroup'
import TravelAgentForm from '../../components/TravelAgentForm/TravelAgentForm'
import UserBookings from '../../components/UserBookings/UserBookings'
import CoPassengerConfig from '../../components/CoPassengerConfig/CoPassengerConfig'
import { formVariants } from '../../components/LoginModal/constants'
import { renderTextInput } from '../../components/TextInput/TextInput'

// *********** CONTEXT **************
import ModalConsumer from '../../context/modalContext'
// ********* SELECTORS **************
import {
  selectAttemptingSetBooking,
  selectSetBookingSuccess,
  selectMonerisCurrencyCode
} from '../../redux/booking/selectors'
import {
  selectLabels,
  selectFields,
  selectDefaultProductCode,
  selectCanUseApp,
  selectRoutesContent
} from '../../redux/configuration/selectors'
import {
  selectCustomerAccount,
  selectAttemptingUpdate,
  selectUpdateSuccess,
  selectRegisterUserSuccess,
  selectUpdateError,
  selectCoPassengers,
  selectLoginSuccess
} from '../../redux/user/selectors'
import {
  selectUserBookings,
  selectUserBookingsLoading,
  selectUserBookingsLoadingError
} from '../../redux/userBookings/selectors'
import {
  selectTravelAgentAccount,
  selectTravelAgentBookings,
  selectTravelAgentBookingsGetLoading,
  selectTravelAgentBookingsGetError
} from '../../redux/travelAgent/selectors'
import { selectSessionId, selectLanguage } from '../../redux/session/selectors'
import { selectBookingsTableRecords } from '../../redux/selectors/selectBookingsTableRecords'
import { selectAgentBookingsTableRecords } from '../../redux/selectors/selectAgentBookingsTableRecords'
// ********** GLOBAL SELECTORS ******

// *********** UTILITIES ************
import {
  addDynamicLabelsToOptions,
  formatPhoneNumber,
  cleanPhoneNumber
} from '../../configuration/utilities'
import {
  filterFieldsByName,
  renderFormFields,
  getSchemaFromFieldDetails
} from '../../redux/configuration/utilities'
import {
  getFormFieldsAndLayout,
  mapCustomerValuesToInputNames
} from './accountUtilities'
// *********** STYLES ***************
import '../../styles/scss/components/forms/page-form-defaults.scss'

const AccountContainer = ({
  handleRouteChange,
  modalIsOpen,
  closeModal,
  openModal
}) => {
  const clientId = process.env.REACT_APP_FLOW_CLIENT_ID
  const dispatch = useDispatch()
  const defaultProductCode = useSelector(selectDefaultProductCode)
  const labels = useSelector(selectLabels)
  const fields = useSelector(selectFields)
  const sessionId = useSelector(selectSessionId)
  const canUseApp = useSelector(selectCanUseApp)
  const loginSuccess = useSelector(selectLoginSuccess)
  const customerAccount = useSelector(selectCustomerAccount)
  const travelAgentAccount = useSelector(selectTravelAgentAccount)
  const attemptingUpdate = useSelector(selectAttemptingUpdate)
  const updateSuccess = useSelector(selectUpdateSuccess)
  const updateError = useSelector(selectUpdateError)
  const bookingSuccess = useSelector(selectSetBookingSuccess)
  const registerSuccess = useSelector(selectRegisterUserSuccess)
  const coPassengers = useSelector(selectCoPassengers)

  // Our API and moneris take different codes.
  const monerisCurrencyCode = useSelector(selectMonerisCurrencyCode)
  const currencyCodes = supportedCurrencies.find(
    ({ monerisCode }) => monerisCode === monerisCurrencyCode
  )
  const currencyCode = currencyCodes ? currencyCodes.APICode : 'CAD'

  const userBookings = useSelector(selectUserBookings)
  const userBookingsGetLoading = useSelector(selectUserBookingsLoading)
  const userBookingsGetError = useSelector(selectUserBookingsLoadingError)
  const agentBookings = useSelector(selectTravelAgentBookings)
  const agentBookingsGetLoading = useSelector(
    selectTravelAgentBookingsGetLoading
  )
  const agentBookingsGetError = useSelector(selectTravelAgentBookingsGetError)
  const attemptingSetBooking = useSelector(selectAttemptingSetBooking)
  const ferryRoutes = useSelector(selectRoutesContent)
  const lang = useSelector(selectLanguage)

  const [passwordMismatchError, setPasswordMismatchError] = useState(false)
  const [minPhoneNumberError, setMinPhoneNumberError] = useState(false)
  const [dateOfBirthError, setDateOfBirthError] = useState(false)
  const [accountCreatedSuccess, setAccountCreatedSuccess] = useState(false)
  const [updatedCoPassengers, setUpdatedCoPassengers] = useState(null)
  const [coPassengersValid, setCoPassengersValid] = useState(true)
  const [renderCoPassengerErrors, setRenderCoPassengerErrors] = useState(false)
  const [shouldAutoOpenDetails, setShouldAutoOpenDetails] = useState(false)

  const { fieldNames, formLayout } = getFormFieldsAndLayout()

  /*
    Note:
    From ticket CTM200-452: each field should be max 30 characters.
    However, in the booking itself, the max length for the "Name" field
    (which combines the first name and last name) is max 40 characters.
  */
  const [nameLengthError, setNameLengthError] = useState(null)

  const fieldDetails = filterFieldsByName(fields, fieldNames)
  const initialValues = mapCustomerValuesToInputNames(customerAccount, fields)
  PHONE_FIELDS.client.forEach(phoneField => {
    if (initialValues[phoneField]) {
      initialValues[phoneField] = formatPhoneNumber(initialValues[phoneField])
    }
  })
  const validationSchema = getSchemaFromFieldDetails(fieldDetails)
  const disabled = attemptingUpdate || !canUseApp
  const showStandardForm = !travelAgentAccount

  const userBookingsSharedProps = {
    handleRouteChange,
    openModal,
    closeModal,
    modalIsOpen,
    sessionId,
    labels,
    agentBookingsGetError,
    attemptingSetBooking,
    ferryRoutes,
    lang,
    clientId,
    currencyCode
  }

  // note: Adding ages to passenger type labels dynamically here
  if (fieldDetails?.passengerType?.options) {
    const newOptions = addDynamicLabelsToOptions({
      options: fieldDetails.passengerType.options,
      nameValueMap: passengerTypeOptionMap,
      customLabelSubstring: 'AgeDesc',
      labels
    })

    if (newOptions.length > 0) {
      fieldDetails.passengerType.options = newOptions
    }
  }

  useEffect(() => {
    if (bookingSuccess) {
      dispatch(resetSetBooking())
    }
    if (registerSuccess) {
      dispatch(resetRegisterForm())
      setAccountCreatedSuccess(true)
    }
  }, [dispatch, bookingSuccess, registerSuccess])

  useEffect(() => {
    if (customerAccount) {
      dispatch(getCustomerBookings())
    }
    dispatch(findReservationReset())
  }, [dispatch, customerAccount])

  const handleSubmit = formValues => {
    setPasswordMismatchError(false)
    setMinPhoneNumberError(false)
    setNameLengthError(false)
    setDateOfBirthError(false)
    let submitError = false

    if (formValues) {
      if (formValues.password !== formValues.confirmPassword) {
        setPasswordMismatchError(true)
        submitError = true
      }

      PHONE_FIELDS.client.forEach(phoneField => {
        if (
          PHONE_FIELDS.requiredStatus[phoneField] === true &&
          formValues[phoneField] &&
          formValues[phoneField].length < 10
        ) {
          submitError = true
          setMinPhoneNumberError(true)
        }
      })

      // using >= 39 to account for the space we need to add when sending to the api.
      if (
        formValues.lastName?.length &&
        formValues.firstName?.length &&
        formValues.lastName.length + formValues.firstName.length >= 39
      ) {
        setNameLengthError(true)
        submitError = true
      }

      if (
        formValues.dateOfBirth !==
        moment(formValues.dateOfBirth).format('YYYY-MM-DD')
      ) {
        setDateOfBirthError(true)
        submitError = true
      }
    }

    if (!coPassengersValid) {
      submitError = true
      setRenderCoPassengerErrors(true)
    }

    if (!submitError) {
      const {
        acceptsContact,
        confirmPassword,
        firstName,
        lastName,
        ...restFormValues
      } = formValues
      const { customerNumber } = customerAccount
      const updatePayload = {
        customerNumber,
        ...restFormValues,
        dateOfBirth: moment(formValues.dateOfBirth).format('YYYY-MM-DD'),
        defaultProductCode,
        acceptsContact,
        sessionId,
        firstName,
        lastName,
        firstAndLastName: `${firstName} ${lastName}`
      }
      PHONE_FIELDS.client.forEach(phoneField => {
        updatePayload[phoneField] = cleanPhoneNumber(formValues[phoneField])
      })
      dispatch(updateUser(updatePayload))
      setRenderCoPassengerErrors(false)

      if (ENABLE_COPASSENGERS && updatedCoPassengers) {
        dispatch(setUserCoPassengers(updatedCoPassengers))
      }
    }
  }

  return (
    <div className="page-container">
      <span className="page-container-top-bg" />
      <div className="row-group-container row-group-container-with-top-bg">
        {fieldDetails && Object.keys(fieldDetails).length > 0 && (
          <div className="row-group-container-bg row-group-container-with-top-bg row-group-container-no-title ">
            {accountCreatedSuccess && (
              <Notification
                type="success"
                size="lg"
                message={labels.accountSuccessfullyCreatedMsg}
                onDismiss={() => {
                  setAccountCreatedSuccess(false)
                }}
              />
            )}
            {travelAgentAccount && (
              <TravelAgentForm agent={travelAgentAccount} />
            )}
            {showStandardForm && (
              <div className="padded-container wider-content">
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                  enableReinitialize
                >
                  {({ setFieldValue, values }) => (
                    <ModalConsumer>
                      {({ openModal }) => (
                        <ExpandableSection
                          labels={labels}
                          title={labels.accountInformation}
                          disabled={!customerAccount}
                          onClickDisabled={() => {
                            setShouldAutoOpenDetails(true)
                            openModal({ type: formVariants.CUSTOMER_LOGIN })
                          }}
                          openOnSuccess={loginSuccess && shouldAutoOpenDetails}
                          showLabel={labels.showSection}
                          hideLabel={labels.hideSection}
                        >
                          <Form>
                            <RowGroup
                              convertInputsToCaps
                              render={({ formRowClasses, errorClasses }) => (
                                <div className="row-group-container-bg row-group-container-no-title">
                                  {customerAccount?.customerNumber && (
                                    <span className={formRowClasses}>
                                      <span
                                        className={
                                          'row-label theme-title-variant-eight'
                                        }
                                      >
                                        {labels.customerNumber}:{' '}
                                        <span className="row-label-value">
                                          {customerAccount.customerNumber}
                                        </span>
                                      </span>
                                    </span>
                                  )}
                                  {renderFormFields({
                                    formLayout,
                                    fieldDetails,
                                    values,
                                    setFieldValue,
                                    disabled,
                                    formRowClasses,
                                    errorClasses
                                  })}
                                  <h4 className="row-group-subtitle theme-font-header">
                                    {labels.password}
                                  </h4>
                                  <span className={formRowClasses}>
                                    {renderTextInput({
                                      name: fieldDetails.password.name,
                                      value: values[fieldDetails.password.name],
                                      type: 'password',
                                      placeholder: fieldDetails.password.label,
                                      errorClasses,
                                      setFieldValue,
                                      disabled,
                                      classNames: 'password',
                                      inputHelpText: labels.passwordHelper
                                    })}
                                    {renderTextInput({
                                      name: fieldDetails.confirmPassword.name,
                                      value:
                                        values[
                                          fieldDetails.confirmPassword.name
                                        ],
                                      type: 'password',
                                      placeholder:
                                        fieldDetails.confirmPassword.label,
                                      errorClasses,
                                      setFieldValue,
                                      disabled,
                                      classNames: 'password'
                                    })}
                                  </span>

                                  {labels.accountUpdateHelpText && (
                                    <div className="row">
                                      <p className="account-update-reminder">
                                        {labels.accountUpdateHelpText}
                                      </p>
                                    </div>
                                  )}

                                  {ENABLE_COPASSENGERS && (
                                    <Fragment>
                                      <h4 className="row-group-subtitle theme-font-header">
                                        {labels.updateCoPassengers}
                                      </h4>
                                      <CoPassengerConfig
                                        formRowClasses={formRowClasses}
                                        labels={labels}
                                        fields={fields}
                                        countryCode={values.countryCode}
                                        initialValues={coPassengers.map(cp => {
                                          // strip nasty date formatting
                                          const { dateOfBirth } = cp
                                          const [
                                            correctDateString
                                          ] = dateOfBirth.split('T')
                                          return {
                                            ...cp,
                                            dateOfBirth: correctDateString,
                                            delete: false
                                          }
                                        })}
                                        onChange={(
                                          newCoPassengers,
                                          isValid
                                        ) => {
                                          setUpdatedCoPassengers(
                                            newCoPassengers
                                          )
                                          setCoPassengersValid(isValid)
                                        }}
                                        renderErrors={renderCoPassengerErrors}
                                      />
                                    </Fragment>
                                  )}

                                  {passwordMismatchError && (
                                    <Notification
                                      type="error"
                                      message={labels.passwordMismatch}
                                      onDismiss={() => {
                                        setPasswordMismatchError(false)
                                      }}
                                    />
                                  )}
                                  {updateError && (
                                    <Notification
                                      type="error"
                                      message={labels.accountUpdateError}
                                      onDismiss={() => {
                                        dispatch(resetUpdateError())
                                      }}
                                    />
                                  )}
                                  {minPhoneNumberError && (
                                    <Notification
                                      type="error"
                                      message={labels.minPhoneNumberError}
                                      onDismiss={() => {
                                        setMinPhoneNumberError(false)
                                      }}
                                    />
                                  )}
                                  {dateOfBirthError && (
                                    <Notification
                                      type="error"
                                      message={
                                        labels.dateOfBirthError ||
                                        'Invalid date of birth entered.'
                                      }
                                      onDismiss={() => {
                                        setDateOfBirthError(false)
                                      }}
                                    />
                                  )}
                                  {updateSuccess && (
                                    <Notification
                                      type="success"
                                      message={labels.accountUpdateSuccess}
                                      onDismiss={() => {
                                        dispatch(resetUpdateSuccess())
                                      }}
                                    />
                                  )}
                                  {nameLengthError && (
                                    <Notification
                                      type="error"
                                      message={
                                        labels.combinedNameMaxLengthErrorMessage
                                      }
                                      onDismiss={() => {
                                        setNameLengthError(false)
                                      }}
                                    />
                                  )}

                                  <div className="fullpage-submit-row">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      disabled={disabled}
                                    >
                                      {labels.updateBtn}
                                    </button>
                                  </div>
                                </div>
                              )}
                            />
                          </Form>
                        </ExpandableSection>
                      )}
                    </ModalConsumer>
                  )}
                </Formik>
              </div>
            )}
            {customerAccount && (
              <UserBookings
                {...userBookingsSharedProps}
                bookings={userBookings}
                loadingBookings={userBookingsGetLoading}
                loadBookingsError={userBookingsGetError}
                getBookingsAction={getCustomerBookings}
                tableRecordsSelector={selectBookingsTableRecords}
              />
            )}
            {!customerAccount && travelAgentAccount && (
              <UserBookings
                {...userBookingsSharedProps}
                bookings={agentBookings}
                loadingBookings={agentBookingsGetLoading}
                loadBookingsError={agentBookingsGetError}
                getBookingsAction={getAgentBookings}
                tableRecordsSelector={selectAgentBookingsTableRecords}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default AccountContainer
