import React, { Fragment, useEffect, useState } from 'react'
import { func, shape } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import {
  selectLabels,
  selectFields,
  selectCanUseApp
} from '../../redux/configuration/selectors'
import RowGroup from '../../components/RowGroup/RowGroup'
import { formVariants } from '../../components/LoginModal/constants'
import {
  getInitialValuesFromFields,
  filterFieldsByName,
  getSchemaFromFieldDetails,
  renderFormFields
} from '../../redux/configuration/utilities'
import {
  passwordResetEmail,
  resetPasswordResetEmailSuccess
} from '../../redux/user/actions'
import {
  selectAttemptingPasswordResetEmail,
  selectPasswordResetEmailSuccess
} from '../../redux/user/selectors'
import { selectLanguage, selectSessionId } from '../../redux/session/selectors'
import { fieldNames, formLayout } from './forgotPasswordConstants'

import '../../styles/scss/components/forms/page-form-defaults.scss'

const ForgotPasswordContainer = ({
  handleRouteChange,
  closeModal,
  modalIsOpen
}) => {
  const dispatch = useDispatch()
  const labels = useSelector(selectLabels)
  const fields = useSelector(selectFields)
  const clientId = process.env.REACT_APP_FLOW_CLIENT_ID
  const sessionId = useSelector(selectSessionId)
  const canUseApp = useSelector(selectCanUseApp)
  const language = useSelector(selectLanguage)
  const attemptingPasswordResetEmail = useSelector(
    selectAttemptingPasswordResetEmail
  )
  const passwordResetEmailSuccess = useSelector(selectPasswordResetEmailSuccess)
  const [attemptedCloseLoginOnMount, setAttemptedCloseLoginOnMount] = useState(
    false
  )
  const fieldDetails = filterFieldsByName(fields, fieldNames)
  const initialValues = getInitialValuesFromFields(fieldDetails)
  const validationSchema = getSchemaFromFieldDetails(fieldDetails)
  const disabled = attemptingPasswordResetEmail || !canUseApp

  useEffect(() => {
    if (!attemptedCloseLoginOnMount) {
      setAttemptedCloseLoginOnMount(true)
      dispatch(resetPasswordResetEmailSuccess())

      if (Object.keys(modalIsOpen).includes(formVariants.CUSTOMER_LOGIN)) {
        closeModal({ type: formVariants.CUSTOMER_LOGIN })
      }
    }
  }, [dispatch, closeModal, modalIsOpen, attemptedCloseLoginOnMount])

  const handleSubmit = ({ emailAddress }) => {
    dispatch(
      passwordResetEmail({ emailAddress, clientId, sessionId, language })
    )
  }

  return (
    <div className="page-container">
      <span className="page-container-top-bg" />
      {!fieldDetails ? null : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, values }) => {
            return !values ? null : (
              <Form>
                <div className="row-group-container row-group-container-with-top-bg">
                  <div className="row-group-container-bg row-group-container-with-top-bg">
                    {!passwordResetEmailSuccess &&
                      !attemptingPasswordResetEmail && (
                        <div className="row alternate-row-header">
                          <h2 className="theme-font-header">
                            {labels.forgotPassword}
                          </h2>
                          <p>{labels.forgotPasswordHelpText}</p>
                        </div>
                      )}
                    <RowGroup
                      render={({ formRowClasses, errorClasses }) => (
                        <Fragment>
                          {passwordResetEmailSuccess ||
                          attemptingPasswordResetEmail ? (
                            <div className="row alternate-row-header alternate-header-padding">
                              <h2 className="theme-font-header">
                                {labels.thankYou}
                              </h2>
                              <p>{labels.passwordResetSuccess}</p>
                            </div>
                          ) : (
                            <Fragment>
                              {renderFormFields({
                                formLayout,
                                fieldDetails,
                                values,
                                setFieldValue,
                                disabled,
                                formRowClasses,
                                errorClasses
                              })}
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    />
                  </div>
                  <div className="fullpage-submit-row">
                    {!passwordResetEmailSuccess &&
                      !attemptingPasswordResetEmail && (
                        <button
                          type="submit"
                          className="btn btn-primary large-primary-btn"
                          disabled={disabled}
                        >
                          {labels.send}
                        </button>
                      )}
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

ForgotPasswordContainer.propTypes = {
  handleRouteChange: func.isRequired,
  closeModal: func.isRequired,
  modalIsOpen: shape().isRequired
}

export default ForgotPasswordContainer
