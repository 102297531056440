import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { arrayOf, shape, string, func, oneOfType, bool } from 'prop-types'
import { sortBy } from 'lodash'
import defaultActiveIcon from '../../../assets/icons/pngs/vehicle-types-green/auto-1.png'
import defaultIcon from '../../../assets/icons/pngs/vehicle-types/auto-1.png'
import {
  VEHICLE_HEIGHT_FILTERING,
  VEHICLE_LENGTH_FILTERING,
  ENABLE_BICYCLE_OPTION
} from '../../../configuration/constants'
import {
  calculateFormFlexOrder,
  renderCardOrder
} from '../../../configuration/utilities'
import VehicleFilter from '../VehicleFilter/VehicleFilter'
import VehicleAdditionalDetails from '../VehicleAdditionalDetails/VehicleAdditionalDetails'
import MeasurementModal from '../../MeasurementModal/MeasurementModal'
import { formatPriceByLanguage } from '../../../redux/configuration/utilities'
import {
  vehicleSections,
  SCROLL_TO_VEHICLE_SECTION_IN
} from '../../../redux/vehicleSelections/constants'
import { selectBicycleResourceCode } from '../../../redux/configuration/selectors'

const VehicleForm = ({
  clientContactPhoneNumber,
  selectedHeightFilter,
  selectedLengthFilter,
  activeSelection,
  vehicleOptions,
  labels,
  language,
  tooltips,
  tooltipIcons,
  modalIsOpen,
  openModal,
  closeModal,
  handleChange,
  handleVehicleSelection,
  formValues,
  formErrors,
  title,
  bestRates,
  walkOnCode,
  allowOneWayWalkOn
}) => {
  const [activeVehicleFilterIndex, setActiveVehicleFilterIndex] = useState(0)
  const [formOrder, setFormOrder] = useState(
    calculateFormFlexOrder(activeVehicleFilterIndex)
  )
  const lengthGroupRef = useRef(null)
  const optionGroupRef = useRef(null)
  const bicycleResourceCode = useSelector(selectBicycleResourceCode)
  const scrollToLengthGroup = () => {
    setTimeout(() => {
      lengthGroupRef.current.scrollIntoView()
    }, SCROLL_TO_VEHICLE_SECTION_IN)
  }
  const scrollToOptionGroup = () => {
    setTimeout(() => {
      optionGroupRef.current.scrollIntoView()
    }, SCROLL_TO_VEHICLE_SECTION_IN)
  }

  useEffect(() => {
    const formPosition = calculateFormFlexOrder(activeVehicleFilterIndex)
    if (formPosition !== formOrder) {
      setFormOrder(formPosition)
    }
    const resizeCheck = () => {
      const formPosition = calculateFormFlexOrder(activeVehicleFilterIndex)
      if (formPosition !== formOrder) {
        setFormOrder(formPosition)
      }
    }

    window.addEventListener('resize', resizeCheck)

    return () => {
      window.removeEventListener('resize', resizeCheck)
    }
  }, [activeVehicleFilterIndex, formOrder])

  const length = vehicleOptions?.groups?.length
  const height = vehicleOptions?.groups?.height
  const vehicles = vehicleOptions?.vehicles

  // using sortBy here as a polyfill for firefox when no displayOrder in on the vehicles.
  let vehicleOptionsToDisplay = vehicles
    ? sortBy(vehicles, ['vehicle', 'displayOrder'])
    : []

  if (VEHICLE_HEIGHT_FILTERING || VEHICLE_LENGTH_FILTERING) {
    vehicleOptionsToDisplay = vehicles
      ? vehicles
          .filter(({ resourceCode, defaultHeight, defaultLength }) => {
            let match = true

            if (VEHICLE_HEIGHT_FILTERING && selectedHeightFilter) {
              const { maxVal, minVal } = selectedHeightFilter
              match = defaultHeight <= maxVal && defaultHeight >= minVal
            }

            if (VEHICLE_LENGTH_FILTERING && selectedLengthFilter) {
              const { maxVal, minVal } = selectedLengthFilter
              match =
                match && defaultLength <= maxVal && defaultLength >= minVal
            }

            if (ENABLE_BICYCLE_OPTION && resourceCode === bicycleResourceCode) {
              match = false
            }

            return match
          })
          .sort((a, b) => (+a.displayOrder < +b.displayOrder ? -1 : 1))
      : []
  }

  const requiresAdditionalDetails = !!(
    activeSelection &&
    formValues?.vehicleType &&
    (formValues.vehicleType.lengthRequired ||
      formValues.vehicleType.licensePlateNumberRequired)
  )

  const renderHeightGroup =
    VEHICLE_HEIGHT_FILTERING && !!(height && height.length && activeSelection)
  const renderLengthGroup =
    VEHICLE_LENGTH_FILTERING &&
    !!(activeSelection && selectedHeightFilter && length && length.length)

  const shouldDisplayVehicleOptions = () => {
    let result = true

    if (VEHICLE_HEIGHT_FILTERING && !selectedHeightFilter) result = false
    if (VEHICLE_LENGTH_FILTERING && !selectedLengthFilter) result = false
    if (!VEHICLE_HEIGHT_FILTERING && !VEHICLE_LENGTH_FILTERING) result = true

    return result
  }

  const displayVehicleOptions = shouldDisplayVehicleOptions()
  const showVehicleLengthWarning =
    selectedLengthFilter &&
    !!(
      selectedLengthFilter.minVal &&
      selectedLengthFilter.minVal > 21 &&
      selectedLengthFilter.type === 'length'
    )

  const isOneWayWalkOn =
    allowOneWayWalkOn && formValues?.vehicleType === walkOnCode
  return (
    <div>
      {title && <h2 className="theme-font-header">{title}</h2>}
      {allowOneWayWalkOn && (
        <div className="passenger-vehicles__initial-form">
          <button
            className={`passenger-vehicles__initial-form-toggle ${
              isOneWayWalkOn ? 'active' : ''
            }`}
            onClick={() =>
              handleVehicleSelection(isOneWayWalkOn ? '' : walkOnCode)
            }
            aria-label={labels.noVehicleMsg}
          >
            <div className="toggle-label">
              <p className="u-remove-margin">{labels.noVehicleMsg}</p>
            </div>
          </button>
        </div>
      )}
      {isOneWayWalkOn ? null : (
        <>
          <div className="passenger-vehicles__group-types-toggles">
            {renderHeightGroup && (
              <VehicleFilter
                onSelect={() => {
                  if (
                    !Object.keys(modalIsOpen).includes('vehicle-height-modal')
                  ) {
                    openModal({ type: 'vehicle-height-modal' })
                  }
                }}
                title={labels.howHighIsYourVehicle}
                helpText={labels.vehicleHeightExplanation}
                labels={labels}
                showTooltip={Boolean(tooltips && tooltips['height'])}
                isTitleClickable={Boolean(tooltips && tooltips['height'])}
                options={height}
                selectedFilter={formValues[vehicleSections.HEIGHT]}
                handleChange={value => {
                  handleChange(vehicleSections.HEIGHT, value)
                  scrollToLengthGroup()
                }}
              />
            )}
            <span ref={lengthGroupRef}>
              {renderLengthGroup && (
                <VehicleFilter
                  onSelect={() => {
                    if (
                      !Object.keys(modalIsOpen).includes('vehicle-length-modal')
                    ) {
                      openModal({ type: 'vehicle-length-modal' })
                    }
                  }}
                  title={labels.howLongIsYourVehicle}
                  helpText={labels.vehicleLengthExplanation}
                  labels={labels}
                  showTooltip={Boolean(tooltips && tooltips['length'])}
                  isTitleClickable={Boolean(tooltips && tooltips['length'])}
                  options={length}
                  selectedFilter={formValues[vehicleSections.LENGTH]}
                  handleChange={value => {
                    handleChange(vehicleSections.LENGTH, value)
                    scrollToOptionGroup()
                  }}
                  showWarningBanner={showVehicleLengthWarning}
                  warningBannerText={labels.vehicleLengthBeAdvised}
                />
              )}
            </span>
          </div>
          <span ref={optionGroupRef}>
            {displayVehicleOptions && (
              <div data-testid={'vehicle-options-container'}>
                <div className="title-container vehicle-section-title-container">
                  <h4 className="u-remove-margin u-text-center vehicle-section-title theme-font-header">
                    {labels.vehicleSelection}
                  </h4>
                </div>

                <div className="passenger-vehicles__types-form-toggles">
                  {vehicleOptionsToDisplay.map((vehicle, i) => {
                    const freeCapacity =
                      bestRates?.[vehicle.resourceCode]?.['freeCapacity']
                    if (+freeCapacity < 0) return null

                    const isSelected =
                      formValues &&
                      formValues.vehicleType &&
                      formValues.vehicleType.resourceCode ===
                        vehicle.resourceCode
                    if (isSelected && activeVehicleFilterIndex !== i) {
                      setActiveVehicleFilterIndex(i)
                    }

                    const icons = vehicle.icons ? vehicle.icons.default : null
                    const activeIcons = vehicle.icons
                      ? vehicle.icons.active
                      : null
                    const icon = isSelected ? activeIcons : icons
                    const imgSrc = icon
                      ? icon.path
                      : isSelected
                      ? defaultActiveIcon
                      : defaultIcon
                    const title =
                      labels[vehicle.resourceCode] || vehicle.resourceCode
                    const description = icons?.description
                    const price = formatPriceByLanguage(
                      bestRates?.[vehicle.resourceCode]?.['price'],
                      language
                    )

                    return (
                      <div
                        className="passenger-vehicle-container "
                        key={vehicle.resourceCode}
                        style={{
                          order: renderCardOrder(i, formOrder)
                        }}
                      >
                        <button
                          className={`passenger-vehicles__types-form-toggle ${
                            isSelected ? ' active' : ''
                          } ${
                            requiresAdditionalDetails && isSelected
                              ? 'add-border'
                              : ''
                          }`}
                          onClick={() => {
                            handleVehicleSelection(vehicle)
                            setActiveVehicleFilterIndex(i)
                          }}
                          aria-label={title}
                        >
                          <div className="passenger-vehicles__types-form-toggle-header">
                            <h6>{title}</h6>
                            {<p>{`${description} `} </p>}
                          </div>
                          <div className="passenger-vehicles__types-form-meta">
                            <span className="passenger-vehicles__types-form-icon">
                              <img src={imgSrc} alt="" role="none" />
                            </span>
                            {price && (
                              <span className="passenger-vehicles__types-form-price-container">
                                <span className="passenger-vehicles__types-form-starting-from">
                                  {labels.startingFrom}
                                </span>
                                <span className="passenger-vehicles__types-form-price-cost">
                                  {price}
                                </span>
                                <span className="passenger-vehicles__types-form-price-taxes">
                                  {labels.taxFeeDesc}
                                </span>
                              </span>
                            )}
                          </div>
                        </button>
                      </div>
                    )
                  })}
                  {requiresAdditionalDetails && (
                    <VehicleAdditionalDetails
                      formOrder={formOrder}
                      formValues={formValues}
                      formErrors={formErrors}
                      labels={labels}
                      tooltips={tooltips}
                      handleChange={handleChange}
                      openModal={openModal}
                      modalIsOpen={modalIsOpen}
                      clientContactPhoneNumber={clientContactPhoneNumber}
                    />
                  )}
                </div>
              </div>
            )}
          </span>
          {tooltips && (
            <Fragment>
              <MeasurementModal
                tooltipIcons={tooltipIcons}
                isOpen={Object.keys(modalIsOpen).includes(
                  'vehicle-length-modal'
                )}
                onRequestClose={() => {
                  if (
                    Object.keys(modalIsOpen).includes('vehicle-length-modal')
                  ) {
                    closeModal({ type: 'vehicle-length-modal' })
                  }
                }}
                tooltips={tooltips}
                displayLength={Object.keys(modalIsOpen).includes(
                  'vehicle-length-modal'
                )}
              />
              <MeasurementModal
                tooltipIcons={tooltipIcons}
                isOpen={Object.keys(modalIsOpen).includes(
                  'vehicle-height-modal'
                )}
                onRequestClose={() => {
                  if (
                    Object.keys(modalIsOpen).includes('vehicle-height-modal')
                  ) {
                    closeModal({ type: 'vehicle-height-modal' })
                  }
                }}
                tooltips={tooltips}
                displayHeight={Object.keys(modalIsOpen).includes(
                  'vehicle-height-modal'
                )}
              />
            </Fragment>
          )}
        </>
      )}
    </div>
  )
}

VehicleForm.propTypes = {
  clientContactPhoneNumber: string,
  selectedHeightFilter: oneOfType([shape(), bool]),
  selectedLengthFilter: oneOfType([shape(), bool]),
  activeSelection: string.isRequired,
  vehicleOptions: shape().isRequired,
  labels: shape().isRequired,
  language: string.isRequired,
  tooltips: shape().isRequired,
  tooltipIcons: arrayOf(shape()).isRequired,
  modalIsOpen: shape().isRequired,
  openModal: func.isRequired,
  closeModal: func.isRequired,
  handleChange: func.isRequired,
  handleVehicleSelection: func.isRequired,
  formValues: shape().isRequired,
  formErrors: shape().isRequired,
  title: string,
  bestRates: shape()
}

export default VehicleForm
