import yn from 'yn'

const shouldDisplayNoPassengerMessage = ({ crossing, totalPassengers }) => {
  const { formattedResources } = crossing
  return !!(
    yn(process.env.REACT_APP_DISABLE_CROSSINGS_BASED_ON_PASSENGER_COUNTS) &&
    totalPassengers &&
    totalPassengers > +formattedResources?.passenger?.[0]?.freeCapacity
  )
}

export default shouldDisplayNoPassengerMessage
