import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'
// ************ CONSTANTS *************
import appRoutes from '../../configuration/appRoutes'
import { ModifyFlowType } from '../../configuration/constants'
// ************ ACTIONS *************
import {
  setDuplicatePassengerQuantities,
  setReturnAdults,
  setReturnSeniors,
  setReturnChildren,
  setReturnStudents,
  setReturnInfants,
  setReturnPets,
  setAllDepartureGuests,
  setAllReturnGuests,
  setDeparturePets
} from '../../redux/passengerSelections/actions'
import * as UserSelectionActions from '../../redux/userSelections/actions'
import { fetchCrossing } from '../../redux/crossings/actions'
// *********** COMPONENTS ***********
import PassengerQuantities from '../../components/PassengerQuantities/PassengerQuantities'
// ********* SELECTORS **************
import {
  selectActiveRouteForm,
  selectDepartureRoute,
  selectIsReturnTrip,
  selectReturnRoute
} from '../../redux/ferryRouteSelections/selectors'
import {
  selectDepartureAdults,
  selectDepartureSeniors,
  selectDepartureChildren,
  selectDepartureInfants,
  selectDepartureStudents,
  selectDeparturePets,
  selectDuplicatePassengerQuantities,
  selectReturnAdults,
  selectReturnSeniors,
  selectReturnStudents,
  hasSetReturnPassengers
} from '../../redux/passengerSelections/selectors'
import { selectCrossingData } from '../../redux/crossings/selectors'
import { selectLabels } from '../../redux/configuration/selectors'
import {
  selectActiveModifyBooking,
  selectModifyFlowType,
  selectIsVehicleEditable,
  selectContinueButtonOnPassengersPage
} from '../../redux/modifyBooking/selectors'
import { selectUserSelections } from '../../redux/userSelections/selectors'
// *********** UTILITIES ************
import { mergeBestRates } from '../../redux/passengerSelections/utilities'
// *********** STYLES ***************
import '../../styles/scss/components/passengers/passenger-quantities.scss'

const PassengerQuantitiesContainer = ({ handleRouteChange }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const activeRouteForm = useSelector(selectActiveRouteForm)

  const departureRoute = useSelector(selectDepartureRoute)
  const departureAdults = useSelector(selectDepartureAdults)
  const departureSeniors = useSelector(selectDepartureSeniors)
  const departureStudents = useSelector(selectDepartureStudents)
  const departureChildren = useSelector(selectDepartureChildren)
  const departureInfants = useSelector(selectDepartureInfants)
  const departurePets = useSelector(selectDeparturePets)

  const returnAdults = useSelector(selectReturnAdults)
  const returnSeniors = useSelector(selectReturnSeniors)
  const returnStudents = useSelector(selectReturnStudents)

  const returnPassengersSet = useSelector(hasSetReturnPassengers)

  const returnRoute = useSelector(selectReturnRoute)
  const isDuplicatedPassengers = useSelector(selectDuplicatePassengerQuantities)
  const isReturnTrip = useSelector(selectIsReturnTrip)
  const labels = useSelector(selectLabels)

  const crossingData = useSelector(selectCrossingData)

  const activeModifyBooking = useSelector(selectActiveModifyBooking)
  const modifyFlowType = useSelector(selectModifyFlowType)
  const vehicleEditable = useSelector(selectIsVehicleEditable)
  const continueButtonLabel = useSelector(selectContinueButtonOnPassengersPage)
  const userSelections = useSelector(selectUserSelections)

  const departurePricesLoading = isDuplicatedPassengers
    ? crossingData?.departure?.loading || crossingData?.return?.loading
    : crossingData?.departure?.loading
  const returnPricesLoading =
    crossingData && crossingData.return && crossingData?.return?.loading
  const departureBestPrices =
    isReturnTrip && isDuplicatedPassengers
      ? mergeBestRates(
          crossingData?.departure?.passengerBestRates,
          crossingData?.return?.passengerBestRates
        )
      : crossingData?.departure?.passengerBestRates

  const returnBestRates = crossingData?.return?.passengerBestRates

  const departurePassengerTypes =
    departureRoute && !isEmpty(departureRoute.passengerTypes)
      ? departureRoute.passengerTypes
      : []

  const returnPassengerTypes =
    returnRoute && !isEmpty(returnRoute.passengerTypes)
      ? returnRoute.passengerTypes
      : []

  const hasDepartureAdults =
    departureAdults || departureSeniors || departureStudents
  const hasReturnAdults =
    isDuplicatedPassengers || returnAdults || returnSeniors || returnStudents
  const hasRequiredAdults = !!(hasDepartureAdults && hasReturnAdults)

  useEffect(() => {
    if (activeModifyBooking) {
      let samePassengerQuantities = activeModifyBooking?.samePassengersAllTrips
      dispatch(
        UserSelectionActions.setIsSamePassengers(samePassengerQuantities)
      )

      if (activeModifyBooking?.departureGuestCounts) {
        const guestCounts = activeModifyBooking.departureGuestCounts
        dispatch(setAllDepartureGuests(guestCounts))
        dispatch(UserSelectionActions.updateDeparturePassengers(guestCounts))
      }

      if (!samePassengerQuantities && activeModifyBooking?.returnGuestCounts) {
        const returnGuests = activeModifyBooking.returnGuestCounts
        dispatch(setAllReturnGuests(returnGuests))
        dispatch(UserSelectionActions.updateReturnPassengers(returnGuests))
      }

      if (modifyFlowType === ModifyFlowType.DEPARTURE_LOCKED) {
        dispatch(setDuplicatePassengerQuantities(false))
        dispatch(UserSelectionActions.setIsSamePassengers(false))
      } else if (isReturnTrip) {
        dispatch(setDuplicatePassengerQuantities(samePassengerQuantities))
        dispatch(
          UserSelectionActions.setIsSamePassengers(samePassengerQuantities)
        )
      }

      if (activeModifyBooking?.departureRoute?.addOns?.booked) {
        const departurePetAddOns = activeModifyBooking.departureRoute.addOns.booked.find(
          a => a.resourceCode === 'PET'
        )
        setDeparturePets(departurePetAddOns?.amount || 0)
      }

      if (activeModifyBooking?.returnRoute?.addOns?.booked) {
        const returnPetAddOns = activeModifyBooking.returnRoute.addOns.booked.find(
          a => a.resourceCode === 'PET'
        )
        setReturnPets(returnPetAddOns?.amount || 0)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if we landed here from the route selection we do NOT need to fetch crossing data.
    const hasRequiredCrossings = location?.state?.isFromRouteSelection

    if (!hasRequiredCrossings) {
      dispatch(
        fetchCrossing('departure', {
          fetchAllPassengerTypes: true
        })
      )

      if (activeRouteForm === 'RT') {
        dispatch(
          fetchCrossing('return', {
            fetchAllPassengerTypes: true
          })
        )
      }
    }
  }, [location, dispatch, activeRouteForm])

  function handleDuplicateDepartureGuests() {
    dispatch(setReturnAdults(departureAdults ? departureAdults : 0))
    dispatch(setReturnSeniors(departureSeniors ? departureSeniors : 0))
    dispatch(setReturnStudents(departureStudents ? departureStudents : 0))
    dispatch(setReturnChildren(departureChildren ? departureChildren : 0))
    dispatch(setReturnInfants(departureInfants ? departureInfants : 0))
    dispatch(setReturnPets(departurePets ? departurePets : 0))
  }

  const handleNextStep = () => {
    if (
      activeRouteForm === 'RT' &&
      departureRoute &&
      returnRoute &&
      isDuplicatedPassengers
    ) {
      handleDuplicateDepartureGuests()

      try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'analytics-passengers-1',
          details: userSelections
        })
      } catch (err) {
        console.log('Analytics error: ' + err)
      }

      handleRouteChange(appRoutes.vehicle.pathname)
    } else {
      try {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'analytics-passengers-1',
          details: userSelections
        })
      } catch (err) {
        console.log('Analytics error: ' + err)
      }
      if (vehicleEditable) {
        return handleRouteChange(appRoutes.vehicle.pathname)
      } else if (modifyFlowType === ModifyFlowType.DEPARTURE_LOCKED) {
        handleRouteChange(appRoutes.return.pathname)
      } else {
        handleRouteChange(appRoutes.departure.pathname)
      }
    }
  }

  return (
    <PassengerQuantities
      activeRouteForm={activeRouteForm}
      departureRoute={departureRoute}
      returnRoute={returnRoute}
      isDuplicatedPassengers={isDuplicatedPassengers}
      handleDuplicateDepartureGuests={handleDuplicateDepartureGuests}
      returnPassengersSet={returnPassengersSet}
      labels={labels}
      departurePricesLoading={departurePricesLoading}
      returnPricesLoading={returnPricesLoading}
      departureBestPrices={departureBestPrices}
      returnBestRates={returnBestRates}
      departurePassengerTypes={departurePassengerTypes}
      returnPassengerTypes={returnPassengerTypes}
      handleNextStep={handleNextStep}
      hasRequiredAdults={hasRequiredAdults}
      returnPassengersOnly={modifyFlowType === ModifyFlowType.DEPARTURE_LOCKED}
      continueButtonLabel={continueButtonLabel}
      isForcesMember={userSelections.isForcesMember}
    />
  )
}

export default PassengerQuantitiesContainer
