import { handleActions } from 'redux-actions'
import {
  PROMO_FETCH_START,
  PROMO_FETCH_SUCCESS,
  PROMO_FETCH_ERROR,
  SET_PROMO_CODE,
  CLEAR_VALIDATION,
  RESET_PROMO_STATE
} from './constants'

const promoCodeInitialState = {
  loading: false,
  code: '',
  coupons: [],
  valid: null
}

const promoCodeReducer = handleActions(
  {
    [SET_PROMO_CODE]: (state, { payload }) => ({
      ...state,
      code: payload
    }),
    [CLEAR_VALIDATION]: state => ({
      ...state,
      valid: null
    }),
    [PROMO_FETCH_START]: state => {
      return {
        ...state,
        loading: true,
        coupons: null,
        valid: null
      }
    },
    [PROMO_FETCH_SUCCESS]: (state, action) => {
      return {
        ...state,
        loading: false,
        valid: true,
        coupons: action.payload.coupons
      }
    },
    [PROMO_FETCH_ERROR]: (state, action) => {
      return {
        ...state,
        loading: false,
        valid: false
      }
    },
    [RESET_PROMO_STATE]: () => ({
      loading: false,
      code: '',
      coupons: [],
      valid: null
    })
  },
  { ...promoCodeInitialState }
)

export { promoCodeInitialState }
export default promoCodeReducer
