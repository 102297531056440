import React, { Fragment, useState } from 'react'
import { object, bool, func, string, array } from 'prop-types'
import { renderContentValues } from '../../redux/configuration/utilities'
import Counter from '../buttons/Counter'

const KennelQuantitiesCard = ({
  labels,
  content,
  kennels,
  selections,
  accommodationsContent,
  startingFrom,
  confirmButtonText,
  closeButtonText,
  isOneWay,
  onCloseClick,
  onConfirmClick
}) => {
  const kennelsDetails = Object.values(kennels).map(x => x.details)
  const initialState = selections
    ? selections
    : [
        {
          resourceCode: '',
          departure: 0,
          return: 0
        }
      ]
  const [selectedQuantities, setSelectedQuantities] = useState(initialState)

  const getSelectOptions = index => {
    const { resourceCode } = selectedQuantities[index]
    const usedResourceCodes = selectedQuantities.map(x => x.resourceCode)
    const options = kennelsDetails
      .map(details => {
        const key = details.resourceCode

        if (key === resourceCode || !usedResourceCodes.includes(key)) {
          const kennelContent = accommodationsContent?.accommodations[key]

          if (!kennelContent) {
            return null
          }

          return (
            <option key={key} value={key}>
              {kennelContent.title}
            </option>
          )
        } else {
          return null
        }
      })
      .filter(e => !!e)
    return (
      <Fragment>
        <option key="_" value="">
          {`- ${labels.selectSize} -`}
        </option>
        {options}
      </Fragment>
    )
  }

  const handleIncrement = (index, direction) => {
    const newState = [...selectedQuantities]
    if (newState[index].resourceCode) {
      newState[index][direction]++
      setSelectedQuantities(newState)
    }
  }

  const handleDecrement = (index, direction) => {
    const newState = [...selectedQuantities]
    if (newState[index].resourceCode) {
      newState[index][direction]--
      setSelectedQuantities(newState)
    }
  }

  const updateSelect = (index, newCode) => {
    const newState = [...selectedQuantities]
    newState[index].resourceCode = newCode
    setSelectedQuantities(newState)
  }

  const canAddKennelRow = selectedQuantities.length < kennelsDetails.length
  const addKennelRow = () => {
    const newState = [
      ...selectedQuantities,
      { resourceCode: '', departure: 0, return: 0 }
    ]
    setSelectedQuantities(newState)
  }

  const handleConfirmClick = () => {
    const selectionObj = selectedQuantities.reduce(
      (acc, cur) => {
        const details = kennelsDetails.find(
          x => x.resourceCode === cur.resourceCode
        )
        acc.departure[cur.resourceCode] = {
          count: cur.departure,
          details
        }
        acc.return[cur.resourceCode] = {
          count: cur.return,
          details
        }
        return acc
      },
      {
        departure: {},
        return: {}
      }
    )
    onConfirmClick(selectionObj)
  }

  let priceElement = null
  if (startingFrom) {
    priceElement = (
      <div className="extras__card-start pad">
        <div className="extras__card-start-label">{labels.startingFrom}</div>
        <div className="extras__card-start-value">{startingFrom}</div>
        <div className="extras__card-start-label">{labels.perKennel}</div>
      </div>
    )
  }

  const quantitySelectGrid = (
    <div className={`extras__card-kennel-grid-${isOneWay ? 'ow' : 'rt'}`}>
      <div className="extras__card-grid-label">{labels.size}</div>
      <div className="extras__card-grid-label">{labels.departureRoute}</div>
      {!isOneWay && (
        <div className="extras__card-grid-label">{labels.returnRoute}</div>
      )}
      {selectedQuantities.map((selection, index) => {
        const resourceCode = selection.resourceCode
        const curDeparture = selection.departure
        const curReturn = selection.return
        const kennelContent = accommodationsContent.accommodations[resourceCode]
        return (
          <Fragment key={`sel_${index}`}>
            <select
              value={resourceCode}
              onChange={e => updateSelect(index, e.target.value)}
            >
              {getSelectOptions(index)}
            </select>
            <Counter
              value={
                resourceCode && kennels[resourceCode]?.departure
                  ? curDeparture
                  : 'x'
              }
              handleIncrement={() => handleIncrement(index, 'departure')}
              handleDecrement={() => handleDecrement(index, 'departure')}
              disabled={!resourceCode || !kennels[resourceCode]?.departure}
              disableIncrement={
                resourceCode && !kennels[resourceCode]?.departure
              }
              disableDecrement={
                curDeparture === 0 ||
                (resourceCode && !kennels[resourceCode]?.departure)
              }
              size="small"
            />
            {!isOneWay && (
              <Counter
                value={
                  resourceCode && kennels[resourceCode]?.return
                    ? curReturn
                    : 'x'
                }
                handleIncrement={() => handleIncrement(index, 'return')}
                handleDecrement={() => handleDecrement(index, 'return')}
                disabled={!resourceCode || !kennels[resourceCode]?.return}
                disableIncrement={
                  resourceCode && !kennels[resourceCode]?.return
                }
                disableDecrement={
                  curReturn === 0 ||
                  (resourceCode && !kennels[resourceCode]?.return)
                }
                size="small"
              />
            )}
            {!!kennelContent && (
              <div className="extras__card-kennel-grid-subtext">
                {kennelContent.textBlocks[0]}
              </div>
            )}
          </Fragment>
        )
      })}
    </div>
  )

  return (
    <div className="extras__card">
      <div className="extras__card-close" onClick={onCloseClick}>
        {closeButtonText}
      </div>
      <div className="extras__card-content">
        <div>{renderContentValues(content)}</div>
        <div className="extras__card-bottom">{priceElement}</div>
        {quantitySelectGrid}
        <div className="extras__card-grid-label add-btn">
          {canAddKennelRow && (
            <span onClick={addKennelRow}>{labels.extraKennel}</span>
          )}
        </div>
        <button
          className="extras__card-btn-confirm btn btn-primary"
          onClick={handleConfirmClick}
        >
          {confirmButtonText}
        </button>
      </div>
    </div>
  )
}

KennelQuantitiesCard.propTypes = {
  labels: object.isRequired,
  content: array.isRequired,
  kennels: object.isRequired,
  selections: array,
  accommodationsContent: object.isRequired,
  startingFrom: string,
  confirmButtonText: string.isRequired,
  closeButtonText: string.isRequired,
  isOneWay: bool.isRequired,
  onCloseClick: func.isRequired,
  onConfirmClick: func.isRequired
}

export default KennelQuantitiesCard
