import yn from 'yn'
import { PRIOR_SAILING_HOURS } from '../../configuration/constants'

const shouldDisableCrossing = (
  crossing,
  activeVehicleResourceCode,
  activeVehicle,
  totalPassengers
) => {
  const { formattedResources } = crossing
  const crossingVehicles = formattedResources?.vehicles || []

  const filteredVehicles = activeVehicleResourceCode
    ? crossingVehicles.filter(
        vehicle => vehicle.resourceCode === activeVehicleResourceCode
      )
    : crossingVehicles

  const vehiclesAvailable = filteredVehicles.some(vehicle => {
    return activeVehicle?.vehicleLength
      ? +vehicle.totalCapacity - +vehicle.bookedAmount >=
          +activeVehicle?.vehicleLength
      : +vehicle.totalCapacity - +vehicle.bookedAmount > 0
  })

  const disableBasedOnPassenger = !!(
    yn(process.env.REACT_APP_DISABLE_CROSSINGS_BASED_ON_PASSENGER_COUNTS) &&
    totalPassengers &&
    totalPassengers > +formattedResources?.passenger?.[0]?.freeCapacity
  )

  return (
    yn(crossing.isICLocked) ||
    (typeof crossing.hoursToDeparture !== 'undefined' &&
      crossing.hoursToDeparture <= PRIOR_SAILING_HOURS) ||
    (activeVehicleResourceCode && !vehiclesAvailable) ||
    disableBasedOnPassenger
  )
}

export default shouldDisableCrossing
