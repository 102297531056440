import { createSelector } from 'reselect'

import { selectActiveAccommodations } from '../activeAccommodations/selectors'
import { selectAvailableKennels } from '../activeCrossings/selectors'

const selectKennelQuantitiesCardState = createSelector(
  selectActiveAccommodations,
  selectAvailableKennels,
  (accommodations, kennels) => {
    const result = []
    const kennelResourceCodes = Object.keys(kennels)

    kennelResourceCodes.forEach(resourceCode => {
      const departureCount = accommodations.departure[resourceCode]?.count || 0
      const returnCount = accommodations.return[resourceCode]?.count || 0

      if (departureCount || returnCount) {
        result.push({
          resourceCode,
          departure: departureCount,
          return: returnCount
        })
      }
    })

    return result.length ? result : null
  }
)

export default selectKennelQuantitiesCardState
