import React from 'react'
import { string, number, shape, oneOfType } from 'prop-types'

import { useSelector } from 'react-redux'
import { selectLabels } from '../../redux/configuration/selectors'
import { formatPriceByLanguage } from '../../redux/configuration/utilities'

import SummaryTableRow from './SummaryTableRow'
import { MobileSummaryBreakdownItem } from './MobileSummaryBreakdown'
import { selectLanguage } from '../../redux/session/selectors'

const SummaryTotal = ({
  headerLabel,
  total,
  taxes,
  activeModifyBooking,
  reservationTotals,
  currencyLabel
}) => {
  const labels = useSelector(selectLabels)
  const lang = useSelector(selectLanguage)

  return (
    <div className="summary-container summary-container__total">
      <div className="summary-container__header theme-font-header">
        <span className="summary-container__header-label">{headerLabel}</span>
      </div>
      <div className="u-md-max">
        <div className="mobile-summary-total-container">
          {taxes && Object.keys(taxes) && Object.keys(taxes).length ? (
            <div className="mobile-summary-trip-breakdown-item">
              <div className="tax-lines">
                {Object.entries(taxes).map(([key, tax], i) => {
                  return (
                    <MobileSummaryBreakdownItem
                      key={key}
                      label={i === 0 ? labels.taxesAndFees : null}
                      value={labels[key]}
                      cost={formatPriceByLanguage(tax, lang)}
                      currencyLabel={currencyLabel}
                    />
                  )
                })}
              </div>
            </div>
          ) : null}

          {activeModifyBooking?.reservationTotals?.paid ? (
            <div className="mobile-summary-modification-totals">
              <div className="mobile-summary-modification-totals-item">
                <p>{labels.tripTotal}</p>
                <p>
                  {formatPriceByLanguage(
                    reservationTotals?.totalWithoutPaid,
                    lang
                  )}{' '}
                  {currencyLabel}
                </p>
              </div>
              <div className="mobile-summary-modification-totals-item">
                <p>{labels.paidAmount}</p>
                <p>
                  {formatPriceByLanguage(reservationTotals?.paid, lang)}{' '}
                  {currencyLabel}
                </p>
              </div>
              <div className="mobile-summary-modification-totals-item balance-due">
                <p>{labels.balanceDue}</p>
                <p>
                  {formatPriceByLanguage(reservationTotals?.total, lang)}{' '}
                  {currencyLabel}
                </p>
              </div>
            </div>
          ) : (
            <div className="mobile-summary-trip-total">
              <p>{labels.tripTotal}</p>
              <p>
                {formatPriceByLanguage(total, lang)} {currencyLabel}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="u-md-up">
        <table
          className="summary-table summary-table__total"
          cellSpacing="0"
          cellPadding="0"
        >
          <tbody>
            {taxes && Object.keys(taxes).length
              ? Object.entries(taxes).map(([key, amount], i) => {
                  let className =
                    Object.keys(taxes).length === 1 || i === 0
                      ? 'first-item'
                      : i + 1 === Object.keys(taxes).length
                      ? 'last-item'
                      : ''

                  return (
                    <SummaryTableRow
                      key={i}
                      label={i === 0 ? `${labels.totalTaxesFees}` : null}
                      value={labels[key]}
                      rowClassName={`tax-line ${className}`}
                      cost={formatPriceByLanguage(amount, lang)}
                      currencyLabel={currencyLabel}
                    />
                  )
                })
              : null}

            {activeModifyBooking?.reservationTotals?.paid ? (
              <>
                <SummaryTableRow
                  label={null}
                  value={labels.tripTotal}
                  rowClassName="tax-line first-item "
                  cost={formatPriceByLanguage(
                    reservationTotals?.totalWithoutPaid,
                    lang
                  )}
                  currencyLabel={currencyLabel}
                />
                <SummaryTableRow
                  value={labels.paidAmount}
                  rowClassName="tax-line"
                  cost={formatPriceByLanguage(reservationTotals?.paid, lang)}
                  currencyLabel={currencyLabel}
                />
                <SummaryTableRow
                  label={labels.balanceDue}
                  value={labels.balanceDue}
                  rowClassName="tax-line last-item"
                  cost={formatPriceByLanguage(reservationTotals?.total, lang)}
                  currencyLabel={currencyLabel}
                />
              </>
            ) : (
              <SummaryTableRow
                label={labels.tripTotal}
                rowClassName="total"
                cost={formatPriceByLanguage(total, lang)}
                currencyLabel={currencyLabel}
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

SummaryTotal.propTypes = {
  headerLabel: string,
  total: number,
  taxes: oneOfType([string, shape()]),
  activeModifyBooking: shape(),
  reservationTotals: shape(),
  currencyLabel: string
}

SummaryTotal.defaultProps = {
  currencyLabel: ''
}

export default SummaryTotal
