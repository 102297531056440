import React, { Fragment, useEffect } from 'react'
import { bool, func, shape } from 'prop-types'
import Modal from '../Modal'
import { useSelector, useDispatch } from 'react-redux'
import { selectBookingCancellationState } from '../../redux/modifyBooking/selectors'
import { selectPassengerInformationContent } from '../../redux/configuration/selectors'
import {
  CANCELLED_WITHOUT_REFUND_MSG,
  CANCELLED_AUTH_ERROR_MSG
} from '../../configuration/constants'
import {
  fetchOrCancelBooking,
  cancelBookingByModify
} from '../../redux/modifyBooking/actions'
import BrandLoader from '../BrandLoader/BrandLoader'
import { selectLabels } from '../../redux/configuration/selectors'
import { formatPriceByLanguage } from '../../redux/configuration/utilities'
import { selectLanguage } from '../../redux/session/selectors'
import { renderContentValues } from '../../redux/configuration/utilities'

import '../../styles/scss/components/user-bookings/cancel-booking-modal.scss'

const CancelBookingModal = ({
  isOpen,
  onRequestClose,
  onCancellationComplete,
  booking
}) => {
  const isCancelReturn = booking.cancelMethod === 'modify'
  const bookingCancellationState = useSelector(state =>
    selectBookingCancellationState(booking?.bookingNumber, state)
  )
  const passengerInformationContent = useSelector(
    selectPassengerInformationContent
  )

  let cancelMethod, cancelParam
  if (isCancelReturn) {
    cancelParam = booking
    cancelMethod = cancelBookingByModify
  } else {
    cancelParam = booking.bookingNumber
    cancelMethod = fetchOrCancelBooking
  }

  const labels = useSelector(selectLabels)
  const language = useSelector(selectLanguage)

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      !bookingCancellationState.loading &&
      !bookingCancellationState.error &&
      !bookingCancellationState.data
    ) {
      dispatch(cancelMethod(cancelParam, false))
    }
  }, [
    booking.bookingNumber,
    bookingCancellationState,
    cancelMethod,
    cancelParam,
    dispatch
  ])

  const paidAmount = formatPriceByLanguage(
    bookingCancellationState?.data?.paidAmount,
    language
  )
  const cancellationFee = formatPriceByLanguage(
    bookingCancellationState?.data?.cancellationFee,
    language
  )
  const totalRefund = formatPriceByLanguage(
    bookingCancellationState?.data?.totalRefund,
    language
  )

  const renderError = () => {
    let errorMessage

    // NOTE(ebarrett): Reuse unable to cancel message from modification flow
    if (
      [CANCELLED_WITHOUT_REFUND_MSG, CANCELLED_AUTH_ERROR_MSG].includes(
        bookingCancellationState?.error
      )
    ) {
      const warningBanners = passengerInformationContent.value.warningBanner
      errorMessage = warningBanners?.forcedCancellations?.value
        ? renderContentValues(warningBanners.forcedCancellations.value)
        : labels.forcedCancellation
    } else {
      errorMessage = labels.forcedCancellation
    }

    return (
      <div className="cancel-booking-modal-error-message">{errorMessage}</div>
    )
  }

  const handleCancelButtonClicked = () => {
    dispatch(cancelMethod(cancelParam, true, booking?.ferryId)).then(() => {
      if (onCancellationComplete) {
        onCancellationComplete()
      }
    })
  }

  const renderCancellationBreakdown = () => {
    return (
      <div className="line-items">
        <p>{labels.cancelBookingMessage}</p>
        <p>
          {`${labels.amountPaid}: `}
          {paidAmount}
        </p>
        <p>
          {`${labels.cancellationFee}: `}
          {cancellationFee}
        </p>
        <p>
          {`${labels.refundAppliedAmount}: `}
          {totalRefund}
        </p>
      </div>
    )
  }

  const renderCancelReturnByModifyBreakdown = () => {
    const data = bookingCancellationState.data
    return (
      <div className="line-items">
        <p>{labels.cancelBookingMessage}</p>
        <p>
          {`${labels.amountPaid}: `}
          {formatPriceByLanguage(data.paid, language)}
        </p>
        <p>
          {`${labels.refundAppliedAmount}: `}
          {formatPriceByLanguage(data.total, language)}
        </p>
      </div>
    )
  }

  const renderCancellationModalButtons = () => {
    return (
      <>
        <button
          className="btn btn-primary"
          onClick={handleCancelButtonClicked}
          disabled={bookingCancellationState.loading}
        >
          {labels.cancelBookingBtnText}
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => onRequestClose(false)}
          disabled={bookingCancellationState.loading}
        >
          {labels.dismissCancelBookingBtnText}
        </button>
      </>
    )
  }

  const renderModalBody = () => {
    if (bookingCancellationState.error) {
      return renderError()
    } else if (bookingCancellationState.data) {
      return (
        <Fragment>
          {/* Confirmation message */}
          {bookingCancellationState?.data?.bookingCancelled && (
            <h4>{labels.cancelBookingSuccessMessage}</h4>
          )}
          {/* Error message */}
          {bookingCancellationState?.error?.length ? renderError() : null}

          {/* Breakdown */}
          {!bookingCancellationState.loading &&
            !bookingCancellationState?.data?.bookingCancelled &&
            (isCancelReturn
              ? renderCancelReturnByModifyBreakdown()
              : renderCancellationBreakdown())}

          {/* Footer / buttons */}
          <div className="cancel-booking-modal-footer">
            {!bookingCancellationState.data?.bookingCancelled ? (
              renderCancellationModalButtons()
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => onRequestClose(false)}
                disabled={bookingCancellationState.loading}
              >
                {labels.cancelledContinueBtn}
              </button>
            )}
          </div>
        </Fragment>
      )
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      className="cancel-booking-modal u-container-md"
    >
      <div className="cancel-booking-modal-content">
        <div className="cancel-booking-modal-header">
          <h1>
            {labels.cancelBooking}
            {' #'}
            {booking.bookingNumber}
          </h1>
          <button className="btn dismiss" onClick={() => onRequestClose(false)}>
            {labels.close} X
          </button>
        </div>

        {bookingCancellationState.loading && <BrandLoader />}
        <div className="cancel-booking-modal-body">{renderModalBody()}</div>
      </div>
    </Modal>
  )
}

CancelBookingModal.propTypes = {
  isOpen: bool,
  onRequestClose: func.isRequired,
  onCancellationComplete: func,
  booking: shape().isRequired
}

export default CancelBookingModal
