import { handleActions } from 'redux-actions'
import {
  // CUSTOMER REGISTER
  ATTEMPTING_REGISTER,
  RESET_ATTEMPTING_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  RESET_REGISTER_ERROR,
  RESET_REGISTER_FORM,
  // CUSTOMER LOG IN
  ATTEMPTING_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  RESET_LOGIN_FORM,
  // CUSTOMER LOG OUT
  CLEAR_CUSTOMER_ACCOUNT,
  // CUSTOMER GET ACCOUNT
  ATTEMPTING_GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_ERROR,
  // CUSTOMER UPDATE
  ATTEMPTING_UPDATE,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  RESET_UPDATE_ERROR,
  RESET_UPDATE_SUCCESS,
  // SEND RESET PASSWORD EMAIL
  ATTEMPTING_PASSWORD_RESET_EMAIL,
  PASSWORD_RESET_EMAIL_SUCCESS,
  PASSWORD_RESET_EMAIL_ERROR,
  RESET_PASSWORD_RESET_EMAIL_ERROR,
  RESET_PASSWORD_RESET_EMAIL_SUCCESS,
  // RESET PASSWORD API SUBMIT
  ATTEMPTING_PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  RESET_PASSWORD_RESET_ERROR,
  RESET_PASSWORD_RESET_SUCCESS,
  // SEND BOOKING DETAILS EMAIL
  ATTEMPTING_SEND_EMAIL_CONFIRMATION,
  SEND_EMAIL_CONFIRMATION_SUCCESS,
  SEND_EMAIL_CONFIRMATION_ERROR,
  RESET_SEND_EMAIL_CONFIRMATION,
  COPASSENGERS_RECEIVED,
  USER_LOGIN_KEY,
  // FIND RESERVATION
  FIND_USER_RESERVATION,
  FIND_RESERVATION_SUCCESS,
  FIND_RESERVATION_ERROR,
  FIND_RESERVATION_RESET
} from './constants'

const userInitialState = {
  // CUSTOMER REGISTER
  attemptingRegister: false,
  registerSuccess: false,
  registerError: false,
  // CUSTOMER LOG IN
  attemptingLogin: false,
  loginSuccess: false,
  loginError: false,
  // CUSTOMER GET ACCOUNT
  customerAccount: false,
  attemptingGetCustomer: false,
  getCustomerError: false,
  // CUSTOMER UPDATE
  attemptingUpdate: false,
  updateSuccess: false,
  updateError: false,
  // SEND RESET PASSWORD EMAIL
  attemptingPasswordResetEmail: false,
  passwordResetEmailSuccess: false,
  passwordResetEmailError: false,
  // RESET PASSWORD API SUBMIT
  attemptingPasswordReset: false,
  passwordResetSuccess: false,
  passwordResetError: false,
  // SEND BOOKING DETAILS EMAIL
  sendingEmailConfirmation: false,
  sendEmailConfirmationSuccess: false,
  sendEmailConfirmationError: false,
  // CUSTOMER CO-PASSENGERS
  coPassengers: [],
  // FIND RESERVATION
  attemptingFindReservation: false,
  findReservationSuccess: false,
  findReservationError: false,
  reservationDetails: null
}

const userReducer = handleActions(
  {
    [ATTEMPTING_REGISTER]: state => ({
      ...state,
      attemptingRegister: true,
      registerSuccess: false,
      registerError: false
    }),
    [REGISTER_SUCCESS]: (state, { payload }) => {
      if (payload?.email) {
        sessionStorage.setItem(USER_LOGIN_KEY, payload.email)
      }
      return {
        ...state,
        attemptingRegister: false,
        registerSuccess: true,
        registerError: false,
        customerAccount: payload
      }
    },
    [REGISTER_ERROR]: (state, { payload }) => ({
      ...state,
      attemptingRegister: false,
      registerSuccess: false,
      registerError: payload
    }),
    [RESET_ATTEMPTING_REGISTER]: state => ({
      ...state,
      attemptingRegister: false
    }),
    [RESET_REGISTER_ERROR]: state => ({
      ...state,
      registerError: false
    }),
    [RESET_REGISTER_FORM]: state => ({
      ...state,
      attemptingRegister: false,
      registerSuccess: false,
      registerError: false
    }),
    [ATTEMPTING_LOGIN]: state => ({
      ...state,
      attemptingLogin: true,
      loginSuccess: false,
      loginError: false
    }),
    [LOGIN_SUCCESS]: (state, { payload }) => {
      if (payload?.email) {
        sessionStorage.setItem(USER_LOGIN_KEY, payload.email)
      }

      return {
        ...state,
        attemptingLogin: false,
        loginSuccess: true, // login status update after success
        loginError: false,
        customerAccount: payload
      }
    },
    [LOGIN_ERROR]: state => ({
      ...state,
      attemptingLogin: false,
      loginSuccess: false,
      loginError: true
    }),
    [RESET_LOGIN_FORM]: state => ({
      ...state,
      attemptingLogin: false,
      loginError: false
    }),
    [ATTEMPTING_GET_CUSTOMER]: state => ({
      ...state,
      attemptingGetCustomer: true,
      getCustomerError: false,
      customerAccount: false
    }),
    [GET_CUSTOMER_SUCCESS]: (state, { payload }) => ({
      ...state,
      attemptingGetCustomer: false,
      getCustomerError: false,
      customerAccount: payload || false
    }),
    [GET_CUSTOMER_ERROR]: state => ({
      ...state,
      attemptingGetCustomer: false,
      getCustomerError: false,
      customerAccount: false
    }),
    [CLEAR_CUSTOMER_ACCOUNT]: state => {
      sessionStorage.removeItem(USER_LOGIN_KEY)
      return {
        ...state,
        customerAccount: false,
        coPassengers: [],
        loginSuccess: false // reset login status after logout
      }
    },
    [ATTEMPTING_UPDATE]: state => ({
      ...state,
      attemptingUpdate: true,
      updateSuccess: false,
      updateError: false
    }),
    [UPDATE_SUCCESS]: (state, { payload }) => {
      if (payload?.email) {
        sessionStorage.setItem(USER_LOGIN_KEY, payload.email)
      }

      return {
        ...state,
        attemptingUpdate: false,
        updateSuccess: true,
        updateError: false,
        customerAccount: payload
      }
    },
    [UPDATE_ERROR]: state => ({
      ...state,
      attemptingUpdate: false,
      updateSuccess: false,
      updateError: true
    }),
    [RESET_UPDATE_ERROR]: state => ({
      ...state,
      updateError: false
    }),
    [RESET_UPDATE_SUCCESS]: state => ({
      ...state,
      updateSuccess: false
    }),
    [ATTEMPTING_PASSWORD_RESET_EMAIL]: state => ({
      ...state,
      attemptingPasswordResetEmail: true,
      passwordResetEmailSuccess: false,
      passwordResetEmailError: false
    }),
    [PASSWORD_RESET_EMAIL_SUCCESS]: (state, { payload }) => ({
      ...state,
      attemptingPasswordResetEmail: false,
      passwordResetEmailSuccess: true
    }),
    [PASSWORD_RESET_EMAIL_ERROR]: state => ({
      ...state,
      attemptingPasswordResetEmail: false,
      passwordResetEmailError: true
    }),
    [RESET_PASSWORD_RESET_EMAIL_ERROR]: state => ({
      ...state,
      passwordResetEmailError: false
    }),
    [RESET_PASSWORD_RESET_EMAIL_SUCCESS]: state => ({
      ...state,
      passwordResetEmailSuccess: false
    }),
    [ATTEMPTING_PASSWORD_RESET]: state => ({
      ...state,
      attemptingPasswordReset: true,
      passwordResetSuccess: false,
      passwordResetError: false
    }),
    [PASSWORD_RESET_SUCCESS]: (state, { payload }) => ({
      ...state,
      attemptingPasswordReset: false,
      passwordResetSuccess: true
    }),
    [PASSWORD_RESET_ERROR]: (state, { payload }) => ({
      ...state,
      attemptingPasswordReset: false,
      passwordResetError: payload
    }),
    [RESET_PASSWORD_RESET_ERROR]: state => ({
      ...state,
      passwordResetError: false
    }),
    [RESET_PASSWORD_RESET_SUCCESS]: state => ({
      ...state,
      passwordResetSuccess: false
    }),
    [RESET_SEND_EMAIL_CONFIRMATION]: state => ({
      ...state,
      sendingEmailConfirmation: false,
      sendEmailConfirmationSuccess: false,
      sendEmailConfirmationError: false
    }),
    [ATTEMPTING_SEND_EMAIL_CONFIRMATION]: state => ({
      ...state,
      sendingEmailConfirmation: true,
      sendEmailConfirmationSuccess: false,
      sendEmailConfirmationError: false
    }),
    [SEND_EMAIL_CONFIRMATION_SUCCESS]: state => ({
      ...state,
      sendingEmailConfirmation: false,
      sendEmailConfirmationSuccess: true
    }),
    [SEND_EMAIL_CONFIRMATION_ERROR]: state => ({
      ...state,
      sendingEmailConfirmation: false,
      sendEmailConfirmationError: true
    }),
    [COPASSENGERS_RECEIVED]: (state, { payload }) => ({
      ...state,
      coPassengers: payload
    }),
    [FIND_USER_RESERVATION]: state => ({
      ...state,
      attemptingFindReservation: true,
      findReservationSuccess: false,
      findReservationError: false
    }),
    [FIND_RESERVATION_SUCCESS]: (state, { payload }) => ({
      ...state,
      findReservationSuccess: true,
      findReservationError: false,
      attemptingFindReservation: false,
      reservationDetails: payload
    }),
    [FIND_RESERVATION_ERROR]: (state, { payload }) => ({
      ...state,
      findReservationError: payload,
      findReservationSuccess: false,
      attemptingFindReservation: false,
      reservationDetails: null
    }),
    [FIND_RESERVATION_RESET]: state => ({
      ...state,
      findReservationError: false,
      findReservationSuccess: false,
      attemptingFindReservation: false,
      reservationDetails: null
    })
  },
  { ...userInitialState }
)

export { userInitialState }
export default userReducer
