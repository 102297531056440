import React from 'react'
import { func, string, shape, bool } from 'prop-types'

import { formatPriceByLanguage } from '../../redux/configuration/utilities'
import { ENABLE_ACCOMMODATIONS } from '../../configuration/constants'

import SummaryTableRow from './SummaryTableRow'
import yn from 'yn'

const SummaryTable = ({
  breakdown,
  handleRouteChange,
  headerLabel,
  headerValue,
  totalLabel,
  labels,
  language,
  editable,
  vehicleEditable,
  currencyLabel
}) => {
  if (!breakdown) return null

  const {
    dateSection,
    passengersSection,
    vehicleSection,
    accommodationsSection,
    discountSection,
    taxesAndFeesSections,
    tripTotal,
    alreadyPaid,
    balanceDue
  } = breakdown

  return (
    <div className="summary-container">
      <div className="summary-container__header theme-font-header">
        <span className="summary-container__header-label">{headerLabel}</span>
        <span className="summary-container__header-value">{headerValue}</span>
      </div>

      <table className="summary-table" cellSpacing="0" cellPadding="0">
        <tbody>
          <SummaryTableRow
            {...dateSection}
            handleRouteChange={handleRouteChange}
            rowClassName="date"
            editable={editable}
            currencyLabel={currencyLabel}
          />
          <SummaryTableRow
            {...passengersSection}
            handleRouteChange={handleRouteChange}
            rowClassName="fares"
            editable={editable}
            currencyLabel={currencyLabel}
          />
          <SummaryTableRow
            {...vehicleSection}
            handleRouteChange={handleRouteChange}
            rowClassName="vehicles"
            editable={vehicleEditable}
            currencyLabel={currencyLabel}
          />
          {ENABLE_ACCOMMODATIONS && (
            <SummaryTableRow
              {...accommodationsSection}
              handleRouteChange={handleRouteChange}
              rowClassName="services"
              editable={editable}
              currencyLabel={currencyLabel}
            />
          )}
          {discountSection && (
            <SummaryTableRow
              {...discountSection}
              cost={`-${discountSection.cost}`}
              editable={editable}
              currencyLabel={currencyLabel}
              iconType={'discount'}
            />
          )}
          {taxesAndFeesSections.map((section, index) => {
            let className = ''
            if (index === 0) {
              className = 'first-item'
            } else if (index + 1 === taxesAndFeesSections.length) {
              className = 'last-item'
            }

            return (
              <SummaryTableRow
                {...section}
                label={index === 0 ? labels.taxesAndFees : null}
                key={index}
                rowClassName={`tax-line ${className}`}
                editable={editable}
                currencyLabel={currencyLabel}
              />
            )
          })}

          {yn(
            process.env
              .REACT_APP_SHOULD_HIDE_PREVIOUS_PAYMENT_LINES_ON_SUMMARY_TRIP_BREAKDOWN
          ) ? (
            <SummaryTableRow
              label={totalLabel}
              rowClassName="total"
              cost={formatPriceByLanguage(tripTotal, language)}
              editable={editable}
              currencyLabel={currencyLabel}
            />
          ) : (
            <>
              {alreadyPaid ? (
                <>
                  <SummaryTableRow
                    label={totalLabel}
                    value={totalLabel}
                    rowClassName="total summary-table__row-tax-line first-item"
                    cost={formatPriceByLanguage(tripTotal, language)}
                    editable={editable}
                    currencyLabel={currencyLabel}
                  />
                  <SummaryTableRow
                    value={labels.paidAmount}
                    rowClassName="total summary-table__row-tax-line "
                    cost={formatPriceByLanguage(alreadyPaid, language)}
                    editable={editable}
                    currencyLabel={currencyLabel}
                  />
                  <SummaryTableRow
                    value={labels.balanceDue}
                    rowClassName="total summary-table__row-tax-line last-item"
                    cost={formatPriceByLanguage(balanceDue, language)}
                    editable={editable}
                    currencyLabel={currencyLabel}
                  />
                </>
              ) : (
                <SummaryTableRow
                  label={totalLabel}
                  rowClassName="total"
                  cost={formatPriceByLanguage(tripTotal, language)}
                  editable={editable}
                  currencyLabel={currencyLabel}
                />
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  )
}

SummaryTable.propTypes = {
  breakdown: shape().isRequired,
  handleRouteChange: func.isRequired,
  headerLabel: string,
  headerValue: string,
  vehicle: string,
  labels: shape().isRequired,
  language: string.isRequired,
  editable: bool,
  vehicleEditable: bool,
  currencyLabel: string
}

SummaryTable.defaultProps = {
  editable: true,
  vehicleEditable: true,
  currencyLabel: ''
}

export default SummaryTable
