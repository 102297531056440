import {
  selectReturnAdults,
  selectReturnSeniors,
  selectReturnStudents,
  selectReturnChildren,
  selectReturnInfants,
  selectReturnPets
} from '../passengerSelections/selectors'
import { createSelector } from 'reselect'

export const selectQueryParamReturnPassengers = createSelector(
  selectReturnAdults,
  selectReturnSeniors,
  selectReturnStudents,
  selectReturnChildren,
  selectReturnInfants,
  selectReturnPets,
  (
    returnAdults,
    returnSeniors,
    returnStudents,
    returnChildren,
    returnInfants,
    returnPets
  ) => ({
    returnAdults,
    returnSeniors,
    returnStudents,
    returnChildren,
    returnInfants,
    returnPets
  })
)
