import moment from 'moment'
import { createSelector } from 'reselect'
import { selectUserSelections } from '../userSelections/selectors'
import { selectConfigurationData } from '../configuration/selectors'
import { selectSessionState } from '../session/selectors'
import cleanObject from '../../utilities/data/cleanObject'

/*
  This selector returns the query params for the fetchCrossings API calls. 
   In the case of a round trip, two calls are made. Only one is
   made for a one-way. The result will be the list of crossings for that particular leg
   of the journey.

  Fetch Crossings from Ferry Routes step requires the following params
    clientId:               string - Defined by env vars
    sessionId:              string - Defined in redux on session data
    currency:               string - Defined in redux on session data
    departureDate:          string - The start of the date range of crossings returned (YYYY-MM-DD)
    returnDate:             string - The end of the date range of crossings returned (YYYY-MM-DD)
    days:                   number - Determined by env vars, not allowing prior to current date
    ticketType:             string - Defined in config on route data,
                                      Found under correct crossing data as 'ticketTypes'
                                      Use the one associated with the tripType
    productCode:            string - Defined in config on route data,
                                      Found under correct crossing data, as 'productCode'
    supplier:               string - Defined in config on route data,
                                      Found under correct crossing data as 'supplierCode'
    yields:                 string - Defined in config on route data as 'yields'
    departureRoute:         string - Defined in config on route data as 'code'
    adults:                 string - # | type code, defined in config on route data (eg '1|A')
    children:               string - # | type code, defined in config on route data (eg '1|C')
    infants:                string - # | type code, defined in config on route data (eg '1|I')
    seniors:                string - # | type code, defined in config on route data (eg '1|S')
    students:               string - # | type code, defined in config on route data (eg '1|ST')
    passengerResourceCode:  string - Defined in config at top-level options (same for all routes)
                                   - Either 'passengerResourceCode' or 'walkOnPassengerResourceCode'
    departureResources:     string - Defined in config at top-level options (same for all routes)
                                   - Passenger, vehicle, and accommodation codes joined by |
                                   - 'accommodationResourceCodes', 'vehicleResourceCodes', passengerResourceCode
    coupon                  string - Coupon applied via the promo code, only if coupon is valid
    accommodationResources: string - '#|code' pairs, comma-delimited.
    vehicleResouceCode:     string - Set in user selections
    vehicleLength:          number - Set in user selections, or pulled from resource record (default)
    vehicleHeight:          number - Set in user selections, or pulled from resource record (default)

    TODO(ebarrett):
      - Not accounting for modify flow
      - Unsure if ENABLE_ACCOMMODATIONS needs to be considered (for CTMA, MAI)
      - Some parts of this might be repeated for later fetchCrossings calls. Can we share functions?
      - Replace magic numbers for getDateRangeParams with constants
      - TESTING! Equivalence is paramount!
*/

const buildFetchCrossingsParams = (
  userSelections,
  config,
  sessionState,
  paramOptions
) => {
  const {
    departureSearchDate,
    departureRoute,
    returnSearchDate,
    returnRoute,
    tripType,
    promoCode,
    isForcesMember,
    departureAccommodations,
    returnAccommodations,
    departureVehicle,
    returnVehicle,
    isSameVehicle,
    departurePassengers,
    returnPassengers,
    isSamePassengers,
    departureCrossing
  } = userSelections
  const {
    fetchAllPassengers,
    includeAccommodations,
    includeVehicle
  } = paramOptions
  const result = {}
  const hasReturn = tripType === 'RT'
  const isUserAccommodations = includeAccommodations === 'user'

  if (!config?.ferry || !departureRoute || (hasReturn && !returnRoute)) {
    return null // Not yet fetched
  }

  const buildParams = (
    searchDate,
    routeCode,
    passengerQuantities,
    vehicle,
    accommodations
  ) => {
    const passengerParamsFunction = fetchAllPassengers
      ? getFetchAllPassengersParams
      : getPassengerParams
    const vehicleParamsFunction = includeVehicle
      ? getVehicleResourceParams
      : () => {}
    const accommodationParamsFunction = includeAccommodations
      ? getAccommodationResourcesParam
      : () => {}

    const params = {
      clientId: process.env.REACT_APP_FLOW_CLIENT_ID,
      sessionId: sessionState.sessionId,
      currency: sessionState.currency,
      ...getDateRangeParams(searchDate),
      ...getRouteDataParams(
        config,
        routeCode,
        tripType,
        isForcesMember,
        promoCode
      ),
      departureResources: getDepartureResourcesParam(config),
      accommodationResources: accommodationParamsFunction(
        config,
        accommodations
      ),
      ...passengerParamsFunction(config, routeCode, passengerQuantities),
      ...vehicleParamsFunction(vehicle)
    }

    return params
  }

  {
    const passengers = departurePassengers
    const vehicle = departureVehicle
    const accommodations = isUserAccommodations ? departureAccommodations : null
    result.departureParams = buildParams(
      departureSearchDate,
      departureRoute,
      passengers,
      vehicle,
      accommodations
    )
  }

  if (hasReturn) {
    const passengers = isSamePassengers ? departurePassengers : returnPassengers
    const vehicle = isSameVehicle ? departureVehicle : returnVehicle
    const accommodations = isUserAccommodations ? returnAccommodations : null
    // Move the search date forward if we selected a departure after the initial search date
    const offsetSearchDate =
      !!departureCrossing?.date && returnSearchDate < departureCrossing.date
    result.returnParams = buildParams(
      offsetSearchDate ? departureCrossing.date : returnSearchDate,
      returnRoute,
      passengers,
      vehicle,
      accommodations
    )
  }

  return result
}

function getDateRangeParams(searchDate) {
  let resultMin
  const daysPrior = process.env.REACT_APP_NUMBER_OF_PRIOR_SAILING_DAYS || 0
  const daysFuture = process.env.REACT_APP_NUMBER_OF_SAILING_DAYS || 5
  const today = moment()
  const searchMin = moment(searchDate).subtract(daysPrior, 'days')
  const searchMax = moment(searchDate).add(daysFuture, 'days')

  if (searchMin.isSameOrBefore(today)) {
    resultMin = today
  } else {
    resultMin = searchMin
  }

  return {
    departureDate: resultMin.format('YYYY-MM-DD'),
    returnDate: searchMax.format('YYYY-MM-DD'),
    days: Math.abs(resultMin.diff(searchMax, 'days'))
  }
}

export function getRouteDataParams(
  config,
  routeCode,
  tripType,
  isForcesMember,
  promoCode
) {
  const {
    routes,
    CFIDPassengerTicketType,
    CFIDBookingProductCode,
    CFIDVehicleTicketCode
  } = config.ferry.options
  const route = routes.find(x => x.code === routeCode)
  if (route?.crossings) {
    const crossing = route.crossings.find(x => x.supplierCode === routeCode)
    const ticketTypeData = crossing.ticketTypes.find(x =>
      x.tripType.startsWith(tripType)
    )
    const result = {
      ticketType: isForcesMember
        ? CFIDPassengerTicketType
        : ticketTypeData.ticketType,
      productCode: isForcesMember
        ? CFIDBookingProductCode
        : crossing.productCode,
      vehicleTicketType: isForcesMember
        ? CFIDVehicleTicketCode
        : ticketTypeData.ticketType,
      supplier: crossing.supplierCode,
      yields: route.yields,
      departureRoute: routeCode
    }

    if (promoCode) {
      result.coupon = promoCode

      const {
        couponBookingProductCode,
        couponBookingTicketType
      } = config.ferry.options

      if (couponBookingProductCode) {
        result.productCode = couponBookingProductCode
      }
      if (couponBookingTicketType) {
        result.ticketType = couponBookingTicketType
      }
    }

    return result
  } else {
    const result = {
      ticketType: null,
      productCode: null,
      vehicleTicketType: null,
      supplier: null,
      yields: null,
      departureRoute: null
    }

    return result
  }
}

/*
  NOTE(ebarrett): This approach is necessary given inconsistencies 
    with what the server expects and what it provides.
*/
function getFetchAllPassengersParams(config, routeCode) {
  const { routes, passengerResourceCode } = config.ferry.options
  const route = routes.find(x => x.code === routeCode)
  if (route?.passengerTypes) {
    const passengerCodeMap = {}
    route.passengerTypes.forEach(type => {
      passengerCodeMap[type.code] = type.type
    })
    return {
      passengerResourceCode,
      adults: `1|${passengerCodeMap.adult || ''}`,
      seniors: `1|${passengerCodeMap.senior || ''}`,
      infants: `1|${passengerCodeMap.infant || ''}`,
      children: `1|${passengerCodeMap.child || ''}`,
      students: `1|${passengerCodeMap.student || ''}`
    }
  } else {
    return {
      passengerResourceCode,
      adults: '1|',
      seniors: '1|',
      infants: '1|',
      children: '1|',
      students: '1|'
    }
  }
}

function getPassengerParams(config, routeCode, quantities) {
  const { routes, passengerResourceCode } = config.ferry.options
  const route = routes.find(x => x.code === routeCode)
  const passengerCodeMap = {}
  route.passengerTypes.forEach(type => {
    passengerCodeMap[type.code] = type.type
  })

  return cleanObject({
    passengerResourceCode,
    adults: quantities.adult
      ? `${quantities.adult}|${passengerCodeMap.adult || ''}`
      : null,
    seniors: quantities.senior
      ? `${quantities.senior}|${passengerCodeMap.senior || ''}`
      : null,
    infants: quantities.infant
      ? `${quantities.infant}|${passengerCodeMap.infant || ''}`
      : null,
    children: quantities.child
      ? `${quantities.child}|${passengerCodeMap.child || ''}`
      : null,
    students: quantities.student
      ? `${quantities.student}|${passengerCodeMap.student || ''}`
      : null
  })
}

function getDepartureResourcesParam(config) {
  const {
    accommodationResourceCodes,
    vehicleResourceCodes,
    passengerResourceCode
  } = config.ferry.options
  const allResourceCodes = []

  allResourceCodes.push(...(accommodationResourceCodes || []))
  allResourceCodes.push(...(vehicleResourceCodes || []))
  allResourceCodes.push(passengerResourceCode) // Walk-on not considered at this stage

  return allResourceCodes.join('|')
}

function getVehicleResourceParams(vehicle) {
  if (!vehicle) {
    return {}
  }

  return {
    vehicleResourceCode: vehicle.code,
    vehicleLength: vehicle.length,
    vehicleHeight: vehicle.height
  }
}

function getAccommodationResourcesParam(config, selectedAccommodations = null) {
  // option to fetch all accommodations for pricing
  const accommodationResourcesCodes = []

  // we either need to fetch *all* codes, or just the current, active codes
  if (!selectedAccommodations) {
    const allAvailableAccommodationResourceCodes =
      config?.ferry?.options?.accommodationResourceCodes || []
    for (const accommodationResourceCode of allAvailableAccommodationResourceCodes) {
      // format is count|code, so for these we just statically set each at 1
      accommodationResourcesCodes.push(`1|${accommodationResourceCode}`)
    }
  } else {
    Object.entries(selectedAccommodations).forEach(([code, data]) => {
      if (+data.count) {
        accommodationResourcesCodes.push(`${data.count}|${code}`)
      }
    })
  }

  const accommodationResources = accommodationResourcesCodes.join(',')
  return accommodationResources
}

/*
  paramOptions used to compose the correct parameters for the /crossing call
    based on stage in the flow. Valid options are....

    fetchAllPassengers:    bool   - ignores selection state, fetches with 1 of each passenger type
    includeVehicle:        bool   - adds vehicle resource params to resulting output
    includeAccommodations: string - adds accommodationResource param to output. either 'all' or 'user'
*/
const createCrossingsParamsSelector = paramOptions => {
  return createSelector(
    selectUserSelections,
    selectConfigurationData,
    selectSessionState,
    () => paramOptions,
    buildFetchCrossingsParams
  )
}

export const selectInitialFetchCrossingsParams = createCrossingsParamsSelector({
  fetchAllPassengers: true,
  includeAccommodations: false,
  includeVehicle: true
})
export const selectFetchCrossingsParamsWithPassengers = createCrossingsParamsSelector(
  { includeVehicle: true }
)
export const selectFetchCrossingsParamsWithVehicle = createCrossingsParamsSelector(
  { includeVehicle: true, includeAccommodations: false }
)
export const selectFetchCrossingsParamsForAccommodations = createCrossingsParamsSelector(
  { includeVehicle: true, includeAccommodations: 'all' }
)
export const selectFetchCrossingsParamsForCrossings = createCrossingsParamsSelector(
  { includeVehicle: true, includeAccommodations: 'all' }
)
