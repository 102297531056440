import React from 'react'
import { arrayOf, bool, func, shape } from 'prop-types'
import Modal from '../Modal'
import { useSelector } from 'react-redux'
import { selectLabels } from '../../redux/configuration/selectors'
import { renderContentValues } from '../../redux/configuration/utilities'

const MeasurementModal = ({
  tooltipIcons,
  tooltips,
  isOpen,
  onRequestClose,
  displayLength,
  displayHeight
}) => {
  const labels = useSelector(selectLabels)
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal--med"
    >
      <div className="vehicle-tooltip">
        <button className="btn btn-close" onClick={onRequestClose}>
          {labels.close} X
        </button>
        {tooltipIcons.map(icon => {
          return (
            <div key={icon.path} className="vehicle-tooltip-icon-container">
              <img src={icon.path} alt={icon.description} role="none" />
            </div>
          )
        })}
        {displayHeight && tooltips.height ? (
          <div className="vehicle-tooltip-section">
            {renderContentValues(tooltips.height.value, 'vehicleHeight')}
          </div>
        ) : null}
        {displayLength && tooltips.length ? (
          <div className="vehicle-tooltip-section">
            {renderContentValues(tooltips.length.value, 'vehicleLength')}
          </div>
        ) : null}
      </div>
    </Modal>
  )
}

MeasurementModal.propTypes = {
  tooltipIcons: arrayOf(shape()).isRequired,
  tooltips: shape().isRequired,
  onRequestClose: func.isRequired,
  isOpen: bool,
  displayLength: bool,
  displayHeight: bool
}

export default MeasurementModal
