import { generateTripDetails } from '../BookingInformation/utilities'

export const preparePreloadChaseData = ({
  ferryId,
  bookingConfirmationData,
  lang,
  labels,
  isTelephoneBooking
}) => {
  let customerId = ''
  let customerEmail = ''
  let result = false

  if (!ferryId || !bookingConfirmationData) return result

  const preloadParams = getPreloadParams({
    ferryId,
    bookingConfirmationData,
    lang,
    labels,
    isTelephoneBooking
  })

  if (!preloadParams || Object.keys(preloadParams).length === 0) return result

  const tripDetails = generateTripDetails({
    ...bookingConfirmationData,
    lang,
    labels
  })

  if (tripDetails?.cust_id) {
    customerId = tripDetails.cust_id
  }

  if (tripDetails?.email) {
    customerEmail = tripDetails?.email
  }

  if (customerId) {
    const phone =
      tripDetails.phone ||
      bookingConfirmationData?.address?.workPhoneNumber.replace(/\D/g, '') ||
      bookingConfirmationData?.address?.mobilePhoneNumber.replace(/\D/g, '') ||
      bookingConfirmationData?.address?.homePhoneNumber.replace(/\D/g, '')

    // prefer home phone
    const merchantPhone =
      bookingConfirmationData?.address?.workPhoneNumber || phone
    result = {
      customerEmail,
      customerId,
      preloadParams: {
        lang: lang === 'fr' ? 'fr_CA' : 'en_US',
        amount: tripDetails.charge_total,
        orderId: '',
        templateUrl: `${process.env.REACT_APP_CHASE_TEMPLATE_PAGE}` || '',
        returnUrl:
          `${process.env.REACT_APP_CHASE_RETURN_PAGE}?lang=${lang}` || '',
        billing: {
          name: tripDetails.bill_name,
          address1: tripDetails.bill_address_one,
          address2: tripDetails.bill_address_two,
          city: tripDetails.bill_city,
          stateProv: tripDetails.bill_state_or_province || '',
          country: tripDetails.bill_country || 'CAN',
          postalCode: tripDetails.bill_postal_code || '',
          email: customerEmail,
          phone: phone
        },
        merchantData1: customerEmail,
        merchantData2: localStorage.getItem('MAI_TOKEN'),
        merchantData3: merchantPhone
      },
      detailsParams: preloadParams
    }
  }

  return result
}

/*
   Internal functions below
*/

function getPreloadParams({
  ferryId = '',
  bookingConfirmationData = {},
  lang = '',
  labels = {},
  isTelephoneBooking
}) {
  let result = {}
  let preloadParams = {}

  const tripDetails = generateTripDetails({
    ...bookingConfirmationData,
    lang,
    labels
  })

  if (
    ferryId &&
    lang &&
    tripDetails?.cust_id &&
    tripDetails?.note &&
    bookingConfirmationData?.reservationTotals?.total
  ) {
    preloadParams = {
      ferryId,
      customerId: tripDetails.cust_id,
      dynamicdescriptor: tripDetails.note,
      language: lang,
      txnTotal: bookingConfirmationData.reservationTotals.total,
      isPhoneBooking: (!!isTelephoneBooking).toString(),
      cart: buildCartParam(tripDetails),
      contactDetails: buildContactDetailsParam(tripDetails),
      shippingDetails: buildLocationDetailsParam(tripDetails),
      billingDetails: buildLocationDetailsParam(tripDetails)
    }
  }

  if (Object.keys(preloadParams).length > 0) {
    result = { params: { ...preloadParams } }
  }

  return result
}

function buildCartParam(tripDetails) {
  const { products, charge_total } = tripDetails
  const tax = getTaxTotal(tripDetails)

  // If the user had a partial payment that exceeds the subtotal
  //  whatever remains should just be marked as tax. The adjustment
  //  is made this way because Moneris will throw an error if provided
  //  a negative subtotal value
  const subtotalAdjusted = Math.max(+charge_total - tax, 0)
  const taxAdjusted = subtotalAdjusted > 0 ? tax : charge_total

  return {
    items: (products || []).map(product => {
      return {
        url: '',
        description: product.description,
        productCode: product.id,
        unitCost: (+product.price / +product.quantity).toFixed(2),
        quantity: (+product.quantity).toFixed(0)
      }
    }),
    subTotal: subtotalAdjusted.toFixed(2),
    tax: {
      amount: taxAdjusted.toString(),
      description: 'Taxes'
    }
  }
}

function getTaxTotal(tripDetails) {
  let result = 0
  for (const taxKey in tripDetails.taxes || {}) {
    result += +tripDetails.taxes[taxKey].subtotal
  }
  return result
}

function buildContactDetailsParam(tripDetails) {
  const [first, last] = (tripDetails.bill_name || '').split(' ')
  return {
    firstName: first || '',
    lastName: last || '',
    email: tripDetails.email || '',
    phone: tripDetails.phone || ''
  }
}

// NOTE(ebarrett): Postal code is being sent as an empty string
//   because Moneris will reject US funds with a Canadian postal code
//  The true source of the data is left as a comment in case
//   this issue is ever resolved
function buildLocationDetailsParam(tripDetails) {
  return {
    address1: tripDetails.bill_address_one || '',
    address2: tripDetails.bill_address_two || '',
    city: tripDetails.bill_city || '',
    province: tripDetails.bill_state_or_province || '',
    country: tripDetails.bill_country || '',
    postalCode: '' //tripDetails.bill_postal_code || ''
  }
}
