import { handleActions } from 'redux-actions'
import {
  SET_ACTIVE_ACCOMMODATION,
  ADD_ACTIVE_ACCOMMODATIONS,
  RESET_ACTIVE_ACCOMMODATION,
  SET_ACTIVE_RETURN_ACCOMMODATIONS,
  SET_ACTIVE_DEPARTURE_ACCOMMODATIONS
} from './constants'

const activeAccommodationsInitialState = {
  departure: {},
  return: {}
}

const activeAccommodationsReducer = handleActions(
  {
    [SET_ACTIVE_ACCOMMODATION]: (state, action) => {
      const accommodationCount = action.payload.count
      let accommodation = {}
      if (accommodationCount > 0) {
        accommodation = {
          ...state[action.payload.direction],
          [action.payload.code]: {
            count: action.payload.count,
            details: action.payload.accommodation,
            waitlist: !!action.payload.waitlist
          }
        }
      } else {
        // properly remove just the now empty item
        accommodation = { ...state[action.payload.direction] }
        if (accommodation[action.payload.code]) {
          delete accommodation[action.payload.code]
        }
      }

      return {
        ...state,
        [action.payload.direction]: accommodation
      }
    },
    [ADD_ACTIVE_ACCOMMODATIONS]: (state, action) => {
      const copy = { ...state }
      copy.departure = {
        ...copy.departure,
        ...action.payload.departure
      }
      copy.return = {
        ...copy.return,
        ...action.payload.return
      }
      return copy
    },
    [SET_ACTIVE_DEPARTURE_ACCOMMODATIONS]: (state, action) => {
      return {
        ...state,
        departure: action.payload
      }
    },
    [SET_ACTIVE_RETURN_ACCOMMODATIONS]: (state, action) => {
      return {
        ...state,
        return: action.payload
      }
    },
    [RESET_ACTIVE_ACCOMMODATION]: () => ({
      ...activeAccommodationsInitialState
    })
  },
  { ...activeAccommodationsInitialState }
)

export { activeAccommodationsInitialState }
export default activeAccommodationsReducer
