import { put, takeEvery, delay } from 'redux-saga/effects'
import { continueToPathname, clearPathname } from './actions'
import { loginFormReset } from '../travelAgent/actions'
import { resetLoginForm } from '../user/actions'
import { formVariants } from '../../components/LoginModal/constants'
import { OPEN, CLOSE, CONTINUE } from './constants'

function* updateRelatedFormState({ type, payload }) {
  if (payload.type === formVariants.CUSTOMER_LOGIN) {
    yield put(resetLoginForm())
  }
  if (payload.type === formVariants.TRAVEL_AGENT_LOGIN) {
    yield put(loginFormReset())
  }
}

function* continueFromForm({ payload }) {
  if (payload.pathname) {
    yield put(continueToPathname(payload.pathname))
  }
  yield delay(500)
  yield put(clearPathname())
}

function* modalSaga() {
  yield takeEvery([OPEN, CLOSE], updateRelatedFormState)
  yield takeEvery(CONTINUE, continueFromForm)
}

export default modalSaga
