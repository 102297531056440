import { createAction } from 'redux-actions'

import {
  SET_DEPARTURE_ADULTS,
  SET_DEPARTURE_SENIORS,
  SET_DEPARTURE_STUDENTS,
  SET_DEPARTURE_CHILDREN,
  SET_DEPARTURE_INFANTS,
  SET_DEPARTURE_PETS,
  SET_DUPLICATE_PASSENGER_QUANTITIES,
  SET_RETURN_ADULTS,
  SET_RETURN_CHILDREN,
  SET_RETURN_INFANTS,
  SET_RETURN_SENIORS,
  SET_RETURN_STUDENTS,
  SET_RETURN_PETS,
  RESET_PASSENGER_SELECTION,
  SET_ALL_DEPARTURE_GUESTS,
  SET_ALL_RETURN_GUESTS
} from './constants'

export const setDepartureAdults = createAction(SET_DEPARTURE_ADULTS)
export const setDepartureSeniors = createAction(SET_DEPARTURE_SENIORS)
export const setDepartureStudents = createAction(SET_DEPARTURE_STUDENTS)
export const setDepartureChildren = createAction(SET_DEPARTURE_CHILDREN)
export const setDepartureInfants = createAction(SET_DEPARTURE_INFANTS)
export const setDeparturePets = createAction(SET_DEPARTURE_PETS)
export const setAllDepartureGuests = createAction(SET_ALL_DEPARTURE_GUESTS)

export const setReturnAdults = createAction(SET_RETURN_ADULTS)
export const setReturnSeniors = createAction(SET_RETURN_SENIORS)
export const setReturnStudents = createAction(SET_RETURN_STUDENTS)
export const setReturnChildren = createAction(SET_RETURN_CHILDREN)
export const setReturnInfants = createAction(SET_RETURN_INFANTS)
export const setReturnPets = createAction(SET_RETURN_PETS)
export const setAllReturnGuests = createAction(SET_ALL_RETURN_GUESTS)

export const setDuplicatePassengerQuantities = createAction(
  SET_DUPLICATE_PASSENGER_QUANTITIES
)

export const resetPassengerSelections = createAction(RESET_PASSENGER_SELECTION)
