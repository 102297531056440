import React from 'react'
import { bool, string } from 'prop-types'
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile-icon-dynamic.svg'
import { ReactComponent as VehicleIcon } from '../../assets/icons/vehicles-icon-dynamic.svg'
import { ReactComponent as NoVehicleIcon } from '../../assets/icons/no-car-dynamic.svg'
import { ReactComponent as TimeIcon } from '../../assets/icons/time-icon-dynamic.svg'
import { ReactComponent as AccommodationsIcon } from '../../assets/icons/accommodations-icon-dynamic.svg'
import { ReactComponent as NoAccommodationsIcon } from '../../assets/icons/no-accommodations-dynamic.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar-icon-dynamic.svg'
import { ReactComponent as ContactIcon } from '../../assets/icons/contact-us-icon-dynamic.svg'
import { ReactComponent as FaresIcon } from '../../assets/icons/fares-dynamic.svg'
import { ReactComponent as RoutesIcon } from '../../assets/icons/routes-icon-dynamic.svg'
import { ReactComponent as PassengerIcon } from '../../assets/icons/passenger-dynamic.svg'
import { ReactComponent as ExtrasIcon } from '../../assets/icons/extras-icon-dynamic.svg'
import { ReactComponent as WarningIcon } from '../../assets/icons/warning-icon-dynamic.svg'
import { ReactComponent as PrevIcon } from '../../assets/icons/prev-slide-dynamic.svg'
import { ReactComponent as NextIcon } from '../../assets/icons/next-slide-dynamic.svg'
import { ReactComponent as EyeOpenIcon } from '../../assets/icons/eye-open.svg'
import { ReactComponent as EyeClosedIcon } from '../../assets/icons/eye-closed.svg'
import { ReactComponent as SortArrowIcon } from '../../assets/icons/sort-arrow-dynamic.svg'
import { ReactComponent as ShipIcon } from '../../assets/icons/ship-dynamic.svg'
import { ReactComponent as SealPercentBlue } from '../../assets/icons/SealPercentBlue.svg'

import themeVariables from '../../styles/scss/configuration/_variables.scss'

const SvgIcon = ({
  type,
  fill,
  width,
  height,
  viewBox,
  className,
  alt,
  allowFocus
}) => {
  let result = null
  let svgProps = { fill, className, alt }

  if (width && height && viewBox) {
    svgProps = {
      ...svgProps,
      width,
      height,
      viewBox
    }
  }

  if (!allowFocus) {
    svgProps.tabIndex = -1
  }

  switch (type) {
    case 'profile': {
      result = <ProfileIcon {...svgProps} />
      break
    }
    case 'passenger': {
      result = <PassengerIcon {...svgProps} />
      break
    }
    case 'vehicle': {
      result = <VehicleIcon {...svgProps} />
      break
    }
    case 'no-vehicle': {
      result = <NoVehicleIcon {...svgProps} />
      break
    }
    case 'time': {
      result = <TimeIcon {...svgProps} />
      break
    }
    case 'accommodations': {
      result = <AccommodationsIcon {...svgProps} />
      break
    }
    case 'no-accommodations': {
      result = <NoAccommodationsIcon {...svgProps} />
      break
    }
    case 'fares': {
      result = <FaresIcon {...svgProps} />
      break
    }
    case 'routes': {
      result = <RoutesIcon {...svgProps} />
      break
    }
    case 'calendar': {
      result = <CalendarIcon {...svgProps} />
      break
    }
    case 'contact': {
      result = <ContactIcon {...svgProps} />
      break
    }
    case 'extras': {
      result = <ExtrasIcon {...svgProps} />
      break
    }
    case 'warning': {
      result = <WarningIcon {...svgProps} />
      break
    }
    case 'next-slide': {
      result = <NextIcon {...svgProps} />
      break
    }
    case 'prev-slide': {
      result = <PrevIcon {...svgProps} />
      break
    }
    case 'eye-open': {
      result = <EyeOpenIcon {...svgProps} />
      break
    }
    case 'sort-arrow': {
      result = <SortArrowIcon {...svgProps} />
      break
    }
    case 'eye-closed': {
      result = <EyeClosedIcon {...svgProps} />
      break
    }
    case 'ship': {
      result = <ShipIcon {...svgProps} />
      break
    }
    case 'discount': {
      return <SealPercentBlue {...svgProps} />
    }
    default: {
      result = null
      break
    }
  }

  return result
}

SvgIcon.propTypes = {
  type: string.isRequired,
  width: string,
  height: string,
  viewBox: string,
  className: string,
  fill: string,
  alt: string,
  allowFocus: bool
}

SvgIcon.defaultProps = {
  fill: themeVariables.themeDisabledIconColor,
  className: '',
  alt: '',
  allowFocus: true
}

export default SvgIcon
