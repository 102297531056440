import { handleActions } from 'redux-actions'
import { SET_RESPONSES, CLEAR_RESPONSES } from './constants'

const accessibilityInitialState = {
  responses: {}
}

const accessibilityReducer = handleActions(
  {
    [SET_RESPONSES]: (state, { payload }) => {
      const copiedState = {
        ...state
      }
      copiedState.responses = payload
      return copiedState
    },
    [CLEAR_RESPONSES]: state => ({
      ...state,
      responses: {}
    })
  },
  { ...accessibilityInitialState }
)

export { accessibilityInitialState }
export default accessibilityReducer
