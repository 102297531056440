import { createSelector } from 'reselect'

export const selectUserState = state => state.userData

export const selectAttemptingRegister = createSelector(
  selectUserState,
  userState => userState.attemptingRegister
)
export const selectRegisterUserSuccess = createSelector(
  selectUserState,
  userState => userState.registerSuccess
)

// note: the registerError string is the label key
export const selectRegisterUserError = createSelector(
  selectUserState,
  userState => userState.registerError
)

export const selectAttemptingLogin = createSelector(
  selectUserState,
  userState => userState.attemptingLogin
)
export const selectLoginSuccess = createSelector(
  selectUserState,
  userState => userState.loginSuccess
)
export const selectLoginError = createSelector(
  selectUserState,
  userState => userState.loginError
)

export const selectAttemptingUpdate = createSelector(
  selectUserState,
  userState => userState.attemptingUpdate
)
export const selectUpdateSuccess = createSelector(
  selectUserState,
  userState => userState.updateSuccess
)
export const selectUpdateError = createSelector(
  selectUserState,
  userState => userState.updateError
)

export const selectCustomerAccount = createSelector(
  selectUserState,
  userState => userState.customerAccount
)

export const selectAttemptingGetCustomer = createSelector(
  selectUserState,
  userState => userState.attemptingGetCustomer
)

export const selectAttemptingPasswordResetEmail = createSelector(
  selectUserState,
  userState => userState.attemptingPasswordResetEmail
)
export const selectPasswordResetEmailSuccess = createSelector(
  selectUserState,
  userState => userState.passwordResetEmailSuccess
)
export const selectPasswordResetEmailError = createSelector(
  selectUserState,
  userState => userState.passwordResetEmailError
)

export const selectAttemptingPasswordReset = createSelector(
  selectUserState,
  userState => userState.attemptingPasswordReset
)
export const selectPasswordResetSuccess = createSelector(
  selectUserState,
  userState => userState.passwordResetSuccess
)
export const selectPasswordResetError = createSelector(
  selectUserState,
  userState => userState.passwordResetError
)

export const selectSendingEmailConfirmation = createSelector(
  selectUserState,
  userState => userState.sendingEmailConfirmation
)
export const selectSendEmailConfirmationSuccess = createSelector(
  selectUserState,
  userState => userState.sendEmailConfirmationSuccess
)
export const selectSendEmailConfirmationError = createSelector(
  selectUserState,
  userState => userState.sendEmailConfirmationError
)

export const selectCoPassengers = createSelector(
  selectUserState,
  userState => userState.coPassengers
)

export const selectFindReservationSuccess = createSelector(
  selectUserState,
  userState => userState.findReservationSuccess
)

export const selectFindReservationError = createSelector(
  selectUserState,
  userState => userState.findReservationError
)

export const selectFindReservationDetails = createSelector(
  selectUserState,
  userState => userState.reservationDetails
)
