import React from 'react'
import { bool, string, func } from 'prop-types'
import { useSelector } from 'react-redux'
import { selectLoginSuccess } from '../../redux/user/selectors'

import './ModifyBookingBanner.scss'

const ModifyBookingBanner = ({
  bookingNumberLabel,
  myAccountLabel,
  onMyAccountClick,
  isDisplayed
}) => {
  const customerLoginSuccess = useSelector(selectLoginSuccess)

  return !isDisplayed ? null : (
    <div className="header-details-modifying-booking-banner">
      <div className="header-details-modifying-booking-banner-content u-container">
        <p>{bookingNumberLabel}</p>
        {customerLoginSuccess && (
          <button className="btn" onClick={onMyAccountClick}>
            {myAccountLabel}
          </button>
        )}
      </div>
    </div>
  )
}

ModifyBookingBanner.propTypes = {
  bookingNumberLabel: string.isRequired,
  myAccountLabel: string.isRequired,
  onMyAccountClick: func.isRequired,
  isDisplayed: bool
}

ModifyBookingBanner.defaultProps = {
  isDisplayed: true
}

export default ModifyBookingBanner
