import React from 'react'
import { node } from 'prop-types'
import SvgIcon from '../SvgIcon/SvgIcon'
import './WarningBanner.scss'

const WarningBanner = ({ children }) => (
  <div className="routes-warning-banner">
    <p>
      <SvgIcon
        type="warning"
        fill="#ffffff"
        className="routes-warning-banner-icon"
      />
      {children}
    </p>
  </div>
)

WarningBanner.propTypes = {
  children: node.isRequired
}

export default WarningBanner
