import { createSelector } from 'reselect'
import { selectLabels } from '../configuration/selectors'
import { selectBookingConfirmationData } from '../booking/selectors'
import { selectSummaryPriceBreakdown } from './selectSummaryPriceBreakdown'

export const selectPaypalCreateOrderParam = createSelector(
  selectBookingConfirmationData,
  selectSummaryPriceBreakdown,
  selectLabels,
  (bookingData, summaryBreakdown, labels) => {
    const currency_code = process.env.REACT_APP_PAYPAL_CURRENCY_CODE || 'CAD'
    const discount = {
      value: 0,
      currency_code
    }
    const items = []

    const shouldAddPrefix =
      summaryBreakdown.departure && summaryBreakdown.return
    const departurePrefix = shouldAddPrefix ? `${labels.departure}: ` : ''
    const returnPrefix = shouldAddPrefix ? `${labels.return}: ` : ''

    _addDirectionBreakdownToPayPalItems(
      summaryBreakdown.departure,
      items,
      discount,
      departurePrefix
    )
    _addDirectionBreakdownToPayPalItems(
      summaryBreakdown.return,
      items,
      discount,
      returnPrefix
    )

    const description = `${labels.booking} #: ${bookingData.bookingNumber}, ${labels.order} ID: ${bookingData.orderId}`

    return {
      purchase_units: [
        {
          amount: {
            value: bookingData.amountOwing,
            currency_code,
            breakdown: {
              item_total: {
                value: (+bookingData.amountOwing + discount.value).toFixed(2),
                currency_code
              },
              discount
            }
          },
          description,
          items,
          reference_id: bookingData.orderId,
          custom_id: bookingData.bookingNumber
        }
      ],
      application_context: {
        shipping_preference: 'NO_SHIPPING'
      }
    }
  }
)

function _addDirectionBreakdownToPayPalItems(
  directionBreakdown,
  items,
  runningDiscount,
  prefix = ''
) {
  if (!directionBreakdown) {
    return
  }

  const currency_code = process.env.REACT_APP_PAYPAL_CURRENCY_CODE || 'CAD'
  const {
    passengersSection,
    vehicleSection,
    accommodationsSection,
    discountSection,
    taxesAndFeesSections,
    alreadyPaid
  } = directionBreakdown

  items.push({
    name: prefix + passengersSection.value,
    quantity: 1,
    unit_amount: {
      value: passengersSection.rawCost.toFixed(2),
      currency_code
    }
  })

  if (vehicleSection?.rawCost > 0) {
    items.push({
      name: prefix + vehicleSection.value,
      quantity: 1,
      unit_amount: {
        value: vehicleSection.rawCost.toFixed(2),
        currency_code
      }
    })
  }

  if (accommodationsSection?.rawCost > 0) {
    items.push({
      name: prefix + accommodationsSection.value,
      quantity: 1,
      unit_amount: {
        value: accommodationsSection.rawCost.toFixed(2),
        currency_code
      }
    })
  }

  taxesAndFeesSections.forEach(feeSection => {
    if (feeSection?.rawCost > 0) {
      items.push({
        name: prefix + feeSection.value,
        quantity: 1,
        unit_amount: {
          value: feeSection.rawCost.toFixed(2),
          currency_code
        }
      })
    }
  })

  if (discountSection?.rawCost > 0) {
    runningDiscount.value += Math.abs(discountSection.rawCost)
  }

  if (+alreadyPaid > 0) {
    runningDiscount.value += +alreadyPaid
  }
}
