import React, { useState, useEffect } from 'react'
import { func, shape, string } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { preloadChaseTransaction } from '../../redux/booking/actions'
import {
  selectPreloadingTransaction,
  selectPreloadTransactionSuccess,
  selectPreloadTransactionError,
  selectPreloadedTicketNumbers
} from '../../redux/booking/selectors'
import { PAYMENT_METHOD_INVOICE } from './constants'
// hard coded labels temporarily as fallback values
import HARD_CODE_LABELS from '../../configuration/labels'
import { preparePreloadChaseData } from './utilities'

const ChaseCheckoutContainer = ({
  bookingConfirmationData,
  ferryId,
  labels,
  lang,
  isTelephoneBooking
}) => {
  const dispatch = useDispatch()

  const preloadingTransaction = useSelector(selectPreloadingTransaction)
  const preloadTransactionError = useSelector(selectPreloadTransactionError)
  const preloadTransactionSuccess = useSelector(selectPreloadTransactionSuccess)
  const preloadedTickets = useSelector(selectPreloadedTicketNumbers)

  const [pageData, setPageData] = useState(null)
  const [bookingPreloaded, setBookingPreloaded] = useState(false)
  const [preloadError, setPreloadError] = useState(false)
  const [awaitingPreload, setAwaitingPreload] = useState(true)
  const [openedPaymentWindow, setOpenedPaymentWindow] = useState(false)

  // Note: Preload success reducer replaces all previous uids with 1 new uid
  const uid = preloadedTickets?.uid

  useEffect(() => {
    if (!pageData?.preloadParams) {
      setPageData(
        preparePreloadChaseData({
          ferryId,
          bookingConfirmationData,
          lang,
          labels,
          isTelephoneBooking
        })
      )
    }
  }, [
    ferryId,
    bookingConfirmationData,
    lang,
    labels,
    isTelephoneBooking,
    pageData
  ])

  const openWindowWithReferrer = (url, data, actionType = 'POST') => {
    const form = document.createElement('form')
    form.method = actionType
    form.action = url
    form.style.display = 'none'
    data.forEach(param => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = param.key
      input.value = param.value
      form.appendChild(input)
    })
    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
  }

  const isInvoicePayment =
    bookingConfirmationData.paymentMethod === PAYMENT_METHOD_INVOICE

  // check preload state
  useEffect(() => {
    if (preloadError || preloadingTransaction || awaitingPreload) return

    if (preloadTransactionError && !preloadTransactionSuccess) {
      setPreloadError(true)
    } else {
      setBookingPreloaded(true)
    }
  }, [
    preloadError,
    preloadingTransaction,
    preloadTransactionError,
    preloadTransactionSuccess,
    awaitingPreload
  ])

  // preload transaction data including ticket number, order
  useEffect(() => {
    if (isInvoicePayment) return
    if (
      !preloadError &&
      !preloadingTransaction &&
      !preloadTransactionSuccess &&
      pageData &&
      !bookingPreloaded
    ) {
      if (pageData?.preloadParams) {
        setAwaitingPreload(false)
        dispatch(
          preloadChaseTransaction(
            { ...pageData.preloadParams },
            bookingConfirmationData.bookingNumber
          )
        )
      } else {
        setPreloadError(true)
      }
    }
  }, [
    isInvoicePayment,
    preloadError,
    preloadingTransaction,
    preloadTransactionSuccess,
    pageData,
    dispatch,
    bookingPreloaded,
    bookingConfirmationData.bookingNumber,
    ferryId
  ])

  const chaseLang = lang === 'fr' ? 'fr_CA' : 'en_US'

  useEffect(() => {
    if (preloadTransactionSuccess && uid && !openedPaymentWindow) {
      setOpenedPaymentWindow(true)
      openWindowWithReferrer(
        `${process.env.REACT_APP_CHASE_HOST}/hpp/?uid=${uid}&lang=${chaseLang}`,
        [
          { key: 'uid', value: uid },
          { key: 'lang', value: chaseLang }
        ],
        'GET'
      )
    }
  }, [preloadTransactionSuccess, uid, openedPaymentWindow]) // eslint-disable-line react-hooks/exhaustive-deps

  // check payment method, if its invoice booking, display message indicating payment not allowed
  if (isInvoicePayment) {
    return (
      <p className="u-page-view-container u-padding-top u-text-center u-error-color">
        {labels.invoicePaymentErrorMsg ||
          HARD_CODE_LABELS[lang].invoicePaymentErrorMsg}
      </p>
    )
  }

  // FIXME: page data error
  if (!pageData) {
    return (
      <p className="u-page-view-container u-padding-top u-text-center u-error-color">
        {labels.pageDataInvalidErroMsg ||
          HARD_CODE_LABELS[lang].pageDataInvalidErroMsg}
      </p>
    )
  }

  return (
    <div className="booking-confirmation">
      <div className="u-container">
        <div className="booking-confirmation-content">
          <div className="padded-container">
            <h2 className="theme-font-header">{labels.bookingConfirmtion}</h2>
            {(preloadError || preloadTransactionError) && (
              <p className="u-text-center" style={{ color: 'red' }}>
                {preloadError || preloadTransactionError}
              </p>
            )}
            <p className="u-text-center">
              {uid ? 'Loading payment portal...' : 'Validating order...'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

ChaseCheckoutContainer.propTypes = {
  bookingConfirmationData: shape().isRequired,
  ferryId: string.isRequired,
  labels: shape().isRequired,
  lang: string.isRequired,
  handleRouteChange: func.isRequired,
  openModal: func.isRequired,
  modalIsOpen: shape().isRequired,
  cancelTransactionRouteTarget: string
}

export default ChaseCheckoutContainer
