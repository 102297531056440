import { createSelector } from 'reselect'

import moment from 'moment'

import { selectCrossingData } from '../crossings/selectors'
import { selectActiveModifyBooking } from '../modifyBooking/selectors'

import { ENABLE_ACCOMMODATIONS } from '../../configuration/constants'
import dateFormats from '../../configuration/dateFormats'

export const selectActiveCrossings = state => state.activeCrossings

export const selectActiveDepartureCrossing = createSelector(
  selectActiveCrossings,
  crossingState => crossingState.departure
)

export const selectActiveReturnCrossing = createSelector(
  selectActiveCrossings,
  crossingState => crossingState.return
)

const selectAvailableAccommodationsInternal = (
  activeCrossings,
  allCrossings,
  activeModifyBooking,
  constraint
) => {
  if (!ENABLE_ACCOMMODATIONS || !activeCrossings) {
    return {}
  }

  // loop activeCrossings, find the matching crossing in allCrossings and use .bookingPrice.bookingPrice.resources.accommodations
  return Object.entries(activeCrossings).reduce(
    (obj, [direction, crossing]) => {
      if (!crossing) return obj

      const dayKey = moment(crossing.departureDate).format(dateFormats.default)
      const crossingsForDay = allCrossings?.[direction]?.crossings?.[dayKey]

      const matchingCrossing = (crossingsForDay || []).find(crossingToTest =>
        isMatchingCrossing(crossing, crossingToTest)
      )

      if (matchingCrossing) {
        const matchingAccommodations =
          matchingCrossing?.bookingPrice?.bookingPrice?.resources
            ?.accommodations

        const matchingAccommodationAvailability =
          matchingCrossing?.formattedResources?.accommodations

        if (
          matchingAccommodations?.length &&
          matchingAccommodationAvailability?.length
        ) {
          for (const matchingAccommodation of matchingAccommodations) {
            // check availability
            const availabilityData = matchingAccommodationAvailability.find(
              accommodation =>
                isMatchingAccommodation(accommodation, matchingAccommodation)
            )

            if (!resourceSatisfiesConstraint(availabilityData, constraint)) {
              continue
            }

            const accommodationWithCapacity = getAccommodationWithCapacity(
              matchingAccommodation,
              crossing,
              availabilityData,
              activeModifyBooking
            )

            obj[matchingAccommodation.resourceCode] = {
              ...(obj[matchingAccommodation.resourceCode] || {}),
              [direction]: accommodationWithCapacity,
              details: matchingAccommodation
            }
          }
        }
      }

      return obj
    },
    {}
  )
}

const createAvailableAccommodationsSelector = constraint =>
  createSelector(
    selectActiveCrossings,
    selectCrossingData,
    selectActiveModifyBooking,
    () => constraint,
    selectAvailableAccommodationsInternal
  )

export const selectAvailableAccommodations = createAvailableAccommodationsSelector(
  null
)
export const selectAvailableKennels = createAvailableAccommodationsSelector({
  type: 'include',
  key: 'seatType',
  values: ['kennel']
})
export const selectAvailableAccommodationsExcludingKennels = createAvailableAccommodationsSelector(
  {
    type: 'exclude',
    key: 'seatType',
    values: ['kennel']
  }
)

// Helper functions
function resourceSatisfiesConstraint(resource, constraint) {
  if (!resource) {
    return false
  } else if (!constraint) {
    return true
  } else {
    const { type, key, values } = constraint
    if (!Array.isArray(values)) {
      if (process.env.REACT_APP_VERB_ENV !== 'production') {
        console.error('Expected constraint values to be an array')
      }
      return true
    }
    switch (type) {
      case 'include':
        return values.includes(resource[key])
      case 'exclude':
        return !values.includes(resource[key])
      default:
        if (process.env.REACT_APP_VERB_ENV !== 'production') {
          console.warn('Unsupported accommodations constraint type ' + type)
        }
        return true
    }
  }
}

function isMatchingCrossing(crossingA, crossingB) {
  return (
    crossingA.departureTime === crossingB.departureTime &&
    crossingA.routeCode === crossingB.routeCode &&
    crossingA.shipCode === crossingB.shipCode
  )
}

function isMatchingAccommodation(accommodationA, accommodationB) {
  return accommodationA.resourceCode === accommodationB.resourceCode
}

function getAccommodationWithCapacity(
  accommodation,
  crossing,
  availabilityData,
  activeModifyBooking
) {
  let freeCapacityOffset = 0

  if (activeModifyBooking) {
    const matchingCrossing = activeModifyBooking.departures.find(x =>
      isMatchingCrossing(crossing, x)
    )
    if (matchingCrossing) {
      const bookedMatchingAccommodations = matchingCrossing.accommodations.booked.filter(
        x => isMatchingAccommodation(accommodation, x)
      )

      freeCapacityOffset += bookedMatchingAccommodations.length
    }
  }

  // freeCapacity might be negative in odd testing situations, but client should be able to
  //   adjust their already booked cabin count in the range [0, freeCapacityOffset]
  const freeCapacity =
    Math.max(+availabilityData.freeCapacity, 0) + freeCapacityOffset

  return {
    ...accommodation,
    ...availabilityData,
    freeCapacity
  }
}
