import { createSelector } from 'reselect'
import { selectActiveCrossingDataRefactored } from './selectActiveCrossingDataRefactored'
import { selectActiveModifyBooking } from '../modifyBooking/selectors'

import { ENABLE_ACCOMMODATIONS } from '../../configuration/constants'
import { getFilteredAccommodations } from './getFilteredAccommodations'

export const selectAvailableKennelsRefactored = createSelector(
  selectActiveCrossingDataRefactored,
  selectActiveModifyBooking,
  (activeCrossings, activeModifyBooking) => {
    if (!ENABLE_ACCOMMODATIONS || !activeCrossings) {
      return {}
    }

    const constraints = {
      type: 'include',
      key: 'seatType',
      values: ['kennel']
    }

    return getFilteredAccommodations(
      activeCrossings,
      activeModifyBooking,
      constraints
    )
  }
)
