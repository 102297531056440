export const mergeBestRates = (departureRates, returnRates) => {
  if (!departureRates || !returnRates) {
    return null
  }

  if (!returnRates) {
    return departureRates
  }
  if (!departureRates) {
    return returnRates
  }

  // we have both (we should except when they are loading)

  const bestRates = { ...departureRates }

  for (const [key, rate] of Object.entries(returnRates)) {
    if (!bestRates[key] || bestRates[key] > rate) {
      bestRates[key] = rate
    }
  }

  return bestRates
}
