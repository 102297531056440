export default function(booking) {
  let customErrorLabelKey = 'incompleteBookingRequiresCall'
  const meetsThreshold =
    +booking.bookingNumber >=
    +process.env.REACT_APP_BROKEN_BOOKING_NUMBER_THRESHOLD
  const paid = booking?.reservationTotals?.paid
  const hasPayment = Boolean(paid && +paid) > 0
  if (meetsThreshold && !hasPayment) {
    customErrorLabelKey = 'incompleteBookingPendingDeletion'
  }
  return customErrorLabelKey
}
