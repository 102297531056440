import React from 'react'
import { func, shape, string, bool, element } from 'prop-types'
import ModalForm from '../ModalForm'

const PaymentErrorModal = ({
  isOpen,
  onCloseButtonClick,
  onContinue,
  onCancel,
  labels,
  continueLabel,
  cancelLabel,
  children,
  suppressOnRequestClose,
  hideCloseButton
}) => {
  const continueButtonText = continueLabel
    ? continueLabel
    : labels.continueModifyingBooking
  const cancelButtonText = cancelLabel ? cancelLabel : labels.cancelBooking
  return (
    <ModalForm
      modalClasses="modal--sm"
      isOpen={isOpen}
      closeModal={!suppressOnRequestClose ? onCloseButtonClick : () => null}
      hideCloseButton={hideCloseButton}
    >
      <div className="modal-row">
        <div className="modal-col modal-question modal-content-padded">
          {children ? (
            children
          ) : (
            <>
              <h4>{labels.transactionCancelled}</h4>
              <p>{labels.genericPaymentErrorMessage}</p>
            </>
          )}

          <div className="modal-question-buttons">
            <button className="btn btn-primary" onClick={onContinue}>
              {continueButtonText}
            </button>
            <button className="btn" onClick={onCancel}>
              {cancelButtonText}
            </button>
          </div>
        </div>
      </div>
    </ModalForm>
  )
}

PaymentErrorModal.propTypes = {
  isOpen: bool,
  onCloseButtonClick: func.isRequired,
  onContinue: func.isRequired,
  onCancel: func.isRequired,
  labels: shape().isRequired,
  continueLabel: string,
  cancelLabel: string,
  suppressOnRequestClose: bool,
  hideCloseButton: bool,
  children: element
}

PaymentErrorModal.defaultProps = {
  isOpen: false,
  suppressOnRequestClose: false,
  hideCloseButton: false,
  children: null
}

export default PaymentErrorModal
