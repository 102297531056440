import { handleActions } from 'redux-actions'
import {
  CROSSING_ERROR,
  FETCH_CROSSING,
  RECEIVE_CROSSINGS,
  RECEIVE_CROSSINGS_UNMODIFIED
} from './constants'

const crossingsInitialState = {
  departure: null,
  return: null,
  responses: {}
}

const crossingsReducer = handleActions(
  {
    [FETCH_CROSSING]: (state, action) => ({
      ...state,
      [action.payload]: {
        loading: true
      }
    }),
    [CROSSING_ERROR]: (state, action) => ({
      ...state,
      [action.payload.direction]: {
        loading: false,
        error: action.payload.error
      }
    }),
    [RECEIVE_CROSSINGS]: (state, action) => ({
      ...state,
      [action.payload.direction]: {
        loading: false,
        crossings: action.payload.crossings,
        resourceBestRates: action.payload.resourceBestRates,
        passengerBestRates: action.payload.passengerBestRates
      }
    }),
    [RECEIVE_CROSSINGS_UNMODIFIED]: (state, action) => {
      const responses = { ...state.responses }
      responses[action.payload.direction] = action.payload.crossings
      return {
        ...state,
        responses
      }
    }
  },
  { ...crossingsInitialState }
)

export { crossingsInitialState }
export default crossingsReducer
