import React, { useState, useEffect, Fragment } from 'react'
import { func, shape } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import { object } from 'yup'
// ************ CONSTANTS *************
import {
  allowAnonymousBookings,
  appRoutes,
  defaultToVehicleOptionsList,
  ENABLE_MONERIS_CHECKOUT
} from '../../configuration/constants'
import {
  passengerTypeOptionMap,
  PHONE_FIELDS
} from '../../redux/configuration/constants'
import { SET_BOOKING_SUCCESS } from '../../redux/booking/constants'
import { formVariants } from '../../components/LoginModal/constants'
import { fieldNames as termsFieldNames } from '../../components/CheckboxWithTitle/constants'
// ************ ACTIONS *************
import {
  postBooking,
  resetSetBooking,
  clearTicketNumbers,
  clearReceipts
} from '../../redux/booking/actions'
import {
  setBookingDetails,
  updatePassengerDetails
} from '../../redux/userSelections/actions'
import {
  getCustomerBookings,
  showNonRefundedModal
} from '../../redux/userBookings/actions'
import { findReservationReset } from '../../redux/user/actions'
// *********** COMPONENTS ***********
import BrandLoader from '../../components/BrandLoader/BrandLoader'
import Notification from '../../components/Notification'
import PageWarningBanner from '../../components/PageWarningBanner/PageWarningBanner'
import SubmitPaymentModal from '../../components/SubmitPaymentModal/SubmitPaymentModal'
import AccountInformationForm from '../../components/AccountInformationForm/AccountInformationForm'
import BookingInformationForm from '../../components/BookingInformationForm/BookingInformationForm'
import LoginFromStep from '../../components/LoginFromStep/LoginFromStep'
import PassengerDetailsForm from '../../components/PassengerDetailsForm/PassengerDetailsForm'
import CheckboxWithTitle from '../../components/CheckboxWithTitle/CheckboxWithTitle'
import TitledCheckboxGroup from '../../components/TitledCheckboxGroup/TitledCheckboxGroup'
// *********** CONTEXT **************

// ********* SELECTORS **************
import {
  selectAttemptingSetBooking,
  selectSetBookingError,
  selectBookingConfirmationData
} from '../../redux/booking/selectors'
import {
  selectLabels,
  selectDefaultProductCode,
  selectCanUseApp,
  selectPassengerInformationContent
} from '../../redux/configuration/selectors'
import {
  selectCustomerAccount,
  selectCoPassengers
} from '../../redux/user/selectors'
import {
  selectDepartureVehicle,
  selectReturnVehicle
} from '../../redux/vehicleSelections/selectors'
import { selectPaymentFerryId } from '../../redux/paymentConfirmation/selectors'
import {
  selectTravelAgentAccount,
  selectTravelAgentBookingParams
} from '../../redux/travelAgent/selectors'
import { selectFields } from '../../redux/configuration/selectors'
import { selectDuplicatePassengerQuantities } from '../../redux/passengerSelections/selectors'
import { selectPassengersForBooking } from '../../redux/selectors/selectPassengersForBooking'
import { selectLanguage } from '../../redux/session/selectors'
import { selectActiveModifyBooking } from '../../redux/modifyBooking/selectors'

// *********** UTILITIES ************
import { buildModifyPassengers } from '../../redux/booking/utilities'
import {
  addDynamicLabelsToOptions,
  formatPhoneNumber
} from '../../configuration/utilities'
import {
  getInitialValuesFromFields,
  filterFieldsByName,
  getSchemaFromFieldDetails
} from '../../redux/configuration/utilities'
import {
  prepareFormBookingData,
  getInitialValuesFromBookingPassengers,
  getPassengerSchemaFromInitialValues,
  mapFormValues,
  addCountryCodeForPassengersFromBookingInfo
} from '../../components/BookingInformation/utilities'
import { getFieldsAndLayout as getPassengerFieldsAndLayout } from '../../components/PassengerDetailsForm/utilities'
import { getFieldsAndLayout as getBookingInfoFieldsAndLayout } from '../../components/BookingInformationForm/utilities'
import { getFormFieldsAndLayout as getAccountInformationFormFieldsAndLayout } from '../../components/AccountInformationForm/utilities'
// *********** STYLES ***************
import '../../styles/scss/components/forms/page-form-defaults.scss'
import '../../styles/scss/components/booking-information/booking-information.scss'
import * as UserSelectionActions from '../../redux/userSelections/actions'
import { setLatestBooking } from '../../redux/session/actions'

const ARRIVE_CAN_URL =
  'https://www.canada.ca/en/public-health/services/diseases/coronavirus-disease-covid-19/arrivecan.html'

/**
 * Passengers Details Page
 */
const BookingInformationContainer = ({
  handleRouteChange,
  openModal,
  closeModal,
  modalIsOpen
}) => {
  const dispatch = useDispatch()
  const labels = useSelector(selectLabels)
  const fields = useSelector(selectFields)

  const travelAgentAccount = useSelector(selectTravelAgentAccount)
  const travelAgentBookingParams = useSelector(selectTravelAgentBookingParams)

  const customerAccount = useSelector(selectCustomerAccount)
  const coPassengers = useSelector(selectCoPassengers)
  const attemptingSetBooking = useSelector(selectAttemptingSetBooking)
  const setBookingError = useSelector(selectSetBookingError)
  const defaultProductCode = useSelector(selectDefaultProductCode)
  const duplicatePassengerQuantities = useSelector(
    selectDuplicatePassengerQuantities
  )

  const lang = useSelector(selectLanguage)

  const bookingConfirmationData = useSelector(selectBookingConfirmationData)
  const canUseApp = useSelector(selectCanUseApp)
  const disabled = attemptingSetBooking || !canUseApp
  const activeModifyBooking = useSelector(selectActiveModifyBooking)
  const departureVehicle = useSelector(selectDepartureVehicle)
  const returnVehicle = useSelector(selectReturnVehicle)
  const passengerFieldDetails = useSelector(selectPassengersForBooking)
  const paymentFerryId = useSelector(selectPaymentFerryId)

  const [passwordMismatchError, setPasswordMismatchError] = useState(false)
  const [
    accountInfoEmailMismatchError,
    setAccountInfoEmailMismatchError
  ] = useState(false)
  const [
    bookingInfoEmailMismatchError,
    setBookingInfoEmailMismatchError
  ] = useState(false)
  const [minPhoneNumberError, setMinPhoneNumberError] = useState(false)
  const [retainedFormValues, setRetainedFormValues] = useState(null)

  const {
    fieldNames: accountInfoFieldNames,
    conditionallyIncludedRequiredFields: conditionalRequiredAccountFields
  } = getAccountInformationFormFieldsAndLayout()

  /*
    From ticket CTM200-452: each field should be max 30 characters.
    However, in the booking itself,
    the max length for the "Name" field
    (which combines the first name and last name) is max 40 characters.
  */
  const paymentModalOpen = Object.keys(modalIsOpen).includes('submit-payment')
  const [nameLengthError, setNameLengthError] = useState(null)
  // Error display top while booking ...
  const passwordError = [
    'passwordTooShort',
    'passwordTooLong',
    'passwordFailedCriteria'
  ].includes(setBookingError)
  const emailError = ['emailAddressAlreadyExists'].includes(setBookingError)
  const emailOrPasswordError = emailError || passwordError
  const errorContent = labels[setBookingError] || labels.anErrorOccured

  const [showDataEntryError, setShowDataEntryError] = useState(false)

  useEffect(() => {
    if (setBookingError && paymentModalOpen) {
      closeModal({ type: 'submit-payment' })
    }
  }, [setBookingError, closeModal, paymentModalOpen])

  useEffect(() => {
    if (setBookingError === '__content:forcedCancellation') {
      dispatch(getCustomerBookings())
      dispatch(showNonRefundedModal(true))
      handleRouteChange(appRoutes.account.pathname)
    }
  }, [setBookingError, dispatch, handleRouteChange])

  // reveal email existance error and scroll down to it
  useEffect(() => {
    if (!emailOrPasswordError) return

    const notificationElement = document.querySelector('.notification-row')
    if (!notificationElement) return
    notificationElement.scrollIntoView()
  }, [setBookingError, emailOrPasswordError, dispatch])

  const hasVehicle = Boolean(
    departureVehicle?.vehicleType?.resourceCode ||
      returnVehicle?.vehicleType?.resourceCode
  )

  const { fields: bookingInfoFieldNames } = getBookingInfoFieldsAndLayout(
    !!customerAccount || !!travelAgentAccount,
    defaultToVehicleOptionsList,
    hasVehicle
  )

  const bookingFieldDetails = filterFieldsByName(fields, bookingInfoFieldNames)
  let initialValuesBookingInfo = getInitialValuesFromFields(bookingFieldDetails)

  // PassengerDetails
  const {
    fieldNames: passengerFormFieldNames,
    conditionallyIncludedRequiredFields: conditionalRequiredPassengerFields,
    conditionalTests: conditionalTestsPassengerFields
  } = getPassengerFieldsAndLayout({ paymentFerryId })

  const sharedPassengerFieldDetails = filterFieldsByName(
    fields,
    passengerFormFieldNames
  )

  const passengerInformationContent = useSelector(
    selectPassengerInformationContent
  )

  const termsContent =
    passengerInformationContent?.value?.checkboxWithHeading?.termsAndConditions
      ?.value
  const warningBanners = passengerInformationContent?.value?.warningBanner
  const renderWarningBanners =
    warningBanners && Object.keys(warningBanners).length > 0

  if (sharedPassengerFieldDetails?.passengerType?.options) {
    const newOptions = addDynamicLabelsToOptions({
      options: sharedPassengerFieldDetails.passengerType.options,
      nameValueMap: passengerTypeOptionMap,
      customLabelSubstring: 'AgeDesc',
      labels
    })

    if (newOptions.length > 0) {
      sharedPassengerFieldDetails.passengerType.options = newOptions
    }
  }

  const initialValuesSharedPassenger = getInitialValuesFromFields(
    sharedPassengerFieldDetails
  )
  const initialValuesPassengerDetails = getInitialValuesFromBookingPassengers(
    passengerFieldDetails,
    initialValuesSharedPassenger,
    activeModifyBooking,
    coPassengers,
    customerAccount?.passengerType
  )

  // Populate booking info for travel agents
  // TODO: move this logic out...
  if (travelAgentAccount) {
    // prefill licensePlateNumber from modify booking if present
    if (
      Object.keys(initialValuesBookingInfo).includes('licensePlateNumber') &&
      activeModifyBooking?.departureVehicleDetails?.licenseNumber
    ) {
      initialValuesBookingInfo = {
        ...initialValuesBookingInfo,
        licensePlateNumber:
          activeModifyBooking.departureVehicleDetails.licenseNumber
      }
    }

    // prefill email fields and prefill/format phone numbers
    initialValuesBookingInfo = mapFormValues({
      initialValues: initialValuesBookingInfo,
      populateWith: travelAgentAccount,
      valueMapping: {
        emailAddress: 'email'
      },
      valueFormatting: {
        homePhoneNumber: value => formatPhoneNumber(value),
        mobilePhoneNumber: value => formatPhoneNumber(value),
        workPhoneNumber: value => formatPhoneNumber(value)
      }
    })
  }

  // Populate booking info from customer account
  // TODO: move this logic out...
  if (customerAccount) {
    initialValuesBookingInfo = mapFormValues({
      initialValues: initialValuesBookingInfo,
      populateWith: customerAccount,
      valueMapping: {
        emailAddress: 'email',
        postalCode: 'postCode'
      },
      valueFormatting: {
        homePhoneNumber: value => formatPhoneNumber(value),
        mobilePhoneNumber: value => formatPhoneNumber(value),
        workPhoneNumber: value => formatPhoneNumber(value)
      }
    })

    /*
      pre populate initial values for first departure passenger with
      same passengerType as customerAccount

      exclude those with names defined, as this may be within the
      modify booking flow, and the matched passenger might have been overridden
    */
    const isCustomerMappedToPassenger =
      Object.values(initialValuesPassengerDetails).findIndex(
        a =>
          a.passengerType === customerAccount.passengerType &&
          a.type === 'departures' &&
          a.firstName === customerAccount.firstName &&
          a.lastName === customerAccount.lastName &&
          a.gender === customerAccount.gender
      ) >= 0

    const departingPassengerMatchingCustomer = Object.values(
      initialValuesPassengerDetails
    ).findIndex(
      a =>
        a.passengerType === customerAccount.passengerType &&
        a.type === 'departures' &&
        !a.firstName
    )

    if (
      !isCustomerMappedToPassenger &&
      departingPassengerMatchingCustomer !== -1
    ) {
      const passengerKeyToMerge = Object.keys(initialValuesPassengerDetails)[
        departingPassengerMatchingCustomer
      ]

      if (
        passengerKeyToMerge &&
        initialValuesPassengerDetails[passengerKeyToMerge]
      ) {
        initialValuesPassengerDetails[passengerKeyToMerge] = {
          ...initialValuesPassengerDetails[passengerKeyToMerge],
          firstName: customerAccount.firstName,
          lastName: customerAccount.lastName,
          gender: customerAccount.gender
        }
        initialValuesPassengerDetails[passengerKeyToMerge].title =
          customerAccount.title
      }
    }
  }

  const validationSchemaBookingInfo = getSchemaFromFieldDetails(
    bookingFieldDetails
  )

  const validationSchemaSharedPassenger = getSchemaFromFieldDetails(
    sharedPassengerFieldDetails,
    conditionalRequiredPassengerFields,
    conditionalTestsPassengerFields
  )
  const validationSchemaPassengerDetails = getPassengerSchemaFromInitialValues(
    initialValuesPassengerDetails,
    validationSchemaSharedPassenger
  )

  // Terms and Conditions
  const consentFieldDetails = filterFieldsByName(fields, termsFieldNames)
  const initialValuesConsent = getInitialValuesFromFields(consentFieldDetails)
  const validationSchemaConsent = getSchemaFromFieldDetails(consentFieldDetails)

  // Form inputs blank values, will be merged with passengersDetails
  const initialValues = {
    passengerDetails: { ...initialValuesPassengerDetails },
    bookingInformation: { ...initialValuesBookingInfo },
    consent: initialValuesConsent.consent,
    accountInformation: null // possibly set later
  }

  let validationSchema = object().shape({
    passengerDetails: validationSchemaPassengerDetails,
    bookingInformation: validationSchemaBookingInfo
  })

  let accountFieldDetails

  validationSchema = validationSchema.concat(validationSchemaConsent)

  // ArriveCAN Consent for CAT - BAY202-162
  const requireArriveCANConsent = false // disable this for the time being, TODO: remove entirely
  const arriveCANConsentFieldDetails = filterFieldsByName(fields, [
    'arriveCANConsent'
  ])
  if (requireArriveCANConsent) {
    const validationSchemaArriveCANConsent = getSchemaFromFieldDetails(
      arriveCANConsentFieldDetails
    )
    initialValues.arriveCANConsent = false
    validationSchema = validationSchema.concat(validationSchemaArriveCANConsent)
  }

  function renderConsentCheckboxes(values, setFieldValue) {
    const defaultConsentElement = (
      <p className="u-remove-margin">
        <strong>I agree</strong> with the terms and conditions
      </p>
    )
    const hasTermsContentConsentElement = (
      <span>
        <a
          href={termsContent[1]?.link || ''}
          title={termsContent[1]?.value || ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          {termsContent[1]?.value || ''}
        </a>

        {` ${termsContent[2]?.value || ''} `}
        <a
          href={termsContent[3]?.link || ''}
          title={termsContent[3]?.value || ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          {termsContent[3]?.value || ''}
        </a>
        {` ${termsContent[4]?.value || ''} `}
        <a
          href={termsContent[5]?.link || ''}
          title={termsContent[5]?.value || ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          {termsContent[5]?.value || ''}
        </a>
        {` ${termsContent[6]?.value || ''}`}
      </span>
    )
    const arriveCANConsentElement = (
      <span>
        <strong>I agree</strong> to use{' '}
        <a target="_blank" rel="noopener noreferrer" href={ARRIVE_CAN_URL}>
          ArriveCAN
        </a>{' '}
        to submit mandatory travel information before and after entry into
        Canada.
      </span>
    )

    if (requireArriveCANConsent) {
      return (
        <TitledCheckboxGroup
          title="Terms & Conditions"
          fieldKeys={['consent', 'arriveCANConsent']}
          fieldLabels={[hasTermsContentConsentElement, arriveCANConsentElement]}
          values={values}
          disabled={disabled}
          fieldDetails={{
            ...consentFieldDetails,
            ...arriveCANConsentFieldDetails
          }}
          setFieldValue={setFieldValue}
        />
      )
    } else if (termsContent) {
      return (
        <CheckboxWithTitle
          fieldKey="consent"
          title={termsContent[0]?.value || ''}
          labels={labels}
          disabled={disabled}
          fieldDetails={consentFieldDetails}
          values={values}
          setFieldValue={setFieldValue}
        >
          {hasTermsContentConsentElement}
        </CheckboxWithTitle>
      )
    } else {
      return (
        <CheckboxWithTitle
          fieldKey="consent"
          title={termsContent?.[0]?.value || labels.termsAndConditions}
          labels={labels}
          disabled={disabled}
          fieldDetails={consentFieldDetails}
          values={values}
          setFieldValue={setFieldValue}
        >
          {defaultConsentElement}
        </CheckboxWithTitle>
      )
    }
  }
  // END ArriveCAN Consent

  // add AccountInformationForm when logged out
  if (!customerAccount && !travelAgentAccount && !allowAnonymousBookings) {
    accountFieldDetails = filterFieldsByName(fields, accountInfoFieldNames)

    // note: Adding ages to passenger type labels dynamically here
    if (accountFieldDetails?.passengerType?.options) {
      const newOptions = addDynamicLabelsToOptions({
        options: accountFieldDetails.passengerType.options,
        nameValueMap: passengerTypeOptionMap,
        customLabelSubstring: 'AgeDesc',
        labels
      })

      if (newOptions.length > 0) {
        accountFieldDetails.passengerType.options = newOptions
      }
    }

    // InitialValues
    const initialValuesAccountInformation = getInitialValuesFromFields(
      accountFieldDetails
    )
    initialValues.accountInformation = { ...initialValuesAccountInformation }

    // Validation Schemas
    const validationSchemaAccountInformation = getSchemaFromFieldDetails(
      accountFieldDetails,
      conditionalRequiredAccountFields
    )
    validationSchema = validationSchema.concat(
      object().shape({
        accountInformation: validationSchemaAccountInformation
      })
    )
  }

  const goToPayment = () => {
    // clear the moneris checkout ticket numbers as they need to be unique on every load
    dispatch(clearTicketNumbers())
    dispatch(clearReceipts())
    handleRouteChange(appRoutes.payment.pathname)
  }

  const handleBookingSuccess = booking => {
    closeModal({ type: 'submit-payment' })
    dispatch(setLatestBooking(booking?.bookingNumber || null))
    if (travelAgentAccount && travelAgentAccount?.accountType === 'INVOICE') {
      /*
        TODO: Add a case here where its a travel agent type invoice,
        in that case dont show moneris or go to bookingSuccess,
        we will go to a new booking success page
      */
      handleRouteChange(appRoutes.account.pathname)
    } else {
      const amountOwing = +booking.amountOwing
      if (amountOwing > 0) {
        goToPayment()
      } else {
        handleRouteChange(appRoutes.bookingSuccess.pathname)
      }
    }
  }

  const handlePayNowConfirmation = (
    values,
    passengerDetails,
    fromSubmitPaymentModal
  ) => {
    let shouldPostBooking = false

    /*
      FIXME(ebarrett): Something broke different passengers on a round trip...
      This hack is in place to fix prod, let's figure out the actual reason though!
    */
    const departurePassengers = []
    const returnPassengers = []
    for (const passengerKey in passengerDetails) {
      const passenger = passengerDetails[passengerKey]
      if (passengerKey.startsWith('departures')) {
        departurePassengers.push(passenger)
      } else {
        returnPassengers.push(passenger)
      }
    }

    values.departures[0] = { passengers: departurePassengers }
    if (values.returns?.length) {
      values.returns[0] = { passengers: returnPassengers }
    }
    // END hack!

    if (bookingConfirmationData?.bookingNumber && !activeModifyBooking) {
      goToPayment()
    } else {
      // When modifying a refund, show submit payment modal before postBooking
      // regardless of payment types available
      const amountOwing = +bookingConfirmationData?.reservationTotals?.total
      if (activeModifyBooking && amountOwing < 0) {
        if (fromSubmitPaymentModal) {
          shouldPostBooking = true
        } else if (!paymentModalOpen) {
          openModal({ type: 'submit-payment' })
        }
      } else {
        shouldPostBooking = true
      }

      if (shouldPostBooking) {
        dispatch(
          postBooking({
            bookingInformation: values,
            commitChanges: true,
            activeModifyBooking,
            modificationId: activeModifyBooking?.bookingNumber,
            modifyPassengers: activeModifyBooking?.bookingNumber
              ? buildModifyPassengers({
                  activeModifyBooking,
                  duplicatePassengerQuantities,
                  fields,
                  passengerFieldDetails,
                  passengerFormData: passengerDetails,
                  paymentFerryId
                })
              : false,
            additionalParams: travelAgentBookingParams
          })
        ).then(res => {
          if (res?.type === SET_BOOKING_SUCCESS) {
            handleBookingSuccess(res.payload)
            // make some redux cleanup after success
            dispatch(findReservationReset())
          }
        })
      }
    }
  } // end of handlePayNowConfirmation

  const handleSubmitForm = formValues => {
    setRetainedFormValues(formValues)
    setPasswordMismatchError(false)
    setMinPhoneNumberError(false)
    setNameLengthError(false)
    dispatch(resetSetBooking())
    let submitError = false

    if (formValues.accountInformation) {
      const {
        password,
        confirmPassword,
        emailAddress,
        emailAddressConfirm
      } = formValues.accountInformation

      // name length error will only be when not logged in.
      // then depends on the "My account contact and address information is the same as my booking information" checkbox
      // aka accountInformation.accountSameAsBooking
      // if checked we need to check the bookingInformation first and last name
      // if NOT checked we need to check the accountInformation first and last name

      if (!customerAccount) {
        let nameToCheck

        if (
          formValues?.accountInformation.accountSameAsBooking &&
          formValues?.bookingInformation?.firstName &&
          formValues?.bookingInformation?.lastName
        ) {
          nameToCheck =
            formValues.bookingInformation.firstName +
            ' ' +
            formValues.bookingInformation.lastName
        }
        if (
          !nameToCheck &&
          formValues?.accountInformation?.firstName &&
          formValues?.accountInformation?.lastName
        ) {
          nameToCheck =
            formValues.accountInformation.firstName +
            ' ' +
            formValues.accountInformation.lastName
        }

        if (nameToCheck?.length && nameToCheck.length >= 40) {
          setNameLengthError(true)
          submitError = true
        }
      }

      if (password !== confirmPassword) {
        submitError = true
        setPasswordMismatchError(true)
      }

      if (
        !customerAccount &&
        !travelAgentAccount &&
        emailAddress !== emailAddressConfirm
      ) {
        submitError = true
        setAccountInfoEmailMismatchError(true)
      }
    }

    if (formValues.bookingInformation) {
      const {
        emailAddress,
        emailAddressConfirm
      } = formValues.bookingInformation

      if (
        !customerAccount &&
        !travelAgentAccount &&
        emailAddress !== emailAddressConfirm
      ) {
        submitError = true
        setBookingInfoEmailMismatchError(true)
      }
      // TODO:
      // update passengers info by adding countryCode from bookingInfomation
      addCountryCodeForPassengersFromBookingInfo(formValues)
    }

    dispatch(setBookingDetails(formValues.bookingInformation)) // TODO: deprecate
    dispatch(
      UserSelectionActions.setBookingDetails(formValues.bookingInformation)
    )
    dispatch(updatePassengerDetails(formValues.passengerDetails))

    if (!submitError) {
      const copiedFormValues = { ...formValues, defaultProductCode }
      const values = prepareFormBookingData(
        copiedFormValues,
        customerAccount,
        duplicatePassengerQuantities
      )

      PHONE_FIELDS.client.forEach(phoneField => {
        if (
          PHONE_FIELDS.requiredStatus[phoneField] === true &&
          values.billingInformation[phoneField] &&
          values.billingInformation[phoneField].length < 10
        ) {
          submitError = true
          setMinPhoneNumberError(true)
        }
      })

      if (!submitError) {
        handlePayNowConfirmation(
          values,
          formValues.passengerDetails,
          formValues.fromSubmitPaymentModal
        )
      }
    }
  } // end of handleSubmitForm

  const generateCtaText = () => {
    let text = labels.checkOutNow

    if (!text) {
      text = lang === 'fr' ? 'Passer à la caisse' : 'Checkout Now'
    }

    return `${text} →`
  }

  const renderInlineError = () => {
    return (
      <div className="row-group-container inline-error u-padding-top">
        <Notification
          type="error"
          message={errorContent}
          onDismiss={() => {
            dispatch(resetSetBooking())
          }}
        >
          {setBookingError === 'emailAddressAlreadyExists' && (
            <button
              type="button"
              className="btn btn-text-only white-text ml-10"
              onClick={() => {
                openModal({ type: formVariants.CUSTOMER_LOGIN })
                setShowDataEntryError(false) // hide error
              }}
            >
              {labels.profileLogin}
            </button>
          )}
        </Notification>
      </div>
    )
  }

  return (
    <div className="page-container step-form-container booking-information-page-container">
      {setBookingError && !emailOrPasswordError ? (
        <div className="row-group-container u-padding-top">
          <Notification
            type="error"
            message={errorContent}
            onDismiss={() => {
              dispatch(resetSetBooking())
            }}
          />
        </div>
      ) : (
        <Fragment>
          {attemptingSetBooking && ENABLE_MONERIS_CHECKOUT ? (
            <BrandLoader />
          ) : (
            <Formik
              initialValues={
                retainedFormValues ? retainedFormValues : { ...initialValues }
              }
              onSubmit={handleSubmitForm}
              validationSchema={validationSchema}
              enableReinitialize
              validateOnMount={true}
            >
              {({ setFieldValue, values, errors }) => {
                const hasErrors = Boolean(errors && Object.keys(errors).length)
                return (
                  <Form>
                    <div className="row-group-container u-page-view-container">
                      <div className="row-group-container-bg">
                        <div className="row-group-container-header no-title">
                          {!customerAccount && !travelAgentAccount && (
                            <LoginFromStep
                              openModal={openModal}
                              labels={labels}
                              loginLabel={`${labels.completeBooking}.`}
                              additionalInfo={
                                <Fragment>
                                  {labels.loginAdditionalInfo}
                                </Fragment>
                              }
                            />
                          )}
                        </div>
                        <PassengerDetailsForm
                          labels={labels}
                          disabled={disabled}
                          fieldDetails={sharedPassengerFieldDetails}
                          values={values.passengerDetails}
                          setFieldValue={setFieldValue}
                          duplicatePassengerQuantities={
                            duplicatePassengerQuantities
                          }
                        />
                        <BookingInformationForm
                          labels={labels}
                          disabled={disabled}
                          fieldDetails={bookingFieldDetails}
                          values={values.bookingInformation}
                          setFieldValue={setFieldValue}
                          emailMismatchError={bookingInfoEmailMismatchError}
                          setEmailMismatchError={
                            setBookingInfoEmailMismatchError
                          }
                          hasVehicle={hasVehicle}
                          signedIn={!!customerAccount}
                        />
                        {emailError && renderInlineError()}
                        {!customerAccount && values.accountInformation && (
                          <AccountInformationForm
                            labels={labels}
                            disabled={disabled}
                            fieldDetails={accountFieldDetails}
                            values={values.accountInformation}
                            setFieldValue={setFieldValue}
                            passwordMismatchError={passwordMismatchError}
                            setPasswordMismatchError={setPasswordMismatchError}
                            emailMismatchError={accountInfoEmailMismatchError}
                            setEmailMismatchError={
                              setAccountInfoEmailMismatchError
                            }
                            nameLengthError={nameLengthError}
                            setNameLengthError={setNameLengthError}
                          />
                        )}
                        {passwordError && renderInlineError()}

                        {renderConsentCheckboxes(values, setFieldValue)}

                        {!renderWarningBanners ? null : (
                          <div className="accommodations-warnings row">
                            {Object.keys(warningBanners).map(
                              (bannerName, bannerIndex) => {
                                const topLevelValue =
                                  warningBanners[bannerName]?.value
                                const label = topLevelValue?.[0]?.value || ''
                                const link = topLevelValue?.[1]?.link || ''
                                const linkLabel =
                                  topLevelValue?.[1]?.value || ''
                                const additionalLabel =
                                  topLevelValue?.[2]?.value || ''
                                const key = `${bannerName}-warningBanner-${bannerIndex}`

                                return !label || !link || !linkLabel ? null : (
                                  <PageWarningBanner message={label} key={key}>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: label
                                      }}
                                    />
                                    <span>
                                      {' '}
                                      <a
                                        href={link}
                                        target={'_blank'}
                                        rel="noreferrer noopener"
                                      >
                                        {linkLabel}
                                      </a>
                                      {` ${additionalLabel}`}
                                    </span>
                                  </PageWarningBanner>
                                )
                              }
                            )}
                          </div>
                        )}
                        {minPhoneNumberError && (
                          <Notification
                            type="error"
                            message={labels.minPhoneNumberError}
                            onDismiss={() => {
                              setMinPhoneNumberError(false)
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="fullpage-submit-row u-page-submit-container">
                      <button
                        type="submit"
                        id="submit-payment"
                        className={`btn btn-primary large-primary-btn ${
                          disabled || hasErrors ? 'disabled' : ''
                        }`}
                        onClick={() => setShowDataEntryError(true)}
                      >
                        {generateCtaText()}
                      </button>

                      {hasErrors &&
                        showDataEntryError &&
                        labels.genericDataEntryError !== '' && (
                          <>
                            <br />
                            <Notification
                              type="error"
                              message={labels.genericDataEntryError}
                              onDismiss={() => {
                                setShowDataEntryError(false)
                              }}
                            />
                          </>
                        )}
                    </div>
                    {paymentModalOpen ? (
                      <SubmitPaymentModal
                        onSubmit={() => {
                          handleSubmitForm({
                            ...values,
                            fromSubmitPaymentModal: true
                          })
                        }}
                        onCloseButtonClick={() => {
                          if (paymentModalOpen) {
                            closeModal({ type: 'submit-payment' })
                          }
                        }}
                        isOpen={paymentModalOpen}
                        labels={labels}
                        disabled={disabled}
                        amountOwing={
                          +bookingConfirmationData?.reservationTotals?.total
                        }
                        activeModifyBooking={activeModifyBooking}
                      />
                    ) : null}
                  </Form>
                )
              }}
            </Formik>
          )}
        </Fragment>
      )}
    </div>
  )
}

BookingInformationContainer.propTypes = {
  handleRouteChange: func.isRequired,
  openModal: func.isRequired,
  closeModal: func.isRequired,
  modalIsOpen: shape().isRequired
}

export default BookingInformationContainer
