import React, { useState, createContext } from 'react'
import { node } from 'prop-types'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  open,
  close,
  continueFromModal,
  setLoginPathName
} from '../redux/modal/actions'
const { Provider, Consumer } = createContext()

const ModalProvider = ({ children }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [modalIsOpen, setModalIsOpen] = useState({})

  const openModal = ({ type, variant, message }) => {
    setModalIsOpen({ ...modalIsOpen, [type]: { type, variant, message } })
    dispatch(open({ type, variant }))
    dispatch(setLoginPathName(location.pathname))
  }

  const closeModal = ({ type, variant }) => {
    const newModalIsOpenState = { ...modalIsOpen }
    if (newModalIsOpenState[type]) delete newModalIsOpenState[type]
    setModalIsOpen(newModalIsOpenState)
    dispatch(close({ type, variant }))
  }

  const modalContinue = ({ type, variant, pathname }) => {
    closeModal({ type, variant })
    dispatch(continueFromModal({ type, variant, pathname }))
  }

  // Allowing opening and closing modals in succession
  const changeModals = ({ openingModal, closingModal }) => {
    const newModalIsOpenState = { ...modalIsOpen }
    const { type, variant, message } = openingModal
    if (newModalIsOpenState[closingModal?.type]) {
      delete newModalIsOpenState[closingModal?.type]
    }
    setModalIsOpen({
      ...newModalIsOpenState,
      [type]: { type, variant, message }
    })
    dispatch(close({ type: closingModal.type, variant: closingModal.variant }))
    dispatch(open({ type, variant }))
  }

  return (
    <Provider
      value={{
        modalIsOpen,
        openModal,
        closeModal,
        modalContinue,
        changeModals
      }}
    >
      {children}
    </Provider>
  )
}

ModalProvider.propTypes = {
  children: node.isRequired
}

export { ModalProvider }
export default Consumer
