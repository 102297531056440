import { formatPriceByLanguage } from '../configuration/utilities'
import { selectActiveReturnCrossing } from '../activeCrossings/selectors'
import { createSelector } from 'reselect'
import {
  selectLabels,
  selectKennelCardContent
} from '../configuration/selectors'
import { selectLanguage } from '../session/selectors'
import { selectAvailableKennelsRefactored } from '../refactor/selectAvailableKennelsRefactored'

export const selectKennelCardDetails = createSelector(
  selectAvailableKennelsRefactored,
  selectLabels,
  selectLanguage,
  selectActiveReturnCrossing,
  selectKennelCardContent,
  (kennels, labels, language, returnCrossing, kennelContent) => {
    let minPrice = Infinity
    Object.values(kennels).forEach(kennel => {
      if (+kennel.details.price < minPrice) {
        minPrice = +kennel.details.price
      }
    })

    if (minPrice === Infinity) {
      return null
    }

    // TODO: Content value below needs to come from server
    return {
      kennels,
      isOneWay: returnCrossing === null,
      startingFrom: formatPriceByLanguage(minPrice, language),
      buttonText: labels.addKennelsBtn, // originally using addKennels
      confirmButtonText: labels.addKennelsBtn, // originally using addKennels
      closeButtonText: `${labels.close} X`,
      content: kennelContent?.value,
      imageUrl: kennelContent?.imageUrl
    }
  }
)
