import { put, takeEvery } from 'redux-saga/effects'
import { startSessionTimer } from './actions'
import { FETCH_ACTIVE_SESSIONS_SUCCESS } from '../configuration/constants'
import { checkCanUseApp } from '../configuration/utilities'

function* startTimerIfCanUseApp({ payload }) {
  if (checkCanUseApp(payload)) {
    yield put(startSessionTimer())
  }
}

function* sessionSaga() {
  yield takeEvery(FETCH_ACTIVE_SESSIONS_SUCCESS, startTimerIfCanUseApp)
}

export default sessionSaga
