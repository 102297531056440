import React, { Fragment } from 'react'
import { shape, func, bool } from 'prop-types'
import { useSelector } from 'react-redux'
import { selectLabels } from '../../../redux/configuration/selectors'
import { SHOW_CUTOFF_MESSAGE } from '../../../configuration/constants'
import SvgIcon from '../../SvgIcon/SvgIcon'
import themeVariables from '../../../styles/scss/configuration/_variables.scss'

// STYLES
import './ShipNameCrossingCard.scss'
import ResourceAvailability from '../ResourceAvailability/ResourceAvailability'

const ShipNameCrossingCard = ({
  accommodationsAvailable,
  crossing,
  isCurrentlySelectedRoute,
  isDisabled,
  isOverNight,
  selectCrossing,
  vehiclesAvailable,
  shouldDisplayNoPassengerMessage,
  isCutOff
}) => {
  const labels = useSelector(selectLabels)

  const renderShipName = () => {
    const shipLabel = labels[crossing.shipCode] || crossing.shipCode
    let prefix,
      rest = ''

    if (shipLabel.startsWith('L’')) {
      ;[prefix, ...rest] = shipLabel.split('’')
      prefix = `${prefix}’`
    } else if (shipLabel.startsWith("L'")) {
      ;[prefix, ...rest] = shipLabel.split("'")
      prefix = `${prefix}'`
    } else if (shipLabel.startsWith('Le ') || shipLabel.startsWith('L’ ')) {
      ;[prefix, ...rest] = shipLabel.split(' ')
    } else {
      return <span className="u-font-italic">{shipLabel}</span>
    }

    return (
      <Fragment>
        <span>{`${prefix}`}</span>
        <span className="u-font-italic">{rest.join(' ')}</span>
      </Fragment>
    )
  }

  return (
    <div
      role={'button'}
      tabIndex={0}
      className={`btn ship-name-crossing-card ${
        isCurrentlySelectedRoute ? 'current-active-route' : ''
      } ${isOverNight ? 'overnight-card' : ''} ${isDisabled ? 'disabled' : ''}`}
      key={`${crossing.departureDate}-${crossing.departureTime}`}
      onClick={() => {
        if (!isDisabled) {
          selectCrossing(crossing)
        }
      }}
      onKeyDown={e => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          if (!isDisabled) {
            selectCrossing(crossing)
          }
        }
      }}
    >
      <div className="header">
        <SvgIcon
          type="ship"
          fill={
            isDisabled
              ? themeVariables.themeDisabledIconColor
              : themeVariables.themeIconColor
          }
        />
        <p className="u-remove-margin theme-font-header">{renderShipName()}</p>
      </div>

      <div
        className={`times ${
          shouldDisplayNoPassengerMessage ? 'has-no-passengers' : ''
        }`}
      >
        <div className="u-flex time">
          <p>{labels.departureTime}</p>
          <p>{crossing.departureTime}</p>
        </div>
        <div className="u-flex time">
          <p>{labels.arrivalTime}</p>
          <p>{crossing.arrivalTime}</p>
        </div>
      </div>
      {isCutOff && SHOW_CUTOFF_MESSAGE && (
        <p className="cutoff-time">{labels.departureBookingCutoffReached}</p>
      )}
      {shouldDisplayNoPassengerMessage && (
        <p className="no-passenger-message">{labels.noPassengerSpace}</p>
      )}
      <ResourceAvailability
        vehiclesAvailable={vehiclesAvailable}
        accommodationsAvailable={accommodationsAvailable}
        isFlex={true}
      />
    </div>
  )
}

ShipNameCrossingCard.propTypes = {
  accommodationsAvailable: bool,
  crossing: shape({}).isRequired,
  isCurrentlySelectedRoute: bool,
  isDisabled: bool,
  isOverNight: bool,
  selectCrossing: func.isRequired,
  vehiclesAvailable: bool,
  isCutOff: bool
}

export default ShipNameCrossingCard
