export const ACCOUNT_CLEAR = 'travelAgent/ACCOUNT_CLEAR'

export const ACCOUNT_GET_LOADING = 'travelAgent/ACCOUNT_GET_LOADING'
export const ACCOUNT_GET_SUCCESS = 'travelAgent/ACCOUNT_GET_SUCCESS'
export const ACCOUNT_GET_ERROR = 'travelAgent/ACCOUNT_GET_ERROR'

export const ACCOUNT_UPDATE_LOADING = 'travelAgent/ACCOUNT_UPDATE_LOADING'
export const ACCOUNT_UPDATE_SUCCESS = 'travelAgent/ACCOUNT_UPDATE_SUCCESS'
export const ACCOUNT_UPDATE_ERROR = 'travelAgent/ACCOUNT_UPDATE_ERROR'

export const ACCOUNT_UPDATE_ERROR_RESET =
  'travelAgent/ACCOUNT_UPDATE_ERROR_RESET'
export const ACCOUNT_UPDATE_SUCCESS_RESET =
  'travelAgent/ACCOUNT_UPDATE_SUCCESS_RESET'

export const AGENT_LOGIN_SESSION_KEY = 'agentUserId'

export const BOOKINGS_GET_LOADING = 'travelAgent/BOOKINGS_GET_LOADING'
export const BOOKINGS_GET_SUCCESS = 'travelAgent/BOOKINGS_GET_SUCCESS'
export const BOOKINGS_GET_ERROR = 'travelAgent/BOOKINGS_GET_ERROR'

export const LOGIN_LOADING = 'travelAgent/LOGIN_LOADING'
export const LOGIN_SUCCESS = 'travelAgent/LOGIN_SUCCESS'
export const LOGIN_ERROR = 'travelAgent/LOGIN_ERROR'
export const LOGIN_FORM_RESET = 'travelAgent/LOGIN_FORM_RESET'
