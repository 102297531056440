import React, { useRef, useEffect } from 'react'
import { bool, shape, string } from 'prop-types'
import { sessionStoragePaymentDataKey } from '../../configuration/constants'
import { generateTripDetails } from './utilities'
import { decorateUrlWithGAParam } from '../../utilities/analytics'

const renderHiddenInput = ({ key, name, value = '' }) => (
  <input readOnly type="hidden" key={key} name={name} value={value} />
)

const MonerisRedirectForm = ({
  labels,
  bookingData,
  lang,
  currencyType,
  isTelephoneBooking
}) => {
  const tripDetails = generateTripDetails({ ...bookingData, lang, labels })
  const { taxes, products, ...restTripDetails } = tripDetails
  let paymentUrl = bookingData?.monerisData.paymentUrl || null

  // Decorate moneris url with GA Linker query parameter for nfl
  if (paymentUrl && process.env.REACT_APP_FLOW_CLIENT_ID === 'nfl') {
    paymentUrl = decorateUrlWithGAParam(paymentUrl)
  }

  const formRef = useRef(null)

  useEffect(() => {
    // we need this after coming back from moneris
    sessionStorage.setItem(
      sessionStoragePaymentDataKey,
      JSON.stringify({
        bookingNumber: bookingData.bookingNumber,
        orderId: bookingData.orderId,
        currencyType,
        isTelephoneBooking,
        lang
      })
    )

    if (formRef.current && paymentUrl) formRef.current.submit()
  })

  let iterator = -1

  return (
    <div className="u-remove-element">
      <form method="POST" action={paymentUrl} ref={formRef}>
        {restTripDetails &&
          Object.keys(restTripDetails).length > 0 &&
          Object.keys(restTripDetails).map(fieldName =>
            renderHiddenInput({
              key: fieldName,
              name: fieldName,
              value: restTripDetails[fieldName]
            })
          )}
        {taxes &&
          Object.keys(taxes).length > 0 &&
          Object.keys(taxes).map(taxKey => (
            <div key={taxKey}>
              {Object.keys(taxes[taxKey]).map(fieldName =>
                renderHiddenInput({
                  key: `${fieldName}${taxKey}`,
                  name: `${fieldName}${taxKey}`,
                  value: taxes[taxKey][fieldName]
                })
              )}
            </div>
          ))}
        {products &&
          products.length > 0 &&
          products.map((product, productIndex) => {
            iterator += 1

            return (
              <div key={`product-${productIndex}`}>
                {Object.keys(product).map(productField => {
                  return renderHiddenInput({
                    key: `${productField}${iterator}`,
                    name: `${productField}${iterator}`,
                    value: product[productField]
                  })
                })}
              </div>
            )
          })}
      </form>
    </div>
  )
}

MonerisRedirectForm.propTypes = {
  labels: shape().isRequired,
  lang: string.isRequired,
  bookingData: shape().isRequired,
  currencyType: string.isRequired,
  isTelephoneBooking: bool
}

MonerisRedirectForm.defaultProps = {
  isTelephoneBooking: false
}

export default MonerisRedirectForm
