import React, { useEffect, useState } from 'react'
import { string, func, shape } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import yn from 'yn'
// ************ CONSTANTS *************
import {
  ENABLE_LOGIN_GATE,
  ENABLE_MONERIS_CHECKOUT,
  ModifyFlowType
} from '../../configuration/constants'
import {
  formVariants,
  modalVariants
} from '../../components/LoginModal/constants'
import modalIds from '../../context/configuration'
import appRoutes from '../../configuration/appRoutes'
import { SET_BOOKING_SUCCESS } from '../../redux/booking/constants'
import generateCustomErrorKey from '../../utilities/bookings/generateCustomErrorKey'
// ************ ACTIONS *************
import {
  postBooking,
  clearTicketNumbers,
  clearReceipts
} from '../../redux/booking/actions'
import {
  getCustomerBookings,
  showNonRefundedModal
} from '../../redux/userBookings/actions'
import {
  clearActiveModifyBooking,
  fetchOrCancelBooking,
  setActiveModificationBooking
} from '../../redux/modifyBooking/actions'
import * as UserSelectionActions from '../../redux/userSelections/actions'
// *********** COMPONENTS ***********
import BrandLoader from '../../components/BrandLoader/BrandLoader'
import CancelTransactionModal from '../../components/CancelTransactionModal/CancelTransactionModal'
import CancelBookingModal from '../../components/UserBookings/CancelBookingModal'
import MobileSummaryBreakdown from '../../components/summary/MobileSummaryBreakdown'
import PaymentErrorModal from '../../components/PaymentErrorModal'
import SummaryTable from '../../components/summary/SummaryTable'
import SummaryTotal from '../../components/summary/SummaryTotal'
import AccessibilitySummary from '../../components/summary/AccessibilitySummary'
// *********** CONTEXT **************
import ModalConsumer from '../../context/modalContext'
// ********* SELECTORS **************
import {
  selectAttemptingSetBooking,
  selectBookingConfirmationData,
  selectSetBookingError
} from '../../redux/booking/selectors'
import {
  selectDepartureRoute,
  selectReturnRoute
} from '../../redux/ferryRouteSelections/selectors'
import {
  selectActiveModifyBooking,
  selectModifyFlowType,
  selectCancelBookingDetails,
  selectIsVehicleEditable
} from '../../redux/modifyBooking/selectors'
import { selectLanguage, selectCurrency } from '../../redux/session/selectors'
import { selectLoginSuccess } from '../../redux/user/selectors'
import { selectCustomerAccount } from '../../redux/user/selectors'
import {
  selectPathToContinueTo,
  selectLoginPathName
} from '../../redux/modal/selectors'
import { selectLabels, selectFields } from '../../redux/configuration/selectors'
import { selectTravelAgentBookingParams } from '../../redux/travelAgent/selectors'
import { selectDuplicatePassengerQuantities } from '../../redux/passengerSelections/selectors'
import { selectPaymentFerryId } from '../../redux/paymentConfirmation/selectors'
// ********** GLOBAL SELECTORS ******
import { selectPassengersForBooking } from '../../redux/selectors/selectPassengersForBooking'
import { selectSummaryPriceBreakdown } from '../../redux/selectors/selectSummaryPriceBreakdown'
// *********** UTILITIES ************
import { buildModifyPassengers } from '../../redux/booking/utilities'
// *********** STYLES ***************
import '../../styles/scss/components/summary/summary.scss'

/**
 * Summary Page after payment
 */
const SummaryContainer = ({
  handleRouteChange,
  openModal,
  closeModal,
  changeModals,
  modalIsOpen,
  searchParams,
  shouldAllowMonerisRetry,
  allowAccountsLinkOnMonerisFailure,
  brokenBookingNumberThreshold,
  clientId
}) => {
  const dispatch = useDispatch()
  const activeDepartureRoute = useSelector(selectDepartureRoute)
  const activeModifyBooking = useSelector(selectActiveModifyBooking)
  const modifyFlowType = useSelector(selectModifyFlowType)
  const activeReturnRoute = useSelector(selectReturnRoute)
  const attemptingSetBooking = useSelector(selectAttemptingSetBooking)
  const bookingData = useSelector(selectBookingConfirmationData)
  const cancelBookingDetails = useSelector(selectCancelBookingDetails)
  const travelAgentBookingParams = useSelector(selectTravelAgentBookingParams)
  const customerAccount = useSelector(selectCustomerAccount)
  const duplicatePassengerQuantities = useSelector(
    selectDuplicatePassengerQuantities
  )
  const paymentFerryId = useSelector(selectPaymentFerryId)
  const fields = useSelector(selectFields)
  const labels = useSelector(selectLabels)
  const language = useSelector(selectLanguage)
  const loginSuccess = useSelector(selectLoginSuccess)
  const pathToContinueTo = useSelector(selectPathToContinueTo)
  const loginPathName = useSelector(selectLoginPathName)
  const passengerFieldDetails = useSelector(selectPassengersForBooking)
  const setBookingError = useSelector(selectSetBookingError)
  const currencyType = useSelector(selectCurrency)

  const [navigatingToPayment, setNavigatingToPayment] = useState(false)

  const bookingDataDeparture = bookingData?.departures?.length
    ? bookingData.departures.find(
        route => route.routeCode === activeDepartureRoute?.code
      )
    : null
  const bookingDataReturn = bookingData?.departures?.length
    ? bookingData.departures.find(
        route => route.routeCode === activeReturnRoute?.code
      )
    : null

  const summaryBreakdown = useSelector(selectSummaryPriceBreakdown)
  const vehicleEditable = useSelector(selectIsVehicleEditable)

  // Our API and moneris take different codes.

  /*
    TODO: write test cases for this
    Given the user cancelled payment in moneris
    When they are redirected to the SummaryContainer
    Then open the cancel-transaction modal

    Given the user payment was rejected in moneris
    When they are redirected to the SummaryContainer
    Then open the payment-error modal
  */
  useEffect(() => {
    if (
      searchParams?.['cancelTXN'] &&
      !Object.keys(modalIsOpen).includes('cancel-transaction')
    ) {
      openModal({ type: 'cancel-transaction' })
    }

    if (
      searchParams.message &&
      !Object.keys(modalIsOpen).includes('payment-error')
    ) {
      openModal({ type: 'payment-error' })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /*
    TODO: write test cases for this
    Given...
  */
  useEffect(() => {
    if (
      !searchParams?.['cancelTXN'] &&
      !searchParams.message &&
      !bookingData?.monerisData
    ) {
      dispatch(
        postBooking({
          bookingInformation: false,
          commitChanges: false,
          activeModifyBooking,
          modificationId: activeModifyBooking?.bookingNumber,
          modifyPassengers: buildModifyPassengers({
            activeModifyBooking,
            duplicatePassengerQuantities,
            fields,
            passengerFieldDetails,
            paymentFerryId
          }),
          additionalParams: travelAgentBookingParams
        })
      )
    }

    try {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'analytics-checkout-1',
        details: bookingData
      })
    } catch (err) {
      console.log('Analytics error: ' + err)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /*
    TODO: write test cases for this
    Given the user successfully logs in from the Summary Container
    Then redirect to the passengerDetails route
    (This is login gate modal functionality)
  */
  useEffect(() => {
    const loginFromThisPage = appRoutes.summary.pathname === loginPathName
    if (loginSuccess && loginFromThisPage) {
      handleRouteChange(appRoutes.passengerDetails.pathname)
    }
  }, [loginSuccess, loginPathName, handleRouteChange])

  /*
    TODO: write test cases for this
    Given the user is presented with the login gate modal on the SummaryContainer
    When they click Continue from the modal
    Then redirect the user to the new route defined in the modal
    (This is login gate modal functionality)
  */
  useEffect(() => {
    if (pathToContinueTo) {
      handleRouteChange(pathToContinueTo)
    }
  }, [pathToContinueTo, handleRouteChange, dispatch])

  const canRetryPayment = Boolean(
    bookingData?.monerisData && yn(shouldAllowMonerisRetry)
  )

  const shouldUseCustomErrorModal = Boolean(
    activeModifyBooking &&
      yn(allowAccountsLinkOnMonerisFailure) &&
      brokenBookingNumberThreshold
  )

  const redirectToAccountsPage = closingModalKey => {
    const customErrorLabelKey = generateCustomErrorKey(activeModifyBooking)
    // go to accountsPage
    changeModals({
      openingModal: { type: modalIds.userBookingCustomErrorModal },
      closingModal: { type: closingModalKey }
    })
    handleRouteChange(appRoutes.account.pathname, {
      showIncompleteBookingModal: true,
      errorKeyToShow: customErrorLabelKey
    })
  }

  const startNewBooking = () => {
    dispatch(fetchOrCancelBooking(bookingData.bookingNumber, true))
    dispatch(clearActiveModifyBooking())
    handleRouteChange(appRoutes.ferryRoutes.pathname)
  }
  /*
     TODO: write test cases for this
     Given...
   */
  const retryPayment = async () => {
    if (ENABLE_MONERIS_CHECKOUT) {
      dispatch(clearTicketNumbers())
      dispatch(clearReceipts())
    }
    handleRouteChange(appRoutes.payment.pathname)
  }

  const postNewBooking = () => {
    const { acceptsContact, address, departures } = bookingData
    const copiedDepartures = departures ? [...departures] : []

    let departurePassengers = []
    let returnPassengers = []

    if (copiedDepartures?.[0]?.passengers?.booked) {
      copiedDepartures[0].passengers.booked.forEach(
        ({
          firstName = '',
          gender = '',
          lastName = '',
          passengerType = ''
        }) => {
          departurePassengers.push({
            firstName,
            gender,
            lastName,
            passengerType
          })
        }
      )
    }

    if (copiedDepartures?.[1]?.passengers?.booked) {
      copiedDepartures[1].passengers.booked.forEach(
        ({
          firstName = '',
          gender = '',
          lastName = '',
          passengerType = ''
        }) => {
          returnPassengers.push({
            firstName,
            gender,
            lastName,
            passengerType
          })
        }
      )
    }

    const newPostBookingParams = {
      activeModifyBooking: null,
      additionalParams: {},
      bookingInformation: {
        acceptsContact,
        billingInformation: {
          acceptsContact: 'false',
          address: address?.address,
          city: address?.city,
          countryCode: address?.countryCode,
          county: address?.county,
          email: address?.email,
          mobilePhoneNumber: address?.mobilePhoneNumber,
          name: address?.name,
          postCode: address?.postCode
        },
        departures: [
          {
            passengers: departurePassengers
          }
        ],
        returns: [
          {
            passengers: returnPassengers
          }
        ]
      },
      commitChanges: true,
      modificationId: undefined,
      modifyPassengers: false
    }

    /*
      Note:
      In create mode if this is an anonymous booking, we might not have this.
      Not a problem for CTMA
    */
    if (customerAccount) {
      newPostBookingParams.bookingInformation.billingInformation.contact =
        customerAccount.contact || ''
      if (customerAccount.gender) {
        newPostBookingParams.bookingInformation.billingInformation.gender =
          customerAccount.gender
      }
      if (customerAccount.language) {
        newPostBookingParams.bookingInformation.billingInformation.language =
          customerAccount.language
      }
      if (customerAccount.passengerType) {
        newPostBookingParams.bookingInformation.billingInformation.passengerType =
          customerAccount.passengerType
      }
      if (customerAccount.consent) {
        newPostBookingParams.bookingInformation.consent =
          customerAccount.consent
      }
    }

    dispatch(postBooking(newPostBookingParams)).then(res => {
      if (res?.type === SET_BOOKING_SUCCESS) {
        handleRouteChange(appRoutes.payment.pathname)
      }
    })
  }

  useEffect(() => {
    if (
      navigatingToPayment &&
      bookingData?.bookingNumber &&
      cancelBookingDetails?.[bookingData.bookingNumber]?.data?.id &&
      !cancelBookingDetails?.[bookingData.bookingNumber]?.loading
    ) {
      setNavigatingToPayment(false)
      postNewBooking()
    }
  }, [bookingData, navigatingToPayment, cancelBookingDetails]) // eslint-disable-line react-hooks/exhaustive-deps

  /*
    TODO: write test cases for this
    Given the user is on the SummaryContainer
    When we are attempting to set a booking
    Then render the BrandLoader
  */
  if (attemptingSetBooking || navigatingToPayment) {
    return (
      <div className="u-container">
        <BrandLoader />
      </div>
    )
  }

  /*
    TODO: write test cases for this
    Given the user is on the SummaryContainer
    When there's no departures bookingData
    Then render a page error
  */
  if (!bookingData?.departures?.length) {
    return (
      <p className="u-text-center u-error-color">
        {labels[setBookingError] || labels.anErrorOccured}
      </p>
    )
  }

  const isDepartureEditable = modifyFlowType !== ModifyFlowType.DEPARTURE_LOCKED
  const isUSD = currencyType === 'USD'
  const currencyRateReminderCAT = isUSD ? labels.currencyRateReminderCAT : null
  const currencyLabel = isUSD ? 'USD' : ''

  return (
    <div className="summary u-page-view-container">
      <div className="u-container">
        <div className="summary-content">
          <h2
            className="theme-font-header"
            data-testid={'summary-container-title'}
          >
            {labels.summary ? labels.summary : 'Summary'}
          </h2>
          {currencyRateReminderCAT && (
            <p className="u-text-center">{currencyRateReminderCAT}</p>
          )}
          {bookingData && (
            <>
              <div className="u-md-max">
                {bookingDataDeparture && (
                  <MobileSummaryBreakdown
                    breakdown={summaryBreakdown.departure}
                    route={bookingDataDeparture}
                    labels={labels}
                    language={language}
                    headerLabel={labels.departureRoute}
                    handleRouteChange={handleRouteChange}
                    totalLabel={labels.departureTotal}
                    editable={isDepartureEditable}
                    currencyLabel={currencyLabel}
                  />
                )}
                {bookingDataReturn && (
                  <MobileSummaryBreakdown
                    breakdown={summaryBreakdown.return}
                    labels={labels}
                    language={language}
                    route={bookingDataReturn}
                    headerLabel={labels.returnRoute}
                    handleRouteChange={handleRouteChange}
                    totalLabel={labels.returnTotal}
                    editable={true}
                    currencyLabel={currencyLabel}
                  />
                )}
                <div className="mobile-summary-booking-total">
                  {bookingData?.reservationTotals?.total && (
                    <SummaryTotal
                      headerLabel={labels.tripTotal}
                      total={+(bookingData?.reservationTotals?.total || 0)}
                      taxes={bookingData?.reservationTotals?.taxesAndLevies}
                      activeModifyBooking={activeModifyBooking}
                      reservationTotals={bookingData?.reservationTotals}
                      editable={isDepartureEditable}
                      currencyLabel={currencyLabel}
                    />
                  )}
                </div>
              </div>
              <div className="u-md-up">
                <div className="summary-details">
                  {/* Departure Route Summary */}
                  <SummaryTable
                    labels={labels}
                    language={language}
                    breakdown={summaryBreakdown.departure}
                    handleRouteChange={handleRouteChange}
                    headerLabel={labels.departureRoute}
                    headerValue={labels[`${activeDepartureRoute?.code}Caption`]}
                    totalLabel={labels.routeTotal}
                    editable={isDepartureEditable}
                    vehicleEditable={vehicleEditable}
                    currencyLabel={currencyLabel}
                  />
                  {/* Return Route Summary */}
                  {bookingData?.departures?.[1] && (
                    <SummaryTable
                      labels={labels}
                      language={language}
                      breakdown={summaryBreakdown.return}
                      handleRouteChange={handleRouteChange}
                      headerLabel={labels.returnRoute}
                      headerValue={labels[`${activeReturnRoute?.code}Caption`]}
                      totalLabel={labels.returnTotal}
                      editable={true}
                      vehicleEditable={vehicleEditable}
                      currencyLabel={currencyLabel}
                    />
                  )}
                  <AccessibilitySummary labels={labels} />
                  <SummaryTotal
                    headerLabel={labels.tripTotal}
                    total={+(bookingData?.reservationTotals?.total || 0)}
                    reservationTotals={bookingData?.reservationTotals}
                    taxes={bookingData?.reservationTotals?.taxesAndLevies}
                    activeModifyBooking={activeModifyBooking}
                    currencyLabel={currencyLabel}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <ModalConsumer>
          {({ openModal }) => (
            <div className="summary-submit-container u-page-submit-container">
              <button
                className="btn btn-primary large-primary-btn"
                onClick={() => {
                  if (!customerAccount && ENABLE_LOGIN_GATE) {
                    openModal({
                      type: formVariants.CUSTOMER_LOGIN,
                      variant: modalVariants.NO_REGISTER
                    })
                  } else {
                    handleRouteChange(appRoutes.passengerDetails.pathname)
                  }
                }}
              >
                {labels.continueToPassengerDetailsBtn} →
              </button>
            </div>
          )}
        </ModalConsumer>
      </div>
      {bookingData && (
        <>
          <CancelTransactionModal
            onCloseButtonClick={() => {
              closeModal({ type: 'cancel-transaction' })
            }}
            onContinue={() => {
              closeModal({ type: 'cancel-transaction' })
              if (canRetryPayment) {
                retryPayment()
              } else {
                handleRouteChange(appRoutes.summary.pathname)
                dispatch(
                  UserSelectionActions.setModifyBookingNumber(
                    bookingData.bookingNumber
                  )
                )
                // TODO: deprecate
                dispatch(setActiveModificationBooking(bookingData))
              }
            }}
            onCancel={() => {
              if (shouldUseCustomErrorModal) {
                redirectToAccountsPage('cancel-transaction')
              } else if (activeModifyBooking) {
                changeModals({
                  openingModal: {
                    type: 'cancel-booking'
                  },
                  closingModal: {
                    type: 'cancel-transaction'
                  }
                })
              } else {
                closeModal({ type: 'cancel-transaction' })
                startNewBooking()
              }
            }}
            continueLabel={
              canRetryPayment
                ? labels.retryPayment
                : labels.continueModifyingBooking
            }
            cancelLabel={
              shouldUseCustomErrorModal
                ? labels.keepMyBooking
                : labels.cancelBooking
            }
            labels={labels}
            isOpen={Object.keys(modalIsOpen).includes('cancel-transaction')}
            hideCloseButton={true}
            suppressOnRequestClose={true}
          />
          <PaymentErrorModal
            onCloseButtonClick={() => {
              closeModal({ type: 'payment-error' })
            }}
            onContinue={() => {
              closeModal({ type: 'payment-error' })
              if (canRetryPayment) {
                retryPayment()
              } else {
                handleRouteChange(appRoutes.summary.pathname)
                dispatch(
                  UserSelectionActions.setModifyBookingNumber(
                    bookingData.bookingNumber
                  )
                )
                // TODO: deprecate
                dispatch(setActiveModificationBooking(bookingData))
              }
            }}
            onCancel={() => {
              if (shouldUseCustomErrorModal) {
                redirectToAccountsPage('payment-error')
              } else {
                closeModal({ type: 'payment-error' })
                startNewBooking()
              }
            }}
            labels={labels}
            isOpen={Object.keys(modalIsOpen).includes('payment-error')}
            continueLabel={
              canRetryPayment
                ? labels.retryPayment
                : labels.continueModifyingBooking
            }
            cancelLabel={
              activeModifyBooking && yn(allowAccountsLinkOnMonerisFailure)
                ? labels.keepMyBooking
                : labels.cancelBooking
            }
            hideCloseButton={Boolean(
              yn(shouldAllowMonerisRetry) ||
                yn(allowAccountsLinkOnMonerisFailure)
            )}
            suppressOnRequestClose={Boolean(
              yn(shouldAllowMonerisRetry) ||
                yn(allowAccountsLinkOnMonerisFailure)
            )}
          ></PaymentErrorModal>
          {activeModifyBooking &&
            Object.keys(modalIsOpen).includes('cancel-booking') && (
              <CancelBookingModal
                onRequestClose={closingDueToNonRefundedError => {
                  closeModal({ type: 'cancel-booking' })

                  if (closingDueToNonRefundedError) {
                    dispatch(getCustomerBookings())
                    dispatch(showNonRefundedModal(true))
                    handleRouteChange(appRoutes.account.pathname)
                  }
                }}
                onCancellationComplete={() => {
                  closeModal({ type: 'cancel-booking' })
                  handleRouteChange(appRoutes.ferryRoutes.pathname)
                  dispatch(clearActiveModifyBooking())
                }}
                isOpen={Object.keys(modalIsOpen).includes('cancel-booking')}
                booking={activeModifyBooking}
              />
            )}
        </>
      )}
    </div>
  )
}

SummaryContainer.propTypes = {
  handleRouteChange: func.isRequired,
  openModal: func.isRequired,
  closeModal: func.isRequired,
  modalIsOpen: shape().isRequired,
  searchParams: shape(),
  shouldAllowMonerisRetry: string,
  allowAccountsLinkOnMonerisFailure: string,
  brokenBookingNumberThreshold: string,
  clientId: string
}

export default SummaryContainer
