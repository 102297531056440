import { combineReducers } from 'redux'

import accessibilityReducer from './accessibility/reducer'
import activeAccommodationsReducer from './activeAccommodations/reducer'
import activeCrossingReducer from './activeCrossings/reducer'
import bookingDataReducer from './booking/reducer'
import configurationReducer from './configuration/reducer'
import crossingsReducer from './crossings/reducer'
import ferryRouteSelectionReducer from './ferryRouteSelections/reducer'
import modalReducer from './modal/reducer'
import modifyBookingReducer from './modifyBooking/reducer'
import passengerSelectionsReducer from './passengerSelections/reducer'
import paymentConfirmationReducer from './paymentConfirmation/reducer'
import promoCodeReducer from './promo/reducer'
import sessionDataReducer from './session/reducer'
import telephoneBookingReducer from './telephoneBooking/reducer'
import travelAgentReducer from './travelAgent/reducer'
import userReducer from './user/reducer'
import userBookingsReducer from './userBookings/reducer'
import userSelectionsReducer from './userSelections/reducer'
import vehicleSelectionsReducer from './vehicleSelections/reducer'
import refactorValidationReducer from './refactorValidation/reducer'

const rootReducer = combineReducers({
  userSelections: userSelectionsReducer,
  accessibility: accessibilityReducer,
  activeAccommodations: activeAccommodationsReducer,
  activeCrossings: activeCrossingReducer,
  bookingData: bookingDataReducer,
  configurationData: configurationReducer,
  crossingsData: crossingsReducer,
  ferryRouteSelections: ferryRouteSelectionReducer,
  modal: modalReducer,
  modifyBookingData: modifyBookingReducer,
  passengerSelections: passengerSelectionsReducer,
  paymentConfirmation: paymentConfirmationReducer,
  promo: promoCodeReducer,
  sessionData: sessionDataReducer,
  telephoneBooking: telephoneBookingReducer,
  travelAgentData: travelAgentReducer,
  userBookings: userBookingsReducer,
  userData: userReducer,
  vehicleSelections: vehicleSelectionsReducer,
  refactorValidations: refactorValidationReducer
})

export default rootReducer
