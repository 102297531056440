import React from 'react'
import { node, string, bool } from 'prop-types'
import ConfigConsumer from '../../redux/configuration/context'
import SvgIcon from '../SvgIcon/SvgIcon'
import './PageWarningBanner.scss'

const PageWarningBanner = ({ children, centered, message, customClass }) => (
  <div
    className={`page-warning-banner ${
      centered ? 'centered' : ''
    } ${customClass || ''}`}
  >
    <ConfigConsumer>
      {({ themeVariables }) => (
        <SvgIcon type="warning" fill={themeVariables.themeIconColor} />
      )}
    </ConfigConsumer>
    <span className={`page-warning-content ${customClass}`}>{children}</span>
  </div>
)

PageWarningBanner.defaultProps = {
  centered: false
}

PageWarningBanner.propTypes = {
  /**
   * The required warning message string for the alt text
   */
  message: string,
  /**
   * The required warning message node, used to include links
   */
  children: node,
  centered: bool
}

export default PageWarningBanner
