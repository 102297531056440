import React, { Fragment } from 'react'
import { shape, string } from 'prop-types'
import { useSelector } from 'react-redux'

import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import { ENABLE_ACCOMMODATIONS } from '../../../configuration/constants'
import { selectLabels } from '../../../redux/configuration/selectors'
import { formatPriceByLanguage } from '../../../redux/configuration/utilities'
import { selectCrossingData } from '../../../redux/crossings/selectors'
import { selectLanguage } from '../../../redux/session/selectors'
import { addDisplayNameToAccommodation } from '../../../configuration/utilities'
import './HeaderBreakdown.scss'

const renderLineItems = ({ group, lang, labels, displayTitle }) => {
  if (!group || !Object.keys(group).length) return null

  const groupItems = Object.values(group).map((item, i) => {
    let display = item.displayName ? item.displayName : displayTitle

    if (item.count > 1 && item.displayTitlePlural) {
      const displayTitlePlural = item.displayTitlePlural
      display = `${item.count} ${displayTitlePlural}`
    }

    let price = item.price

    if (!price) price = item?.details?.price
    if (item.count <= 0) return null

    price *= item.count

    return (
      <Fragment key={`${display}-${i}`}>
        <div className="header-breakdown-line-item">
          <p className="u-remove-margin description">{display}</p>
          <p className="u-remove-margin cost">
            {item.waitlist ? '--' : formatPriceByLanguage(price, lang)}
          </p>
        </div>
      </Fragment>
    )
  })

  return <Fragment>{groupItems}</Fragment>
}

const renderBreakDown = (trip, labels, lang) => {
  const {
    passengers,
    passengerTaxesAndLevies,
    vehicles,
    vehicleTaxesAndLevies,
    miscFees
  } = trip
  let accommodations = false
  let accommodationTaxesAndLevies = false
  let accommodationFees = 0

  if (
    ENABLE_ACCOMMODATIONS &&
    trip?.accommodations &&
    !!Object.keys(trip.accommodations).length
  ) {
    accommodations = trip?.accommodations
    // add display name for different accommodation type
    addDisplayNameToAccommodation(accommodations, lang, labels)
    accommodationTaxesAndLevies =
      trip?.accommodationTaxesAndLevies?.additionalTaxesAndLevies
    accommodationFees = +accommodationTaxesAndLevies
  }
  const passengerFees = +passengerTaxesAndLevies?.additionalTaxesAndLevies
  const vehicleFees = +vehicleTaxesAndLevies?.additionalTaxesAndLevies
  const otherFees = miscFees
    ? miscFees.reduce((acc, cur) => acc + +cur.price, 0)
    : 0
  const tripFees = accommodationFees + passengerFees + vehicleFees + otherFees
  const formattedFees = formatPriceByLanguage(tripFees, lang)

  return (
    <>
      {renderLineItems({
        group: passengers,
        lang,
        labels
      })}
      {renderLineItems({
        group: vehicles,
        displayTitle: labels.vehicle,
        lang,
        labels
      })}
      {ENABLE_ACCOMMODATIONS
        ? renderLineItems({
            // probably including: Cabin, Reserved seat, Kennel
            group: accommodations,
            lang,
            labels
          })
        : null}
      {formattedFees && (
        <div className="header-breakdown-line-item">
          <p className="u-remove-margin description">{labels.taxesAndFees}</p>
          <p className="u-remove-margin cost">{formattedFees}</p>
        </div>
      )}
    </>
  )
}

const HeaderBreakdown = ({ pricingBreakdown, overrideTotal }) => {
  const labels = useSelector(selectLabels)
  const crossingData = useSelector(selectCrossingData)
  const lang = useSelector(selectLanguage)

  return (
    <div className="header-breakdown">
      {+pricingBreakdown?.departure?.total ? (
        <div className="header-breakdown-trip">
          <h4>{labels.departure}</h4>
          {crossingData &&
          crossingData.departure &&
          crossingData.departure.loading ? (
            <div>
              <LoadingSpinner />
            </div>
          ) : (
            <>
              {renderBreakDown(pricingBreakdown.departure, labels, lang)}
              <div className="trip-total">
                <p className="u-remove-margin">{labels.departureTotal}</p>
                <p className="u-remove-margin">
                  {formatPriceByLanguage(
                    pricingBreakdown?.departure?.total,
                    lang
                  )}
                </p>
              </div>
            </>
          )}
        </div>
      ) : null}
      {+pricingBreakdown?.return?.total ? (
        <div className="header-breakdown-trip">
          <h4>{labels.return}</h4>
          {crossingData &&
          crossingData.return &&
          crossingData.return.loading ? (
            <div>
              <LoadingSpinner />
            </div>
          ) : (
            <>
              {renderBreakDown(pricingBreakdown.return, labels, lang)}
              <div className="trip-total">
                <p className="u-remove-margin">{labels.returnTotal}</p>
                <p className="u-remove-margin">
                  {formatPriceByLanguage(pricingBreakdown.return.total, lang)}
                </p>
              </div>
            </>
          )}
        </div>
      ) : null}
      {+pricingBreakdown?.paid ? (
        <div className="header-breakdown-paid">
          <p>{labels.amountPaid}</p>
          <p>{formatPriceByLanguage(pricingBreakdown.paid, lang)}</p>
        </div>
      ) : null}
      {+pricingBreakdown.total ? (
        <div
          className={`header-breakdown-total ${pricingBreakdown?.paid &&
            'with-paid'}`}
        >
          <p>
            {overrideTotal
              ? labels.totalAfterDiscount || labels.total
              : labels.total}
          </p>
          <p>
            {formatPriceByLanguage(
              pricingBreakdown?.paid
                ? pricingBreakdown.totalAfterPaid
                : overrideTotal
                ? overrideTotal
                : pricingBreakdown?.total,
              lang
            )}
          </p>
        </div>
      ) : null}
    </div>
  )
}

HeaderBreakdown.propTypes = {
  pricingBreakdown: shape(),
  overrideTotal: string
}

export default HeaderBreakdown
