import React from 'react'
import { object, bool, func, string, array, element } from 'prop-types'
import { renderContentValues } from '../../redux/configuration/utilities'

/**
 * Common card component used by: OpenableAccommodationCard, ExtrasContainer.
 * In different step it need different price labels,
 * while this card used by OpenableAccommodationCard, it will set `priceUnitLabel` prop.
 * By default(without priceUnitLabel), price label using `labels.perKennel`
 */
const ExtrasCard = ({
  labels,
  content,
  imageUrl,
  buttonDisabled,
  buttonText,
  onButtonClick,
  startingFrom,
  warningHeader,
  priceUnitLabel,
  resourceCode
}) => {
  let priceElement = null
  if (startingFrom) {
    priceElement = (
      <div className="extras__card-start">
        <div className="extras__card-start-label">{labels.startingFrom}</div>
        <div className="extras__card-start-value">{startingFrom}</div>
        <div className="extras__card-start-label">
          {priceUnitLabel || labels.perKennel}
        </div>
      </div>
    )
  }
  return (
    <div className="extras__card">
      <div className="extras__card-warning-container">{warningHeader}</div>
      <img src={imageUrl} alt={warningHeader} role="none" />
      <div className="extras__card-content with-image">
        <div>{renderContentValues(content)}</div>
        <div className="extras__card-bottom">
          {priceElement}
          <button
            disabled={buttonDisabled}
            aria-labelledby={`${resourceCode}-header`}
            onClick={onButtonClick}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  )
}

ExtrasCard.propTypes = {
  labels: object.isRequired,
  content: array,
  imageUrl: string.isRequired,
  buttonDisabled: bool,
  buttonText: string.isRequired,
  onButtonClick: func.isRequired,
  startingFrom: string,
  warningHeader: element,
  priceUnitLabel: string,
  resourceCode: string
}

export default ExtrasCard
