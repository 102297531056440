import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  selectAccommodationsContent,
  selectLabels
} from '../../redux/configuration/selectors'
import { setReturnCrossing } from '../../redux/activeCrossings/actions'
import * as UserSelectionActions from '../../redux/userSelections/actions'
import {
  ENABLE_ACCOMMODATIONS,
  ENABLE_EXTRAS,
  ModifyFlowType
} from '../../configuration/constants'
import appRoutes from '../../configuration/appRoutes'
import { selectReturnCrossing } from '../../redux/crossings/selectors'
import {
  selectReturnDate,
  selectReturnRoute
} from '../../redux/ferryRouteSelections/selectors'
import {
  selectActiveDepartureCrossing,
  selectActiveReturnCrossing
} from '../../redux/activeCrossings/selectors'
import { fetchCrossing } from '../../redux/crossings/actions'
import dateFormats from '../../configuration/dateFormats'
import {
  selectActiveModifyBooking,
  selectModifyFlowType
} from '../../redux/modifyBooking/selectors'
import { selectReturnVehicle } from '../../redux/vehicleSelections/selectors'
import HighlightedCrossingRouteSelection from '../../components/RouteSelection/HighlightedCrossingRouteSelection'
import { selectPassengerSelections } from '../../redux/passengerSelections/selectors'
import { selectUserSelections } from '../../redux/userSelections/selectors'

const ReturnRouteSelection = ({ handleRouteChange }) => {
  const labels = useSelector(selectLabels)
  const dispatch = useDispatch()

  const crossingsData = useSelector(selectReturnCrossing)
  const departureDate = useSelector(selectReturnDate)
  const activeDepartureCrossing = useSelector(selectActiveDepartureCrossing)
  const returnRoute = useSelector(selectReturnRoute)
  const currentActiveReturn = useSelector(selectActiveReturnCrossing)

  const activeModifyBooking = useSelector(selectActiveModifyBooking)
  const modifyFlowType = useSelector(selectModifyFlowType)

  const activeVehicle = useSelector(selectReturnVehicle)

  const selectedPassengers = useSelector(selectPassengerSelections)
  const totalPassengers = Object.values(
    selectedPassengers?.return || {}
  ).reduce((num, passengerCount) => {
    return (num += passengerCount)
  }, 0)

  const userSelections = useSelector(selectUserSelections)

  const activeVehicleResourceCode = activeVehicle?.vehicleType?.resourceCode

  const accommodationsContent = useSelector(selectAccommodationsContent)

  const cabinResourceCodes = Object.values(
    accommodationsContent?.accommodations ?? []
  )
    .filter(accommodation => !Number(accommodation?.isExtra))
    .map(accommodation => accommodation.resourceCode)

  useEffect(() => {
    if (modifyFlowType === ModifyFlowType.DEPARTURE_LOCKED) {
      dispatch(
        fetchCrossing('departure', {
          setActiveCrossingFromModification: Boolean(
            activeModifyBooking?.departureRoute
          ),
          activeModifyBooking: activeModifyBooking?.departureRoute
        })
      )
    }
    dispatch(
      fetchCrossing('return', {
        setActiveCrossingFromModification: Boolean(
          activeModifyBooking?.returnRoute
        ),
        activeModifyBooking: activeModifyBooking?.returnRoute
      })
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = !crossingsData || crossingsData.loading
  const error = crossingsData && crossingsData.error

  const departureCrossingArrivalDate = activeDepartureCrossing
    ? activeDepartureCrossing.arrivalDate
    : null
  const departureCrossingArrivalTime = activeDepartureCrossing
    ? activeDepartureCrossing.arrivalTime
    : null

  const filteredCrossings =
    crossingsData && crossingsData.crossings
      ? Object.entries(crossingsData.crossings).reduce(
          (obj, [dayKey, crossings]) => {
            // skip any days before our departure crossing.
            // we shouldn't have any, but just to be safe.
            let filteredDayCrossings

            if (
              moment(dayKey, dateFormats.default).isBefore(
                departureCrossingArrivalDate,
                'day'
              )
            ) {
              return obj
            }

            // filter out any crossings on the same day that depart after our departure crossing arrives.
            if (
              moment(dayKey, dateFormats.default).isSame(
                departureCrossingArrivalDate,
                'day'
              )
            ) {
              filteredDayCrossings = crossings.filter(crossing =>
                moment(crossing.departureTime, dateFormats.timeOnly).isAfter(
                  moment(departureCrossingArrivalTime, dateFormats.timeOnly),
                  'minutes'
                )
              )
            } else {
              // these are all on a later date than our departure crossing
              filteredDayCrossings = crossings
            }

            if (filteredDayCrossings.length) {
              obj[dayKey] = filteredDayCrossings
            }

            return obj
          },
          {}
        )
      : null

  let continueBtnLabel, continueBtnRoute
  if (ENABLE_ACCOMMODATIONS) {
    continueBtnLabel = labels.continueToAccommodationsBtn
    continueBtnRoute = appRoutes.accommodations.pathname
  } else if (ENABLE_EXTRAS) {
    continueBtnLabel = labels.continueToExtrasBtn
    continueBtnRoute = appRoutes.extras.pathname
  } else {
    continueBtnLabel = labels.continueToSummaryBtn
    continueBtnRoute = appRoutes.summary.pathname
  }

  const handleCrossingSelection = crossing => {
    const { departureDate, departureTime } = crossing
    dispatch(
      UserSelectionActions.setReturnCrossing({
        date: moment(departureDate).format('YYYY-MM-DD'),
        time: departureTime
      })
    )
    dispatch(setReturnCrossing(crossing)) // TODO: Deprecate
  }

  return (
    <HighlightedCrossingRouteSelection
      crossingsData={crossingsData}
      crossings={filteredCrossings}
      departureDate={departureDate}
      route={returnRoute}
      selectCrossing={handleCrossingSelection}
      currentSelection={currentActiveReturn}
      continueButtonText={continueBtnLabel}
      continueIsDisabled={
        !currentActiveReturn ||
        !crossingsData?.crossings ||
        Object.keys(crossingsData.crossings).length === 0
      }
      continueClickHandler={() => {
        // MAI tracking
        try {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'analytics-return-1',
            details: userSelections
          })
        } catch (err) {
          console.log('Analytics error: ' + err)
        }
        handleRouteChange(continueBtnRoute)
      }}
      isLoading={isLoading}
      error={error}
      activeVehicleResourceCode={activeVehicleResourceCode}
      activeVehicle={activeVehicle}
      titleLabel={labels.return}
      newRoutesClickHandler={() => {
        handleRouteChange(appRoutes.ferryRoutes)
      }}
      totalPassengers={totalPassengers}
      cabinResourceCodes={cabinResourceCodes}
    />
  )
}

export default ReturnRouteSelection
