import { renderParagraph, renderSpan } from '../../configuration/utilities'
import { crossingTypes } from '../crossings/constants'
import { ENABLE_BICYCLE_OPTION } from '../../configuration/constants'

export const SET_VEHICLE_FORM_TYPE = 'vehicleSelections/SET_VEHICLE_FORM_TYPE'
export const SET_VEHICLE_DETAILS = 'vehicleSelections/SET_VEHICLE_DETAILS'
export const RESET_VEHICLE_SELECTIONS =
  'vehicleSelections/RESET_VEHICLE_SELECTIONS'
export const SET_VEHICLE_DETAILS_SUCCESS =
  'vehicleSelections/SET_VEHICLE_DETAILS_SUCCESS'
export const SET_VEHICLE_DETAILS_ERROR =
  'vehicleSelections/SET_VEHICLE_DETAILS_ERROR'
export const SET_VEHICLE_ERROR = 'vehicleSelections/SET_VEHICLE_ERROR'
export const CLEAR_VEHICLE_ERROR = 'vehicleSelections/CLEAR_VEHICLE_ERROR'
export const CLEAR_VEHICLE_ERRORS = 'vehicleSelections/CLEAR_VEHICLE_ERRORS'
export const SET_VEHICLE_DETAILS_DEFAULT_ACTION_ERROR =
  'Missing or incorrect default values'

export const SCROLL_TO_VEHICLE_SECTION_IN = 250
export const vehicleTypes = {
  VEHDUP: 'VEH-DUP',
  VEHUNQ: 'VEH-UNQ',
  VEHOWY: 'VEH-OWY',
  VEHWLK: 'VEH-WLK',
  VELO: 'VELO'
}
export const tripTypes = {
  OW: 'OW',
  RT: 'RT'
}
export const maxVehicleLengthBeforeContact = 65
export const vehicleSections = {
  HEIGHT: 'vehicleGroupTypeHeight',
  LENGTH: 'vehicleGroupTypeLength',
  TYPE: 'vehicleType',
  FORM_TYPE: 'vehicleFormType'
}
export const vehicleInputs = {
  LENGTH: 'vehicleLength',
  LICENSE: 'vehicleLicense'
}
export const vehicleErrorStateKeysObject = {
  DEPARTURE: `${crossingTypes.DEPARTURE}Errors`,
  RETURN: `${crossingTypes.RETURN}Errors`
}

export const vehicleSelectionDefaultState = {
  [vehicleSections.HEIGHT]: {},
  [vehicleSections.LENGTH]: {},
  [vehicleSections.TYPE]: {},
  [vehicleInputs.LENGTH]: '',
  [vehicleInputs.LICENSE]: ''
}

export const vehicleSelectionDefaultErrorState = {
  [vehicleSections.HEIGHT]: false,
  [vehicleSections.LENGTH]: false,
  [vehicleSections.TYPE]: false,
  [vehicleInputs.LENGTH]: false,
  [vehicleInputs.LICENSE]: false
}

export const getVehicleFilterOptions = () => {
  let filters = {}

  // Yes, same vehicle both routes
  filters[vehicleTypes.VEHDUP] = {
    renderLabel: ({ yesLabel, sameLabel, vehicleOnBothRoutesLabel }) =>
      `${renderParagraph(
        `${yesLabel}, ${renderSpan(sameLabel)} ${vehicleOnBothRoutesLabel}`
      )}`,
    tripTypes: [`${tripTypes.RT}`],
    ariaLabel: ({ yesLabel, sameLabel, vehicleOnBothRoutesLabel }) =>
      `${yesLabel}, ${sameLabel} ${vehicleOnBothRoutesLabel}`
  }

  // Yes, different vehicle both routes
  filters[vehicleTypes.VEHUNQ] = {
    renderLabel: ({ yesLabel, differentLabel, vehicleOnBothRoutesLabel }) =>
      `${renderParagraph(
        `${yesLabel}, ${renderSpan(differentLabel)} ${vehicleOnBothRoutesLabel}`
      )}`,
    tripTypes: [`${tripTypes.RT}`],
    ariaLabel: ({ yesLabel, differentLabel, vehicleOnBothRoutesLabel }) =>
      `${yesLabel}, ${differentLabel} ${vehicleOnBothRoutesLabel}`
  }

  // Yes
  filters[vehicleTypes.VEHOWY] = {
    renderLabel: ({ yesLabel }) => `${renderParagraph(yesLabel)}`,
    tripTypes: [`${tripTypes.OW}`],
    ariaLabel: ({ yesLabel }) => yesLabel
  }

  // No, I will be walking
  filters[vehicleTypes.VEHWLK] = {
    renderLabel: ({ noWalkingOnLabel }) =>
      `${renderParagraph(noWalkingOnLabel)}`,
    tripTypes: [`${tripTypes.OW}`, `${tripTypes.RT}`],
    ariaLabel: ({ noWalkingOnLabel }) => noWalkingOnLabel
  }

  // Just a bicycle / small
  if (ENABLE_BICYCLE_OPTION) {
    filters[vehicleTypes.VELO] = {
      renderLabel: ({ bicycleLabel }) => `${renderParagraph(bicycleLabel)}`,
      tripTypes: [`${tripTypes.OW}`, `${tripTypes.RT}`],
      ariaLabel: ({ bicycleLabel }) => bicycleLabel
    }
  }

  return filters
}

export const allVehicleFilterOptions = {
  // Yes, same vehicle both routes
  [vehicleTypes.VEHDUP]: {
    renderLabel: ({ yesLabel, sameLabel, vehicleOnBothRoutesLabel }) =>
      `${renderParagraph(
        `${yesLabel}, ${renderSpan(sameLabel)} ${vehicleOnBothRoutesLabel}`
      )}`,
    tripTypes: [`${tripTypes.RT}`],
    ariaLabel: ({ yesLabel, sameLabel, vehicleOnBothRoutesLabel }) =>
      `${yesLabel}, ${sameLabel} ${vehicleOnBothRoutesLabel}`
  },
  // Yes, different vehicle both routes
  [vehicleTypes.VEHUNQ]: {
    renderLabel: ({ yesLabel, differentLabel, vehicleOnBothRoutesLabel }) =>
      `${renderParagraph(
        `${yesLabel}, ${renderSpan(differentLabel)} ${vehicleOnBothRoutesLabel}`
      )}`,
    tripTypes: [`${tripTypes.RT}`],
    ariaLabel: ({ yesLabel, differentLabel, vehicleOnBothRoutesLabel }) =>
      `${yesLabel}, ${differentLabel} ${vehicleOnBothRoutesLabel}`
  },
  // Yes
  [vehicleTypes.VEHOWY]: {
    renderLabel: ({ yesLabel }) => `${renderParagraph(yesLabel)}`,
    tripTypes: [`${tripTypes.OW}`],
    ariaLabel: ({ yesLabel }) => yesLabel
  },
  // No, I will be walking
  [vehicleTypes.VEHWLK]: {
    renderLabel: ({ noWalkingOnLabel }) =>
      `${renderParagraph(noWalkingOnLabel)}`,
    tripTypes: [`${tripTypes.OW}`, `${tripTypes.RT}`],
    ariaLabel: ({ noWalkingOnLabel }) => noWalkingOnLabel
  }
}
