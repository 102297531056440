import React, { useState, useEffect } from 'react'
import SeatPicker from './SeatPicker'
import SeatsImage from '../../assets/images/seatplan.png'
import SeatsImageHLD from '../../assets/images/seatplan-HLD.png'
import { getAvailableSeating } from '../../api/api'
import { useSelector } from 'react-redux'
import { selectSessionState } from '../../redux/session/selectors'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import moment from 'moment'
import WarningBanner from '../WarningBanner/WarningBanner'

const BACKGROUND_IMAGE_EXTENT = [0, 0, 693, 737] // for seatplan image

const SeatPickerContainer = ({
  supplierCode,
  routeCode,
  resourceCode,
  startDate,
  startTime,
  productCode,
  shipCode,
  passengerType,
  defaultSelectedSeats,
  onCloseClick,
  onSubmit,
  seatsFromModification,
  activeModifyBooking,
  numberPassengers
}) => {
  const [seatData, setSeatData] = useState({
    data: null,
    loading: false,
    error: false
  })
  const [selectedSeats, setSelectedSeats] = useState(
    defaultSelectedSeats?.map(seat => seat.number) || []
  )

  const sessionData = useSelector(selectSessionState)
  const { sessionId, language } = sessionData

  useEffect(() => {
    setSeatData({
      ...seatData,
      loading: true
    })
    fetchSeats({
      primaryLangId: language,
      sessionId,
      supplierCode,
      resourceCode,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      startTime,
      productCode,
      passengerType
    })
      .then(seats => {
        if (!defaultSelectedSeats && seatsFromModification?.length) {
          let isModifyingSameCrossing = !!activeModifyBooking
          if (isModifyingSameCrossing) {
            //   compare the crossings
            if (
              routeCode !== activeModifyBooking?.routeCode ||
              startDate?.split('T')?.[0] !==
                activeModifyBooking?.departureDate ||
              startTime !== activeModifyBooking?.departureTime ||
              shipCode !== activeModifyBooking?.shipCode
            ) {
              isModifyingSameCrossing = false
            }
          }
          const matchingSeats = seatsFromModification
            ?.filter(currentSeat =>
              seats?.features?.find(
                seat =>
                  seat?.properties?.seatNumber === currentSeat.number &&
                  seat?.place === currentSeat.place &&
                  (!seat?.properties?.unavailable || isModifyingSameCrossing)
              )
            )
            ?.map(seat => seat.number)
          setSelectedSeats(matchingSeats)
        }

        setSeatData({
          ...seatData,
          data: seats,
          loading: false,
          error: false
        })
      })
      .catch(e => {
        setSeatData({
          ...seatData,
          loading: false,
          error: 'Unable to fetch available seating'
        })
      })
    // eslint-disable-next-line
  }, [])

  function handleSeatChange(seats) {
    setSelectedSeats(seats)
    onSubmit(
      seats.map(seat => {
        const seatInfo = seatData?.data?.features.find(
          seatItem => seatItem.id === seat
        )
        const { id, place } = seatInfo
        return {
          number: id,
          place
        }
      })
    )
  }

  return (
    <div className="seat-picker-container">
      {seatData.loading && (
        <div className="seat-picker-loader">
          <LoadingSpinner />
        </div>
      )}
      {seatData.error && (
        <div className="seat-picker-error">
          <div>
            <WarningBanner>{seatData.error}</WarningBanner>
          </div>
        </div>
      )}
      {seatData.data && (
        <SeatPicker
          seatData={seatData.data}
          backgroundImage={shipCode === 'HLD' ? SeatsImageHLD : SeatsImage}
          backgroundImageExtent={BACKGROUND_IMAGE_EXTENT}
          selectedSeats={selectedSeats}
          onCloseClick={onCloseClick}
          onSelectionComplete={handleSeatChange}
          seatsFromModification={seatsFromModification}
          numberPassengers={numberPassengers}
        />
      )}
    </div>
  )
}

export default SeatPickerContainer

function fetchSeats({
  primaryLangId,
  sessionId,
  supplierCode,
  resourceCode,
  startDate,
  startTime,
  productCode,
  passengerType
}) {
  return getAvailableSeating({
    primaryLangId,
    sessionId,
    supplierCode,
    resourceCode,
    startDate,
    startTime,
    productCode,
    passengerType
  }).then(res => {
    const data = res?.data?.['hydra:member']?.[0]
    if (data) {
      return reduceSeats(data)
    }
    return Promise.reject()
  })
}

function reduceSeats(data) {
  const { availableSeatingNumbers } = data
  const { availableNumbers, reservedNumbers } = availableSeatingNumbers
  const seats = [
    ...availableNumbers
      .map(seat => reduceSeat(seat, false))
      .filter(seat => seat !== null),
    ...reservedNumbers
      .map(seat => reduceSeat(seat, true))
      .filter(seat => seat !== null)
  ]
  return {
    features: seats,
    type: 'FeatureCollection'
  }
}

function reduceSeat(seat, unavailable) {
  const geometry = seat?.features?.geometry
  if (!geometry) {
    return null
  }
  const newGeo = {
    type: geometry?.type,
    coordinates: !geometry
      ? []
      : [
          ...geometry.coordinates?.map(coords =>
            coords.filter(
              (coord, index) =>
                coords.findIndex(
                  test => test[0] === coord[0] && test[1] === coord[1]
                ) === index
            )
          )
        ]
  }
  return {
    id: seat.number,
    type: 'Feature',
    place: seat?.place,
    properties: {
      ...seat?.features?.properties,
      unavailable
    },
    geometry: newGeo
  }
}
