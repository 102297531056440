import React from 'react'
import Modal from '../Modal'
import modalIds from '../../context/configuration'
import BrandLoader from '../BrandLoader/BrandLoader'

import './LoadingModal.scss'

const LoadingModal = ({ modalIsOpen }) => (
  <Modal
    isOpen={Boolean(modalIsOpen[modalIds.loading])}
    className="loading-modal"
  >
    <BrandLoader />
  </Modal>
)

export default LoadingModal
