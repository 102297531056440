// TODO: replace with actual content
export const mockCanadianForcesModalContent = {
  value: [
    {
      type: '',
      name: 'canadianForcesModalContent',
      value: [
        {
          attributes: 'h2',
          value: 'Special Fares/The Canadian Forces Appreciation Fare'
        },
        {
          attributes: 'h3',
          value: 'Special Fares'
        },
        {
          attributes: 'h4',
          value: 'Special Group Fares'
        },
        {
          value:
            'A 10% discount is available on selected rates. Some restrictions apply. For more details regarding our special group fares, contact us at 1-800-341-7981 and select the option to speak with the group tour agent.'
        },
        {
          attributes: 'h2',
          value: 'The Canadian Forces Appreciation Fare'
        },
        {
          value:
            'The Canadian Forces Appreciation Fare is a year-round discount offered to serving members and veterans of the Canadian Forces. Designed as a token of appreciation for their current and past efforts in protecting the interests of the people of Canada at home and abroad, it has been well-received since its introduction in 2009.'
        },
        {
          value:
            'This year-round program is available to Canadian Forces personnel, veterans and up to three companions on the same reservation and provides free passenger fare on the Port aux Basques/North Sydney ferry route, or a 50 per cent discount on passenger fare when travelling on the Argentia/North Sydney ferry service. Please note that neither vehicle fares nor on board services are included in this promotion.'
        },
        {
          attributes: 'h2',
          value: 'Eligibility'
        },
        {
          value:
            'In an effort to simplify the eligibility process for this fare program, Marine Atlantic has worked with the Canadian Forces Morale and Welfare Services to ensure all eligible members and veterans have access to the program.'
        },
        {
          value:
            'To facilitate this process, the following two cards are accepted as eligibility for the program:'
        },
        {
          value:
            'An eligible individual must possess one of the above cards at the time of booking a reservation and must also present it at the time of travel.'
        },
        {
          value:
            'For Canadian Armed Forces personnel and veterans who do not have these cards, the CF One Program of the Canadian Forces Morale and Welfare Services would be pleased to assist by visiting their website at www.cf1fc.ca or by calling 1-855-245-0330 Ext 6.'
        },
        {
          attributes: 'h2',
          value: 'Booking a Reservation'
        },
        {
          value:
            'To make a reservation under the Canadian Forces Appreciation Fare, please contact Marine Atlantic reservations at 1-800-341-7981 or visit the Marine Atlantic website at www.marineatlantic.ca.'
        },
        {
          value:
            'The primary individual on the reservation must possess the appropriate CF1 identification card (not necessarily the driver of the vehicle). At the time of making the reservation, the Canadian Forces member must indicate the type of CF1 identification card being used, as well as present the identification card and valid photo at the time of check-in to confirm eligibility under the program. This offer cannot be combined with any other promotion and is for personal related travel only. Standard cancellation and amendment fees apply.'
        }
      ]
    }
  ]
}

export const mockVehicleHeightModalValues = [
  {
    attributes: 'heading',
    value: 'How to measure the height of your vehicle?'
  },
  {
    value:
      'To determine the height to book, you should measure the distance between the ground and the top of your vehicle, including trailers at the rear or any object on the roof of the vehicle. Please round your measurement up to the next whole number.'
  },
  {
    attributes: 'exampleList',
    value: [
      {
        value:
          'Total height of exactly 6 feet --> please check "6 feet and under"'
      },
      {
        value:
          'Total height of 6 feet 2 inches --> please check "higher than six feet"'
      }
    ]
  },
  {
    value:
      'Important: If you book the wrong height category, you have until three hours before your departure to change your reservation, free of charge, by entering the right category. \nHowever, if you are not registered in the right category at the time of departure, here is the procedure:'
  },
  {
    attributes: 'list',
    value: [
      { value: 'You will have to pay a $25 modification fee.' },
      {
        value:
          'If you move to the higher category; your reservation will only be kept if there is availability for that category. Otherwise, you will automatically be put back into the queue.'
      }
    ]
  },
  {
    attributes: 'italic',
    value:
      '*Please note that the car icons are for reference only. It is possible that your vehicle is included in a category without the corresponding icon being shown.'
  }
]

export const mockBFLModalValues = [
  {
    interpolation: {
      pleaseClickHere: {
        value: 'Please click here',
        href: 'https://www.ferries.ca/nb-ns-ferry/what-to-expect/'
      }
    },
    value:
      'Please note that there are travel restrictions in place for those entering New Brunswick and Nova Scotia. Due to COVID-19, Bay Ferries Limited has implemented special measures to ensure the safety of our passengers and crew. By making a reservation you agree to follow these measures throughout the duration of our voyage. To review travel restrictions and our COVID-19 response measures, {{pleaseClickHere}}'
  }
]
