import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  ENABLE_ACCOMMODATIONS,
  USE_REFACTOR,
  PRIOR_SAILING_HOURS
} from '../../configuration/constants'

import DefaultCrossingCard from './DefaultCrossingCard/DefaultCrossingCard'
import ShipNameCrossingCard from './ShipNameCrossingCard/ShipNameCrossingCard'

const CrossingCardContainer = ({
  crossing,
  selectCrossing,
  isDisabled,
  isCurrentlySelectedRoute,
  activeVehicleResourceCode,
  activeVehicle,
  shouldDisplayNoPassengerMessage,
  cabinResourceCodes
}) => {
  const { departureDate } = crossing
  let accommodationsAvailable = false
  let vehiclesAvailable
  let isOverNight
  let isDisabledParam = isDisabled
  let isCutOff = false

  if (USE_REFACTOR) {
    accommodationsAvailable = crossing.accommodationsAvailable
    vehiclesAvailable = crossing.vehiclesAvailable
    isOverNight = crossing.isOvernight
    isDisabledParam = crossing.isDisabled
  } else {
    const { formattedResources, departureTime } = crossing
    const crossingVehicles = formattedResources?.vehicles || []

    const filteredVehicles = activeVehicleResourceCode
      ? crossingVehicles.filter(
          vehicle => vehicle.resourceCode === activeVehicleResourceCode
        )
      : crossingVehicles

    vehiclesAvailable = filteredVehicles.some(vehicle => {
      return activeVehicle?.vehicleLength
        ? +vehicle.totalCapacity - +vehicle.bookedAmount >=
            activeVehicle?.vehicleLength
        : +vehicle.totalCapacity - +vehicle.bookedAmount > 0
    })

    if (ENABLE_ACCOMMODATIONS) {
      accommodationsAvailable = (formattedResources?.accommodations || []).some(
        accommodation =>
          cabinResourceCodes.includes(accommodation.resourceCode) &&
          +accommodation.freeCapacity > 0
      )
    }

    const departureTimeNumber = +moment(departureTime, 'HH:mm').format('HHmm')
    isOverNight = departureTimeNumber > 0 && departureTimeNumber < 400
    isCutOff =
      typeof crossing.hoursToDeparture === 'undefined' ||
      crossing.hoursToDeparture <= PRIOR_SAILING_HOURS
  }

  if (process.env.REACT_APP_CROSSING_CARD_LAYOUT === 'ship_name') {
    return (
      <ShipNameCrossingCard
        accommodationsAvailable={accommodationsAvailable}
        crossing={crossing}
        departureDate={departureDate}
        isCurrentlySelectedRoute={isCurrentlySelectedRoute}
        isDisabled={isDisabledParam}
        isOverNight={isOverNight}
        selectCrossing={selectCrossing}
        vehiclesAvailable={vehiclesAvailable}
        shouldDisplayNoPassengerMessage={shouldDisplayNoPassengerMessage}
        isCutOff={isCutOff}
      />
    )
  }

  return (
    <DefaultCrossingCard
      accommodationsAvailable={accommodationsAvailable}
      crossing={crossing}
      departureDate={departureDate}
      isCurrentlySelectedRoute={isCurrentlySelectedRoute}
      isDisabled={isDisabledParam}
      isOverNight={isOverNight}
      selectCrossing={selectCrossing}
      vehiclesAvailable={vehiclesAvailable}
      isCutOff={isCutOff}
    />
  )
}

CrossingCardContainer.propTypes = {
  crossing: PropTypes.shape().isRequired,
  selectCrossing: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isCurrentlySelectedRoute: PropTypes.bool,
  activeVehicleResourceCode: PropTypes.string,
  activeVehicle: PropTypes.shape(),
  cabinResourceCodes: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default CrossingCardContainer
