import Point from 'ol/geom/Point'
import { Fill, Stroke, Style, Icon } from 'ol/style'
import { getCenter } from 'ol/extent'

import checkedIcon from '../../assets/icons/checked-white.svg'

export const featureStyle = new Style({
  fill: new Fill({
    color: 'rgba(0, 0, 0, 0)'
  }),
  stroke: new Stroke({
    color: '#ff0000',
    width: 2
  })
})

export const selectionStyle = new Style({
  fill: new Fill({
    color: 'rgba(48,158,0,0.3)'
  }),
  stroke: new Stroke({
    color: '#0000ff',
    width: 2
  })
})

export const pickerSelectionStyle = new Style({
  fill: new Fill({
    color: '#309e00'
  }),
  stroke: new Stroke({
    color: '#309e00',
    width: 2
  })
})

export const invisibleStyle = new Style({
  fill: new Fill({
    color: 'rgba(00, 000, 00, 00)'
  }),
  stroke: new Stroke({
    color: 'rgba(0, 0, 0, 0.0)',
    width: 2
  })
})

export const geometryCenter = feature => {
  const aa = feature.getGeometry().getExtent()
  const oo = getCenter(aa)

  return new Point(oo)
}

export const choosenStyle = [
  pickerSelectionStyle,
  new Style({
    image: new Icon({
      crossOrigin: 'anonymous',
      rotation: 0.3,
      scale: 1.2,
      src: checkedIcon
    }),
    geometry: geometryCenter
  })
]

export const unavailableStyle = [
  new Style({
    fill: new Fill({
      color: 'rgba(77, 77, 79, 1)'
    }),
    stroke: new Stroke({
      color: 'rgba(77, 77, 79, 1)',
      width: 2
    })
  })
]

export const twoCoordinatesDistance = (coordA, coordB) => {
  const deltX = coordA[0] - coordB[0]
  const deltY = coordA[1] - coordB[1]
  return Math.sqrt(deltX * deltX + deltY * deltY)
}
