import React, { useEffect, useRef } from 'react'

function useClickAwayListener(ref, toggleEvent, triggerRef) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (triggerRef && triggerRef.current.contains(event.target)) {
          return null
        }
        toggleEvent()
      }
    }

    function handleEscKey(e) {
      if (e.keyCode === 27) {
        toggleEvent()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleEscKey)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscKey)
    }
  }, [ref, toggleEvent, triggerRef])
}

export default function ClickAwayListener({
  children,
  toggleEvent,
  triggerRef
}) {
  const wrapperRef = useRef(null)
  useClickAwayListener(wrapperRef, toggleEvent, triggerRef)

  return (
    <div ref={wrapperRef} className="u-click-away-listener">
      {children}
    </div>
  )
}
