import { createSelector } from 'reselect'

export const selectModalState = state => state.modal

export const selectPathToContinueTo = createSelector(
  selectModalState,
  modalState => modalState.pathToContinueTo
)

export const selectLoginPathName = createSelector(
  selectModalState,
  modalState => modalState.loginPathName
)
