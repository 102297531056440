import { handleActions } from 'redux-actions'
import {
  FETCH_TELEPHONE_BOOKING,
  RECEIVE_TELEPHONE_BOOKING,
  TELEPHONE_BOOKING_ERROR,
  CLEAR_TELEPHONE_BOOKING,
  CHECKOUT_FORM_RESET,
  CHECKOUT_FORM_INIT
} from './contants'

const telephoneBookingInitialState = {
  loading: false,
  data: null,
  error: null,
  checkoutFormInitialized: false
}

const telephoneBookingReducer = handleActions(
  {
    [FETCH_TELEPHONE_BOOKING]: state => {
      return {
        ...state,
        data: null,
        loading: true,
        error: null
      }
    },
    [RECEIVE_TELEPHONE_BOOKING]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      }
    },
    [TELEPHONE_BOOKING_ERROR]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: null
      }
    },
    [CLEAR_TELEPHONE_BOOKING]: () => {
      return {}
    },
    [CHECKOUT_FORM_INIT]: state => {
      return {
        ...state,
        checkoutFormInitialized: true
      }
    },
    [CHECKOUT_FORM_RESET]: state => {
      return {
        ...state,
        checkoutFormInitialized: false
      }
    }
  },
  { ...telephoneBookingInitialState }
)

export { telephoneBookingInitialState }
export default telephoneBookingReducer
