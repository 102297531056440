export default {
  A: {
    code: 'adult',
    type: 'A',
    apiCode: 'adults'
  },
  S: {
    code: 'senior',
    type: 'S',
    apiCode: 'seniors'
  },
  E: {
    code: 'child',
    type: 'E',
    apiCode: 'children'
  },
  B: {
    code: 'infant',
    type: 'B',
    apiCode: 'infants'
  },
  C: {
    code: 'child',
    type: 'C',
    apiCode: 'children'
  },
  I: {
    code: 'infant',
    type: 'I',
    apiCode: 'infants'
  },
  ST: {
    code: 'student',
    type: 'ST',
    apiCode: 'students'
  },
  PET: {
    code: 'pet',
    type: 'PET',
    apiCode: 'pets'
  }
}
