import { languageLabelMap } from '../../components/language/constants'

export const ATTEMPTING_REGISTER = 'user/ATTEMPTING_REGISTER'
export const RESET_ATTEMPTING_REGISTER = 'user/RESET_ATTEMPTING_REGISTER'
export const REGISTER_SUCCESS = 'user/REGISTER_SUCCESS'
export const REGISTER_ERROR = 'user/REGISTER_ERROR'
export const RESET_REGISTER_ERROR = 'user/RESET_REGISTER_ERROR'
export const RESET_REGISTER_FORM = 'user/RESET_REGISTER_FORM'

export const ATTEMPTING_LOGIN = 'user/ATTEMPTING_LOGIN'
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS'
export const LOGIN_ERROR = 'user/LOGIN_ERROR'
export const RESET_LOGIN_FORM = 'user/RESET_LOGIN_FORM'

export const CLEAR_CUSTOMER_ACCOUNT = 'user/CLEAR_CUSTOMER_ACCOUNT'
export const ATTEMPTING_GET_CUSTOMER = 'user/ATTEMPTING_GET_CUSTOMER'
export const GET_CUSTOMER_SUCCESS = 'user/GET_CUSTOMER_SUCCESS'
export const GET_CUSTOMER_ERROR = 'user/GET_CUSTOMER_ERROR'

export const ATTEMPTING_UPDATE = 'user/ATTEMPTING_UPDATE'
export const UPDATE_SUCCESS = 'user/UPDATE_SUCCESS'
export const UPDATE_ERROR = 'user/UPDATE_ERROR'
export const RESET_UPDATE_ERROR = 'user/RESET_UPDATE_ERROR'
export const RESET_UPDATE_SUCCESS = 'user/RESET_UPDATE_SUCCESS'

export const ATTEMPTING_PASSWORD_RESET_EMAIL =
  'user/ATTEMPTING_PASSWORD_RESET_EMAIL'
export const PASSWORD_RESET_EMAIL_SUCCESS = 'user/PASSWORD_RESET_EMAIL_SUCCESS'
export const PASSWORD_RESET_EMAIL_ERROR = 'user/PASSWORD_RESET_EMAIL_ERROR'
export const RESET_PASSWORD_RESET_EMAIL_ERROR =
  'user/RESET_PASSWORD_RESET_EMAIL_ERROR'
export const RESET_PASSWORD_RESET_EMAIL_SUCCESS =
  'user/RESET_PASSWORD_RESET_EMAIL_SUCCESS'

export const ATTEMPTING_PASSWORD_RESET = 'user/ATTEMPTING_PASSWORD_RESET'
export const PASSWORD_RESET_SUCCESS = 'user/PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_ERROR = 'user/PASSWORD_RESET_ERROR'
export const RESET_PASSWORD_RESET_ERROR = 'user/RESET_PASSWORD_RESET_ERROR'
export const RESET_PASSWORD_RESET_SUCCESS = 'user/RESET_PASSWORD_RESET_SUCCESS'

export const COPASSENGERS_RECEIVED = 'user/COPASSENGERS_RECEIVED'

export const ATTEMPTING_SEND_EMAIL_CONFIRMATION =
  'user/ATTEMPTING_SEND_EMAIL_CONFIRMATION'
export const SEND_EMAIL_CONFIRMATION_ERROR =
  'user/SEND_EMAIL_CONFIRMATION_ERROR'
export const SEND_EMAIL_CONFIRMATION_SUCCESS =
  'user/SEND_EMAIL_CONFIRMATION_SUCCESS'
export const RESET_SEND_EMAIL_CONFIRMATION =
  'user/RESET_SEND_EMAIL_CONFIRMATION'

export const USER_LOGIN_KEY = 'loggedInEmail'

export const DEFAULT_LANG =
  sessionStorage.getItem('language') &&
  Object.keys(languageLabelMap).includes(sessionStorage.getItem('language'))
    ? sessionStorage.getItem('language')
    : process.env.REACT_APP_DEFAULT_LANG_CODE

export const FIND_USER_RESERVATION = 'user/FIND_USER_RESERVATION'
export const FIND_RESERVATION_SUCCESS = 'user/FIND_RESERVATION_SUCCESS'
export const FIND_RESERVATION_ERROR = 'user/FIND_RESERVATION_ERROR'
export const FIND_RESERVATION_RESET = 'user/FIND_RESERVATION_RESET'
