import React from 'react'
import { shape } from 'prop-types'
import './BookingReminders.scss'

const BookingReminders = ({ labels }) =>
  !Object.keys(labels).length ? null : (
    <div className="booking-reminders">
      <p className="theme-font-header">{labels.confirmationEmailMsg}</p>
      <p>{labels.confirmationEmailReminder1}</p>
      <p>{labels.confirmationEmailReminder2}</p>
    </div>
  )

BookingReminders.propTypes = {
  labels: shape().isRequired
}

export default BookingReminders
