import React from 'react'
import { shape, func, string, bool } from 'prop-types'
import { Formik, Form } from 'formik'
import { renderTextInput } from '../TextInput/TextInput'
import './ModalHeaderForm.scss'

const ModalHeaderForm = ({
  fieldDetails,
  initialValues,
  handleSubmit,
  validationSchema,
  submitButtonClasses,
  disabled,
  submitLabel
}) => {
  return (
    <div className="modal-header-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="modal-header-form-row">
              {renderTextInput({
                name: fieldDetails.emailAddress.name,
                value: values[fieldDetails.emailAddress.name],
                placeholder: fieldDetails.emailAddress.label,
                errorClasses: 'modal-header-form-row-error',
                setFieldValue,
                disabled,
                classNames: 'email'
              })}
            </div>
            <div className="modal-header-form-row">
              <button
                type="submit"
                className={submitButtonClasses}
                disabled={disabled}
              >
                {submitLabel}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

ModalHeaderForm.propTypes = {
  fieldDetails: shape().isRequired,
  initialValues: shape().isRequired,
  handleSubmit: func.isRequired,
  validationSchema: shape().isRequired,
  submitButtonClasses: string.isRequired,
  disabled: bool.isRequired,
  submitLabel: string.isRequired
}

export default ModalHeaderForm
