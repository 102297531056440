import { PHONE_FIELDS } from '../../redux/configuration/constants'

export const getFieldsAndLayout = () => {
  const allFieldNames = [
    'title',
    'firstName',
    'lastName',
    'language',
    'dateOfBirth',
    'passengerType',
    'address',
    'city',
    'countryCode',
    'county',
    'postalCode',
    'contactName',
    'homePhoneNumber',
    'mobilePhoneNumber',
    'workPhoneNumber',
    'emailAddress',
    'emailAddressConfirm',
    'acceptsContact',
    'password',
    'confirmPassword'
  ]
  const contactRows = PHONE_FIELDS.useSplitLayout
    ? [
        [...PHONE_FIELDS.client],
        ['contactName', 'emailAddress', 'emailAddressConfirm']
      ]
    : [[...PHONE_FIELDS.client, 'emailAddress', 'emailAddressConfirm']]
  const allFieldLayout = [
    ['title', 'firstName', 'lastName'],
    ['language', 'passengerType', 'dateOfBirth'],
    ['address', 'city'],
    ['countryCode', 'county', 'postalCode'],
    ...contactRows,
    ['acceptsContact']
  ]

  const keysToRemove = PHONE_FIELDS.all.reduce((acc, phoneField) => {
    if (!PHONE_FIELDS.client.includes(phoneField)) {
      acc[phoneField] = true
    }
    return acc
  }, {})

  const filterKeys = arr => arr.filter(x => !keysToRemove[x])

  return {
    fieldNames: filterKeys(allFieldNames),
    formLayout: allFieldLayout.map(row => filterKeys(row))
  }
}
